import * as React from 'react';

const SvgCornerRightUp = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="corner-right-up_svg__feather corner-right-up_svg__feather-corner-right-up"
        {...props}
    >
        <path d="M10 9l5-5 5 5" />
        <path d="M4 20h7a4 4 0 004-4V4" />
    </svg>
);

export default SvgCornerRightUp;
