import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { Async } from "react-async";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { AppService } from "strikejs-app-service";
import { useAppService } from "../../../../../AppService";
import { Button, ButtonIcon } from "../../../../../components/ui/Button";
import { Panel } from "../../../../../components/ui/Panel";
import { Pill } from "../../../../../components/ui/Pill";
import { RagStatus } from "../../../../../components/ui/RagStatus";
import { Services } from "../../../../../constants";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { IModalService } from "../../../../../core/modal/IModalService";
import { B } from "../../../../../core/util/BootstrapHelper";
import { Enums, getStatusAccentFromImpactLevel, Translator } from "../../../../../enums";
import { RenderXssSafeString } from "../../../../../core/AntiXss/AntiXssHelper";
import { ImpactReportGroupTypes } from "../impactsByProjects/ImpactsByProjects_model";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import I18n from "../../../../../core/localization/I18n";

interface IImpactOverTimeDetailedViewProps {
  impactReportGroupType: ImpactReportGroupTypes;
  year: number;
  month: number;
  resourceId: number;
  status: string;
  resourceName: string;
  onUnmount: () => void;
}

export const ImpactOverTimeDetailedView: React.FC<IImpactOverTimeDetailedViewProps> = ({
  impactReportGroupType,
  resourceId,
  resourceName,
  status,
  month,
  onUnmount,
  year
}) => {
  const history = useHistory();
  const currentOrganisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const modalService = appService.getService<IModalService>(Services.AsideModalService);
  const dataProvider = getDataFunction(impactReportGroupType, appService);
  
  const load = useCallback(async () => {
    const resp = await dataProvider(currentOrganisationId, resourceId, year, month);
    return resp.payload;
  }, [year, month, resourceId, currentOrganisationId, dataProvider]);

  useEffect(() => {
    return onUnmount;
  }, [onUnmount]);

  return (
    <PanelWrapper>
      <RightAlign>
        <Button type="primary">{I18n.t("phrases.viewTimeline")}</Button>
        <ButtonIcon
          symbol="Close"
          type="outline-primary"
          onClick={() => {
            modalService.hide();
          }}
        />
      </RightAlign>
      <h3>{moment(new Date(2020, month - 1, 1)).format("MMMM")}</h3>
      <Async promiseFn={load}>
        <Async.Loading>Loading...</Async.Loading>
        <Async.Resolved<
          {
            id: number;
            name: string;
            projectId: number;
            businessAreaName: string;
            referenceNumber: string;
            impactLevel: number;
            startDate: string | null;
            progressStatus: number;
            actualEndDate: string | null;
          }[]
        >>
          {payload => (
            <>
              <div className="row">
                <div className="col-6">
                  <DetailedViewH4>{getTitle(impactReportGroupType)}</DetailedViewH4>
                  <div>{resourceName}</div>
                </div>
                <div className="col-6">
                  <DetailedViewH4>RAG status</DetailedViewH4>
                  <div>{status}</div>
                </div>
              </div>
              <br />
              <br />
              <DetailedViewH4>Impacts</DetailedViewH4>
              {payload.map(item => {
                const cls = B().bl("project-info-card");
                return (
                  <Panel
                    hasShadow={true}
                    type="border-left-primary"
                    className={`${cls.bem}`}
                    background="bg-white"
                    hasBorderRadius={true}
                    style={{ minWidth: "320px" }}
                  >
                    <div className="row mb-2">
                      <div className="col-8">
                        <div>
                          <h4
                            className="mb-1 strong d-inline-block"
                            onClick={() => {
                              modalService.hide();
                              history.push(
                                `/organisations/${currentOrganisationId}/projects/${item.projectId}/impacts/${item.id}`
                              );
                            }}
                          >
                            {item.referenceNumber || "No ref"}{" "}
                            <SmallRagStatus state={getRagStatus(item.impactLevel)} className="mt-1" />
                          </h4>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.name) }}></div>
                      </div>
                      <div className="col-4">
                        <Pill type={getStatusAccentFromImpactLevel(item.impactLevel)} className="text-nowrap">
                          {Translator.Metric(item.impactLevel)}
                        </Pill>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <span>
                          {item.startDate && moment(item.startDate).format("DD/MM/yyyy")} -{" "}
                          {item.actualEndDate && moment(item.actualEndDate).format("DD/MM/yyyy")}
                        </span>
                      </div>
                    </div>
                  </Panel>
                );
              })}
            </>
          )}
        </Async.Resolved>
        <Async.Rejected>{err => err.message}</Async.Rejected>
      </Async>
    </PanelWrapper>
  );
};

const PanelWrapper = styled.div``;

const RightAlign = styled.div`
  display: flex;
  justify-content: end;
  justify-content: flex-end;
  margin-bottom: 2.42rem;

  button + button {
    margin-left: 4px;
  }
`;

const DetailedViewH4 = styled.h4`
  margin-bottom: 10px;
`;

const getDataFunction = (impactReportGroupType: ImpactReportGroupTypes, appService: AppService) => {
  const orgasationProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi)
  switch (impactReportGroupType) {
    case ImpactReportGroupTypes.BUSINESS_AREAS:
      return orgasationProvider.getImpactReportOverTimeByBusinessAreaDetails;
    case ImpactReportGroupTypes.LOCATIONS:
      return orgasationProvider.getImpactReportOverTimeByLocationDetails;
    case ImpactReportGroupTypes.STAKEHOLDERS:
      return orgasationProvider.getImpactReportOverTimeByStakeholderDetails;

    default:
      break;
  }
}

const getTitle = (impactReportGroupType: ImpactReportGroupTypes) => {
  switch (impactReportGroupType) {
    case ImpactReportGroupTypes.BUSINESS_AREAS:
      return I18n.t("entities.businessArea");
    case ImpactReportGroupTypes.LOCATIONS:
      return I18n.t("entities.location");
    case ImpactReportGroupTypes.STAKEHOLDERS:
      return I18n.t("entities.stakeholder");

    default:
      break;
  }
}

export function getRagStatus(level: number) {
  if (level <= 4) {
    return Enums.RagStatus.GREEN;
  }
  if (level < 7) {
    return Enums.RagStatus.AMBER;
  }

  return Enums.RagStatus.RED;
}

export const SmallRagStatus = styled(RagStatus)`
  width: 10px;
  height: 10px;
  margin-left: 10px;
`;
