import { BaseModel } from "../util/BaseModel";
import { ITableHeaderModel, ITableHeaderConfig } from "./ITableHeaderModel";
import { observable, action } from "mobx";

export class TableHeaderModel extends BaseModel implements ITableHeaderModel {
  @observable config: ITableHeaderConfig = {
    key: "",
    content: "",
    isSortAsc: null,
    onClick: () => {},
    selector: "",
    action: null,
    cellContentFunction: null,
    isDivTable: false,
    showSortIcons: false
  };
  @observable isSortAsc = null;
  isDivTable: boolean = false;
  action: (model: ITableHeaderModel) => void;

  constructor(initOpts?: ITableHeaderConfig) {
    super();
    if (initOpts) {
      this.setConfig(initOpts);
    }
  }

  @action
  setConfig = (config: Partial<ITableHeaderConfig>): void => {
    this.config = { ...this.config, ...config };
    this.isSortAsc =
      config.isSortAsc !== null && typeof config.isSortAsc !== "undefined" ? config.isSortAsc : this.config.isSortAsc;
    this.action = config.action || this.config.action;
    this.isDivTable = config.isDivTable || this.config.isDivTable;
  };

  @action
  resetSort = () => {
    this.isSortAsc = null;
  };

  @action
  toggleSort = () => {
    this.isSortAsc = !this.isSortAsc;
  };
}
