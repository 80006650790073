import { TimeLineModel } from "./Timeline_model";
import { observable, action } from "mobx";

export class EditableTimelineModel extends TimeLineModel {
  id: number | string;
  isSingle: boolean;
  updateItem: (data: any) => Promise<void>;
  clickItem: any | ((itemId: number) => void);
  @observable.ref openGroups: any = {};

  constructor(
    updateItem: (data: any) => Promise<void>,
    clickItem?: (data: any) => void,
    id?: number | string,
    isSingle?: boolean
  ) {
    super();
    this.id = id || -1;
    this.isEditable = true;
    this.canEdit = true; //TODO replace once permissions are complete
    this.isSingle = isSingle || false;
    if (isSingle) {
      this.openGroups = { [this.id]: true };
    }
    this.updateItem = updateItem;
    this.clickItem = clickItem || null;
  }

  handleItemMove = async (itemId, time, edge) => {
    this.isLoading = true;
    await this.updateItem({
      id: itemId,
      action: "move",
      date: time
    });
    this.isLoading = false;
  };

  handleItemResize = async (itemId, time, edge) => {
    this.isLoading = true;
    await this.updateItem({
      id: itemId,
      action: "resize",
      date: time,
      edge
    });
    this.isLoading = false;
  };

  handleItemDoubleClick = (itemId: string) => {
    if (itemId.charAt(0) === "i") {
      this.clickItem(+itemId.split("-")[1]);
      // this.clickItem(+itemId.substring(2));
    }
  };

  @action
  toggleGroup = id => {
    this.openGroups = {
      ...this.openGroups,
      [id]: !this.openGroups[id]
    };
  };
}
