import { observer } from "mobx-react-lite";
import * as React from "react";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { HeatmapModel } from "./Heatmap_model";
import * as _ from "lodash";
import { HeatmapBody } from "./HeatmapBody_view";
import { HeatmapSidebar } from "./HeatmapSidebar_view";
import { Tooltip } from "react-tippy";
export interface HeatmapProps {
  model: HeatmapModel;
}

const Heatmap: React.FC<HeatmapProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  if (model.heatmapData && model.heatmapData.length === 0) {
    return (
      <div className="heatmap">
        <p>No results were returned</p>
      </div>
    );
  }

  return (
    <div className={`heatmap ${model.config.rotateHeader ? "heatmap--extended" : ""}`}>
      <div className="heatmap__sidebar">
        <HeatmapSidebar model={model} />
      </div>
      <div className="heatmap__content">
        <HeatmapHeader model={model} />
        <HeatmapBody model={model} />
      </div>
    </div>
  );
});

interface HeatmapHeaderProps {
  model: HeatmapModel;
}

const HeatmapHeader: React.FC<HeatmapHeaderProps> = observer(({ model }) => {
  const rotateHeaders = model.config.rotateHeader;
  const groups = model.headerGroups;
  return (
    <div className={`heatmap__header ${(rotateHeaders && "heatmap__header--rotate") || ""}`}>
      {_.map(groups, (e, i) => {
        let hasProgramme = e[0] && (typeof e[0].parent !== "undefined" || e[0].parent !== null);
        return (
          <div key={"e" + i} className="heatmap__header-group">
            <div
              className="heatmap__header-group-title text-truncate d-block"
              style={{ width: e.length * 50, height: hasProgramme ? "63px" : "auto" }}
            >
              <Tooltip
                theme="light"
                position="top"
                followCursor
                popperOptions={{
                  modifiers: {
                    preventOverflow: { enabled: false },
                    flip: { enabled: false },
                    hide: { enabled: false }
                  }
                }}
                html={<small className="d-block">{e[0].parent}</small>}
              >
                {e[0].parent}
              </Tooltip>
            </div>
            <div className="heatmap__header-group-childs">
              {_.map(e, (k, ii) => {
                return (
                  <div
                    key={"k" + ii}
                    className="heatmap__header-child-title"
                    style={{
                      opacity: model.highlightedCell && `${model.highlightedCell.xAxisKey}` !== `${k.key}` ? 0.3 : 1
                    }}
                  >
                    <div className="heatmap__header--t">
                      <Tooltip
                        theme="light"
                        position="left"
                        followCursor
                        html={<small className="d-block">{k.label}</small>}
                      >
                        <p className="mb-0 text-truncate">{k.label}</p>
                      </Tooltip>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export { Heatmap };
