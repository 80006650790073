import { IHttp } from "../../IHttp";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IImpactsApi } from "./IImpacts.api";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";

export class ImpactsApi extends ExportableProjectApi<FP.Entities.IImpact> implements IImpactsApi {
  controller: string = "impacts";

  constructor(http: IHttp) {
    super(http, "impacts");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    projectId?: number,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getMixedFiltered = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/all-types${query}`,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getDetailedById = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/detailed`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact>;
    }
  };

  getUnassignedStakeholders = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unassignedStakeholders`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>;
    }
  };

  getImpactsWithNoImpactGroup = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/with-no-impact-groups`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  getUnassignedActions = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unassignedActions`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
  };

  getActions = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/actions`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
  };

  getUnassignedStGroups = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/unassignedStGroups`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>;
    }
  };

  addProjectStakeholder = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stakeholderIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/stakeholders`;
    let res = await this.http.post(url, stakeholderIds, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder>;
    }
  };

  addStakeholderGroups = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stGroups: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/stakeholder-groups`;
    let res = await this.http.post(url, stGroups, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>;
    }
  };

  addActions = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stGroups: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/actions`;
    let res = await this.http.post(url, stGroups, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
  };

  removeAction = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    actionId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/actions/${actionId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  removeStakeholder = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/stakeholders/${stakeholderId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  getComments = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/comments`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url + impactId + "/comments");
    console.error("Status: " + res.statusText);
    return null;
  };

  addComment = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/comments`;
    let res = await this.http.post(url, comment, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + impactId + "/comments");
    console.error("Status: " + res.statusText);
    return null;
  };

  shiftImpactDates = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    days: number,
    shiftActions: boolean,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/shiftdates`;
    let res = await this.http.put(url, { days, shiftActions }, config);
    if (res && res.status === 200) {
      return res.data;
    }

    return null;
  };

  getDataHistory = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactId}/short-history/`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }
}
