import React from "react";
import { Route, Switch } from "react-router";
import { CanEditProjectRoute, CanViewProjectRoute } from "../../core/router/Route";
import { ContainerProps } from ".";
import { ActionListView } from "../../pages/change/projects/actionListView/ActionListView_view";
import { ActionExtendedView } from "../../pages/change/actions/actionExtendedView/ActionExtendedView_view";
import { AddStakeholder } from "../../pages/change/projects/addStakeholder/AddStakeholder_view";
import { AddStakeholderModel } from "../../pages/change/projects/addStakeholder/AddStakeholder_model";
import { CreateImpactView } from "../../pages/change/impacts/createImpactView/CreateImpactView_view";
import { CreateImpactViewModel } from "../../pages/change/impacts/createImpactView/CreateImpactView_model";
import { AddImpactGroup } from "../../pages/change/projects/addImpactGroup/AddImpactGroup_view";
import { ImpactGroupsView } from "../../pages/change/projects/impactGroupsView/ImpactGroupsView_view";
import { ImpactGroupsViewModel } from "../../pages/change/projects/impactGroupsView/ImpactGroupsView_model";
import { CreateActionView } from "../../pages/change/actions/createActionView/CreateActionView_view";
import { EditImpactView } from "../../pages/change/impacts/editImpactView/EditImpactView_view";
import { EditImpactViewModel } from "../../pages/change/impacts/editImpactView/EditImpactView_model";
import { EditStakeholderView } from "../../pages/change/stakeholders/editStakeholderView/EditStakeholderView_view";
import { EditStakeholderViewModel } from "../../pages/change/stakeholders/editStakeholderView/EditStakeholderView_model";
import { EditActionView } from "../../pages/change/actions/editActionView/EditActionView_view";
import { StakeholderImpactsView } from "../../pages/change/stakeholders/stakeholderImpactsView/StakeholderImpactsView_view";
import { StakeholderImpactsViewModel } from "../../pages/change/stakeholders/stakeholderImpactsView/StakeholderImpactsView_model";
import { StakeholderView } from "../../pages/change/projects/stakeholderView/StakeholderView_view";
import { StakeholderViewModel } from "../../pages/change/projects/stakeholderView/StakeholderView_model";
import { SettingsView } from "../../pages/change/projects/settingsView/SettingsView_view";
import { SettingsViewModel } from "../../pages/change/projects/settingsView/SettingsView_model";
import { StakeholderExtendedView } from "../../pages/change/stakeholders/stakeholderExtendedView/StakeholderExtendedView_view";
import { StakeholderExtendedViewModel } from "../../pages/change/stakeholders/stakeholderExtendedView/StakeholderExtendedView_model";
import { ImpactGroupExtendedView } from "../../pages/change/impactGroup/impactGroupExtendedView/ImpactGroupExtendedView_view";
import { ImpactExtendedView } from "../../pages/change/impacts/impactExtendedView/ImpactExtendedView_view";
import { ImpactsView } from "../../pages/change/projects/impactsView/ImpactsView_view";
import { SettingsView as CustomPropertySettingsView } from "../../pages/change/projects/customPropertiesSettings/SettingsView_view";
import { SettingsViewModel as CustomPropertySettingsModel } from "../../pages/change/projects/customPropertiesSettings/SettingsView_model";
import { ProjectView } from "../../pages/change/projects/view/View_view";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { ReportsView } from "../../pages/change/projects/reportsView/ReportsView_view";
import { AddAudience } from "../../pages/change/projects/addAudience/AddAudience_view";
import { AddAudienceModel } from "../../pages/change/projects/addAudience/AddAudience_model";
import { AddStakeholderGroup } from "../../pages/change/projects/addStakeholderGroup/AddStakeholderGroup_view";
import { AddStakeholderGroupModel } from "../../pages/change/projects/addStakeholderGroup/AddStakeholderGroup_model";
import { useFlightPathUser } from "../../setup";
import { useIsOrganisationAdmin, useIsTestUser, useRefreshClaims } from "../../core/auth/authorise";
import { EditImpactGroupView } from "../../pages/change/impactGroup/editImpactGroupView/EditImpactGroupView_view";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";
import { EditQuickImpactView } from "../../pages/change/quickImpacts/EditQuickImpactView_view";
import { EditQuickImpactViewModel } from "../../pages/change/quickImpacts/EditQuickImpactView_model";
import { ShowIfOrganisationAdmin } from "../../components/hoc/CanEdit";
import { ImpactVisualisationsView } from "../../pages/change/projects/impactVisualisationsView/ImpactVisualisationsView_view";
import { AudienceView } from "../../pages/change/projects/audienceView/AudienceView_view";
import { StakeholderVisualisationsView } from "../../pages/change/projects/stakeholderVisualisationsView/StakeholderVisualisationsView_view";
import { ActionVisualisationsView } from "../../pages/change/projects/actionVisualisationView/ActionVisualisationsView_view";

export interface IProjectsContainer extends ContainerProps { }

const ProjectsContainer: React.FunctionComponent<IProjectsContainer> = ({ appService }) => {
  const user = useFlightPathUser();
  const isTestUser = useIsTestUser();
  const refreshClaims = useRefreshClaims();
  const organisationId = useCurrentOrganisationId();
  const isOrganisationAdmin = useIsOrganisationAdmin(organisationId);
  const isAdmin = useIsOrganisationAdmin(organisationId);
  return (
    <Route path="/organisations/:organisationId/projects/:projectId">
      <Switch>
        <Route path="/organisations/:organisationId/projects/:projectId/actions/create">
          <CanEditProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <CreateActionView />
          </CanEditProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/actions/:actionId/edit">
          <CanEditProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <EditActionView />
          </CanEditProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/actions/:actionId">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <ActionExtendedView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/actions">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <ActionListView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/reports">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.reports`)}`} />
            <ReportsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impact-visualisations/:page" >
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactVisualisationPageHeading`)}`} />
            <ImpactVisualisationsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impact-visualisations" >
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactVisualisationPageHeading`)}`} />
            <ImpactVisualisationsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/stakeholder-visualisations/:page" >
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
            <StakeholderVisualisationsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/stakeholder-visualisations" >
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
            <StakeholderVisualisationsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/action-visualisations/:page" >
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actionVisualisationPageHeading`)}`} />
            <ActionVisualisationsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/action-visualisations">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actionVisualisationPageHeading`)}`} />
            <ActionVisualisationsView />
          </CanViewProjectRoute>
        </Route>

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings/customProperties/:customPropertyId/:key"
          render={(props) => (
            <ShowIfOrganisationAdmin>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <CustomPropertySettingsView
                key={`${props.match.params["organisationId"]}-${props.match.params["key"]}`}
                model={
                  new CustomPropertySettingsModel(appService,
                    +props.match.params["organisationId"],
                    props,
                    refreshClaims,
                    isAdmin,
                    parseInt(props.match.params["customPropertyId"]))
                }
              />
            </ShowIfOrganisationAdmin>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings/customProperties/:customPropertyId/"
          render={(props) => (
            <ShowIfOrganisationAdmin>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <CustomPropertySettingsView
                key={`${props.match.params["organisationId"]}-${props.match.params["page"]}`}
                model={
                  new CustomPropertySettingsModel(appService,
                    +props.match.params["organisationId"],
                    props,
                    refreshClaims,
                    isAdmin,
                    parseInt(props.match.params["customPropertyId"]))
                }
              />
            </ShowIfOrganisationAdmin>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings/:page"
          render={({
            match: {
              params: { projectId, organisationId, page }
            }
          }) => (
            <CanEditProjectRoute projectId={projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <SettingsView
                key={`${projectId}_${page}`}
                model={new SettingsViewModel(appService, +organisationId, +projectId, user, refreshClaims, isOrganisationAdmin, page)}
              />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings"
          render={({
            match: {
              params: { projectId, organisationId }
            }
          }) => (
            <CanEditProjectRoute projectId={projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <SettingsView
                key={`${projectId}_${organisationId}`}
                model={new SettingsViewModel(appService, +organisationId, +projectId, user, refreshClaims, isOrganisationAdmin)}
              />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/addStakeholder"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={I18n.t("phrases.addStakeholder")} />
              <AddStakeholder key={props.match.params["id"]} model={new AddStakeholderModel(appService, props)} />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/addAudience"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={I18n.t("phrases.addAudience")} />
              <AddAudience key={props.match.params["id"]} model={new AddAudienceModel(appService, props)} />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/addStakeholderGroup"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={I18n.t("phrases.addStakeholderGroup")} />
              <AddStakeholderGroup
                key={props.match.params["id"]}
                model={new AddStakeholderGroupModel(appService, props)}
              />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/stakeholders/:stakeholderId/impacts"
          render={props => (
            <CanViewProjectRoute projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderImpactsView
                key={`${props.match.params["projectId"]}-${props.match.params["stakeholderId"]}`}
                model={new StakeholderImpactsViewModel(appService, props)}
              />
            </CanViewProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/stakeholders/:sid/edit"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <EditStakeholderView
                key={`${props.match.params["id"]}-${props.match.params["sid"]}`}
                model={new EditStakeholderViewModel(appService, props)}
              />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/stakeholders/:sid"
          render={routeProps => (
            <CanViewProjectRoute projectId={routeProps.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderExtendedView
                key={`${routeProps.match.params["id"]}-${routeProps.match.params["sid"]}`}
                model={new StakeholderExtendedViewModel({ appService, routeProps, authUser: user })}
              />
            </CanViewProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/stakeholders"
          render={props => (
            <CanViewProjectRoute projectId={props.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderView key={props.match.params["id"]} model={new StakeholderViewModel(appService, props, organisationId)} />
            </CanViewProjectRoute>
          )}
        />

        <Route path="/organisations/:organisationId/projects/:projectId/audiences">
            <CanViewProjectRoute>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
              <AudienceView />
            </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impactGroups/create">
          <CanEditProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
            <AddImpactGroup />
          </CanEditProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impactGroups/:impactGroupId/edit">
          <CanEditProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
            <EditImpactGroupView />
          </CanEditProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impactGroups/:impactGroupId">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
            <ImpactGroupExtendedView />
          </CanViewProjectRoute>
        </Route>

        <Route
          path="/organisations/:organisationId/projects/:id/impactGroups"
          render={props => (
            <CanViewProjectRoute projectId={props.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
              <ImpactGroupsView key={props.match.params["id"]} model={new ImpactGroupsViewModel(appService, props, organisationId)} />
            </CanViewProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/impacts/create"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
              <CreateImpactView key={props.match.params["id"]} model={new CreateImpactViewModel(appService, props, isTestUser)} />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/impacts/:impactId/edit"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
              <EditImpactView
                key={`${props.match.params["id"]}-${props.match.params["impactId"]}`}
                model={new EditImpactViewModel(appService, props, isTestUser, organisationId)}
              />
            </CanEditProjectRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:id/quick-impacts/:impactId/edit"
          render={props => (
            <CanEditProjectRoute projectId={props.match.params.id}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
              <EditQuickImpactView
                key={`${props.match.params["id"]}-${props.match.params["impactId"]}`}
                model={new EditQuickImpactViewModel(appService, props, isTestUser, organisationId)}
              />
            </CanEditProjectRoute>
          )}
        />

        <Route path="/organisations/:organisationId/projects/:projectId/impacts/:impactId">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
            <ImpactExtendedView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impacts">
          <CanViewProjectRoute>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
            <ImpactsView />
          </CanViewProjectRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId">
          <CanViewProjectRoute>
            <PageTitle title={I18n.t(`phrases.project`)} />
            <ProjectView />
          </CanViewProjectRoute>
        </Route>

        <Route path="*">
          <>
            <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
            <NotFoundIndex />
          </>
        </Route>
      </Switch>
    </Route>
  );
};

export { ProjectsContainer };
