import * as React from "react";
import { IPaginationModel } from "./Pagination_model";
import { PaginationGroup, PaginationButton } from "../../ui/Pagination";
import { observer } from "mobx-react-lite";
import * as _ from "lodash";

export interface PaginationProps {
  model: IPaginationModel;
}

const Pagination: React.FunctionComponent<PaginationProps> = observer(({ model }) => {
  if (!model.config.currentPage || !model.config.totalPages) return null;

  return (
    <PaginationGroup>
      {_.range(0, model.config.totalPages).map(e => {
        let k = e + 1;
        return (
          <PaginationButton
            key={k}
            status={k === model.config.currentPage ? "active" : (null as any)}
            onClick={() => model.config.onPageClick(k)}
          >
            {k}
          </PaginationButton>
        );
      })}
    </PaginationGroup>
  );
});

export { Pagination };
