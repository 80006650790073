import * as React from "react";

const SvgTimeline = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 14"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M3.5 1h10M4.5 13h10M1.5 7h10" />
    </svg>
);

export default SvgTimeline;
