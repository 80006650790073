import { IInnerNavContentItem } from "../../../components/widgets/innerNavigation/InnerNavContent_model";
import { IInnerNavigationService } from "./IInnerNavigationService";

export class InnerNavigationService implements IInnerNavigationService {

  createInnerNavContentItem = (k: string, l: string, c: React.ReactNode):IInnerNavContentItem => {
    return {
      key: k,
      label: l,
      content: c
    };
  };

  innerNavigationConfigKeys = (items: Dictionary<IInnerNavContentItem>):any[] => {
    var keys = [];
    Object.entries(items).forEach(([key, item]) => {
      return keys.push(item.key);
    });
    return keys;
  };
}
