import React, { useState } from "react";
import { Route, Switch } from "react-router";
import { CanContributeOrgRoute } from "../../core/router/Route";
import { ContainerProps } from ".";
import { CreateActionView } from "../../pages/change/actions/createActionView/CreateActionView_view";
import { CreateActionViewModel } from "../../pages/change/actions/createActionView/CreateActionView_model";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { useAppService } from "../../AppService";
import { useFlightPathUser } from "../../setup";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";

export interface IActionsContainer extends ContainerProps { }

export const ActionsContainer: React.FunctionComponent<IActionsContainer> = ({ appService }) => {
  return (
    <Switch>
      <Route path="*">
        <>
          <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
          <NotFoundIndex />
        </>
      </Route>
    </Switch>
  );
};

export const CreateActionFormWrapper: React.FC = () => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();

  const [model] = useState(() => new CreateActionViewModel({ appService, user, organisationId: organisationId }));
  return (
    <CanContributeOrgRoute>
      <PageTitle title={I18n.t(`phrases.actions`)} />
      <CreateActionView model={model} />
    </CanContributeOrgRoute>
  );
};
