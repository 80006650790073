import * as React from "react";
import { ActivityCardModel } from "./ActivityCard_model";
import { Panel } from "../../ui/Panel";
import { AvatarContent } from "../../ui/AvatarContent";
import { Tooltip } from "../../ui/Tooltip";
import { Icon } from "../../ui/Icon";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import moment from "moment";
import { UiSizes } from "../../../enums";
import I18n from "../../../core/localization/I18n";
import { observer } from "mobx-react";
import { RichTextEditor } from "../../ui/_forms/RichTextEditor";
import { Button } from "../../ui/Button";
import { RenderXssSafeString } from "../../../core/AntiXss/AntiXssHelper";

export interface ActivityCardProps {
  model: ActivityCardModel;
}

const ActivityCard: React.FunctionComponent<ActivityCardProps> = observer(({ model }) => {
  const { actions } = model.config;

  return (
    <AvatarContent
      type="primary"
      avatarProps={{
        size: UiSizes.MD,
        imgSrc: model.comment.owner.profileImageUrl as string
      }}
      className="activity-card"
    >
      <Panel className="activity-card__panel p-4" background="bg-white" hasBorderRadius={true}>
        <div className="row mb-3">
          <div className="col">
            <div className="d-inline-block">
              <p className="mb-0 strong">{`${model.comment.owner.firstName} ${model.comment.owner.lastName}`}</p>
              <small className="strong text-muted d-block">
                {moment(model.comment.createdAt).format("LLL")}
                {model.comment.createdAt !== model.comment.updatedAt ? <em> - {I18n.t("phrases.edited")}</em> : null}
              </small>
            </div>

            {actions && actions.length > 0 && (
              <Tooltip
                position="left"
                className="float-right"
                shownElement={<Icon symbol="DotsHorizontal" />}
                triggeredOn="click"
              >
                <Panel background="bg-white" hasShadow={true}>
                  {actions.map(action => {
                    return <UiActionComponent key={action.id} action={action} contextModel={model} />;
                  })}
                </Panel>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {model.isEditMode ? (
              <RichTextEditor value={model.inputValue} onChange={model.handleInputChange} />
            ) : (
              <div
                data-testid="content-area"
                dangerouslySetInnerHTML={{ __html: RenderXssSafeString(model.comment.content) }}
              />
            )}
          </div>
        </div>

        {model.isEditMode ? (
          <div className="row mt-3">
            <div className="col">
              <div className="d-flex">
                <Button className="ml-auto" onClick={model.exitEditMode} type="link">
                  {I18n.t("phrases.cancel")}
                </Button>
                <Button
                  title={!model.inputValue ? I18n.t("phrases.typeSomething") : ""}
                  className="ml-2"
                  onClick={() => model.handleOnSave(model.comment)}
                  disabled={!model.inputValue}
                >
                  {I18n.t("phrases.save")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </Panel>
    </AvatarContent>
  );
});

export { ActivityCard };
