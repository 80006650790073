import { getAppToken } from "../../setup";
import { IApiResponse } from "../types";
import { IClaim } from "./authorise";

export async function loadFlightPathUserClaims(userId?: number) {
  let result = await fetchUserClaims();
  if (!result.payload.length || !userId) {
    await createSystemUser();
    result = await fetchUserClaims();
  }
  return result.payload;
}

async function fetchUserClaims() {
  const resp = await fetch(`${window.appConfig.flightPathCoreApiUrl}/api/v1/auth/claims`, {
    method: "get",
    headers: {
      authorization: `Bearer ${await getAppToken()}`
    }
  });

  return (await resp.json()) as IApiResponse<IClaim[]>;
}

async function createSystemUser() {
  const resp = await fetch(`${window.appConfig.flightPathCoreApiUrl}/api/v1/auth/add`, {
    method: "put",
    headers: {
      authorization: `Bearer ${await getAppToken()}`
    }
  });

  return (await resp.json()) as IApiResponse<boolean>;
}
