import * as React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { RagStatus as R } from "../../../enums";

export interface RagStatusProps {
  state?: R;
  className?: string;
}

export const RagStates = [null, "red", "amber", "green", "complete"];

const RagStatus: React.FunctionComponent<RagStatusProps> = props => {
  const cls = B()
    .bl("rag-status")
    .pmod((props.state && RagStates[props.state]) || "red").bem;

  return <div className={`${cls} ${props.className || ""}`} />;
};

export { RagStatus };
