import React, { useEffect } from "react";
import { ImpactGroupModalModel } from "./ImpactGroupModal_model";
import { observer } from "mobx-react";
import { ImpactGroupDisplay } from "../../../impactGroup/ImpactGroupDisplay";
import { ImpactCard } from "../../../impacts/impactCard/ImpactCard_view";
import I18n from "../../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";

export interface ImpactGroupModalProps {
  model: ImpactGroupModalModel;
}

const ImpactGroupModal: React.FunctionComponent<ImpactGroupModalProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="container-fluid impact-compact-view">
      <div className="row mb-4">
        <div className="col">
          <h1 className="mb-0">
            {model.impactGroup.refNumber} - {model.impactGroup.name}
          </h1>
        </div>
      </div>
      <ImpactGroupDisplay item={model.impactGroup} />
      <div className="row mb-4">
        <div className="col">
          <h2>{I18n.t("phrases.impacts")}</h2>
          {model.impactGroup.impacts.length ? (
            model.impactGroup.impacts.map(impact => (
              <Link
                key={impact.id}
                to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}
                onClick={model.modalService.hide}
              >
                <ImpactCard item={impact} />
              </Link>
            ))
          ) : (
              <p>{I18n.t("phrases.noImpacts")}</p>
            )}
        </div>
      </div>
    </div>
  );
});

export { ImpactGroupModal };
