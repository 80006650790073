import { AxiosRequestConfig } from "axios";
import { gEntities } from "../../../../FlightPathEntities";
import { ExportableOrganisationApi } from "../../exportableApi/ExportableOrganisationApiModel";
import { IFilteredOptions, IFilteredResponse } from "../../filteredApi/FilteredApiModel";
import { IHttp } from "../../IHttp";
import { ICustomPropertiesApi } from "./ICustomPropertiesApi";

export class CustomPropertiesApi
  extends ExportableOrganisationApi<FP.Entities.ICustomProperty>
  implements ICustomPropertiesApi {
  controller: string = "custom-properties";

  constructor(http: IHttp) {
    super(http, "custom-properties");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }
  getAssociationsCount = async (organisationId: number, customPropertyId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/get-associations-count`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  canEditFields = async (organisationId: number, customPropertyId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/can-edit`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
  setPropertyIsUsedInAllProjects = async (
    organisationId: number,
    customPropertyId: number,
    value: boolean,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/is-used-in-all-projects`;
    const res = await this.http.put(url, { isRequired: value }, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  setPropertyIsRequired = async (
    organisationId: number,
    customPropertyId: number,
    value: boolean,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/is-required`;
    const res = await this.http.put(url, { isRequired: value }, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getProjectFiltered = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<FP.Entities.ICustomProperty>> => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/${this.controller}/programmes/${projectId}`;
    let res = await this.http.get(url + query, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getProjectPropertyById = async (
    organisationId: number,
    projectId: number,
    customPropertyId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/projects/${projectId}`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  setProjectPropertyIsRequired = async (
    organisationId: number,
    projectsId: number,
    customPropertyId: number,
    value: boolean,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/projects/${projectsId}/is-required`;
    const res = await this.http.put(url, { isRequired: value }, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  removeProjectProperty = async (
    organisationId: number,
    projectId: number,
    customPropertyId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/projects/${projectId}`;
    const res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  createProjectProperty = async (
    organisationId: number,
    projectId: number,
    data: gEntities.ICustomProperty,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/projects/${projectId}`;
    const res = await this.http.post(url, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  updateProjectProperty = async (
    organisationId: number,
    projectId: number,
    customPropertyId: number,
    data: gEntities.ICustomProperty,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${customPropertyId}/projects/${projectId}`;
    const res = await this.http.put(url, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
