import * as React from "react";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react-lite";
import { StakeholderListView } from "../../stakeholders/stakeholderListView/StakeholderListView_view";
import { AudienceViewModel } from "./AudienceView_model";
import { useAppService } from "../../../../AppService";
import { useHistory, useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useState } from "react";

export interface AudienceViewProps {
  model?: AudienceViewModel;
}

const AudienceView: React.FunctionComponent<AudienceViewProps> = observer(({ model:m }) => {
  const appService = useAppService();
  const history = useHistory();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(() => new AudienceViewModel(appService, history, !!projectId ? +projectId : null, organisationId));
  const modelProjectId = model.projectId;

  React.useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new AudienceViewModel(appService, history, !!projectId ? +projectId : null, organisationId));
    }
    model.onMount();
    return model.onUnmount;
  }, [appService,model,history,projectId,modelProjectId,organisationId]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return (
      <h2 className="mb-0">{`${title} ${countText}`}</h2>
    );
  };

  return (
    <div className="stakeholder-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-7">
            <h1>
              {model.project?.name || ""} | <strong>{I18n.t("phrases.audiences")}</strong>
            </h1>
            <p className="mb-5">{I18n.t("phrases.audienceViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        className="tabs--primary mb-5"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        initialTab={1}
        onTabClicked={model.changeCurrentView}>
        <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount)}></Tab>
        <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
        {model.showVisualisations === true && (
          <Tab title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}></Tab>
        )}
      </Tabs>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="mt-4">
              <StakeholderListView key="ke2" model={model.stakeholderAudienceListViewModel} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { AudienceView };
