import React, { useEffect } from "react";
import { CommentListViewModel } from "./CommentListView_model";
import { SearchButton } from "../../../../components/ui/SearchButton";
import { observer } from "mobx-react-lite";
import { Button } from "../../../../components/ui/Button";
import { ActivityCard } from "../../../../components/widgets/activityCard/ActivityCard_view";
import { ActivityCardModel } from "../../../../components/widgets/activityCard/ActivityCard_model";
import { RichTextEditor } from "../../../../components/ui/_forms/RichTextEditor";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { useUserId } from "../../../../core/auth";
import { useIsTestUser } from "../../../../core/auth/authorise";
export interface CommentListVewProps {
  model: CommentListViewModel;
}

const CommentListView: React.FunctionComponent<CommentListVewProps> = observer(props => {
  const flightPathUserId = useUserId();
  const { model } = props;
  const isTestUser = useIsTestUser();
  useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  return (
    <div className="comment-list-view container-fluid pt-5">
      {model.config.description && (
        <div className="row mb-4">
          <div className="col">{model.config.description}</div>
        </div>
      )}
      <CanEditProject projectId={model.projectId}>
        <div className="row mb-4">
          <div className="col">
            {
              isTestUser ?
                <input id="CommentInput" value={model.commentInput} onChange={(e) => model.handleInputChange(e.currentTarget.value)} />
                :
                <RichTextEditor value={model.commentInput} onChange={model.handleInputChange} />
            }
            <div className="d-flex justify-content-end mt-4">
              <Button
                id="AddCommentButton"
                title={!model.commentInput ? I18n.t("phrases.typeSomething") : null}
                onClick={model.createComment}
                disabled={!model.commentInput}
              >
                {I18n.t("phrases.add")}
              </Button>
            </div>
          </div>
        </div>
        <hr />
      </CanEditProject>

      <div className="row my-4">
        <div className="col">
          <SearchButton
            className="float-right"
            onChange={e => model.setSearchInput(e.currentTarget.value)}
            value={model.searchInput}
            onClose={model.resetSearchInput}
          />
        </div>
      </div>

      <div className="row mb-4" id="CommentArea">
        <div className="col">
          {model.isLoadingComments ? (
            <div>{I18n.t("phrases.loadingComments")}</div>
          ) : model.errorMessage ? (
            <div>{model.errorMessage}</div>
          ) : model.comments.length ? (
            model.filteredComments.length ? (
              model.filteredComments.map((comment, i) => {
                let actions = flightPathUserId === comment.ownerId ? model.activityCardActions : null;
                actions = model.permissionService.canEditProject(model.projectId) ? actions : [];
                const cardModel = new ActivityCardModel(comment, { actions });
                cardModel.onSave = model.updateComment;

                return (
                  <div className="mb-2" key={i}>
                    <ActivityCard model={cardModel} />
                  </div>
                );
              })
            ) : (
                <div>{I18n.t("phrases.noResultsFound")}</div>
              )
          ) : (
                  <div>{I18n.t("phrases.noComments")}</div>
                )}
        </div>
      </div>
    </div>
  );
});

export { CommentListView };
