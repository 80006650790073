import * as React from "react";
import { OrganisationsSettingsModel } from "./Organisations_model";
import { TableView } from "../../../../../core/table/Table_view";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { Animations } from "../../../../../core/util/Animations";

export interface OrganisationsSettingsProps {
  model: OrganisationsSettingsModel;
}

export const OrganisationsSettings: React.FunctionComponent<OrganisationsSettingsProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-7">
          <h2>Organisations</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <TableView model={model.tableModel} />
        </div>
      </div>
    </div>
  );
});
