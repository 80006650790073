export function locationSearchFeatureProvider(name: string) {
  return window.location.search.includes(`featureToggles.${name}`);
}

export function configFeatureProvider({
  featureToggles
}: {
  featureToggles: { [idx: string]: boolean };
}) {
  return (name: string) =>
    featureToggles && featureToggles[name] ? true : false;
}

export function localStorageFeatureProvider(name: string) {
  return localStorage.getItem(`featureToggles.${name}`) ? true : false;
}

export function sessionStorageFeatureProvider(name: string) {
  return sessionStorage.getItem(`featureToggles.${name}`) ? true : false;
}
