import * as React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";

export interface ButtonGroupProps {
  size?: UiSizes;
  isVertical?: boolean;
  isToggle?: boolean;
  className?: string;
}

export const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = props => {
  let cls = B()
    .bl(`btn-group${props.isVertical ? "--vertical" : ""}`)
    .pmod(props.size || UiSizes.MD)
    .pmod(props.isToggle ? "toggle" : "").bem;

  return (
    <div className={`${cls} ${props.className || ""}`} role="group">
      {props.children}
    </div>
  );
};
