import { AppService } from "strikejs-app-service";
import { IApplication } from "./IApplication";
import { createConsoleLoggerDelegate, createLogger } from "./core/logger/Logger";
import { ILogger } from "./core/logger/ILogger";
import { IApp } from "./IApp";
import { InvalidParameterError } from "./core/util/InvalidParameterError";
import { AppEventType, Enums } from "./enums";

export interface IAppEvent {
  type: AppEventType;
  payload: any;
}

export interface ApplicationConfig {
  logger: ILogger;
  appService: AppService;
  debug: boolean;
  dispose?(): void;
}

export enum ApplicationMessageType {}

export interface IApplicationMessage {
  type: ApplicationMessageType;
  payload: any;
}

export class Application implements IApplication {
  _debug: boolean;
  _logger: ILogger;
  _appService: AppService;
  private _loadingPromise: Promise<any>;
  constructor({ logger, appService, debug }: Partial<ApplicationConfig>) {
    this._logger = logger as any;
    this._appService = appService as any;
    this._loadingPromise = null as any;
    this._debug = debug as any;
    if (!this._logger) {
      console.warn("No logger has been provided. Switching to default logger");
      this._logger = createLogger(createConsoleLoggerDelegate(), Enums.LogLevel.All);
    }
  }

  get isDebug() {
    return this._debug;
  }

  get logger() {
    return this._logger;
  }

  get appService() {
    return this._appService;
  }

  registerApp(app: IApp) {
    if (typeof app.install !== "function") {
      throw new InvalidParameterError("registerApp", "function", typeof app.install);
    } else {
      if (typeof app.configureServices === "function") {
        app.configureServices(this._appService);
      }

      this._loadingPromise.then(() => {
        app.install(this, this._appService);
      });
    }
  }

  start() {
    if (this._loadingPromise) {
      return this._loadingPromise;
    }
    const promises: Promise<any>[] = [];
    return (this._loadingPromise = Promise.all(promises));
  }

  run(fn: () => void): void;
  run(deps: string[], fn: (...args: any[]) => void): void;
  run(...args: any[]) {
    if (args.length === 2) {
      if (typeof args[0] === "object" && typeof args[1] === "function") {
        if (args[0] && args[0].length) {
          return args[1].apply(
            this,
            args[0].map((e: any) => this._appService.getService(e))
          );
        } else {
          return args[1].apply(this);
        }
      } else {
        throw new InvalidParameterError("Application.run", "string[]", typeof args[0]);
      }
    } else if (arguments.length === 1 && typeof args[0] === "function") {
      args[0].apply(this);
    }
  }

  runWhenReady(fn: () => void): void;
  runWhenReady(deps: string[], fn: (...args: any[]) => void): void;
  runWhenReady(...args: any[]) {
    return this.start().then(() => {
      return this.run.apply(this, args as any);
    });
  }

  private static _app: Application;

  static startUp(cfg: Partial<ApplicationConfig>) {}
}
