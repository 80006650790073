import * as React from 'react';

const SvgSortUp = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
        <defs>
            <path
                id="sort-up_svg__a"
                d="M5.705 10L8 12.12 10.295 10l.705.652-3 2.777-3-2.777z"
            />
            <path
                id="sort-up_svg__b"
                d="M5.705 3L8 5.12 10.295 3l.705.652L8 6.43 5 3.652z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <use
                fill="#8C9BD3"
                transform="matrix(-1 0 0 1 16 0)"
                xlinkHref="#sort-up_svg__a"
            />
            <use
                fill="#001730"
                transform="matrix(1 0 0 -1 0 9.429)"
                xlinkHref="#sort-up_svg__b"
            />
        </g>
    </svg>
);

export default SvgSortUp;
