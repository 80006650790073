import * as React from 'react';

const SvgCrop = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="crop_svg__feather crop_svg__feather-crop"
        {...props}
    >
        <path d="M6.13 1L6 16a2 2 0 002 2h15" />
        <path d="M1 6.13L16 6a2 2 0 012 2v15" />
    </svg>
);

export default SvgCrop;
