import * as React from 'react';
import { B } from '../../../core/util/BootstrapHelper';



export interface BulletProps {
    className?: string;
}


const Bullet: React.FunctionComponent<BulletProps> = (props) => {
    let cls: any = B().bl("bullet")
        .bem
    return <span className={`${cls} ${props.className || ""}`}>

    </span>
}


export { Bullet };