import { IPermissionService } from "./IPermissionService";
import { IOrganisationContextModel } from "../organisationContext/IOrganisationContextModel";
import { useCurrentOrganisation } from "../../../core/auth/organisationContext";

export class PermissionService implements IPermissionService {
  orgContextModel: IOrganisationContextModel;
  currentOrg: FP.Entities.IOrganisation;
  orgList: FP.Entities.IOrganisation[];
  orgWithPermission: FP.Entities.IOrganisation;

  canEdit = () => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);
    // if (!orgWithPermission) return;
    // return orgWithPermission.permissionScope >= Enums.PermissionScope.CONTRIBUTOR;
  };

  canEditOrg = () => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);

    // if (!orgWithPermission) return false;

    // return orgWithPermission.permissionScope >= Enums.PermissionScope.OWNER;
  };

  canContributeOrg = () => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);

    // if (!orgWithPermission) return false;

    // return orgWithPermission.permissionScope >= Enums.PermissionScope.CONTRIBUTOR;
  };

  canViewOrg = () => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);
    // return orgWithPermission.permissionScope >= Enums.PermissionScope.READER;
  };

  canEditProject = (projectId: number) => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);
    // if (orgWithPermission && orgWithPermission.permissionScope >= Enums.PermissionScope.OWNER) {
    //   return true;
    // }

    // let project = orgContextModel.getProject(projectId);
    // if (!project) {
    //   return false;
    // }

    // if (!project.isPrivate) {
    //   return orgWithPermission.permissionScope >= Enums.PermissionScope.CONTRIBUTOR;
    // }

    // let permissions = project.permissions[0];
    // if (permissions) {
    //   return permissions.isOwner || permissions.canEdit;
    // }

    // return false;
  };

  canEditProgramme = (programmeId: number) => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);

    // if (orgWithPermission && orgWithPermission.permissionScope >= Enums.PermissionScope.OWNER) {
    //   return true;
    // }

    // let programme = orgContextModel.getProgramme(programmeId);
    // if (!programme) {
    //   return false;
    // }
    // if (!programme.isPrivate) {
    //   return orgWithPermission.permissionScope >= Enums.PermissionScope.CONTRIBUTOR;
    // }

    // let permissions = programme.permissions[0];
    // if (permissions) {
    //   return permissions.isOwner || permissions.canEdit;
    // }
    // return false;
  };

  canViewProject = (projectId: number) => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);

    // if (orgWithPermission && orgWithPermission.permissionScope >= Enums.PermissionScope.OWNER) {
    //   return true;
    // }

    // let project = orgContextModel.getProject(projectId);
    // if (!project) {
    //   return false;
    // }
    // if (!project.isPrivate) {
    //   return orgWithPermission.permissionScope >= Enums.PermissionScope.READER;
    // }

    // let permissions = project.permissions[0];
    // if (permissions) {
    //   return permissions.isOwner || permissions.canView;
    // }

    // return false;
  };

  canViewProgramme = (programmeId: number) => {
    const currentOrg: any = useCurrentOrganisation();
    if (currentOrg.scope === "OWNER" || currentOrg.scope === "ADMIN") {
      return true;
    }

    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);

    // if (orgWithPermission && orgWithPermission.permissionScope >= Enums.PermissionScope.OWNER) {
    //   return true;
    // }

    // let programme = orgContextModel.getProgramme(programmeId);
    // if (!programme) {
    //   return false;
    // }
    // if (!programme.isPrivate) {
    //   return orgWithPermission.permissionScope >= Enums.PermissionScope.READER;
    // }

    // let permissions = programme.permissions[0];
    // if (permissions) {
    //   return permissions.isOwner || permissions.canView;
    // }

    return false;
  };

  canView = () => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);
    // if (!orgWithPermission) return;
    // return orgWithPermission.permissionScope >= Enums.PermissionScope.READER;
  };

  shouldNotView = () => {
    return true;
    // let orgContextModel = AppService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    // let currentOrg = orgContextModel.getCurrentOrganisation();
    // let orgList = orgContextModel.organisationList;
    // let orgWithPermission = orgList.find(e => e.id === currentOrg.id);
    // if (!orgWithPermission) return;
    // return orgWithPermission.permissionScope < Enums.PermissionScope.READER;
  };
}
