import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { IDropdownModel } from "../../../../core/forms/controls/dropdown/IDropdownModel";
import moment from "moment";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import { RAG_STATUS_OPTIONS, PROGRESS_STATUS_OPTIONS } from "../../../../constants";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { gEntities } from "../../../../FlightPathEntities";

export const getActionFormFields = (
  actionTypeProvider: IActionTypeApi,
  projectProvider: IProjectsApi,
  projId: number,
  orgId: number,
  authUser: FP.Entities.IUser,
  impactProvider: IImpactsApi,
  impacts: Partial<gEntities.IImpact>[],
  action?: FP.Entities.IAction
): IFormFieldModel<any, any>[] => {
  let actualEndDate = {
    ...INIT_TEXT_FIELD,
    key: "actualEndDate",
    inputType: "hidden",
    value: action?.actualEndDate
  };

  const projectId = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projId,
    defaultValue: projId
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    label: <label htmlFor="name">{I18n.t("forms.actionName")} *</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.actionName"),
    fieldClassName: "col-12 col-lg-6",
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.actionName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: action?.name
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")} *</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: "col-12 col-lg-6",
    validate: function () {
      const self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.startDate")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      icon: "calendar",
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    value: action && moment(action.startDate)
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "initialEndDate",
    label: <label htmlFor="initialEndDate">{I18n.t("forms.endDate")} *</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: "col-12 col-lg-6",
    subscribeTo: ["startDate", "progressStatus", "actualEndDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      const sd = field.value;
      if (sd) {
        self.setDatepickerProps({
          isOutsideRange: day => {
            return day < sd;
          }
        });
      }
    },
    validate: function () {
      const self: IDatePickerModel = this;
      const startDate = this.channels.startDate.value;
      let res = true;

      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDate")}</ErrorMessage>;
        return false;
      }

      if (startDate > this.value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    componentProps: {
      icon: "calendar",
      datePickerProps: action && {
        isOutsideRange: day => {
          return day < moment(action.startDate);
        }
      }
    },
    extractValue: function () {
      const progress = this.channels.progressStatus.extractValue();
      this.channels.actualEndDate.value = this.value?.format(this.exportedDateFormat);
      if (!action || progress === Enums.ProgressStatus.NOT_STARTED) {
        this.key = "initialEndDate";
        return this.value?.format(this.exportedDateFormat);
      } else {
        this.key = "disableInitialDate";
        return this.channels.actualEndDate.value;
      }
    },
    value: action && moment(action.actualEndDate)
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")} *</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IDropdownModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.progressStatus")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12 col-lg-6",
    extractValue: function () {
      return this.value?.key;
    },
    value: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(
      e => e.key === action?.progressStatus
    )
  };

  const actionType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "actionTypeId",
    label: <label htmlFor={"actionTypeId"}>{I18n.t("forms.actionType")} *</label>,
    placeholder: I18n.t("placeholders.actionType"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await actionTypeProvider.getFilteredAsync({ filters: `organisationId==${orgId}` }, null, orgId);

      if (res) {
        const actionTypes = _.orderBy(res.payload, [a => a.name.toLowerCase()]);
        self.setOptions(actionTypes);
      }
    },
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.actionType")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    extractValue: function () {
      return this.value?.id;
    },
    searchAttribute: "name",
    fieldClassName: "col-12 col-lg-6",
    value: action?.actionType,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.actionType")}</p>
      </FormTooltip>
    )
  };

  const assignedTo: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "assigneeId",
    label: <label htmlFor="assigneeId">{I18n.t("forms.assignedUser")} *</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      disabled: !projId,
      icon: "user"
    },
    charInputNumber: 1,
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.assignedUser")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: "col-12 col-lg-6",
    onFocus: async function () {
      // TODO: in future assignees will be loaded through
      // the project base on their permission level
      const self: IAutocompleteModel = this;
      if (projId && self.options.length === 0) {
        const res = await projectProvider.getUsers(orgId, projId);

        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          self.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value && this.value.id;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    value: action && action.assignee,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.actionAssigneeDesc")}</p>
      </FormTooltip>
    )
  };

  const ragStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ragStatus",
    label: <label htmlFor={"ragStatus"}>{I18n.t("forms.ragStatus")} *</label>,
    placeholder: I18n.t("placeholders.selectRAGStatus"),
    subscribeTo: ["progressStatus"],
    onChannelFieldChanged: function (field) {
      let self: IAutocompleteModel = this;
      if (field.key === "progressStatus") {
        let val = field.extractValue();
        if (val === Enums.ProgressStatus.COMPLETED) {
          self.isHidden = true;
        } else {
          self.isHidden = false;
        }
      }
    },
    options: RAG_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={option => (
          <span>
            <RagStatus state={option.key} className="mr-2" />
            {option.label}
          </span>
        )}
      />
    ),

    validate: function () {
      const self: IDropdownModel = this;
      let progress = self.channels["progressStatus"].extractValue();

      // if progress is completed the validations rules should be ignored
      if (progress === Enums.ProgressStatus.COMPLETED) return true;

      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.ragStatus")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12 col-lg-6",
    extractValue: function () {
      const self: IDropdownModel = this;
      let progress = self.channels["progressStatus"].extractValue();
      if (progress === Enums.ProgressStatus.COMPLETED) return Enums.RagStatus.COMPLETE;

      return this.value?.key;
    },
    value: RAG_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(e => e.key === action?.ragStatus),
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.ragStatusDesc")}</p>
      </FormTooltip>
    )
  };

  const summary: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
    placeholder: I18n.t("placeholders.description"),
    fieldClassName: "col-12",
    value: action?.description
  };

  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: <label htmlFor="ownerId">{I18n.t("forms.actionOwner")} * </label>,
    placeholder: I18n.t("placeholders.selectUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      disabled: !projId,
      icon: "user"
    },
    charInputNumber: 1,
    fieldClassName: "col-12 col-lg-6",
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.actionOwner")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      if (projId && self.options.length === 0) {
        const res = await projectProvider.getUsers(orgId, projId);

        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          self.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    value: (action && action.owner) || authUser,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.actionOwnerDesc")}</p>
      </FormTooltip>
    )
  };

  const impactSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactSearch",
    label: <label htmlFor={"impactSearch"}>{I18n.t("forms.searchImpactsForAction")} </label>,
    placeholder: I18n.t("placeholders.searchImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search",
      disabled: !projId
    },
    onFocus: async function () {
      if (projId) {
        const self: IAutocompleteModel = this;
        this.componentProps.disabled = false;
        const res = await impactProvider.getFiltered(orgId, projId, {
          filters: `projectId==${projId},LifecycleStatus==0`
        });
        if (res?.payload) {
          const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
          self.setOptions(sortedImpacts);
        }
      }
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, item => {
        const lowerName = item.name.toLowerCase();
        const lowerRef = item.refNumber ? item.refNumber.toLowerCase() : "";
        return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: "col-12 col-lg-6",
    valueLabelFn: e => e.name
  };

  const impactListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "impacts",
    placeholder: I18n.t("placeholders.selectImpact"),
    subscribeTo: ["impactSearch"],
    onChannelFieldChanged: function (field) {
      const val = field.value;
      this.addItem(val);
      field.reset();
    },
    label: <label htmlFor={"impacts"}>{I18n.t("forms.selectedImpacts")}</label>,
    fieldClassName: "col-12 col-lg-6",
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: (e: FP.Entities.IImpact) => (
      <p className="mb-0 d-inline-block">
        {e.refNumber} - {e.name}
      </p>
    ),
    value: action?.impacts || impacts || []
  };

  const fields = [];
  fields.push(actualEndDate);
  fields.push(projectId);
  fields.push(name);
  fields.push(ragStatus);
  fields.push(summary);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(progressStatus);
  fields.push(actionType);
  fields.push(owner);
  fields.push(assignedTo);
  fields.push(impactSearch);
  fields.push(impactListing);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
