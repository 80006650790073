import _ from "lodash";
import React from "react";
import AppService from "../../../../../AppService";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FormTooltip } from "../../../../../components/ui/_forms/FormTooltip";
import { Services } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IRTEditorModel } from "../../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../../core/forms/controls/rteditor/RTEditor_model";
import { ISliderFieldModel } from "../../../../../core/forms/controls/slider/ISliderFieldModel";
import { INIT_SLIDER_FIELD } from "../../../../../core/forms/controls/slider/SliderField_init";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import { IImpactTypeApi } from "../../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { INIT_MULTISELECTOR } from "../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";

export const QuickImpactCreateFormFields = (
  impactGroupsProvider: IImpactGroupsApi,
  organisationId: number,
  projectId: number,
  isTestUser: boolean,
  selectedImpactGroups: string[], // FP.Entities.IImpactGroup[],
  impact?: FP.Entities.IQuickImpact
) => {
  const impactTypeProvider = AppService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.impactName"),
    label: <label htmlFor="name">{I18n.t("forms.impactName")} *</label>,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: "col-12",
    value: impact?.name
  };

  const description = isTestUser
    ? {
        ...INIT_TEXT_FIELD,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.description")} *</label>,
        validate: function () {
          const self: IRTEditorModel = this;
          let res = true;
          if (Validations.IS_EMPTY(self.extractValue())) {
            self.errorMessage = <ErrorMessage>{I18n.t("validations.impactDescription")}</ErrorMessage>;
            res = false;
          }

          return res;
        },
        fieldClassName: "col-12",
        value: impact?.description
      }
    : {
        ...INIT_RTEDITOR,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
        fieldClassName: "col-12",
        value: impact?.description
      };

  const baseSlider: Partial<ISliderFieldModel> = {
    ...INIT_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Enums.Translator.ImpactLevel(value),
      defaultValue: 1,
      min: 1,
      max: 10,
      marks: true
    }
  };

  const impactLevel: Partial<ISliderFieldModel> = {
    ...baseSlider,
    key: "impactLevel",
    label: <label htmlFor="impactLevel">{I18n.t("forms.impactLevel")}</label>,
    fieldClassName: "col-12",
    validate: function () {
      const self: ISliderFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactLevel")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: impact?.impactLevel,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactLevel")}</p>
      </FormTooltip>
    ),
    defaultValue: 0
  };

  const impactType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactType",
    label: <label htmlFor={"impactType"}>{I18n.t("forms.impactType")} *</label>,
    placeholder: I18n.t("placeholders.selectImpactType"),
    onFocus: async function (model: IAutocompleteModel) {
      const res = await impactTypeProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `lifecycleStatus==0,organisationId==${organisationId}`
      });
      if (!res.payload) return;
      const impactTypes = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(impactTypes);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactType")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col",
    extractValue: function () {
      return this.value?.id;
    },

    valueLabelFn: e => e?.name,
    value: impact?.nImpactType,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactType")}</p>
      </FormTooltip>
    )
  };

  const isExistingImpact = ():boolean =>
    impact !== null && impact !== undefined;

  const shouldShowAutoPopulateTooltip = ():boolean => 
    isExistingImpact() ? false : selectedImpactGroups?.length > 0;

  const impactGroups: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroups",
    placeholder: I18n.t("placeholders.selectImpactGroup"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    manageLink: `/organisations/${organisationId}/projects/${projectId}/impactGroups`,
    label: <label htmlFor="impactGroups">{I18n.t("forms.impactGroup")}</label>,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await impactGroupsProvider.getFilterList(organisationId, projectId);

      if (res?.payload) {
        self.setOptions(res.payload);
      }
    },
    componentProps: {
      icon: "search"
    },
    searchAttribute: "name",
    fieldClassName: "col-lg-12",
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact == null ? selectedImpactGroups : impact?.impactGroups,
    valueLabelFn: e => e?.name,
    lowerTooltipLabel: I18n.t("forms.prepopulateImpactGroupsEnabled"),
    lowerTooltipContent: (shouldShowAutoPopulateTooltip() && I18n.t("forms.prepopulateImpactGroupsHint"))
  };



  const fields = [];
  fields.push(name);
  fields.push(impactType);
  fields.push(impactGroups);
  fields.push(impactLevel);
  fields.push(description);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
