import * as React from 'react';
import { Enums } from '../../../../enums';
import I18n from '../../../../core/localization/I18n';
import { TargetLabel } from '../../../../components/widgets/TargetLabel';
import { Animations } from '../../../../core/util/Animations';
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface ProjectStakeholderCardProps {
    item: FP.Entities.IProjectStakeholder;
}

const ProjectStakeholderCard: React.FunctionComponent<ProjectStakeholderCardProps> = ({ item }) => {
    return <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row">
            <div className="col-lg-4 mb-5">
                <h4 className="mb-2">{I18n.t("table.isKeyStakeholder")}</h4>
                <div id="IsKeyStakeholderLabel">{I18n.t(item.isKeyStakeholder ? "phrases.yes" : "phrases.no")}</div>
            </div>

            <div className="col-lg-4 mb-5">
                <h4 className="mb-2">{I18n.t("table.impact")}</h4>
                <div>{Enums.Translator.Metric(item.impact)}</div>
            </div>

            <div className="col-lg-4 mb-5">
                <h4 className="mb-2">{I18n.t("table.influence")}</h4>
                <div>{Enums.Translator.Metric(item.influence)}</div>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-4 mb-5">
                <h4 className="mb-2">{I18n.t("table.currentSentiment")}</h4>
                <TargetLabel
                    target={item.targetSentiment}
                    current={item.sentiment}
                    onTarget={item.sentiment >= item.targetSentiment}
                    text={Enums.Translator.SentimentLevel(item.sentiment)}
                    labelModifier={Enums.Translator.SentimentLevel}
                />
            </div>

            <div className="col-lg-4 mb-5">
                <h4 className="mb-2">{I18n.t("table.currentCommitment")}</h4>
                <TargetLabel
                    target={item.targetCommitment}
                    current={item.commitment}
                    onTarget={item.commitment >= item.targetCommitment}
                    text={Enums.Translator.CommitmentLevel(item.commitment)}
                    labelModifier={Enums.Translator.CommitmentLevel}
                />
            </div>

            <div className="col-lg-4 mb-5">
                <h4 className="mb-2">{I18n.t("table.currentReceptiveness")}</h4>
                <TargetLabel
                    target={item.targetReceptiveness}
                    current={item.receptiveness}
                    onTarget={item.receptiveness >= item.targetReceptiveness}
                    text={Enums.Translator.ReceptivenessLevel(item.receptiveness)}
                    labelModifier={Enums.Translator.ReceptivenessLevel}
                />
            </div>
        </div>

        <div className="row">
            <div className="col-12 mb-5">
                <h4 className="mb-2">{I18n.t("table.reasonForResistance")}</h4>
                {item.resistanceReason ?
                    <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.resistanceReason) }}></div>
                    :
                    <div>{I18n.t("phrases.noReasonForResistance")}</div>
                }
            </div>
        </div>
    </div>
}

export { ProjectStakeholderCard };