import * as React from "react";
import "react-dates/initialize";
import { SingleDatePicker as SDP, SingleDatePickerShape } from "react-dates";
import * as moment from "moment";
import { Icon } from "../../Icon";
import { B } from "../../../../core/util/BootstrapHelper";
import { Enums } from "../../../../enums";

export interface SingleDatePickerProps {
  icon?: string;
  datePickerProps?: Partial<SingleDatePickerShape>; //, "onFocusChange" | "focused">
}

const SingleDatePicker: React.FunctionComponent<SingleDatePickerProps> = (props: any) => {
  const { datePickerProps } = props;
  const [focused, setFocused] = React.useState(datePickerProps.focused);
  const cls = B().bl("ui-input");

  return props.icon ? (
    <div className="ui-input z-index-high">
      <SDP
        id={datePickerProps.id}
        date={datePickerProps.date}
        onDateChange={datePickerProps.onDateChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        displayFormat={datePickerProps.displayFormat || (() => moment.localeData("en-gb").longDateFormat("L"))}
        {...datePickerProps}
      />
      <Icon className={cls.el("icon").bem} symbol={props.icon} size={Enums.UiSizes.MD} />
    </div>
  ) : (
    <SDP
      id={datePickerProps.id}
      date={datePickerProps.date}
      onDateChange={datePickerProps.onDateChange}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      displayFormat={datePickerProps.displayFormat || (() => moment.localeData("en-gb").longDateFormat("L"))}
      {...datePickerProps}
    />
  );
};

export { SingleDatePicker };
