import * as React from 'react';
import { observable } from 'mobx';
import { FormFieldModel } from '../../formField/FormField_model';
import { RadioButtonListProps, IRadioButtonOption, RadioButtonList } from '../../../../components/ui/_forms/RadioButtons';
import { IRadioButtonListModel } from './IRadioButtonsModel';
import { FormFieldType } from '../../formField/FormFieldTypes';
import { INIT_FORM_FIELD } from '../../formField/FormField_init';


export const INIT_RADIOBUTTONLIST: Partial<IRadioButtonListModel> = {
    ...INIT_FORM_FIELD,
    type: FormFieldType.RadioButtonList
}

export class RadioButtonListModel extends FormFieldModel<string, RadioButtonListProps>
    implements IRadioButtonListModel {
    @observable value: string;
    @observable options: IRadioButtonOption[];
    readonly type: FormFieldType = FormFieldType.RadioButtonList;

    constructor(initOpts?: IRadioButtonListModel) {
        super(initOpts);
        if (initOpts) {
            this.value = initOpts.value || this.value;
            this.options = initOpts.options || this.options;
            this.validate = initOpts.validate || INIT_RADIOBUTTONLIST.validate;
            this.extractValue = initOpts.extractValue || this.extractValue;
        }
    }

    validate: (...args: any[]) => any;

    setValue(val: string): void {
        this.value = val;
    }

    setFieldValue(val: any): void {
        throw new Error("Method not implemented.");
    }

    extractValue = () => {
        return this.value;
    }

    reset(): void {
        this.value = this.defaultValue || null;
    }

    renderComponent = (): React.ReactNode => {
        return <RadioButtonList
            id={this.key}
            disabled={this.disabled}
            onItemClick={(item: IRadioButtonOption) => this.setValue(item.inputProps.value as string)}
            options={this.options}
            value={this.value}
        />
    };

}