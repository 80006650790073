import moment from "moment";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { Async } from "react-async";
import { AppService } from "strikejs-app-service";
import { useAppService } from "../../../../../AppService";
import { ButtonIcon, LinkButton } from "../../../../../components/ui/Button";
import { Panel } from "../../../../../components/ui/Panel";
import { Pill } from "../../../../../components/ui/Pill";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Services } from "../../../../../constants";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { B } from "../../../../../core/util/BootstrapHelper";
import { getStatusAccentFromImpactLevel, Translator, UiSizes } from "../../../../../enums";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { getRagStatus, SmallRagStatus } from "../impactsOverTime/ImpactDetailedView";
import { ImpactReportGroupTypes } from "./ImpactsByProjects_model";
import { RenderXssSafeString } from "../../../../../core/AntiXss/AntiXssHelper";

export interface ImpactsByProjectsCompactViewProps {
  impactReportGroupType: ImpactReportGroupTypes;
  projectId: number;
  projectName: string;
  modalService: IModalService;
  status: string;
  resourceId: number;
  resourceName: string;
  onUnmount: () => void;
}

export const ImpactsByProjectsCompactView: React.FC<ImpactsByProjectsCompactViewProps> = (props) => {
  const { projectId, projectName, status, modalService, resourceId, resourceName, impactReportGroupType, onUnmount } = props;
  const appService = useAppService();
  const dataProvider = getDataFunction(impactReportGroupType, appService);
  const currentOrganisationId = useCurrentOrganisationId();

  const load = useCallback(async () => {
    const resp = await dataProvider(currentOrganisationId, projectId, resourceId)
    return resp.payload;
  }, [dataProvider, currentOrganisationId, projectId, resourceId])

  useEffect(() => {
    return onUnmount;
  }, [onUnmount]);

  return <Async promiseFn={load}>
    <Async.Loading>
      <PositionedSpinner />
    </Async.Loading>
    <Async.Rejected>{err => err.message}</Async.Rejected>
    <Async.Resolved>
      {(payload) => <ImpactsByProjectsCompactViewContent
        modalService={modalService}
        resourceName={resourceName}
        projectId={projectId}
        resourceId={resourceId}
        impactReportGroupType={impactReportGroupType}
        projectName={projectName}
        status={status}
        organisationId={currentOrganisationId}
        impacts={payload as FP.Entities.IImpact[]}
      />}
    </Async.Resolved>
  </Async>
}

const ImpactsByProjectsCompactViewContent: React.FC<{
  impacts: FP.Entities.IImpact[];
  modalService: IModalService;
  resourceName: string;
  organisationId: number;
  projectId: number;
  resourceId: number;
  impactReportGroupType: ImpactReportGroupTypes;
  projectName: string;
  status: string;
}> = ({ impacts, status, organisationId, modalService, resourceName, impactReportGroupType, projectName, projectId, resourceId }) => {

  const pillType: any = `outline-${status.toLowerCase()}`;
  const cls = B().bl("project-info-card");
  return <>
    <div >
      <ButtonIcon
        symbol="Close"
        className="float-right"
        type="outline-primary"
        onClick={() => {
          modalService.hide();
        }}
      />
      <LinkButton type="primary"
        onClick={() => modalService.hide()}
        href={`/organisations/${organisationId}/impact-report/impact-by-project/${impactReportGroupType}/${projectId}/${resourceId}`}
        className="mr-3 float-right">
        {I18n.t("phrases.viewTimeline")}
      </LinkButton>
    </div>
    <br />
    <br />
    <br />
    <br />
    <div className="row">
      <div className="col">
        <h2>{projectName}</h2>
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <h4>{getTitle(impactReportGroupType)}</h4>
        <div>{resourceName}</div>
      </div>
      <div className="col-6">
        <h4>{I18n.t("forms.ragStatus")}</h4>
        <div>
          <Pill
            type={pillType}
            size={UiSizes.MD}>
            {status}
          </Pill>
        </div>
      </div>
    </div>
    <br />
    <br />
    <h4>{I18n.t("entities.impacts")}</h4>
    {
      impacts.length === 0 ? getNoImpactsText(impactReportGroupType) :
        impacts.map(item => {
          return (
            <Panel
              hasShadow={true}
              type="border-left-primary"
              className={`${cls.bem}`}
              background="bg-white"
              hasBorderRadius={true}
              style={{ minWidth: "320px" }}
            >
              <div className="row mb-2">
                <div className="col-8">
                  <div>
                    <LinkButton
                      type="link"
                      style={{ padding: "0px" }}
                      href={`/organisations/${organisationId}/projects/${item.projectId}/impacts/${item.id}`}
                    >
                      <h4
                        className="mb-1 strong d-inline-block"
                        onClick={() => {
                          modalService.hide();
                        }}
                      >
                        {item.refNumber || "No ref"}{" "}
                        <SmallRagStatus state={getRagStatus(item.impactLevel)} className="mt-1" />
                      </h4>
                    </LinkButton>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.name) }}></div>
                </div>
                <div className="col-4">
                  <Pill type={getStatusAccentFromImpactLevel(item.impactLevel)} className="text-nowrap">
                    {Translator.Metric(item.impactLevel)}
                  </Pill>
                </div>
              </div>

              <div className="row">
                <div className="col-8">
                  <span>
                    {item.startDate && moment(item.startDate).format("DD/MM/yyyy")} -{" "}
                    {item.actualEndDate && moment(item.actualEndDate).format("DD/MM/yyyy")}
                  </span>
                </div>
              </div>
            </Panel>
          );
        })}
  </>

}

const getTitle = (impactReportGroupType: ImpactReportGroupTypes) => {
  switch (impactReportGroupType) {
    case ImpactReportGroupTypes.BUSINESS_AREAS:
      return I18n.t("entities.businessArea");
    case ImpactReportGroupTypes.LOCATIONS:
      return I18n.t("entities.location");
    case ImpactReportGroupTypes.STAKEHOLDERS:
      return I18n.t("entities.stakeholder");

    default:
      break;
  }
}

const getNoImpactsText = (impactReportGroupType: ImpactReportGroupTypes) => {
  switch (impactReportGroupType) {
    case ImpactReportGroupTypes.BUSINESS_AREAS:
      return I18n.t("phrases.noImpactsBusinessArea");
    case ImpactReportGroupTypes.LOCATIONS:
      return I18n.t("phrases.noImpactsLocation");
    case ImpactReportGroupTypes.STAKEHOLDERS:
      return I18n.t("phrases.noImpactsStakeholder");

    default:
      break;
  }
}

const getDataFunction = (impactReportGroupType: ImpactReportGroupTypes, appService: AppService) => {
  const orgasationProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi)
  switch (impactReportGroupType) {
    case ImpactReportGroupTypes.BUSINESS_AREAS:
      return orgasationProvider.getImpactReportByBusinessAreaDetails;
    case ImpactReportGroupTypes.LOCATIONS:
      return orgasationProvider.getImpactReportByLocationDetails;
    case ImpactReportGroupTypes.STAKEHOLDERS:
      return orgasationProvider.getImpactReportByStakeholderDetails;

    default:
      break;
  }
}