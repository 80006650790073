import React, { useEffect, useState } from "react";
import { ImpactViewModel } from "./ImpactsView_model";
import { Button, ButtonIcon, LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { TableView } from "../../../../core/table/Table_view";
import { observer } from "mobx-react";
import { DonutInfo } from "../../../../components/widgets/donutInfo/DonutInfo_view";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { Pagination } from "../../../../components/widgets/pagination/Pagination_view";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { SearchButton } from "../../../../components/ui/SearchButton";
import { Enums, ImpactLevel } from "../../../../enums";
import { ImpactTimeline } from "../../../../components/widgets/impactTimeline/ImpactTimeline_view";
import { useAppService } from "../../../../AppService";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ImpactsViewProps {
  model?: ImpactViewModel;
}

const ImpactsView: React.FunctionComponent<ImpactsViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(() => m || new ImpactViewModel(appService, !!projectId ? +projectId : null, organisationId));
  const modelProjectId = model.projectId;

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ImpactViewModel(appService, !!projectId ? +projectId : null, organisationId));
    }
    model.onMount();
    return model.onUnmount;
  }, [projectId, modelProjectId, organisationId, appService, model]);

  const searchValue = model.filterModel.getFilter("name").value;
  return (
    <div className="impacts-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>
              {model.project?.name} | <strong>{I18n.t("phrases.impacts")}</strong>
            </h1>
            <p className="mb-5">{I18n.t("phrases.impactViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary mb-5"
        ulClassName="tabs__menu--primary"
        initialTab={0}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.individualImpact")}</h2>}></Tab>
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.impactGroups")}</h2>}></Tab>
        {model.showVisualisations === true && (
          <Tab title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}></Tab>
        )}
      </Tabs>
      <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-5">
          <div className="col">
            <CanEditProject projectId={model.projectId}>
              <LinkButton
                id="CreateImpactButton"
                className="mr-2 float-left"
                href={`/organisations/${organisationId}/projects/${model.projectId}/impacts/create`}>
                <Icon symbol="plus" />
                <span className="ml-2">{I18n.t("phrases.createImpact")}</span>
              </LinkButton>
              <Button
                id="CreateDraftImpactButton"
                onClick={e => model.quickImpactModel.showCreateModal()}>
                <Icon symbol="plus" />
                <span className="ml-2">{I18n.t("phrases.quickCreateImpact")}</span>
              </Button>
            </CanEditProject>

            <SearchButton
              onChange={model.onSearchChange}
              value={searchValue.length > 0 ? searchValue[0] : ""}
              className={"float-right ml-2"}
              onClose={model.resetNameFilter}
              buttonType="outline-primary"
            />
            <Button
              className={"float-right ml-2"}
              type="outline-primary"
              onClick={model.exportService.showSelectionModal}
            >
              <Icon symbol="download" className="mr-2" />
              {I18n.t("phrases.export")}
            </Button>
            <Button
              className={"float-right ml-2"}
              type="outline-primary"
              onClick={model.filterModel.showFilterFormModal}
            >
              <Icon symbol="filter" className="mr-2" />
              {I18n.t("phrases.applyFilters")}
            </Button>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              {model.generalInfo?.map((e, i) => {
                return (
                  <div
                    onClick={() => model.updateImpactFilter(e.key as ImpactLevel)}
                    className={`${Animations.ZOOM_IN} speed-1 delay-${i}`}
                    key={i}
                  >
                    <DonutInfo model={e} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <ButtonIcon
              className={"float-right ml-2"}
              type={model.currentListView === "timeline" ? "primary" : "link"}
              symbol="timeline"
              iconSize={Enums.UiSizes.SM}
              onClick={() => model.changeCurrentListView("timeline")}
            />
            <ButtonIcon
              className={"float-right ml-2"}
              type={model.currentListView === "list" ? "primary" : "link"}
              symbol="list"
              iconSize={Enums.UiSizes.SM}
              onClick={() => model.changeCurrentListView("list")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {model.currentListView === "timeline" ? (
              <div className="mb-4">
                <ImpactTimeline model={model.impactTimelineModel} />
              </div>
            ) : (
              <>
                <TableView model={model.tableModel} />
                <Pagination model={model.paginationModel} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { ImpactsView };
