import * as React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";

export interface PillProps extends React.HTMLAttributes<HTMLDivElement> {
  type?:
  | "primary"
  | "secondary"
  | "tertiary"
  | "outline-primary"
  | "outline-secondary"
  | "outline-tertiary"
  | "indicator-accent-1"
  | "indicator-accent-2"
  | "indicator-accent-3"
  | "outline-green"
  | "outline-amber"
  | "outline-red";
  size?: UiSizes;
  onClick?(e: React.SyntheticEvent): void;
  style?: React.CSSProperties;
  className?: string;
}

const Pill: React.FunctionComponent<PillProps> = props => {
  const { type, size, onClick, children } = props;
  const cls: string = B()
    .bl("pill")
    .pmod(type || "primary")
    .pmod(size || UiSizes.SM).bem;

  return (
    <div {...props} className={`${cls} ${props.className || ""}`} onClick={onClick} style={props.style}>
      {children}
    </div>
  );
};

export { Pill };
