import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IStakeholdersApi } from "./IStakeholders.api";
import { IHttp } from "../../IHttp";
import { AxiosRequestConfig } from "axios";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { ExportableOrganisationApi } from "../../exportableApi/ExportableOrganisationApiModel";

export class StakeholdersApi extends ExportableOrganisationApi<FP.Entities.IStakeholder> implements IStakeholdersApi {
  controller: string = "stakeholders";

  constructor(http: IHttp) {
    super(http, "stakeholders");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getDetailedById = async (organisationId: number, stakeholderId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/stakeholders/${stakeholderId}/detailed`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholder>;
    }
  };

  //#endregion CRUD

  bulkUpload = async (
    organisationId: number,
    stakeholders: any[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.put(this.url + `/${organisationId}/stakeholders/bulk-change-roles`, stakeholders, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  //#region ProjectStakeholder

  getDetailedProjectsById = async (organisationId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${window.appConfig.flightPathCoreApiUrl}/api/v1/organisations/${organisationId}/stakeholders/${id}/assigned-projects`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>;
    }
  };
  //#endregion
}
