import * as React from "react";
import AnimateHeight from "react-animate-height";

export interface AccordionCollapseProps {
  children: React.ReactNode;
  isDynamicallyLoaded?: boolean;
  isOpen: boolean;
}

export class AccordionCollapse extends React.Component<AccordionCollapseProps> {
  private containerRef: React.RefObject<HTMLDivElement> | any;
  private contentRef: React.RefObject<HTMLDivElement>;
  private timeout: any;

  constructor(props: any) {
    super(props);
    this.containerRef = React.createRef();
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    this.onResize();
  }

  componentDidUpdate() {
    this.onResize();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getSnapshotBeforeUpdate() {
    if (!this.containerRef || !this.contentRef) {
      return null;
    }
    if (this.containerRef.current.style.height === "auto") {
      const { clientHeight: contentHeight } = this.contentRef.current as HTMLDivElement;
      this.containerRef.current.style.height = `${contentHeight}px`;
    }
    return null;
  }

  onResize = () => {
    clearTimeout(this.timeout);

    if (!this.containerRef || !this.contentRef) {
      return;
    }

    const { isOpen } = this.props;
    const { clientHeight: containerHeight } = this.containerRef.current;
    const { clientHeight: contentHeight } = this.contentRef.current as HTMLDivElement;

    const isFullOpen = isOpen && contentHeight === containerHeight;
    const isFullClosed = !isOpen && containerHeight === 0;

    if (isFullOpen || isFullClosed) {
      this.onRest({ isFullOpen, isFullClosed, isOpen, containerHeight, contentHeight });
    } else {
      this.onWork({ isFullOpen, isFullClosed, isOpen, containerHeight, contentHeight });
      this.timeout = setTimeout(() => this.onResize(), 50);
    }
  };

  onRest = ({ isFullOpen, isFullClosed, isOpen, containerHeight, contentHeight }: any) => {
    if (!this.containerRef || !this.contentRef) {
      return;
    }

    const opened = isOpen && this.containerRef.current.style.height === `${contentHeight}px`;
    const closed = !isOpen && this.containerRef.current.style.height === "0px";

    if (opened || closed) {
      this.containerRef.current.style.height = isOpen ? "auto" : "0px";
    }
  };

  onWork = ({ isFullOpen, isFullClosed, isOpen, containerHeight, contentHeight }: any) => {
    if (!this.containerRef || !this.contentRef) {
      return;
    }

    const opened = isOpen && this.containerRef.current.style.height === `${contentHeight}px`;
    const closed = !isOpen && this.containerRef.current.style.height === "0px";

    if (opened || closed) {
      return;
    }

    this.containerRef.current.style.height = isOpen ? `${contentHeight}px` : "0px";
  };

  render() {
    const { children, isOpen } = this.props;

    return (
      <div ref={this.containerRef} className="accordion-item__content">
        <div ref={this.contentRef}>
          {this.props.isDynamicallyLoaded ? (
            <AnimateHeight height={isOpen ? "auto" : 0} duration={400} animateOpacity={true}>
              {isOpen && children}
            </AnimateHeight>
          ) : (
            <AnimateHeight height={isOpen ? "auto" : 0} duration={400} animateOpacity={true}>
              {children}
            </AnimateHeight>
          )}
        </div>
      </div>
    );
  }
}
