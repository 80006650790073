import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../../constants";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IMilestonesApi } from "../../../../../services/api/v1/milestones/IMilestones.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getFormFields } from "../../../forms/milestone/MilestoneFormSection_data";
import I18n from "../../../../../core/localization/I18n";

export class MilestoneModalModel extends BaseModel {
  appService: AppService;
  formModel: SingleFormModel;
  modalService: IModalService;
  projectId: number;
  projectProvider: IProjectsApi;
  milestoneProvider: IMilestonesApi;
  milestone: FP.Entities.IMilestone;
  organisationId: number;

  constructor(appService: AppService, organisationId: number, milestone?: FP.Entities.IMilestone, closeFn?: Function, projectId?: number) {
    super();
    this.projectId = projectId;
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.milestoneProvider = this.appService.getService<IMilestonesApi>(Services.MilestonesApi);
    this.milestone = milestone;

    this.formModel = new SingleFormModel();
    this.formModel.formFields = getFormFields(this.milestone);
    this.formModel.actions = [
      {
        id: "no",
        label: I18n.t("phrases.cancel"),
        onAction: () => {
          this.modalService.hide();
        },
        rendersIn: "Button",
        componentProps: {
          type: "link",
          className: "ml-auto"
        }
      },
      {
        id: "CreateMilestoneButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let milestoneFormEl = await this.formModel.submit();
          if (milestoneFormEl) {
            let formResult = {
              ...milestoneFormEl
            };

            formResult.projectId = projectId || (this.milestone ? this.milestone.projectId : 0);

            let res;
            if (this.milestone) {
              res = await this.milestoneProvider.updateProjectMilestone(
                this.organisationId,
                this.projectId,
                this.milestone.id,
                formResult as FP.Entities.IMilestone
              );
            } else {
              res = await this.milestoneProvider.createProjectMilestone(
                this.organisationId,
                this.projectId,
                formResult as FP.Entities.IMilestone
              );
            }

            if (res) {
              closeFn && closeFn();
              this.modalService.hide();
            }
          }
        },
        rendersIn: "Button",
        componentProps: {
          type: "primary",
          className: "ml-2"
        }
      }
    ];
  }

  onMount = () => { };

  onUnmount = () => { };
}
