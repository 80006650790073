import * as React from "react";
import { ActionCompactViewModel } from "./ActionCompactView_model";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { ActionDisplay } from "../ActionDisplay";
import I18n from "../../../../core/localization/I18n";
import { ImpactCard } from "../../impacts/impactCard/ImpactCard_view";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export interface ActionCompactViewProps {
  model: ActionCompactViewModel;
}

const ActionCompactView: React.FunctionComponent<ActionCompactViewProps> = observer(({ model }) => {
  const { actionObject } = model;
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="action-compact-view container-fluid">
      <div className="row mb-4">
        <div className="col">
          <h2 className="mb-0">
            <RagStatus state={actionObject.ragStatus} />
            <span className="mx-3 h2-secondary font-weight-bold">{actionObject.refNumber}</span>
            {actionObject.name}
          </h2>
        </div>
      </div>

      <ActionDisplay item={actionObject} parentModel={model} />

      <div className="row pb-4">
        <div className="col">
          <h4 className="mb-2">{I18n.t("phrases.relatedImpacts")}</h4>
          {actionObject.impacts?.map((impact: FP.Entities.IImpact) => (
            <Link
              key={impact.id}
              to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}
              onClick={model.modalService.hide}
            >
              <ImpactCard key={impact.id} item={impact} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});

export { ActionCompactView };
