import React, { useEffect } from "react";
import { EditImpactViewModel } from "./EditImpactView_model";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { ImpactFormSectionView } from "../../forms/impact/ImpactFormSection_view";
import { Enums } from "../../../../enums";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export interface EditImpactViewProps {
  model: EditImpactViewModel;
}

const EditImpactView: React.FunctionComponent<EditImpactViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="edit-impact-view pt-6">
      {!!model.projectId && (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col">
              <LinkButton
                size={Enums.UiSizes.MD}
                type="link-big"
                className="p-0"
                href={`/organisations/${organisationId}/projects/${model.projectId}/impacts/${model.impactId}`}
              >
                <Icon className="mr-2" symbol="ChevronLeft" />
                {I18n.t("phrases.backToImpact")}
              </LinkButton>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>{I18n.t("phrases.editImpact")}</h1>
          </div>
          <div className="col-lg-7 mb-3">
            <p>{I18n.t("phrases.editImpactDescription")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-10">
            <ImpactFormSectionView model={model.formModel} />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-10">
            <div className="d-flex">
              <LinkButton
                type="link"
                className="ml-auto"
                href={`/organisations/${organisationId}/projects/${model.projectId}/impacts/${model.impactId}`}
              >
                {I18n.t("phrases.cancel")}
              </LinkButton>
              <Button onClick={model.updateImpact} className="ml-2">
                {I18n.t("phrases.save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { EditImpactView };
