import { IProjectStakeholdersApi } from "./IProjectStakeholders.api";
import { IHttp } from "../../IHttp";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";

export class ProjectStakeholdersApi
  extends ExportableProjectApi<FP.Entities.IProjectStakeholder>
  implements IProjectStakeholdersApi {
  controller: string = "stakeholders";

  constructor(http: IHttp) {
    super(http, "stakeholders");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  //#region CRUD

  async getStakeholdersOnly(organisationId: number, projectId: number): Promise<IFlightPathApiResponse<any[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/thin`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  }

  async getStakeholders(
    organisationId: number,
    projectId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>;
    }
  }
  async getStakeholderById(
    organisationId: number,
    projectId: number,
    stakeholderId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/${stakeholderId}`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder>;
    }
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number,
    projectId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  async getDeletedStakeholder(
    organisationId: number,
    projectId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/${stakeholderId}/deleted`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder>;
    }
  }

  //#endregion CRUD

  //#region Impact
  addImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/impact/${projectStakeholderId}`;
    let res = await this.http.post(url, impactIds, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/impact/${projectStakeholderId}/${impactId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  getUnassignedImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/unassignedImpacts/${projectStakeholderId}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  getStakeholderAndAudienceCountsForProject = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderAudienceCounts>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholders/counts`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderAudienceCounts>;
    }
  }

  //#endregion
}
