import * as React from "react";
import { useIsTestUser } from "../../core/auth/authorise";

const CanCreateOrganisation: React.FunctionComponent<any> = props => {
  const isTestUser = useIsTestUser();
  const isDevelopmentEnvironment = IsDevelopmentEnvironment();
  return (isTestUser && isDevelopmentEnvironment) ? props.children : null;
};

const IsDevelopmentEnvironment = () => process.env.NODE_ENV === "development";

export { CanCreateOrganisation };