import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { Services } from "../../../../constants";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import {
  getTopFormFields,
  getRadioFields,
  getBottomForm,
  getProjectFormField
} from "../../forms/impact/ImpactFormSection_data";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import I18n from "../../../../core/localization/I18n";
import { observable, action } from "mobx";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IModalService } from "../../../../core/modal/IModalService";
import { Icon } from "../../../../components/ui/Icon";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";

export class CreateImpactViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps<any, any, any>;
  organisationProvider: IOrganisationsApi;
  impactProvider: IImpactsApi;
  projectProvider: IProjectsApi;
  httpProgress: IHttpProgressModel;
  cookieService: ICookieService;
  modalService: IModalService;
  stakeholderGroupsProvider: IStakeholderGroupsApi;
  orgId: number;
  toasterService: IToasterService;
  @observable projectId: number;
  @observable.ref projectForm: SingleFormModel;
  @observable.ref topForm: SingleFormModel;
  @observable.ref middleForm: SingleFormModel;
  @observable.ref bottomForm: SingleFormModel;
  projectStakeholdersProvider: any;
  isTestUser: boolean;
  impactGroupsProvider: IImpactGroupsApi;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps<any, any, any>, isTestUser: boolean) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.isTestUser = isTestUser;
    this.projectId = parseInt(this.routeProps.match.params["id"], 10);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.projectStakeholdersProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.stakeholderGroupsProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.orgId = parseInt(this.cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID));
    this.impactGroupsProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);

    if (this.projectId) {
      this.setForms(this.projectId);
    } else {
      this.projectForm = new SingleFormModel();
      this.projectForm.formFields = getProjectFormField(this.organisationProvider, this.orgId, this.setForms);
    }
  }

  @action
  setForms = (projectId: number) => {
    this.projectId = projectId;
    let impact: any = {};
    if (this.topForm && this.middleForm && this.bottomForm) {
      impact = {
        ...this.topForm.getFormKeyValueData(),
        ...this.middleForm.getFormKeyValueData(),
        ...this.bottomForm.getFormKeyValueData()
      };

      impact.progressStatus = impact.progressStatus?.key;
      impact.impactType = impact.impactType?.key;

      delete impact.stakeholderGroups;
      delete impact.projectStakeholders;
      delete impact.stakeholderSearch;
    }

    this.topForm = new SingleFormModel();
    this.topForm.formFields = getTopFormFields(projectId, this.isTestUser, impact as FP.Entities.IImpact);

    this.middleForm = new SingleFormModel();
    this.middleForm.formFields = getRadioFields(this.orgId, impact as FP.Entities.IImpact);

    this.bottomForm = new SingleFormModel();
    this.bottomForm.formFields = getBottomForm(
      this.organisationProvider,
      this.orgId,
      this.projectProvider,
      projectId,
      this.projectStakeholdersProvider,
      this.stakeholderGroupsProvider,
      this.impactGroupsProvider,
      impact as FP.Entities.IImpact
    );
    this.bottomForm.actions = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: async () => {
          let res = await this.createImpact();
          if (res) {
            this.toasterService
              .showSuccessToast()
              .setContent(<p>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.impact") })}</p>)
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
            this.topForm.resetFields();
            this.middleForm.resetFields();
            this.bottomForm.resetFields();
          }
        },
        rendersIn: "Button",
        componentProps: {
          type: "outline-primary",
          className: "ml-auto"
        }
      },
      {
        id: "CreateImpactButton",
        label: I18n.t("phrases.createImpact"),
        onAction: async () => {
          let impact = await this.createImpact();
          if (impact) {
            this.routeProps.history.push(
              `/organisations/${this.orgId}/projects/${impact.projectId}/impacts/${impact.id}`
            );
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
  };

  onMount = () => { };

  onUnmount = () => { };

  createImpact = async () => {
    const topFormRes = await this.topForm.submit();
    const middleFormRes = await this.middleForm.submit();
    const bottomFormRes = await this.bottomForm.submit();
    if (!topFormRes || !middleFormRes || !bottomFormRes) return;

    let impactExists = await this.impactProvider.getFiltered(
      this.orgId,
      this.projectId,
      {
        filters: `name==${topFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
      },
    );
    if (impactExists && !impactExists.isError && impactExists.payload.length) {
      let confirmCreateImpact = await this.confirmCreateImpact(topFormRes.name);
      if (!confirmCreateImpact) return;
    }

    const res = {
      ...topFormRes,
      ...middleFormRes,
      ...bottomFormRes
    };

    this.httpProgress.showOverlay();
    const result = await this.impactProvider.create(this.orgId, this.projectId, res as FP.Entities.IImpact);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const impact = result.payload;
    return impact;
  };

  confirmCreateImpact = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="AlertCircle" className="mr-2" />
              {I18n.t("warnings.createSameNameImpact")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };
}
