import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";

interface IImpactGroupExtendedViewModelOptions {
  appService: AppService;
  projectId: number;
  impactGroupId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}
export class ImpactGroupExtendedViewModel extends BaseModel {
  appService: AppService;
  projectProvider: IProjectsApi;
  impactGroupProvider: IImpactGroupsApi;
  projectId: number;
  impactGroupId: number;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  @observable.ref impactGroup: FP.Entities.IImpactGroup;
  organisationId: number;

  constructor({ appService, authUser, projectId, impactGroupId, organisationId }: IImpactGroupExtendedViewModelOptions) {
    super();
    this.appService = appService;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactGroupProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.projectId = projectId;
    this.impactGroupId = impactGroupId;
    this.organisationId = organisationId;
    this.commentViewModel = new CommentListViewModel(appService, this.projectId, authUser, {
      searchAttribute: "impactGroupId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId
    });
    this.revisionHistoryModel = new RevisionHistoryModel(appService, {
      entityId: this.impactGroupId,
      projectId: this.projectId,
      historyType: "impact-groups",
      organisationId
    });
  }

  @action.bound
  async loadImpactGroup() {
    const res = await this.impactGroupProvider.getDetailed(this.organisationId, this.projectId, this.impactGroupId);
    this.impactGroup = res.payload as FP.Entities.IImpactGroup;
    this.commentViewModel.setConfig({
      id: this.impactGroup.id,
      description: <p className="mb-0">{I18n.t("phrases.impactGroupCommentsDescription")}</p>
    });
  }
}
