import * as React from "react";
import { IModel, BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import {
  IInnerNavContentConfig,
  IInnerNavContentModel,
  InnerNavContentModel
} from "../../../../components/widgets/innerNavigation/InnerNavContent_model";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { Services } from "../../../../constants";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { observable } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { GeneralSettingsModel } from "./general/General_model";
import { SecuritySettingsModel } from "./security/Security_model";
import { OrganisationsSettingsModel } from "./organisations/Organisations_model";
import { PreferencesSettingsModel } from "./preferences/Preferences_model";
import { GeneralSettings } from "./general/General_view";
import { SecuritySettings } from "./security/Security_view";
import { OrganisationsSettings } from "./organisations/Organisations_view";

export interface ISettingsViewModel extends IModel {
  innerNavContentModel: IInnerNavContentModel;
  organisation: FP.Entities.IOrganisation;
}

export class SettingsViewModel extends BaseModel implements ISettingsViewModel {
  innerNavContentConfig: IInnerNavContentConfig;
  innerNavContentModel: IInnerNavContentModel;
  organisationContext: IOrganisationContextModel;
  orgProvider: IOrganisationsApi;
  modalService: IModalService;
  @observable organisation: FP.Entities.IOrganisation;
  routeProps: RouteComponentProps;
  generalSettingsModel: GeneralSettingsModel;
  securitySettingsModel: SecuritySettingsModel;
  orgSettingsModel: OrganisationsSettingsModel;
  preferenceSettings: PreferencesSettingsModel;
  authUser: FP.Entities.IUser;
  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, authUser: FP.Entities.IUser, refreshClaims: () => void) {
    super();

    let organisatonId = parseInt(routeProps.match.params["organisationId"]);

    this.authUser = authUser;
    this.organisationContext = appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.orgProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.innerNavContentModel = new InnerNavContentModel(this.innerNavContentConfig);
    this.generalSettingsModel = new GeneralSettingsModel(appService, routeProps, authUser, organisatonId, refreshClaims);
    this.securitySettingsModel = new SecuritySettingsModel(appService, routeProps, organisatonId);
    this.orgSettingsModel = new OrganisationsSettingsModel(appService, routeProps, organisatonId);
    this.preferenceSettings = new PreferencesSettingsModel(appService, routeProps);

    this.loadOrganisation(organisatonId);
    this.routeProps = routeProps;
  }

  loadOrganisation = async (id: number) => {
    // let res = await this.orgProvider.getByIdAsync(id);

    // if (!res || res.isError) {
    //     return;
    // }
    // this.organisation = res.payload;
    // this.generalSettingsModel.organisation = this.organisation;
    // this.orgSettingsModel.loadForm();

    this.innerNavContentConfig = {
      navigationTitle: "Settings",
      displayNavWithLinks: true,
      items: {
        general: {
          key: "general",
          label: I18n.t("phrases.generalSettings"),
          content: <GeneralSettings model={this.generalSettingsModel} />
        },
        securitySettings: {
          key: "securitySettings",
          label: I18n.t("phrases.securitySettings"),
          content: <SecuritySettings model={this.securitySettingsModel} />
        },
        organisations: {
          key: "organisations",
          label: I18n.t("phrases.organisationsSettings"),
          content: <OrganisationsSettings model={this.orgSettingsModel} />
        }
        // preferences: {
        //     key: "preferences",
        //     label: I18n.t("phrases.preferencesSettings"),
        //     content: <PreferencesSettings model={this.preferenceSettings} />
        // },
      }
    };
    // test change
    this.innerNavContentModel.setConfig(this.innerNavContentConfig);
    // let page = this.routeProps.match.params["page"];
    // let keys = _.map(this.innerNavContentConfig.items, e => e.key)
    // if (page && keys.indexOf(page) >= 0) {
    //     this.innerNavContentModel.setCurrentViewKey(page)
    // }
  };
}
