import { BaseModel } from "../../../../core/util/BaseModel";
import { IModalService } from "../../../../core/modal/IModalService";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../../constants";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";

export class ActionCompactViewModel extends BaseModel {
  appService: AppService;
  modalService: IModalService;
  @observable.ref actionObject: FP.Entities.IAction;
  actionsProvider: IActionsApi;
  @observable isLoading = true;
  actionId: number;
  projectId: number;
  organisationId: number;
  /**
   *
   */
  constructor(appService: AppService, projectId: number, organisationId: number, actionId?: number) {
    super();
    this.appService = appService;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.actionsProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.actionId = actionId;
    this.projectId = projectId;
    this.organisationId = organisationId;
  }

  @action
  setActionObject = (action: FP.Entities.IAction) => {
    this.actionObject = action;
    this.isLoading = false;
  };

  onUnmount = () => { };

  onMount = () => {
    if (this.actionId) {
      this.getAction();
    }
  };

  @action
  async getAction() {
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, this.actionId);
    if (res) this.setActionObject(res.payload);
  }
}
