import * as React from "react";
import { AddStakeholderModel } from "./AddStakeholder_model";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { IF } from "../../../../components/hoc/If";
import { StakeholderDetails } from "../../stakeholders/StakeholderDetails";
import { Panel } from "../../../../components/ui/Panel";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { useCanEditOrganisationClaim } from "../../../../core/auth/authorise";

export interface AddStakeholderProps {
  model: AddStakeholderModel;
}

export const AddStakeholder: React.FC<AddStakeholderProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  const canEditOrganisation = useCanEditOrganisationClaim(organisationId);

  return (
    <div className={`add-stakeholder pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders`}
            >
              <Icon className="mr-2" symbol="chevron-left" />
              {I18n.t("phrases.backToStakeholders")}
            </LinkButton>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        onTabClicked={model.resetTabs}
      >
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.addExisting")}</h2>}>
          <div className="container-fluid">
            <Panel background="bg-light" className="row py-4 mb-4">
              <div className="col-lg-7">
                <h1>
                  {I18n.t("phrases.add")} <strong>{I18n.t("phrases.stakeholders")} </strong>
                </h1>
                <p className="mb-0">
                  {I18n.t("phrases.stakIndExp")} {I18n.t("phrases.stakIndExample")}
                </p>
              </div>
            </Panel>
            <div className="row">
              <div className="col-lg-10">
                <SingleForm model={model.searchStakeholderFormModel} />
              </div>
            </div>
          </div>
        </Tab>
        {canEditOrganisation && (
          <Tab title={<h2 className="mb-0">{I18n.t("phrases.createNew")}</h2>}>
            <IF condition={!model.stakeholder}>
              <div className="container-fluid">
                <Panel background="bg-light" className="row py-4 mb-4">
                  <div className="col-lg-7">
                    <h1>
                      {I18n.t("phrases.create")} <strong>{I18n.t("phrases.stakeholder")} </strong>
                    </h1>
                    <p className="mb-0">
                      {I18n.t("phrases.stakIndExp")} {I18n.t("phrases.stakIndExample")}
                    </p>
                  </div>
                </Panel>

                <div className="row mb-4">
                  <div className="col-lg-10">
                    <SingleForm model={model.createStakeholderFormModel} />
                  </div>
                </div>
              </div>
            </IF>
          </Tab>
        )}
      </Tabs>

      <IF condition={!!model.stakeholder}>
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-12">
              <h2>{I18n.t("phrases.stakeholderProfile")}</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <StakeholderDetails item={model.stakeholder} />
            </div>
          </div>
        </div>
      </IF>

      <IF condition={!!model.stakeholder}>
        <hr />
        <div className="container-fluid">
          <div className="row mt-5 mb-4">
            <div className="col-12">
              <h2>{I18n.t("phrases.projectProfile")}</h2>
              <p>{I18n.t("phrases.stakeholderProjectProfileDescription")}</p>
              <p>{I18n.t("phrases.stakeholderProjectProfileGuidance")}</p>
            </div>
          </div>

          {model.proStakeholderFormModel && (
            <div className="row">
              <div className="col-lg-10">
                <SingleForm model={model.proStakeholderFormModel} />
              </div>
            </div>
          )}
        </div>

        <hr />

        <div className="container-fluid">
          <div className="row my-4">
            <div className="col-12">
              <Button id="AddProjectStakeholderButton" className="float-right" onClick={model.addProjectStakeholder}>
                {I18n.t("phrases.addToProject")}
              </Button>
            </div>
          </div>
        </div>
      </IF>
    </div>
  );
});
