import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { getConcernFormFields } from "../../forms/concern/ConcernFormFields_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { IModalService } from "../../../../core/modal/IModalService";
import { Services } from "../../../../constants";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IConcernsApi } from "../../../../services/api/v1/concerns/IConcerns.api";
import I18n from "../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IFlightPathApiResponse } from "../../../../services/api/BaseApiModel";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";
import { Animations } from "../../../../core/util/Animations";

export class StakeholderConcernsViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  modalService: IModalService;
  projectProvider: IProjectsApi;
  concernsProvider: IConcernsApi;
  httpProgress: IHttpProgressModel;
  stakeholderId: number;
  projectId: number;
  isRouteView: boolean;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref concerns: FP.Entities.IConcern[] = [];
  @observable.ref filteredConcerns: FP.Entities.IConcern[] = [];

  concernCardActions: IUiAction<FP.Entities.IConcern>[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  organisationId: number;
  cookieService: ICookieService;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, isRouteView: boolean = true) {
    super();

    this.appService = appService;
    this.routeProps = routeProps;
    this.isRouteView = isRouteView;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.organisationId = parseInt(this.cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID));
    this.concernsProvider = this.appService.getService<IConcernsApi>(Services.ConcernsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.stakeholderId = parseInt(this.routeProps.match.params["stakeholderId"]);
    this.projectId = parseInt(this.routeProps.match.params["projectId"]);
    this.setConcernCardActions();
  }

  onMount = () => {
    if (this.isRouteView) {
      this.loadProjectStakeholder(this.projectId, this.stakeholderId);
    }
  };

  setConcernCardActions = () => {
    if (this.permissionService.canEdit()) {
      this.concernCardActions.push(
        {
          id: "action1",
          label: I18n.t("phrases.edit"),
          onAction: (ev, concern: FP.Entities.IConcern) => {
            this.showAddConcernModal(concern);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        },
        {
          id: "action2",
          label: I18n.t("phrases.remove"),
          onAction: (ev, concern: FP.Entities.IConcern) => {
            this.showConcernConfirmDeleteModal(concern);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        }
      );
    }
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    this.isLoading = true;
    const res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload as FP.Entities.IProjectStakeholder);
    this.isLoading = false;
  };

  @action
  setProjectStakeholder = (proStakeholder: FP.Entities.IProjectStakeholder) => {
    if (!this.projectId || !this.stakeholderId) {
      this.projectId = proStakeholder.project.id;
      this.stakeholderId = proStakeholder.stakeholder.id;
    }
    this.projectStakeholder = proStakeholder;
    this.concerns = proStakeholder.concerns;
    this.filteredConcerns = proStakeholder.concerns;
    this.isLoading = false;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterConcerns();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterConcerns();
    this.hideSearchMode();
  };

  @action
  filterConcerns = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredConcerns = this.concerns.filter(concern => {
        const lowerOwner = `${concern.owner.firstName} ${concern.owner.lastName}`.toLowerCase();
        const lowerSummary = concern.summary.toLowerCase();

        return lowerOwner.includes(lowerSearch) || lowerSummary.includes(lowerSearch);
      });
    } else {
      this.filteredConcerns = this.concerns;
    }
  };

  showConcernConfirmDeleteModal = (concern: FP.Entities.IConcern) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDelete", { name: I18n.t("phrases.thisKeyConcern") })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          this.httpProgress.showOverlay();
          const res = await this.concernsProvider.remove(this.organisationId, this.projectId, concern.id);
          this.httpProgress.hideOverlay();

          if (!res || res.isError) {
            return;
          }

          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  showAddConcernModal = (concern?: FP.Entities.IConcern) => {
    let formFields = getConcernFormFields(concern);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t(concern ? "phrases.update" : "phrases.add"),
        onAction: async ev => {
          const formRes = await formModel.submit();
          if (!formRes) return;
          this.modalService.hide();
          formRes.projectStakeholderId = this.projectStakeholder.id;

          let res: IFlightPathApiResponse<FP.Entities.IConcern>;
          this.httpProgress.showOverlay();
          if (concern) {
            res = await this.concernsProvider.update(this.organisationId, this.projectId, concern.id, formRes as FP.Entities.IConcern);
          } else {
            res = await this.concernsProvider.create(this.organisationId, this.projectId, formRes as FP.Entities.IConcern);
          }
          this.httpProgress.hideOverlay();

          if (!res || res.isError) {
            return;
          }

          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t(concern ? "phrases.editKeyConcern" : "phrases.addKeyConcern")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-light"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        }
      });
    });
  };
}
