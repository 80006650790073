import * as React from "react";
import { RolesSettingsModel } from "./RolesSettings_model";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { Accordion } from "../../../../../components/ui/Accordion";
import { UiActionComponent } from "../../../../../core/uiAction/UiAction";
import { Panel } from "../../../../../components/ui/Panel";
import { Enums } from "../../../../../enums";
import { Tooltip } from "../../../../../components/ui/Tooltip";
import { Icon } from "../../../../../components/ui/Icon";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { Link } from "react-router-dom";
import { convertStakeholderToName } from "../../../../../core/util/Helpers";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { FormatAsDataTestId } from "../../../../../core/util/TestHelpers";
import { Animations } from "../../../../../core/util/Animations";

export interface RolesSettingsProps {
  model: RolesSettingsModel;
}

const RolesSettings: React.FunctionComponent<RolesSettingsProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (!model.organisation || !model.roles || model.isLoading) {
    return <PositionedSpinner />;
  }

  let searchValue = model.filterModel.getFilter("name").value;

  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {model.organisation.name} | <strong>{I18n.t("phrases.manageRoles")}</strong>
          </h1>
          <p>{I18n.t("phrases.manageRolesDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <LinkButton
              type="outline-primary"
              href={`/organisations/${model.organisation?.id}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Button
            id={"ShowRoleFormButton"}
            onClick={() => {
              model.showRoleFormModal();
            }}
          >
            {I18n.t("phrases.createNewRole")}
          </Button>

          <SearchButton
            id="SearchRole"
            className={"float-right"}
            value={searchValue.length > 0 ? searchValue[0] : ""}
            onChange={model.searchRoles}
            onClose={model.resetNameFilter}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <div className="row mx-0 p-3">
            <div className="col-4 px-0">
              <h4 className="d-flex align-items-center mb-0" onClick={() => model.filterModel.setSortByKey("name")}>
                {I18n.t("table.roleName")}
                {model.filterModel.currentOrder?.key === "name" ? (
                  <Icon
                    size={Enums.UiSizes.SM}
                    className="ml-2"
                    symbol={model.filterModel.currentOrder.isAsc ? "sort-down" : "sort-up"}
                  />
                ) : (
                    <Icon size={Enums.UiSizes.SM} className="ml-2" symbol="sort-none" />
                  )}
              </h4>
            </div>
            <div className="col-4 px-0">
              <h4
                className="d-flex align-items-center mb-0"
                onClick={() => model.filterModel.setSortByKey("BusinessArea.Name")}
              >
                {I18n.t("table.businessArea")}
                {model.filterModel.currentOrder?.key === "BusinessArea.Name" ? (
                  <Icon
                    size={Enums.UiSizes.SM}
                    className="ml-2"
                    symbol={model.filterModel.currentOrder.isAsc ? "sort-down" : "sort-up"}
                  />
                ) : (
                    <Icon size={Enums.UiSizes.SM} className="ml-2" symbol="sort-none" />
                  )}
              </h4>
            </div>
            <div className="col-4 px-0">
              <h4
                className="d-flex align-items-center mb-0"
                onClick={() => model.filterModel.setSortByKey("numOfStakeholders")}
              >
                {I18n.t("table.numOfStakeholders")}
                {model.filterModel.currentOrder?.key === "numOfStakeholders" ? (
                  <Icon
                    size={Enums.UiSizes.SM}
                    className="ml-2"
                    symbol={model.filterModel.currentOrder.isAsc ? "sort-down" : "sort-up"}
                  />
                ) : (
                    <Icon size={Enums.UiSizes.SM} className="ml-2" symbol="sort-none" />
                  )}
              </h4>
            </div>
          </div>
          {model.roles?.length ? (
            <>
              {model.roles.map(role => {
                return (
                  <Accordion
                    key={role.id}
                    side="left"
                    actions={
                      <>
                        {model.actions.map(action => {
                          return <UiActionComponent key={action.id} action={action} contextModel={role} testId={FormatAsDataTestId(action.label.toString(), role.name)} />;
                        })}
                      </>
                    }
                    testId={FormatAsDataTestId("role-actions",role.name)}
                    header={
                      <Panel type="border-lines-y" className="p-3 row mx-0">
                        <div className="col-4 p-0 pl-4">
                          <p className="mb-0">{role.name}</p>
                        </div>
                        <div className="col-4 p-0">
                          <p className="mb-0">{role.businessArea?.name || ""}</p>
                        </div>
                        <div className="col-4 p-0">
                          <p className="mb-0">{role.stakeholders?.length || 0}</p>
                        </div>
                      </Panel>
                    }
                  >
                    {role.stakeholders?.map(stakeholder => {
                      return (
                        <Panel type="border-lines-y" key={stakeholder.id + "-s" + role.id} className="p-3 row mx-0">
                          <div className="col-7 offset-md-4 p-0">
                            <Link to={`/organisations/${organisationId}/stakeholders/${stakeholder.id}`}>{convertStakeholderToName(stakeholder)}</Link>
                          </div>
                          {model.stakeholderActions?.length ? (
                            <div className="ml-auto">
                              <Tooltip
                                position="left"
                                shownElement={<Icon symbol="dots-horizontal" />}
                                triggeredOn="click"
                              >
                                <Panel background="bg-white" hasShadow={true}>
                                  {model.stakeholderActions.map(action => (
                                    <UiActionComponent
                                      key={action.id}
                                      action={action}
                                      contextModel={{ stakeholder, role: role }}
                                    />
                                  ))}
                                </Panel>
                              </Tooltip>
                            </div>
                          ) : null}
                        </Panel>
                      );
                    })}
                  </Accordion>
                );
              })}
              <Pagination model={model.paginationModel} />
            </>
          ) : (
              <div className="px-3">{I18n.t("phrases.noResults")}</div>
            )}
        </div>
      </div>
    </div>
  );
});

export { RolesSettings };
