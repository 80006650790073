import React, { useMemo } from "react";
import { Icon } from "../../../../components/ui/Icon";
import { NavLink, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import I18n from "../../../../core/localization/I18n";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { INavigationItem, useNavigationContext } from "../contentNav/nav";
import { Animations } from "../../../../core/util/Animations";

export interface ProjectListNavProps {
  programme?: INavigationItem;
  projects: INavigationItem[];
  project?: INavigationItem;
}

export const ProjectListNav: React.FC<ProjectListNavProps> = observer(({ programme, projects, project }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId?: string }>();
  const { setCurrentLevel } = useNavigationContext();

  useOrganisationSwitch(true);

  const links = useMemo(
    () => [
      { key: "overview", label: I18n.t("phrases.overview"), href: "" },
      { key: "stakeholders", label: I18n.t("entities.stakeholders"), href: "/stakeholders" },
      { key: "impacts", label: I18n.t("entities.impacts"), href: "/impacts" },
      { key: "actions", label: I18n.t("entities.actions"), href: "/actions" },
      { key: "reports", label: I18n.t("phrases.reports"), href: "/reports" }
    ],
    []
  );

  return (
    <div className="project-list-nav p-4">
      <div
        className="navigation__back navigation__link"
        onClick={() => {
          setCurrentLevel("organisations");
        }}
      >
        <Icon symbol="ChevronLeft" className="mr-2" />
        <h5 className="text-uppercase mb-0">{I18n.t("phrases.back")}</h5>
      </div>

      <>
        {programme && (
          <NavLink
            className={`navigation__link mb-3`}
            activeClassName="navigation__link--active disabled-link"
            to={`/organisations/${organisationId}/programmes/${programme.resourceId}`}
          >
            {programme.name}
          </NavLink>
        )}
        {projects.length
          ? projects.map(project => {
            return (
              <div className={`project-list-nav__project mb-4 ${Animations.FADE_IN} speed-1`} key={project.resourceId}>
                <div
                  className={`project-list-nav__label navigation__label${projectId === `${project.resourceId}` ? "--highlight" : ""
                    } pl-3`}
                >
                  <h5 className="text-uppercase mb-1">{project.name}</h5>
                </div>
                {links.map(e => {
                  return (
                    <NavLink
                      key={e.key}
                      data-testid={`navigation-link-${e.key}`}
                      className={`navigation__link`}
                      activeClassName="navigation__link--active disabled-link"
                      isActive={match => {
                        return match && (match.isExact as any);
                      }}
                      onClick={() => {
                        setCurrentLevel("programme");
                      }}
                      to={`/organisations/${organisationId}/projects/${project.resourceId}${e.href}`}
                    >
                      {e.label}
                    </NavLink>
                  );
                })}
              </div>
            );
          })
          : project &&
          [project].map(project => (
            <div className={`project-list-nav__project mb-4 ${Animations.ZOOM_IN} speed-1`} key={project.resourceId}>
              <div
                className={`project-list-nav__label navigation__label${projectId === `${project.resourceId}` ? "--highlight" : ""
                  } pl-3`}
              >
                <h5 className="text-uppercase mb-1">{project.name}</h5>
              </div>
              {links.map(e => {
                return (
                  <NavLink
                    key={e.key}
                    data-testid={`navigation-link-${e.key}`}
                    className={`navigation__link`}
                    activeClassName="navigation__link--active disabled-link"
                    onClick={() => setCurrentLevel("programme")}
                    isActive={match => {
                      return match && (match.isExact as any);
                    }}
                    to={`/organisations/${organisationId}/projects/${project.resourceId}${e.href}`}
                  >
                    {e.label}
                  </NavLink>
                );
              })}
            </div>
          ))}
      </>
    </div>
  );
});
