import * as React from 'react';

const SvgSmile = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="smile_svg__feather smile_svg__feather-smile"
        {...props}
    >
        <circle cx={12} cy={12} r={10} />
        <path d="M8 14s1.5 2 4 2 4-2 4-2M9 9h.01M15 9h.01" />
    </svg>
);

export default SvgSmile;
