import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getActionFormFields } from "../../forms/action/ActionFormSection_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { Enums } from "../../../../enums";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { getProjectFormField } from "../../forms/impact/ImpactFormSection_data";
import { observable, action } from "mobx";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IModalService } from "../../../../core/modal/IModalService";
import { Icon } from "../../../../components/ui/Icon";
import { appHistory } from "../../../../setup";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { gEntities } from "../../../../FlightPathEntities";

interface ICreateActionViewModelOptions {
  appService: AppService;
  projectId?: number;
  organisationId: number;
  user: FP.Entities.IUser;
}

export class CreateActionViewModel extends BaseModel {
  orgProvider: IOrganisationsApi;
  projectsProvider: IProjectsApi;
  actionsProvider: IActionsApi;
  orgId: number;
  appService: AppService;
  httpProgress: IHttpProgressModel;
  toasterService: IToasterService;
  modalService: IModalService;
  actionTypeProvider: IActionTypeApi;
  @observable projectId: number;
  @observable.ref projectForm: SingleFormModel;
  @observable.ref formModel: SingleFormModel;
  user: FP.Entities.IUser;
  impactProvider: IImpactsApi;
  @observable impacts: Partial<gEntities.IImpact>[] = [];
  impactId: number;

  constructor({ appService, user, projectId, organisationId }: ICreateActionViewModelOptions) {
    super();
    this.appService = appService;
    this.user = user;
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.projectsProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.actionsProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);

    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.actionTypeProvider = this.appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.orgId = organisationId;
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    if (projectId) {
      this.projectId = +projectId;
      this.setForms(this.projectId);
    } else {
      this.projectForm = new SingleFormModel();
      this.projectForm.formFields = getProjectFormField(this.orgProvider, this.orgId, this.setForms);
    }

    this.impactId = 0;
  }

  @action
  onMount = async (impactId: number) => {
    this.impactId = impactId;
    this.getImpactIfNotNull();
  };

  impactIdIsValid = (): boolean => {
    return this.impactId !== null && this.impactId !== undefined && this.impactId > 0;
  };

  getImpactIfNotNull = () => {
    if (this.impactIdIsValid()) {
      this.impactProvider
        .getDetailedById(this.orgId, this.projectId, this.impactId)
        .then(result => {
          return this.impacts.push(result.payload);
        })
        .catch(e => {});
    }
  };

  @action
  setForms = (projectId: number) => {
    this.projectId = projectId;
    let action: any = {};
    if (this.formModel) {
      action = this.formModel.getFormKeyValueData();
      action.ragStatus = action.ragStatus?.key;
      action.progressStatus = action.progressStatus?.key;
      action.assignee = action.assigneeId;
      action.owner = action.ownerId;
      action.actionType = action.actionTypeId;
      action.actualEndDate = action.initialEndDate;

      delete action.impacts;
      delete action.assigneeId;
      delete action.actionTypeId;
      delete action.ownerId;
    }

    const fields = getActionFormFields(
      this.actionTypeProvider,
      this.projectsProvider,
      projectId,
      this.orgId,
      this.user,
      this.impactProvider,
      this.impacts,
      action
    );

    this.formModel = new SingleFormModel();
    this.formModel.formFields = fields;
    this.formModel.actions = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: async () => {
          let res = await this.createAction();
          if (res) {
            this.toasterService
              .showSuccessToast()
              .setContent(<p>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.action") })}</p>)
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
            this.formModel.resetFields();
          }
        },
        rendersIn: "Button",
        componentProps: {
          type: "outline-primary",
          className: "ml-auto"
        }
      },
      {
        id: "CreateActionButton",
        label: I18n.t("phrases.createAction"),
        rendersIn: "Button",
        componentProps: {
          type: "primary",
          className: "ml-2"
        },
        onAction: async () => {
          let action = await this.createAction();
          if (action) {
            appHistory.push(
              `/organisations/${this.orgId}/projects/${action.projectId}/actions/${action.id}`
            );
          }
        }
      }
    ];
  };

  createAction = async () => {
    const formRes = await this.formModel.submit();
    if (!formRes) return;

    let actionExists = await this.actionsProvider.getFiltered(this.orgId, this.projectId, {
      filters: `name==${formRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });
    if (actionExists && !actionExists.isError && actionExists.payload.length) {
      let confirmCreateAction = await this.confirmCreateAction(formRes.name);
      if (!confirmCreateAction) return;
    }

    this.httpProgress.showOverlay();
    const res = await this.actionsProvider.create(this.orgId, this.projectId, formRes as FP.Entities.IAction);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    if (res.payload) {
      return res.payload;
    }
  };

  confirmCreateAction = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="AlertCircle" className="mr-2" />
              {I18n.t("warnings.createSameNameAction")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };
}
