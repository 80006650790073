import React from "react";
import ReactSlider from "@material-ui/core/Slider";
import { SliderProps } from "../../../../core/forms/controls/slider/Slider_model";

const Slider: React.FunctionComponent<SliderProps> = props => {
  const { className, valueLabelFn, isUnknownValue, ...restProps } = props;
  
  return (
    <div
      style={props.style}
      className={`slider d-flex align-items-center ${className || ""} ${isUnknownValue && "unknown-value"}`}
      data-showvaluelabel={props.valueLabelDisplay}
    >
      <ReactSlider {...restProps} />
      {valueLabelFn && typeof valueLabelFn === "function" ? (
        <span className="slider__value-label ml-3">{valueLabelFn(isUnknownValue ? null : props.value as number)}</span>
      ) : null}
    </div>
  );
};

export { Slider };
