import * as React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import FroalaEditor from "../../FroalaEditor/FroalaEditor";

// Froala Config Constants
const key = "cJC7bC5E2E2G2G2I2yQNDMIJg1IQNSEa1EUAi1XVFQd1EaG3C2A5A4C4D3C2D4G2E2==";
const toolbarButtons = ["bold", "italic", "underline", "formatOL", "formatUL", "insertLink"];
const editorClass = "sys-froala-editor";

export interface RichTextEditorProps {
  id?: string;
  value: string;
  toolbarItems?: string[];
  componentProps?: any;
  onChange: (value: any) => void;
}

@observer
export class RichTextEditor extends React.Component<RichTextEditorProps> {
  config: any;

  constructor(props: any) {
    super(props);

    this.config = {
      key,
      toolbarButtons: props.toolbarItems || toolbarButtons,
      editorClass,
      quickInsertTags: [""],
      attribution: false,
      charCounterCount: false,
      imagePaste: false,
      zIndex: 1001,
      events: {
        "froalaEditor.initialized": this.onInit
      }
    };
  }

  @action handleModelChange = (value: string) => {
    this.props.onChange(value);
  };

  onInit = (editor: any) => {
    editor.$$model = this.props.value;
  };

  render() {
    return (
      <FroalaEditor id={this.props.id} tag="div" config={this.config} model={this.props.value} onModelChange={this.handleModelChange} />
    );
  }
}
