import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { GetOrgSettingsTableConfig } from './OrganisationsTable_config';
import { Services } from "../../../../../constants";
import { observable } from "mobx";
import { IUsersApi } from "../../../../../services/api/v1/users/IUsers.api";

export class OrganisationsSettingsModel extends BaseModel {
    @observable.ref orgs: FP.Entities.IOrganisation[];
    @observable isLoading: boolean = true;
    tableModel: ITableModel<FP.Entities.IOrganisation>;
    usersProvider: IUsersApi;
    organisationId: number;
    /**
     *
     */
    constructor(appService: AppService, routeProps: RouteComponentProps, organisationId: number) {
        super();
        this.usersProvider = appService.getService<IUsersApi>(Services.UsersApi);
        this.tableModel = new TableModel();

        let tableConfig = GetOrgSettingsTableConfig(this, this.tableModel);

        this.tableModel.set(tableConfig);
        this.organisationId = organisationId;
    }


    loadOrgs = async () => {
        let res = await this.usersProvider.getOrgs(this.organisationId);
        if (!res || res.isError) return;
        this.setOrgs(res.payload);
    }

    setOrgs = (orgs: FP.Entities.IOrganisation[]) => {
        this.orgs = orgs;
        this.tableModel.setData(this.orgs);
        this.isLoading = false;
    }

    onMount = () => {
        this.loadOrgs();
    }

    onUnmount = () => {
    }
}