import { IHttp } from "../../IHttp";
import { IActionsApi } from "./IActions.api";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";

export class ActionsApi extends ExportableProjectApi<FP.Entities.IAction> implements IActionsApi {
  controller: string = "actions";

  constructor(http: IHttp) {
    super(http, "actions");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    projectId?: number,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  };

  async updateDates(
    organisationId: number,
    projectId: number,
    actionId: number,
    data: FP.Entities.IAction,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> {
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/setDates`,
      data,
      config
    );
    if (res && res.status === 200) {
      return res.data;
    }
  }

  getDetailedByIdAsync = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  addImpactsAsync = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/impacts`,
      impactIds,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  removeImpactAsync = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${actionId}/impacts/${impactId}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  getUnassignedImpactsAsync = async (
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/unassignedImpacts`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  getComments = async (organisationId: number, projectId: number, actionId: number, config?: AxiosRequestConfig) => {
    const res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/comments`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment[]>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  addComment = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ) => {
    const res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/comments`,
      comment,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IComment>;
    }
    throw new Error(`Error while executing Action Endpoint API error - Status Code: ${res.status} Url: ${this.url}`);
  };

  //#endregion impacts
}
