import * as React from "react";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { IFilterModel } from "../../../../../core/filter/Filter_model";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { convertStakeholderToName } from "../../../../../core/util/Helpers";
import { Tooltip } from "../../../../../components/ui/Tooltip";
import { Panel } from "../../../../../components/ui/Panel";
import { Bullet } from "../../../../../components/ui/Bullet";
import { Enums } from "../../../../../enums";
import { Pill } from "../../../../../components/ui/Pill";
import { TargetLabel } from "../../../../../components/widgets/TargetLabel";

export const GetUserStakeholderTableConfig = (
  filterModel: IFilterModel<any>,
  tableModel: ITableModel<FP.Entities.IProjectStakeholder>,
  showCompactModal: (row: ITableRowModel) => void,
  isExtended?: boolean,
  showActionConfirmDeleteModal?
): ITableConfig<FP.Entities.IProjectStakeholder> => {
  let k: ITableConfig<FP.Entities.IProjectStakeholder> = {
    onRowClick: row => {
      showCompactModal(row);
    },
    colHeaders: [
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => convertStakeholderToName(obj.stakeholder),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "project.name",
        content: <h4 className="mb-0">{I18n.t("table.project")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => obj.project.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "influence",
        content: <h4 className="mb-0">{I18n.t("table.influence")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => obj.influence,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "impact",
        content: <h4 className="mb-0">{I18n.t("table.impact")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <Pill
              style={{ width: "90px" }}
              type={`indicator-accent-${Enums.Translator.MetricToEnum(obj.impact)}` as any}
            >
              {Enums.Translator.Metric(obj.impact)}
            </Pill>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      }
    ] as any[]
  };

  if (isExtended) {
    k.colHeaders.push(
      {
        key: "Project.Name",
        content: <h4 className="mb-0">{I18n.t("table.project")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.project.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      } as any,
      {
        key: "Project.Organisation.Name",
        content: <h4 className="mb-0">{I18n.t("table.organisation")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.project.organisation.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      } as any
    );

    k.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        rendersIn: "HTMLAnchor",
        componentProps: {
          type: "link"
        },
        hrefFn: context => `/organisations/${context.rowObject.organisationId}/projects/${context.rowObject.projectId}/actions/${context.rowObject.id}/edit`
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: async (ev, row: ITableRowModel) => {
          await showActionConfirmDeleteModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    ];
  }
  return k;
};

export interface StakeholderProgressProps {
  projectStakeholder: any;
  isProject: boolean;
}

export const StakeholderProgress: React.FunctionComponent<StakeholderProgressProps> = props => {
  let ps = props.projectStakeholder;
  let onTarget =
    ps.attitude >= ps.targetAttitude && ps.commitment >= ps.targetCommitment && ps.resistance >= ps.targetResistance;
  return (
    <div className="stakeholder-progress">
      <Tooltip
        position="top-right"
        shownElement={
          <p className="mb-0">
            <Bullet className={`bg-${onTarget ? "success" : "danger"} mr-2`} />
            {props.isProject ? "" : convertStakeholderToName({
              firstName: ps.firstName,
              lastName: ps.lastName,
              stakeholderType: ps.stakeholderType
            })}
          </p>
        }
        triggeredOn="hover"
      >
        <Panel style={{ width: "120px" }} className="p-3" background="bg-white" hasShadow={true}>
          <TargetLabel
            target={ps.targetAttitude}
            current={ps.attitude}
            onTarget={ps.attitude >= ps.targetAttitude}
            text={I18n.t("phrases.attitude")}
            labelModifier={Enums.Translator.SentimentLevel}
          />
          <TargetLabel
            target={ps.targetCommitment}
            current={ps.commitment}
            onTarget={ps.commitment >= ps.targetCommitment}
            text={I18n.t("phrases.commitment")}
            labelModifier={Enums.Translator.CommitmentLevel}
          />
          <TargetLabel
            target={ps.targetResistance}
            current={ps.resistance}
            onTarget={ps.resistance >= ps.targetResistance}
            text={I18n.t("phrases.resistance")}
            labelModifier={Enums.Translator.ReceptivenessLevel}
          />
        </Panel>
      </Tooltip>
    </div>
  );
};
