import React from "react";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Avatar } from "../../../../components/ui/Avatar";
import { UiSizes } from "../../../../enums";
import { Panel } from "../../../../components/ui/Panel";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { useFlightPathUser } from "../../../../setup";
import { useOktaAuth } from "@okta/okta-react";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export const AuthUserAvatar: React.FunctionComponent<any> = props => {
  const { authService, authState } = useOktaAuth();
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();

  const logout = async () => {
    // Read idToken before local session is cleared
    const idToken = authState.idToken;
    await authService.logout();

    // Clear remote session
    window.location.href = `${window.appConfig.oauth_authority}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${window.appConfig.baseUrl}`;
  };

  return (
    <Tooltip
      triggeredOn="click"
      position="top-right"
      shownElement={<Avatar size={UiSizes.MD} imgSrc={authUser.profileImageUrl} />}
    >
      <Panel hasBorderRadius={true} background="bg-white" hasShadow={true} className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}>
        <LinkButton href={`/organisations/${organisationId}/users/settings`} type="link" size={UiSizes.XS}>
          Settings
        </LinkButton>
        <Button
          type="link"
          size={UiSizes.XS}
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
      </Panel>
    </Tooltip>
  );
};
