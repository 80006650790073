import * as React from 'react';
import { B } from '../../../core/util/BootstrapHelper';


export interface TableProps {
    id?: string;
    className?: string;
    isStriped?: boolean;
    isCondensed?: boolean;
    isBordered?: boolean;
}


export const Table: React.FunctionComponent<TableProps> = (props) => {
    let cls = B().bl("table")
        .pmod(props.isStriped ? "striped" : "")
        .pmod(props.isCondensed ? "condensed" : "")
        .pmod(props.isBordered ? "bordered" : "")
        .boot;


    return <table
        id={props.id}
        className={`${cls} ${props.className || ""}`}
    >
        {props.children}
    </table>

}

export const TableHeader: React.FunctionComponent<any> = (props) => {
    return <th
        {...props}
    >
        {props.children}
    </th>
}

export interface TableHeaderGroupProps extends
    React.HTMLProps<HTMLTableSectionElement> {
    rowProps?: TableRowProps;
}

export const TableHeaderGroup: React.FunctionComponent<TableHeaderGroupProps> = (props) => {
    return <thead {...props}>
        <TableRow {...props.rowProps}>
            {props.children}
        </TableRow>
    </thead>
}


export const TableBody: React.FunctionComponent<any> = (props) => {
    return <tbody style={props.style || {}}>
        {props.children}
    </tbody>
}

export const TableFooter: React.FunctionComponent<any> = (props) => {
    return <tfoot style={props.style || {}}>
        {props.children}
    </tfoot>
}

export interface TableRowProps extends React.HTMLProps<HTMLTableRowElement> {
    index?: number;
}

export const TableRow: React.FunctionComponent<TableRowProps> = (props) => {
    return <tr
        {...props}
    >
        {props.children}
    </tr>
}



export const TableCol: React.FunctionComponent<any> = (props) => {
    return <td
        {...props}
    >
        {props.children}
    </td>
}

let s = {
    Table: Table,
    Col: TableCol,
    Row: TableRow,
    Body: TableBody,
    Header: TableHeaderGroup,
    HeaderCol: TableHeader,
    Footer: TableFooter
}

export default s; 