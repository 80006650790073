import { IRolesApi } from "./IRoles.api";
import { IHttp } from "../../IHttp";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { AxiosRequestConfig } from "axios";

export class RolesApi extends FilteredOrganisationApiModel<FP.Entities.IRole> implements IRolesApi {
  controller: string = "roles";

  constructor(http: IHttp) {
    super(http, "roles");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/${this.controller}${query}`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
