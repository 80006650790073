
import React from 'react';
import { observable, action } from 'mobx';
import { FormFieldModel } from '../../formField/FormField_model';
import { FormFieldType } from '../../formField/FormFieldTypes';
import { INIT_TEXT_FIELD } from './TextField_init';
import { ITextFieldModel } from './ITextFieldModel';
import { Input, InputProps } from '../../../../components/ui/_forms/Input';

export class TextFieldModel extends FormFieldModel<string, InputProps> implements ITextFieldModel {
    @observable value: string = '';
    placeholder: string = "";
    readonly type: FormFieldType = FormFieldType.Text;
    inputType: "text" | "password" | "file" | "textarea" | "hidden" = "text";
    borderStyle: "underline" = null;

    constructor(initOpts?: ITextFieldModel) {
        super(initOpts);
        if (initOpts) {
            this.value = initOpts.value || "";
            this.validate = initOpts.validate || INIT_TEXT_FIELD.validate;
            this.placeholder = initOpts.placeholder || INIT_TEXT_FIELD.placeholder;
            this.inputType = initOpts.inputType || INIT_TEXT_FIELD.inputType;
            this.borderStyle = initOpts.borderStyle || this.borderStyle;
        }
    }

    @action.bound
    setValue(val: string): void {
        this.hasChanges = true;
        this.value = val;
        if (this.validateOnChange && this.validate) {
            this.validate(this.value);
        }
    }


    onKeyUp: (evt: React.KeyboardEvent<any>) => void;

    extractValue() {
        return this.value;
    }

    setFieldValue(val: string): void {
        this.hasChanges = false;
        this.value = val;
    }

    validate: (...args: any[]) => any;

    @action.bound
    reset(): void {
        this.hasChanges = true;
        this.errorMessage = null;
        this.value = this.defaultValue || '';
    }

    renderComponent = () => {
        let props = {
            className: `form-control ${this.className || ''}`,
            id: this.key,
            onChange: (e: React.SyntheticEvent<any>) => {
                const val = e.currentTarget.value;
                this.setValue(val);
            },
            onKeyUp: this.onKeyUp,
            placeholder: this.placeholder,
            disabled: this.disabled,
            value: this.value,
            borderStyle: this.borderStyle
        }
        if (this.inputType === "textarea") {
            delete props.borderStyle;
            return <textarea
                {...props}
                className={`input ${props.className || ""}`}
            />
        }
        return <Input type={this.inputType}
            {...props}
        />
    }
}
