import { BaseModel } from "../../../core/util/BaseModel";
import { observable } from "mobx";
import I18n from "../../../core/localization/I18n";



export class RevisionCreateModel extends BaseModel {
    revision: FP.Entities.IRevisionHistory;
    @observable changePhrase: string = "";


    /**
     *
     */
    constructor(revision: FP.Entities.IRevisionHistory) {
        super();
        this.revision = revision;
        this.changePhrase = `${revision.changedBy?.firstName} ${revision.changedBy?.lastName} ${I18n.t(revision.changes && revision.changes[0]?.newValue[0])} <strong>'${revision.changes && revision.changes[0]?.newValue[1]}'</strong>`;
    }

}