import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action, runInAction } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { Services } from "../../../../constants";
import { getActionFormFields } from "../../forms/action/ActionFormSection_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { appHistory } from "../../../../setup";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";

interface IEditActionViewModelOptions {
  appService: AppService;
  actionId: number;
  projectId: number;
  user: FP.Entities.IUser;
}

export class EditActionViewModel extends BaseModel {
  appService: AppService;
  cookieService: ICookieService;
  projectId: number;
  actionId: number;
  projectProvider: IProjectsApi;
  orgId: number;
  orgProvider: IOrganisationsApi;
  actionProvider: IActionsApi;
  httpProgress: IHttpProgressModel;
  actionTypeProvider: IActionTypeApi;
  @observable isLoading: boolean = true;
  @observable.ref action: FP.Entities.IAction;
  @observable.ref formModel: SingleFormModel;
  user: FP.Entities.IUser;
  impactProvider: IImpactsApi;

  constructor({ appService, projectId, actionId, user }: IEditActionViewModelOptions) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.actionId = actionId;
    this.user = user;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.actionTypeProvider = this.appService.getService<IActionTypeApi>(Services.ActionTypesApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.formModel = new SingleFormModel();
    this.orgId = parseInt(this.cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID), 10);
  }

  onMount = async () => {
    await this.loadAction();
    this.formModel.formFields = getActionFormFields(
      this.actionTypeProvider,
      this.projectProvider,
      this.projectId,
      this.orgId,
      this.user,
      this.impactProvider,
      [],
      this.action
    );

    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: "LinkButton",
        componentProps: {
          type: "link",
          className: "ml-auto",
          href: `/organisations/${this.orgId}/projects/${this.projectId}/actions/${this.actionId}`
        }
      },
      {
        id: "UpdateActionButton",
        label: I18n.t("phrases.save"),
        rendersIn: "Button",
        componentProps: {
          type: "primary",
          className: "ml-2"
        },
        onAction: this.updateAction
      }
    ];
  };

  onUnmount = () => { };

  @action
  loadAction = async () => {
    let res = await this.actionProvider.getDetailedByIdAsync(this.orgId, this.projectId, this.actionId);
    if (!res || res.isError) return;

    runInAction(() => {
      this.action = res.payload;
    });
  };

  @action
  updateAction = async () => {
    let res = await this.formModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.actionProvider.update(this.orgId, this.projectId, this.actionId, res as FP.Entities.IAction);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    this.action = result.payload;
    appHistory.push(`/organisations/${this.orgId}/projects/${this.projectId}/actions/${this.actionId}`);
  };
}
