import { BaseModel } from "../../../../../core/util/BaseModel";
import { action, observable } from "mobx";
import { ImpactGroupsViewModel } from "../../impactGroupsView/ImpactGroupsView_model";
import { ModalService } from "../../../../../core/modal/ModalService";
import { Services } from "../../../../../constants";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";

export class ImpactGroupModalModel extends BaseModel {
  owner: ImpactGroupsViewModel;
  projectId: number;
  impactGroupId: number;
  modalService: ModalService;
  impactService: IImpactsApi;
  impactGroupService: IImpactGroupsApi;
  @observable isLoading: boolean = true;
  @observable.ref impactGroup?: FP.Entities.IImpactGroup = null;
  organisationId: number;

  constructor(owner: ImpactGroupsViewModel, projectId: number, impactGroupId: number, organisationId: number) {
    super();
    this.owner = owner;
    this.projectId = projectId;
    this.impactGroupId = impactGroupId;
    this.organisationId = organisationId;
    this.modalService = this.owner.appService.getService<ModalService>(Services.AsideModalService);
    this.impactService = this.owner.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactGroupService = this.owner.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
  }

  onMount() {
    this.getImpactGroup();
  }

  @action
  async getImpactGroup() {
    this.isLoading = true;
    const res = await this.owner.impactGroupProvider.getDetailed(this.organisationId, this.projectId, this.impactGroupId);
    if (res) this.impactGroup = res.payload;
    this.isLoading = false;
  }
}
