import { ReportCardModel } from "./ReportCard_model";
import * as React from "react";
import { Panel } from "../../ui/Panel";
import { Tooltip } from "../../ui/Tooltip";
import { Icon } from "../../ui/Icon";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { IF } from "../../hoc/If";

export interface ReportCardProps {
  model: ReportCardModel;
}

const ReportCard: React.FunctionComponent<ReportCardProps> = observer(({ model }: ReportCardProps) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`report-card ${model.config.className || ""} report-card--${model.config.size || "md"}`}>
      <Panel
        {...model.config.panelProps}
        className={`p-4 ${model.config?.panelProps?.className || ""}`}
        hasShadow={true}
        hasBorderRadius={true}
      >
        <IF condition={!model.isDataLoaded}>
          <PositionedSpinner />
        </IF>
        <IF condition={model.isDataLoaded}>
          {model.config.actions && (
            <Tooltip
              position="left"
              className="float-right"
              shownElement={<Icon symbol="DotsHorizontal" />}
              triggeredOn="click"
            >
              <Panel style={{ width: "140px" }} background="bg-white" hasShadow={true}>
                {model.config.actions.map(e => {
                  return <UiActionComponent key={e.id} action={e} contextModel={model} />;
                })}
              </Panel>
            </Tooltip>
          )}
          <div>
            <div className="report-card__title">{model.config.title}</div>
          </div>
          <div className="report-card__graph">{model.config.render(model.data)}</div>
        </IF>
      </Panel>
    </div>
  );
});

export { ReportCard };
