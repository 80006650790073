import React, { ErrorInfo } from "react";

type ErrorOptions = {
  error: Error;
  errorInfo: ErrorInfo | null;
  clearError: () => void;
};

type ErrorRenderer = (options: ErrorOptions) => React.ReactNode;

interface ErrorBoundaryProps {
  renderError?: ErrorRenderer;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState(() => ({ error, errorInfo }));
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  defaultErrorRenderer: ErrorRenderer = ({ error }) => (
    <div data-testid="error-boundary__error-container">{error.message}</div>
  );

  render() {
    const { children, renderError } = this.props;
    const { clearError } = this;
    const { error, errorInfo } = this.state;

    const errorRenderer = renderError || this.defaultErrorRenderer;

    return error ? errorRenderer({ error, errorInfo, clearError }) : children;
  }
}
