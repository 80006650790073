import React, { useState } from "react";
import { Tooltip } from "../../ui/Tooltip";
import { Icon } from "../../ui/Icon";
import { UiSizes } from "../../../enums";
import { observer } from "mobx-react";
import NotificationsModel from "./Notifications_model";
import NotificationsContent from "./NotificationsContent";
import { useAppService } from "../../../AppService";
import { Animations } from "../../../core/util/Animations";

export interface INotificationsProps {
  model?: NotificationsModel;
}

const Notifications: React.FunctionComponent<INotificationsProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const [model] = useState(() => m || new NotificationsModel(appService));

  return (
    <Tooltip
      triggeredOn="click"
      position="top-right"
      shownElement={
        <div
          onClick={!model.isDataLoaded ? model.loadLastNotifications : undefined}
          className={`btn btn--link btn--sm btn btn--icon btn--shape-rectangle mb-1 navigation__action navigation-view__action ${Animations.FADE_IN} speed-3 delay-3`}
        >
          {/* *
           * TODO: add number of new notifications
           */}
          <Icon symbol="Bell" size={UiSizes.MD} />
        </div>
      }
    >
      <NotificationsContent model={model} />
    </Tooltip>
  );
});

export default Notifications;
