import * as React from "react";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IMPACT_LEVEL_OPTIONS } from "../../../../constants";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import _ from "lodash";

export const GetAudienceListFilters = (filterModel: IFilterModel<FP.Entities.IProjectStakeholder>) => {
  // define filters
  const nameFilter = filterModel.getFilter("Stakeholder.FirstName|Stakeholder.LastName");

  const ownerFilter = filterModel.getFilter("Owner.FirstName|Owner.LastName");

  const roleFilter = filterModel.getFilter("Role.Name");

  const busAFilter = filterModel.getFilter("BusinessArea.Name");

  const impactFilter = filterModel.getFilter("impactGen");
  const influenceFilter = filterModel.getFilter("influenceGen");

  // const typeFilter = filterModel.getFilter("stakeholderType");

  // add filter form fields
  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderName",
    label: <label htmlFor={"stakeholderName"}>{I18n.t("forms.audienceName")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.audienceName"),
    onValueChange: value => {
      filterModel.setFilterValue(nameFilter.key, value);
    },
    fieldClassName: "col-12",
    value: nameFilter?.value[0]
  };
  const ownerName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: ownerFilter.key,
    label: <label htmlFor={ownerFilter.key}>{I18n.t("forms.owner")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.searchOwner"),
    onValueChange: value => {
      filterModel.setFilterValue(ownerFilter.key, value);
    },
    fieldClassName: "col-12",
    value: ownerFilter?.value[0]
  };
  const roleName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "roleName",
    label: <label htmlFor={"roleName"}>{I18n.t("forms.roleName")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.searchRole"),
    onValueChange: value => {
      filterModel.setFilterValue(roleFilter.key, value);
    },
    fieldClassName: "col-12",
    value: roleFilter?.value[0]
  };
  const busAName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "businessAreaName",
    label: <label htmlFor={"businessAreaName"}>{I18n.t("forms.businessArea")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.businessArea"),
    onValueChange: value => {
      filterModel.setFilterValue(busAFilter.key, value);
    },
    fieldClassName: "col-12",
    value: busAFilter?.value[0]
  };

  let val: any = _.filter(IMPACT_LEVEL_OPTIONS, e => impactFilter.value.indexOf(e.key as any) >= 0)
  val.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });

  const impactType: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impact",
    label: <label htmlFor={"impact"}>{I18n.t("forms.impact")}</label>,
    placeholder: I18n.t("placeholders.selectImpact"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        IMPACT_LEVEL_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    value: val,
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12",
    onValueChange: value => {
      filterModel.setFilterValueList(
        impactFilter.key,
        value.map(e => e.key)
      );
    }
  };

  let inVal: any = _.filter(IMPACT_LEVEL_OPTIONS, e => influenceFilter.value.indexOf(e.key as any) >= 0)
  inVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });

  const influence: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "influence",
    label: <label htmlFor={"influence"}>{I18n.t("forms.influence")}</label>,
    placeholder: I18n.t("placeholders.selectInfluence"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        IMPACT_LEVEL_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    value: inVal,
    fieldClassName: "col-12",
    onValueChange: value => {
      filterModel.setFilterValueList(
        influenceFilter.key,
        value.map(e => e.key)
      );
    }
  };

  const fields = [];
  fields.push(name);
  fields.push(ownerName);
  fields.push(roleName);
  fields.push(busAName);
  fields.push(impactType);
  fields.push(influence);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
