import React from "react";
import { BaseModel } from "../../../core/util/BaseModel";
import { SearchPanelConfig, SearchPanelModel } from "./SearchPanel_model";
import { AppService } from "strikejs-app-service";
import { action } from "mobx";
import moment from "moment";
import { IProgrammesApi } from "../../../services/api/v1/programmes/IProgrammes.api";
import { Services } from "../../../constants";
import { IModalService } from "../../../core/modal/IModalService";
import I18n from "../../../core/localization/I18n";
import { LinkButton } from "../../ui/Button";
import { Icon } from "../../ui/Icon";
import { ITableConfig, ITableModel } from "../../../core/table/ITableModel";
import { TableModel } from "../../../core/table/Table_model";
import { CanEditOrg } from "../../hoc/CanEdit";
import { IOrganisationContextModel } from "../../../services/local/organisationContext/IOrganisationContextModel";
import { appHistory } from "../../../setup";

export class ProgrammeSearchPanelModel extends BaseModel {
  model: SearchPanelModel;
  appService: AppService;
  programmeProvider: IProgrammesApi;
  tableModel: ITableModel<FP.Entities.IProgramme>;
  modalService: IModalService;
  searchPanelConfig: SearchPanelConfig;
  organisation: FP.Entities.IOrganisation;
  organisationContext: IOrganisationContextModel;
  refreshFn?: () => void;

  constructor(appService: AppService, organisation: FP.Entities.IOrganisation, refreshFn?: () => void) {
    super();
    this.appService = appService;
    this.organisation = organisation;
    this.refreshFn = refreshFn;
    this.tableModel = new TableModel();
    this.setTable() /** WHAT EXACTLY ARE WE SETTING */;
    this.programmeProvider = this.appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.organisationContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.searchPanelConfig = {
      label: <h2 className="mb-0">{I18n.t("phrases.programmes")}</h2>,
      contentClassName: "divTable",
      link: (
        <CanEditOrg>
          <LinkButton data-testid="create-programme-button" href="/create/programmes" type="outline-primary">
            <Icon className="mr-2" symbol="plus" />
            {I18n.t("phrases.createProgramme")}
          </LinkButton>
        </CanEditOrg>
      ),
      items: null as any,
      tableModel: this.tableModel,
      searchAttribute: "name"
    };
    this.model = new SearchPanelModel(this.searchPanelConfig);
  }

  setTable = () => {
    let tableConfig: ITableConfig<FP.Entities.IProgramme> = {
      actions: [
        {
          id: "action123",
          label: I18n.t("phrases.manage"),
          hrefFn: (obj: any) => {
            return `/organisations/${obj.rowObject.organisationId}/programmes/${obj.rowObject.id}/settings`;
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "HTMLAnchor"
        }
      ],
      colHeaders: [
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
          selector: (obj: FP.Entities.IProgramme) => obj.name
        },
        {
          key: "stakeholderOwner",
          content: <h4 className="mb-0">{I18n.t("table.owner")}</h4>,
          selector: (obj: FP.Entities.IProgramme) => {
            return obj.stakeholderOwner.firstName + " " + obj.stakeholderOwner.lastName;
          }
        },
        {
          key: "startDate",
          content: <h4 className="mb-0">{I18n.t("table.startDate")}</h4>,
          selector: (obj: FP.Entities.IProgramme) => {
            return moment(obj.startDate).format("L");
          }
        },
        {
          key: "endDate",
          content: <h4 className="mb-0">{I18n.t("table.endDate")}</h4>,
          selector: (obj: FP.Entities.IProgramme) => {
            return moment(obj.actualEndDate).format("L");
          }
        }
      ] as any,
      initData: null as any,
      onRowClick: row => {
        // this.organisationContext.setCurrentOrganisation(this.organisation);
        appHistory.push(
          `/organisations/${this.organisation.id}/programmes/${(row.rowObject as FP.Entities.IProgramme).id}`
        );
      },
      tableProps: {}
    };

    this.tableModel.set(tableConfig);
  };

  @action
  setItems = (items: FP.Entities.IProgramme[]) => {
    this.model.setConfig({ items });
    this.tableModel.setData(items);
  };
}
