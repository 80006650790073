import * as React from 'react';

const SvgHome = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        stroke="currentColor"
        strokeWidth={1}
        viewBox="0 0 23 23"
        className="home_svg__feather home_svg__feather-home"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.5 1a.872.872 0 00-.604.242L1.27 10.43a.875.875 0 00.604 1.508h.875v9.187c0 .483.392.875.875.875H8a.875.875 0 00.875-.875V15h5.25v6.125c0 .483.392.875.875.875h4.375a.875.875 0 00.875-.875v-9.188h.875a.875.875 0 00.604-1.507l-9.625-9.188A.872.872 0 0011.5 1m0 1l9.5 9.068h-1.727V21h-4.318v-6.91h-6.91V21H3.727v-9.932H2L11.5 2"
        />
    </svg>
);

export default SvgHome;
