import * as React from "react";
import { observer } from "mobx-react-lite";
import { B } from "../../../core/util/BootstrapHelper";
import { ISearchPanelModel } from "./SearchPanel_model";
import { Icon } from "../../ui/Icon";
import { Input } from "../../ui/_forms/Input";
import { ButtonIcon } from "../../ui/Button";
import { Enums } from "../../../enums";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { TableView } from "../../../core/table/Table_view";
import { CanEditOrg } from "../../hoc/CanEdit";

export interface SearchPanelViewProps {
  model: ISearchPanelModel;
}

const SearchPanelView: React.FunctionComponent<SearchPanelViewProps> = observer(props => {
  const cls = B().bl("search-panel-view");
  const { model } = props;

  if (model.filteredItems === null) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`${cls.bem}`}>
      <div className={`${cls.el("block").pmod("label-wrapper").bem}`}>
        <div className="d-flex align-items-center" style={{ width: "100%" }}>
          <div className={`${cls.el("label").bem}`}>{model.config.label}</div>

          <div className={`${cls.el("search-icon").bem} ml-3`} onClick={model.showSearchMode}>
            <Icon symbol="Search" size={Enums.UiSizes.SM} />
          </div>

          {model.isSearchModeOn ? (
            <div className={`${cls.el("search-bar").bem}`}>
              <Input
                autoFocus={true}
                size={-1}
                onChange={model.updateSearchValue}
                borderStyle="underline"
                value={model.searchValue}
                onBlur={model.searchValue ? undefined : model.hideSearchMode}
              />
              <ButtonIcon
                className={`${cls.el("close-icon").bem}`}
                symbol="Close"
                onClick={model.hideSearchMode}
                type="link"
              />
            </div>
          ) : (
            <div className="ml-auto">
              <CanEditOrg>{model.config.link}</CanEditOrg>
            </div>
          )}
        </div>
      </div>
      <div className={`${cls.el("block").pmod("items").bem} ${model.config.contentClassName || ""}`}>
        {model.config.tableModel && <TableView model={model.config.tableModel} />}
      </div>
    </div>
  );
});

export { SearchPanelView };
