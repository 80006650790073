import * as React from "react";
import { ITableConfig } from "../../../../../core/table/ITableModel";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { CustomPropertiesModel } from "./CustomProperties_model";
import { Checkbox } from "../../../../../components/ui/Checkbox";


interface CheckboxCustomPropertyIsUsedOnAllProps {
  obj: FP.Entities.ICustomProperty;
  onChange: (value: boolean) => Promise<FP.Entities.ICustomProperty>;
}

export const CheckboxCustomPropertyIsUsedOnAll: React.FC<CheckboxCustomPropertyIsUsedOnAllProps> = ({ obj, onChange }) => {
  let [isChecked, setIsChecked] = React.useState(obj.isRequired);
  return <Checkbox
    checked={isChecked}
    onChange={async (e) => {
      const s = e.currentTarget.checked;
      setIsChecked(s);
      await onChange(s);
    }}
  />
}

interface CheckboxCustomPropertyIsRequiredProps {
  obj: FP.Entities.ICustomProperty;
  onChange: (value: boolean) => Promise<FP.Entities.ICustomProperty>;
}

export const CheckboxCustomPropertyIsRequired: React.FC<CheckboxCustomPropertyIsRequiredProps> = ({ obj, onChange }) => {
  let [isChecked, setIsChecked] = React.useState(obj.isRequired);
  return <Checkbox
    checked={isChecked}
    onChange={async (e) => {
      const s = e.currentTarget.checked;
      setIsChecked(s);
      await onChange(s);
    }}
  />
}

export const GetCustomPropertiesTableConfig = (
  parentModel: CustomPropertiesModel
): ITableConfig<FP.Entities.ICustomProperty> => {
  return {
    colHeaders: [
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "customEntityType",
        content: <h4 className="mb-0">{I18n.t("table.customPropertyEntityTypeName")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.entityType.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "customPropertyType",
        content: <h4 className="mb-0">{I18n.t("table.customPropertyTypeName")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.propertyType.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
    ] as any[],
    actions: parentModel.permissionService.canContributeOrg
      ? [
        {
          id: "action1",
          label: I18n.t("phrases.manage"),
          rendersIn: "HTMLAnchor",
          componentProps: {
            type: "link"
          },
          hrefFn: context => `/organisations/${parentModel.organisationId}/settings/customProperties/${context.rowObject.id}`
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          rendersIn: "Button",
          componentProps: {
            type: "link"
          },
          onAction: (ev, row: ITableRowModel) => {
            parentModel.showConfirmDeleteModal(row.rowObject);
          }
        }
      ]
      : []
  };
};
