import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { GetChangePasswordFields } from "./ChangePassword_fields";
import { IUsersApi } from "../../../../../services/api/v1/users/IUsers.api";
import { Services } from "../../../../../constants";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { observable, action } from "mobx";

export class SecuritySettingsModel extends BaseModel {
  formModel: SingleFormModel;
  usersProvider: IUsersApi;
  httpProgress: IHttpProgressModel;
  routeProps: RouteComponentProps;
  @observable errorMessage: string = "";
  organisationId: number;
  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, organisationId: number) {
    super();

    this.routeProps = routeProps;

    this.formModel = new SingleFormModel();
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.usersProvider = appService.getService<IUsersApi>(Services.UsersApi);
    this.formModel.formFields = GetChangePasswordFields();
    this.organisationId = organisationId;
  }

  @action
  resetErrorMessage = () => {
    this.errorMessage = "";
  };

  @action
  updatePassword = async () => {
    this.resetErrorMessage();
    let res = await this.formModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.usersProvider.setPassword(this.organisationId, res.currentPassword, res.newPassword);
    this.httpProgress.hideOverlay();
    if ((result.payload as any).error) {
      this.errorMessage = (result.payload as any).message
        .split(":")
        .filter((e, i) => i !== 0)
        .map((e, i) => (i === 0 ? e : ": " + e))
        .join("");
    } else if (result && result.payload) {
      this.routeProps.history.push("/");
    }
  };
}
