import { observable, action, computed } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { PositionedSpinner } from "../../components/ui/PositionedSpinner";
import { Services } from "../../constants";
import { Enums } from "../../enums";
import { gEntities } from "../../FlightPathEntities";
import { IOrganisationsApi } from "../../services/api/v1/organisations/IOrganisations.api";
import { useUserIdentity } from "../../setup";
import { ICookieService } from "../util/CookieService";
import { IClaim, useClaims } from "./authorise";
import { FlightPathUserOrganisationIdsClaimKey } from "./constants";

type IOrganisationContext = {
  refetchCount: number;
  currentOrganisationId: number;
  organisationIds: number[];
  projects: FP.Entities.IProject[];
  programmes: FP.Entities.IProgramme[];
  currentOrganisation: null | FP.Entities.IOrganisation;
  organisations: FP.Entities.IOrganisation[];
  getCurrentOrganisation: () => FP.Entities.IOrganisation;
  setCurrentOrganisationId: (id: number) => void;
  loadProjects(): Promise<void>;
  loadProgrammes(): Promise<void>;
  refetch(): void;
};

const OrganisationContext = React.createContext<IOrganisationContext>({
  currentOrganisationId: 0,
  currentOrganisation: null,
  refetch: () => { },
  loadProgrammes: () => Promise.resolve(),
  getCurrentOrganisation: () => null as any,
  loadProjects: () => Promise.resolve(),
  programmes: [],
  projects: [],
  refetchCount: 0,
  organisations: [],
  organisationIds: [],
  setCurrentOrganisationId: (id: number) => { }
});

export class OrganisationContextModel implements IOrganisationContext {
  organisationsProvider: IOrganisationsApi;
  localStorageService: ICookieService;
  @observable refetchCount = 0;
  @observable currentOrganisationId: number = 0;
  @observable.ref organisationIds: number[] = [];
  @observable.ref projects: gEntities.IProject[] = [];
  @observable.ref programmes: gEntities.IProgramme[] = [];
  @computed get currentOrganisation(): gEntities.IOrganisation {
    return this.organisations.find(e => `${e.id}` === `${this.currentOrganisationId}`) || null;
  }
  @observable.ref organisations: gEntities.IOrganisation[] = [];

  constructor(appService: AppService) {
    this.localStorageService = appService.getService<ICookieService>(Services.CookieService);
    this.organisationsProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
  }
  getCurrentOrganisation() {
    return this.currentOrganisation;
  }

  @action.bound
  async loadProjects(): Promise<void> {
    const res = await this.organisationsProvider.getProjects(this.currentOrganisation.id);
    if (res && !res.isError) {
      this.setProjects(res.payload);
    }
  }

  @action.bound
  setProjects(projects: FP.Entities.IProject[]) {
    this.projects = projects;
  }

  @action.bound
  async loadProgrammes(): Promise<void> {
    const res = await this.organisationsProvider.getProgrammes(this.currentOrganisation.id);

    if (res && !res.isError) {
      this.setProgrammes(res.payload);
    }
  }

  @action.bound
  setProgrammes(programmes: FP.Entities.IProgramme[]) {
    this.programmes = programmes;
  }

  @action.bound
  setCurrentOrganisationId(id: number) {
    this.currentOrganisationId = id;
    this.localStorageService.setCookie(Enums.LocalCookies.ORGANISATION_ID, `${id}`, 365);
  }

  @action.bound
  refetch() {
    this.refetchCount++;
  }

  @action.bound
  async initialize(organisationIds: number[], claims: IClaim[], initialOrganisationId?: number) {
    const filteredClaims = claims
      .filter(e => e.key.startsWith("organisation:"))
      .reduce((dict, curr) => {
        dict[curr.key] = curr.value;
        return dict;
      }, {});
    this.organisations = organisationIds
      .filter(id => filteredClaims[`organisation:${id}:name`])
      .map(id => {
        return {
          id,
          name: filteredClaims[`organisation:${id}:name`],
          profileImageUrl: filteredClaims[`organisation:${id}:profileImageUrl`],
          scope: filteredClaims[`organisation:${id}:scope`]
        } as any;
      });
    this.organisationIds = organisationIds;
    const currentOrganisationIdFromStorage = +this.localStorageService.getCookie(Enums.LocalCookies.ORGANISATION_ID);

    this.currentOrganisationId =
      initialOrganisationId ||
      organisationIds.find(e => `${e}` === `${currentOrganisationIdFromStorage}`) ||
      organisationIds[0];
  }
}

export const OrganisationContextProvider: React.FC<{
  initialOrganisationId?: number;
  model: OrganisationContextModel;
}> = observer(({ children, model: ctx, initialOrganisationId }) => {
  const organisationIds = useUserOrganisationIds();
  const claims = useClaims();

  useEffect(() => { }, [ctx, claims, organisationIds]);

  const initialize = useCallback(async () => {
    await ctx.initialize(organisationIds, claims, initialOrganisationId);
  }, [ctx, claims, organisationIds, initialOrganisationId]);

  return (
    <Async promiseFn={initialize}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <OrganisationContext.Provider value={ctx} key={ctx.currentOrganisationId}>
            {children}
          </OrganisationContext.Provider>
        )}
      </Async.Resolved>
    </Async>
  );
});

export const SetCurrentOrganisationFromRoute: React.FC = () => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const organisationIds = useCurrentOrganisationIds();
  const organisationContext = useOrganisationContext();

  if (!organisationIds.find(id => `${id}` === organisationId)) {
    throw new Error("Unauthorised access");
  }

  useEffect(() => {
    if (organisationId !== `${organisationContext.currentOrganisationId}`) {
      organisationContext.setCurrentOrganisationId(+organisationId);
    }
  }, [organisationId, organisationContext]);

  return null;
};

export const SetCurrentOrganisationIdToLastAccessedOrganisation: React.FC = () => {
  const organisationIds = useUserOrganisationIds();
  const organisationContext = useOrganisationContext();
  const lastAccessedOrganisationId = localStorage.getItem(Enums.LocalCookies.ORGANISATION_ID);
  const currentOrgnisationId = organisationContext.currentOrganisationId;
  let selectedOrganisationId = lastAccessedOrganisationId || organisationIds[0];

  selectedOrganisationId = organisationIds.find(e => `${e}` === `${selectedOrganisationId}`) || organisationIds[0];

  if (!selectedOrganisationId) {
    throw new Error("Unauthorized access");
  }

  useEffect(() => {
    if (selectedOrganisationId && `${currentOrgnisationId}` !== `${selectedOrganisationId}`) {
      organisationContext.setCurrentOrganisationId(+selectedOrganisationId);
    }
  }, [selectedOrganisationId, currentOrgnisationId, organisationContext]);

  return null;
};

export function useCurrentOrganisationId() {
  return useContext(OrganisationContext).currentOrganisationId;
}

export function useCurrentOrganisationIds() {
  return useContext(OrganisationContext).organisationIds;
}

export function useOrganisationContext() {
  return useContext(OrganisationContext);
}

export function useUserOrganisations() {
  return useContext(OrganisationContext).organisations;
}

export function useCurrentOrganisation() {
  return useOrganisationContext().currentOrganisation;
}

export function useUserOrganisationIds() {
  const profile = useUserIdentity();
  return (profile[`${FlightPathUserOrganisationIdsClaimKey}`] as number[]) || [];
}
