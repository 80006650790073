import * as React from "react";
import { AppService } from "strikejs-app-service";
import { ImpactFormSectionView } from "./ImpactFormSection_view";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { getTopFormFields, getRadioFields, getBottomForm, getProjectFormField } from "./ImpactFormSection_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Services } from "../../../../constants";
import { IProgrammesApi } from "../../../../services/api/v1/programmes/IProgrammes.api";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { RouteComponentProps } from "react-router-dom";
import { BaseModel } from "../../../../core/util/BaseModel";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { observable } from "mobx";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";
import { SingleFormModel } from "../singleFormModel/SingleForm_model";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";

export class ImpactFormSectionModel extends BaseModel {
  @observable projectForm: IFormFieldModel<any, any>[] = [];
  projectProvider: IProjectsApi;
  organisationContext: IOrganisationContextModel;
  orgProvider: IOrganisationsApi;
  programmeProvider: IProgrammesApi;
  topForm: SingleFormModel;
  impactForm: SingleFormModel;
  bottomForm: SingleFormModel;
  appService: AppService;
  stakeholderGroupProvider: IStakeholderGroupsApi;
  orgId: number;
  routeProps: RouteComponentProps;
  isTestUser: boolean;
  impact: FP.Entities.IImpact = null;
  config = {
    showActionsInView: false,
    projectId: null,
    isFullWidth: false
  };
  @observable projectId: number = null;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  impactGroupsProvider: IImpactGroupsApi;

  get actions(): IUiAction<any>[] {
    return [
      {
        id: "no",
        label: "Cancel",
        onAction: () => { },
        rendersIn: "Button",
        componentProps: {
          type: "link",
          className: "ml-2"
        }
      },
      {
        id: "save",
        label: "Save",
        onAction: async () => {
          await this.submit();
        },
        rendersIn: "Button",
        componentProps: {
          type: "primary",
          className: "ml-2"
        }
      }
    ];
  }

  constructor(appService: AppService, routeProps: RouteComponentProps, isTestUser: boolean, initConfig?: any, impact?: FP.Entities.IImpact) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.impact = impact || this.impact;
    this.isTestUser = isTestUser;
    if (initConfig) {
      this.setConfig(initConfig);
    }
    this.onSectionInstall();
  }

  setConfig = (opt: any): void => {
    this.config = { ...this.config, ...opt };
  };

  onSectionInstall = (): void => {
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.programmeProvider = this.appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.stakeholderGroupProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.projectStakeholdersProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.organisationContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.impactGroupsProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.projectId = this.routeProps.match.params["id"];
    this.orgId = parseInt(
      this.appService.getService<ICookieService>(Services.CookieService).getCookie(Enums.LocalCookies.ORGANISATION_ID)
    );

    if (this.projectId) {
      this.setFormFields(this.projectId);
    } else {
      this.projectForm = getProjectFormField(this.orgProvider, this.orgId, this.setFormFields);
    }
  };

  setFormFields = projectId => {
    this.topForm = new SingleFormModel();
    this.topForm.formFields = getTopFormFields(projectId, this.isTestUser, this.impact);

    this.impactForm = new SingleFormModel();
    this.impactForm.formFields = getRadioFields(this.orgId, this.impact);

    this.bottomForm = new SingleFormModel();
    this.bottomForm.formFields = getBottomForm(this.orgProvider, this.orgId, this.projectProvider, projectId, this.projectStakeholdersProvider, this.stakeholderGroupProvider, this.impactGroupsProvider, this.impact);

    this.projectId = projectId;
  };

  onMount = () => { };

  submit = async () => {
    let topF = await this.topForm.submit();
    let bottomF = await this.bottomForm.submit();
    let impactF = await this.impactForm.submit();
    if (topF && bottomF && impactF) {
      let formResult = { ...topF, ...impactF, ...bottomF }
      return formResult
    }
    return null;
  };

  renderComponent = (): React.ReactNode => {
    return <ImpactFormSectionView model={this} />;
  };
}
