import React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";
import { Avatar, AvatarProps } from "../Avatar";

export interface AvatarContentProps extends React.HTMLProps<HTMLDivElement> {
  avatarProps: AvatarProps;
  type?: "primary";
}

const AvatarContent: React.FunctionComponent<AvatarContentProps> = ({
  className,
  type,
  avatarProps,
  children,
  ...props
}) => {
  const cls = B().bl("avatar-content");

  return (
    <div {...props} className={`${className || ""} ${cls.pmod(type || "primary").bem}`}>
      <div className={`${cls.el("block").pmod("avatar-wrapper").bem}`}>
        <Avatar
          size={avatarProps.size || UiSizes.SM}
          borderType="border-primary"
          className={`${avatarProps.className || ""} ${cls.el("avatar").bem}`}
          {...avatarProps}
        />
      </div>
      <div className={`${cls.el("block").pmod("content").bem}`}>{children}</div>
    </div>
  );
};

export { AvatarContent };
