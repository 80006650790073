import { FormFieldModel } from "../../formField/FormField_model";
import { IDropdownModel } from "./IDropdownModel";
import { observable, action } from "mobx";
import { FormFieldType } from "../../formField/FormFieldTypes";
import * as React from 'react';
import { INIT_FORM_FIELD } from "../../formField/FormField_init";
import Dropdown from '../../../../components/ui/_forms/Select';

export const INIT_DROPDOWN: Partial<IDropdownModel> = {
    ...INIT_FORM_FIELD,
    type: FormFieldType.Dropdown,
    defaultOption: { key: "", label: "Select Option" }
}



export class DropdownModel extends FormFieldModel<string, React.HTMLProps<HTMLSelectElement>> implements IDropdownModel {
    @observable value: string = "";
    @observable options: FP.Generic.IKeyLabel[] = [];
    defaultOption?: FP.Generic.IKeyLabel = null;

    readonly type: FormFieldType = FormFieldType.Dropdown;

    constructor(initOpts?: IDropdownModel) {
        super(initOpts);
        if (initOpts) {
            this.value = initOpts.value || this.value;
            this.options = initOpts.options || this.options;
            this.validate = initOpts.validate || this.validate;
            this.defaultOption = initOpts.defaultOption || this.defaultOption;
        }
    }

    @action.bound
    setValue(val: string): void {
        this.hasChanges = true;
        this.value = val;

        if (this.validateOnChange && this.validate) {
            this.validate(this.value);
        }
    }

    validate: (...args: any[]) => any;

    setFieldValue = (ev: React.SyntheticEvent<any>): void => {
        let val = ev.currentTarget.value;
        this.setValue(val);
    }

    extractValue() {
        return this.value;
    }

    reset(): void {
        this.value = this.defaultOption.key || null;
    }

    renderComponent = () => {
        return <Dropdown.Select name={this.key}
            id={this.key}
            value={this.value}
            onChange={this.setFieldValue}
        >
            {
                this.defaultOption && <Dropdown.Option value={this.defaultOption.key}>
                    {this.defaultOption.label}
                </Dropdown.Option>
            }
            {
                this.options && this.options.map((e) => {
                    return <Dropdown.Option value={e.key} key={e.key}>{e.label}</Dropdown.Option>
                })
            }
        </Dropdown.Select>
    };


}