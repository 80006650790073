import * as React from "react";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Panel } from "../../../../components/ui/Panel";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Icon } from "../../../../components/ui/Icon";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { Enums } from "../../../../enums";
import { Link } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface ConcernCardProps {
  item: FP.Entities.IConcern;
  actions?: IUiAction<FP.Entities.IConcern>[];
}

const ConcernCard: React.FunctionComponent<ConcernCardProps> = ({ item, actions }) => {
  const organisationId = useCurrentOrganisationId();
  return (
    <Panel background="bg-white" hasBorderRadius={true} className="concern-card p-4 mb-2">
      <div className="row">
        <div className="col">
          <Link to={`/organisations/${organisationId}/users/${item.owner?.id}`}>
            <AvatarContent
              className="d-inline-flex"
              avatarProps={{
                imgSrc: item.owner?.profileImageUrl
              }}
            >
              {item.owner?.firstName} {item.owner?.lastName}
            </AvatarContent>
          </Link>

          {actions?.length > 0 && (
            <Tooltip
              position="left"
              className="float-right ml-2"
              shownElement={<Icon symbol="DotsHorizontal" />}
              triggeredOn="click"
            >
              <Panel background="bg-white" hasShadow={true}>
                {actions.map(action => {
                  return <UiActionComponent key={action.id} action={action} contextModel={item} />;
                })}
              </Panel>
            </Tooltip>
          )}
          <h5 className="float-right text-uppercase mb-0 mt-1">{Enums.Translator.ConcernStatus(item.status)}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col" dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.summary) }} />
      </div>
    </Panel>
  );
};

export { ConcernCard };
