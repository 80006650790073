import { IModel, BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { INavigationModel } from "../../../../components/widgets/navigation/Navigation_model";
import { Services } from "../../../../constants";

export interface ICreateNavModel extends IModel {
  appService: AppService;
  previousContent: () => void;
  goNavBack: () => void;

  /**
   * This is the url before the user clicked one of the create links
   * this is what we will redirect to when the back button is pressed
   */
  previousUrl: string;
}

// TODO: potentially look at removing this model
export class CreateNavModel extends BaseModel implements ICreateNavModel {
  appService: AppService;
  previousUrl: string;
  navigationModel: INavigationModel;
  previousContent: () => void = null;

  constructor(appService: AppService) {
    super();

    this.appService = appService;

    this.navigationModel = appService.getService<INavigationModel>(Services.NavigationModel);
  }

  goNavBack = () => {
    this.navigationModel.onBack();
  };
}
