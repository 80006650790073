import {
  locationSearchFeatureProvider,
  sessionStorageFeatureProvider,
  localStorageFeatureProvider
} from "./providers";

export function isFeatureEnabled(
  name: string,
  providers = [
    locationSearchFeatureProvider,
    localStorageFeatureProvider,
    sessionStorageFeatureProvider
  ]
) {
  return providers.some(isEnabled => isEnabled(name));
}

export const isFeatureEnabledFactory = (
  ...providers: ((name: string) => boolean)[]
) => (name: string) => isFeatureEnabled(name, providers);
