import * as React from 'react';

const SvgAtSign = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="at-sign_svg__feather at-sign_svg__feather-at-sign"
        {...props}
    >
        <circle cx={12} cy={12} r={4} />
        <path d="M16 8v5a3 3 0 006 0v-1a10 10 0 10-3.92 7.94" />
    </svg>
);

export default SvgAtSign;
