import React, { useEffect, useState } from "react";
import { ReportsViewModel } from "./ReportsView_model";
import { ReportCard } from "../../../../components/widgets/reportCard/ReportCard_view";
import I18n from "../../../../core/localization/I18n";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../AppService";
import { Async } from "react-async";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ReportsViewProps {
  model?: ReportsViewModel;
}

export const ReportsView: React.FunctionComponent<ReportsViewProps> = observer(({ model: m }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ReportsViewModel(appService, +projectId, organisationId));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <Async promiseFn={model.loadProject}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <>
          <div className={`container-fluid pt-6 pb-5 ${Animations.FP_ZOOM_IN} speed-4 bg-light`}>
            <div className="row">
              <div className="col">
                <h1>
                  {I18n.t("phrases.reporting")} | <strong>{model.project?.name}</strong>
                </h1>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-5">
            <div className="row">
              {model.reports.map(report => {
                return (
                  <div className="col-6 mb-5" key={report.config?.key}>
                    <ReportCard model={report} />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      </Async.Resolved>
    </Async>
  );
});
