import React from "react";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import moment from "moment";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD, ListingModel } from "../../../../core/forms/controls/listing/Listing_model";
import { AutocompleteModel } from "../../../../core/forms/controls/autocomplete/Autocomplete_model";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import _ from "lodash";
import I18n from "../../../../core/localization/I18n";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { Enums } from "../../../../enums";
import { IDropdownModel } from "../../../../core/forms/controls/dropdown/IDropdownModel";
import { PROGRESS_STATUS_OPTIONS } from "../../../../constants";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";

export const getProjectInitFormFields = (
  organisationId: number,
  orgProvider: IOrganisationsApi,
  project?: FP.Entities.IProject
): IFormFieldModel<any, any>[] => {
  const programme: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "programmeId",
    label: <label htmlFor={"programme"}>Assign programme: </label>,
    placeholder: "Select programme",
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getProgrammes(organisationId);

      if (res?.payload) {
        const sortedProgrammes = _.orderBy(res.payload, [programme => programme.name.toLowerCase()]);
        self.setOptions(sortedProgrammes);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: (obj: FP.Entities.IProgramme) => obj.name,
    searchAttribute: "name",
    fieldClassName: "col-lg-6 mb-0",
    value: project?.programme
  };

  const fields = [];
  fields.push(programme);

  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getProjectFormFields = (
  organisationId: number,
  orgProvider: IOrganisationsApi,
  user?: FP.Entities.IUser,
  project?: FP.Entities.IProject
): IFormFieldModel<any, any>[] => {
  let actualEndDate = {
    ...INIT_TEXT_FIELD,
    key: "actualEndDate",
    inputType: "hidden",
    value: project?.actualEndDate
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: "Enter Project title",
    label: <label htmlFor="name">{I18n.t("forms.projectName")} *</label>,
    value: project?.name,
    fieldClassName: "col-lg-6",
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.projectName")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  const stakeholderOwner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderOwnerId",
    label: <label htmlFor="stakeholderOwnerId">{I18n.t("forms.projectOwner")} *</label>,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: "user"
    },
    charInputNumber: 1,
    valueLabelFn: (obj: FP.Entities.IStakeholder) => `${obj.firstName} ${obj.lastName} (${obj.email})`,
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getStakeholders(organisationId, { pageSize: 10000, page: 1 });

      if (res?.payload) {
        const stakeholders = res.payload.filter(e => e.stakeholderType === Enums.StakeholderType.INDIVIDUAL);
        const sortedStakeholders = _.orderBy(stakeholders, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        self.setOptions(sortedStakeholders);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    validate: function () {
      let self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.projectOwner")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: "col-lg-6",
    value: project?.stakeholderOwner,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")} *</label>,
    placeholder: "dd/mm/yyyy",
    fieldClassName: "col-lg-6",
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.startDate")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: "calendar",
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    value: project && moment(project.startDate)
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "initialEndDate",
    label: <label htmlFor="initialEndDate">{I18n.t("forms.endDate")} *</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: "col-lg-6",
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: "calendar",
      datePickerProps: project && {
        isOutsideRange: day => {
          return day < moment(project.startDate);
        }
      }
    },
    value: project && moment(project.actualEndDate),
    subscribeTo: ["startDate", "progressStatus", "actualEndDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "startDate") {
        const sd = field.value;
        if (sd) {
          self.setDatepickerProps({
            isOutsideRange: day => {
              return day < sd;
            }
          });
        }
      }
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDate")}</ErrorMessage>;
        return false;
      }

      if (startDate > this.value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    extractValue: function () {
      const progress = this.channels.progressStatus.extractValue();
      this.channels.actualEndDate.value = this.value?.format(this.exportedDateFormat);
      if (!project || progress === Enums.ProgressStatus.NOT_STARTED) {
        this.key = "initialEndDate";
        return this?.value?.format(this.exportedDateFormat);
      } else {
        this.key = "disableInitialDate";
        return this.channels.actualEndDate.value;
      }
    }
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")} *</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IDropdownModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.progressStatus")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12 col-lg-6",
    extractValue: function () {
      return this.value?.key;
    },
    value: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(
      e => e.key === project?.progressStatus
    )
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.overview")}</label>,
    placeholder: I18n.t("placeholders.overview"),
    fieldClassName: "col-lg-12",
    value: project?.description,
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.projectOverviewHint")}</p>
  };

  const objectives: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "objectivesText",
    label: <label htmlFor="objectivesText">{I18n.t("forms.objectives")}</label>,
    placeholder: I18n.t("placeholders.projectObjectives"),
    fieldClassName: "col-lg-12",
    value: project?.objectivesText || I18n.t("placeholders.defaultObjectives"),
    defaultValue: I18n.t("placeholders.defaultObjectives"),
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.projectObjectivesHint")}</p>
  };

  const teamSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "teamSearch",
    label: <label htmlFor={"teamSearch"}>{I18n.t("forms.projectTeam")} </label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    charInputNumber: 1,
    componentProps: {
      className: "form-control",
      icon: "user"
    },
    onFocus: async function (model: IAutocompleteModel) {
      const res = await orgProvider.getUsers(organisationId);

      if (res?.payload) {
        const sortedUsers = _.orderBy(res.payload, [
          user => user.firstName.toLowerCase(),
          user => user.lastName.toLowerCase()
        ]);
        model.setOptions(sortedUsers);
      }
    },
    subscribeTo: ["keySystemUsers"],
    onItemSelected: async function () {
      let self: AutocompleteModel = this;
      let listingModel: ListingModel = self.channels.keySystemUsers as ListingModel;
      let val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    valueLabelFn: e => "",
    shouldClearOnBlur: true,
    fieldClassName: "col-lg-6",
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  const keySystemUsers: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "keySystemUsers",
    placeholder: I18n.t("placeholders.selectProjectTeam"),
    label: <label htmlFor={"keySystemUsers"}>{I18n.t("forms.selectedProjectTeam")}</label>,
    fieldClassName: "col-lg-6",
    selector: (e: FP.Entities.IStakeholder) => (
      <p className="mb-0 d-inline-block">{`${e.firstName} ${e.lastName} (${e.email})`}</p>
    ),
    value: project ? project.keySystemUsers : user ? [user] : [],
    extractValue: function () {
      return (this.value && this.value.map(e => e.id)) || [];
    }
  };

  let location: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "locations",
    label: <label htmlFor={"locations"}>{I18n.t("forms.locations")} *</label>,
    manageLink: `/organisations/${organisationId}/settings/locations`,
    placeholder: I18n.t("placeholders.searchLocation"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getLocations(organisationId);
      if (res?.payload) {
        const sortedLocations = _.orderBy(res.payload, [location => location.name.toLowerCase()]);
        self.setOptions(sortedLocations);
      }
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: "col-12 col-lg-6",
    valueLabelFn: e => e?.name,
    value: project && project.locations,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.projectLocations")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  let shouldAddProgramme: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "controlQuestion",
    label: <label htmlFor="controlQuestion">{I18n.t("forms.shouldAddProjectToProgramme")}</label>,
    fieldClassName: project?.programme ? "col-12 col-lg-6" : "col-12 col-lg-6 col-space-lg-6",
    options: [
      {
        key: "controlQuestionsYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "controlQuestion"
        }
      },
      {
        key: "controlQuestionsNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "controlQuestion"
        }
      }
    ],
    onValueChange: function (val) {
      if (val === "yes") {
        this.fieldClassName = "col-12 col-lg-6";
        return;
      }
      this.fieldClassName = "col-12 col-lg-6 col-space-lg-6";
    },
    value: project?.programme ? "yes" : "no"
  };

  let programmeId: Partial<IAutocompleteModel | ITextFieldModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "programmeId",
    label: <label htmlFor={"programme"}>{I18n.t("forms.chooseProgramme")} </label>,
    placeholder: I18n.t("placeholders.searchProgramme"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getProgrammes(organisationId);

      if (res?.payload) {
        const sortedProgrammes = _.orderBy(res.payload, [programme => programme.name.toLowerCase()]);
        self.setOptions(sortedProgrammes);
      }
    },
    extractValue: function () {
      return this.isHidden ? null : this.value?.id;
    },
    valueLabelFn: (obj: FP.Entities.IProgramme) => obj.name,
    searchAttribute: "name",
    isHidden: !project || project?.programme === null,
    fieldClassName: "col-lg-6 mb-0",
    value: project?.programme
  };

  let isPrivate: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isPrivate",
    label: <label htmlFor="isPrivate">{I18n.t("forms.isProjectPrivate")}</label>,
    fieldClassName: "col-12 col-lg-6",
    options: [
      {
        key: "isPrivateYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "isPrivate"
        }
      },
      {
        key: "isPrivateNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "isPrivate"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes";
    },
    onValueChange: function (val) {
      if (val === "yes") {
        this.fieldClassName = "col-12 col-lg-6";
        return;
      }
      this.fieldClassName = "col-12 col-lg-6 col-space-lg-6";
    },
    value: project?.isPrivate ? "yes" : "no"
  };

  const fields = [];

  // if (programme) {
  //     programmeId = {
  //         ...INIT_TEXT_FIELD,
  //         key: "programmeId",
  //         inputType: "hidden",
  //         value: programme && (programme.id + "")
  //     }
  //     fields.push(programmeId);
  // }

  if (organisationId) {
    const orgId: Partial<ITextFieldModel> = {
      ...INIT_TEXT_FIELD,
      key: "organisationId",
      inputType: "hidden",
      value: organisationId + "",
      defaultValue: organisationId + ""
    };
    fields.push(orgId);
  }

  fields.push(name);
  fields.push(stakeholderOwner);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(actualEndDate);
  fields.push(progressStatus);
  fields.push(location);
  fields.push(description);
  fields.push(objectives);
  // fields.push(keyStakeholderSearch);
  // fields.push(keyStakeholders)
  fields.push(teamSearch);
  fields.push(keySystemUsers);
  fields.push(shouldAddProgramme);
  fields.push(programmeId);
  fields.push(isPrivate);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
