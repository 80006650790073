import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";


export class PreferencesSettingsModel extends BaseModel {

    /**
     *
     */
    constructor(appService: AppService, routeProps: RouteComponentProps) {
        super();


    }
}