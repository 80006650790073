import React, { useEffect, useState } from "react";
import { AddImpactGroupModel } from "./AddImpactGroup_model";
import { LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { Panel } from "../../../../components/ui/Panel";
import { ImpactGroupFormSectionView } from "../../forms/impactGroups/ImpactGroupFormSection_view";
import I18n from "../../../../core/localization/I18n";
import { useAppService } from "../../../../AppService";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface AddImpactGroupProps {
  model?: AddImpactGroupModel;
}

export const AddImpactGroup: React.FC<AddImpactGroupProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => m || new AddImpactGroupModel(appService, +projectId, organisationId));
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId])

  return (
    <div className={`add-stakeholder pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisationId}/projects/${projectId}/impactGroups`}
            >
              <Icon className="mr-2" symbol="chevron-left" />
              {I18n.t("phrases.backToImpactGroups")}
            </LinkButton>
          </div>
        </div>

        <Panel background="bg-light" className="row py-4 mb-4">
          <div className="col-lg-7">
            <h1>
              {I18n.t("phrases.create")} <strong>{I18n.t("entities.impactGroup")}</strong>
            </h1>
            <p className="mb-0">{I18n.t("phrases.createImpactGroupDescription")}</p>
          </div>
        </Panel>

        <ImpactGroupFormSectionView model={model.impactGroupFormModel} />
      </div>
    </div>
  );
});
