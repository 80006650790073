import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { Services } from "../../../../constants";
import { TableModel } from "../../../../core/table/Table_model";
import { ITableModel, ITableConfig } from "../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import _ from "lodash";
import { Pill } from "../../../../components/ui/Pill";
import { observable, action } from "mobx";
import { IModalService } from "../../../../core/modal/IModalService";
import { ButtonIcon, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { ImpactGroupModal } from "../impactsView/ImpactGroupModal/ImpactGroupModal_view";
import { ImpactGroupModalModel } from "../impactsView/ImpactGroupModal/ImpactGroupModal_model";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Icon } from "../../../../components/ui/Icon";
import { PaginationModel, IPaginationModel } from "../../../../components/widgets/pagination/Pagination_model";
import { FilterModel, IFilterAttribute, FilterOperator, IFilterModel } from "../../../../core/filter/Filter_model";
import { GetImpactGroupListFilters } from "./ImpactGroupListViewFilter_fields";
import { ITableHeaderModel } from "../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../core/table/TableHeader_view";
import { IExportService, ExportService } from "../../../../services/local/export/ExportService";
import moment from "moment";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { Animations } from "../../../../core/util/Animations";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";

export class ImpactGroupsViewModel extends BaseModel {
  tableModel: ITableModel<FP.Entities.IImpact | FP.Entities.IImpactGroup>;
  projectProvider: IProjectsApi;
  impactGroupProvider: IImpactGroupsApi;
  routeProps: RouteComponentProps;
  appService: AppService;
  modalService: IModalService;
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IImpactGroup>;
  projectId: number;
  exportService: IExportService<FP.Entities.IImpactGroup>;
  @observable viewType: "groups" | "impacts" = "groups";
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  organisationSettings: IOrganisationSettingsService;
  showVisualisations: boolean;

  constructor(appService: AppService, routeProps: RouteComponentProps, organisationId: number) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactGroupProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.organisationSettings = this.appService.getService<IOrganisationSettingsService>(Services.OrganisationSettingsService);
    this.organisationId = organisationId;
    this.projectId = parseInt(routeProps.match.params["id"], 10);
    this.loadProject();
    this.loadShowVisualisations()
    this.installPagination();
    this.installFilter();
    this.installExportService();

    this.tableModel = new TableModel();
    this.setTable();
  }

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    this.filterModel = new FilterModel(this.appService, this.paginationModel, {
      filterCb: async (filterOptions) => await this.impactGroupProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
      projectId: this.projectId
    });

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const nameFilter: IFilterAttribute = {
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const refFilter: IFilterAttribute = {
      key: "refNumber",
      label: I18n.t("filters.refNumber"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const typeFilter: IFilterAttribute = {
      key: "impactType",
      label: I18n.t("filters.impactType"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ProgressStatus(parseInt(k));
      },
    };
    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addFilter(projectFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(refFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(typeFilter);
    this.filterModel.setConfig({
      formFields: GetImpactGroupListFilters,
      onDataLoaded: d => this.tableModel.setData(d)
    });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  loadShowVisualisations = async () =>
    this.showVisualisations = await this.organisationSettings.isVisualisationsEnabled(this.organisationId);

  onMount = () => {
    this.loadGroups();
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IImpactGroup>(this.appService, this.filterModel, {
      filename: `${_.kebabCase(I18n.t("entities.impactGroups"))}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.impactGroupProvider.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: [
        {
          key: "refNumber",
          label: I18n.t("table.refNo")
        },
        {
          key: "name",
          label: I18n.t("table.name")
        },
        {
          key: "changeBenefitText",
          label: I18n.t("table.changeBenefitText")
        },
        {
          key: "type",
          label: I18n.t("table.type"),
          selector: item => item.impactTypes
        },
        {
          key: "locations",
          label: I18n.t("table.locations"),
        },
        {
          key: "impactLevels",
          label: I18n.t("table.impactLevels")
        },
        {
          key: "impactLevelsValue",
          label: I18n.t("table.impactLevelsValues")
        },
        {
          key: "impactRefs",
          label: I18n.t("table.impacts"),
        }
      ]
    });
  };

  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  setTable = () => {
    let actions = [];
    let self = this;
    actions.push({
      id: "view",
      label: I18n.t("phrases.view"),
      rendersIn: "HTMLAnchor",
      componentProps: {
        type: "link"
      },
      hrefFn: context =>
        `/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups/${(context.rowObject as FP.Entities.IImpactGroup).id}`
    });
    if (this.permissionService.canEditProject(this.projectId)) {
      actions.push(
        {
          id: "edit",
          label: I18n.t("phrases.edit"),
          rendersIn: "HTMLAnchor",
          componentProps: {
            type: "link"
          },
          hrefFn: context =>
            `/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups/${(context.rowObject as FP.Entities.IImpactGroup).id}/edit`
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            this.showImpactConfirmDeleteModal(this.projectId, row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        }
      );
    }

    const tableConfig: ITableConfig<FP.Entities.IImpactGroup> = {
      actions,
      colHeaders: [
        {
          key: "refNumber",
          content: <h4 className="mb-0">{I18n.t("table.refNo")}</h4>,
          selector: (obj: FP.Entities.IImpactGroup) => {
            return (
              <p className="strong mb-0">
                <Icon symbol="chevron-right" className="mr-2" />
                {obj.refNumber}
              </p>
            );
          },
          action: function (model: ITableHeaderModel) {
            AddTableColumnSort(model, self.tableModel, self.filterModel);
          },
          showSortIcons: true
        },
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
          action: function (model: ITableHeaderModel) {
            AddTableColumnSort(model, self.tableModel, self.filterModel);
          },
          showSortIcons: true
        },
        {
          key: "impactType",
          content: <h4 className="mb-0">{I18n.t("table.type")}</h4>,
          selector: (obj: FP.Entities.IImpactGroup) => {
            return obj.nImpactTypes.map(e => e.name).join(", ");
          }
        },
        {
          key: "impactLevel",
          content: <h4 className="mb-0">{I18n.t("table.level")}</h4>,
          selector: (obj: FP.Entities.IImpactGroup) => {
            const level = Math.round(parseFloat(obj.impactLevels));

            if (level === 0) {
              return I18n.t("phrases.noImpacts");
            }

            return (
              <Pill style={{ width: "90px" }} type={`indicator-accent-${Enums.Translator.MetricToEnum(level)}` as any}>
                {Enums.Translator.ImpactLevel(level)}
              </Pill>
            );
          }
        }
      ] as any[],
      onRowClick: this.showModal,
      tableProps: {}
    };

    this.tableModel.set(tableConfig);
  };

  @action
  async loadGroups() {
    this.viewType = "groups";
    await this.filterModel.loadData();
    this.tableModel.setData(this.filterModel.data);
  }

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impact-visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts`);
      return;
    }
    this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups`);
  };

  @action
  showImpactConfirmDeleteModal = (projectId: number, impactGroup: FP.Entities.IImpactGroup) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeImpactGroupFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impactGroup.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          await this.removeImpactGroup(projectId, impactGroup.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  @action
  removeImpactGroup = async (projectId: number, impactGroupId: number) => {
    await this.impactGroupProvider.remove(this.organisationId, projectId, impactGroupId);

    this.loadGroups();
  };

  onUnmount = () => { };

  showModal = (row: ITableRowModel) => {
    const id = row.rowObject.id;
    this.showImpactGroupModal(id);
  };

  showImpactGroupModal = (id: number) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="mr-1 ml-auto"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impactgroups/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="pencil"
            onClick={this.modalService.hide}
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impactgroups/${id}/edit`}
          />
          <ButtonIcon
            key={"1"}
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="close"
            className=""
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: <ImpactGroupModal model={new ImpactGroupModalModel(this, this.projectId, id, this.organisationId)} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
      }
    });
  };
}
