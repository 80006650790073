import * as React from "react";
import { AccordionCollapse } from "./AccordionCollapse";
import { Icon, IconSymbol } from "../Icon";
import { Tooltip } from "../Tooltip";
import { Panel } from "../Panel";
import { Enums } from "../../../enums";

export interface AccordionProps {
  className?: string;
  id?: string;
  header: React.ReactNode;
  children: React.ReactNode;
  active?: boolean;
  side?: "left" | "right";
  hideArrow: boolean;
  isArrowInteractiveOnly: boolean;
  actions?: React.ReactNode;
  onClick?: () => void;
  isDynamicallyLoaded?: boolean;
  testId?: string;
}

export interface AccordionState {
  active: boolean;
}

interface IAccordion { }

export class Accordion extends React.Component<AccordionProps, AccordionState> implements IAccordion {
  constructor(props: any) {
    super(props);
    this.state = {
      active: this.props.active ? true : false
    };
  }

  public static defaultProps: Partial<AccordionProps> = {
    children: [],
    active: false,
    isArrowInteractiveOnly: false
  };

  toggleItem() {
    this.setState(
      {
        active: !this.state.active
      },
      this.props.onClick
    );
  }

  render() {
    let props = this.props,
      state = this.state,
      cls = props.className || "";

    const isActive = state.active;
    const activeCls = isActive ? "expanded" : "collapsed";
    const icon: IconSymbol = isActive ? "ChevronUp" : "ChevronDown";

    return (
      <div id={props.id} className={`accordion ${cls} accordion--${props.side || "right"}`}>
        <div className={`accordion-item accordion__item--${activeCls}`}>
          <div className={`accordion-item__header accordion-item__header--${activeCls}`}>
            <div onClick={() => !props.isArrowInteractiveOnly && this.toggleItem()}>
              {props.header}
              {!props.hideArrow && (
                <div className="accordion-item__icon" onClick={() => props.isArrowInteractiveOnly && this.toggleItem()}>
                  <Icon symbol={icon} size={Enums.UiSizes.MD} />
                </div>
              )}
            </div>
            {props.actions && (
              <div className="accordion__actions">
                <Tooltip
                  position="left"
                  shownElement={<Icon symbol="DotsHorizontal" />}
                  triggeredOn="click"
                  className="float-right"
                  testId={props.testId}
                >
                  <Panel background="bg-white" hasShadow={true}>
                    {props.actions}
                  </Panel>
                </Tooltip>
              </div>
            )}
          </div>
          <AccordionCollapse isDynamicallyLoaded={props.isDynamicallyLoaded} isOpen={isActive}>
            {props.children}
          </AccordionCollapse>
        </div>
      </div>
    );
  }
}
