import { IAutocompleteModel } from "./IAutocompleteModel";
import { INIT_TEXT_FIELD } from "../textField/TextField_init";
import { FormFieldType } from "../../formField/FormFieldTypes";

export const INIT_AUTOCOMPLETE: Partial<IAutocompleteModel> = {
  ...INIT_TEXT_FIELD,
  value: null,
  options: null,
  type: FormFieldType.Autocomplete,
  shouldClearOnBlur: false
} as any;
