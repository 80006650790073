import * as React from 'react';

const SvgFrown = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="frown_svg__feather frown_svg__feather-frown"
        {...props}
    >
        <circle cx={12} cy={12} r={10} />
        <path d="M16 16s-1.5-2-4-2-4 2-4 2M9 9h.01M15 9h.01" />
    </svg>
);

export default SvgFrown;
