import React from "react";
import _ from "lodash";
import { observable, action } from "mobx";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { Services } from "../../../../../constants";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { GetUserTableConfig } from "./UsersSettings_config";
import { IModalService } from "../../../../../core/modal/IModalService";
import { Enums } from "../../../../../enums";
import { Icon } from "../../../../../components/ui/Icon";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { IUiAction } from "../../../../../core/uiAction/IUiAction";
import { IFilteredOptions, INIT_FILTER_OPTIONS } from "../../../../../services/api/filteredApi/FilteredApiModel";
import { getUserFormFields } from "../../../forms/users/UserFormFields_data";
import I18n from "../../../../../core/localization/I18n";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../../core/filter/Filter_model";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { IOrganisationContextModel } from "../../../../../services/local/organisationContext/IOrganisationContextModel";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IExportService, ExportService } from "../../../../../services/local/export/ExportService";
import moment from "moment";
import { IPermissionService } from "../../../../../services/local/permissionService/IPermissionService";
import { Animations } from "../../../../../core/util/Animations";

export class UsersSettingsModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  tableModel: ITableModel<FP.Entities.IUser>;
  organisationsProvider: IOrganisationsApi;
  exportService: IExportService<FP.Entities.IUser>;
  modalService: IModalService;
  innerModalService: IModalService;
  httpProgress: IHttpProgressModel;
  filterOptions: IFilteredOptions = { ...INIT_FILTER_OPTIONS };
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IUser>;
  permissionService: IPermissionService;
  orgContext: IOrganisationContextModel;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable searchQuery: string = "";
  @observable.ref organisation: FP.Entities.IOrganisation;
  @observable.ref users: FP.Entities.IUser[];

  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.organisationId = parseInt(this.routeProps.match.params["organisationId"], 10);
    this.permissionService = this.appService.getService<IPermissionService>(Services.PermissionService);
    this.orgContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.tableModel = new TableModel();

    this.installPagination();
    this.installFilter();
    this.installExportService();
  }
  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    this.filterModel = new FilterModel(this.appService, this.paginationModel, {
      filterCb: async (filters: Partial<IFilteredOptions>) => {
        return await this.organisationsProvider.getAssociateUsers(this.organisationId, filters);
      }
    });

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "FirstName|LastName|Email",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.filterModel.addSort({
      key: "firstName,lastName",
      isAsc: true
    });
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.setConfig({
      formFields: null,
      onDataLoaded: d => this.setUsers(d)
    });
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IUser>(this.appService, this.filterModel, {
      filename: `${_.kebabCase(I18n.t("entities.users"))}-${I18n.t("phrases.export").toLowerCase()}-${moment().format(
        "L"
      )}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.organisationsProvider.exportUsers(columns, filterOptions, null, this.organisationId);
      },
      fields: [
        {
          key: "name",
          label: I18n.t("table.name"),
          selector: (i) => `${i.firstName} ${i.lastName}`
        },
        {
          key: "email",
          label: I18n.t("table.email"),
        },
        {
          key: "permissionLevel",
          label: I18n.t("table.permissionLevel"),
          selector: (i: any) => {
            return Enums.Translator.PermissionScope(i.permissionScope);
          }
        },
        {
          key: "status",
          label: I18n.t("table.status"),
        }
      ]
    });
  };

  onMount = (organisationId: number) => {
    let tableConfig = GetUserTableConfig(this, organisationId);
    this.tableModel.set(tableConfig);
    this.loadUsers();
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  onUnmount = () => { };

  @action
  setUsers = users => {
    this.users = users;
    this.tableModel.setData(users);
  };

  @action
  loadUsers = async () => {
    this.isLoading = true;
    await this.filterModel.loadData();
    this.setUsers(this.filterModel.data);
    this.isLoading = false;
  };

  searchUsers = (ev: React.SyntheticEvent) => {
    const e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("FirstName|LastName|Email", e.value);
  };

  resetQuery = () => {
    this.filterModel.setFilterValue("FirstName|LastName|Email", "");
  };

  showUserConfirmDeleteModal = (user: FP.Entities.IUser) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeUser")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: `${user.firstName} ${user.lastName}` })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          const res = await this.organisationsProvider.removeUser(this.organisation.id, user.id);
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.loadUsers();
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  showUserFormModal = () => {
    let formFields = getUserFormFields(this.organisation, this.modalService);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.innerModalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "UserInvitationButton",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (res) {
            this.innerModalService.hide();
            this.httpProgress.showOverlay();
            await this.organisationsProvider.addUser(this.organisation.id, res as FP.Entities.IUser);
            this.httpProgress.hideOverlay();
            this.loadUsers();
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.innerModalService.show({
        showClose: true,
        title: (
          <h1 className="mt-6">
            {this.organisation.name} | <strong>{I18n.t("phrases.inviteUser")}</strong>
          </h1>
        ),
        content: (
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-lg-7">
                <p>{I18n.t("phrases.inviteUserDescription")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10">{formModel.renderComponent()}</div>
            </div>
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "full",
          position: "bottom"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_UP,
          animateOut: Animations.SLIDE_OUT_DOWN,
          speed: 5
        },
        actions: []
      });
    });
  };
}
