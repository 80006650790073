import * as React from "react";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Panel } from "../../../../components/ui/Panel";
import { LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Icon } from "../../../../components/ui/Icon";
import IntercomLiveChat from "../../../../services/external/Intercom/IntercomLiveChat";
import { Animations } from "../../../../core/util/Animations";
import { IF } from "../../../../components/hoc/If";
import { IsProduction } from "../../../../core/util/EnvironmentHelper";

interface IHelpNavigationProps {
  currentOrganisationName: string;
  currentOrganisationId: number;
}

const HelpNavigation: React.FunctionComponent<IHelpNavigationProps> = props => {

  return (
    <Tooltip
      triggeredOn="click"
      position="top-right"
      shownElement={
        <div
          title={I18n.t("phrases.help")}
          className={`btn btn--link btn--sm btn btn--icon btn--shape-rectangle mb-1 navigation__action navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${3}`}
        >
          <Icon symbol="HelpCircle" size={Enums.UiSizes.MD} />
        </div>
      }
    >
      <Panel
        hasBorderRadius={true}
        background="bg-white"
        hasShadow={true}
        className={`p-2 ml-3 ${Animations.FADE_IN} speed-3 d-flex align-items-start flex-column`}
        style={{ width: "160px" }}
      >
        <IF condition={!IsProduction()}>
          <LinkButton
            externalUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=9q1pDOAt20mnRKuMC3UmS4sd-QWCIkpCj9ymZaDuXplUQk5HOVdLMkRPOURQNVVFM0hHS0ZKODVMMiQlQCN0PWcu"
            type="link"
            size={Enums.UiSizes.XS}
            target="_blank"
          >
            {I18n.t("phrases.reportBug")}
          </LinkButton>
          <hr className="w-100" />
        </IF>
        <LinkButton
          externalUrl="https://help.flightpath.it/"
          id="IntercomChatTrigger"
          type="link"
          size={Enums.UiSizes.XS}
          target="_blank"
        >
          {I18n.t("phrases.helpCentre")}
        </LinkButton>
        <IntercomLiveChat
          currentOrganisationId={props.currentOrganisationId}
          currentOrganisationName={props.currentOrganisationName} />
      </Panel>
    </Tooltip>
  );
};

export default HelpNavigation;
