import React from "react";
import { AppService } from "strikejs-app-service";
import { Icon } from "../../../../../components/ui/Icon";
import { Services } from "../../../../../constants";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { IUiAction } from "../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../core/util/Animations";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { IQuickImpactsApi } from "../../../../../services/api/v1/quickImpacts/IQuickImpacts.api";
import { ILogger } from "../../../../../services/local/Logger/ILogger";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { QuickImpactCreateFormFields } from "./QuickImpact_fields";

export class QuickImpactModel {
  modalService: IModalService;
  quickImpactsProvider: IQuickImpactsApi;
  organisationId: number;
  projectId: number;
  toasterService: IToasterService;
  logger: ILogger;
  loadImpacts: () => void;
  httpProgress: any;
  impactGroupsProvider: IImpactGroupsApi;
  isWorkshopMode: () => boolean;
  getFilteredImpactGroups: () => string[];

  constructor(
    appService: AppService,
    organisationId: number,
    projectId: number,
    loadImpacts,
    isWorkshopMode,
    getFilteredImpactGroups
  ) {
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.logger = appService.getService<ILogger>(Services.Logger);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.quickImpactsProvider = appService.getService<IQuickImpactsApi>(Services.QuickImpactsApi);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadImpacts = loadImpacts;
    this.impactGroupsProvider = appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.isWorkshopMode = isWorkshopMode;
    this.getFilteredImpactGroups = getFilteredImpactGroups;
  }

  showCreateModal = async () => {
    const formModel = this.getQuickFormModel();
    this.modalService.show({
      showClose: true,
      title: <h1 className="mt-6">{I18n.t("phrases.createNewImpact")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col">
              <p>{I18n.t("phrases.quickImpactCreateDescription")}</p>
            </div>
          </div>
          <SingleForm model={formModel} />
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showUpdateModal = async (impact: FP.Entities.IQuickImpact) => {
    const formModel = this.getQuickFormModel(impact);
    this.modalService.show({
      showClose: true,
      title: <h1 className="mt-6">{I18n.t("phrases.updateQuickImpact")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col">
              <p>{I18n.t("phrases.quickImpactCreateDescription")}</p>
            </div>
          </div>
          <SingleForm model={formModel} />
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  getQuickFormModel = (impact?: FP.Entities.IQuickImpact) => {
    var selectedImpactGroups = this.isWorkshopMode() ? this.getFilteredImpactGroups() : null;

    const formFields = QuickImpactCreateFormFields(
      this.impactGroupsProvider,
      this.organisationId,
      this.projectId,
      false,
      selectedImpactGroups,
      impact
    );
    const formModel = new SingleFormModel();

    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.createAnother"),
        onAction: async ev => {
          let res = await formModel.submit();
          let phrase = "";
          if (!res) return;

          phrase = "phrases.itemCreatedSuccessfully";
          await this.quickImpactsProvider.create(this.organisationId, this.projectId, res as any);

          this.toasterService
            .showSuccessToast()
            .setContent(<p>{I18n.t(phrase, { item: I18n.t("entities.impact") })}</p>)
            .startTimer(TOASTER_TOAST_TIME.NORMAL);

          if(this.isWorkshopMode() === false){
            formModel.resetFields();
          }

          if(this.isWorkshopMode() === true){
            formModel.resetFieldByKey("name");
            formModel.resetFieldByKey("description");
            formModel.resetFieldByKey("impactLevel");
            formModel.resetFieldByKey("impactType");
          }
        },
        componentProps: {
          type: "outline-primary",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t(impact ? "phrases.save" : "phrases.create"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          let phrase = "";
          if (impact) {
            phrase = "phrases.itemUpdatedSuccessfully";
            await this.quickImpactsProvider.update(this.organisationId, this.projectId, impact.id, res as any);
          } else {
            phrase = "phrases.itemCreatedSuccessfully";
            await this.quickImpactsProvider.create(this.organisationId, this.projectId, res as any);
            this.toasterService
              .showSuccessToast()
              .setContent(<p>{I18n.t(phrase, { item: I18n.t("entities.impact") })}</p>)
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
          }
          this.loadImpacts();
          this.modalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    if (impact) {
      actions = actions.slice(1);
    }
    formModel.formFields = formFields;
    formModel.actions = actions;
    return formModel;
  };

  showQuickImpactConfirmDeleteModal = (projectId: number, impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeImpactFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          await this.removeQuickImpact(projectId, impact.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  removeQuickImpact = async (projectId: number, impactId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.quickImpactsProvider.remove(this.organisationId, projectId, impactId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    this.loadImpacts();
  };
}
