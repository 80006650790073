import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { ITableModel, ITableConfig } from "../../../../core/table/ITableModel";
import { IModalService } from "../../../../core/modal/IModalService";
import { TableModel } from "../../../../core/table/Table_model";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { ButtonIcon, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { StakeholderCompactView } from "../stakeholderCompactView/StakeholderCompactView_view";
import { StakeholderCompactViewModel } from "../stakeholderCompactView/StakeholderCompactView_model";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { observable, action } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { Enums, StakeholderType } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import { IPaginationModel } from "../../../../components/widgets/pagination/Pagination_model";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IExportService, ExportService } from "../../../../services/local/export/ExportService";
import moment from "moment";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import * as _ from "lodash";
import { Animations } from "../../../../core/util/Animations";

export interface StakeholderListViewConfig {
  filterModel?: IFilterModel<FP.Entities.IProjectStakeholder>;
  hideHeaderButtons?: boolean;
  stakeholderType?: StakeholderType;
  paginationModel: IPaginationModel;
}

export class StakeholderListViewModel extends BaseModel {
  projectProvider: IProjectsApi;
  tableModel: ITableModel<FP.Entities.IProjectStakeholder>;
  modalService: IModalService;
  innerModalService: IModalService;
  httpProgress: IHttpProgressModel;
  appService: AppService;
  organisationProvider: IOrganisationsApi;
  projectId: number;
  hideHeaderButtons: boolean = false;
  organisationContextModel: IOrganisationContextModel;
  tableConfig: ITableConfig<FP.Entities.IProjectStakeholder>;
  isRouteView: boolean;
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>;
  paginationModel: IPaginationModel;
  stakeholderType: StakeholderType = Enums.StakeholderType.INDIVIDUAL;
  @observable.ref stakeholders: FP.Entities.IProjectStakeholder[];
  exportService: IExportService<FP.Entities.IProjectStakeholder>;
  projStakeholderApi: IProjectStakeholdersApi;
  organisationId: number;
  projectStakeholderProvider: IProjectStakeholdersApi;

  constructor(
    appService: AppService,
    isRouteView: boolean = true,
    modelConfig: StakeholderListViewConfig,
    projectId: number
  ) {
    super();
    this.appService = appService;
    this.isRouteView = isRouteView;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.projectStakeholderProvider = appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationContextModel = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.tableModel = new TableModel();
    this.projectId = projectId;
    this.projStakeholderApi = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

    if (modelConfig) {
      this.filterModel = modelConfig.filterModel;
      this.hideHeaderButtons = modelConfig.hideHeaderButtons || this.hideHeaderButtons;
      this.stakeholderType = modelConfig.stakeholderType || this.stakeholderType;
      this.paginationModel = modelConfig.paginationModel || this.paginationModel;
    }

    this.loadData();

    this.installExportService();
  }

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IProjectStakeholder>(this.appService, this.filterModel, {
      filename: `${_.kebabCase(I18n.t("entities.projectStakeholder"))}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.projStakeholderApi.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: [
        {
          key: "name",
          label: I18n.t("table.name")
        },
        {
          key: "email",
          label: I18n.t("table.email"),
        },
        {
          key: "businessArea",
          label: I18n.t("table.businessArea"),
        },
        {
          key: "owner",
          label: I18n.t("table.owner")
        },
        {
          key: "impact",
          label: I18n.t("table.impact"),
          selector: i => Enums.Translator.Metric(i.impact)
        },
        {
          key: "influence",
          label: I18n.t("table.influence"),
          selector: i => Enums.Translator.Metric(i.influence)
        },
        {
          key: "sentiment",
          label: I18n.t("table.sentiment"),
          selector: i => Enums.Translator.Metric(i.sentiment)
        },
        {
          key: "targetSentiment",
          label: I18n.t("table.targetSentiment"),
          selector: i => Enums.Translator.Metric(i.targetSentiment)
        },
        {
          key: "commitment",
          label: I18n.t("table.commitment"),
          selector: i => Enums.Translator.Metric(i.commitment)
        },
        {
          key: "targetCommitment",
          label: I18n.t("table.targetCommitment"),
          selector: i => Enums.Translator.Metric(i.targetCommitment)
        },
        {
          key: "receptiveness",
          label: I18n.t("table.receptiveness"),
          selector: i => Enums.Translator.Metric(i.receptiveness)
        },
        {
          key: "targetReceptiveness",
          label: I18n.t("table.targetReceptiveness"),
          selector: i => Enums.Translator.Metric(i.targetReceptiveness)
        },
        {
          key: "impactedBy",
          label: I18n.t("table.impactedBy"),
        }
      ]
    });
  };

  setTableConfig = (config: ITableConfig<FP.Entities.IProjectStakeholder>) => {
    this.tableConfig = config;
    this.tableModel.set(this.tableConfig);
  };

  onMount = (organisationId) => {
    this.organisationId = organisationId;
  };

  onUnmount = () => { };

  loadData = async () => {
    await this.filterModel.loadData();
    this.setStakeholders(this.filterModel.data);
  };

  @action
  setStakeholders = (data: FP.Entities.IProjectStakeholder[]) => {
    this.stakeholders = data;
    this.tableModel.setData(data);
  };

  onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.filterModel.setFilterValue("Stakeholder.FirstName|Stakeholder.LastName", e.currentTarget.value);
  };

  resetNameFilter = () => {
    this.filterModel.setFilterValue("Stakeholder.FirstName|Stakeholder.LastName", "");
  };

  showStakeholderModal = (row: ITableRowModel) => {
    let stakeholder: FP.Entities.IStakeholder = row.rowObject.stakeholder;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="mr-1 ml-auto"
            id="ViewDetailsButton"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/${stakeholder.id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="pencil"
            onClick={this.modalService.hide}
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/${stakeholder.id}/edit`}
          />
          <ButtonIcon
            key={"1"}
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="close"
            className="mr-1"
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: (
        <StakeholderCompactView
          model={new StakeholderCompactViewModel(this.appService, this.organisationId, row.rowObject)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
      }
    });
  };

  removeStakeholderFromProject = async (projectId: number, stakeholderId: number) => {
    this.httpProgress.showOverlay();
    const res = await this.projectStakeholderProvider.remove(this.organisationId, projectId, stakeholderId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }
    this.loadData();
  };

  showProjectStakeholderConfirmDeleteModal = (
    projectId: number,
    stakeholderId: number,
    stakeholder: FP.Entities.IStakeholder
  ) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t(
                stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
                  ? "warnings.removeStakeholderFromProject"
                  : "warnings.removeAudienceFromProject"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {I18n.t("phrases.confirmRemove", { name: convertStakeholderToName(stakeholder) })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeStakeholderFromProject(projectId, stakeholderId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
