import * as React from "react";
import { IModel } from "../../../../core/util/BaseModel";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { IFormSectionConfig } from "../IFormSectionModel";
import { SingleForm } from "./SingleForm_view";
import { observable, computed } from "mobx";
import { FormViewModel } from "../../../../core/forms/baseForm/FormViewModel";

export interface ISingleFormModel extends IModel {
  formFields: IFormFieldModel<any, any>[];
  actions: IUiAction<any>[];
  hasChanges: boolean;
  className: string;
}

export class SingleFormModel extends FormViewModel implements ISingleFormModel {
  @observable.ref actions: IUiAction<any>[] = [];
  @observable.ref formFields: IFormFieldModel<any, any>[] = [];
  className: string;

  @computed get hasChanges(): boolean {
    for (const field of this.formFields) {
      if (field.hasChanges) {
        return true;
      }
    }
    return false;
  }

  constructor(initConfig?: IFormSectionConfig) {
    super();
  }

  onMount = () => {};

  resetFields = () => {
    this.formFields.forEach(e => {
      e.reset();
    });
  };

  resetFieldByKey = (key: string) => {
    const foundField = this.formFields.find(field => field.key === key);

    if (foundField) {
      foundField.reset();
    }
  };

  renderComponent = (): React.ReactNode => {
    return <SingleForm model={this} />;
  };
}
