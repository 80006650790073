import * as React from "react";
import { BulkRoleUpdateModel } from "./BulkRoleUpdate_model";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../core/localization/I18n";

export interface BulkRoleUpdateProps {
  model: BulkRoleUpdateModel;
}

export const BulkRoleUpdate: React.FunctionComponent<BulkRoleUpdateProps> = ({ model }) => {
  return (
    <div className="container-fluid pt-2">
      <div className="row">
        <div className="col">
          <p>{I18n.t("phrases.deleteRoleModalIntro")}</p>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col">
          <h2>{I18n.t("phrases.assignStakeholders")}</h2>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-4">
          <h4>{I18n.t("phrases.stakeholderName")}</h4>
        </div>
        <div className="col-4">
          <h4>{I18n.t("phrases.assignNewBusinessArea")}</h4>
        </div>
        <div className="col-4">
          <h4>{I18n.t("phrases.assignNewRole")}</h4>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col">
          <SingleForm model={model.formModel} />
        </div>
      </div>
    </div>
  );
};
