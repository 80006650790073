import * as React from "react";
import _ from "lodash";
import { IModel, BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import {
  IInnerNavContentConfig,
  IInnerNavContentModel,
  InnerNavContentModel
} from "../../../../components/widgets/innerNavigation/InnerNavContent_model";
import { OrganisationSettings } from "./organisationSettings/OrganisationSettings_view";
import { OrganisationSettingsModel } from "./organisationSettings/OrganisationSettings_model";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { Services } from "../../../../constants";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { observable, action } from "mobx";
import { BusinessArea } from "./businessAreas/BusinessArea_view";
import { IModalService } from "../../../../core/modal/IModalService";
import { LocationSettings } from "./locations/LocationsSettings_view";
import { UsersSettings } from "./usersSettings/UsersSettings_view";
import { UsersSettingsModel } from "./usersSettings/UsersSettings_model";
import { RolesSettingsModel } from "./rolesSettings/RolesSettings_model";
import { RolesSettings } from "./rolesSettings/RolesSettings_view";
import { StakeholdersSettings } from "./stakeholdersSettings/StakeholdersSettings_view";
import { StakeholderGroupSettingsModel } from "./stakeholderGroupsSettings/StakeholderGroupSettings_model";
import { StakeholderGroupSettings } from "./stakeholderGroupsSettings/StakeholderGroupSettings_view";
import I18n from "../../../../core/localization/I18n";
import { ActionTypeSettings } from "./actionTypeSettings/ActionTypeSettings_view";
import { RLink } from "../../../../components/ui/Rlink";
import { ImpactTypeSettings } from "./impactTypeSettings/ImpactTypeSettings_view";
import { CustomProperties } from "./customProperties/CustomProperties_view";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";

export interface ISettingsViewModel extends IModel {
  innerNavContentModel: IInnerNavContentModel;
  isLoading: boolean;
  organisation: FP.Entities.IOrganisation;
}

export class SettingsViewModel extends BaseModel implements ISettingsViewModel {
  innerNavContentConfig: IInnerNavContentConfig;
  innerNavContentModel: IInnerNavContentModel;
  orgSettingsModel: OrganisationSettingsModel;
  organisationContext: IOrganisationContextModel;
  usersSettingsModel: UsersSettingsModel;
  stGroupSettingsModel: StakeholderGroupSettingsModel;
  rolesSettingsModel: RolesSettingsModel;
  orgProvider: IOrganisationsApi;
  modalService: IModalService;
  routeProps: RouteComponentProps;
  @observable isLoading: boolean = true;
  @observable.ref organisation: FP.Entities.IOrganisation;
  isAdmin: boolean;
  organisationId: number;
  organisationSettings: IOrganisationSettingsService;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number, routeProps: RouteComponentProps, refreshClaims: () => void, isAdmin: boolean) {
    super();
    this.isAdmin = isAdmin;
    this.organisationContext = appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.orgSettingsModel = new OrganisationSettingsModel(appService, routeProps, refreshClaims, organisationId);
    this.usersSettingsModel = new UsersSettingsModel(appService, routeProps);
    this.rolesSettingsModel = new RolesSettingsModel(appService, organisationId);
    this.stGroupSettingsModel = new StakeholderGroupSettingsModel(appService, routeProps);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.orgProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.innerNavContentModel = new InnerNavContentModel(this.innerNavContentConfig);
    this.organisationSettings = appService.getService<IOrganisationSettingsService>(Services.OrganisationSettingsService);
    this.organisationId = organisationId;
    this.routeProps = routeProps;

    this.loadOrganisation(organisationId);
  }

  showCustomProperies = async () =>
    this.isAdmin && await this.organisationSettings.isCustomPropertiesEnabled(this.organisationId);

  @action
  loadOrganisation = async (id: number) => {
    this.isLoading = true;
    let res = await this.orgProvider.getByIdAsync(id);

    if (!res || res.isError) {
      return;
    }
    this.organisation = res.payload;

    this.usersSettingsModel.organisation =
      this.stGroupSettingsModel.organisation =
      this.rolesSettingsModel.organisation =
      this.orgSettingsModel.organisation = this.organisation;

    let contentItems = {
      organisation: {
        key: "organisation",
        label: I18n.t("entities.organisation"),
        content: <OrganisationSettings model={this.orgSettingsModel} />
      },
      businessAreas: {
        key: "businessAreas",
        label: I18n.t("entities.businessAreas"),
        content: <BusinessArea />
      },
      locations: {
        key: "locations",
        label: I18n.t("entities.locations"),
        content: <LocationSettings />
      },
      roles: {
        key: "roles",
        label: I18n.t("entities.roles"),
        content: <RolesSettings model={this.rolesSettingsModel} />
      },
      actionTypes: {
        key: "actionTypes",
        label: I18n.t("entities.actionTypes"),
        content: <ActionTypeSettings />
      },
      impactTypes: {
        key: "impactTypes",
        label: I18n.t("entities.impactTypes"),
        content: <ImpactTypeSettings />
      },
      stakeholders: {
        key: "stakeholders",
        label: I18n.t("entities.stakeholders"),
        content: <StakeholdersSettings />
      },
      stakeholderGroups: {
        key: "stakeholderGroups",
        label: I18n.t("entities.stakeholderLists"),
        content: <StakeholderGroupSettings model={this.stGroupSettingsModel} />
      },
      users: {
        key: "users",
        label: I18n.t("entities.users"),
        content: <UsersSettings model={this.usersSettingsModel} />
      }
    };

    if (await this.showCustomProperies()) {
      contentItems["customProperties"] = {
        key: "customProperties",
        label: I18n.t("entities.customProperties"),
        content: <CustomProperties />
      }
    }

    this.orgSettingsModel.loadForm();

    this.innerNavContentConfig = {
      navigationTitle: "Settings",
      displayNavWithLinks: true,
      linkRender: e => {
        return (
          <RLink to={`/organisations/${this.organisation.id}/settings/${e.key}`}>
            <h4 id={`inner-nav-${e.key}`} className="mb-0">{e.label}</h4>
          </RLink>
        );
      },
      items: contentItems
    };
    // test change
    this.innerNavContentModel.setConfig(this.innerNavContentConfig);
    let page = this.routeProps.match.params["page"];
    let keys = _.map(this.innerNavContentConfig.items, e => e.key);
    if (page && keys.indexOf(page) >= 0) {
      this.innerNavContentModel.setCurrentViewKey(page);
    }
    this.isLoading = false;
  };
}
