import React from "react";
import { AddStakeholderGroupModel } from "./AddStakeholderGroup_model";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { TableView } from "../../../../core/table/Table_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

interface AddStakeholderGroupProps {
  model: AddStakeholderGroupModel;
}

const AddStakeholderGroup: React.FunctionComponent<AddStakeholderGroupProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`add-stakeholder-group pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders`}
            >
              <Icon className="mr-2" symbol="chevron-left" />
              {I18n.t("phrases.backToStakeholders")}
            </LinkButton>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-7">
            <h1>{I18n.t("phrases.addStakeholderList")}</h1>
            <p>{I18n.t("phrases.addStakeholderGroupDescription")}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-10">
            <SingleForm model={model.searchFormModel} />
          </div>
        </div>
      </div>

      {model.stakeholderGroup ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2>{I18n.t("phrases.stakeholderList")}</h2>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              {model.tableModel.data?.length ? (
                <TableView model={model.tableModel} />
              ) : (
                  <p>{I18n.t("phrases.noStakeholdersInGroup")}</p>
                )}
            </div>
          </div>

          {model.stakeholderGroupStakeholders?.length ? (
            model.stakeholdersNotInProject?.length ? (
              <>
                <hr />

                <div className="row mt-5 mb-4">
                  <div className="col-12">
                    <h2>{I18n.t("phrases.projectProfile")}</h2>
                    <p>{I18n.t("phrases.stakeholderListProjectProfileDescription")}</p>
                    <p>{I18n.t("phrases.addStakeholderListToProjectGuidance")}</p>
                  </div>
                </div>

                {model.proStakeholderFormModel ? (
                  <div className="row mb-4">
                    <div className="col-lg-10">
                      <SingleForm model={model.proStakeholderFormModel} />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
                <div className="row mb-4">
                  <div className="col-12">
                    <p>{I18n.t("phrases.allStakeholdersInGroupAlreadyInProject")}</p>
                  </div>
                </div>
              )
          ) : null}
        </div>
      ) : null}
    </div>
  );
});

export { AddStakeholderGroup };
