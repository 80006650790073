import React, { useEffect, useState } from "react";
import { ActionVisualisationsViewModel } from "./ActionVisualisationsView_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { Tab, Tabs } from "../../../../components/ui/Tabs";
import { useAppService } from "../../../../AppService";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useHistory, useParams } from "react-router-dom";
import { ActionBar } from "../../../../components/widgets/actionBar/ActionBar_view";
import { InnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";

export interface ActionVisualisationsViewProps {
  model?: ActionVisualisationsViewModel;
}

const ActionVisualisationsView: React.FunctionComponent<ActionVisualisationsViewProps> = observer(({model:m}) => {
  const appService = useAppService();
  const history = useHistory();
  const organisationId = useCurrentOrganisationId();
  const { projectId, page } = useParams<{ projectId: string, page: string }>();
  const [model, setModel] = useState(
    () => new ActionVisualisationsViewModel(appService, history, !!projectId ? +projectId : null, organisationId, page)
  );
  const modelProjectId = model.projectId;
  const modelPage = model.currentPage;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [projectId, modelProjectId, organisationId, appService, model]);

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ActionVisualisationsViewModel(appService, history, !!projectId ? +projectId : null, organisationId, page));
    }
    model.onMount();
    return model.onUnmount;
  }, [projectId, modelProjectId, organisationId, appService, model, history, page]);

  useEffect(() => {
    if (`${modelPage}` !== (page || "")) {
      setModel(new ActionVisualisationsViewModel(appService, history, !!projectId ? +projectId : null, organisationId, page));
    }

    model.onMount();
    return model.onUnmount;
  }, [modelPage, page, organisationId, appService, model, history, projectId]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="impacts-view pt-6">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col">
            <h1>
              {model.project?.name} | <strong>{I18n.t("phrases.actionVisualisationsHeading")}</strong>
            </h1>
            <p className="mb-0">{I18n.t("phrases.actionVisualisationsDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={1}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("phrases.actions"), null)}></Tab>
        {model.showVisualisations === true && (
          <Tab title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}></Tab>
        )}
      </Tabs>
      <ActionBar></ActionBar>
      <InnerNavContent model={model.innerNavContentModel}></InnerNavContent>
    </div>
  );
});

export { ActionVisualisationsView };
