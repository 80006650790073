import * as React from "react";

export interface TooltipProps {
  /**
   * This is the element that is going to triger the tooltip to show
   */
  shownElement: JSX.Element | string;

  /**
   * Any addition class names to be added to the parent element
   */
  className?: string;

  /**
   * how should the tooltip be triggered
   */
  triggeredOn: "click" | "hover";

  /**
   * Custom Funtion to call on toggle
   */
  onToggle?: () => void;

  /**
   * Tooltip content position
   */
  position?: "top" | "left" | "bottom" | "right" | "top-right" | "bottom-right" | "bottom-left";

  hasArrow?: boolean;
  style?: React.CSSProperties;
  testId?: string
}

const Tooltip: React.FunctionComponent<TooltipProps> = props => {
  const [isVisible, setVisible] = React.useState(false);

  return (
    <div
      className={`tooltip trigger-on-${props.triggeredOn || "hover"} ${props.className || ""} ${props.position || "top"
        }-side ${props.hasArrow ? "has-arrow" : ""}`}
      data-visible={isVisible}
      style={props.style || undefined}
      tabIndex={0}
      onBlur={
        () => setTimeout(() => setVisible(false), 300) /** TODO: consider finding an alternative for setTimeout */
      }
      data-testid={props.testId}
    >
      <div
        className="tooltip__element"
        onClick={() => {
          setVisible(!isVisible);
        }}
      >
        {props.shownElement}
      </div>
      <div
        className="tooltip__content"
        onClick={() => {
          setVisible(!isVisible);
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export interface TooltipIconProps {
  className?: string;
}

const TooltipIcon: React.FunctionComponent<TooltipIconProps> = props => {
  return (
    <div className={`tooltip-icon ${props.className || ""}`}>
      <h6 className="tooltip-icon__text mb-0 text-lowercase d-flex align-items-center">i</h6>
    </div>
  );
};

export { Tooltip, TooltipIcon };
