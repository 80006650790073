import React, { useState } from "react";
import { Route, Switch } from "react-router";
import { CanEditProgrammeRoute, CanViewProgrammeRoute, CanContributeOrgRoute } from "../../core/router/Route";
import { CreateProgrammeView } from "../../pages/change/programmes/createProgrammeView/CreateProgrammeView_view";
import { CreateProgrammeViewModel } from "../../pages/change/programmes/createProgrammeView/CreateProgrammeView_model";
import { ContainerProps } from ".";
import { SettingsView } from "../../pages/change/programmes/settingsView/SettingsView_view";
import { SettingsViewModel } from "../../pages/change/programmes/settingsView/SettingsView_model";
import { ProgrammeView } from "../../pages/change/programmes/view/View_view";
import { ProgrammeViewModel } from "../../pages/change/programmes/view/View_model";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { useFlightPathUser } from "../../setup";
import { useIsOrganisationAdmin, useRefreshClaims } from "../../core/auth/authorise";
import { useAppService } from "../../AppService";
import { useHistory } from "react-router-dom";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";

export interface IProgrammesContainer extends ContainerProps { }

const ProgrammesContainer: React.FunctionComponent<IProgrammesContainer> = ({ appService }) => {
  const user = useFlightPathUser();
  const refreshClaims = useRefreshClaims();
  const organisationId = useCurrentOrganisationId();
  const isOrganisationAdmin = useIsOrganisationAdmin(organisationId);

  return (
    <Switch>
      <Route path="/organisations/:organisationId/programmes/:programmeId">
        <Switch>
          <Route
            path="/organisations/:organisationId/programmes/:programmeId/settings/:page"
            render={({
              match: {
                params: { programmeId, page }
              }
            }) => (
              <CanEditProgrammeRoute programmeId={parseInt(programmeId)}>
                <PageTitle title={`${I18n.t(`phrases.programme`)} | ${I18n.t(`phrases.settings`)}`} />
                <SettingsView
                  key={programmeId}
                  model={new SettingsViewModel(appService, +programmeId, user, refreshClaims, isOrganisationAdmin, page)}
                />
              </CanEditProgrammeRoute>
            )}
          />
          <Route
            path="/organisations/:organisationId/programmes/:programmeId/settings"
            render={({
              match: {
                params: { programmeId }
              }
            }) => (
              <CanEditProgrammeRoute programmeId={parseInt(programmeId)}>
                <PageTitle title={`${I18n.t(`phrases.programme`)} | ${I18n.t(`phrases.settings`)}`} />
                <SettingsView
                  key={programmeId}
                  model={new SettingsViewModel(appService, +programmeId, user, refreshClaims, isOrganisationAdmin)}
                />
              </CanEditProgrammeRoute>
            )}
          />
          <Route
            path="/organisations/:organisationId/programmes/:programmeId"
            render={({
              match: {
                params: { programmeId }
              }
            }) => (
              <CanViewProgrammeRoute programmeId={parseInt(programmeId)}>
                <PageTitle title={I18n.t(`phrases.programme`)} />
                <ProgrammeView key={programmeId} model={new ProgrammeViewModel(appService, parseInt(programmeId))} />
              </CanViewProgrammeRoute>
            )}
          />
        </Switch>
      </Route>
      <Route path="*">
        <>
          <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
          <NotFoundIndex />
        </>
      </Route>
    </Switch>
  );
};

export const CreateProgramme: React.FC = () => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const refreshClaims = useRefreshClaims();
  const history = useHistory();
  const [model] = useState(() => new CreateProgrammeViewModel(appService, user, refreshClaims, history, organisationId));
  return (
    <Route path="/create/programmes">
      <CanContributeOrgRoute>
        <PageTitle title={I18n.t(`phrases.programmes`)} />
        <CreateProgrammeView model={model} />
      </CanContributeOrgRoute>
    </Route>
  );
};
export { ProgrammesContainer };
