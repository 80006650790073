import * as React from "react";
import { observer } from "mobx-react";
import { B } from "../../../../core/util/BootstrapHelper";

export type IRadioButtonOption = RadioButtonProps & {
  label: string;
  key?: string;
};

export interface RadioButtonListProps {
  id?: string;
  options: IRadioButtonOption[];
  value: string;
  disabled: boolean;
  onItemClick: (item: IRadioButtonOption) => void;
  className?: string;
}

const RadioButtonList: React.FunctionComponent<RadioButtonListProps> = observer(props => {
  const cls = B().bl("radio-button-list");

  return (
    <ul
      id={props.id}
      className={`${cls.bem} ${props.className || ""} ${props.disabled ? "radio-button-list--disabled" : ""}`}>
      {props.options.map((e: IRadioButtonOption) => {
        return (
          <RadioButton
            id={e.key}
            className={e.className}
            fillContainer={e.fillContainer}
            inputProps={{ ...e.inputProps, checked: props.value === e.inputProps.value }}
            onChange={
              ((ev: React.SyntheticEvent) => {
                if (!props.disabled) {
                  props.onItemClick(e);
                }
              }) as any
            }
            key={e.label}
          >
            {e.label}
          </RadioButton>
        );
      })}
    </ul>
  );
});

export interface RadioButtonProps {
  id?: string;
  className?: string;
  onChange?: (ev?: React.SyntheticEvent) => void;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  fillContainer?: boolean;
}

const RadioButton: React.FunctionComponent<RadioButtonProps> = (props: any) => {
  const cls = B()
    .bl("radio-button")
    .pmod(props.inputProps.checked ? "selected" : "")
    .pmod(props.fillContainer ? "fill-container" : "").bem;

  return (
    <label htmlFor={props.inputProps.id} className={`${props.className || ""} ${cls}`}>
      <input
        onChange={e => props.onChange(e)}
        {...props.inputProps}
        id={props.id}
        type="radio"
        className={`${props.inputProps.className || ""} mr-2`}
      />
      {props.children}
    </label>
  );
};

export { RadioButtonList, RadioButton };
