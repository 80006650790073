import React, { useCallback } from "react";
import { UserActionsModel } from "./UserActions_model";
import { observer } from "mobx-react-lite";
import { TableView } from "../../../../../core/table/Table_view";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { Icon } from "../../../../../components/ui/Icon";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { UiSizes } from "../../../../../enums";
import { Async } from "react-async";
import { Animations } from "../../../../../core/util/Animations";

export interface UserActionsProps {
  model: UserActionsModel;
}

export const UserActions: React.FunctionComponent<UserActionsProps> = observer(({ model }) => {
  const currentPage = model.paginationModel.currentPage;
  const loadData = useCallback(async () => {
    await model.loadPageData(currentPage);
  }, [model, currentPage]);

  const searchValue = model.filterModel.getFilter("name").value;

  return (
    <Async promiseFn={loadData}>
      <Async.Resolved>
        <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
          <div className="row mb-5">
            <div className="col">
              <LinkButton type="link-big" size={UiSizes.MD} href="/" className="p-0">
                <Icon className="mr-2" symbol="ChevronLeft" />
                {I18n.t("phrases.goBackDashboard")}
              </LinkButton>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              {model.title}
              <SearchButton
                onChange={model.onSearchChange}
                value={searchValue.length > 0 ? searchValue[0] : ""}
                className={"float-right ml-2"}
                onClose={model.resetNameFilter}
                buttonType="outline-primary"
              />
              <Button className={"float-right"} type="outline-primary" onClick={model.filterModel.showFilterFormModal}>
                <Icon symbol="Filter" className="mr-2" />
                {I18n.t("phrases.applyFilters")}
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TableView model={model.tableModel} />
              <Pagination model={model.paginationModel} />
            </div>
          </div>
        </div>
      </Async.Resolved>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Rejected>{err => err.message}</Async.Rejected>
    </Async>
  );
});
