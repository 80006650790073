import * as React from "react";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { Services } from "../../../../constants";
import { TableModel } from "../../../../core/table/Table_model";
import { ITableModel, ITableConfig } from "../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { observable, action, reaction } from "mobx";
import { IModalService } from "../../../../core/modal/IModalService";
import { ButtonIcon, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { ImpactCompactView } from "../../impacts/ImpactCompactView/ImpactCompactView_view";
import { ImpactCompactViewModel } from "../../impacts/ImpactCompactView/ImpactCompactView_model";
import { IDonutInfoModel } from "../../../../components/widgets/donutInfo/DonutInfo_model";
import { Enums, ImpactLevel } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { GetImpactListFilters } from "./ImpactsListViewFilter_fields";
import { IPaginationModel, PaginationModel } from "../../../../components/widgets/pagination/Pagination_model";
import { GetImpactTableConfig } from "./ImpactTableConfig";
import moment from "moment";
import { ExportService, IExportService } from "../../../../services/local/export/ExportService";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { EditableTimelineModel } from "../../../../components/widgets/timeline/EditableTimeline_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { ImpactTimelineModel } from "../../../../components/widgets/impactTimeline/ImpactTimeline_model";
import { appHistory } from "../../../../setup";
import { Animations } from "../../../../core/util/Animations";
import { QuickImpactModel } from "./QuickImpactModel/QuickImpact_model";
import { IQuickImpactsApi } from "../../../../services/api/v1/quickImpacts/IQuickImpacts.api";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";

export class ImpactViewModel extends DisposableModel {
  tableModel: ITableModel<FP.Entities.IImpact>;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  actionProvider: IActionsApi;
  appService: AppService;
  routeProps: RouteComponentProps;
  modalService: IModalService;
  filterModel: IFilterModel<FP.Entities.IImpact>;
  httpProgress: IHttpProgressModel;
  projectId: number;
  @observable.ref impacts: FP.Entities.IImpact[];
  @observable.ref generalInfo: IDonutInfoModel[] = [];
  @observable.ref project: FP.Entities.IProject;
  paginationModel: IPaginationModel;
  exportService: IExportService<FP.Entities.IImpact>;
  timelineModel: EditableTimelineModel;
  impactTimelineModel: ImpactTimelineModel;
  @observable.ref timelineModels: EditableTimelineModel[] = [];
  @observable currentListView: "list" | "timeline" = "list";
  tableConfig: ITableConfig<FP.Entities.IImpact>;
  actionImpacts: {} = {};
  organisationId: number;
  quickImpactModel: QuickImpactModel;
  quickImpactProvider: IQuickImpactsApi;
  organisationSettings: IOrganisationSettingsService;
  @observable showVisualisations: boolean;

  constructor(appService: AppService, projectId: number, organisationId: number) {
    super();
    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.quickImpactProvider = this.appService.getService<IQuickImpactsApi>(Services.QuickImpactsApi);
    this.organisationSettings = this.appService.getService<IOrganisationSettingsService>(Services.OrganisationSettingsService);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.impactTimelineModel = new ImpactTimelineModel(appService, this);
    this.tableModel = new TableModel();
    this.setTable();
    this.installPagination();
    this.installFilter();
    this.installExportService();
    this.filterModel.setFromQueryString(window.location.search);
    this.quickImpactModel = new QuickImpactModel(
      appService,
      organisationId,
      projectId,
      this.loadImpacts,
      this.isWorkshopMode,
      this.getFilteredImpactGroups
    );
    this.showVisualisations = false;
  }

  installReactions = () => {
    reaction(
      () => {
        return this.impacts;
      },
      (e, reaction) => { }
    );
    // this.addDisposer(d);
  };

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  installFilter = () => {
    this.filterModel = new FilterModel(this.appService, this.paginationModel, {
      filterCb: async filterOptions =>
        await this.impactProvider.getMixedFiltered(this.organisationId, this.projectId, filterOptions),
      projectId: this.projectId
    });

    const nameFilter: IFilterAttribute = {
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const refFilter: IFilterAttribute = {
      key: "refNumber",
      label: I18n.t("filters.refNumber"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const type: IFilterAttribute = {
      key: "impactType",
      label: I18n.t("filters.impactType"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };
    const level: IFilterAttribute = {
      key: "impactLevelGen",
      label: I18n.t("filters.impactLevel"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ImpactLevelMin(parseInt(k));
      }
    };

    const progressFilter: IFilterAttribute = {
      key: "progressStatus",
      value: [],
      isMultiValue: true,
      label: I18n.t("filters.progressStatus"),
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ImpactProgressStatus(parseInt(k));
      }
    };

    const group: IFilterAttribute = {
      key: "impactGroup",
      label: I18n.t("filters.impactGroup"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };

    const impactGroupAutoPopulate: IFilterAttribute = {
      key: "impactGroupAutoPopulate",
      label: I18n.t("forms.prepopulateImpactGroups"),
      value: [],
      extractFilterValue: value => {
        return value;
      },
      isMultiValue: false,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k;
      },
      isHidden: true
    };

    const businessAreaFilter: IFilterAttribute = {
      key: "businessAreaId",
      label: I18n.t("filters.businessArea"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };

    const withActionsFilter: IFilterAttribute = {
      key: "withActions",
      value: [],
      operator: FilterOperator.EQUALS,
      valueRenderer: val => {
        return I18n.t(val === "true" ? "phrases.withActions" : "phrases.withoutActions");
      }
    };

    this.filterModel.addSort({ key: "impactLevel", isAsc: false });

    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(projectFilter);
    this.filterModel.addFilter(progressFilter);
    this.filterModel.addFilter(businessAreaFilter);
    this.filterModel.addFilter(refFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(type);
    this.filterModel.addFilter(level);
    this.filterModel.addFilter(group);
    this.filterModel.addFilter(impactGroupAutoPopulate);
    this.filterModel.addFilter(withActionsFilter);
    this.filterModel.setConfig({
      formFields: s => GetImpactListFilters(s, this.organisationId, this.projectId),
      onDataLoaded: d => this.tableModel.setData(d)
    });
  };

  isWorkshopMode = (): boolean => {
    let result: boolean = false;

    let selectedImpactGroups: string[] = this.filterModel.getFilter("impactGroup")?.value;
    let impactGroupAutoPopulate: string[] = this.filterModel.getFilter("impactGroupAutoPopulate")?.value;

    if (selectedImpactGroups.length > 0 && impactGroupAutoPopulate.length > 0) {
      return impactGroupAutoPopulate[0] === "true";
    }

    return result;
  };

  loadShowVisualisations = async () =>
    await this.organisationSettings.isVisualisationsEnabled(this.organisationId);

  getFilteredImpactGroups = (): string[] => {
    var selectedImpactGroups = this.filterModel.getFilter("impactGroup")?.value;
    return selectedImpactGroups;
  };

  onMount = async () => {
    Promise.all([this.loadImpacts(), this.loadProject(), this.loadShowVisualisations()]).then(values => {
      // the project should be set before impacts, timeline requires the
      // project to be present before installing the timelines
      this.setProject(values[1]);
      this.setImpacts(values[0]);
      this.showVisualisations = values[2];
    });
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
    return res.payload;
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
    this.impactTimelineModel.setProject(project);
  };

  setTable = () => {
    this.tableConfig = GetImpactTableConfig(this, this.tableModel, this.projectId);
    this.tableModel.set(this.tableConfig);
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IImpact>(this.appService, this.filterModel, {
      filename: `${I18n.t("entities.impacts").toLowerCase()}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.impactProvider.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: [
        {
          key: "refNumber",
          label: I18n.t("table.refNo")
        },
        {
          key: "name",
          label: I18n.t("table.name")
        },
        {
          key: "description",
          label: I18n.t("table.description"),
          selector: item => item.description === null ? "" : item.description
        },
        {
          key: "type",
          label: I18n.t("table.type"),
          selector: item => item.nImpactType.name
        },
        {
          key: "totalPeopleImpacted",
          label: I18n.t("table.numOfIndividualsImpacted"),
          selector: item => item.progressStatus === 0 ? "N/A" : item.totalPeopleImpacted
        },
        {
          key: "impactLevel",
          label: I18n.t("table.impactLevel"),
          selector: item => Enums.Translator.ImpactLevel(item.impactLevel)
        },
        {
          key: "startDate",
          label: I18n.t("table.startDate"),
          selector: item => this.renderNullableDate(item.startDate)
        },
        {
          key: "actualEndDate",
          label: I18n.t("table.endDate"),
          selector: item => this.renderNullableDate(item.actualEndDate)
        },
        {
          key: "progressStatus",
          label: I18n.t("table.progressStatus"),
          selector: item => Enums.Translator.ImpactProgressStatus(item.progressStatus)
        },
        {
          key: "locations",
          label: I18n.t("table.locations"),
          selector: item => item.locations === null ? "N/A" : item.locations
        },
        {
          key: "businessAreas",
          label: I18n.t("table.businessAreas"),
          selector: item => item.businessAreas === null ? "N/A" : item.businessAreas
        },
        {
          key: "actions",
          label: I18n.t("table.actions"),
          selector: item => item.actions === null ? "N/A" : item.actions
        },
        {
          key: "impactGroups",
          label: I18n.t("table.impactGroups")
        },
        {
          key: "createdBy",
          label: I18n.t("table.createdBy"),

          isHidden: true
        },
        {
          key: "createdAt",
          label: I18n.t("table.createdOn"),
          selector: item => moment(item.createdAt).format("L"),
          isHidden: true
        },
        {
          key: "lastUpdatedBy",
          label: I18n.t("table.lastUpdatedBy"),
          isHidden: true
        },
        {
          key: "lastUpdatedAt",
          label: I18n.t("table.lastUpdatedOn"),
          selector: item => moment(item.updatedAt).format("L"),
          isHidden: true
        }
      ]
    });
  };

  renderNullableDate = (value: any): string => {
    return value === null ? "N/A" : moment(value).format("L")
  }

  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  loadImpacts = async () => {
    await this.filterModel.loadData();
    return this.filterModel.data;
  };

  @action
  setImpacts = (impacts: FP.Entities.IImpact[]) => {
    this.impacts = impacts;
    this.tableModel.setData(this.filterModel.data);
    this.tableModel.isLoading = false;

    this.impactTimelineModel.setImpacts(
      impacts.filter(e => e.impactCompletionState === Enums.ImpactCompletionState.Complete)
    );
    this.setGeneralInfo();
  };

  showImpactModal = (row: ITableRowModel) => {
    const id = row.rowObject.id;
    if (row.rowObject.impactCompletionState === Enums.ImpactCompletionState.Incomplete) {
      this.quickImpactModel.showUpdateModal(row.rowObject);
      return;
    }
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="pencil"
            onClick={this.modalService.hide}
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${id}/edit`}
          />
          <ButtonIcon
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="close"
            className="mr-1"
            onClick={this.modalService.hide}
            key={"1"}
          />
        </div>
      ),
      content: (
        <ImpactCompactView
          model={new ImpactCompactViewModel(this.appService, this.projectId, id, this.organisationId)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impact-visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts`);
      return;
    }
    appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups`);
  };

  changeCurrentListView = (selectedView: "list" | "timeline") => {
    this.currentListView = selectedView;
  };

  @action
  setGeneralInfo = async () => {
    let res = await this.projectProvider.getImpactsCount(this.organisationId, this.projectId);

    if (!res || res.isError) return;
    let { totalHigh, totalMedium, totalLow, completedHigh, completedMedium, completedLow } = res.payload;

    let s: IDonutInfoModel[] = [
      {
        key: Enums.ImpactLevel.HIGH,
        value: totalHigh,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.highImpact")}</h3>
            <small className="strong">
              {I18n.t("phrases.numOfTotalCompleted", { num: completedHigh, total: totalHigh })}
            </small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "indicator-accent-3"
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        key: Enums.ImpactLevel.MEDIUM,
        value: totalMedium,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.mediumImpact")}</h3>
            <small className="strong">
              {I18n.t("phrases.numOfTotalCompleted", { num: completedMedium, total: totalMedium })}
            </small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "indicator-accent-2"
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        key: Enums.ImpactLevel.LOW,
        value: totalLow,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.lowImpact")}</h3>
            <small className="strong">
              {I18n.t("phrases.numOfTotalCompleted", { num: completedLow, total: totalLow })}
            </small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "indicator-accent-1"
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      }
    ];
    this.generalInfo = s;
  };

  updateImpactFilter = (impactLevel: ImpactLevel) => {
    this.filterModel.setFilterValue("impactLevelGen", impactLevel + "");
  };

  showImpactConfirmDeleteModal = (projectId: number, impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeImpactFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          await this.removeImpact(projectId, impact.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  removeImpact = async (projectId: number, impactId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.remove(this.organisationId, projectId, impactId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    this.loadImpacts();
  };

  onUnmount = () => { };
}
