import React, { useContext } from "react";
import { createAppService } from "strikejs-app-service";

export type IAppService = ReturnType<typeof createAppService>;

const AppService = createAppService();

const AppServiceContext = React.createContext<IAppService>(AppService);

export const AppServiceProvider: React.FC<{ appService: IAppService }> = ({ appService, children }) => (
  <AppServiceContext.Provider value={appService}>{children}</AppServiceContext.Provider>
);

export function useAppService() {
  return useContext(AppServiceContext);
}

export default AppService;
