import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IBusinessAreasApi } from "./IBusinessAreas.api";
import { IHttp } from "../../IHttp";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { AxiosRequestConfig } from "axios";

export class BusinessAreasApi
  extends FilteredOrganisationApiModel<FP.Entities.IBusinessArea>
  implements IBusinessAreasApi {
  controller: string = "business-areas";

  constructor(http: IHttp) {
    super(http, "business-areas");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getRoles = async (
    organisationId: number,
    businessAreaId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IRole[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${businessAreaId}/roles${
      (filterOptions && this.getRequestQuery(filterOptions)) || ""
    }`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IRole[]>;
    }
  };

  getHierarchyStakeholders = async (
    organisationId: number,
    businessAreaId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${businessAreaId}/stakeholders-tree`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholder[]>;
    }
  };
}
