import * as React from 'react';
import { B } from '../../../core/util/BootstrapHelper';


export interface PaginationGroupProps {
    className?: string;
    size?: "lg" | "sm";
}

export const PaginationGroup: React.FunctionComponent<PaginationGroupProps> = (props) => {
    let cls = B().bl("pagination")
        .pmod(props.size ? props.size : "")
        .boot

    return <nav>
        <ul
            className={`${cls}`}
        >
            {props.children}
        </ul>
    </nav>
}

export interface PaginationButtonProps {
    status?: "active" | "disabled";
    onClick?: (e: React.SyntheticEvent) => void;
}

export const PaginationButton: React.FunctionComponent<PaginationButtonProps> = (props) => {
    return <li className={`page-item ${props.status || ""}`}
        onClick={props.onClick}
    >
        <span
            className="page-link"
        >
            {props.children}
        </span>
    </li>
}

let s = {
    Button: PaginationButton,
    Group: PaginationGroup
}

export default s; 