import { Application } from "./Application";
import { createConsoleLoggerDelegate, createLogger } from "./core/logger/Logger";
import { Services } from "./constants";
import { ILogger } from "./core/logger/ILogger";
import { AppService } from "strikejs-app-service";
import { Enums } from "./enums";
import moment from "moment";
import AppServiceObj from "./AppService";
import "moment/locale/en-gb";
// MobX batching configuration
moment.locale("en-gb");

export interface StartUpConfig {
  isDebug: boolean;
  logger?: ILogger;
  configureServices?(appService: AppService): void;
  configureApp?(app: Application): void;
}

export async function startUp({ isDebug, logger, configureServices, configureApp }: StartUpConfig) {
  let appService = AppServiceObj;
  logger = logger || createLogger(createConsoleLoggerDelegate(), isDebug ? Enums.LogLevel.All : Enums.LogLevel.Error);

  //Registering default services
  //appService.setService(Services.Cache,()=>cache);
  appService.setService(Services.Logger, () => logger);

  if (configureServices) {
    configureServices(appService);
  }

  let app = new Application({
    logger,
    appService,
    debug: isDebug,
    dispose() {}
  });

  if (configureApp) {
    configureApp(app);
  }

  await app.start();

  let w: any = window;
  w.FlightPath = w.FlightPath || {};
  w.FP = w.FP || {};
  Object.defineProperty(w.FlightPath, "App", {
    get() {
      return app;
    }
  });
  return app;
}
