import * as React from 'react';
import { ProgressBarWrapper, ProgressBar, ProgressBarPoint } from '../../ui/ProgressBar';
import moment from 'moment';
import { IF } from '../../hoc/If';
import I18n from '../../../core/localization/I18n';

export interface ITimelineProps {
    startDate: Date,
    initialEndDate: Date,
    actualEndDate: Date,
}

export const Timeline: React.FunctionComponent<ITimelineProps> = (props) => {
    let totalDaysDiff: number
    let todayPosition;
    let actualEndDatePosition;

    const isTodayLastDate = moment(new Date()).isAfter(props.actualEndDate)

    if (isTodayLastDate) {
        totalDaysDiff = moment(new Date()).diff(moment(props.startDate), 'days');
        const actualEndDateDiff = totalDaysDiff - moment(new Date()).diff(moment(props.actualEndDate), 'days');
        actualEndDatePosition = actualEndDateDiff * 100 / totalDaysDiff;;
        todayPosition = 100;
    } else {
        totalDaysDiff = moment(props.actualEndDate).diff(moment(props.startDate), 'days');
        const todayDiff = totalDaysDiff - moment(props.actualEndDate).diff(moment(new Date()), 'days');
        todayPosition = todayDiff * 100 / totalDaysDiff;
        actualEndDatePosition = 100
    }

    const initialEndDateDiff = totalDaysDiff - (isTodayLastDate ? moment(new Date()) : moment(props.actualEndDate)).diff(moment(props.initialEndDate), 'days');
    const initialEndDatePosition = initialEndDateDiff * 100 / totalDaysDiff;

    return <ProgressBarWrapper>
        <IF condition={moment(props.initialEndDate).isSame(props.actualEndDate)}>
            <ProgressBar
                width={isTodayLastDate ? actualEndDatePosition : todayPosition}
            />
            <IF condition={isTodayLastDate}>
                <ProgressBar
                    width={todayPosition - actualEndDatePosition}
                    offset={actualEndDatePosition}
                    type={"primary-danger"}
                />
            </IF>
        </IF>
        <IF condition={!moment(props.initialEndDate).isSame(props.actualEndDate)}>
            <ProgressBar
                width={initialEndDatePosition}
            />
            <IF condition={isTodayLastDate}>
                <ProgressBar
                    width={actualEndDatePosition - initialEndDatePosition}
                    offset={initialEndDatePosition}
                    type={"primary-warning"}
                />
                <ProgressBar
                    width={todayPosition - actualEndDatePosition}
                    offset={actualEndDatePosition}
                    type={"primary-danger"}
                />
            </IF>
            <IF condition={!isTodayLastDate}>
                <ProgressBar
                    width={todayPosition - initialEndDatePosition}
                    offset={initialEndDatePosition}
                    type={"primary-warning"}
                />
            </IF>
        </IF>


        {/* TODAY DATE */}
        <IF condition={moment(new Date()).isAfter(props.startDate)}>
            <ProgressBarPoint
                shape="line"
                type={isTodayLastDate ? "primary-danger" : !moment(props.initialEndDate).isSame(props.actualEndDate) ? "primary-warning" : "primary"}
                offset={todayPosition}
                tooltipPosition={isTodayLastDate ? "bottom-right" : "bottom"}
                tooltipContent={<>
                    <div className="timeline__tooltip-title">
                        {I18n.t("phrases.today")}
                    </div>
                    <div className="timeline__tooltip-subtitle">
                        {moment(new Date()).format("L")}
                    </div>
                </>
                } />
        </IF>

        {/* START DATE */}
        <ProgressBarPoint
            // shape={moment(new Date()).isAfter(props.startDate)? "circle" : "empty-circle"}
            shape={"empty-circle"}
            offset={0}
            tooltipPosition="bottom-left"
            className="progress-bar__point--first"
            tooltipContent={<>
                <div className="timeline__tooltip-title">
                    {I18n.t("phrases.startDate")}
                </div>
                <div className="timeline__tooltip-subtitle">
                    {moment(props.startDate).format("L")}
                </div>
            </>
            } />

        {/* INITIAL END DATE */}
        <IF condition={!moment(props.initialEndDate).isSame(props.actualEndDate)}>
            <ProgressBarPoint
                shape="empty-circle"
                offset={initialEndDatePosition}
                tooltipContent={<>
                    <div className="timeline__tooltip-title">
                        {I18n.t("phrases.initialTargetDeliveryDate")}
                    </div>
                    <div className="timeline__tooltip-subtitle">
                        {moment(props.initialEndDate).format("L")}
                    </div>
                </>
                } />
        </IF>

        {/* ACTUAL END DATE */}
        <ProgressBarPoint
            shape="empty-circle"
            type={isTodayLastDate ? "primary-danger" : "default"}
            className="progress-bar__point--last"
            tooltipPosition={isTodayLastDate ? "bottom" : "bottom-right"}
            offset={actualEndDatePosition}
            tooltipContent={<>
                <div className="timeline__tooltip-title">
                    {moment(props.initialEndDate).isSame(props.actualEndDate) ?
                        I18n.t("phrases.targetDeliveryDate")
                        : I18n.t("phrases.newTargetDeliveryDate")}
                </div>
                <div className="timeline__tooltip-subtitle">
                    {moment(props.actualEndDate).format("L")}
                </div>
            </>
            } />

    </ProgressBarWrapper>
};