import * as React from "react";
import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { IModalService } from "../../../core/modal/IModalService";
import { Services } from "../../../constants";
import { SingleFormModel } from "../../../pages/change/forms/singleFormModel/SingleForm_model";
import I18n from "../../../core/localization/I18n";
import { IFilterModel } from "../../../core/filter/Filter_model";
import { INIT_CHECKBOX_FIELD } from "../../../core/forms/controls/checkbox/Checkbox_init";
import { generateFormFieldsFromJson } from "../../../core/forms/helpers/FormFieldMappers";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import { Icon } from "../../../components/ui/Icon";
import { IFilteredOptions } from "../../api/filteredApi/FilteredApiModel";
import { IFlightPathApiResponse } from "../../api/BaseApiModel";
import { ICsvHelper } from "../csvHelper/CsvHelper";
import { Animations } from "../../../core/util/Animations";

interface ExportFieldItem<T> extends FP.Generic.IKeyLabel {
  selector?: (obj: T) => React.ReactNode;
  isHidden?: boolean;
}

interface IKeyLabelHidden extends FP.Generic.IKeyLabel {
  isHidden?: boolean;
}

export interface IExportConfig<T> {
  exportCb: (columns: string[], filterOptions: Partial<IFilteredOptions>) => Promise<IFlightPathApiResponse<T[]>>;
  fields: ExportFieldItem<T>[];
  filename: string;
}

export interface IExportService<T> {
  showSelectionModal: () => void;
  setConfig: (config: Partial<IExportConfig<T>>) => void;
}

export class ExportService<T> implements IExportService<T> {
  @observable.ref config: IExportConfig<T>;
  modalService: IModalService;
  appService: AppService;
  filterModel: IFilterModel<T>;
  csvHelper: ICsvHelper;
  /**
   *
   */
  constructor(appService: AppService, filterModel: IFilterModel<T>, config?: Partial<IExportConfig<T>>) {
    this.appService = appService;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.csvHelper = this.appService.getService<ICsvHelper>(Services.CsvHelper);
    this.filterModel = filterModel;
    if (config) {
      this.setConfig(config);
    }
  }

  setConfig = (config: Partial<IExportConfig<T>>) => {
    this.config = { ...this.config, ...config };
  };

  showSelectionModal = () => {
    let formModel = new SingleFormModel();
    formModel.formFields = this.getFormFields(this.config.fields);
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: (
          <>
            <Icon symbol="download" className="mr-2" />
            {I18n.t("phrases.export")}
          </>
        ),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;

          let keys = Object.keys(res);
          let columns = [];
          let headerFields = [];
          keys.forEach(k => {
            if (res[k]) {
              columns.push(k);
              let s = this.config.fields.find(e => e.key === k);
              if (s) {
                headerFields.push(s);
              }
            }
          });
          let result = await this.config.exportCb(columns, this.filterModel.getFilterOptions());
          if (!result || result.isError) return;

          this.generateCsv(result.payload, headerFields);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.actions = actions;
    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.selectExportFields")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-white"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  generateCsv = (data: T[], fields: ExportFieldItem<T>[]) => {
    let headers = [fields.map(e => e.label)];

    let res = data.map(row => {
      return fields.map(header => {
        if (header.selector) {
          return header.selector(row);
        }
        return row[header.key];
      });
    });

    let combined = [...headers, ...res];
    this.csvHelper.exportToCsv(this.config.filename, combined);
  };

  getFormFields = (fields: IKeyLabelHidden[]) => {
    let res = fields.map(e => {
      return {
        ...INIT_CHECKBOX_FIELD,
        key: e.key,
        fieldClassName: "col-12 d-flex flex-row-reverse justify-content-end",
        label: <label htmlFor={e.key}>{e.label} </label>,
        value: true,
        isHidden: e.isHidden
      };
    });
    return generateFormFieldsFromJson(res);
  };
}
