import React, { useEffect, useState } from "react";
import { ActionListViewModel } from "./ActionListView_model";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { TableView } from "../../../../core/table/Table_view";
import { DonutInfo } from "../../../../components/widgets/donutInfo/DonutInfo_view";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { RagStatus } from "../../../../enums";
import { Pagination } from "../../../../components/widgets/pagination/Pagination_view";
import { SearchButton } from "../../../../components/ui/SearchButton";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { Async } from "react-async";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { Tab, Tabs } from "../../../../components/ui/Tabs";
import { useAppService } from "../../../../AppService";
import { useHistory, useParams } from "react-router-dom";

export interface ActionListViewProps {
  model?: ActionListViewModel;
}

const ActionListView: React.FunctionComponent<ActionListViewProps> = observer(({ model:m }) => {
  const appService = useAppService();
  const history = useHistory();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(() => new ActionListViewModel(appService, history, !!projectId ? +projectId : null, organisationId));
  const modelProjectId = model.projectId;
  
  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ActionListViewModel(appService, history, !!projectId ? +projectId : null, organisationId));
    }
    model.onMount();
  }, [appService, model, modelProjectId, history, projectId, organisationId]);

  return (
    <Async promiseFn={model.load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <ActionListViewContent model={model} organisationId={organisationId} />
      </Async.Resolved>
    </Async>
  );
});

const ActionListViewContent: React.FC<any> = observer(({ model, organisationId }) => {
  const searchValue = model.filterModel.getFilter("name").value;

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return (
      <h2 className="mb-0">{`${title} ${countText}`}</h2>
    );
  };

  return <div className={`action-list-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
    <div className="container-fluid">
      <div className="row mb-5">
        <div className="col">
          <h1>
            {model.project?.name} | <strong>{I18n.t("phrases.actions")}</strong>
          </h1>
          <p className="mb-0">{I18n.t("phrases.actionViewDescription")}</p>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary mb-5"
        ulClassName="tabs__menu--primary"
        initialTab={0}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("phrases.actions"), null)}></Tab>
        {model.showVisualisations === true && (
          <Tab title={tabHeadingWithCount(I18n.t("phrases.visualisationTabHeading"), null)}></Tab>
        )}
      </Tabs>
      <div className="row mb-5">
        <div className="col">
          <CanEditProject projectId={model.projectId}>
            <LinkButton
              id="CreateActionButton"
              href={`/organisations/${organisationId}/projects/${model.projectId}/actions/create`}>
              <Icon symbol="plus" className="mr-2" />
              {I18n.t("phrases.createAction")}
            </LinkButton>
          </CanEditProject>
          <SearchButton
            onChange={model.onSearchChange}
            value={searchValue.length > 0 ? searchValue[0] : ""}
            className={"float-right ml-2"}
            onClose={model.resetNameFilter}
            buttonType="outline-primary"
          />
          <Button
            className={"float-right ml-2"}
            type="outline-primary"
            onClick={model.exportService.showSelectionModal}
          >
            <Icon symbol="download" className="mr-2" />
            {I18n.t("phrases.export")}
          </Button>
          <Button
            className={"float-right"}
            type="outline-primary"
            onClick={model.filterModel.showFilterFormModal}
          >
            <Icon symbol="filter" className="mr-2" />
            {I18n.t("phrases.applyFilters")}
          </Button>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <div className="d-flex flex-wrap">
            {model.generalInfo?.map((e, i) => {
              return (
                <div
                  onClick={() => model.updateRagFilter(e.key as RagStatus)}
                  className={`${Animations.ZOOM_IN} speed-1 delay-${i}`}
                  key={i}
                >
                  <DonutInfo model={e} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex flex-wrap">
            <FilterPills filterModel={model.filterModel} />
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <TableView model={model.tableModel} />
          <Pagination model={model.paginationModel} />
        </div>
      </div>
    </div>
  </div>
})

export { ActionListView };
