import * as React from "react";
import { BaseModel } from "../../../core/util/BaseModel";
import {
  TreeItem,
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  toggleExpandedForAll,
  getNodeAtPath
} from "react-sortable-tree";
import { observable, action } from "mobx";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import { TreeNodeInput } from "./TreeEditor_view";
import { Enums } from "../../../enums";
import I18n from "../../../core/localization/I18n";

export function getNodeKey({ treeIndex }) {
  return treeIndex;
}

export class TreeEditorModel extends BaseModel {
  parentKeySelector: string = "id";
  currentNodeModel: TreeNodeInputModel;
  @observable.ref treeData: TreeItem[] = [];
  @observable searchValue: string = "";
  onAdd: any | ((node: any, path: number[] | string[], treeIndex?: number | string) => Promise<any>);
  onUpdate: any | ((node: any, path: number[] | string[]) => Promise<void>);
  onConfirm: any | ((node: any, path: number[] | string[]) => void);
  onDelete: any | ((node: any, path: number[] | string[]) => Promise<any>);
  onHierarchyChanged: any | ((node: any, path: number[] | string[]) => Promise<any>);
  id: string;

  /**
   *
   */
  constructor(id: string) {
    super();
    this.id = id;
  }
  @action.bound
  onTreeDataChanged = (newTreeData: TreeItem[]) => {
    this.treeData = newTreeData;
  };

  generateNodeProps = (node: any, path: number[] | string[]) => {
    const actions: IUiAction<any>[] = [];

    if (!node.isEditMode) {
      actions.push(
        {
          id: "edit",
          label: I18n.t("phrases.edit"),
          onAction: (ev, context) => {
            this.editNode(node, path);
          },
          rendersIn: "ButtonIcon",
          componentProps: {
            size: Enums.UiSizes.XS,
            type: "link",
            symbol: "Pencil"
          }
        },
        {
          id: "create",
          label: I18n.t("phrases.create"),
          onAction: (ev, context) => this.addNode(node, path),
          rendersIn: "ButtonIcon",
          componentProps: {
            size: Enums.UiSizes.XS,
            type: "link",
            symbol: "Plus"
          }
        }
      );
    }

    if (!node.id || !node.isEditMode) {
      actions.push({
        id: "delete",
        label: I18n.t("phrases.delete"),
        onAction: async (ev, context) => {
          if (this.onDelete) {
            await this.onDelete(node, path);
          }
        },
        rendersIn: "ButtonIcon",
        componentProps: {
          size: Enums.UiSizes.XS,
          type: "link",
          symbol: "Trash2"
        }
      });
    }

    return {
      buttons: generateActions(actions, node, path)
    };
  };

  addNode = action((node?: any, path?: number[] | string[]) => {
    this.currentNodeModel = new TreeNodeInputModel("", this.id);
    let newNode = {
      isEditMode: true,
      title: <TreeNodeInput model={this.currentNodeModel} />
    };
    this.currentNodeModel.setMode("edit");
    let s = addNodeUnderParent({
      treeData: this.treeData,
      parentKey: path ? path[path.length - 1] : null,
      expandParent: true,
      getNodeKey,
      newNode
    });
    this.treeData = s.treeData;
    this.currentNodeModel.onConfirm = this.confirmNodeCreation(node, path as any[], s.treeIndex);
  });

  editNode = (node: any, path: number[] | string[]) => {
    this.currentNodeModel = new TreeNodeInputModel(node.title, this.id);
    this.currentNodeModel.setMode("edit");
    const newNode = {
      ...node,
      isEditMode: true,
      title: <TreeNodeInput model={this.currentNodeModel} />
    };
    this.updateNode(newNode, path);
    this.currentNodeModel.onConfirm = value => {
      this.onUpdate(
        {
          ...newNode,
          isEditMode: false,
          name: value
        },
        path
      );
    };
    this.currentNodeModel.onCancel = () => {
      this.updateNode(node, path);
    };
  };

  updateNode = action((newNode: any, path: number[] | string[]) => {
    this.treeData = changeNodeAtPath({
      treeData: this.treeData,
      path,
      getNodeKey,
      newNode
    });
  });

  confirmNodeCreation = (parentNode: any, parentPath: string[] | number[], treeIndex?: number | string) => (
    value: string
  ) => {
    let newNode: Partial<FP.Entities.IBusinessArea> = {
      name: value,
      parent: parentNode && parentNode[this.parentKeySelector]
    };
    this.onAdd(newNode, parentPath, treeIndex).then(e => {
      if (e) {
        this.currentNodeModel.confirm();
      }
    });
  };

  removeNode = action((node: any, path: number[] | string[]) => {
    this.treeData = removeNodeAtPath({ treeData: this.treeData, path, getNodeKey });
  });

  setExpand = action(isExpanded => {
    this.treeData = toggleExpandedForAll({ treeData: this.treeData, expanded: isExpanded });
  });

  hierarchyChanged = ({ node, treeIndex, path }) => {
    let parentNode = this.getParentNode(path);
    this.onHierarchyChanged(
      {
        ...node,
        parent: parentNode.node.id
      },
      path
    );
  };

  getParentNode = (path: string[] | number[]) => {
    let parentPath = path.slice();
    parentPath.pop();

    return getNodeAtPath({ treeData: this.treeData, getNodeKey, path: parentPath });
  };
}

export class TreeNodeInputModel extends BaseModel {
  @observable private mode: "display" | "edit" | "loading" = "display";
  @observable value: string = "";
  onConfirm: any | ((value: string) => void);
  onCancel: any | (() => void);
  id: string;

  constructor(value: string, id: string) {
    super();
    this.id = id;
    this.value = value;
  }

  setValue = (ev: React.FormEvent<HTMLInputElement>) => {
    this.value = ev.currentTarget.value;
  };

  setMode = (mode: "display" | "edit" | "loading") => {
    this.mode = mode;
  };

  getMode = () => this.mode;

  confirm = () => {
    this.mode = "display";
  };

  edit = () => {
    this.mode = "edit";
  };

  loading = () => {
    if (this.value !== "") {
      this.mode = "loading";
      this.onConfirm(this.value);
    }
  };
}

function generateActions(actions: IUiAction<any>[], node, path) {
  return actions.map(action => {
    let s = { ...action };
    let k: any = s.onAction;
    s.onAction = (ev: any) => {
      k(ev, { node, path });
    };
    return <UiActionComponent action={s} />;
  });
}
