import * as React from "react";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { AppService } from "strikejs-app-service";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../../core/filter/Filter_model";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { IFilteredOptions } from "../../../../../services/api/filteredApi/FilteredApiModel";
import { IProgrammesApi } from "../../../../../services/api/v1/programmes/IProgrammes.api";
import { Enums } from "../../../../../enums";
import { GetInheritUsersTableConfig, GetUserTableConfig } from "./ProgrammePermissions_config";
import { GetProgrammePermissionFields } from "./ProgrammePermissions_fields";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { IUiAction } from "../../../../../core/uiAction/IUiAction";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { ButtonIcon } from "../../../../../components/ui/Button";
import { Services } from "../../../../../constants";
import { TableModel } from "../../../../../core/table/Table_model";
import { Icon } from "../../../../../components/ui/Icon";
import { ICookieService } from "../../../../../core/util/CookieService";
import { Animations } from "../../../../../core/util/Animations";

export class ProgrammePermissionModel extends BaseModel {
  @observable isLoading: boolean = true;
  @observable programme: FP.Entities.IProgramme;
  programmeProvider: IProgrammesApi;
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IUser>;
  tableModel: ITableModel<FP.Entities.IUser>;
  inheritUserTable: ITableModel<any>;
  orgProvider: IOrganisationsApi;
  appService: AppService;
  modalService: IModalService;
  httpProgress: IHttpProgressModel;
  @observable isInheritLoading: boolean = true;
  @observable.ref users: FP.Entities.IUser[];
  orgId: number;
  programmeId: number;
  constructor(appService: AppService, programmeId: number) {
    super();
    this.programmeId = programmeId;
    this.modalService = appService.getService<IModalService>(Services.AsideModalService);
    this.orgProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.programmeProvider = appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    this.tableModel = new TableModel();
    this.inheritUserTable = new TableModel();
    this.appService = appService;
    let cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.orgId = parseInt(cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID), 10);

    this.installPagination();
    this.installFilter();
  }

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    this.filterModel = new FilterModel(this.appService, this.paginationModel, {
      filterCb: async (filters: Partial<IFilteredOptions>) => {
        return await this.programmeProvider.getPermissionUsers(this.orgId, this.programme.id, filters);
      }
    });

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "SystemUser.FirstName|SystemUser.LastName|SystemUser.Email",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.filterModel.addSort({
      key: "SystemUser.firstName,SystemUser.lastName",
      isAsc: true
    });
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.setConfig({
      formFields: null,
      onDataLoaded: d => this.setUsers(d)
    });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  onMount = (organisationId: number) => {
    let tableConfig = GetUserTableConfig(this, organisationId);
    let inTableConfig = GetInheritUsersTableConfig();
    this.tableModel.set(tableConfig);
    this.inheritUserTable.set(inTableConfig);
    this.loadUsers();
    this.loadInherit();
  };

  onUnmount = () => { };
  @action
  setProgramme = async (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
  };

  @action
  setUsers = users => {
    this.users = users;
    this.tableModel.setData(users);
  };

  @action
  loadUsers = async () => {
    this.isLoading = true;
    await this.filterModel.loadData();
    this.setUsers(this.filterModel.data);
    this.isLoading = false;
  };

  @action
  loadInherit = async () => {
    this.isInheritLoading = true;
    let data = await this.programmeProvider.getInheritUsers(this.orgId, this.programme.id);
    if (!data) {
      return;
    }
    this.inheritUserTable.setData(data.payload);
    this.isInheritLoading = false;
  };

  showAddUserModal = () => {
    let formFields = GetProgrammePermissionFields(this.programme.organisationId, this.programme.id, this.orgProvider);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (res) {
            this.modalService.hide();
            this.httpProgress.showOverlay();
            await this.programmeProvider.addPermission(this.orgId, this.programme.id, res as any);
            this.httpProgress.hideOverlay();
            this.filterModel.loadData();
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon symbol="close" className="float-right mr-1" onClick={this.modalService.hide} />
        </div>
      ),
      content: (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-lg-7">
              <p>{I18n.t("phrases.inviteUserDescription")}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">{formModel.renderComponent()}</div>
          </div>
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
      }
    });
  };

  removeUser = async (userId: number) => {
    this.httpProgress.showOverlay();
    await this.programmeProvider.removeUserPermission(this.orgId, this.programme.id, userId);
    this.httpProgress.hideOverlay();
  };

  showPermissionConfirmDeleteModal = row => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeUserAcccessFromProgramme")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: `${row.firstName} ${row.lastName}` })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeUser(row.id);
          await this.filterModel.loadData();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
