import { IDataDogLoggingService } from "./IDataDogLoggingService";
import { datadogLogs } from "@datadog/browser-logs";

export class DataDogLoggingService implements IDataDogLoggingService {
  constructor() {
    datadogLogs.init({
      clientToken: window.appConfig.datadog_Client_Token,
      site: "datadoghq.eu",
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: "Change UI",
      env: window.appConfig.appEnvironment
    });
  }

  defaultProperties = {
    host: window.appConfig.appEnvironment,
    service: "Change UI"
  };

  LogInfo = (event: string, properties: object): void => {
    datadogLogs.logger.info(event, Object.assign(this.defaultProperties, properties));
  };

  LogError = (message: string, properties: object): void => {
    datadogLogs.logger.error(message, Object.assign(this.defaultProperties, properties));
  };
  LogWarning = (message: string, properties: object): void => {
    datadogLogs.logger.error(message, Object.assign(this.defaultProperties, properties));
  };
}
