import React from "react";
import { BaseModel } from "../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { ITableModel, ITableConfig } from "../../../core/table/ITableModel";
import { TableModel } from "../../../core/table/Table_model";
import I18n from "../../../core/localization/I18n";
import moment from "moment";
import { Enums } from "../../../enums";
import { RenderXssSafeString } from "../../../core/AntiXss/AntiXssHelper";

export class RevisionHistoryCardModel extends BaseModel {
  tableModel: ITableModel<FP.Entities.IChangeEvent>;
  @observable isExpanded: boolean = false;
  @observable.ref revision: FP.Entities.IRevisionHistory;

  constructor(revision: FP.Entities.IRevisionHistory) {
    super();
    this.revision = revision;
    this.setTable();
  }

  @action
  toggleExpanded = () => {
    this.isExpanded = !this.isExpanded;
  };

  @action
  setTable = () => {
    this.tableModel = new TableModel();

    let tableConfig: ITableConfig<FP.Entities.IChangeEvent> = {
      colHeaders: [
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.field")}</h4>,
          selector: (obj: FP.Entities.IChangeEvent) => obj.property.split(/(?=[A-Z])/).join(" ")
        },
        {
          key: "from",
          content: <h4 className="mb-0">{I18n.t("table.from")}</h4>,
          selector: (obj: FP.Entities.IChangeEvent) => this.renderField(obj.property, obj.oldValue)
        },
        {
          key: "to",
          content: <h4 className="mb-0">{I18n.t("table.to")}</h4>,
          selector: (obj: FP.Entities.IChangeEvent) => this.renderField(obj.property, obj.newValue)
        }
      ] as any[],
      tableProps: {
        className: "no-border"
      }
    };

    this.tableModel.set(tableConfig);
    this.tableModel.setData(this.revision.changes);
  };

  renderField = (fieldName: string, value: any) => {
    if (value == null || typeof value == "undefined") return "-";

    if (Enums.Translator[fieldName]) {
      return Enums.Translator[fieldName](value);
    }

    switch (fieldName) {
      case "IsKeyStakeholder":
        return value ? I18n.t("phrases.yes") : I18n.t("phrases.no");
      case "StartDate":
      case "InitialEndDate":
      case "ActualEndDate":
        return moment(value).format("L");
      case "Sentiment":
      case "TargetSentiment":
        return (
          <div>
            {Enums.Translator.SentimentLevel(value)} ({value})
          </div>
        );
      case "Impact":
      case "Influence":
      case "Commitment":
      case "TargetCommitment":
        return (
          <div>
            {Enums.Translator.Metric(value)} ({value})
          </div>
        );
      case "Receptiveness":
      case "TargetReceptiveness":
        return (
          <div>
            {Enums.Translator.ReceptivenessLevel(value)} ({value})
          </div>
        );
      case "Description":
      case "Summary":
      case "ResistanceReason":
        return <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(value) }} />;
      case "Owner":
      case "Assignee":
        return `${value.firstName} ${value.lastName}`;
      case "ImpactGroup":
        return value.name;

      default:
        return value;
    }
  };
}
