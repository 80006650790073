import React, { useCallback, useEffect } from "react";
import { ImpactActionsModel } from "./ImpactActions_model";
import { Button, ButtonIcon } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { ButtonGroup } from "../../../../components/ui/ButtonGroup";
import { observer } from "mobx-react-lite";
import { ActionCard } from "../../actions/actionCard/ActionCard_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { CanViewProjectRoute } from "../../../../core/router/Route";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ImpactActionsProps {
  model: ImpactActionsModel;
}

const ImpactActions: React.FunctionComponent<ImpactActionsProps> = observer(({ model }) => {
  const { impactId } = useParams() as any;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  const loadImpact = useCallback(async () => {
    await model.loadActions(impactId);
  }, [impactId, model]);

  const actions = model.filteredActions
    .filter(action => {
      return model.toggle === "closed"
        ? action.progressStatus === Enums.ProgressStatus.COMPLETED
        : action.progressStatus !== Enums.ProgressStatus.COMPLETED;
    })
    .map(action => <ActionCard key={action.id} item={action} actions={model.actionCardActions} className="mb-2" />);

  return (
    <Async promiseFn={loadImpact}>
      <Async.Loading>
        <PositionedSpinner background="transparent" />;
      </Async.Loading>
      <Async.Resolved>
        <CanViewProjectRoute projectId={model.projectId}>
          <div className={`container-fluid pt-5 ${Animations.FADE_IN} speed-4`}>
            <div className="row mb-4">
              <div className="col">
                <CanEditProject projectId={model.projectId}>
                  <Button onClick={model.showAddActionModal}>
                    <Icon symbol="Plus" className="mr-2" />
                    {I18n.t("phrases.addAction")}
                  </Button>
                </CanEditProject>

                <ButtonIcon
                  className="float-right ml-1"
                  symbol={model.isSearchMode ? "Close" : "Search"}
                  iconSize={Enums.UiSizes.SM}
                  type="outline-primary"
                  onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
                />

                <ButtonGroup className="float-right" isToggle={true}>
                  <Button
                    type={model.toggle === "active" ? "primary" : "outline-primary"}
                    onClick={() => {
                      model.toggle = "active";
                    }}
                  >
                    {I18n.t("phrases.active")}
                  </Button>
                  <Button
                    type={model.toggle === "closed" ? "primary" : "outline-primary"}
                    onClick={() => {
                      model.toggle = "closed";
                    }}
                  >
                    {I18n.t("phrases.completed")}
                  </Button>
                </ButtonGroup>

                <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                  <Input
                    autoFocus={true}
                    size={-1}
                    onChange={model.updateSearchValue}
                    borderStyle="underline"
                    placeholder={I18n.t("placeholders.searchImpactActions")}
                    icon="search"
                    value={model.searchValue}
                    onBlur={null}
                    className="mt-3"
                  />
                </AnimateHeight>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">{actions.length ? actions : <div>{I18n.t("phrases.noImpactActions")}</div>}</div>
            </div>
          </div>
        </CanViewProjectRoute>
      </Async.Resolved>
    </Async>
  );
});

export { ImpactActions };
