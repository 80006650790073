import React, { useEffect, useState } from "react";
import { CreateActionViewModel } from "./CreateActionView_model";
import { observer } from "mobx-react-lite";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../core/localization/I18n";
import { LinkButton } from "../../../../components/ui/Button";
import { UiSizes } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import { useAppService } from "../../../../AppService";
import { useFlightPathUser } from "../../../../setup";
import { useCurrentOrganisation } from "../../../../core/auth/organisationContext";
import { useRouteMatch } from "react-router-dom";

export interface CreateActionViewProps {
  model?: CreateActionViewModel;
}

const CreateActionView: React.FunctionComponent<CreateActionViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const router = useRouteMatch();
  const currentOrganisation = useCurrentOrganisation();
  const [model] = useState(
    () =>
      m ||
      new CreateActionViewModel({
        appService,
        user,
        organisationId: currentOrganisation.id,
        projectId: router.params["projectId"]
      })
  );

  const getImpactIdFromQueryString = ():number => {
    let querystring = window.location.search;
    let params = new URLSearchParams(querystring);
    let impactId = params.get("impactId");
    return +impactId ?? 0;
  }

  const actionCreatingFromImpact = ():boolean => {
    return getImpactIdFromQueryString() > 0;
  }

  useEffect(() => {
    var impactId = getImpactIdFromQueryString();
    model.onMount(impactId);
  }, [model]);

  return (
    <div className="create-Action-view container-fluid pt-6">
      {!!model.projectId && (
        <div className="row mb-3">
          <div className="col-12">
            {actionCreatingFromImpact() && (
              <LinkButton
              size={UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${currentOrganisation.id}/projects/${model.projectId}/impacts`}
            >
              <Icon className="mr-1" symbol="ChevronLeft" />
              {I18n.t("phrases.backToImpacts")}
            </LinkButton>
            )}
            {actionCreatingFromImpact() === false && (
              <LinkButton
                size={UiSizes.MD}
                type="link-big"
                className="p-0"
                href={`/organisations/${currentOrganisation.id}/projects/${model.projectId}/actions`}
              >
                <Icon className="mr-1" symbol="ChevronLeft" />
                {I18n.t("phrases.goBackToActions")}
              </LinkButton>
            )}
          </div>
        </div>
      )}
      <div className="row mb-4">
        <div className="col-12">
          <h1>
            {I18n.t("phrases.createNew")} <strong>{I18n.t("entities.action")}</strong>
          </h1>
        </div>
        <div className="col-lg-7">
          <p>{I18n.t("phrases.createActionDescription")}</p>
        </div>
      </div>

      {model.projectForm && (
        <div className="row mb-5 bg-primary-light-5 py-5">
          <div className="col-lg-10">
            <SingleForm model={model.projectForm} />
          </div>
        </div>
      )}

      {model.formModel && (
        <div className="row mb-4">
          <div className="col-lg-10">
            <SingleForm model={model.formModel} />
          </div>
        </div>
      )}
    </div>
  );
});

export { CreateActionView };
