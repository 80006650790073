import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IHttp } from "../../IHttp";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { IProjectsApi } from "./IProject.api";
import { AxiosRequestConfig } from "axios";
import { getAppToken } from "../../../../setup";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";

export class ProjectsApi extends FilteredOrganisationApiModel<FP.Entities.IProject> implements IProjectsApi {
  controller: string = "projects";
  urlWithOrgId: string;

  constructor(http: IHttp) {
    super(http, "projects");
    this.urlWithOrgId = `${this.http.url}/${this.version}/organisations`;
  }

  async loadProjectsByOrganisationId(id: number) {
    const resp = await fetch(`${this.urlWithOrgId}/${id}/projects`, {
      headers: {
        authorization: `Bearer ${await getAppToken()}`
      }
    });

    return await resp.json();
  }

  getInsights = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectInsights>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/insights`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectInsights>;
    }
  };

  getHistory = async (
    organisationId: number,
    projectId: number,
    entityId: number,
    historyType: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IRevisionHistory[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/${historyType}/${entityId}/history`;
    let res = await this.http.get(url, config);

    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IRevisionHistory[]>;
    }
  };

  //#region Stakeholders

  getProjectStakeholderImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/projectStakeholder/${projectStakeholderId}/impacts`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  getUnassignedProjectStakeholders = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/unassignedStakeholders`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholder[]>;
    }
  };
  //#endregion

  //#region Stakeholder Group
  removeStakeholderGroup = async (
    organisationId: number,
    projectId: number,
    stGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholderGroups/${stGroupId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  addStakeholderGroup = async (
    organisationId: number,
    projectId: number,
    stakeholder: FP.Entities.IStakeholderGroup,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholderGroups`;
    let res = await this.http.post(url, stakeholder, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>;
    }
  };

  getStakeholderGroups = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholderGroups`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>;
    }
  };

  //#endregion

  //#region Impacts

  getImpacts = async (
    organisationId: number,
    projectId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impacts${
      (filterOptions && this.getRequestQuery(filterOptions)) || ""
    }`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  getImpactsCount = async (
    organisationId: number,
    projectId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impacts/count`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  //#endregion

  //#region Impact Groups

  getImpactGroups = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/impact-groups`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpactGroup[]>;
    }
  };
  //#endregion

  //#region Actions

  getActions = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/actions`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
  };

  getActionsCount = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/actions/count`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
  };

  //#endregion

  //#region users

  getUsers = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/projectUsers`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
  };

  //#endregion

  //#region Stakeholder impacts

  addProjectStakeholderImpacts = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    impacts: FP.Entities.IImpact[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/stakeholders/impacts/${projectStakeholderId}`;
    let res = await this.http.post(url, impacts, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderGroup>;
    }
  };

  //#endregion

  //#region permissions

  addPermission = async (
    organisationId: number,
    projectId: number,
    permissions: FP.Entities.IPermission,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IPermission>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/permissions/create`;
    let res = await this.http.post(url, permissions, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IPermission>;
    }
  };

  getPermissionUsers = async (
    organisationId: number,
    projectId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/permissions`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
  };

  removeUserPermission = async (
    organisationId: number,
    projectId: number,
    userId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/permissions/${userId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };

  getInheritUsers = async (
    organisationId: number,
    projectId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${projectId}/inherited-users`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
  };

  //#endregion
}
