import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { ProjectSearchPanelModel } from "../../../../components/widgets/searchPanel/ProjectSearchPanel_model";
import { ProgrammeSearchPanelModel } from "../../../../components/widgets/searchPanel/ProgrammeSearchPanel_model";
import { IModalService } from "../../../../core/modal/IModalService";
import { Services } from "../../../../constants";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { TimeLineModel, ITimelineItem, ITimelineGroup } from "../../../../components/widgets/timeline/Timeline_model";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import I18n from "../../../../core/localization/I18n";

export interface IOrganisationViewModel {
  orgId: number;
  projectSearchModel: ProjectSearchPanelModel;
  programmeSearchModel: ProgrammeSearchPanelModel;
  onMount: () => void;
  onUnmount: () => void;
  organisation: FP.Entities.IOrganisation;
  isLoading: boolean;
  programmeTimelineModel: TimeLineModel;
  projectTimelineModel: TimeLineModel;
  timelineViewMode: "programme" | "project";
  setTimelineViewMode: (viewMode: "programme" | "project") => void;
  loadOrganisation(organisationId: number): Promise<void>;
}

export class OrganisationViewModel extends BaseModel implements IOrganisationViewModel {
  projectSearchModel: ProjectSearchPanelModel;
  programmeSearchModel: ProgrammeSearchPanelModel;
  innerModalService: IModalService;
  orgProvider: IOrganisationsApi;
  appService: AppService;
  programmeTimelineModel: TimeLineModel;
  projectTimelineModel: TimeLineModel;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  @observable orgId: number;
  @observable timelineViewMode: "programme" | "project" = "programme";
  @observable.ref organisation: FP.Entities.IOrganisation;

  constructor(appService: AppService, user: FP.Entities.IUser) {
    super();

    this.appService = appService;
    this.authUser = user;
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.programmeTimelineModel = new TimeLineModel();
    this.projectTimelineModel = new TimeLineModel();
  }

  @action
  loadOrganisation = async (id: number) => {
    this.orgId = id;
    let res = await this.orgProvider.getByIdAsync(id);
    if (res && !res.isError) {
      this.setOrganisation(res.payload);
      this.setSearchModels();
    }
  };

  @action
  setOrganisation = (org: FP.Entities.IOrganisation) => {
    this.organisation = org;
    this.setProgrammeTimeLineItems(this.organisation);
    this.setProjectTimeLineItems(this.organisation);
  };

  @action
  setSearchModels = () => {
    this.programmeSearchModel = new ProgrammeSearchPanelModel(this.appService, this.organisation, () =>
      this.loadOrganisation(this.orgId)
    );
    this.projectSearchModel = new ProjectSearchPanelModel(this.appService, this.organisation, () =>
      this.loadOrganisation(this.orgId)
    );
    this.programmeSearchModel.setItems(this.organisation.programmes);
    this.projectSearchModel.setItems(this.organisation.projects);
  };

  @action
  setTimelineViewMode = (viewMode: "programme" | "project") => {
    this.timelineViewMode = viewMode;
  };

  setProgrammeTimeLineItems = (organisation: FP.Entities.IOrganisation) => {
    let minStartDate: moment.Moment;
    let maxEndDate: moment.Moment;

    // Calculates earliest programme start date and latest end date
    for (const programme of organisation.programmes) {
      const startDate = moment(programme.startDate);
      const endDate = moment(programme.actualEndDate);

      if (!minStartDate || startDate.isBefore(minStartDate)) {
        minStartDate = startDate;
      }
      if (!maxEndDate || endDate.isAfter(maxEndDate)) {
        maxEndDate = endDate;
      }
    }

    const items: ITimelineItem[] = organisation.programmes.map(programme => {
      return {
        id: programme.id,
        group: programme.id,
        title: programme.name,
        start_time: moment(programme.startDate),
        end_time: moment(programme.actualEndDate),
        data: programme
      };
    });

    const groups: ITimelineGroup[] = organisation.programmes.map(programme => {
      return {
        id: programme.id,
        title:
          programme.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{programme.name}</small>}>
              {programme.name}
            </Tooltip>
          ) : (
              programme.name
            )
      };
    });

    this.programmeTimelineModel.noItemsText = I18n.t("phrases.noOrganisationProgrammes");
    if (minStartDate && maxEndDate) {
      this.programmeTimelineModel.setDefaultDates(minStartDate.subtract(1, "week"), maxEndDate.add(1, "week"));
      this.programmeTimelineModel.setVisibleDates(minStartDate.subtract(1, "week"), maxEndDate.add(1, "week"));
    }
    this.programmeTimelineModel.setItems(items);
    this.programmeTimelineModel.setGroups(groups);
    this.programmeTimelineModel.itemRenderer = props => {
      const { item, getItemProps } = props;

      const itemProps = getItemProps({});
      const top = parseInt(itemProps.style.top.replace("px", ""))
      itemProps.style = { ...itemProps.style, height: "12px", lineHeight: "12px", top: (top + 3) + "px" }

      const programme: FP.Entities.IProgramme = item.data;

      return (
        <Link key={item.id} to={`/organisations/${this.orgId}/programmes/${item.id}`}>
          <Tooltip
            theme="light"
            followCursor
            html={
              <>
                <small className="d-block">{programme.name}</small>
                <small className="d-block">
                  {I18n.t("table.startDate")}: {moment(programme.startDate).format("L")}
                </small>
                <small className="d-block">
                  {I18n.t("table.endDate")}: {moment(programme.actualEndDate).format("L")}
                </small>
              </>
            }
          >
            <div {...itemProps} title={null}></div>
          </Tooltip>
        </Link>
      );
    };
    this.programmeTimelineModel.isLoading = false;
  };

  setProjectTimeLineItems = (organisation: FP.Entities.IOrganisation) => {
    let minStartDate: moment.Moment;
    let maxEndDate: moment.Moment;

    // Calculates earliest programme start date and latest end date
    for (const project of organisation.projects) {
      const startDate = moment(project.startDate);
      const endDate = moment(project.actualEndDate);

      if (!minStartDate || startDate.isBefore(minStartDate)) {
        minStartDate = startDate;
      }
      if (!maxEndDate || endDate.isAfter(maxEndDate)) {
        maxEndDate = endDate;
      }
    }

    const items: ITimelineItem[] = organisation.projects.map(project => {
      return {
        id: project.id,
        group: project.id,
        title: project.name,
        start_time: moment(project.startDate),
        end_time: moment(project.actualEndDate),
        data: project
      };
    });

    const groups: ITimelineGroup[] = organisation.projects.map(project => {
      return {
        id: project.id,
        title:
          project.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{project.name}</small>}>
              {project.name}
            </Tooltip>
          ) : (
              project.name
            )
      };
    });

    this.projectTimelineModel.noItemsText = I18n.t("phrases.noOrganisationProjects");
    if (minStartDate && maxEndDate) {
      this.projectTimelineModel.setDefaultDates(minStartDate.subtract(1, "week"), maxEndDate.add(1, "week"));
      this.projectTimelineModel.setVisibleDates(minStartDate.subtract(1, "week"), maxEndDate.add(1, "week"));
    }
    this.projectTimelineModel.setItems(items);
    this.projectTimelineModel.setGroups(groups);
    this.projectTimelineModel.itemRenderer = props => {
      const { item, getItemProps } = props;
      const itemProps = getItemProps({});
      const top = parseInt(itemProps.style.top.replace("px", ""))
      itemProps.style = { ...itemProps.style, height: "12px", lineHeight: "12px", top: (top + 3) + "px" }
      const project: FP.Entities.IProject = item.data;

      return (
        <Link key={item.id} to={`/organisations/${this.orgId}/projects/${item.id}`}>
          <Tooltip
            theme="light"
            followCursor
            html={
              <>
                <small className="d-block">{project.name}</small>
                <small className="d-block">
                  {I18n.t("table.startDate")}: {moment(project.startDate).format("L")}
                </small>
                <small className="d-block">
                  {I18n.t("table.endDate")}: {moment(project.actualEndDate).format("L")}
                </small>
              </>
            }
          >
            <div {...itemProps} title={null}></div>
          </Tooltip>
        </Link>
      );
    };
    this.projectTimelineModel.isLoading = false;
  };

  onMount = () => {
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
  };

  onUnmount = () => { };

}
