import * as React from "react";
import { ImpactStakeholdersModel } from "./ImpactStakeholders_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { IF } from "../../../../components/hoc/If";
import { LinkButton, Button, ButtonIcon } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import { Link } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import { StakeholderCard } from "../../stakeholders/StakeholderCard";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { observer } from "mobx-react-lite";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useEffect } from "react";

export interface ImpactStakeholdersProps {
  model: ImpactStakeholdersModel;
}

export const ImpactStakeholders: React.FC<ImpactStakeholdersProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading || !model.impact) {
    return <PositionedSpinner />;
  }

  return (
    <div className="impact-stakeholders pt-5">
      <div className="container-fluid">
        <IF condition={model.isRouteView}>
          <div className="row mb-3">
            <div className="col">
              <LinkButton
                size={Enums.UiSizes.MD}
                type="link-big"
                className="p-0"
                href={`/organisations/${organisationId}/projects/${model.impact.project.id}/impacts/${model.impact.id}`}
              >
                <Icon className="mr-2" symbol="ChevronLeft" />
                {I18n.t("phrases.backToImpactDetails")}
              </LinkButton>
            </div>
          </div>
        </IF>

        <div className="row mb-5">
          <div className="col">
            <CanEditProject projectId={model.impact.project.id}>
              <Button onClick={model.showAddStakeholdersModal}>{I18n.t("phrases.addStakeholder")}</Button>
              <Button className="ml-2" type="outline-primary" onClick={model.showAddStakeholderGroupModal}>
                {I18n.t("phrases.addCollection")}
              </Button>
            </CanEditProject>

            <ButtonIcon
              className="float-right"
              symbol={model.isSearchMode ? "Close" : "Search"}
              iconSize={Enums.UiSizes.SM}
              type="outline-primary"
              onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
            />

            <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
              <Input
                autoFocus={true}
                size={-1}
                onChange={model.updateSearchValue}
                borderStyle="underline"
                placeholder={I18n.t("placeholders.searchImpactStakeholders")}
                icon="search"
                value={model.searchValue}
                onBlur={null}
                className="mt-3"
              />
            </AnimateHeight>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {model.filteredProjectStakeholders?.length ? (
              model.filteredProjectStakeholders.map(e => {
                let stakeholder = e.stakeholder;
                return (
                  <Link
                    key={stakeholder.id}
                    to={`/organisations/${organisationId}/projects/${model.impact.projectId}/stakeholders/${stakeholder.id}`}
                  >
                    <StakeholderCard stakeholder={stakeholder} />
                  </Link>
                );
              })
            ) : (
              <div>{I18n.t("phrases.noImpactStakeholders")}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
