import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { IOrganisationContextModel } from "../../../../../services/local/organisationContext/IOrganisationContextModel";
import { observable } from "mobx";
import { Services } from "../../../../../constants";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import I18n from "../../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IPermissionService } from "../../../../../services/local/permissionService/IPermissionService";
import { IUsersApi } from "../../../../../services/api/v1/users/IUsers.api";
import { IModalService } from "../../../../../core/modal/IModalService";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getOrganisationFormFields } from "../../../forms/organisation/OrganisationFormSection_data";

export class OrganisationSettingsModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  orgProvider: IOrganisationsApi;
  formModel: SingleFormModel;
  organisationContextModel: IOrganisationContextModel;
  permissionService: IPermissionService;
  organisationId: number;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref organisation: FP.Entities.IOrganisation;
  modalService: IModalService;
  usersProvider: IUsersApi;
  refreshClaims: () => void;

  constructor(appService: AppService, routeProps: RouteComponentProps, refreshClaims: () => void, organisationId: number) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.organisationId = organisationId;
    this.refreshClaims = refreshClaims;
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.permissionService = this.appService.getService<IPermissionService>(Services.PermissionService);
    this.organisationContextModel = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.usersProvider = appService.getService<IUsersApi>(Services.UsersApi);
    this.modalService = appService.getService<IModalService>(Services.ModalService);

  }

  loadForm = async () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getOrganisationFormFields(this.usersProvider, this.orgProvider, this.modalService, this.organisation);
    this.formModel.actions = this.permissionService.canEditOrg()
      ? [
        {
          id: "cancel",
          label: I18n.t("phrases.cancel"),
          rendersIn: "LinkButton",
          componentProps: {
            type: "link",
            className: "ml-auto",
            href: `/organisations/${this.organisationId}`
          }
        },
        {
          id: "save",
          label: I18n.t("phrases.save"),
          onAction: async () => {
            let res = await this.formModel.submit();
            if (res) {
              res.isImageChanged = res.profileImage ? true : false;
              let formData = new FormData();
              Object.keys(res).forEach(e => {
                if (e === "profileImage" && res[e].blob) {
                  formData.append(e, res[e].blob, new Date().getTime() + ".jpg");
                  return;
                }
                formData.append(e, res[e]);
              });

              this.httpProgress.showOverlay();
              const result = await this.orgProvider.updateAsync(res.id, formData as any);
              this.refreshClaims();
              this.httpProgress.hideOverlay();

              if (!result || result.isError) {
                return;
              }

              // await this.organisationContextModel.loadOrganisations();

              // const foundOrganisation = this.organisationContextModel.organisationList.find(
              //   e => e.id === this.organisationId
              // );
              // if (foundOrganisation) {
              //   this.organisationContextModel.setCurrentOrganisation(foundOrganisation);
              // }
              this.routeProps.history.push(`/organisations/${res.id}`);
            }
          },
          rendersIn: "Button",
          componentProps: {
            type: "primary",
            className: "ml-2"
          }
        }
      ]
      : [];
    this.isLoading = false;
  };
}
