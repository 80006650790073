import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { ImpactGroupFormSectionModel } from "../../forms/impactGroups/ImpactGroupFormSection_model";

export class CreateImpactGroupViewModel extends BaseModel {
  orgId: number;
  formModel: ImpactGroupFormSectionModel;

  constructor({
    appService,
    organisationId,
    projectId
  }: {
    appService: AppService;
    organisationId: number;
    projectId: number | null;
  }) {
    super();

    this.orgId = organisationId;
    this.formModel = new ImpactGroupFormSectionModel({ appService, organisationId, projectId });
  }
}
