import { B } from "../../../core/util/BootstrapHelper";
import * as React from "react";
import { UiSizes } from "../../../enums";

export interface AvatarProps {
  /**
   * Button class name
   */
  className?: string;
  id?: string;
  onClick?: (e: React.SyntheticEvent<any>) => void;
  type?: "primary" | "secondary";
  borderType?: "border-primary";
  size?: UiSizes;
  hoverLabel?: React.ReactNode;
  imgSrc: string;
  imgLink?: string;
}

const Avatar: React.FunctionComponent<AvatarProps> = props => {
  const cls: any = B()
    .bl("avatar")
    .pmod(props.type || "primary")
    .pmod(props.size || "md")
    .pmod(props.borderType || "").bem;

  return (
    <div className={`${cls} ${props.className || ""}`}>
      {props.imgLink ? (
        <a href={props.imgLink}>
          <div
            className="avatar__content"
            style={{
              backgroundImage: `url('${props.imgSrc}')`
            }}
          />
        </a>
      ) : (
        <div className="avatar__content" style={{ backgroundImage: `url("${props.imgSrc}")` }} />
      )}
      {props.hoverLabel && <div className="avatar__label">{props.hoverLabel}</div>}
    </div>
  );
};

export { Avatar };
