import * as React from "react";
import { EditStakeholderViewModel } from "./EditStakeholderView_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { StakeholderDetails } from "../StakeholderDetails";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { Enums } from "../../../../enums";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { useParams } from "react-router-dom";
import { Animations } from "../../../../core/util/Animations";

export interface EditStakeholderViewProps {
  model: EditStakeholderViewModel;
}

const EditStakeholderView: React.FunctionComponent<EditStakeholderViewProps> = observer(({ model }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  const { stakeholder } = model;

  return (
    <div className={`data-extended-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid data-extended-view__block data-extended-view__block--header">
        <div className="row">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders/${model.stakeholderId}`}
            >
              <Icon className="mr-2" symbol="chevron-left" />
              {I18n.t("phrases.backToStakeholder")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="data-extended-view__block data-extended-view__block--wrapper">
        <div className={`data-extended-view__col pt-4 container-fluid ${Animations.FP_ZOOM_IN} speed-4`}>
          <div className="row mb-5">
            <div className="col">
              <h2 className="mb-0 d-inline-block">
                {convertStakeholderToName(stakeholder)} | <strong>{I18n.t("phrases.details")}</strong>
              </h2>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-10">
              <h2>{I18n.t("phrases.projectProfile")}</h2>
              <SingleForm model={model.proStakeholderFormModel} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <hr />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-10">
              <h2 className="mt-4">{I18n.t("phrases.stakeholderProfile")}</h2>
              <StakeholderDetails item={stakeholder} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { EditStakeholderView };
