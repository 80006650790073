import React, { useEffect } from "react";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { StakeholderListViewModel } from "./StakeholderListView_model";
import { TableView } from "../../../../core/table/Table_view";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { Pagination } from "../../../../components/widgets/pagination/Pagination_view";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { SearchButton } from "../../../../components/ui/SearchButton";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface StakeholderListViewProps {
  model: StakeholderListViewModel;
}

const StakeholderListView: React.FunctionComponent<StakeholderListViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();

  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  let searchValue = model.filterModel.getFilter("Stakeholder.FirstName|Stakeholder.LastName")?.value;
  return (
    <div className={`stakeholder-list-view ${Animations.FP_ZOOM_IN} speed-4`}>
      {!model.hideHeaderButtons && (
        <div className="row mb-5">
          <div className="col">
            <CanEditProject projectId={model.projectId}>
              {model.stakeholderType === Enums.StakeholderType.INDIVIDUAL ? (
                <>
                  <LinkButton id="AddStakeholderButton" href={`/organisations/${organisationId}/projects/${model.projectId}/addStakeholder`} className="mr-2">
                    <Icon symbol="plus" className="mr-2" />
                    {I18n.t("phrases.addStakeholder")}
                  </LinkButton>
                  <LinkButton href={`/organisations/${organisationId}/projects/${model.projectId}/addStakeholderGroup`} type="outline-primary">
                    <Icon symbol="plus" className="mr-2" />
                    {I18n.t("phrases.addMultipleStakeholders")}
                  </LinkButton>
                </>
              ) : (
                  <LinkButton id="AddAudienceButton" href={`/organisations/${organisationId}/projects/${model.projectId}/addAudience`} className="mr-2">
                    <Icon symbol="plus" className="mr-2" />
                    {I18n.t("phrases.addAudience")}
                  </LinkButton>
                )}
              
            </CanEditProject>
            <SearchButton
              onChange={model.onSearchChange}
              value={searchValue.length > 0 ? searchValue[0] : ""}
              className={"float-right ml-2"}
              onClose={model.resetNameFilter}
              buttonType="outline-primary"
            />
            <Button
              className={"float-right ml-2"}
              type="outline-primary"
              onClick={model.exportService.showSelectionModal}
            >
              <Icon symbol="download" className="mr-2" />
              {I18n.t("phrases.export")}
            </Button>
            <Button
              className={"float-right ml-2"}
              type="outline-primary"
              onClick={model.filterModel.showFilterFormModal}
            >
              <Icon symbol="filter" className="mr-2" />
              {I18n.t("phrases.applyFilters")}
            </Button>
          </div>
        </div>
      )}

      <div className="row mb-2">
        <div className="col-12">
          <div className="d-flex flex-wrap">
            <FilterPills filterModel={model.filterModel} />
          </div>
        </div>
      </div>
      <div key="stakeholders" className={`${Animations.FP_ZOOM_IN} speed-4`}>
        <TableView model={model.tableModel} />
        <Pagination model={model.paginationModel} />
      </div>
    </div>
  );
});

export { StakeholderListView };
