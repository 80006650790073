import * as React from 'react';

const SvgCloudSnow = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="cloud-snow_svg__feather cloud-snow_svg__feather-cloud-snow"
        {...props}
    >
        <path d="M20 17.58A5 5 0 0018 8h-1.26A8 8 0 104 16.25M8 16h0M8 20h0M12 18h0M12 22h0M16 16h0M16 20h0" />
    </svg>
);

export default SvgCloudSnow;
