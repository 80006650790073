import Polyglot from "node-polyglot";
import { Locale } from "../../locales/en-GB";

class I18n {
    private locale: string;
    private data: Dictionary<any>;
    private polyglot: Polyglot;

    constructor() {
        this.polyglot = new Polyglot(Locale);
    }

    setLocale = (loc: string) => {
        this.locale = loc;
        this.polyglot.locale(loc);
    }

    setData = (data: Dictionary<any>) => {
        this.data = data;
        this.polyglot.extend(data);
    }

    getLocale = () => {
        return this.locale;
    }

    getData = () => {
        return this.data;
    }

    t = (key: string, options?: number | Polyglot.InterpolationOptions): string => {
        let res = this.polyglot.t(key, options);
        return res === key ? `{--${key}--}` : res;
    }

    get(key: string, smartNum?: number) {
        return this.polyglot.t(key, smartNum);
    }

    format(key: string, val: any, def?: any) {
        return this.polyglot.t(key, def ? { ...val, _: def } : val ? { ...val, _: key } : { _: key });
    }

    has(key: string) {
        return this.data.phrases[key] ? true : false;
    }

}

export default new I18n(); 