import * as React from "react";
import { StakeholderExtendedViewModel } from "./StakeholderExtendedView_model";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ProjectStakeholderCard } from "../../projects/projectStakeholderCard/ProjectStakeholderCard_view";
import { StakeholderDetails } from "../StakeholderDetails";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { Enums } from "../../../../enums";
import { StakeholderImpactsView } from "../stakeholderImpactsView/StakeholderImpactsView_view";
import { StakeholderConcernsView } from "../stakeholderConcernsView/StakeholderConcernsView_view";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { RevisionHistoryView } from "../../revisionHistory/RevisionHistory_view";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface StakeholderExtendedViewProps {
  model: StakeholderExtendedViewModel;
}

export const StakeholderExtendedView: React.FunctionComponent<StakeholderExtendedViewProps> = observer(({ model }) => {
  const { stakeholder, projectStakeholder } = model;
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`data-extended-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`} id="ProjectStakeholderPage">
      <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
        <div className="row">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders`}
            >
              <Icon className="mr-2" symbol="chevron-left" />
              {I18n.t("phrases.backToStakeholders")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="data-extended-view__block data-extended-view__block--wrapper">
        <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-4`}>
          <div className="row mb-4">
            <div className="col">
              <div className="d-flex">
                <h2 className="mb-0">
                  {convertStakeholderToName(stakeholder)} | <strong>{I18n.t("phrases.details")}</strong>
                </h2>

                {!model.isEditView && (
                  <CanEditProject projectId={model.projectId}>
                    <LinkButton
                      className="ml-auto"
                      id="EditStakeholderButton"
                      type={"outline-primary"}
                      href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders/${model.stakeholderId}/edit`}
                    >
                      {" "}
                      <Icon symbol="pencil" size={Enums.UiSizes.SM} className="mr-2" />
                      {I18n.t("phrases.edit")}
                    </LinkButton>
                  </CanEditProject>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h2>{I18n.t("phrases.projectProfile")}</h2>
            </div>
          </div>
          {model.isEditView ? (
            <>
              <div className="row mb-4">
                <div className="col-12">
                  <SingleForm model={model.proStakeholderFormModel} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Button onClick={model.updateProjectStakeholder} className="float-right ml-2">
                    {I18n.t("phrases.save")}
                  </Button>
                  <Button
                    onClick={() => {
                      model.setIsEditView(false);
                    }}
                    type="outline-primary"
                    className="float-right"
                  >
                    {I18n.t("phrases.cancel")}
                  </Button>
                </div>
              </div>
            </>
          ) : (
              <div className="row">
                <div className="col">
                  <ProjectStakeholderCard item={projectStakeholder} />
                  <hr />
                </div>
              </div>
            )}
          <div className="row">
            <div className="col">
              <h2 className="mt-4">{I18n.t("phrases.stakeholderProfile")}</h2>
              <StakeholderDetails item={stakeholder} />
            </div>
          </div>
        </div>
        <Panel
          background="bg-light"
          className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-4`}
        >
          <TabGroup
            className="data-extended-view__tabs"
            tabs={[
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.impactedBy")}</h3>,
                children: <StakeholderImpactsView model={model.stakeholderImpactModel} />
              },
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.keyConcerns")}</h3>,
                children: <StakeholderConcernsView model={model.stakeholderConcernsModel} />
              },
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                children: <CommentListView model={model.commentViewModel} />
              },
              {
                title: <h3 className="mb-0 text-center">{I18n.t("phrases.stakeholderHistory")}</h3>,
                children: <RevisionHistoryView model={model.revisionHistoryModel} />
              }
            ]}
          ></TabGroup>
        </Panel>
      </div>
    </div>
  );
});
