import { observable, action } from "mobx";
import { RouteComponentProps } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../constants";
import { IHttpProgressModel } from "../../../core/httpProgress/HttpProgress_model";
import { BaseModel } from "../../../core/util/BaseModel";
import { IQuickImpactsApi } from "../../../services/api/v1/quickImpacts/IQuickImpacts.api";
import { ImpactFormSectionModel } from "../forms/impact/ImpactFormSection_model";


export class EditQuickImpactViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectId: number = null;
  impactId: number = null;
  httpProgress: IHttpProgressModel;
  quickImpactProvider: IQuickImpactsApi;
  @observable isLoading: boolean = true;
  @observable.ref formModel: ImpactFormSectionModel;
  @observable.ref impact: FP.Entities.IQuickImpact;
  organisationId: number;
  isTestUser: boolean;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, isTestUser: boolean, organisationId: number) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.isTestUser = isTestUser;

    this.projectId = parseInt(this.routeProps.match.params["id"]);
    this.impactId = parseInt(this.routeProps.match.params["impactId"]);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.quickImpactProvider = this.appService.getService<IQuickImpactsApi>(Services.QuickImpactsApi);
    this.organisationId = organisationId;
    this.loadImpact();
  }

  onMount = async (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => { };

  @action
  loadImpact = async () => {
    let res = await this.quickImpactProvider.getById(this.organisationId, this.projectId, this.impactId);
    if (!res || res.isError) return;
    this.impact = res.payload;
    this.formModel = new ImpactFormSectionModel(
      this.appService,
      this.routeProps,
      this.isTestUser,
      {
        showActionsInView: false,
        projectId: this.projectId
      },
      this.impact as FP.Entities.IImpact
    );
    this.isLoading = false;
  };

  updateImpact = async () => {
    let res: any = await this.formModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.quickImpactProvider.migrateToComplete(this.organisationId, this.projectId, this.impactId, res as FP.Entities.IImpact);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;
    let impact = result.payload;
    this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${impact.id}`);
  };
}