import React, { useEffect } from "react";
import { ActionImpactsModel } from "./ActionImpacts_model";
import { IF } from "../../../../components/hoc/If";
import { LinkButton, Button, ButtonIcon } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import { ImpactCard } from "../../impacts/impactCard/ImpactCard_view";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ActionImpactsProps {
  model: ActionImpactsModel;
}

const ActionImpacts: React.FunctionComponent<ActionImpactsProps> = observer(props => {
  const { model } = props;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`container-fluid pt-5 ${Animations.FP_ZOOM_IN} speed-5`}>
      <IF condition={model.isRouteView}>
        <div className="row mb-3">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisationId}/projects/${model.action.projectId}/actions/${model.action.id}`}
            >
              <Icon className="mr-2" symbol="ChevronLeft" />
              {I18n.t("phrases.backToImpact")}
            </LinkButton>
          </div>
        </div>
      </IF>

      <div className="row mb-4">
        <div className="col">
          <CanEditProject projectId={model.action.projectId}>
            <Button onClick={model.showAddImpactsModal}>{I18n.t("phrases.addImpact")}</Button>
          </CanEditProject>

          <ButtonIcon
            className="float-right"
            symbol={model.isSearchMode ? "Close" : "Search"}
            iconSize={Enums.UiSizes.SM}
            type="outline-primary"
            onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
          />

          <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
            <Input
              autoFocus={true}
              size={-1}
              onChange={model.updateSearchValue}
              borderStyle="underline"
              placeholder={I18n.t("placeholders.searchImpactActions")}
              icon="search"
              value={model.searchValue}
              onBlur={null}
              className="mt-3"
            />
          </AnimateHeight>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col">
          {model.filteredActionImpacts?.length ? (
            model.filteredActionImpacts.map(impact => (
              <Link
                key={impact.id}
                to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}
              >
                <ImpactCard item={impact} />
              </Link>
            ))
          ) : (
            <div>{I18n.t("phrases.noActionImpacts")}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export { ActionImpacts };
