import * as React from 'react';

const SvgCode = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="code_svg__feather code_svg__feather-code"
        {...props}
    >
        <path d="M16 18l6-6-6-6M8 6l-6 6 6 6" />
    </svg>
);

export default SvgCode;
