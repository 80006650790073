import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel"
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init"
import I18n from "../../../../core/localization/I18n"
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";

export const getActionTypeFormFields = (orgId: number, actionType?: FP.Entities.IActionType) => {
    const org: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "organisationId",
        inputType: "hidden",
        value: orgId + "",
        defaultValue: orgId + ""
    }

    const name: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "name",
        placeholder: I18n.t("placeholders.actionTypeName"),
        label: <label htmlFor="name">{I18n.t("forms.actionTypeName")} *</label>,
        fieldClassName: "col-12",
        validate: function () {
            const self: TextFieldModel = this;
            if (Validations.IS_EMPTY(self.value)) {
                self.errorMessage = <ErrorMessage key="name">{I18n.t("validations.actionTypeName")}</ErrorMessage>
                return false;
            }
            return true;
        },
        value: actionType?.name
    }

    const fields = [];

    fields.push(org);
    fields.push(name);

    const models = generateFormFieldsFromJson(fields);

    return models;
}