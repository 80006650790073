import React, { useState } from "react";
import { CreateImpactGroupViewModel } from "./CreateImpactGroupView_model";
import { observer } from "mobx-react-lite";
import { ImpactGroupFormSectionView } from "../../forms/impactGroups/ImpactGroupFormSection_view";
import I18n from "../../../../core/localization/I18n";
import { LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import { useAppService } from "../../../../AppService";
import { useCurrentOrganisation, useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useParams } from "react-router-dom";

export interface CreateImpactGroupViewProps {
  model?: CreateImpactGroupViewModel;
}

const CreateImpactGroupView: React.FunctionComponent<CreateImpactGroupViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model] = useState(
    () =>
      m ||
      new CreateImpactGroupViewModel({
        appService,
        organisationId,
        projectId: !!projectId ? +projectId : null
      })
  );

  return (
    <div className="create-Impact-view container-fluid pt-6">
      {!!projectId && (
        <div className="row mb-4">
          <div className="col">
            <LinkButton
              size={Enums.UiSizes.MD}
              type="link-big"
              className="p-0"
              href={`/organisations/${organisation.id}/projects/${projectId}/impacts`}
            >
              <Icon className="mr-2" symbol="ChevronLeft" />
              {I18n.t("phrases.backToImpacts")}
            </LinkButton>
          </div>
        </div>
      )}
      <div className="row mb-4">
        <div className="col-12">
          <h1>
            {I18n.t("phrases.createNew")} <strong>{I18n.t("entities.impactGroup")}</strong>
          </h1>
        </div>
        <div className="col-12 col-lg-7">
          <p>{I18n.t("phrases.createImpactGroupDescription")}</p>
        </div>
      </div>
      <ImpactGroupFormSectionView model={model.formModel} />
    </div>
  );
});

export { CreateImpactGroupView };
