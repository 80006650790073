import * as React from "react";
import { HeatmapDataValueItem, HeatmapModel, HeatmapXAxisItem, HeatmapYAxisItem } from "./Heatmap_model";
import * as _ from "lodash";
import { observer } from "mobx-react";

export interface HeatmapBodyProps {
  model: HeatmapModel;
}

const HeatmapBody: React.FC<HeatmapBodyProps> = observer(props => {
  const { sidebarItems } = props.model;
  const headers = _.flatMap(props.model.headerGroups);
  return (
    <div className="heatmap__body">
      {_.map(sidebarItems, (e, i) => (
        <HeatmapRow key={i} headers={headers} model={props.model} row={e} />
      ))}
    </div>
  );
});

interface HeatmapRowProps {
  row: HeatmapYAxisItem;
  headers: HeatmapXAxisItem[];
  model: HeatmapModel;
}

const HeatmapRow: React.FC<HeatmapRowProps> = observer(props => {
  const { row, headers, model } = props;
  const { heatmapData: data } = model;

  return (
    <div className="heatmap__row" style={{ width: 50 * headers.length }}>
      {_.map(headers, (header, i) => {
        const cell = _.find(data, ee => `${ee.xAxisKey}` === `${header.key}` && `${ee.yAxisKey}` === `${row.key}`);

        return <Cell key={`${header.key}_${row.key}_${i}`} cell={cell} header={header} row={row} model={model} />;
      })}
    </div>
  );
});

const Cell: React.FC<{
  cell: HeatmapDataValueItem;
  model: HeatmapModel;
  header: HeatmapXAxisItem;
  row: HeatmapYAxisItem;
}> = observer(({ cell, model, row, header }) => {
  const val = !cell ? "0" : cell.value;
  return (
    <div
      className={`heatmap__cell ${cell ? cell.className : "heatmap__cell--green"} ${
        model.highlightedCell && cell !== model.highlightedCell ? "heatmap__cell--shaded" : ""
      }`}
      onClick={() => {
        model.onCellClick && model.onCellClick(row, header, cell);
      }}
    >
      {val}
    </div>
  );
});

export { HeatmapBody };
