import I18n from "../../../../core/localization/I18n";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { Enums } from "../../../../enums"
import { ImpactViewModel } from "./ImpactsView_model";



export const GetImpactTableActions = (parentModel: ImpactViewModel) => (row: FP.Entities.IImpact) => {

  let actions = [];
  if (row.impactCompletionState === Enums.ImpactCompletionState.Complete) {
    actions.push({
      id: "view",
      label: I18n.t("phrases.view"),
      rendersIn: "HTMLAnchor",
      componentProps: {
        type: "link"
      },
      hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/impacts/${context.rowObject.id}`
    });
    if (parentModel.permissionService.canEditProject(parentModel.projectId)) {
      actions.push(
        {
          id: "action1",
          label: I18n.t("phrases.edit"),
          rendersIn: "HTMLAnchor",
          componentProps: {
            type: "link"
          },
          hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/impacts/${context.rowObject.id}/edit`
        },
        {
          id: "action2",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            parentModel.showImpactConfirmDeleteModal(parentModel.projectId, row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        }
      );
    }
  } else {
    if (parentModel.permissionService.canEditProject(parentModel.projectId)) {
      actions.push(
        {
          id: "action1",
          label: I18n.t("phrases.complete"),
          rendersIn: "HTMLAnchor",
          componentProps: {
            type: "link"
          },
          hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/quick-impacts/${context.rowObject.id}/edit`
        },
        {
          id: "action1",
          label: I18n.t("phrases.edit"),
          onAction: (ev, row: ITableRowModel) => {
            parentModel.quickImpactModel.showUpdateModal(row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button",
        },
        {
          id: "action2",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            parentModel.quickImpactModel.showQuickImpactConfirmDeleteModal(parentModel.projectId, row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        }
      );
    }
  }

  return actions;
}