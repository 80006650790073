import { AxiosRequestConfig } from "axios";
import { BaseApiModel, IBaseApiModel, IFlightPathApiResponse } from "../BaseApiModel";

export interface IFilteredOptions {
  // currentPage: number;
  // nextPage: string;
  // prevPage: string;
  // pageSize: number;
  // totalPages: number;
  // totalItems: number;
  page: number;
  pageSize: number;
  sorts?: string;
  filters?: string;
}

export const INIT_FILTER_OPTIONS: IFilteredOptions = {
  page: 1,
  pageSize: 10
};

export interface IFilteredPagination {
  currentPage: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export type IFilteredResponse<T> = IFlightPathApiResponse<T> & {
  pagination?: IFilteredPagination;
  payload?: T[];
  sentDate?: Date;
};

export interface IFilteredRequest {
  page?: number;
  pageSize?: number;
  sorts?: string;
  filters?: string;
}

export interface IFilteredApiModel<T> extends IBaseApiModel<T> {
  getFilteredAsync: (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    parentId?: number,
    secondaryParentId?: number
  ) => Promise<IFilteredResponse<T>>;
}

export abstract class FilteredApiModel<T> extends BaseApiModel<T> implements IFilteredApiModel<T> {
  abstract controller: string;
  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    parentId?: number,
    secondaryParentId?: number
  ): Promise<IFilteredResponse<T>> => {
    let query = this.getRequestQuery(filterOptions);
    let res = await this.http.get(this.url + query, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getRequestQuery = (filters: Partial<IFilteredOptions>): string => {
    let s: IFilteredRequest = {
      page: filters.page,
      pageSize: filters.pageSize,
      sorts: filters.sorts,
      filters: filters.filters
    };
    let params = "?";
    params += s.page ? `page=${s.page}&` : "";
    params += s.pageSize ? `pageSize=${s.pageSize}` : "";
    params += s.sorts ? "&sorts=" + s.sorts : "";
    params += s.filters ? "&filters=" + s.filters : "";
    return params.replace(/^\?&(.*)$/i, "?$1");
  };
}
