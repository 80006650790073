import * as _ from "lodash";
import { Enums } from "../../../../../enums";

function getCellClassName(item) {
  if (item <= 4) {
    return "heatmap__cell--green";
  } else if (item < 7) {
    return "heatmap__cell--amber";
  } else if (item >= 7) {
    return "heatmap__cell--red";
  } else {
    return "heatmap__cell--green";
  }
}


const CsvExportFilename = (name: string) => {
  return `Impact Report - By Project - ${name}`;
}

export const ImpactsByProjectsConfig = {

  businessAreas: {
    csvFilename: CsvExportFilename("Business Areas"),
    csvSideBarTitle: "Business Area",
    rotateHeader: true,
    xAxisDomain: data => {
      const { impactByBusinessAreaData } = data;
      const filteredBusinessAreas = _.filter(impactByBusinessAreaData, e => e.projectId !== 0);
      return filteredBusinessAreas.map(filteredBusinessArea => {
        return {
          key: filteredBusinessArea.projectId + "",
          label: filteredBusinessArea.projectName,
          parent: filteredBusinessArea.programmeName
        };
      });
    },
    yAxisDomain: data => {
      const { hierarchy, impactByBusinessAreaData } = data;
      return hierarchy.map(businessArea => {
        const rows = _.filter(impactByBusinessAreaData, (k: any) => k.businessAreaId === businessArea.id);
        const sum = _.sumBy(rows, e => e.impactCount);
        const avg = _.mean(_.map(rows, e => e.impactAverage));
        return {
          key: businessArea.id + "",
          label: businessArea.name,
          parent: businessArea.parentId === 0 ? "" : businessArea.parentId + "",
          shouldShowChildren: false,
          total: sum,
          className: getCellClassName(avg)
        };
      });
    },
    dataGenerator: data => {
      const { impactByBusinessAreaData } = data;
      return impactByBusinessAreaData.map(impactByBusinessArea => {
        return {
          key: `${impactByBusinessArea.projectId}_${impactByBusinessArea.businessAreaId}`,
          xAxisKey: impactByBusinessArea.projectId,
          yAxisKey: impactByBusinessArea.businessAreaId,
          value: impactByBusinessArea.impactCount,
          className: getCellClassName(impactByBusinessArea.impactAverage),
          impactLevel: impactByBusinessArea.impactAverage
        };
      });
    }
  },
  locations: {
    csvFilename: CsvExportFilename("Locations"),
    csvSideBarTitle: "Locations",
    rotateHeader: true,
    xAxisDomain: data => {
      const { impactByLocationData } = data;
      const filteredLocations = _.filter(impactByLocationData, e => e.projectId !== 0);
      return filteredLocations.map(location => {
        return {
          key: location.projectId + "",
          label: location.projectName,
          parent: location.programmeName
        };
      });
    },
    yAxisDomain: data => {
      const { hierarchy, impactByLocationData } = data;
      return hierarchy.map(location => {
        const rows = _.filter(impactByLocationData, (k: any) => k.locationId === location.id);
        const sum = _.sumBy(rows, e => e.impactCount);
        const avg = _.mean(_.map(rows, e => e.impactAverage));
        return {
          key: location.id + "",
          label: location.name,
          parent: location.parentId === 0 ? "" : location.parentId + "",
          shouldShowChildren: false,
          total: sum,
          className: getCellClassName(avg)
        };
      });
    },
    dataGenerator: data => {
      const { impactByLocationData } = data;
      return impactByLocationData.map(impactLocation => {
        return {
          key: `${impactLocation.projectId}_${impactLocation.locationId}`,
          xAxisKey: impactLocation.projectId,
          yAxisKey: impactLocation.locationId,
          value: impactLocation.impactCount,
          className: getCellClassName(impactLocation.impactAverage),
          impactLevel: impactLocation.impactAverage
        };
      });
    }
  },
  stakeholders: {
    csvFilename: CsvExportFilename("Stakeholders"),
    csvSideBarTitle: "Stakeholders",
    rotateHeader: true,
    xAxisDomain: data => {
      return data.map(impactStakeholder => {
        return {
          key: impactStakeholder.projectId + "",
          label: impactStakeholder.projectName,
          parent: impactStakeholder.programmeName,
          selector: "impacts"
        };
      });
    },
    yAxisDomain: data => {
      return data.map(impactStakeholder => {
        const rows = _.filter(data, (k: any) => k.stakeholderId === impactStakeholder.stakeholderId);
        const sum = _.sumBy(rows, e => e.impactCount);
        const avg = _.mean(_.map(rows, e => e.impactAverage));
        const name =
          impactStakeholder.stakeholderType === Enums.StakeholderType.AUDIENCE
            ? `${impactStakeholder.firstName} (Audience)`
            : `${impactStakeholder.firstName} ${impactStakeholder.lastName}`;
        return {
          key: impactStakeholder.stakeholderId + "",
          label: name,
          shouldShowChildren: false,
          total: sum,
          parent: "",
          className: getCellClassName(avg)
        };
      });
    },
    dataGenerator: data => {
      return data.map(impactStakeholder => {
        return {
          key: _.kebabCase(impactStakeholder.projectId + impactStakeholder.stakeholderId),
          xAxisKey: impactStakeholder.projectId,
          yAxisKey: impactStakeholder.stakeholderId,
          value: impactStakeholder.impactCount,
          className: getCellClassName(impactStakeholder.impactAverage),
          impactLevel: impactStakeholder.impactAverage
        };
      });
    }
  }
}
