import { PaginatedContentModel } from "../PaginatedContent_model";
import { IPaginatedTableModel, IPaginatedTableConfig } from "./IPaginatedTableModel";
import { IPaginatedContentConfig } from "../IPaginatedContentModel";

/**
 * @category Pagination
 */
export class PaginatedTableModel<T> extends PaginatedContentModel<T> implements IPaginatedTableModel<T> {
  constructor(initOpts?: IPaginatedTableConfig<T>) {
    super(initOpts as IPaginatedContentConfig<T>);
  }

  onPageChange(currentPage: number): void {}
}
