import * as React from "react";

import { ISettingsViewModel } from "./SettingsView_model";
import { InnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { observer } from "mobx-react-lite";

export interface SettingsViewProps {
  model: ISettingsViewModel;
}

export const SettingsView: React.FunctionComponent<SettingsViewProps> = observer(({ model }) => {
  return (
    <div className="settings-view">
      <InnerNavContent model={model.innerNavContentModel} />
    </div>
  );
});
