import React, { useState } from "react";
import { CreateProjectViewModel } from "./CreateProjectView_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { useAppService } from "../../../../AppService";
import { useFlightPathUser } from "../../../../setup";
import { useRefreshClaims } from "../../../../core/auth/authorise";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export interface CreateProjectViewProps {
  model?: CreateProjectViewModel;
}

const CreateProjectView: React.FunctionComponent<CreateProjectViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const refreshClaims = useRefreshClaims();
  const [model] = useState(() => m || new CreateProjectViewModel(appService, user, refreshClaims, organisationId));

  return (
    <div data-testid="create-project-view" className="create-project-view container-fluid pt-6">
      <div className="row mb-5">
        <div className="col-12">
          <h1>
            {I18n.t("phrases.createNew")} <strong>{I18n.t("entities.project")}</strong>
          </h1>
        </div>
        <div className="col-12 col-lg-7">
          <p className="mb-0">{I18n.t("phrases.createProjectDescription")}</p>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-lg-10">
          <SingleForm model={model.formModel} />
        </div>
      </div>
    </div>
  );
});

export { CreateProjectView };
