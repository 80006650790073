import { BaseModel, IModel } from "../util/BaseModel";
import { observable, action } from "mobx";

export interface IHttpProgressModel extends IModel {
  isOverlayVisible: boolean;
  isProgressVisible: boolean;
  showOverlay: () => void;
  hideOverlay: () => void;
  showProgress: () => void;
  showHideProgress: () => void;
}

export class HttpProgressModel extends BaseModel implements IHttpProgressModel {
  @observable isOverlayVisible: boolean = false;
  @observable isProgressVisible: boolean = false;

  @action
  showOverlay = () => {
    this.isOverlayVisible = true;
  };

  @action
  hideOverlay = () => {
    this.isOverlayVisible = false;
  };

  @action
  showProgress = () => {
    this.isProgressVisible = true;
  };

  @action
  showHideProgress = () => {
    this.isProgressVisible = false;
  };
}
