import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { RouteComponentProps } from "react-router";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../../constants";
import { ProjectsApi } from "../../../../services/api/v1/projects/Project.api";
import {
  TimeLineModel,
  ITimelineItem,
  ITimelineGroup,
  ITimelineMarker
} from "../../../../components/widgets/timeline/Timeline_model";
import moment from "moment";
import { Link } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import { Tooltip } from "react-tippy";
import { IRecentProjectsService } from "../../../../services/local/recentProjectsService/IRecentProjectsService";

export interface IProjectViewModel {
  id: number;
  project?: FP.Entities.IProject;
  insights?: FP.Entities.IProjectInsights;
  actions: FP.Entities.IAction[];
  selectedMilestone?: FP.Entities.IMilestone;
  appService: AppService;
  routeProps: RouteComponentProps;
  timelineModel: TimeLineModel;
  isLoadingProject: boolean;
  isLoadingInsights: boolean;
  setMilestone: (milestones: FP.Entities.IMilestone) => void;
  handleMilestoneClick: (milestone: FP.Entities.IMilestone) => void;
}

export class ProjectViewModel extends BaseModel implements IProjectViewModel {
  id: number;
  appService: AppService;
  routeProps: RouteComponentProps;
  projectsApi: ProjectsApi;
  timelineModel: TimeLineModel;
  @observable isLoadingProject: boolean = true;
  @observable isLoadingInsights: boolean = true;
  @observable.ref project?: FP.Entities.IProject = null;
  @observable.ref insights?: FP.Entities.IProjectInsights = null;
  @observable.ref actions: FP.Entities.IAction[] = [];
  @observable.ref selectedMilestone?: FP.Entities.IMilestone;
  organisationId: number;

  constructor(appService: AppService, projectId: number, orgId: number) {
    super();
    this.appService = appService;
    this.organisationId = orgId;
    this.projectsApi = this.appService.getService(Services.ProjectsApi);
    this.id = projectId; //parseInt(this.routeProps.match.params["id"], 10);
    this.timelineModel = new TimeLineModel();
  }

  @action.bound
  async load() {
    await Promise.all([this.loadProject(this.id), this.loadActions(this.id), this.loadInsights(this.id)]);
    this.setTimeLineItems();
  }

  loadProject = async (id: number) => {
    const res = await this.projectsApi.getById(this.organisationId, id);
    if (!res || res.isError) return;
    this.setProject(res.payload);
  };

  loadInsights = async (projectId: number) => {
    this.isLoadingInsights = true;
    const res = await this.projectsApi.getInsights(this.organisationId, projectId);
    if (!res || res.isError) return;
    this.setInsights(res.payload);
    this.isLoadingInsights = false;
  };

  loadActions = async (projectId: number) => {
    const res = await this.projectsApi.getActions(this.organisationId, projectId);
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
    const recentProjectsService = this.appService.getService<IRecentProjectsService>(Services.RecentProjectsService);
    if (project) {
      recentProjectsService.addProject({
        projectId: project.id,
        organisationId: project.organisationId,
        name: project.name
      })
    }
    this.setTimelineDefaultDates(this.project);
  };

  @action
  setMilestone = (milestone: FP.Entities.IMilestone) => {
    this.selectedMilestone = milestone;
  };

  @action
  setInsights = (insights: FP.Entities.IProjectInsights) => {
    this.insights = insights;
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.actions = actions;
  };

  handleMilestoneClick = (milestone: FP.Entities.IMilestone) => {
    this.setMilestone(milestone);

    const foundMarker = this.timelineModel.markers.find(marker => marker.id === milestone.id);

    if (foundMarker) {
      this.timelineModel.setSelectedMarker(foundMarker);
      this.timelineModel.scrollToDate(foundMarker.date);
    }
  };

  handleTimelineMarkerSelect = (marker: ITimelineMarker) => {
    const foundMilestone = this.project.milestones.find(milestone => milestone.id === marker.id);

    if (foundMilestone) {
      this.setMilestone(foundMilestone);
    }
  };

  setTimelineDefaultDates = (project: FP.Entities.IProject) => {
    const startDate = moment(project.startDate);
    const endDate = moment(project.actualEndDate);
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate, endDate);
  };

  setTimeLineItems = () => {
    const items: ITimelineItem[] = this.actions.map(action => {
      return {
        id: action.id,
        group: action.id,
        title: action.name,
        start_time: moment(action.startDate).startOf("day"),
        end_time: moment(action.actualEndDate).endOf("day"),
        data: action
      };
    });

    const groups: ITimelineGroup[] = this.actions.map(action => {
      return {
        id: action.id,
        title:
          action.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{action.name}</small>}>
              {action.name}
            </Tooltip>
          ) : (
              action.name
            )
      };
    });

    const markers: ITimelineMarker[] = this.project.milestones.map(milestone => {
      return {
        id: milestone.id,
        date: moment(milestone.deadline),
        title: milestone.name
      };
    });

    this.timelineModel.noItemsText = I18n.t("phrases.noProjectActionsOrMilestones");
    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setMarkers(markers);
    this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = props => {
      const { item, getItemProps } = props;
      const action: FP.Entities.IAction = item.data;
      const itemProps = getItemProps({});
      const top = parseInt(itemProps.style.top.replace("px", ""))
      itemProps.style = { ...itemProps.style, height: "12px", lineHeight: "12px", top: (top + 3) + "px" }
      return (
        <Link key={item.id} to={`/organisations/${this.organisationId}/projects/${this.id}/actions/${item.id}`}>
          <Tooltip
            theme="light"
            followCursor
            html={
              <>
                <small className="d-block">{action.name}</small>
                <small className="d-block">
                  {I18n.t("table.startDate")}: {moment(action.startDate).format("L")}
                </small>
                <small className="d-block">
                  {I18n.t("table.endDate")}: {moment(action.actualEndDate).format("L")}
                </small>
              </>
            }
          >
            <div {...itemProps} title={null} />
          </Tooltip>
        </Link>
      );
    };
    this.timelineModel.isLoading = false;
  };
}
