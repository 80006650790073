import React, { useCallback, useState } from "react";
import { UserViewModel } from "./View_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { LinkButton } from "../../../../components/ui/Button";
import { UiSizes } from "../../../../enums";
import { Icon } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react-lite";
import { UserDetails } from "../userDetails";
import { useAppService } from "../../../../AppService";
import { useParams } from "react-router-dom";
import { Async } from "react-async";
import { useOrganisationContext } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface UserViewProps {
  model?: UserViewModel;
}

export const UserView: React.FunctionComponent<UserViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const { currentOrganisation } = useOrganisationContext();
  const { userId } = useParams<{ userId: string }>();
  const [model] = useState(() => m || new UserViewModel(appService));

  const loadUser = useCallback(async () => {
    return model.loadUser(+userId);
  }, [model, userId]);

  return (
    <Async promiseFn={loadUser}>
      <Async.Loading>
        <PositionedSpinner />;
      </Async.Loading>
      <Async.Resolved>
        {() => {
          const { user } = model;
          return (
            <div className="data-extended-view pt-6">
              <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
                <div className="row">
                  <div className="col">
                    <LinkButton
                      size={UiSizes.MD}
                      type="link-big"
                      className="p-0"
                      href={`/organisations/${currentOrganisation.id}/settings/users`}
                    >
                      <Icon className="mr-2" symbol="chevron-left" />
                      {I18n.t("phrases.backToUsers")}
                    </LinkButton>
                  </div>
                </div>
              </div>
              <div className="data-extended-view__block data-extended-view__block--wrapper">
                <div className={`data-extended-view__col container-fluid ${Animations.FP_ZOOM_IN} speed-5`}>
                  <div className="row mb-4">
                    <div className="col">
                      <h2 className="mb-0">
                        {user.firstName} {user.lastName} | <strong>{I18n.t("phrases.userDetails")}</strong>
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <UserDetails item={user} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Async.Resolved>
      <Async.Rejected>{err => err.message}</Async.Rejected>
    </Async>
  );
});
