import React, { useEffect } from "react";
import { CreateImpactViewModel } from "./CreateImpactView_model";
import { observer } from "mobx-react-lite";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export interface CreateImpactViewProps {
  model: CreateImpactViewModel;
}

const CreateImpactView: React.FunctionComponent<CreateImpactViewProps> = observer(props => {
  const { model } = props;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  });

  return (
    <div className="create-Impact-view pt-6">
      {!!model.projectId && (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col">
              <LinkButton
                size={Enums.UiSizes.MD}
                type="link-big"
                className="p-0"
                href={`/organisations/${organisationId}/projects/${model.projectId}/impacts`}
              >
                <Icon className="mr-2" symbol="ChevronLeft" />
                {I18n.t("phrases.backToImpacts")}
              </LinkButton>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12">
            <h1>
              {I18n.t("phrases.createNew")} <strong>{I18n.t("entities.impact")}</strong>
            </h1>
          </div>
          <div className="col-lg-7">
            <p>{I18n.t("phrases.createImpactDescription")}</p>
          </div>
        </div>

        {model.projectForm && (
          <div className="row mb-5 bg-primary-light-5 py-5">
            <div className="col-lg-10">
              <SingleForm model={model.projectForm} />
            </div>
          </div>
        )}

        {model.topForm && (
          <div className="row mb-4">
            <div className="col-lg-10">
              <SingleForm model={model.topForm} />
            </div>
          </div>
        )}

        {model.middleForm && (
          <div className="row mb-5 bg-primary-light-5 py-5">
            <div className="col-lg-10">
              <SingleForm model={model.middleForm} />
            </div>
          </div>
        )}

        {model.bottomForm && (
          <div className="row mb-4">
            <div className="col-lg-10">
              <SingleForm model={model.bottomForm} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export { CreateImpactView };
