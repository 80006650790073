import { IPermissionService } from "../../services/local/permissionService/IPermissionService";
import AppService from "../../AppService";
import { Services } from "../../constants";
export interface IModel {
  // i18n: I18nLocalizer;
  permissionService?: IPermissionService;
  renderComponent?: () => React.ReactNode;
}

export abstract class BaseModel implements IModel {
  permissionService: IPermissionService;
  constructor() {
    this.permissionService = AppService.getService<IPermissionService>(Services.PermissionService);
  }

  // i18n: I18nLocalizer = createDefaultI18nLocalizer();
}
