import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { AutocompleteModel } from "../../../../core/forms/controls/autocomplete/Autocomplete_model";
import { ListingModel, INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";

export const getImpactGroupForm = (
  impactProvider: IImpactsApi,
  projId: number,
  organisationId: number,
  impactGroup?: Partial<FP.Entities.IImpactGroup>
) => {
  const projectId = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projId,
    defaultValue: projId
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.impactGroupName"),
    label: <label htmlFor="name">{I18n.t("forms.impactGroupName")} *</label>,
    fieldClassName: "col-sm-12 col-lg-6 col-space-lg-6",
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactGroupName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: impactGroup?.name
  };

  const benefit: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "changeBenefitText",
    label: <label htmlFor="changeBenefitText">{I18n.t("forms.changeBenefit")} *</label>,
    fieldClassName: "col-12",
    validate: function () {
      const self: IRTEditorModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.changeBenefit")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: impactGroup?.changeBenefitText
  };

  const impactSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactSearch",
    label: <label htmlFor={"impactSearch"}>{I18n.t("forms.searchImpactsForImpactGroup")}</label>,
    placeholder: I18n.t("placeholders.searchImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function (model, val) {
      this.componentProps.disabled = false;

      const filterOptions: IFilteredOptions = {
        page: 0,
        pageSize: 0
      };

      const res = await impactProvider.getFiltered(organisationId, impactGroup?.project?.id || projId, filterOptions);

      if (res?.payload) {
        const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
        model.setOptions(sortedImpacts);
      }
    },
    subscribeTo: ["impacts"],
    onItemSelected: async function () {
      const self: AutocompleteModel = this;
      const listingModel: ListingModel = self.channels.impacts as ListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, item => {
        const lowerName = item.name.toLowerCase();
        const lowerRef = item.refNumber?.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: "col-lg-6"
  };

  const impacts: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "impacts",
    label: <label htmlFor={"impacts"}>{I18n.t("forms.selectedImpacts")}</label>,
    placeholder: I18n.t("placeholders.selectImpact"),
    fieldClassName: "col-lg-6",
    selector: (e: FP.Entities.IImpact) => (
      <p className="mb-0 d-inline-block">
        {e.refNumber} {e.name}
      </p>
    ),
    value: impactGroup?.impacts,
    extractValue: function () {
      return (this.value && this.value.map(e => e.id)) || [];
    }
  };

  const fields = [];

  fields.push(projectId);
  fields.push(name);
  fields.push(benefit);
  fields.push(impactSearch);
  fields.push(impacts);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
