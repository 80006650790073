import * as React from "react";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import { UiActionComponent } from "../../../core/uiAction/UiAction";

export interface ITab {
  children: React.ReactNode;
  title: React.ReactNode;
  id?: string;
  actions?: IUiAction<any>;
}

export interface ITabGroupProps {
  tabs: ITab[];
  className?: string;
  active?: number;
}

export const TabGroup: React.FunctionComponent<ITabGroupProps> = ({ tabs, active, className }) => {
  const [activeTab, setActiveTab] = React.useState(active && active < tabs.length ? active : 0);

  if (tabs.length === 0) return null;

  return (
    <div className={`tab-group ${className || ""}`}>
      <div className="tab-group__tabs">
        {tabs.map((tab, idx) => {
          let cls = "tab-group__tab";
          if (idx === activeTab) cls += " tab-group__tab--active";

          return (
            <div className={cls} id={tab.id} key={idx} onClick={() => setActiveTab(idx)}>
              {tab.title}
              <div className="tab-group__tab-actions">{tab.actions && <UiActionComponent action={tab.actions} />}</div>
            </div>
          );
        })}
      </div>
      <div className="tab-group__content">{tabs[activeTab].children}</div>
    </div>
  );
};
