import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { IDonutInfoModel } from "../../../../components/widgets/donutInfo/DonutInfo_model";
import { action, observable } from "mobx";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import * as H from "history";
import { IInnerNavContentConfig, IInnerNavContentItem, IInnerNavContentModel, InnerNavContentModel } from "../../../../components/widgets/innerNavigation/InnerNavContent_model";
import React from "react";
import { ActionVisualisationsPlaceholderPage } from "./ActionVisualisationsPlaceholderPage/ActionVisualisationsPlaceholderPage_view";
import { RLink } from "../../../../components/ui/Rlink";
import { ActionVisualisationsPlaceholderPageModel } from "./ActionVisualisationsPlaceholderPage/ActionVisualisationsPlaceholderPage_model";
import { IInnerNavigationService } from "../../../../services/local/innerNavigationService/IInnerNavigationService";
import I18n from "../../../../core/localization/I18n";

export class ActionVisualisationsViewModel extends BaseModel {
  appService: AppService;
  history: H.History<unknown>;
  projectId: number;
  projectProvider: IProjectsApi;
  organisationSettings: IOrganisationSettingsService;
  innerNavigationService: IInnerNavigationService;
  @observable.ref actions: FP.Entities.IAction[];
  @observable.ref generalInfo: IDonutInfoModel[] = [];
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  @observable showVisualisations: boolean;
  innerNavContentModel: IInnerNavContentModel;
  @observable currentPage?: string;

  constructor(appService: AppService, history: H.History, projectId: number | null, organisationId: number, page: string) {
    super();
    this.appService = appService;
    this.history = history;
    
    this.organisationId = organisationId;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.organisationSettings = this.appService.getService<IOrganisationSettingsService>(Services.OrganisationSettingsService);
    this.innerNavigationService = this.appService.getService<IInnerNavigationService>(Services.InnerNavigationService);
    if (projectId) {
      this.projectId = projectId;
    }
    this.loadProject();
    this.showVisualisations = true;
    this.innerNavContentModel = new InnerNavContentModel(this.innerNavigationConfig());
    this.currentPage = page || "";
    this.setPage();
  }

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  loadShowVisualisations = async () =>
    await this.organisationSettings.isVisualisationsEnabled(this.organisationId);

  onMount = async () => {
    Promise.all([this.loadShowVisualisations()]).then(values => {
      this.showVisualisations = values[0];
    });
  };

  onUnmount = () => {};

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 1) {
      this.history.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/action-visualisations`
      );
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions`);
  };

  // ------------------------------------
  // Visualisation section navigation
  // ------------------------------------

  createImpactVisualisationsPlaceholderModel = (title: string) => {
    return new ActionVisualisationsPlaceholderPageModel(
      this.appService,
      null,
      this.projectId,
      this.organisationId,
      title
    );
  };

  innerNavigationContent = (): Dictionary<IInnerNavContentItem> => {
    let result: Dictionary<IInnerNavContentItem> = {
      summary: this.innerNavigationService.createInnerNavContentItem(
        "summary",
        I18n.t("phrases.visualisationNavigationSummary"),
        <ActionVisualisationsPlaceholderPage model={this.createImpactVisualisationsPlaceholderModel("Summary page")} />
      ),
      totals: this.innerNavigationService.createInnerNavContentItem(
        "totals",
        I18n.t("phrases.visualisationNavigationTotal"),
        <ActionVisualisationsPlaceholderPage model={this.createImpactVisualisationsPlaceholderModel("Totals page")} />
      ),
      "over-time": this.innerNavigationService.createInnerNavContentItem(
        "over-time",
        I18n.t("phrases.visualisationNavigationOverTime"),
        <ActionVisualisationsPlaceholderPage
          model={this.createImpactVisualisationsPlaceholderModel("Over time page")}
        />
      )
    };
    return result;
  };

  innerNavigationConfig = (): IInnerNavContentConfig => {
    return {
      navigationTitle: I18n.t("phrases.visualisationNavigationHeading"),
      displayNavWithLinks: true,
      linkRender: e => (
        <RLink to={`/organisations/${this.organisationId}/projects/${this.projectId}/action-visualisations/${e.key}`}>
          <h4 id={`inner-nav-${e.key}`} className="mb-0">
            {e.label}
          </h4>
        </RLink>
      ),
      items: this.innerNavigationContent(),
      blockClassName: "navigation-visualisation"
    };
  };

  innerNavigationConfigKeys = () => {
    var keys = [];
    Object.entries(this.innerNavigationConfig().items).forEach(([key, item]) => {
      return keys.push(item.key);
    });
    return keys;
  };

  setPage = () => {
    let page = this.currentPage;
    var keys = this.innerNavigationService.innerNavigationConfigKeys(this.innerNavigationConfig().items);
    if (page && keys.indexOf(page) >= 0) {
      this.innerNavContentModel.setCurrentViewKey(page);
    }
  };
}
