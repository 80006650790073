import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { getProjectStakeholderFormFields } from "../../forms/projectStakeholder/ProjectStakeholder_data";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";

export class EditStakeholderViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectProvider: IProjectsApi;
  projectId: number;
  stakeholderId: number;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref stakeholder: FP.Entities.IStakeholder;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref proStakeholderFormModel: SingleFormModel;
  projectStakeholderProvider: IProjectStakeholdersApi;
  organisationId: number;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationId = parseInt(this.routeProps.match.params["organisationId"])
    this.projectId = parseInt(this.routeProps.match.params["id"]);
    this.stakeholderId = parseInt(this.routeProps.match.params["sid"]);
    this.loadProjectStakeholder(this.projectId, this.stakeholderId);
  }

  onMount = () => { };

  onUnmount = () => { };

  @action
  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    const res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);
    if (!res || res.isError) return;
    this.projectStakeholder = res.payload;
    this.stakeholder = this.projectStakeholder.stakeholder;
    this.setForm();
    this.isLoading = false;
  };

  @action
  setForm = () => {
    this.proStakeholderFormModel = new SingleFormModel();
    this.proStakeholderFormModel.formFields = getProjectStakeholderFormFields(
      this.projectId,
      this.stakeholder,
      this.projectStakeholder
    );
    this.proStakeholderFormModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: "LinkButton",
        componentProps: {
          type: "link",
          className: "ml-auto",
          href: `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/${this.stakeholderId}`
        }
      },
      {
        id: "save",
        label: I18n.t("phrases.save"),
        rendersIn: "Button",
        componentProps: {
          className: "ml-2"
        },
        onAction: this.updateProjectStakeholder
      }
    ];
  };

  @action
  updateProjectStakeholder = async () => {
    let res = await this.proStakeholderFormModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.projectStakeholderProvider.update(
      this.organisationId,
      this.projectId,
      this.stakeholderId,
      res as FP.Entities.IProjectStakeholder
    );
    this.httpProgress.hideOverlay();
    if (!result || result.isError) return;
    this.projectStakeholder = result.payload;
    this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders/${this.stakeholderId}`);
  };
}
