import * as _ from "lodash";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { StakeholderGroupListViewModel } from "../../stakeholderGroup/stakeholderGroupListView/StakeholderGroupListView_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { GetStakeholderAudienceTableConfig } from "../../stakeholders/stakeholderListView/StakeholderTableConfig";
import { StakeholderListViewModel } from "../../stakeholders/stakeholderListView/StakeholderListView_model";
import { Enums } from "../../../../enums";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { IPaginationModel, PaginationModel } from "../../../../components/widgets/pagination/Pagination_model";
import { GetAudienceListFilters } from "./AudienceViewFilter_fields";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import I18n from "../../../../core/localization/I18n";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";
import * as H from "history";

export class AudienceViewModel extends BaseModel {
  appService: AppService;
  history: H.History<unknown>;
  stakeholderListViewModel: StakeholderListViewModel;
  stakeholderAudienceListViewModel: StakeholderListViewModel;
  stakeholderGroupListViewModel: StakeholderGroupListViewModel;
  projectProvider: IProjectsApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  projectId: number;
  individualFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  audienceFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  projStProvider: IProjectStakeholdersApi;
  individualPagination: IPaginationModel;
  audiencePagination: IPaginationModel;
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  organisationSettings: IOrganisationSettingsService;
  @observable showVisualisations: boolean;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  /**
   *
   */
  constructor(appService: AppService, history: H.History, projectId: number, organisationId: number) {
    super();
    this.appService = appService;
    this.history = history;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projStProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.organisationSettings = this.appService.getService<IOrganisationSettingsService>(Services.OrganisationSettingsService);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

    this.projectId = projectId;
    this.organisationId = organisationId;

    this.installAudiencePaginations();
    this.installAudienceFilters();
    this.audienceFilter.setFromQueryString(window.location.search);

    this.stakeholderAudienceListViewModel = new StakeholderListViewModel(this.appService, false, {
      filterModel: this.audienceFilter,
      stakeholderType: Enums.StakeholderType.AUDIENCE,
      paginationModel: this.audiencePagination
    }, projectId);
    this.stakeholderAudienceListViewModel.setTableConfig(
      GetStakeholderAudienceTableConfig(
        this.stakeholderAudienceListViewModel,
        this.stakeholderAudienceListViewModel.tableModel,
        this.audienceFilter
      )
    );

    this.loadProject();
    this.loadStakeholderAndAudienceCounts();
  }

  loadIndPageData = (index: number) => {
    this.individualFilter.setConfig({ page: index });
    this.individualFilter.loadData();
  };

  installAudiencePaginations = () => {
    this.audiencePagination = new PaginationModel();
    this.audiencePagination.setConfig({ onPageClick: this.loadAudPageData });
  };

  loadAudPageData = (index: number) => {
    this.audienceFilter.setConfig({ page: index });
    this.audienceFilter.loadData();
  };

  installAudienceFilters = () => {
    this.audienceFilter = new FilterModel(this.appService, this.audiencePagination, {
      filterCb: async filterOptions =>
        await this.projStProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
      projectId: this.projectId
    });

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const nameFilter: IFilterAttribute = {
      key: "Stakeholder.FirstName|Stakeholder.LastName",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const typeFilter: IFilterAttribute = {
      key: "Stakeholder.StakeholderType",
      value: [Enums.StakeholderType.AUDIENCE.toString()],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const ownerFilter: IFilterAttribute = {
      key: "Owner.FirstName|Owner.LastName",
      label: I18n.t("filters.owner"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const roleFilter: IFilterAttribute = {
      key: "Role.Name",
      label: I18n.t("filters.role"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const busAFilter: IFilterAttribute = {
      key: "BusinessArea.Name",
      label: I18n.t("filters.businessArea"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const impactFilter: IFilterAttribute = {
      key: "impact",
      label: I18n.t("filters.impact"),
      value: [],
      operator: FilterOperator.EQUALS
    };

    const influenceFilter: IFilterAttribute = {
      key: "influence",
      label: I18n.t("filters.influence"),
      value: [],
      operator: FilterOperator.EQUALS
    };

    const impactGenFilter: IFilterAttribute = {
      key: "impactGen",
      label: I18n.t("filters.impactGen"),
      value: [],
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.ImpactLevelMin(parseInt(value));
      }
    };

    const influenceGenFilter: IFilterAttribute = {
      key: "influenceGen",
      label: I18n.t("filters.influenceGen"),
      value: [],
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.InfluenceLevelMin(parseInt(value));
      }
    };

    this.audienceFilter.addSort({ key: "impact", isAsc: false });
    this.audienceFilter.addFilter(nameFilter);
    this.audienceFilter.addFilter(typeFilter);
    this.audienceFilter.addFilter(ownerFilter);
    this.audienceFilter.addFilter(projectFilter);
    this.audienceFilter.addFilter(lifeCycleFilter);
    this.audienceFilter.addFilter(roleFilter);
    this.audienceFilter.addFilter(busAFilter);
    this.audienceFilter.addFilter(impactFilter);
    this.audienceFilter.addFilter(influenceFilter);
    this.audienceFilter.addFilter(impactGenFilter);
    this.audienceFilter.addFilter(influenceGenFilter);

    this.audienceFilter.setConfig({
      formFields: GetAudienceListFilters,
      onDataLoaded: d => this.stakeholderAudienceListViewModel.tableModel.setData(d)
    });
  };

  loadShowVisualisations = async () =>
  await this.organisationSettings.isVisualisationsEnabled(this.organisationId);

  onMount = () => {
    Promise.all([this.loadShowVisualisations()]).then(values => {
        // the project should be set before impacts, timeline requires the
        // project to be present before installing the timelines
        this.showVisualisations = values[0];
    });
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    if (!res || res.isError) return;

    if (res.payload) {
      this.setProject(res.payload);
    }
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );

    if (!res || res.isError) return;

    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  onUnmount = () => {};

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.history.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholder-visualisations`
      );
      return;
    }

    if (newTabIndex === 0) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };
}

export const ProjectAudienceHelper = {
  getAudience: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    return _.filter(stakeholders, e => e.stakeholder.stakeholderType === Enums.StakeholderType.AUDIENCE);
  },
  getGroups: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    let newList = _.filter(stakeholders, e => typeof e.stakeholderGroup !== "undefined" || e.stakeholderGroup == null);
    let res = _.groupBy(newList, e => {
      return e.stakeholderGroup ? _.camelCase(e.stakeholderGroup.name) : "other";
    });
    return res;
  }
};
