import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { useAppService } from "../../../../AppService";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { useCurrentOrganisation } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { MultiForm } from "../../forms/multiForm/MultiForm_view"
import { CreateCustomPropertyModel } from "./CreateCustomProperty_model";


interface CreateCustomPropertyProps {

}

export const CreateCustomProperty: React.FC<CreateCustomPropertyProps> = observer((props) => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const [model] = useState(() => new CreateCustomPropertyModel(appService, organisation.id));
  return <div className="impacts-view pt-6">
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col">
          <LinkButton
            size={Enums.UiSizes.MD}
            type="link-big"
            className="p-0"
            href={`/organisations/${organisation.id}/settings/customProperties`}
          >
            <Icon className="mr-2" symbol="ChevronLeft" />
            {I18n.t("phrases.backToCustomPropertyList")}
          </LinkButton>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {organisation.name} | <strong>{I18n.t("phrases.createCustomProperty")}</strong>
          </h1>
          <p>{I18n.t("phrases.createCustomPropertiesDescription")}</p>
        </div>

      </div>
      <div className="row">
        <div className="col-6">
          <MultiForm
            forms={model.formModels}
            onFormSubmit={(s) => {
              console.log("FORM", s)
            }} />
        </div>
      </div>
    </div>
  </div>
})