import * as React from 'react';
import { Enums } from '../../../../enums';
import I18n from '../../../../core/localization/I18n';
import { Link } from 'react-router-dom';
import { useCurrentOrganisationId } from '../../../../core/auth/organisationContext';
import { Animations } from '../../../../core/util/Animations';

export interface StakeholderDetailsProps {
    item: FP.Entities.IStakeholder;
}

const StakeholderDetails: React.FunctionComponent<StakeholderDetailsProps> = ({ item }) => {
    const organisationId = useCurrentOrganisationId();
    if (item.stakeholderType === Enums.StakeholderType.AUDIENCE) {
        return <AudienceDetails item={item} />
    }

    return <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4 row`}>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.name")}</h4>
            <Link to={`/organisations/${organisationId}/stakeholders/${item.id}`}>
                <p>{`${item.firstName} ${item.lastName}`}</p>
            </Link>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.email")}</h4>
            <p><a className="hover-underline" href={`mailto:${item.email}`}>{item.email}</a></p>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.changeNetwork")}</h4>
            <p>{I18n.t(item.isChangeNetwork ? "phrases.yes" : "phrases.no")}</p>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.businessArea")}</h4>
            <p>{item.businessArea?.name}</p>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.role")}</h4>
            <p>{item.role?.name}</p>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.relationshipOwner")}</h4>
            <Link to={`/organisations/${organisationId}/users/${item.owner?.id}`}>
                <p>{`${item.owner?.firstName} ${item.owner?.lastName}`}</p>
            </Link>
        </div>
    </div>
};


const AudienceDetails: React.FunctionComponent<StakeholderDetailsProps> = ({ item }) => {
    const organisationId = useCurrentOrganisationId();
    return <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4 row`}>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.name")}</h4>
            <Link to={`/organisations/${organisationId}/stakeholders/${item.id}`}>
                <p>{item.firstName}</p>
            </Link>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.audienceCount")}</h4>
            <p>{item.audienceCount}</p>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.businessArea")}</h4>
            <p>{item.businessArea?.name}</p>
        </div>
        <div className="col-lg-6 mb-4">
            <h4 className="mb-2">{I18n.t("table.relationshipOwner")}</h4>
            <Link to={`/organisations/${organisationId}/users/${item.owner.id}`}>
                <p>{`${item.owner?.firstName} ${item.owner?.lastName}`}</p>
            </Link>
        </div>
    </div>
};

export { StakeholderDetails, AudienceDetails };