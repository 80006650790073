import * as React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";

export interface SpinnerProps {
  size?: UiSizes;
}

declare var lottie: any;
declare var spinnerAnimationData: any;

export class Spinner extends React.Component<SpinnerProps> {
  params: any;
  el: HTMLDivElement | any;

  componentDidMount() {
    this.params = {
      container: this.el,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: spinnerAnimationData
    };
    if (lottie && spinnerAnimationData) {
      lottie.loadAnimation(this.params);
    }
  }
  render() {
    let { props } = this;
    let cls = B()
      .bl("spinner")
      .pmod(props.size || UiSizes.SM).bem;
    if (!lottie) {
      return "Loading...";
    }
    return <div className={cls} ref={e => (this.el = e)}></div>;
  }
}
