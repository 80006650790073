import * as React from 'react';
import { SecuritySettingsModel } from './Security_model';
import { SingleForm } from '../../../forms/singleFormModel/SingleForm_view';
import { LinkButton, Button } from '../../../../../components/ui/Button';
import I18n from '../../../../../core/localization/I18n';
import { ErrorMessage } from '../../../../../components/ui/ErrorMessage';
import { observer } from 'mobx-react-lite';
import { Animations } from '../../../../../core/util/Animations';


export interface SecuritySettingsProps {
    model: SecuritySettingsModel;
}


export const SecuritySettings: React.FunctionComponent<SecuritySettingsProps> = observer(({ model }) => {
    return <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
            <div className="col-lg-7">
                <h2>{I18n.t("phrases.security")}</h2>

                <SingleForm model={model.formModel} />

                {
                    model.errorMessage &&
                    <ErrorMessage>
                        {model.errorMessage}
                    </ErrorMessage>
                }
            </div>
        </div>

        <div className="row">
            <div className="col-12">
                <div className="settings-view__actions">
                    <div className="d-flex justify-content-end">
                        <LinkButton
                            href="/"
                            type="outline-primary"
                            className="float-right"
                        >
                            {I18n.t("phrases.cancel")}
                        </LinkButton>
                        <Button
                            onClick={model.updatePassword}
                            type="primary"
                            className="float-right ml-2"
                        >
                            {I18n.t("phrases.save")}
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    </div>
})