import * as React from 'react';
import { IToasterModel, ToasterModel } from './Toaster_model';
import { observable, action } from 'mobx';
import { ToasterView } from './Toaster_view';
import { observer } from 'mobx-react';
import _ from 'lodash';


export interface IToasterService {
    toasts: IToasterModel[];
    newToast: () => IToasterModel;
    removeToast: (toast: IToasterModel) => void;
    showErrorToast: (durations?: number) => IToasterModel;
    showSuccessToast: (duration?: number) => IToasterModel;
}


export class ToasterService implements IToasterService {
    @observable toasts: IToasterModel[] = [];

    @action
    newToast = () => {
        let s = new ToasterModel({
            id: Date.now(),
            onDestroy: this.removeToast
        });
        this.toasts.push(s);
        return s;
    }

    @action
    removeToast = (toast: IToasterModel) => {
        _.remove(this.toasts, (e) => e.config.id === toast.config.id)
    }

    showErrorToast = (duration?: number): IToasterModel => {

        let s = this.newToast()
            .setPanelProps({
                type: "border-top-danger",
            })
        if (duration) {
            s.startTimer(duration);
        }

        return s;
    };


    showSuccessToast = (duration?: number): IToasterModel => {

        let s = this.newToast()
            .setPanelProps({
                type: "border-top-secondary",
            })

        if (duration) {
            s.startTimer(duration);
        }
        return s;
    };
}

interface TosterServiceViewProps {
    toasts: IToasterModel[];
}

export const ToasterServiceView: React.FunctionComponent<TosterServiceViewProps> = observer((props) => {
    let { toasts } = props;
    return <div className="toaster-service">
        {
            toasts.map((e, i) => <ToasterView model={e} key={i} />)
        }
    </div>
})