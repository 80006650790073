import { BaseModel, IModel } from "../../../core/util/BaseModel";
import { observable, action } from "mobx";
import _ from "lodash";
import { ITableModel } from "../../../core/table/ITableModel";

export interface SearchPanelConfig {
  items: any[];
  label?: React.ReactNode;
  link?: React.ReactNode;
  searchAttribute?: string;
  contentClassName?: string;
  tableModel: ITableModel<any>;
}

export interface ISearchPanelModel extends IModel {
  config: SearchPanelConfig;
  searchValue: string;
  filteredItems: any[];
  isSearchModeOn: boolean;
  setConfig: (config: Partial<SearchPanelConfig>) => void;
  hideSearchMode: () => void;
  updateSearchValue: (ev: React.SyntheticEvent) => void;
  filterItems: (searchValue: string) => void;
  showSearchMode: () => void;
}

export class SearchPanelModel extends BaseModel implements ISearchPanelModel {
  @observable isSearchModeOn: boolean = false;
  @observable searchValue: string = "";
  @observable.ref filteredItems: any[] = [];
  @observable.ref config: SearchPanelConfig = {
    label: "title",
    items: null as any,
    searchAttribute: "label",
    tableModel: null as any
  };
  /**
   *
   */
  constructor(initConfig?: SearchPanelConfig) {
    super();
    if (initConfig) {
      this.setConfig(initConfig);
      this.filteredItems = initConfig.items;
    }
  }

  @action
  setConfig = (config: Partial<SearchPanelConfig>) => {
    this.config = { ...this.config, ...config };
    if (config.items) {
      this.filteredItems = config.items;
    }
  };

  @action
  hideSearchMode = () => {
    if (this.isSearchModeOn) {
      this.isSearchModeOn = false;
      this.filteredItems = this.config.items;
      this.config.tableModel.setData(this.filteredItems);
      this.searchValue = "";
    }
  };

  @action
  showSearchMode = () => {
    this.isSearchModeOn = true;
  };

  @action
  updateSearchValue = (ev: React.SyntheticEvent) => {
    this.searchValue = (ev.currentTarget as HTMLInputElement).value;
    this.filterItems(this.searchValue);
  };

  @action
  filterItems = (searchValue: string) => {
    const filterItemCb = e => {
      let kk = e[this.config.searchAttribute as any].toLowerCase();
      return kk.includes(searchValue.toLowerCase());
    };
    this.filteredItems = _.filter(this.config.items, filterItemCb);
    this.config.tableModel.setData(this.filteredItems);
  };

  onMount = () => {};

  onUnmount = () => {};
}
