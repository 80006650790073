import {
  IFilteredApiModel,
  IFilteredOptions,
  IFilteredResponse,
  FilteredApiModel
} from "../filteredApi/FilteredApiModel";
import { AxiosRequestConfig } from "axios";

export interface IExportableApi<T> extends IFilteredApiModel<T> {
  exportDataAsync: (
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    restrictById?: number
  ) => Promise<IFilteredResponse<T>>;

  exportProjectDataAsync: (
    organisationId: number,
    projectId: number,
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => Promise<IFilteredResponse<T>>;
}

export abstract class ExportableApi<T> extends FilteredApiModel<T> implements IExportableApi<T> {
  abstract controller: string;

  exportDataAsync = async (
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    restrictById?: number
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${restrictById}/${this.controller}/export${query} &columns=${columns.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  exportProjectDataAsync = async (
    organisationId: number,
    projectId: number,
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${
      this.controller
    }/export${query} &columns=${columns.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
