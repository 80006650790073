import React from "react";
import { ISettingsViewModel } from "./SettingsView_model";
import { InnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";

export interface SettingsViewProps {
  model: ISettingsViewModel;
}

export const SettingsView: React.FunctionComponent<SettingsViewProps> = observer(({ model }) => {
  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className="settings-view">
      <InnerNavContent model={model.innerNavContentModel} />
    </div>
  );
});
