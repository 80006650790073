import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { AddAudienceModel } from "./AddAudience_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { LinkButton, Button } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import { Panel } from "../../../../components/ui/Panel";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { IF } from "../../../../components/hoc/If";
import { AudienceDetails } from "../../stakeholders/StakeholderDetails";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

interface AddAudienceProps {
    model: AddAudienceModel;
}

const AddAudience: React.FunctionComponent<AddAudienceProps> = observer(({ model }) => {
    const organisationId = useCurrentOrganisationId();
    useEffect(() => {
        model.onMount();
        return model.onUnmount;
    })

    if (model.isLoading) {
        return <PositionedSpinner />
    }

    return <div className={`add-audience pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <LinkButton
                        size={Enums.UiSizes.MD}
                        type="link-big"
                        className="p-0"
                        href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders`}
                    >
                        <Icon className="mr-2" symbol="chevron-left" />
                        {I18n.t("phrases.backToStakeholders")}
                    </LinkButton>
                </div>
            </div>
        </div>
        <Tabs
            key="first"
            className="tabs--primary"
            ulClassName="tabs__menu--primary"
            liClassName="tabs__item--primary"
            onTabClicked={model.resetTabs}
        >
            <Tab title={<h2 className="mb-0">{I18n.t("phrases.addExisting")}</h2>}>
                <div className="container-fluid">
                    <Panel background="bg-light" className="row py-4 mb-4">
                        <div className="col-lg-7">
                            <h1>{I18n.t("phrases.add")} <strong>{I18n.t("entities.audience")} </strong></h1>
                            <p className="mb-0">
                                {I18n.t("phrases.addAudienceDescription")}
                            </p>
                        </div>
                    </Panel>
                    <div className="row">
                        <div className="col-lg-10">
                            <SingleForm model={model.searchFormModel} />
                        </div>
                    </div>
                </div>
            </Tab>
            <Tab title={<h2 className="mb-0">{I18n.t("phrases.createNew")}</h2>}>
                <IF condition={!model.audience}>
                    <div className="container-fluid">
                        <Panel background="bg-light" className="row py-4 mb-4">
                            <div className="col-lg-7">
                                <h1>{I18n.t("phrases.create")} <strong>{I18n.t("entities.audience")} </strong></h1>
                                <p className="mb-0">
                                    {I18n.t("phrases.createAudienceDescription")}
                                </p>
                            </div>
                        </Panel>

                        <div className="row mb-4">
                            <div className="col-lg-10">
                                <SingleForm model={model.createFormModel} />
                            </div>
                        </div>
                    </div>
                </IF>
            </Tab>
        </Tabs>

        <IF condition={!!model.audience}>
            <div className="container-fluid">
                <div className="row mt-5">
                    <div className="col-12">
                        <h2>{I18n.t("phrases.stakeholderProfile")}</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-10">
                        <AudienceDetails item={model.audience} />
                    </div>
                </div>

                <hr />

                <div className="row mt-5 mb-4">
                    <div className="col-12">
                        <h2>{I18n.t("phrases.projectProfile")}</h2>
                        <p>{I18n.t("phrases.audienceProjectProfileDescription")}</p>
                        <p>{I18n.t("phrases.addAudienceGroupToProjectGuidance")}</p>
                    </div>
                </div>

                {model.projectAudienceFormModel &&
                    <div className="row">
                        <div className="col-lg-10">
                            <SingleForm model={model.projectAudienceFormModel} />
                        </div>
                    </div>
                }

                <hr />

                <div className="row my-4">
                    <div className="col-12">
                        <Button
                            className="float-right"
                            onClick={model.addAudienceToProject}
                        >
                            {I18n.t("phrases.addToProject")}
                        </Button>
                    </div>
                </div>
            </div>
        </IF>
    </div>
})

export { AddAudience };