import { observer } from "mobx-react-lite";
import * as React from "react";
import { Button } from "../../../../../components/ui/Button";
import { Icon } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { Pill } from "../../../../../components/ui/Pill";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { FilterPills } from "../../../../../components/widgets/filterPills/FilterPills_view";
import { Heatmap } from "../../../../../components/widgets/heatmap/Heatmap_view";
import { ProjectInsight, ProjectInsightCard } from "../../../../../components/widgets/projects/ProjectInsightCard/ProjectInsightCard_view";
import I18n from "../../../../../core/localization/I18n";
import { UiSizes } from "../../../../../enums";
import { getImpactReportRagStatus } from "../impactsOverTime/ImpactsOverTime_model";
import { ImpactsByProjectsModel } from "./ImpactsByProjects_model";

export interface ImpactsByProjectsProps {
  model: ImpactsByProjectsModel;
}

const ImpactsByProjects: React.FC<ImpactsByProjectsProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <div style={{ height: "100%" }}>
      <PositionedSpinner />
    </div>
  }

  const overallRag = getImpactReportRagStatus(model.totalImpacts === 0 ? 0 : model.overallRAG);
  const pillType: any = model.totalImpacts === 0 ? `outline-green` : `outline-${overallRag.toLowerCase()}`;
  const projectInsights: ProjectInsight[] = [
    {
      key: "totalImpacts",
      label: I18n.t("phrases.totalImpacts"),
      value: model.totalImpacts
    },
    {
      key: "totalProjects",
      label: I18n.t("phrases.totalProjects"),
      value: model.totalProjects
    },
    {
      key: "overallRag",
      label: I18n.t("phrases.overallRag"),
      value: <Pill
        type={pillType}
        size={UiSizes.MD}
      >
        {overallRag}
      </Pill>
    }]
  return (<>
    <Panel background="bg-light" className="pb-2">
      <div className="container-fluid pt-4">
        <div className="row mb-2">
          <div className="col">
            <p className="m-0 mt-2">{I18n.t("phrases.impactReportByProjectDescription", { group: I18n.t(`entities.${model.impactReportGroup}`) })}</p>
          </div>
          <div className="col">
            <Button className={"float-right"} type="outline-primary" onClick={model.filterModel.showFilterFormModal}>
              <Icon symbol="Filter" className="mr-2" />
              {I18n.t("phrases.applyFilters")}
            </Button>
            <Button className={"float-right mr-2"} type="outline-primary" onClick={model.heatmapModel.exportAsCsv}>
              <Icon symbol="download" className="mr-2" />
              {I18n.t("phrases.export")}
            </Button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              {
                projectInsights.map(e => {
                  return <div key={"Insight_" + e.key} className="mb-2 mr-2">
                    <ProjectInsightCard
                      key={e.key}
                      insight={e} />
                  </div>
                })
              }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <div className="container-fluid mt-5">
      <div className="row mb-5">
        <div className="col">{model.isLoading ? <PositionedSpinner /> : <Heatmap model={model.heatmapModel} />}</div>
      </div>
    </div>
  </>
  );
});

export { ImpactsByProjects };
