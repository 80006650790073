import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getStakeholderGroupFormFields } from "../../forms/stakeholderGroup/StakeholderGroup_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { Services } from "../../../../constants";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";

export class CreateStakeholderGroupViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  organisationProvider: IOrganisationsApi;
  stakeholderGroupProvider: IStakeholderGroupsApi;
  cookieService: ICookieService;
  orgId: number;
  httpProgress: IHttpProgressModel;
  formModel: SingleFormModel;
  toasterService: IToasterService;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps<any, any, any>) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.stakeholderGroupProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.orgId = parseInt(this.cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID));
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.setForm();
  }

  setForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getStakeholderGroupFormFields(this.orgId, this.organisationProvider);
    this.formModel.actions = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: async () => {
          let res = await this.createStakeholderGroup();
          if (res) {
            this.toasterService
              .showSuccessToast()
              .setContent(
                <p>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.stakeholderGroup") })}</p>
              )
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
            this.formModel.resetFields();
          }
        },
        rendersIn: "Button",
        componentProps: {
          type: "outline-primary",
          className: "ml-auto"
        }
      },
      {
        id: "create",
        label: I18n.t("phrases.createStakeholderGroup"),
        onAction: async () => {
          let group = await this.createStakeholderGroup();
          if (group) {
            this.routeProps.history.push(`/organisations/${group.organisation.id}/settings/stakeholderGroups`);
          }
        },
        rendersIn: "Button",
        componentProps: {
          type: "primary",
          className: "ml-2"
        }
      }
    ];
  };

  createStakeholderGroup = async (): Promise<FP.Entities.IStakeholderGroup> => {
    const formResult = await this.formModel.submit();
    if (!formResult) return;

    this.httpProgress.showOverlay();
    const res = await this.stakeholderGroupProvider.create(this.orgId, formResult as FP.Entities.IStakeholderGroup);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  onMount = () => { };

  onUnmount = () => { };
}
