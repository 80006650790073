import * as React from "react";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";
import { useCanEditProjectClaim, useCanEditOrganisationClaim, useCanEditProgrammeClaim, useCanOwnOrganisationClaim, useIsOrganisationAdmin } from "../../core/auth/authorise";

const ShowIfOrganisationAdmin: React.FC<any> = props => {
  const organsiationId = useCurrentOrganisationId();
  const isOrganisationAdmin = useIsOrganisationAdmin(organsiationId);
  return isOrganisationAdmin && props.children;
}

const CanEditOrg: React.FunctionComponent<any> = props => {
  const organisationId = useCurrentOrganisationId();
  const canContributeToOrg = useCanEditOrganisationClaim(organisationId);
  return canContributeToOrg ? props.children : null;
};

const CanContributeOrg: React.FunctionComponent<any> = props => {
  const organisationId = useCurrentOrganisationId();
  const canContributeToOrg = useCanEditOrganisationClaim(organisationId);
  return canContributeToOrg && props.children;
};

const CanOwnOrg: React.FunctionComponent<any> = props => {
  const organisationId = useCurrentOrganisationId();
  const canOwnOrg = useCanOwnOrganisationClaim(organisationId);
  return canOwnOrg && props.children;
};

const CanEditProject: React.FunctionComponent<any> = props => {
  const canEdit = useCanEditProjectClaim(props.projectId);
  return canEdit ? props.children : null;
};

const CanEditProgramme: React.FunctionComponent<any> = props => {
  const canEdit = useCanEditProgrammeClaim(props.programmeId)
  return canEdit ? props.children : null;
};

export { CanEditProject, CanEditProgramme, CanEditOrg, CanContributeOrg, CanOwnOrg, ShowIfOrganisationAdmin };
