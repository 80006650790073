import { ICheckboxListModel, ICheckboxListItem } from "./ICheckboxListModel";
import * as React from "react";
import { CheckboxListProps, CheckboxList } from "../../../../components/ui/_forms/CheckboxList";
import { FormFieldModel } from "../../formField/FormField_model";
import { observable, action } from "mobx";
import * as _ from "lodash";
import { FormFieldType } from "../../formField/FormFieldTypes";
import { INIT_FORM_FIELD } from "../../formField/FormField_init";

export const INIT_CHECKBOXLIST: Partial<ICheckboxListModel> = {
  ...INIT_FORM_FIELD,
  type: FormFieldType.CheckboxSlider,
};

export class CheckboxListModel
  extends FormFieldModel<ICheckboxListItem[], CheckboxListProps>
  implements ICheckboxListModel {
  // valus should be the list of all options along with if it is selected is not
  @observable value: ICheckboxListItem[] = [];
  readonly type: FormFieldType = FormFieldType.CheckboxList;
  isRadio: boolean = false;

  constructor(initOpts?: ICheckboxListModel) {
    super(initOpts);
    if (initOpts) {
      this.isRadio = initOpts.isRadio || false;
      this.value = initOpts.value || this.value;
    }
  }

  validate: any | ((...args: any[]) => any);

  @action.bound
  setValue(val: ICheckboxListItem[]): void {
    this.value = val;
  }

  setFieldValue(val: any): void {
    throw new Error("Method not implemented.");
  }

  extractValue() {
    let res = _.filter(this.value, e => e.isChecked);
    return res.map(e => {
      return {
        key: e.key,
        label: e.label
      };
    });
  }

  reset(): void {
    this.value.forEach(e => (e.isChecked = false));
  }

  @action.bound
  valueClicked = (valueItem: ICheckboxListItem) => {
    if (this.isRadio) {
      this.value.forEach(e => (e.isChecked = false));
      valueItem.isChecked = true;
      return;
    }

    valueItem.isChecked = !valueItem.isChecked;

    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  };

  renderComponent = (): React.ReactNode => {
    return <CheckboxList onItemClick={this.valueClicked} options={this.value} />;
  };
}
