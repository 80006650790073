import * as React from 'react';
import { Panel } from '../../../../components/ui/Panel';
import { Enums } from '../../../../enums';
import I18n from '../../../../core/localization/I18n';
import { Pill } from '../../../../components/ui/Pill';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useCurrentOrganisationId } from '../../../../core/auth/organisationContext';
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface ImpactDisplayProps {
    item: FP.Entities.IImpact;
}

const ImpactDisplay: React.FunctionComponent<ImpactDisplayProps> = (props) => {
    const { item } = props;
    const organisationId = useCurrentOrganisationId();
    return <>
        <div className="row mb-4">
            <div className="col">
                <h4 className="mb-2">{I18n.t("table.description")}</h4>
                {item.description ?
                    <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
                    :
                    <div>{I18n.t("phrases.noDescription")}</div>
                }
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.impactType")}</h4>
                <p>{item.nImpactType.name}</p>
            </div>
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.impactLevel")}</h4>
                <Pill type={`indicator-accent-${Enums.Translator.MetricToEnum(item.impactLevel)}` as any}>
                    {Enums.Translator.ImpactLevel(item.impactLevel)}
                </Pill>
            </div>
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.mitigationConfidence")}</h4>
                <Pill type={`indicator-accent-${Enums.Translator.MetricToEnum(item.mitigationConfidence)}` as any}>
                    {Enums.Translator.MitigationConfidence(item.mitigationConfidence)}
                </Pill>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.startDate")}</h4>
                <p>{moment(item.startDate).format("L")}</p>
            </div>
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.endDate")}</h4>
                <p>{moment(item.actualEndDate).format("L")}</p>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.numOfIndividualsImpacted")}</h4>
                <p>{item.totalPeopleImpacted}</p>
            </div>
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.locations")}</h4>
                <p>
                    {item.locations?.length ?
                        item.locations.map((e) => e.name).join(", ")
                        :
                        I18n.t("phrases.noLocations")
                    }
                </p>
            </div>
        </div>
        <div className="row pb-4">
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.businessAreas")}</h4>
                <p>
                    {item.businessAreas?.length ?
                        item.businessAreas.map((e) => e.name).join(", ")
                        :
                        I18n.t("phrases.noBusinessAreas")
                    }
                </p>
            </div>
        </div>
        {item.impactGroup &&
            <div className="row pb-4">
                <div className="col">
                    <h4 className="mb-2">{I18n.t("table.impactGroup")}</h4>
                    <Link to={`/organisations/${organisationId}/projects/${item.projectId}/impactGroups/${item.impactGroup.id}`}>
                        <Panel
                            type="border-left-primary"
                            className="p-4"
                            hasShadow
                        >
                            {item.impactGroup.name}
                        </Panel>
                    </Link>
                </div>
            </div>
        }
    </>
}

export { ImpactDisplay };