import * as React from "react";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { ButtonIconProps } from "../../../../components/ui/Button";
import { IModalService } from "../../../../core/modal/IModalService";
import { GlobalSearchView } from "../../../../components/widgets/globalSearch/GlobalSearch_view";
import { INavigationModel } from "../../../../components/widgets/navigation/Navigation_model";
import I18n from "../../../../core/localization/I18n";
import { CreateNav } from "../create/CreateNav_view";
import { Animations } from "../../../../core/util/Animations";

export function GetChangeNavActions(
  model: INavigationModel,
  modalService: IModalService,
  setSection: (string) => void,
  section: string,
  pathName
): IUiAction<ButtonIconProps>[] {
  return [
    {
      id: "dashboard",
      label: I18n.t("phrases.dashboard"),
      rendersIn: "LinkButtonIcon",
      onAction: () => {
        setSection("dashboard");
        // model.secondaryContent = <DashboardNav />;
      },
      componentProps: {
        className: `mb-1 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${0} ${pathName.includes(`${process.env.PUBLIC_URL}dashboard`) && section !== "create"
          ? "navigation__action--active"
          : ""
          }`,
        type: "link",
        symbol: "home",
        href: "/dashboard"
      }
    },
    {
      id: "content",
      label: I18n.t("phrases.projectsAndProgrammes"),
      onAction: () => {
        setSection("content");
      },
      rendersIn: "LinkButtonIcon",
      componentProps: {
        className: `mb-3 navigation-view__action navigation-view__action--content ${Animations.ZOOM_IN} speed-3 delay-${1} ${pathName.includes(`${process.env.PUBLIC_URL}organisations/`) && section !== "create"
          ? "navigation__action--active"
          : ""
          }`,
        type: "link",
        symbol: "layout",
        href: "/takemeto/current-organisation"
      }
    },
    {
      id: "create",
      label: I18n.t("phrases.create"),
      onAction: () => {
        if (section === "create") {
          return;
        }
        setSection("create");
        // we are setting this here so that when the back button is press within the
        // createNav we know the url the user came from
        // createNavModel.previousUrl = window.location.hash;
        model.secondaryContent = <CreateNav onBack={() => setSection("")} />;
        // navModel.getCurrentContentKey() === "organisations" && orgSwitch.reset();
        // navModel.setCurrentContentKey("create");
      },
      rendersIn: "ButtonIcon",
      componentProps: {
        className: `mb-1 mt-2 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${2} ${pathName.includes(`${process.env.PUBLIC_URL}create/`) || section === "create"
          ? "navigation__action--active"
          : ""
          }`,
        type: "link",
        symbol: "plus"
      }
    },
    {
      id: "search",
      label: I18n.t("phrases.search"),
      onAction: () => {
        modalService.show({
          title: null,
          showClose: true,
          content: <GlobalSearchView model={model.searchModel} />,
          componentProps: {
            wrapHeight: "full",
            wrapWidth: "medium",
            position: "left"
          },
          animationOptions: {
            animateIn: Animations.SLIDE_IN_LEFT,
            animateOut: Animations.SLIDE_OUT_LEFT,
            speed: 5
          },
          actions: []
        });
      },
      rendersIn: "ButtonIcon",
      componentProps: {
        className: `mb-1 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${3}`,
        type: "link",
        symbol: "search"
      }
    }
  ];
}
