import React from "react";

export type IFeatureToggleContext = {
  toggleFeature(name: string, enable?: boolean): void;
  features: { [idx: string]: FeatureDefinition };
  registerFeature(name: string, displayName?: string): void;
  isFeatureEnabled(name: string): boolean;
};

export type FeatureDefinition = {
  name: string;
  displayName: string;
  isEnabled: boolean;
};

export const FeatureToggleContext = React.createContext<IFeatureToggleContext>(
  null as any
);
