import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { IHttp } from "../../IHttp";
import { IImpactTypeApi } from "./IImpactTypes.api";

export class ImpactTypesApi extends FilteredOrganisationApiModel<FP.Entities.IImpactType> implements IImpactTypeApi {
  controller: string = "impact-types";

  constructor(http: IHttp) {
    super(http, "impact-types");
  }
}
