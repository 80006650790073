import * as React from "react";
import { ITableConfig, ITableModel } from "../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { RagStatus } from "../../../../components/ui/RagStatus";
import moment from "moment";
import { ActionListViewModel } from "./ActionListView_model";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../core/table/TableHeader_view";

export const GetActionTableConfig = (
  parentModel: ActionListViewModel,
  tableModel: ITableModel<FP.Entities.IAction>
): ITableConfig<any> => {
  let actions = [];

  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: "HTMLAnchor",
    componentProps: {
      type: "link"
    },
    hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/actions/${context.rowObject.id}`
  });
  if (parentModel.permissionService.canEditProject(parentModel.projectId)) {
    actions.push(
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        rendersIn: "HTMLAnchor",
        componentProps: {
          type: "link"
        },
        hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/actions/${context.rowObject.id}/edit`
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: async (ev, row: ITableRowModel) => {
          await parentModel.showActionConfirmDeleteModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    );
  }
  return {
    actions,
    colHeaders: [
      {
        key: "ragStatus",
        content: <h4 className="mb-0">{I18n.t("table.ragStatus")}</h4>,
        selector: (obj: FP.Entities.IAction) => <RagStatus state={obj.ragStatus} />,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "refNumber",
        content: <h4 className="mb-0">{I18n.t("table.refNo")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.refNumber,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "owner",
        content: <h4 className="mb-0">{I18n.t("table.owner")}</h4>,
        selector: (obj: FP.Entities.IAction) => `${obj.owner.firstName} ${obj.owner.lastName}`
      },
      {
        key: "startDate",
        content: <h4 className="mb-0">{I18n.t("table.startDate")}</h4>,
        selector: (obj: FP.Entities.IAction) => moment(obj.startDate).format("L"),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "actualEndDate",
        content: <h4 className="mb-0">{I18n.t("table.endDate")}</h4>,
        selector: (obj: FP.Entities.IAction) => {
          let cls =
            moment(new Date()) > moment(obj.actualEndDate) && obj.progressStatus !== Enums.ProgressStatus.COMPLETED
              ? "text-danger"
              : "";
          cls =
            moment(new Date()).format("L") === moment(obj.actualEndDate).format("L") &&
              obj.progressStatus !== Enums.ProgressStatus.COMPLETED
              ? "text-warning"
              : cls;
          return <span className={`${cls}`}>{moment(obj.actualEndDate).format("L")}</span>;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "progressStatus",
        content: <h4 className="mb-0">{I18n.t("table.progressStatus")}</h4>,
        selector: (obj: FP.Entities.IAction) => Enums.Translator.ProgressStatus(obj.progressStatus),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "ActionType.Name",
        content: <h4 className="mb-0">{I18n.t("table.actionType")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj?.actionType?.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    onRowClick: parentModel.showActionModal,
    tableProps: { id: "ActionTable" },
    isDivTable: true
  };
};
