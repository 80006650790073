
import { IIndustriesApi } from './IIndustries.api';
import { IHttp } from '../../IHttp';
import { FilteredApiModel } from '../../filteredApi/FilteredApiModel';

export class IndustriesApi extends FilteredApiModel<FP.Entities.IIndustry> implements IIndustriesApi {
    controller: string = "Industries";

    constructor(http: IHttp) {
        super(http, "Industries");
    }
}