import * as React from "react";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { ITableConfig, ITableModel } from "../../../../core/table/ITableModel";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { Pill } from "../../../../components/ui/Pill";
import { ImpactViewModel } from "./ImpactsView_model";
import { ITableHeaderModel } from "../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../core/table/TableHeader_view";
import moment from "moment";
import { ImpactActionsTimeline } from "../../../../components/widgets/impactActionsTimeline/ImpactActionsTimeline_view";
import { ImpactActionsTimelineModel } from "../../../../components/widgets/impactActionsTimeline/ImpactActionsTimeline_model";
import { GetImpactTableActions } from "./ImpactTableActions";
import { ImpactHistory } from "../../../../components/widgets/impactHistory/ImpactHistory_View";
import { ImpactHistoryModel } from "../../../../components/widgets/impactHistory/ImpactHistory_Model";

export const GetImpactTableConfig = (
  parentModel: ImpactViewModel,
  tableModel: ITableModel<any>,
  projectId: number
) => {
  let tableConfig: ITableConfig<FP.Entities.IImpact> = {
    actionsFn: GetImpactTableActions(parentModel),
    colHeaders: [
      {
        key: "refNumber",
        content: <h4 className="mb-0">{I18n.t("table.refNo")}</h4>,
        selector: obj => {
          return <p className="mb-0 strong">{obj.refNumber}</p>;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "impactType",
        content: <h4 className="mb-0">{I18n.t("table.type")}</h4>,
        selector: (obj: FP.Entities.IImpact) => {
          return obj.nImpactType.name
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel, "impactTypeName");
        },
        showSortIcons: true
      },
      {
        key: "startDate",
        content: <h4 className="mb-0">{I18n.t("table.startDate")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true,
        cellClassName: "d-none d-xxl-table-cell",
        cellContentFunction: (impact: FP.Entities.IImpact) => {
          if (isDraftImpact(impact.impactCompletionState)) return "N/A";
          return moment(impact.startDate).format("L");
        }
      },
      {
        key: "actualEndDate",
        content: <h4 className="mb-0">{I18n.t("table.endDate")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true,
        cellClassName: "d-none d-xxl-table-cell",
        cellContentFunction: (impact: FP.Entities.IImpact) => {
          if (isDraftImpact(impact.impactCompletionState)) return "N/A";
          return moment(impact.actualEndDate).format("L");
        }
      },
      {
        key: "totalPeopleImpacted",
        content: <h4 className="mb-0">{I18n.t("table.numOfIndividualsImpacted")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        selector: (obj: FP.Entities.IImpact) => isDraftImpact(obj.impactCompletionState) ?
          "N/A" :
          obj.totalPeopleImpacted,
        showSortIcons: true
      },
      {
        key: "impactLevel",
        content: <h4 className="mb-0">{I18n.t("table.level")}</h4>,
        selector: obj => {
          var greyPill = obj.impactLevel === -1 ? "#999999":"";
          return (
            <Pill
              style={{ width: "40px",backgroundColor:greyPill, borderColor: greyPill}}
              type={`indicator-accent-${Enums.Translator.MetricToEnum(obj.impactLevel)}` as any}
            >
              {Enums.Translator.ImpactLevelShort(obj.impactLevel)}
            </Pill>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "progressStatus",
        content: <h4 className="mb-0">{I18n.t("table.status")}</h4>,
        selector: (obj: FP.Entities.IImpact) => isDraftImpact(obj.impactCompletionState) ?
          <p className="m-0 text-danger">
            {I18n.t("phrases.draft")}
          </p>
          :
          Enums.Translator.ImpactProgressStatus(obj.progressStatus),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "createdById",
        content: <h4 className="mb-0">{I18n.t("table.created")}</h4>,
        selector: (obj: FP.Entities.IImpact) => {
          return (
            <ImpactHistory model={new ImpactHistoryModel(
              parentModel.appService,
              obj.createdByProfileImageUrl,
              obj.id,
              parentModel.organisationId,
              obj.projectId,
              isDraftImpact(obj.impactCompletionState))}></ImpactHistory>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    initData: null,
    renderExpandContent: (row: ITableRowModel) => {
      if (row.rowObject.impactCompletionState === Enums.ImpactCompletionState.Incomplete) return;
      return (
        <ImpactActionsTimeline
          model={
            new ImpactActionsTimelineModel(
              parentModel.appService,
              row.rowObject.id,
              projectId,
            )
          }
        />
      );
    },
    onRowClick: parentModel.showImpactModal,
    tableProps: {}
  };

  let isDraftImpact = (state) => {
    return state === Enums.ImpactCompletionState.Incomplete;
  }

  return tableConfig;
};
