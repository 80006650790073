import { IHttp } from "../../IHttp";
import { FilteredApiModel } from "../../filteredApi/FilteredApiModel";
import { IAdminApi } from "./IAdmin.api";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class AdminApi extends FilteredApiModel<any> implements IAdminApi {
  controller: string = "admin";

  constructor(http: IHttp) {
    super(http, "admin");
  }

  getOrgById = (
    id: number,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IOrganisation>) => void,
    config?: AxiosRequestConfig
  ) => {
    this.http
      .get(this.url + "organisation/get/" + id, config)
      .then((e: AxiosResponse<IFlightPathApiResponse<FP.Entities.IOrganisation>>) => {
        if (e.status === 200 && cb) {
          cb(e.data);
        }
      });
  };

  getOrgByIdAsync = async (id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + "organisation/get/" + id, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IOrganisation>;
    }
    console.error("API error - Status Code: " + res.status + "\t Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  createOrg = (
    data: FP.Entities.IOrganisation,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IOrganisation>[]) => void,
    config?: AxiosRequestConfig
  ): void => {
    this.http.post(this.url + "organisation/create", data, config).then(e => {
      if (e.status === 200 && cb) {
        cb(e.data);
      }
    });
  };

  createOrgAsync = async (data: FP.Entities.IOrganisation, config?: AxiosRequestConfig) => {
    return await this.http.post(this.url + "organisation/create", data, config);
  };
}
