import React, { useState } from "react";
import { ImpactGroupExtendedViewModel } from "./ImpactGroupExtendedView_model";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { ImpactStakeholderListView } from "./ImpactStakeholderListView/ImpactStakeholderListView_view";
import { ImpactStakeholderListViewModel } from "./ImpactStakeholderListView/ImpactStakeholderListView_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ImpactGroupDisplay } from "../ImpactGroupDisplay";
import { Enums } from "../../../../enums";
import { ImpactListView } from "./ImpactListView/ImpactListView_view";
import { ImpactListViewModel } from "./ImpactListView/ImpactListView_model";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { useAppService } from "../../../../AppService";
import { useParams } from "react-router-dom";
import { Async } from "react-async";
import { useFlightPathUser } from "../../../../setup";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ImpactGroupExtendedViewProps {
  model?: ImpactGroupExtendedViewModel;
}

const ImpactGroupExtendedView: React.FunctionComponent<ImpactGroupExtendedViewProps> = observer(({ model: m }) => {
  const authUser = useFlightPathUser();
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const { projectId, impactGroupId } = useParams<{ projectId: string; impactGroupId: string }>();
  const [model] = useState(
    () =>
      m ||
      new ImpactGroupExtendedViewModel({ appService, projectId: +projectId, authUser, impactGroupId: +impactGroupId, organisationId })
  );
  const { impactGroup } = model;

  return (
    <Async promiseFn={model.loadImpactGroup}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="data-extended-view pt-6">
            <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
              <div className="row">
                <div className="col">
                  <LinkButton
                    size={Enums.UiSizes.MD}
                    type="link-big"
                    className="p-0"
                    href={`/organisations/${organisationId}/projects/${model.projectId}/impactGroups`}
                  >
                    <Icon className="mr-2" symbol="ChevronLeft" />
                    {I18n.t("phrases.backToImpactGroups")}
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="data-extended-view__block data-extended-view__block--wrapper">
              <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="d-flex">
                      <h2 className="mb-0">
                        {impactGroup.refNumber} - {impactGroup.name} | <strong>{I18n.t("phrases.details")}</strong>
                      </h2>

                      <CanEditProject projectId={model.projectId}>
                        <LinkButton
                          className="ml-auto"
                          type={"outline-primary"}
                          href={`/organisations/${organisationId}/projects/${model.projectId}/impactGroups/${model.impactGroupId}/edit`}
                        >
                          <Icon symbol="Pencil" size={Enums.UiSizes.SM} className="mr-2" />
                          {I18n.t("phrases.edit")}
                        </LinkButton>
                      </CanEditProject>
                    </div>
                  </div>
                </div>
                <ImpactGroupDisplay item={impactGroup} />
              </div>
              <Panel
                background="bg-light"
                className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-4`}
              >
                <TabGroup
                  className="data-extended-view__tabs"
                  tabs={[
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.impacts")}</h3>,
                      children: (
                        <div className={`stakeholder-action-list pt-5 ${Animations.FADE_IN} speed-4`}>
                          <ImpactListView model={new ImpactListViewModel(model.appService, model)} />
                        </div>
                      )
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.stakeholders")}</h3>,
                      children: (
                        <ImpactStakeholderListView
                          model={new ImpactStakeholderListViewModel(model.appService, +projectId, model.impactGroup)}
                        />
                      )
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                      children: <CommentListView model={model.commentViewModel} />
                    },
                    // {
                    //   title: <h3 className="mb-0 text-center">{I18n.t("phrases.impactGroupHistory")}</h3>,
                    //   children: <RevisionHistoryView model={model.revisionHistoryModel} />
                    // }
                  ]}
                ></TabGroup>
              </Panel>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});

export { ImpactGroupExtendedView };
