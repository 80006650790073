import { ILocationsApi } from "./ILocations.api";
import { IHttp } from "../../IHttp";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";

export class LocationsApi extends FilteredOrganisationApiModel<FP.Entities.ILocation> implements ILocationsApi {
  controller: string = "locations";

  constructor(http: IHttp) {
    super(http, "locations");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }
}
