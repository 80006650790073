import React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Icon } from "../../../../components/ui/Icon";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { Link } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import moment from "moment";
import { Enums } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface ActionCardProps {
  item: FP.Entities.IAction;
  actions?: IUiAction<FP.Entities.IAction>[];
  className?: string;
}

export const ActionCard: React.FunctionComponent<ActionCardProps> = props => {
  const organisationId = useCurrentOrganisationId();
  let { item, actions } = props;
  return (
    <Panel className={"action-card p-4 " + (props.className || "")} background="bg-white" hasBorderRadius={true}>
      <div className="row mb-4">
        <div className="col">
          <div>
            <h4 className="mb-1 strong d-inline-block">
              <Link to={`/organisations/${organisationId}/projects/${item.projectId}/actions/${item.id}`}>
                {item.refNumber} - {item.name}
              </Link>
            </h4>
            {actions && (
              <Tooltip
                position="left"
                className="float-right"
                shownElement={<Icon symbol="DotsHorizontal" />}
                triggeredOn="click"
              >
                <Panel background="bg-white" hasShadow={true}>
                  {actions.map(e => {
                    return <UiActionComponent key={e.id} action={e} contextModel={item} />;
                  })}
                </Panel>
              </Tooltip>
            )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
        </div>
      </div>

      <div className="row">
        <div className="col-1">
          <div className="mb-1">
            <small>{I18n.t("phrases.rag")}</small>
          </div>
          <div>
            <RagStatus state={item.ragStatus} className="mt-1" />
          </div>
        </div>
        <div className="col-4">
          <div className="mb-2">
            <small>{I18n.t("phrases.owner")}</small>
          </div>
          <Link to={`/organisations/${organisationId}/users/${item.owner.id}`}>
            <p>
              {item.owner.firstName} {item.owner.lastName}
            </p>
          </Link>
        </div>
        <div className="col-2">
          <div className="mb-2">
            <small>{I18n.t("phrases.startDate")}</small>
          </div>
          <p>{moment(item.startDate).format("L")}</p>
        </div>
        <div className="col-2">
          <div className="mb-2">
            <small>{I18n.t("phrases.endDate")}</small>
          </div>
          <p>{moment(item.actualEndDate).format("L")}</p>
        </div>
        <div className="col-3">
          <div className="mb-2">
            <small>{I18n.t("phrases.status")}</small>
          </div>
          <p>{Enums.Translator.ProgressStatus(item.progressStatus)}</p>
        </div>
      </div>
    </Panel>
  );
};
