import _ from "lodash";

export const FormatAsDataTestId = (...texts: string[]) => {
  let joinedTexts: string = texts.join("_");
  return _.replace(joinedTexts, / /g, "_");
};

export const FormatAsDataTestValue = (...texts: string[]) => {
  let joinedTexts: string = texts.join("_");
  return _.replace(joinedTexts, / /g, "_");
};
