import * as React from 'react';
import moment from 'moment';
import { Enums } from '../../../../enums';
import { Timeline } from '../../../../components/widgets/TimelineWidget';
import I18n from '../../../../core/localization/I18n';
import { Link } from 'react-router-dom';
import { ActionCompactViewModel } from '../actionCompactView';
import { useCurrentOrganisationId } from '../../../../core/auth/organisationContext';
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface ActionDisplayProps {
    item: FP.Entities.IAction;
    parentModel?: ActionCompactViewModel;
}


export const ActionDisplay: React.FunctionComponent<ActionDisplayProps> = (props) => {
    const { item, parentModel } = props;
    const organisationId = useCurrentOrganisationId();
    return <>
        <div className="row mb-4">
            <div className="col">
                <h4 className="mb-2">{I18n.t("forms.description")}</h4>
                <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <h4 className="mb-2">{I18n.t("forms.actionOwner")}</h4>
                <p>
                    <Link
                        to={`/organisations/${organisationId}/users/${item.owner.id}`}
                        onClick={parentModel?.modalService.hide}>
                        {item.owner.firstName + " " + item.owner.lastName}
                    </Link>
                </p>
            </div>
            <div className="col">
                <h4 className="mb-2">{I18n.t("phrases.assignedTo")}</h4>
                <p>
                    <Link
                        to={`/organisations/${organisationId}/users/${item.assignee.id}`}
                        onClick={parentModel?.modalService.hide}>
                        {item.assignee.firstName} {item.assignee.lastName}
                    </Link>
                </p>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <h4 className="mb-2">{I18n.t("forms.startDate")}</h4>
                <p>{moment(item.startDate).format("L")}</p>
            </div>
            <div className="col">
                <h4 className="mb-2">{I18n.t("forms.endDate")}</h4>
                <p>{moment(item.actualEndDate).format("L")}</p>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col">
                <h4 className="mb-2">{I18n.t("phrases.progressStatus")}</h4>
                <p data-testid="progressStatus">{Enums.Translator.ProgressStatus(item.progressStatus)}</p>
            </div>
            {item.actionType &&
                <div className="col">
                    <h4 className="mb-2">{I18n.t("forms.actionType")}</h4>
                    <p>{item.actionType.name}</p>
                </div>}
        </div>


        <div className="row mb-4">
            <div className="col">
                <h4 className="mb-2">{I18n.t("phrases.timeline")}</h4>
                <Timeline
                    startDate={new Date(item.startDate)}
                    initialEndDate={new Date(item.initialEndDate)}
                    actualEndDate={item.actualEndDate && new Date(item.actualEndDate) > new Date(1970, 1, 1) ? new Date(item.actualEndDate) : null}
                />
            </div>
        </div>
    </>
}