import * as React from "react";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import { ButtonIconProps } from "../../ui/Button";
import { IModalService } from "../../../core/modal/IModalService";
import { observable, action, reaction, computed } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../constants";
import { GlobalSearchModel } from "../globalSearch/GlobalSearch_model";
import { IDisposableModel, DisposableModel } from "../../../core/util/DisposableModel";
import { IOrganisationContextModel } from "../../../services/local/organisationContext/IOrganisationContextModel";
import { GetMainSelectionFromUrl } from "../../../pages/change/navigation/config";
import { appHistory } from "../../../setup";

export interface INavigationConfig {
  actions?: IUiAction<ButtonIconProps>[];
  secondaryContentsObject?: Dictionary<React.ReactNode>;
}

export interface INavigationModel extends IDisposableModel {
  actions: IUiAction<ButtonIconProps>[];
  onUrlChange: (url: string) => void;
  readonly canGoBack: boolean;
  isOrganisationSwitchActive: boolean;
  isOrganisationListVisible: boolean;
  modalService: IModalService;
  secondaryContent: any;
  setConfig: (config: Partial<INavigationConfig>) => void;
  setSecondaryContent: (content: any) => void;
  showPreviousContent: () => void;
  isLoading: boolean;
  searchModel: GlobalSearchModel;
  setCurrentContentKey: (val: string) => void;
  getCurrentContentKey: () => string;
  onBack(): void;
  mainBottomContent: React.ReactNode;
  lastSection: string;
  toggleOrganisationSwitch(): void;
  toggleOrganisationList(): void;
  setOrganisationSwitchActive(val: boolean): void;
  setOrganisationListVisible(val: boolean): void;
}

export class NavigationModel extends DisposableModel implements INavigationModel {
  modalService: IModalService;
  onUrlChange: (url: string) => void = () => {};
  searchModel: GlobalSearchModel;
  appService: AppService;
  organisationContext?: IOrganisationContextModel;
  @observable isOrganisationListVisible: boolean = false;
  @observable isOrganisationSwitchActive: boolean = false;
  @observable.ref stack: React.ReactNode[] = [];

  @computed get secondaryContent() {
    return this.stack.length ? this.stack[this.stack.length - 1] : null;
  }

  @computed get canGoBack() {
    return this.stack.length > 1;
  }

  set secondaryContent(value: React.ReactNode) {
    this.pushSecondaryContent(value);
  }

  @observable.ref mainBottomContent: React.ReactNode;
  @observable lastSection: string = "";
  @observable.ref user: FP.Entities.IUser | null = null;
  @observable currentContentKey: string = "";
  @observable isLoading: boolean = true;
  @observable.ref secondaryContentsObject: Dictionary<React.ReactNode> = {};

  @observable.ref actions: IUiAction<ButtonIconProps>[] = [];

  constructor(appService: AppService) {
    super();
    this.appService = appService;
    this.modalService = appService.getService<IModalService>(Services.AsideModalService);

    appHistory.listen(location => {
      const section = GetMainSelectionFromUrl(location.pathname.split("/")[1]);
      this.setCurrentContentKey(section);
    });

    this.searchModel = new GlobalSearchModel(appService);

    this.installReactions();
  }

  @action.bound
  toggleOrganisationSwitch(): void {
    this.isOrganisationSwitchActive = !this.isOrganisationSwitchActive;
  }

  @action.bound
  toggleOrganisationList(): void {
    this.isOrganisationListVisible = !this.isOrganisationListVisible;
  }

  @action.bound
  setOrganisationSwitchActive(val: boolean): void {
    this.isOrganisationSwitchActive = val;
  }

  @action.bound
  setOrganisationListVisible(val: boolean): void {
    this.isOrganisationListVisible = val;
  }

  @action.bound
  pushSecondaryContent(content: React.ReactNode) {
    this.stack = [...this.stack, content];
  }

  @action.bound
  onBack() {
    if (this.stack.length > 1) {
      this.stack.pop();
      this.stack = [...this.stack];
    }
  }

  installReactions = () => {
    var d = reaction(
      () => {
        return this.currentContentKey;
      },
      (e, reaction) => {
        this.updateSecondaryContent(e);
      }
    );
    this.addDisposer(d);
  };

  @action
  showPreviousContent = () => {
    this.onBack();
  };

  @action
  setCurrentContentKey = (val: string) => {
    // this.lastSection = this.currentContentKey;
    // this.currentContentKey = val;
  };

  getCurrentContentKey = () => {
    return this.currentContentKey;
  };

  destroyModel = () => {
    this.dispose();
  };

  setConfig = (config: Partial<INavigationConfig>) => {
    this.actions = config.actions || this.actions;
    this.secondaryContentsObject = config.secondaryContentsObject || this.secondaryContentsObject;
  };

  @action
  updateSecondaryContent = (content: string) => {
    this.secondaryContentsObject[content] && this.setSecondaryContent(this.secondaryContentsObject[content]);
  };

  @action
  setSecondaryContent = (content: any): void => {
    this.pushSecondaryContent(content);
  };
}
