import { IPaginatedContentModel, IPaginatedContentConfig, IPaginatableModel } from "./IPaginatedContentModel";
import { observable, reaction } from "mobx";
import { DisposableModel } from "../util/DisposableModel";

/**
 * @category Pagination
 */
export abstract class PaginatedContentModel<T> extends DisposableModel implements IPaginatedContentModel<T> {
  @observable totalPages: number;
  @observable currentPage: number;
  @observable config: IPaginatedContentConfig<T> = {
    items: [],
    paginationGroupProps: {},
    itemsPerPage: -1,
    onPreviousClick: null,
    onNextClick: null,
    onPageChange: null
  };
  targetModel: IPaginatableModel;

  constructor(initOpts?: IPaginatedContentConfig<T>) {
    super();
    if (initOpts) {
      this.setConfig(initOpts);
    }
  }

  setConfig = (initOpts: IPaginatedContentConfig<T>) => {
    this.config = {
      items: initOpts.items || this.config.items,
      paginationGroupProps: initOpts.paginationGroupProps || this.config.paginationGroupProps,
      itemsPerPage: initOpts.itemsPerPage || this.config.itemsPerPage,
      onNextClick: initOpts.onNextClick || this.config.onNextClick,
      onPreviousClick: initOpts.onPreviousClick || this.config.onPreviousClick,
      onPageChange: initOpts.onPageChange || this.config.onPageChange,
      isHttpLoader: initOpts.isHttpLoader || this.config.isHttpLoader
    };

    this.totalPages = this.config.items.length / this.config.itemsPerPage;
    this.currentPage = this.currentPage > 0 ? this.currentPage : 1;
  };

  initReactions = () => {
    // reaction for current page change
    let r = reaction(
      () => {
        return this.currentPage;
      },
      (currentPage, reaction) => {
        if (!this.config.isHttpLoader) {
          let data = this.getPageItems(currentPage);
          this.setCurrentPageData(currentPage, data);
          return;
        }

        this.config.onPageChange && this.config.onPageChange(currentPage);
      }
    );

    this.addDisposer(r);
  };

  destroyModel = () => {
    this.dispose();
  };

  setTotalPages = (i: number) => {
    this.totalPages = i;
  };

  setCurrentPageData = (currentPage: number, data: T[]) => {
    this.setTargetModelData(data);
  };

  setCurrentPage(number: number): void {
    this.currentPage = number;
  }

  setTargetModel = (targetModel: IPaginatableModel) => {
    this.targetModel = targetModel;
    this.setVisibleItems();
  };

  setTargetModelData = (data: T[]) => {
    this.targetModel && this.targetModel.setData(data);
  };

  setVisibleItems = () => {
    let data = this.getPageItems(this.currentPage);
    this.setCurrentPageData(this.currentPage, data);
  };

  getPageItems = (pageIdx: number): T[] => {
    pageIdx -= 1;
    let startIdx = pageIdx * this.config.itemsPerPage;
    let endIdx = pageIdx * this.config.itemsPerPage + this.config.itemsPerPage;

    return this.config.items.slice(startIdx, endIdx);
  };

  next = (): void => {
    this.config.onNextClick();
  };

  prev = (): void => {
    this.config.onPreviousClick();
  };

  abstract onPageChange(currentPage: number): void;
}
