import * as React from 'react';

const SvgAlignLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="align-left_svg__feather align-left_svg__feather-align-left"
        {...props}
    >
        <path d="M17 10H3M21 6H3M21 14H3M17 18H3" />
    </svg>
);

export default SvgAlignLeft;
