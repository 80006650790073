import * as React from "react";
import { IDonutInfoModel } from "./DonutInfo_model";
import { Panel } from "../../ui/Panel";
import { observer } from "mobx-react-lite";
import { B } from "../../../core/util/BootstrapHelper";
import { DonutFiller } from "../../ui/DonutFiller";

export interface DonutInfoProps {
  model: IDonutInfoModel;
}

const DonutInfo: React.FunctionComponent<DonutInfoProps> = observer(({ model, children }: any) => {
  const cls = B().bl("donut-info");

  return (
    <Panel {...model.panelProps} className={`${cls.bem} ${model.panelProps.className || ""}`}>
      <div className={cls.el("block").bem}>
        <div className={cls.el("donut-wrapper").bem}>
          <DonutFiller
            {...model.donutFillerProps}
            className={`${model.donutFillerProps.className || ""} ${cls.el("donut").bem}`}
          />
          <span className={`display-s ${cls.el("label").bem}`}>{model.value}</span>
        </div>
      </div>
      <div className={cls.el("block").pmod("content").bem}>{model.content}</div>
    </Panel>
  );
});

export { DonutInfo };
