import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IImageCropperModel } from "../../../../core/forms/controls/imageCropper/IImageCropperModel";
import { INIT_IMAGE_CROPPER_FIELD } from "../../../../core/forms/controls/imageCropper/ImageCropper_model";
import { IModalService } from "../../../../core/modal/IModalService";
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { Validations } from "../../../../core/forms/helpers/Validations";
import I18n from "../../../../core/localization/I18n";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { PERMISSION_SCOPE_OPTIONS, USER_IMAGE_PLACEHOLDER } from "../../../../constants";

export const getUserFormFields = (
  org: FP.Entities.IOrganisation,
  modalService: IModalService,
  user?: FP.Entities.IUser,
  isExtended?: boolean
) => {
  let orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: org && org.id + "",
    defaultValue: org && org.id + ""
  };

  let profPic: Partial<IImageCropperModel> = {
    ...INIT_IMAGE_CROPPER_FIELD,
    key: "ProfileImage",
    value: {
      url: user?.profileImageUrl || USER_IMAGE_PLACEHOLDER(user?.firstName, user?.lastName),
      blob: null,
      base64: ""
    },
    modalService: modalService,
    orignalImageSource: user && user.profileImageUrl,
    avatarLabel: "Add user profile image",
    fieldClassName: "col-lg-6 col-space-lg-6"
  };

  let email: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "email",
    label: <label htmlFor="email">{I18n.t("forms.email")} *</label>,
    placeholder: I18n.t("placeholders.email"),
    validate: function () {
      let self: TextFieldModel = this;
      let errorMessages: React.ReactNode[] = [];
      let res = true;

      if (Validations.IS_EMPTY(self.value)) {
        errorMessages.push(<ErrorMessage key="1">{I18n.t("validations.email")}</ErrorMessage>);
        res = false;
      }

      if (!Validations.IS_EMAIL(self.value)) {
        errorMessages.push(<ErrorMessage key="2">{I18n.t("validations.validEmail")}</ErrorMessage>);
        res = false;
      }

      self.errorMessage = errorMessages.length ? errorMessages : null;
      return res;
    },
    fieldClassName: "col-lg-6 col-space-lg-6",
    value: user?.email
  };

  const firstName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "firstName",
    placeholder: I18n.t("placeholders.firstName"),
    label: <label htmlFor="name">{I18n.t("forms.firstName")} *</label>,
    fieldClassName: "col-lg-6",
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="3">{I18n.t("validations.firstName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: user?.firstName
  };

  const lastName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "lastName",
    placeholder: I18n.t("placeholders.lastName"),
    label: <label htmlFor="lastName">{I18n.t("forms.lastName")} *</label>,
    fieldClassName: "col-lg-6",
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.lastName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: user?.lastName
  };

  const permissionScope: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "permissionScope",
    label: <label htmlFor="permissionScope">{I18n.t("forms.permissionScope")} *</label>,
    placeholder: I18n.t("placeholders.permissionScope"),
    fieldClassName: "col-12 col-lg-6",
    componentProps: {
      className: "form-control"
    },
    options: PERMISSION_SCOPE_OPTIONS.map(option => ({ ...option, label: I18n.t(option.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IAutocompleteModel = this;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.permissionScope")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    extractValue: function () {
      return this.value?.key;
    }
  };

  const fields = [];

  if (user) {
    let userId = {
      ...INIT_TEXT_FIELD,
      key: "id",
      inputType: "hidden",
      value: user && user.id + "",
      defaultValue: user && user.id + ""
    };
    fields.push(userId);
  }

  fields.push(orgId);
  fields.push(email);
  fields.push(firstName);
  fields.push(lastName);
  fields.push(permissionScope);

  if (isExtended) {
    fields.push(profPic);
  }

  const models = generateFormFieldsFromJson(fields);

  return models;
};
