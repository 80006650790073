import * as React from 'react';
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IListingModel } from '../../../../core/forms/controls/listing/IListingModel';
import { INIT_LISTING_FIELD } from '../../../../core/forms/controls/listing/Listing_model';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import { IImpactsApi } from '../../../../services/api/v1/impacts/IImpacts.api';
import I18n from '../../../../core/localization/I18n';
import _ from 'lodash';

export const getImpactActionFormFields = (
    impactProvider: IImpactsApi,
    organisationId: number,
    projectId: number,
    impactId: number,
) => {

    let actionSearch: Partial<IAutocompleteModel> = {
        ...INIT_AUTOCOMPLETE,
        key: "actionSearch",
        label: (<label htmlFor={"actionSearch"}>{I18n.t("forms.impactActions")}</label>),
        placeholder: I18n.t("placeholders.searchAction"),
        optionElement: (<AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={(e: FP.Entities.IAction) => `${e.refNumber} - ${e.name}`}
        />),
        componentProps: {
            className: "form-control",
            icon: "search",
        },
        onFocus: async function () {
            let self: IAutocompleteModel = this;
            this.componentProps.disabled = false;
            const res = await impactProvider.getUnassignedActions(organisationId, projectId, impactId);

            if (res?.payload) {
                const sortedActions = _.orderBy(res.payload, [action => action.name.toLowerCase()]);
                self.setOptions(sortedActions);
            }
        },
        shouldClearOnBlur: true,
        filterFn: (items: FP.Entities.IAction[], query) => {
            const lowerQuery = query.toLowerCase();
            return _.filter(items, item => {
                const lowerName = item.name.toLowerCase();
                const lowerRef = item.refNumber ? item.refNumber.toLowerCase() : "";
                return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
            })
        },
        fieldClassName: "col-12"
    }


    let listing: Partial<IListingModel> = {
        ...INIT_LISTING_FIELD,
        key: "actions",
        placeholder: I18n.t("placeholders.selectAction"),
        label: (<label htmlFor={"actions"}>{I18n.t("forms.selectedActions")}</label>),
        fieldClassName: "col-12",
        subscribeTo: ["actionSearch"],
        onChannelFieldChanged: function (field) {
            let val = field.value;
            if (val) {
                this.addItem(val);
                field.reset();
            }
        },
        extractValue: function () {
            return this.value && this.value.map((e) => e.id)
        },
        selector: (e: FP.Entities.IAction) => <p className="mb-0 d-inline-block">{e.refNumber} - {e.name}</p>,
    }

    const fields = [];

    fields.push(actionSearch);
    fields.push(listing);
    const models = generateFormFieldsFromJson(fields);
    return models;
}