import * as React from "react";
import { observer } from "mobx-react";
import { ImpactGroupFormSectionModel } from "./ImpactGroupFormSection_model";
import { SingleForm } from "../singleFormModel/SingleForm_view";

export interface ImpactGroupFormSectionProps {
  model: ImpactGroupFormSectionModel;
}

@observer
export class ImpactGroupFormSectionView extends React.Component<ImpactGroupFormSectionProps> {
  componentDidMount() {
    this.props.model.onMount();
  }

  render() {
    const { model } = this.props;

    return (
      <>
        <div className={`row mb-5 py-5 bg-primary-light-5 ${model.impactGroup?.project ? "d-none" : ""}`}>
          <div className="col-lg-10">
            <SingleForm model={model.projectForm} />
          </div>
        </div>
        {!!model.projectId && (
          <div className="row mb-4">
            <div className="col-lg-10">
              <SingleForm model={model.formModel} />
            </div>
          </div>
        )}
      </>
    );
  }
}
