import { IFilteredOptions, IFilteredResponse } from "../filteredApi/FilteredApiModel";
import {
  IFilteredOrganisationApiModel,
  FilteredOrganisationApiModel
} from "../filteredApi/FilteredOrganisationApiModel";
import { AxiosRequestConfig } from "axios";

export interface IExportableOrganisationApi<T> extends IFilteredOrganisationApiModel<T> {
  exportData: (
    organisationId: number,
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => Promise<IFilteredResponse<T>>;
}

export abstract class ExportableOrganisationApi<T>
  extends FilteredOrganisationApiModel<T>
  implements IExportableOrganisationApi<T> {
  abstract controller: string;

  exportData = async (
    organisationId: number,
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/${this.controller}/export${query}&columns=${columns.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
