import * as React from "react";
import { UsersSettingsModel } from "./UsersSettings_model";
import { Button, LinkButton } from "../../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import { TableView } from "../../../../../core/table/Table_view";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { Icon } from "../../../../../components/ui/Icon";
import { CanEditOrg } from "../../../../../components/hoc/CanEdit";
import { useParams } from "react-router-dom";
import { Animations } from "../../../../../core/util/Animations";

export interface UsersProps {
  model: UsersSettingsModel;
}

const UsersSettings: React.FunctionComponent<UsersProps> = observer(({ model }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  React.useEffect(() => {
    model.onMount(+organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  let searchValue = model.filterModel.getFilter("FirstName|LastName|Email").value;
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {model.organisation.name} | <strong>{I18n.t("phrases.manageUsers")}</strong>
          </h1>
          <p>{I18n.t("phrases.manageUsersDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <LinkButton
              type="outline-primary"
              href={`/organisations/${model.organisation?.id}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <CanEditOrg>
            <Button
              id="InviteUserButton"
              onClick={() => {
                model.showUserFormModal();
              }}
            >
              {I18n.t("phrases.inviteUsers")}
            </Button>
          </CanEditOrg>
          <Button
            className={"float-right ml-2"}
            type="outline-primary"
            onClick={model.exportService.showSelectionModal}
          >
            <Icon symbol="download" className="mr-2" />
            {I18n.t("phrases.export")}
          </Button>
          <SearchButton
            id="SearchUsers"
            className={"float-right"}
            value={searchValue.length > 0 ? searchValue[0] : ""}
            onChange={model.searchUsers}
            onClose={model.resetQuery}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          {model.isLoading ? (
            <PositionedSpinner />
          ) : (
              <>
                <TableView model={model.tableModel} />
                <Pagination model={model.paginationModel} />
              </>
            )}
        </div>
      </div>
    </div>
  );
});

export { UsersSettings };
