import * as React from 'react';

const SvgDownloadCloud = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="download-cloud_svg__feather download-cloud_svg__feather-download-cloud"
        {...props}
    >
        <path d="M8 17l4 4 4-4M12 12v9" />
        <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29" />
    </svg>
);

export default SvgDownloadCloud;
