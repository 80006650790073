import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { ITableModel } from "../../../../core/table/ITableModel";
import { TableModel } from "../../../../core/table/Table_model";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { IModalService } from "../../../../core/modal/IModalService";
import { Services } from "../../../../constants";
import { ButtonIcon, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { ActionCompactView, ActionCompactViewModel } from "../../actions/actionCompactView";
import { GetActionTableConfig } from "./ActionTableConfig";
import { IDonutInfoModel } from "../../../../components/widgets/donutInfo/DonutInfo_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { observable, action } from "mobx";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { Enums, RagStatus } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { IFilterModel, FilterModel, FilterOperator, IFilterAttribute } from "../../../../core/filter/Filter_model";
import { GetActionListFilters } from "./ActionListViewFilter_fields";
import { Icon } from "../../../../components/ui/Icon";
import { PaginationModel, IPaginationModel } from "../../../../components/widgets/pagination/Pagination_model";
import { IExportService, ExportService } from "../../../../services/local/export/ExportService";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import moment from "moment";
import { Animations } from "../../../../core/util/Animations";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";
import * as H from "history";

export class ActionListViewModel extends BaseModel {
  appService: AppService;
  history: H.History<unknown>;
  projectId: number;
  tableModel: ITableModel<FP.Entities.IAction>;
  modalService: IModalService;
  innerModalService: IModalService;
  actionProvider: IActionsApi;
  projectProvider: IProjectsApi;
  organisationSettings: IOrganisationSettingsService;
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IAction>;
  exportService: IExportService<FP.Entities.IAction>;
  @observable.ref actions: FP.Entities.IAction[];
  @observable.ref generalInfo: IDonutInfoModel[] = [];
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  @observable showVisualisations: boolean;
  

  constructor(appService: AppService, history: H.History<unknown>, projectId: number | null, organisationId: number) {
    super();
    this.appService = appService;
    this.history = history;
    
    this.organisationId = organisationId;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.organisationSettings = this.appService.getService<IOrganisationSettingsService>(Services.OrganisationSettingsService);
    if (projectId) {
      this.projectId = projectId;
    }
    this.installPagination();
    this.installFilter();
    this.installExportService();
    this.tableModel = new TableModel();

    let tableConfig = GetActionTableConfig(this, this.tableModel);

    this.tableModel.set(tableConfig);
  }

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    this.filterModel = new FilterModel(this.appService, this.paginationModel, {
      filterCb: async filterOptions => await this.actionProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
      projectId: this.projectId
    });

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const progressFilter: IFilterAttribute = {
      key: "progressStatus",
      label: I18n.t("filters.progressStatus"),
      value: [],
      operator: FilterOperator.EQUALS,
      isMultiValue: true,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ProgressStatus(parseInt(k));
      },
    };

    const nameFilter: IFilterAttribute = {
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const refFilter: IFilterAttribute = {
      key: "refNumber",
      label: I18n.t("filters.refNumber"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const ragStatusFilter: IFilterAttribute = {
      key: "ragStatus",
      label: I18n.t("filters.ragStatus"),
      operator: FilterOperator.EQUALS,
      value: [],
      isMultiValue: true,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.RagStatus(parseInt(k));
      },
    };

    const ownerFilter: IFilterAttribute = {
      key: "Owner.FirstName|Owner.LastName|Owner.Email",
      label: I18n.t("filters.owner"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.filterModel.addSort({ key: "actualEndDate", isAsc: false });

    this.filterModel.addFilter(projectFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(refFilter);
    this.filterModel.addFilter(ragStatusFilter);
    this.filterModel.addFilter(ownerFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(progressFilter);
    this.filterModel.setConfig({
      formFields: GetActionListFilters(this.organisationId),
      onDataLoaded: d => this.tableModel.setData(d)
    });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  updateRagFilter = (rag: RagStatus) => {
    this.filterModel.setFilterValue("ragStatus", rag + "");
  };

  @action.bound
  async load() {
    await this.loadActions();
    await this.loadProject();
  }

  @action
  loadActions = async () => {
    await this.filterModel.loadData();
    this.actions = this.filterModel.data;
    this.setGeneralInfo();
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
  };

  loadShowVisualisations = async () =>
    await this.organisationSettings.isVisualisationsEnabled(this.organisationId);

  onMount = async () => {
    Promise.all([this.loadShowVisualisations()]).then(values => {
      this.showVisualisations = values[0];
    });
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IAction>(this.appService, this.filterModel, {
      filename: `${I18n.t("entities.actions").toLowerCase()}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.actionProvider.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: [
        {
          key: "ragStatus",
          label: I18n.t("table.ragStatus"),
          selector: item => Enums.Translator.RagStatus(item.ragStatus)
        },
        {
          key: "refNumber",
          label: I18n.t("table.refNo")
        },
        {
          key: "name",
          label: I18n.t("table.name")
        },
        {
          key: "description",
          label: I18n.t("table.description")
        },
        {
          key: "owner",
          label: I18n.t("table.owner"),
        },
        {
          key: "assignee",
          label: I18n.t("table.assignee"),
        },
        {
          key: "startDate",
          label: I18n.t("table.startDate"),
          selector: item => moment(item.startDate).format("L")
        },
        {
          key: "actualEndDate",
          label: I18n.t("table.endDate"),
          selector: item => moment(item.actualEndDate).format("L")
        },
        {
          key: "progressStatus",
          label: I18n.t("table.progressStatus"),
          selector: item => Enums.Translator.ProgressStatus(item.progressStatus)
        },
        {
          key: "actionType",
          label: I18n.t("table.actionType")
        }
      ]
    });
  };

  removeAction = async (actionId: number) => {
    let res = await this.actionProvider.remove(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;
    return res.payload;
  };

  @action
  setGeneralInfo = async () => {
    let res = await this.projectProvider.getActionsCount(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    let actions: any = res.payload;
    const totalRed = actions.totalRed;
    const totalAmber = actions.totalAmber;
    const totalGreen = actions.totalGreen;
    const totalComplete = actions.totalComplete;

    let s: IDonutInfoModel[] = [
      {
        key: Enums.RagStatus.RED,
        value: totalRed,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.redStatus")}</h3>
            <small className="strong">{I18n.t("phrases.incompleteActions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "danger"
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        key: Enums.RagStatus.AMBER,
        value: totalAmber,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.amberStatus")}</h3>
            <small className="strong">{I18n.t("phrases.incompleteActions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "warning"
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        key: Enums.RagStatus.GREEN,
        value: totalGreen,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.greenStatus")}</h3>
            <small className="strong">{I18n.t("phrases.incompleteActions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "success"
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        key: Enums.RagStatus.COMPLETE,
        value: totalComplete,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.complete")}</h3>
            <small className="strong">{I18n.t("phrases.actions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: "primary"
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      }
    ];
    this.generalInfo = s;
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  showActionConfirmDeleteModal = (action: FP.Entities.IAction) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeActionFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: action.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeAction(action.id);
          this.loadActions();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  showActionModal = (row: ITableRowModel) => {
    let action: FP.Entities.IAction = row.rowObject;
    let actionCompactModel = new ActionCompactViewModel(this.appService, this.projectId, this.organisationId);
    actionCompactModel.setActionObject(row.rowObject);
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            id="ViewDetailsButton"
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/actions/${action.id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="pencil"
            onClick={this.modalService.hide}
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/actions/${action.id}/edit`}
          />
          <ButtonIcon
            key="3"
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="close"
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: <ActionCompactView model={actionCompactModel} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
      }
    });
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 1) {
      this.history.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/action-visualisations`
      );
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions`);
  };
}
