import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import React, { useCallback, useEffect, useState } from "react";
import { Async } from "react-async";
import { useAppService } from "../../../../../AppService";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCanEditOrganisationClaim } from "../../../../../core/auth/authorise";
import { useCurrentOrganisation } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { TableView } from "../../../../../core/table/Table_view";
import { Animations } from "../../../../../core/util/Animations";
import { CustomPropertiesModel } from "./CustomProperties_model";
import { ShowIfOrganisationAdmin } from "../../../../../components/hoc/CanEdit";
import { useParams } from "react-router";
import { Button, LinkButton } from "../../../../../components/ui/Button";


export const CustomProperties: React.FC<any> = (props) => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const canEditOrgnisation = useCanEditOrganisationClaim(organisation.id);
  const params = useParams();

  const [model] = useState(new CustomPropertiesModel(appService, organisation.id, parseInt(params["projectId"])));

  useEffect(() => {
    model.onMount(canEditOrgnisation);
    return model.onUnmount;
  }, [model, canEditOrgnisation]);

  const load = useCallback(async () => {
    await model.loadCustomProperties(organisation.id);
  }, [model, organisation]);

  return <ShowIfOrganisationAdmin>
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`} style={{ height: "100%", minHeight: "100vh" }}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {organisation.name} | <strong>{I18n.t("phrases.manageCustomProperties")}</strong>
          </h1>
          <p>{I18n.t("phrases.manageCustomPropertiesDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <LinkButton
              type="outline-primary"
              href={`/organisations/${organisation.id}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Button
            id="ShowCustomPropertyFormButton"
            onClick={() => model.showFormModal()}>{I18n.t("phrases.createCustomProperty")}
          </Button>
        </div>
      </div>
      <Async promiseFn={load}>
        <Async.Loading>
          <PositionedSpinner />
        </Async.Loading>
        <Async.Resolved>
          <TableView model={model.tableModel} />
          <Pagination model={model.paginationModel} />
        </Async.Resolved>
      </Async>
    </div>
  </ShowIfOrganisationAdmin>
}