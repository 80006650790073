import * as React from "react";
import I18n from "../../../../../core/localization/I18n";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { Enums } from "../../../../../enums";

export const GetOrgSettingsTableConfig = (
  parentModel,
  tableModel: ITableModel<FP.Entities.IOrganisation>
): ITableConfig<any> => {
  return {
    actions: null,
    colHeaders: [
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        }
      },
      {
        key: "industry",
        content: <h4 className="mb-0">{I18n.t("table.industry")}</h4>,
        selector: (obj: FP.Entities.IOrganisation) => obj.industry?.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        }
      },
      {
        key: "owner",
        content: <h4 className="mb-0">{I18n.t("table.owner")}</h4>,
        selector: (obj: FP.Entities.IOrganisation) => obj.owner.firstName + " " + obj.owner.lastName,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        }
      },
      {
        key: "permissionScope",
        content: <h4 className="mb-0">{I18n.t("table.permissionScope")}</h4>,
        selector: (obj: FP.Entities.IOrganisation) => I18n.t(Enums.Translator.PermissionScope(obj.permissionScope))
      }
    ] as any[],
    onRowClick: parentModel.showActionModal,
    tableProps: {}
  };
};
