import * as React from 'react';
import { B } from '../../../core/util/BootstrapHelper';


export interface DivTableProps {
    id?: string;
    className?: string;
    isStriped?: boolean;
    isCondensed?: boolean;
    isBordered?: boolean;
}


export const DivTable: React.FunctionComponent<DivTableProps> = (props) => {
    let cls = B().bl("table")
        .pmod(props.isStriped ? "striped" : "")
        .pmod(props.isCondensed ? "condensed" : "")
        .pmod(props.isBordered ? "bordered" : "")
        .boot;


    return <div
        id={props.id}
        className={`${cls} ${props.className || ""} divTable`}
    >
        {props.children}
    </div>

}

export const DivTableHeader: React.FunctionComponent<any> = (props) => {
    return <div
        {...props}
        className={`${props.className || ""} divTableHead`}
    >
        {props.children}
    </div>
}

export interface DivTableHeaderGroupProps extends
    React.HTMLProps<HTMLDivElement> {
    rowProps?: DivTableRowProps;
}

export const DivTableHeaderGroup: React.FunctionComponent<DivTableHeaderGroupProps> = (props) => {
    return <div {...props}
        className={`${props.className || ""} divTableHeading`}>
        <DivTableRow {...props.rowProps}>
            {props.children}
        </DivTableRow>
    </div>
}


export const DivTableBody: React.FunctionComponent<any> = (props) => {
    return <div
        style={props.style || null}
        className={`${props.className || ""} divTableBody`}>
        {props.children}
    </div>
}

export interface DivTableRowProps extends React.HTMLProps<HTMLElement> {
    index?: number;
}

export const DivTableRow: React.FunctionComponent<DivTableRowProps> = (props) => {
    return <div
        {...props as any}
        className={`${props.className || ""} divTableRow`}
    >
        {props.children}
    </div>
}



export const DivTableCol: React.FunctionComponent<any> = (props) => {
    return <div
        {...props}
        className={`${props.className || ""} divTableCell`}
    >
        {props.children}
    </div>
}

export const DivTableFooter: React.FunctionComponent<any> = (props) => {
    return <div
        {...props}
        className={`${props.className || ""} divTableFoot`}
    >
        {props.children}
    </div>
}

let s = {
    Table: DivTable,
    Col: DivTableCol,
    Row: DivTableRow,
    Body: DivTableBody,
    Header: DivTableHeaderGroup,
    HeaderCol: DivTableHeader,
    Footer: DivTableFooter
}

export default s; 