import * as React from "react";
import { Enums, UiSizes } from "../../../enums";
import { B } from "../../../core/util/BootstrapHelper";

export interface DonutFillerProps {
  percent: number;
  className?: string;
  type?:
    | "primary"
    | "primary-1"
    | "primary-2"
    | "primary-3"
    | "secondary"
    | "tertiary"
    | "danger"
    | "warning"
    | "success"
    | "indicator-accent-1"
    | "indicator-accent-2"
    | "indicator-accent-3";
  size?: UiSizes;
}

function getDonutRadius(size: UiSizes) {
  switch (size) {
    case Enums.UiSizes.XXS:
      return 20;
    case Enums.UiSizes.XS:
      return 30;
    case Enums.UiSizes.SM:
      return 40;
    case Enums.UiSizes.MD:
      return 50;
    case Enums.UiSizes.LG:
      return 60;
    default:
      return 30;
  }
}

export const DonutFiller: React.FunctionComponent<DonutFillerProps> = (props: any) => {
  const RADIUS = getDonutRadius(props.size);

  const VIEWPORT = RADIUS * 2 + 8;
  const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
  let progress = props.percent;
  var dashoffset = CIRCUMFERENCE * (1 - progress);
  let cls = B()
    .bl("donut-filler")
    .pmod(props.size || Enums.UiSizes.SM)
    .pmod(props.type || "primary").bem;

  return (
    <div className={`${props.className || ""} ${cls}`}>
      <svg className="donut-filler__svg" width={VIEWPORT} height={VIEWPORT}>
        <circle className="donut-filler__circle donut-filler__meter" cx={VIEWPORT / 2} cy={VIEWPORT / 2} r={RADIUS} />
        <circle
          className="donut-filler__circle donut-filler__value"
          style={{
            strokeDashoffset: dashoffset,
            strokeDasharray: CIRCUMFERENCE
          }}
          cx={VIEWPORT / 2}
          cy={VIEWPORT / 2}
          r={RADIUS}
        />
      </svg>
    </div>
  );
};
