import * as React from "react";
import { ChangeType } from "../../../enums";
import { Icon } from "../../ui/Icon";
import I18n from "../../../core/localization/I18n";

export interface ChangeIndicatorProps {
  changeType: ChangeType;
  changeValue?: number;
  changeSymbol?: string;
}

export const ChangeIndicator: React.FunctionComponent<ChangeIndicatorProps> = (props: any) => {
  const { changeType, changeValue, changeSymbol } = props;

  if (changeType === ChangeType.POSITIVE) {
    return (
      <div className="d-flex align-items-center">
        <span className="mr-2 text-success d-flex align-items-center">
          <Icon symbol="ArrowUp" />
          {changeValue + changeSymbol}
        </span>
        {I18n.t("phrases.inTheLast7Days")}
      </div>
    );
  }

  if (changeType === ChangeType.NEGATIVE) {
    return (
      <div className="d-flex align-items-center">
        <span className="mr-2 text-danger d-flex align-items-center">
          <Icon symbol="ArrowDown" />
          {changeValue + changeSymbol}
        </span>
        {I18n.t("phrases.inTheLast7Days")}
      </div>
    );
  }

  return <div>{I18n.t("phrases.noChangeThisWeek")}</div>;
};
