import * as React from "react";
import { StakeholderCompactViewModel } from "./StakeholderCompactView_model";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { ProjectStakeholderCard } from "../../projects/projectStakeholderCard/ProjectStakeholderCard_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { StakeholderDetails } from "../StakeholderDetails";
import { Link } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export interface StakeholderCompactViewProps {
  model: StakeholderCompactViewModel;
}

const StakeholderCompactView: React.FunctionComponent<StakeholderCompactViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  const { stakeholder } = model;

  return (
    <div className="stakeholder-compact-view container-fluid">
      <div className="row">
        <div className="col">
          <h1 className="my-4">
            {stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
              ? `${stakeholder.firstName} ${stakeholder.lastName}`
              : `${stakeholder.firstName} (${I18n.t("phrases.audience")})`}
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <StakeholderDetails item={stakeholder} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ProjectStakeholderCard item={model.projectStakeholder} />
        </div>
      </div>

      <div className="row pb-5">
        <div className="col">
          <h2>{I18n.t("phrases.impactedBy")}</h2>
          {model.isLoadingImpacts ? (
            <PositionedSpinner />
          ) : model.impacts?.length ? (
            model.impacts.map(impact => {
              return (
                <Link
                  key={impact.id}
                  to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}
                  onClick={model.modalService.hide}
                >
                  <Panel background="bg-white" className="mb-2 p-4" type="border-left-primary">
                    {impact.name}
                  </Panel>
                </Link>
              );
            })
          ) : (
                <p className="mb-0">{I18n.t("phrases.noImpacts")}</p>
              )}
        </div>
      </div>
    </div>
  );
});

export { StakeholderCompactView };
