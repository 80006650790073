import React, { useEffect } from "react";
import { ImpactListViewModel } from "./ImpactListView_model";
import { observer } from "mobx-react";
import { ImpactCard } from "../../../impacts/impactCard/ImpactCard_view";
import { Button, ButtonIcon } from "../../../../../components/ui/Button";
import { Icon } from "../../../../../components/ui/Icon";
import I18n from "../../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { Enums } from "../../../../../enums";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../../components/ui/_forms/Input";
import { CanEditProject } from "../../../../../components/hoc/CanEdit";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";

export interface ImpactListViewProps {
  model: ImpactListViewModel;
}

const ImpactListView: React.FunctionComponent<ImpactListViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const { filteredImpacts } = model;

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col">
          <CanEditProject projectId={model.owner.projectId}>
            <Button onClick={model.showAddImpactModal}>
              <Icon symbol="Plus" className="mr-2" />
              {I18n.t("phrases.addImpact")}
            </Button>
          </CanEditProject>

          <ButtonIcon
            className="float-right"
            symbol={model.isSearchMode ? "Close" : "Search"}
            iconSize={Enums.UiSizes.SM}
            type="outline-primary"
            onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
          />

          <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
            <Input
              autoFocus={true}
              size={-1}
              onChange={model.updateSearchValue}
              borderStyle="underline"
              placeholder={I18n.t("placeholders.searchImpacts")}
              icon="search"
              value={model.searchValue}
              onBlur={null}
              className="mt-3"
            />
          </AnimateHeight>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          {filteredImpacts?.length ? (
            filteredImpacts.map(impact => {
              return (
                <Link
                  key={impact.id}
                  to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}
                >
                  <ImpactCard item={impact} />
                </Link>
              );
            })
          ) : (
            <div>{I18n.t("phrases.noImpacts")}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export { ImpactListView };
