import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { Services } from "../../../../constants";
import { IModalService } from "../../../../core/modal/IModalService";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { getImpactActionFormFields } from "./ImpactActions_data";
import I18n from "../../../../core/localization/I18n";
import { Icon } from "../../../../components/ui/Icon";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { LinkButton } from "../../../../components/ui/Button";
import { Animations } from "../../../../core/util/Animations";

export class ImpactActionsModel extends BaseModel {
  appService: AppService;
  impactProvider: IImpactsApi;
  isRouteView: boolean;
  impactId: number;
  projectId: number;
  modalService: IModalService;
  httpProgress: IHttpProgressModel;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable isLoading: boolean = true;
  @observable toggle: "active" | "closed" = "active";
  @observable.ref impact: FP.Entities.IImpact;
  @observable.ref impactActions: FP.Entities.IAction[] = [];
  @observable.ref filteredActions: FP.Entities.IAction[] = [];

  actionCardActions: IUiAction<FP.Entities.IAction>[] = [];
  organisationId: number;

  constructor(appService: AppService, organisationId: number, projectId: number, impactId: number, isRouteView: boolean = true) {
    super();

    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.isRouteView = isRouteView;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.impactId = impactId;
    this.setActionCardActions();
  }

  onMount = (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => {
    this.resetSearch();
  };

  loadActions = async (impactId: number) => {
    this.impactId = impactId;
    this.isLoading = true;
    let res = await this.impactProvider.getActions(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  setActionCardActions = () => {
    this.actionCardActions.push({
      id: "action1",
      label: I18n.t("phrases.view"),
      rendersIn: "HTMLAnchor",
      componentProps: {
        type: "link"
      },
      hrefFn: action => `/organisations/${this.organisationId}/projects/${action.projectId}/actions/${action.id}`
    });
    if (this.permissionService.canEdit()) {
      this.actionCardActions.push({
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: (ev, action: FP.Entities.IAction) => {
          this.showActionConfirmDeleteModal(action);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      });
    }
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.impactActions = actions;
    this.filterActions();
    this.isLoading = false;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterActions();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterActions();
    this.hideSearchMode();
  };

  @action
  filterActions = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredActions = this.impactActions.filter(action => {
        const lowerName = `${action.owner.firstName} ${action.owner.lastName}`.toLowerCase();
        const lowerContent = action.name.toLowerCase();
        const lowerRefNo = action.refNumber.toLowerCase();

        return (
          lowerName.includes(lowerSearch) || lowerContent.includes(lowerSearch) || lowerRefNo.includes(lowerSearch)
        );
      });
    } else {
      this.filteredActions = this.impactActions;
    }
  };

  removeAction = async (impactId: number, actionId: number) => {
    this.httpProgress.showOverlay();
    const res = await this.impactProvider.removeAction(this.organisationId, this.projectId, impactId, actionId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  showAddActionModal = () => {
    let formFields = getImpactActionFormFields(this.impactProvider, this.organisationId, this.projectId, this.impactId);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          this.modalService.hide();

          this.httpProgress.showOverlay();
          await this.impactProvider.addActions(this.organisationId, this.projectId, this.impactId, res.actions);
          this.httpProgress.hideOverlay();

          this.loadActions(this.impactId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: (
          <>
            <h1 className="mt-6">
              {I18n.t("phrases.addActions")}
              <LinkButton
                type="link"
                className="float-right"
                onClick={() => {
                  this.modalService.hideAll();
                }}
                href={`/organisations/${this.organisationId}/projects/${this.projectId}/actions/create?impactId=${this.impactId}`}
              >
                {I18n.t("phrases.createAction")}
              </LinkButton>
            </h1>
          </>
        ),
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-white"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showActionConfirmDeleteModal = (action: FP.Entities.IAction) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="AlertCircle" className="mr-2" />
              {I18n.t("warnings.removeImpactAction")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: action.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeAction(this.impactId, action.id);
          this.loadActions(this.impactId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
