import React from "react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../AppService";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { StakeholderVisualisationsPlaceholderPageModel } from "./StakeholderVisualisationsPlaceholderPage_model";

export interface StakeholderVisualisationsPlaceholderPageProps {
    model: StakeholderVisualisationsPlaceholderPageModel;
  }
  
  const StakeholderVisualisationsPlaceholderPage: React.FunctionComponent<StakeholderVisualisationsPlaceholderPageProps> = observer(props => {
    let { model } = props
    const appService = useAppService();
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams<{ projectId: string }>();

    useEffect(() => {
        model.onMount();
        return model.onUnmount;
      }, [projectId, organisationId, appService, model]);

      return (
          <p>{model.title || "Placeholder title"}</p>
      )
  });

  export {StakeholderVisualisationsPlaceholderPage}