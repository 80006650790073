import * as React from "react";
import { IFilterModel } from "../../../core/filter/Filter_model";
import { Pill } from "../../ui/Pill";
import { Icon } from "../../ui/Icon";
import { observer } from "mobx-react-lite";

export interface FilterPillsProps {
  filterModel: IFilterModel<any>;
}

export const FilterPills: React.FunctionComponent<FilterPillsProps> = observer(props => {
  return (
    <div className="filter-pills">
      {props.filterModel.currentFilters.map((e: any, i: number) => {
        if (!e.value || e.isHidden || e.value.length === 0) {
          return null;
        }

        let filter = props.filterModel.getFilter(e.key) as any;
        let filterList: any[] = filter.value as any;
        if (filterList.length === 0) return null;

        return filterList.map((k: any, j: number) => {
          if (k === "") return null;

          const valueLabel = e.valueRenderer ? e.valueRenderer(k, e) : k;
          const label = e.label ? `${e.label}: ${valueLabel}` : valueLabel;

          return (
            <Pill className="m-1" key={i + "-" + j}>
              <span
                onClick={ee => {
                  props.filterModel.removeFilterValue(filter.key, k);
                }}
              >
                <Icon symbol="Close" className="mr-2" />
              </span>
              <p className="mb-0 d-inline-block">{label}</p>
            </Pill>
          );
        });
      })}
    </div>
  );
});
