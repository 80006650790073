import { Enums } from "./enums";

//General Constants
// export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
// export const ERROR_REPORT_EMAIL = 'jon.hay@sysdocgroup.com';

/**
 * Default application services.
 * This is a list of the services that can be registered with the
 * application DI container.
 */
export enum Services {
  AppCache = "AppCache",
  Cache = "Cache",
  Logger = "Logger",
  HttpClient = "HttpClient",
  AdminApp = "AdminApp",
  ModalService = "AsideModalService",
  InnerModalService = "InnerModalService",
  AsideModalService = "AsideModalService",
  ToasterService = "ToasterService",
  Http = "Http",
  TableModel = "TableModel",
  PaginatedTableModel = "PaginatedTableModel",
  RouteProvider = "RouteProvider",
  CookieService = "CookieService",
  OrganisationContext = "OrganisationContext",
  NavigationModel = "NavigationModel",
  HttpProgress = "HttpProgress",
  OrganisationSwitchModel = "OrganisationSwitchModel",
  CreateNavModel = "CreateNavModel",
  DashboardNavModel = "DashboardNavModel",
  Glogger = "Glogger",
  PermissionService = "PermissionService",
  RecentProjectsService = "RecentProjectsService",
  CsvHelper = "CsvHelper",
  OrganisationSettingsService = "OrganisationSettingsService",
  InnerNavigationService = "InnerNavigationService",
  // APIs
  UsersApi = "UsersApi",
  AdminApi = "AdminApi",
  OrganisationsApi = "OrganisationsApi",
  BusinessAreasApi = "BusinessAreasApi",
  CommentsApi = "CommentsApi",
  ImpactGroupsApi = "ImpactGroupsApi",
  IndustriesApi = "IndustriesApi",
  LocationsApi = "LocationsApi",
  ProjectsApi = "ProjectsApi",
  ProgrammesApi = "ProgrammesApi",
  ActionsApi = "ActionsApi",
  StakeholdersApi = "StakeholdersApi",
  RolesApi = "RolesApi",
  StakeholderGroupsApi = "StakeholderGroupsApi",
  ImpactsApi = "ImpactsApi",
  QuickImpactsApi = "QuickImpactsApi",
  ImpactTypesApi = "ImpactTypesApi",
  ConcernsApi = "ConcernsApi",
  CustomPropertiesApi = "CustomPropertiesApi",
  CustomPropertyTypesApi = "CustomPropertyTypesApi",
  CustomPropertyEntityTypesApi = "CustomPropertyEntityTypesApi",
  MilestonesApi = "MilestonesApi",
  ProjectStakeholdersApi = "ProjectStakeholdersApi",
  ReportsApi = "ReportsApi",
  UserReportsApi = "UserReportsApi",
  ActionTypesApi = "ActionTypesApi",
  DataDogLoggingService = "DataDogLoggingService",
  OrganisationSettingsApi = "OrganisationSettingsApi"
}

export const LOG_LEVEL_NAMES = {
  [Enums.LogLevel.Diagnostics]: "Diagnostics",
  [Enums.LogLevel.Info]: "Info",
  [Enums.LogLevel.Warning]: "Warning",
  [Enums.LogLevel.Error]: "Error"
};

export interface IAppService {
  label: string;
  cb: () => any;
}

export const COMPANY_SIZE_OPTIONS = [
  { key: 1, label: "1 - 100 people" },
  { key: 2, label: "101 - 500 people" },
  { key: 3, label: "501 - 1000 people" },
  { key: 4, label: "1001 - 5000 people" },
  { key: 5, label: "5000+ people" }
];

export const IMPACT_TYPE_OPTIONS = [
  { key: Enums.ImpactType.PEOPLE, label: "phrases.people" },
  { key: Enums.ImpactType.PROCESS, label: "phrases.process" },
  { key: Enums.ImpactType.TECHNOLOGY, label: "phrases.technology" }
];

export const IMPACT_LEVEL_OPTIONS = [
  { key: Enums.ImpactLevel.LOW, label: "phrases.low" },
  { key: Enums.ImpactLevel.MEDIUM, label: "phrases.medium" },
  { key: Enums.ImpactLevel.HIGH, label: "phrases.high" }
];

export const PROGRESS_STATUS_OPTIONS = [
  { key: Enums.ProgressStatus.NOT_STARTED, label: "phrases.notStarted" },
  { key: Enums.ProgressStatus.IN_PROGRESS, label: "phrases.inProgress" },
  { key: Enums.ProgressStatus.COMPLETED, label: "phrases.completed" }
];

export const IMPACT_STATUS_OPTIONS = [
  { key: Enums.ProgressStatus.NOT_STARTED, label: "phrases.notStarted" },
  { key: Enums.ProgressStatus.IN_PROGRESS, label: "phrases.inProgress" },
  { key: Enums.ProgressStatus.COMPLETED, label: "phrases.mitigated" }
];

export const RAG_STATUS_OPTIONS = [
  { key: Enums.RagStatus.RED, label: "phrases.red" },
  { key: Enums.RagStatus.AMBER, label: "phrases.amber" },
  { key: Enums.RagStatus.GREEN, label: "phrases.green" }
];

export const KEY_CONCERN_STATUS_OPTIONS = [
  { key: Enums.ConcernStatus.ACTIVE, label: "phrases.active" },
  { key: Enums.ConcernStatus.CLOSED, label: "phrases.closed" },
  { key: Enums.ConcernStatus.IRRELEVANT, label: "phrases.noLongerRelevant" }
];

export const PERMISSION_SCOPE_OPTIONS = [
  {
    key: Enums.PermissionScope.ADMIN,
    label: "phrases.admin",
    isAssignable: false
  },
  {
    key: Enums.PermissionScope.OWNER,
    label: "phrases.owner",
    isAssignable: true
  },
  {
    key: Enums.PermissionScope.CONTRIBUTOR,
    label: "phrases.contributor",
    isAssignable: true
  },
  {
    key: Enums.PermissionScope.NOACCESS,
    label: "phrases.noAccess",
    isAssignable: false
  },
  {
    key: Enums.PermissionScope.READER,
    label: "phrases.reader",
    isAssignable: true
  }
];

export const IMPACT_REPORT_GROUPS = [
  { key: "businessAreas", label: "phrases.businessAreas" },
  { key: "locations", label: "phrases.locations" },
  { key: "stakeholders", label: "phrases.stakeholderAudience" }
];

export const DEBOUNCE_DELAY = {
  FAST: 250,
  NORMAL: 500,
  SLOW: 750
};

export const USER_IMAGE_PLACEHOLDER = (firstName: string, lastName: string) =>
  `https://eu.ui-avatars.com/api/?background=fff&color=00135d&name=${firstName || "N"}+${lastName || "A"}`;
export const NAME_IMAGE_PLACEHOLDER = (firstName: string) =>
  `https://eu.ui-avatars.com/api/?background=fff&color=00135d&name=${firstName || "NA"}`;
