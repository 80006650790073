import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getProjectStakeholderFormFields } from "../../forms/projectStakeholder/ProjectStakeholder_data";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { StakeholderImpactsViewModel } from "../stakeholderImpactsView/StakeholderImpactsView_model";
import { StakeholderConcernsViewModel } from "../stakeholderConcernsView/StakeholderConcernsView_model";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";

interface IStakeholderExtendedViewModelOptions {
  appService: AppService;
  routeProps: RouteComponentProps;
  authUser: FP.Entities.IUser;
}
export class StakeholderExtendedViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  projectProvider: IProjectsApi;
  stakeholderProvider: IStakeholdersApi;
  projectId: number;
  stakeholderId: number;
  stakeholderImpactModel: StakeholderImpactsViewModel;
  stakeholderConcernsModel: StakeholderConcernsViewModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable isEditView: boolean = false;
  @observable.ref proStakeholderFormModel: SingleFormModel;
  @observable.ref stakeholder: FP.Entities.IStakeholder = null;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder = null;
  projectStakeholderProvider: IProjectStakeholdersApi;
  cookieService: ICookieService;
  organisationId: number;

  constructor({ routeProps, appService, authUser }: IStakeholderExtendedViewModelOptions) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.stakeholderProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);

    this.projectId = parseInt(this.routeProps.match.params["id"]);
    this.stakeholderId = parseInt(this.routeProps.match.params["sid"]);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.organisationId = parseInt(this.cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID));

    this.proStakeholderFormModel = new SingleFormModel();
    this.stakeholderImpactModel = new StakeholderImpactsViewModel(this.appService, this.routeProps, false);
    this.stakeholderConcernsModel = new StakeholderConcernsViewModel(this.appService, this.routeProps, false);
    this.commentViewModel = new CommentListViewModel(appService, this.projectId, authUser, {
      searchAttribute: "projectStakeholderId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId: this.organisationId
    });

    this.revisionHistoryModel = new RevisionHistoryModel(appService, {
      entityId: this.stakeholderId,
      projectId: this.projectId,
      historyType: "stakeholders",
      organisationId: this.organisationId
    });
  }

  onMount = async () => {
    await this.loadProjectStakeholder(this.projectId, this.stakeholderId);

    let fields = getProjectStakeholderFormFields(this.projectId, this.stakeholder, this.projectStakeholder);
    this.proStakeholderFormModel.formFields = fields;
  };

  onUnmount = () => { };

  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    let res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);

    if (!res || res.isError) return;

    this.setProjectStakeholder(res.payload);
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.stakeholder = projectStakeholder.stakeholder;

    this.stakeholderImpactModel.setProjectStakeholder(projectStakeholder);
    this.stakeholderConcernsModel.setProjectStakeholder(projectStakeholder);
    this.commentViewModel.setConfig({
      id: projectStakeholder.id,
      description: <p className="mb-0">{I18n.t("phrases.stakeholderCommentsDescription")}</p>
    });
    this.isLoading = false;
  };

  updateProjectStakeholder = async () => {
    let res = await this.proStakeholderFormModel.submit();
    if (!res) return;
    this.httpProgress.showOverlay();
    let result = await this.projectStakeholderProvider.update(
      this.organisationId,
      this.projectId,
      this.stakeholderId,
      res as FP.Entities.IProjectStakeholder
    );
    this.httpProgress.hideOverlay();
    if (!result || result.isError) return;
    this.setProjectStakeholder(result.payload);
    this.setIsEditView(false);
  };

  @action
  setIsEditView = (val: boolean) => {
    this.isEditView = val;
  };
}
