import * as React from "react";

export const Select: React.FC<any> = React.forwardRef((props, ref?: React.Ref<HTMLSelectElement>) => (
  <select ref={ref} {...props} className={`select ${props.className || ""}`}>
    {props.children}
  </select>
));

export const Option: React.FunctionComponent<React.HTMLProps<HTMLOptionElement>> = props => {
  return <option {...props}>{props.children}</option>;
};

export const OptionGroup: React.FunctionComponent<React.HTMLProps<HTMLOptGroupElement>> = props => (
  <optgroup {...props}>{props.children}</optgroup>
);

let s = {
  Select,
  Option,
  OptionGroup
};

export default s;
