import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { BaseModel } from "../../../../core/util/BaseModel";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getCustomPropertiesFormFields } from "../customPropertiesSettings/generalSettings/GeneralSettings_fields";
import { getCustomPropertiesManageValueFormFields } from "../customPropertiesSettings/manageValueSettings/ManageValueSettings_fields";



export class CreateCustomPropertyModel extends BaseModel {
  @observable.ref formModels: SingleFormModel[] = [];
  organisationId: number;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    super();
    this.organisationId = organisationId;
    this.setForms()
  }

  setForms = () => {
    let generalForm = new SingleFormModel();
    generalForm.formFields = getCustomPropertiesFormFields(this.organisationId, null, true);

    let manageValuesForm = new SingleFormModel();
    manageValuesForm.formFields = getCustomPropertiesManageValueFormFields(this.organisationId, null, true)

    this.formModels.push(generalForm);
    this.formModels.push(manageValuesForm);
  }

}