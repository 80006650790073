import * as React from "react";
import moment from "moment";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import I18n from "../../../../../core/localization/I18n";
import { RagStatus } from "../../../../../components/ui/RagStatus";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { IFilterModel } from "../../../../../core/filter/Filter_model";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";

export const GetUserActionsTableConfig = (
  filterModel: IFilterModel<any>,
  tableModel: ITableModel<FP.Entities.IAction>,
  showCompactModal: (row: ITableRowModel) => void,
  isExtended?: boolean,
  showActionConfirmDeleteModal?
): ITableConfig<FP.Entities.IAction> => {
  let k: ITableConfig<FP.Entities.IAction> = {
    onRowClick: row => {
      showCompactModal(row);
    },
    tableProps: {
      id: "UserActionTable"
    },
    colHeaders: [
      {
        key: "ragStatus",
        content: <h4 className="mb-0">{I18n.t("table.ragStatus")}</h4>,
        selector: (obj: FP.Entities.IAction) => <RagStatus state={obj.ragStatus} />,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "actualEndDate",
        content: <h4 className="mb-0">{I18n.t("table.dueDate")}</h4>,
        selector: (obj: FP.Entities.IAction) => {
          const cls = moment(obj.actualEndDate).isBefore(moment()) ? "text-danger" : "text-warning";
          return <span className={cls}>{moment(obj.actualEndDate).format("L")}</span>;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "project.Organisation.Name",
        content: <h4 className="mb-0">{I18n.t("table.organisation")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.project.organisation.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      }
    ] as any[]
  };

  if (isExtended) {
    k.colHeaders.push(
      {
        key: "Project.Name",
        content: <h4 className="mb-0">{I18n.t("table.project")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.project.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      } as any,
      {
        key: "Project.Organisation.Name",
        content: <h4 className="mb-0">{I18n.t("table.organisation")}</h4>,
        selector: (obj: FP.Entities.IAction) => obj.project.organisation.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      } as any
    );

    k.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        rendersIn: "HTMLAnchor",
        componentProps: {
          type: "link"
        },
        hrefFn: context => `/organisations/${context.rowObject.project.organisationId}/projects/${context.rowObject.projectId}/actions/${context.rowObject.id}/edit`
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: async (ev, row: ITableRowModel) => {
          await showActionConfirmDeleteModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    ];
  }
  return k;
};
