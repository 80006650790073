import * as React from "react";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { PROGRESS_STATUS_OPTIONS, Services } from "../../../../constants";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import AppService from "../../../../AppService";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { getAppToken } from "../../../../setup";
import _ from "lodash";

export const GetActionListFilters = (
  orgId: number,
  includeFields: string[] = ["name", "refNumber", "Owner.FirstName|Owner.LastName|Owner.Email", "progressStatus"]
) => (filterModel: IFilterModel<FP.Entities.IAction>) => {
  const fields = [];
  let userProvider = AppService.getService<IUsersApi>(Services.UsersApi);

  includeFields.forEach((el, i) => {
    switch (el) {
      case "name":
        const nameFilter = filterModel.getFilter("name");
        const name: Partial<ITextFieldModel> = {
          ...INIT_TEXT_FIELD,
          key: nameFilter.key,
          label: <label htmlFor={nameFilter.key}>{I18n.t("forms.actionName")}</label>,
          borderStyle: "underline",
          placeholder: I18n.t("placeholders.actionName"),
          onValueChange: value => {
            filterModel.setFilterValue(nameFilter.key, value);
          },
          fieldClassName: "col-12",
          value: nameFilter?.value.length > 0 ? nameFilter?.value[0] : ""
        };
        fields.push(name);
        break;
      case "refNumber":
        const refFilter = filterModel.getFilter("refNumber");

        const ref: Partial<ITextFieldModel> = {
          ...INIT_TEXT_FIELD,
          key: refFilter.key,
          label: <label htmlFor={refFilter.key}>{I18n.t("table.refNo")}</label>,
          borderStyle: "underline",
          placeholder: I18n.t("placeholders.searchReference"),
          onValueChange: value => {
            filterModel.setFilterValue(refFilter.key, value);
          },
          fieldClassName: "col-12",
          value: refFilter?.value.length > 0 ? refFilter?.value[0] : ""
        };
        fields.push(ref);
        break;
      case "Owner.FirstName|Owner.LastName|Owner.Email":
        const ownerFilter = filterModel.getFilter("Owner.FirstName|Owner.LastName|Owner.Email");

        const owner: Partial<ITextFieldModel> = {
          ...INIT_TEXT_FIELD,
          key: ownerFilter.key,
          label: <label htmlFor={ownerFilter.key}>{I18n.t("table.owner")}</label>,
          borderStyle: "underline",
          placeholder: I18n.t("placeholders.searchOwner"),
          onValueChange: value => {
            filterModel.setFilterValue(ownerFilter.key, value);
          },
          fieldClassName: "col-12",
          value: ownerFilter?.value.length > 0 ? ownerFilter?.value[0] : ""
        };
        fields.push(owner);
        break;
      case "progressStatus":
        const progressFilter = filterModel.getFilter("progressStatus");

        let val: any = _.filter(PROGRESS_STATUS_OPTIONS, e => progressFilter.value.indexOf(e.key as any) >= 0)
        val.forEach(el => {
          if (el.label.startsWith("phrases")) {
            el.label = I18n.t(el.label);
          }
        });

        const progressStatus: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "progressStatus",
          label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")}</label>,
          placeholder: I18n.t("placeholders.progressStatus"),
          onFocus: function (model: IMultiSelectorModel) {
            if (model.extractValue() !== null) {
              model.searchQuery = "";
            }
            if (model.options.length === 0) {
              model.setOptions(
                PROGRESS_STATUS_OPTIONS.map(o => ({
                  ...o,
                  id: o.key,
                  label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
                }))
              );
            }
          },
          valueLabelFn: obj => obj?.label,
          componentProps: {
            className: "form-control"
          },
          value: val,
          fieldClassName: "col-12",
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              progressFilter.key,
              value.map(e => e.key)
            );
          }
        };

        fields.push(progressStatus);
        break;
      case "projectId":
        const project: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "projectId",
          optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
          label: <label htmlFor={"projectId"}>{I18n.t("forms.project")}</label>,
          placeholder: I18n.t("placeholders.project"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await userProvider.getProjects(orgId);
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return obj?.name;
          },
          componentProps: {
            className: "form-control"
          },
          fieldClassName: "col-12",
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              project.key,
              value.map(e => e.id)
            );
          }
        };

        fields.push(project);
        break;
      case "organisationId":
        const organisation: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "project.OrganisationId",
          optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
          label: <label htmlFor={"organisationId"}>{I18n.t("forms.organisation")}</label>,
          placeholder: I18n.t("placeholders.organisation"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await userProvider.getOrgs(orgId);
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return obj?.name;
          },
          componentProps: {
            className: "form-control"
          },
          fieldClassName: "col-12",
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              organisation.key,
              value.map(e => e.id)
            );
          }
        };

        fields.push(organisation);
        break;
      case "actionTypeId":
        const actionType: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "actionTypeId",
          optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
          label: <label htmlFor={"actionTypeId"}>{I18n.t("forms.actionType")}</label>,
          placeholder: I18n.t("placeholders.actionType"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await getActionTypesByOrganisationId(orgId);
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return obj?.name;
          },
          componentProps: {
            className: "form-control"
          },
          fieldClassName: "col-12",
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              actionType.key,
              value.map(e => e.id)
            );
          }
        };

        fields.push(actionType);
        break;
      default:
        break;
    }
  });

  const models = generateFormFieldsFromJson(fields);

  return models;
};

async function getActionTypesByOrganisationId(organisationId: number) {
  const resp = await fetch(
    `${window.appConfig.flightPathCoreApiUrl}/api/v2/organisations/${organisationId}/action-types`,
    {
      headers: {
        authorization: `Bearer ${await getAppToken()}`
      }
    }
  );
  const result = await resp.json();

  return result.payload;
}
