import React, { useEffect } from "react";
import { StakeholderImpactsViewModel } from "./StakeholderImpactsView_model";
import { Button, ButtonIcon } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { ImpactCard } from "../../impacts/impactCard/ImpactCard_view";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import I18n from "../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { Enums } from "../../../../enums";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface StakeholderImpactsViewProps {
  model: StakeholderImpactsViewModel;
}

const StakeholderImpactsView: React.FunctionComponent<StakeholderImpactsViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`container-fluid pt-5 ${Animations.FADE_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col">
          <CanEditProject projectId={model.projectId}>
            <Button onClick={model.showAddImpactModal}>
              <Icon symbol="plus" className="mr-2" />
              {I18n.t("phrases.addImpact")}
            </Button>
          </CanEditProject>

          <ButtonIcon
            className="float-right"
            symbol={model.isSearchMode ? "close" : "search"}
            iconSize={Enums.UiSizes.SM}
            type="outline-primary"
            onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
            disabled={model.impacts?.length === 0}
          />

          <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
            <Input
              autoFocus={true}
              size={-1}
              onChange={model.updateSearchValue}
              borderStyle="underline"
              placeholder={I18n.t("placeholders.searchStakeholderImpacts")}
              icon="search"
              value={model.searchValue}
              onBlur={null}
              className="mt-3"
            />
          </AnimateHeight>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          {model.filteredImpacts.length ? (
            model.filteredImpacts.map(impact => {
              return (
                <Link key={impact.id} to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}>
                  <ImpactCard item={impact} />
                </Link>
              );
            })
          ) : (
              <div>{I18n.t("phrases.noStakeholderImpacts")}</div>
            )}
        </div>
      </div>
    </div>
  );
});

export { StakeholderImpactsView };
