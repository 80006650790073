import { AxiosRequestConfig } from "axios";
import { gEntities } from "../../../../FlightPathEntities";
import { BaseProjectApiModel } from "../../BaseProjectApiModel";
import { IHttp } from "../../IHttp";
import { IQuickImpactsApi } from "./IQuickImpacts.api";

export class QuickImpactsApi extends BaseProjectApiModel<FP.Entities.IQuickImpact> implements IQuickImpactsApi {
  controller: string = "quick-impacts";

  constructor(http: IHttp) {
    super(http, "quick-impacts");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  migrateToComplete = async (
    organisationId: number,
    projectId: number,
    quickImpactId: number,
    data: gEntities.IImpact,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/complete/${quickImpactId}`;
    let res = await this.http.post(url, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getDataHistory = async (
    organisationId: number,
    projectId: number,
    quickImpactId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${quickImpactId}/history`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }
}
