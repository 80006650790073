import { observer } from "mobx-react";
import React, { useState } from "react";
import { ButtonIcon } from "../../Button";
import { Icon } from "../../Icon";
import { Pill } from "../../Pill";
import { Input, InputProps } from "../Input";


export interface MultiInputProps {
  inputProps: InputProps;
  selectedItems: any[];
  clearOnEnter: boolean;
  removeItem: (item: any, index: number) => void;
  valueLabelFn: (obj: any) => string;
  onEnter: (value: string) => void;
}



export const MultiInput: React.FC<MultiInputProps> = observer((props) => {
  const [value, setValue] = useState("");
  return <div className="multi-input">
    <div className="mb-3 multi-input__controls">
      <Input {...props.inputProps}
        value={value}
        className={`multi-input__input ${props.inputProps && props.inputProps.className ? props.inputProps.className : ""}`}
        onChange={(event) => {
          // Cancel the default action, if needed
          event.preventDefault();
          setValue(event.currentTarget.value);

        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            props.onEnter(value);
            if (props.clearOnEnter) {
              setValue("")
            }
          }
        }} />
      <ButtonIcon
        className="multi-input__button"
        symbol="plus"
        onClick={() => {
          props.onEnter(value);
          if (props.clearOnEnter) {
            setValue("")
          }
        }}
      />
    </div>
    <div className="multi-selector_menu">
      {Array.isArray(props.selectedItems) &&
        props.selectedItems.map((e, i) => {
          if (e === null) {
            return null;
          }
          return (
            <Pill className="m-1" key={i}>
              <p className="mb-0 d-inline-block">{props.valueLabelFn(e)}</p>
              <span onClick={ee => props.removeItem(e, i)}>
                <Icon symbol="Close" className="ml-2" />
              </span>
            </Pill>
          );
        })}
    </div>
  </div>
})