import * as React from 'react';

interface IfProps {
    condition: boolean
}

const IF: React.FunctionComponent<IfProps> = (props) => {
    return <>{props.condition && props.children}</>
};

export { IF };