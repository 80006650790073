import * as React from "react";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { StakeholderType, Translator } from "../../../../enums";
import { ISliderFieldModel } from "../../../../core/forms/controls/slider/ISliderFieldModel";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import _ from "lodash";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import I18n from "../../../../core/localization/I18n";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { ICheckboxSliderModel } from "../../../../core/forms/controls/checkboxslider/ICheckboxSliderModel";
import { INIT_CHECKBOX_SLIDER_FIELD } from "../../../../core/forms/controls/checkboxslider/CheckboxSlider_init";

export const getProjectStakeholderSearchField = (
  organisationId: number,
  projectProvider: IProjectsApi,
  onItemSelected: (val: any) => any,
  projectId: number,
  stakeholderType?: StakeholderType
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    label: <label htmlFor={"stakeholdersSearch"}>{I18n.t("phrases.searchForStakeholder")}</label>,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectProvider.getUnassignedProjectStakeholders(organisationId, projectId);

      if (res?.payload) {
        let sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        if (stakeholderType) {
          sortedStakeholders = sortedStakeholders.filter(s => s.stakeholderType === stakeholderType);
        }
        self.setOptions(sortedStakeholders);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
    },
    shouldClearOnBlur: true,
    fieldClassName: "col-lg-6 mb-lg-0",
    valueLabelFn: e =>
      e.stakeholderType === StakeholderType.INDIVIDUAL ? e.firstName + " " + e.lastName : e.firstName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    }
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stakeholdersSearch);

  return generateFormFieldsFromJson(fields);
};

export const getProjectAudienceSearchField = (
  organisationId: number,
  projectProvider: IProjectsApi,
  onItemSelected: (val: any) => any,
  projectId: number,
  stakeholderType?: StakeholderType
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    label: <label htmlFor={"stakeholdersSearch"}>{I18n.t("phrases.searchForAudience")}</label>,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectProvider.getUnassignedProjectStakeholders(organisationId, projectId);

      if (res?.payload) {
        let sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        if (stakeholderType) {
          sortedStakeholders = sortedStakeholders.filter(s => s.stakeholderType === stakeholderType);
        }
        self.setOptions(sortedStakeholders);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
    },
    shouldClearOnBlur: true,
    fieldClassName: "col-lg-6 mb-lg-0",
    valueLabelFn: e =>
      e.stakeholderType === StakeholderType.INDIVIDUAL ? e.firstName + " " + e.lastName : e.firstName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    }
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stakeholdersSearch);

  return generateFormFieldsFromJson(fields);
};

export const getProjectStGroupSearchField = (
  organisationId: number,
  organisationProvider: IOrganisationsApi,
  onItemSelected: (val: any) => any,
  stakeholderGroupProvider: IStakeholderGroupsApi
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const stGroup: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stGroup",
    label: <label htmlFor={"stGroup"}>{I18n.t("phrases.search")}</label>,
    placeholder: I18n.t("placeholders.searchStakeholderGroup"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await stakeholderGroupProvider.getFilteredAsync({ filters: `organisationId==${organisationId},lifecycleStatus==0` }, null, organisationId);

      if (res?.payload) {
        const sortedStakeholderGroups = _.orderBy(res.payload, [
          stakeholderGroup => stakeholderGroup.name.toLowerCase()
        ]);
        self.setOptions(sortedStakeholderGroups);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: "col-lg-6 mb-lg-0",
    valueLabelFn: e => e.name
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stGroup);

  return generateFormFieldsFromJson(fields);
};

export const getProjectStakeholderFormFields = (
  projectId: number,
  stakeholder?: FP.Entities.IStakeholder,
  projectStakeholder?: FP.Entities.IProjectStakeholder
) => {
  const proId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId + "",
    defaultValue: projectId + ""
  };

  const stkId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderId",
    inputType: "hidden",
    value: stakeholder ? stakeholder.id + "" : null,
    defaultValue: stakeholder ? stakeholder.id + "" : null
  };


  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  }

  const isKeyStakeholder: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isKeyStakeholder",
    label: (
      <label htmlFor="isKeyStakeholder">
        {I18n.t(
          stakeholder?.stakeholderType === StakeholderType.AUDIENCE ? "forms.isKeyAudience" : "forms.isKeyStakeholder"
        )}
      </label>
    ),
    fieldClassName: "col-lg-6 col-space-lg-6",
    options: [
      {
        key: "isKeyStakeholderYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupA"
        }
      },
      {
        key: "isKeyStakeholderNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupA"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: projectStakeholder?.isKeyStakeholder ? "yes" : "no"
  };

  const impact: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impact",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"impact"}>{I18n.t("forms.stakeholderImpact")}</label>,
    value: projectStakeholder?.impact,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderImpact")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const influence: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "influence",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"influence"}>{I18n.t("forms.stakeholderInfluence")}</label>,
    value: projectStakeholder?.influence,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderInfluence")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const sentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "sentiment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"sentiment"}>{I18n.t("forms.currentSentiment")}</label>,
    value: projectStakeholder?.sentiment,
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.SentimentLevel
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentSentiment")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const targetSentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetSentiment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"targetSentiment"}>{I18n.t("forms.targetSentiment")}</label>,
    value: projectStakeholder?.targetSentiment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetSentiment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      color: "secondary",
      valueLabelFn: Translator.SentimentLevel
    },
    defaultValue: 5
  };

  const commitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "commitment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"commitment"}>{I18n.t("forms.currentCommitment")}</label>,
    value: projectStakeholder?.commitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5
  };

  const targetCommitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetCommitment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"targetCommitment"}>{I18n.t("forms.targetCommitment")}</label>,
    value: projectStakeholder?.targetCommitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      color: "secondary",
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5
  };

  const receptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "receptiveness",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"receptiveness"}>{I18n.t("forms.currentReceptiveness")}</label>,
    value: projectStakeholder?.receptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5
  };

  const targetReceptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetReceptiveness",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"targetReceptiveness"}>{I18n.t("forms.targetReceptiveness")}</label>,
    value: projectStakeholder?.targetReceptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      color: "secondary",
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5
  };

  const fields = [];

  fields.push(proId);
  fields.push(stkId);
  fields.push(isKeyStakeholder);
  fields.push(impact);
  fields.push(influence);
  fields.push(sentiment);
  fields.push(targetSentiment);
  fields.push(commitment);
  fields.push(targetCommitment);
  fields.push(receptiveness);
  fields.push(targetReceptiveness);

  return generateFormFieldsFromJson(fields);
};

export const getProjectAudienceFormFields = (
  projectId: number,
  stakeholder?: FP.Entities.IStakeholder,
  projectStakeholder?: FP.Entities.IProjectStakeholder
) => {
  const proId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId + "",
    defaultValue: projectId + ""
  };

  const stkId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderId",
    inputType: "hidden",
    value: stakeholder ? stakeholder.id + "" : null,
    defaultValue: stakeholder ? stakeholder.id + "" : null
  };

  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  }

  const isKeyStakeholder: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isKeyStakeholder",
    label: (
      <label htmlFor="isKeyStakeholder">
        {I18n.t(
          stakeholder?.stakeholderType === StakeholderType.AUDIENCE ? "forms.isKeyAudience" : "forms.isKeyStakeholder"
        )}
      </label>
    ),
    fieldClassName: "col-lg-6 col-space-lg-6",
    options: [
      {
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupA"
        }
      },
      {
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupA"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: projectStakeholder?.isKeyStakeholder ? "yes" : "no"
  };

  const impact: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impact",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"impact"}>{I18n.t("forms.audienceImpact")}</label>,
    value: projectStakeholder?.impact,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderImpact")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const influence: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "influence",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"influence"}>{I18n.t("forms.audienceInfluence")}</label>,
    value: projectStakeholder?.influence,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderInfluence")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const sentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "sentiment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"sentiment"}>{I18n.t("forms.currentSentiment")}</label>,
    value: projectStakeholder?.sentiment,
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.SentimentLevel
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentSentiment")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const targetSentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetSentiment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"targetSentiment"}>{I18n.t("forms.targetSentiment")}</label>,
    value: projectStakeholder?.targetSentiment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetSentiment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      color: "secondary",
      valueLabelFn: Translator.SentimentLevel
    },
    defaultValue: 5
  };

  const commitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "commitment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"commitment"}>{I18n.t("forms.currentCommitment")}</label>,
    value: projectStakeholder?.commitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5
  };

  const targetCommitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetCommitment",
    fieldClassName: "col-lg-6",
    label: <label htmlFor={"targetCommitment"}>{I18n.t("forms.targetCommitment")}</label>,
    value: projectStakeholder?.targetCommitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      color: "secondary",
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5
  };

  const receptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "receptiveness",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"receptiveness"}>{I18n.t("forms.currentReceptiveness")}</label>,
    value: projectStakeholder?.receptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5
  };

  const targetReceptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetReceptiveness",
    fieldClassName: "col-lg-6 mb-6",
    label: <label htmlFor={"targetReceptiveness"}>{I18n.t("forms.targetReceptiveness")}</label>,
    value: projectStakeholder?.targetReceptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      color: "secondary",
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5
  };

  const fields = [];

  fields.push(proId);
  fields.push(stkId);
  fields.push(isKeyStakeholder);
  fields.push(impact);
  fields.push(influence);
  fields.push(sentiment);
  fields.push(targetSentiment);
  fields.push(commitment);
  fields.push(targetCommitment);
  fields.push(receptiveness);
  fields.push(targetReceptiveness);

  return generateFormFieldsFromJson(fields);
};
