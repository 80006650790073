import * as React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { Icon, IconSymbol } from "../Icon";
import { UiSizes } from "../../../enums";
import { Link } from "react-router-dom";

export interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "size"> {
  /**
   * Button class name
   */
  className?: string;
  id?: string;
  onClick?: (e: React.SyntheticEvent<any>) => void;
  type?:
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-danger"
  | "outline-warning"
  | "outline-info"
  | "outline-light"
  | "outline-dark"
  | "link"
  | "link-big";

  size?: UiSizes | "block";
  disabled?: boolean;
  style?: React.CSSProperties;
}

/**
 * @category UI-components
 * @param props
 */
const Button: React.FunctionComponent<ButtonProps> = props => {
  let cls: any = B()
    .bl("btn")
    .pmod(props.type || "primary")
    .pmod(props.size || "sm")
    .add(props.disabled ? "disabled" : "").bem;

  return (
    <button
      data-testid={props["data-testid"]}
      id={props.id || ""}
      title={props.title || ""}
      style={props.style}
      className={`${cls} ${props.className || ""}`}
      onClick={props.onClick}
      disabled={props.disabled || props.disabled}
    >
      {props.children}
    </button>
  );
};

export interface LinkButtonProps extends ButtonProps {
  href?: string;
  externalUrl?: string;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = props => {
  let cls: any = B()
    .bl("btn")
    .pmod(props.type || "primary")
    .pmod(props.size || "sm")
    .add(props.disabled ? "disabled" : "").bem;
  let k: any = props;
  return props.href ? (
    <Link
      {...k}
      target={props.target}
      title={props.title || ""}
      className={`${cls} ${props.className || ""}`}
      to={props.href || ""}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  ) : (
    <a
      target={props.target}
      title={props.title || ""}
      className={`${cls} ${props.className || ""}`}
      href={props.externalUrl || ""}
    >
      {props.children}
    </a>
  );
};

export interface ButtonIconProps extends ButtonProps {
  symbol: IconSymbol;
  shape?: "circle" | "rectangle" | "none";
  iconSize?: UiSizes;
}

const ButtonIcon: React.FunctionComponent<ButtonIconProps> = props => {
  let cls: any = B()
    .bl("btn")
    .pmod("icon")
    .pmod((props.shape && "shape-" + props.shape) || "shape-rectangle").bem;

  return (
    <Button {...props} className={`${cls} ${props.className || ""}`} onClick={props.onClick}>
      <Icon symbol={props.symbol} size={props.iconSize || UiSizes.MD} />
    </Button>
  );
};

export interface LinkButtonIconProps extends LinkButtonProps {
  symbol: IconSymbol;
  shape?: "circle" | "rectangle" | "none";
  iconSize?: UiSizes;
}

const LinkButtonIcon: React.FunctionComponent<ButtonIconProps> = props => {
  let cls: any = B()
    .bl("btn")
    .pmod("icon")
    .pmod((props.shape && "shape-" + props.shape) || "shape-rectangle").bem;

  return (
    <LinkButton {...props} className={`${cls} ${props.className || ""}`}>
      <Icon symbol={props.symbol} size={props.iconSize || UiSizes.MD} />
    </LinkButton>
  );
};

export { Button, LinkButton, ButtonIcon, LinkButtonIcon };
