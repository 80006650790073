import _ from "lodash";
import React from "react";
import AppService from "../../../../../AppService";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FormTooltip } from "../../../../../components/ui/_forms/FormTooltip";
import { Services } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IListingModel } from "../../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../../core/forms/controls/listing/Listing_model";
import { IRadioButtonListModel } from "../../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { TextFieldModel } from "../../../../../core/forms/controls/textField/TextField_model";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";
import { ICustomPropertyEntityTypesApi } from "../../../../../services/api/v1/customPropertyEntityTypes/ICustomPropertyEntityTypesApi";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";




export const getCustomPropertiesFormFields = (organisationId: number, item?: FP.Entities.ICustomProperty, canEdit?: boolean) => {
  const customPropertyEntityTypeProvider = AppService.getService<ICustomPropertyEntityTypesApi>(Services.CustomPropertyEntityTypesApi)
  const orgProvider = AppService.getService<IOrganisationsApi>(Services.OrganisationsApi)
  const org: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  }

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.customPropertyName"),
    label: <label htmlFor="name">{I18n.t("forms.customPropertyName")} *</label>,
    fieldClassName: "col-12",
    validate: function () {
      const self: TextFieldModel = this;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="name">{I18n.t("validations.customPropertyName")}</ErrorMessage>
        return false;
      }
      return true;
    },
    value: item?.name
  }

  const description: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "description",
    inputType: "textarea",
    placeholder: I18n.t("placeholders.customPropertyDescription"),
    label: <label htmlFor="name">{I18n.t("forms.customPropertyDescription")}</label>,
    fieldClassName: "col-12",
    value: item?.name
  }

  const entityType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "customPropertyEntityTypeId",
    label: <label htmlFor={"customPropertyEntityTypeId"}>{I18n.t("forms.customPropertyEntityTypeId")} *</label>,
    placeholder: I18n.t("placeholders.searchCustomPropertyEntityTypeId"),
    value: item?.entityType,
    hintLabel: !canEdit ? I18n.t("phrases.customPropertyInUse") : "",
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        const res = await customPropertyEntityTypeProvider.getAll(organisationId);

        if (res?.payload) {
          const sortedTypes = _.orderBy(res.payload, [type => type.name.toLowerCase()]);
          model.setOptions(sortedTypes);
        }
      }
    },
    componentProps: {
      className: "form-control",
    },
    disabled: !canEdit,
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.customPropertyTypeRequired")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: "col-12",
    extractValue: function () {
      return this.value?.id;
    }
  }



  const isAppliedOnAllProjects: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isAppliedOnAllProjects",
    label: (
      <label htmlFor="isAppliedOnAllProjects">
        {I18n.t("forms.isAppliedOnAllProjects")}
      </label>
    ),
    disabled: !canEdit,
    fieldClassName: "col-12",
    options: [
      {
        key: "iisAppliedOnAllProjectsYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupB"
        }
      },
      {
        key: "iisAppliedOnAllProjectsNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupB"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: item?.isAppliedOnAllProjects ? "yes" : "no"
  };

  const projectSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "projectSearch",
    label: <label htmlFor={"projectSearch"}>{I18n.t("forms.projects")}</label>,
    placeholder: I18n.t("placeholders.searchProject"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      const res = await orgProvider.getOrphanProjects(organisationId);

      if (res?.payload) {
        const sortedProjects = _.orderBy(res.payload, [project => project.name.toLowerCase()]);
        self.setOptions(sortedProjects);
      }
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: "col-12",
    valueLabelFn: e => e.name,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.projectDesc")}</p>
      </FormTooltip>
    ),
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val && field.key === "isAppliedOnAllProjects") {
        this.isHidden = field.value === "yes";
      }
    },
    subscribeTo: ["projects", "isAppliedOnAllProjects"],
    onItemSelected: async function () {
      const self: IAutocompleteModel = this;
      const listingModel: IListingModel = self.channels.projects as IListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    disabled: !canEdit,
  };

  let projectListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "projects",
    placeholder: I18n.t("placeholders.selectProject"),
    subscribeTo: ["projectSearch", "isAppliedOnAllProjects"],
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val && field.key === "projectSearch") {
        this.addItem(val);
        field.reset();
      }

      if (val && field.key === "isAppliedOnAllProjects") {
        this.isHidden = (field.value as any) === "yes";
      }
    },
    label: <label htmlFor={"projects"}>{I18n.t("forms.selectedProjects")} </label>,
    fieldClassName: "col-12",
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: e => <p className="mb-0 d-inline-block">{e.name}</p>,
    value: item?.projects
  };


  const fields = [];

  fields.push(org);
  fields.push(name);
  fields.push(description);
  fields.push(entityType);
  fields.push(isAppliedOnAllProjects);
  fields.push(projectSearch);
  fields.push(projectListing);

  const models = generateFormFieldsFromJson(fields);

  return models;
}