import { ICommentsApi } from "./IComments.api";
import { FilteredApiModel, IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { IHttp } from "../../IHttp";
import { AxiosRequestConfig } from "axios";
import { gEntities } from "../../../../FlightPathEntities";
import { IFlightPathApiResponse } from "../../BaseApiModel";

export class CommentsApi extends FilteredApiModel<FP.Entities.IComment> implements ICommentsApi {
  controller: string = "";

  constructor(http: IHttp) {
    super(http, "");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }
  async remove(
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<void>> {
    let res = await this.http.delete(`${this.url}/${organisationId}/projects/${projectId}/comments/${id}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }

  getFiltered = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(`${this.url}/${organisationId}/projects/${projectId}/comments${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  async create(
    organisationId: number,
    projectId: number,
    data: gEntities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<gEntities.IComment>> {
    let res = await this.http.post(`${this.url}/${organisationId}/projects/${projectId}/comments`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }

  update = async (
    organisationId: number,
    projectId: number,
    id: number,
    data: gEntities.IComment,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.put(`${this.url}/${organisationId}/projects/${projectId}/comments/${id}`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
