import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Icon } from "../../../../components/ui/Icon";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { observer } from "mobx-react-lite";
import { convertStakeholderToName } from "../../../../core/util/Helpers";

export interface StakeholderCardProps {
  stakeholder: FP.Entities.IStakeholder;
  actions?: IUiAction<FP.Entities.IStakeholder>[];
}

const StakeholderCard: React.FunctionComponent<StakeholderCardProps> = observer(({ stakeholder, actions }) => {
  return (
    <Panel className="stakeholder-card p-4 mb-2" background="bg-white" type="border-left-primary">
      <div className="row">
        <div className="col">
          <div className="d-flex">
            <div className="strong mr-3">{convertStakeholderToName(stakeholder)}</div>
            {actions && (
              <Tooltip
                position="left"
                className="float-right"
                shownElement={<Icon symbol="dots-horizontal" />}
                triggeredOn="click"
              >
                <Panel background="bg-white" hasShadow={true}>
                  {actions.map(e => {
                    return <UiActionComponent key={e.id} action={e} contextModel={stakeholder} />;
                  })}
                </Panel>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
});

export { StakeholderCard };
