
declare var firebase;

export const firebaseConfig = {
    apiKey: "AIzaSyAWw2gCc-PWfB4udYy69ZkwmH7flQWRSgM",
    authDomain: "flightpath-62cd9.firebaseapp.com",
    databaseURL: "https://flightpath-62cd9.firebaseio.com",
    projectId: "flightpath-62cd9",
    storageBucket: "flightpath-62cd9.appspot.com",
    messagingSenderId: "957933513964",
    appId: "1:957933513964:web:c92bd3d2f3978ad33fc9c9"
};

