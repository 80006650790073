import * as React from "react";
import * as _ from "lodash";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { action, observable } from "mobx";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { Services } from "../../../../constants";
import { IModalService } from "../../../../core/modal/IModalService";
import { getImpactStakeholderFormFields } from "../../forms/impact/ImpactStakeholder_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { getImpactStakeholderGroupFormFields } from "../../forms/impact/ImpactStakeholderGroup_data";
import I18n from "../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { Enums } from "../../../../enums";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Animations } from "../../../../core/util/Animations";

export class ImpactStakeholdersModel extends BaseModel {
  appService: AppService;
  impactProvider: IImpactsApi;
  isRouteView: boolean = true;
  modalService: IModalService;
  httpProgress: IHttpProgressModel;
  impactId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable currentView: "individual" | "groups" = "individual";
  @observable.ref impact: FP.Entities.IImpact;
  @observable.ref projectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable.ref filteredProjectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable.ref stakeholderGroups: Dictionary<FP.Entities.IProjectStakeholder[]>;
  organisationProvider: IOrganisationsApi;
  organisationId: number;

  constructor(appService: AppService, projectId: number, impactId: number, organisationId: number, isRouteView: boolean = true) {
    super();
    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.organisationId = organisationId;
    this.isRouteView = isRouteView;
    this.projectId = projectId;
    this.impactId = impactId;
  }

  onMount = () => {
    this.loadImpact(this.impactId);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  loadImpact = async (impactId: number) => {
    this.isLoading = true;
    let res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpact(res.payload);
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.projectStakeholders = this.impact.projectStakeholders;
    this.filterProjectStakeholders();
    this.stakeholderGroups = this.getStakeholderGroups(this.projectStakeholders);
    this.isLoading = false;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterProjectStakeholders();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterProjectStakeholders();
    this.hideSearchMode();
  };

  @action
  filterProjectStakeholders = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredProjectStakeholders = this.projectStakeholders.filter(pStakeholder => {
        const name =
          pStakeholder.stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
            ? `${pStakeholder.stakeholder.firstName} ${pStakeholder.stakeholder.lastName}`
            : pStakeholder.stakeholder.firstName;
        const lowerName = name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredProjectStakeholders = this.projectStakeholders;
    }
  };

  getStakeholderGroups = (
    stakeholders: FP.Entities.IProjectStakeholder[]
  ): Dictionary<FP.Entities.IProjectStakeholder[]> => {
    let res = _.groupBy(stakeholders, e => {
      return e.stakeholderGroup ? e.stakeholderGroup.slug : "other";
    });
    return res;
  };

  showAddStakeholdersModal = () => {
    let formFields = getImpactStakeholderFormFields(this.impactProvider, this.impact, this.organisationId);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          await this.impactProvider.addProjectStakeholder(this.organisationId, this.projectId, this.impact.id, res.stakeholders);

          this.modalService.hide();
          this.loadImpact(this.impact.id);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.addStakeholder")}</h1>,
        content: (
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col">
                <p>{I18n.t("phrases.addImpactStakeholderDescription")}</p>
              </div>
            </div>
            {formModel.renderComponent()}
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-white"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showAddStakeholderGroupModal = () => {
    let formFields = getImpactStakeholderGroupFormFields(this.organisationProvider, this.organisationId);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          await this.impactProvider.addStakeholderGroups(this.organisationId, this.projectId, this.impact.id, res.stakeholderGroups);

          this.modalService.hide();
          this.loadImpact(this.impact.id);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.addStakeholderGroup")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-white"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  setCurrentView = (currentView: "individual" | "groups") => {
    this.currentView = currentView;
  };

  removeStakeholder = async (impactId: number, stakeholderId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.removeStakeholder(this.organisationId, this.projectId, impactId, stakeholderId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  showStakeholderConfirmDeleteModal = (impactId: number, stakeholder: FP.Entities.IStakeholder) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              {I18n.t("phrases.confirmRemove", { name: `${stakeholder.firstName} ${stakeholder.lastName}` })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeStakeholder(impactId, stakeholder.id);
          this.loadImpact(impactId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
