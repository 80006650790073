import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { TimelineView } from "../timeline/Timeline_view";
import { ImpactActionsTimelineModel } from "./ImpactActionsTimeline_model";

export interface ImpactActionsTimelineProps {
  model: ImpactActionsTimelineModel;
}

const ImpactActionsTimeline: React.FC<ImpactActionsTimelineProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount(organisationId, model.impactId);

    return model.onUnmount;
  }, [model, organisationId, model.impactId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return <TimelineView model={model.timelineModel} />;
});

export { ImpactActionsTimeline };
