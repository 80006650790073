import React from "react";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";
import { Panel } from "../Panel";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import AnimateHeight from "react-animate-height";

type SimpleAccordionProps = {
  className?: string;
  id?: string;
  header: React.ReactNode;
  children: React.ReactNode;
  isActive?: boolean;
  side?: "left" | "right";
  actions?: IUiAction<SimpleAccordion>[];
};

type SimpleAccordionState = {
  isActive: boolean;
};

export class SimpleAccordion extends React.Component<SimpleAccordionProps, SimpleAccordionState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isActive: this.props.isActive ? true : false
    };
  }

  toggleActive = () => {
    this.setState({
      isActive: !this.state.isActive
    });
  };

  render() {
    const { className, id, header, children, actions } = this.props;
    const { isActive } = this.state;

    return (
      <>
        <div id={id} className={`simple-accordion ${className || ""}`} data-active={isActive}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center py-3">
                  <div className="simple-accordion__icon" onClick={this.toggleActive}>
                    <Icon symbol={"ChevronDown"} />
                  </div>
                  <div className="simple-accodian__header" style={{ flexGrow: 1 }}>
                    {header}
                  </div>
                  {actions ? (
                    <div className="simple-accordion__actions">
                      <Tooltip
                        position="left"
                        shownElement={<Icon symbol="DotsHorizontal" />}
                        triggeredOn="click"
                        className="float-right"
                      >
                        <Panel background="bg-white" style={{ width: "140px" }} hasShadow={true}>
                          {actions.map(action => (
                            <UiActionComponent key={action.id} action={action} contextModel={this} />
                          ))}
                        </Panel>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="simple-accordion__children">
          <AnimateHeight height={isActive ? "auto" : 0}>{children}</AnimateHeight>
        </div>
      </>
    );
  }
}
