import { action, observable } from "mobx";
import { BaseModel } from "../../../core/util/BaseModel";
import * as _ from "lodash";
import { buildTree } from "../../../core/util/Helpers";
import { ICsvHelper } from "../../../services/local/csvHelper/CsvHelper";
import moment from "moment";

export interface HeatmapXAxisItem {
  key: string;
  label: React.ReactNode;
  parent?: React.ReactNode;
}

export interface HeatmapYAxisItem {
  key: string;
  // headerKey: string;
  parent: string;
  label: React.ReactNode;
  shouldShowChildren: boolean;
  depth: number;
  children: HeatmapYAxisItem[];
  total: number;
  className?: string;
}

export interface HeatmapDataValueItem {
  key: string;
  xAxisKey: string;
  yAxisKey: string;
  value: React.ReactNode;
  className: string;
}

export interface IHeatmapConfig {
  xAxisDomain: (data: any) => HeatmapXAxisItem[];
  yAxisDomain: (data: any) => HeatmapYAxisItem[];
  dataGenerator: (data: any) => HeatmapDataValueItem[];
  sidebarTitle: React.ReactNode;
  csvFilename: string;
  csvSideBarTitle: string;
  rotateHeader: boolean;
  csvHelper: ICsvHelper;
}

export class HeatmapModel extends BaseModel {
  onCellClick: (row: HeatmapYAxisItem, header: HeatmapXAxisItem, val: HeatmapDataValueItem | any) => void;
  xScaleItems: HeatmapXAxisItem[] = [];
  yScaleItems: HeatmapYAxisItem[] = [];
  @observable isLoading: boolean = true;
  @observable.ref headerGroups: Dictionary<HeatmapXAxisItem[]> = {};
  @observable.ref sidebarItems: HeatmapYAxisItem[] = [];
  @observable.ref heatmapData: HeatmapDataValueItem[] = [];

  config: IHeatmapConfig | any;
  totalFormatter: (value: number) => React.ReactNode;
  totalColumnHeaderFormatter: (val: string) => React.ReactNode;
  data: Dictionary<any> | any[] = {};
  @observable.ref highlightedCell: HeatmapDataValueItem | null = null;

  @action.bound
  clearHighlightedCell() {
    this.highlightedCell = null;
  }

  @action.bound
  highlightCell(cell: HeatmapDataValueItem) {
    this.highlightedCell = cell;
  }

  @action
  refresh = () => {
    if (this.data) {
      this.setXScaleItems();
    }
    this.isLoading = false;
  };

  setConfig = (config: Partial<IHeatmapConfig>) => {
    this.config = { ...this.config, ...config };
  };

  @action
  setData = (data: any[]) => {
    this.data = data;
    this.yScaleItems = buildTree(this.config.yAxisDomain(data), null, "key");
    this.sidebarItems = _.uniqBy(this.getSidebarItems(this.yScaleItems, []), "key");
    this.heatmapData = this.config.dataGenerator(data);
    this.refresh();
  };

  getSidebarItems = (yScaleItems: HeatmapYAxisItem[], result: any[]) => {
    yScaleItems.forEach(e => {
      let idx = result.findIndex(ee => ee.key === e.key);
      if (idx < 0) {
        result.push(e);
        if (e.shouldShowChildren && e.children) {
          this.getSidebarItems(e.children, result);
        }
      }
    });
    return result;
  };

  @action
  setXScaleItems = () => {
    if (this.data) {
      this.xScaleItems = this.config.xAxisDomain(this.data);
      this.headerGroups = _.groupBy(_.uniqBy(this.xScaleItems, "key"), "parent");
    } else {
      console.warn("Heatmap component: data object must be set to a value before executing 'setXScaleItems' function");
    }
  };

  @action
  onExpandableRowClick = (item: HeatmapYAxisItem) => {
    item.shouldShowChildren = !item.shouldShowChildren;
    let idx = this.sidebarItems.findIndex(e => e.key === item.key);
    if (idx < 0) {
      return;
    }
    let k = this.sidebarItems.slice();
    k.splice(idx, 1, item);
    let children = this.getChildren(item.key);
    if (item.shouldShowChildren) {
      k = this.getSidebarItems(k, []);
    } else {
      this.removeChildren(children, k);
    }
    this.sidebarItems = k.slice();
  };

  removeChildren = (list: any[], sidebarItems: any[]) => {
    if (list) {
      list.forEach(e => {
        this.removeChildren(e.children, sidebarItems);
        let kidx = sidebarItems.findIndex(kk => kk.key === e.key);
        if (kidx <= 0) return;
        sidebarItems.splice(kidx, 1);
      });
    }
  };

  getChildren = (key: string) => {
    let res: any;
    this.yScaleItems.forEach(e => {
      let k = this.searchTree(e, key);
      if (k && k.children) {
        res = k;
      }
    });
    return res?.children;
  };

  searchTree: (...args: any[]) => any = (element: HeatmapYAxisItem, key: any) => {
    if (element.key === key) {
      return element as any;
    }
    if (element.children) {
      var i;
      var result = null;
      for (i = 0; result === null && i < element.children.length; i++) {
        result = this.searchTree(element.children[i], key);
      }
      return result as any;
    }
    return null as any;
  };

  exportAsCsv = () => {
    const content = this.getCsvDataContent(this.sidebarItems);
    this.config.csvHelper.exportToCsv(`${this.config.csvFilename} - ${moment().format("L")}.csv`, content);
  }


  generateCsvHeader = (sideBarTitle: string, flatList) => {
    let topHeader = flatList.map((e: any) => e.parent);
    topHeader.unshift("");
    topHeader.unshift("");
    let bottomHeader = flatList.map((e: any) => e.label);
    bottomHeader.unshift("Total");
    bottomHeader.unshift(sideBarTitle);
    return [topHeader, bottomHeader];
  }

  getCsvDataContent = (sidebarItems: HeatmapYAxisItem[]) => {
    const flatList = _.flatMap(this.headerGroups);
    const header = this.generateCsvHeader(this.config.csvSideBarTitle, flatList);
    let s = sidebarItems.map(e => {
      const k = _.map(flatList, (header, i) => {
        const cell = _.find(this.heatmapData, ee => `${ee.xAxisKey}` === `${header.key}` && `${ee.yAxisKey}` === `${e.key}`);
        return !cell ? "0" : `${cell.value}`;
      })
      k.unshift(e.total + "")
      k.unshift(e.label + "")
      return k;
    })
    return [...header, ...s]
  }

  onMount = () => { };

  onUnmount = () => { };
}
