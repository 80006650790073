import * as React from "react";
import T from "../Table";
import * as _ from "lodash";
import { B } from "../../../core/util/BootstrapHelper";

export interface PlaceholderProps {
  className?: string;
  showIf: boolean;
  type: "Table" | "Card" | "Text" | "Circle";
  componentProps?: any;
}

export const Placeholder: React.FC<PlaceholderProps> = props => {
  const C: any = {
    TablePlaceholder
  };
  const K = C[props.type + "Placeholder"];
  return props.showIf ? <K {...props.componentProps} /> : <>{props.children}</>;
};

export interface TablePlaceholderProps {
  numberOfRows: number;
  numberOfColumns: number;
}

const TablePlaceholder: React.FC<TablePlaceholderProps> = props => {
  if (!props) {
    return null;
  }
  return (
    <T.Table>
      <T.Header>
        {_.range(0, props.numberOfColumns).map((e: any) => {
          return (
            <T.HeaderCol key={e}>
              <LinePlaceholder />
            </T.HeaderCol>
          );
        })}
      </T.Header>
      <T.Body>
        {_.range(0, props.numberOfRows).map((e: any, i: number) => {
          return (
            <T.Row key={e}>
              {_.range(0, props.numberOfColumns).map((e: any) => {
                return (
                  <T.Col key={e}>
                    <LinePlaceholder />
                  </T.Col>
                );
              })}
            </T.Row>
          );
        })}
      </T.Body>
    </T.Table>
  );
};

export const MultiLinePlaceholder: React.FunctionComponent<LinePlaceholderProps> = props => {
  return (
    <>
      <LinePlaceholder {...props} />
      <LinePlaceholder {...props} />
      <LinePlaceholder {...props} />
    </>
  );
};

export interface LinePlaceholderProps {
  type?: "primary" | "secondary";
  className?: string;
}

export const LinePlaceholder: React.FunctionComponent<LinePlaceholderProps> = props => {
  let cls = B()
    .bl("placeholder")
    .pmod("animated")
    .pmod("line")
    .pmod(props.type || "primary").bem;
  return <div className={`${cls} ${props.className || ""}`}></div>;
};
