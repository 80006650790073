import * as React from "react";
import { observer } from "mobx-react";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { EditImpactGroupViewModel } from "./EditImpactGroupView_model";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../AppService";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { useFlightPathUser } from "../../../../setup";
import { Animations } from "../../../../core/util/Animations";

export interface EditImpactGroupViewProps {
  // model?: EditImpactGroupViewModel;
}

const EditImpactGroupView: React.FunctionComponent<EditImpactGroupViewProps> = observer(() => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const { projectId, impactGroupId } = useParams<{ projectId: string; impactGroupId: string }>();
  const [model] = React.useState(
    () => new EditImpactGroupViewModel({ appService, user, projectId: +projectId, impactGroupId: +impactGroupId, organisationId })
  );

  React.useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  return (
    <Async promiseFn={model.loadImpactGroup}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <div className="impact-group-extended-view data-extended-view pt-6">
          <div className="container-fluid data-extended-view__block data-extended-view__block--header">
            <div className="row">
              <div className="col">
                <LinkButton
                  size={Enums.UiSizes.MD}
                  type="link-big"
                  className="p-0"
                  href={`/organisations/${organisationId}/projects/${model.projectId}/impactGroups/${model.impactGroupId}`}
                >
                  <Icon className="mr-2" symbol="ChevronLeft" />
                  {I18n.t("phrases.backToImpactGroup")}
                </LinkButton>
              </div>
            </div>
          </div>
          <div className="data-extended-view__block data-extended-view__block--wrapper">
            <div className={`data-extended-view__col pt-4 container-fluid ${Animations.FP_ZOOM_IN} speed-5`}>
              <div className="row mb-5">
                <div className="col-lg-7">
                  <h1>{I18n.t("phrases.editImpactGroup")}</h1>
                  <p className="mb-0">{I18n.t("phrases.editImpactGroupDescription")}</p>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-10">
                  <SingleForm model={model.formModel} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Async.Resolved>
    </Async>
  );
});

export { EditImpactGroupView };
