import { observer } from "mobx-react";
import * as React from "react";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { TimelineView } from "../timeline/Timeline_view";
import { ImpactTimelineModel } from "./ImpactTimeline_model";

export interface ImpactTimelineProps {
  model: ImpactTimelineModel;
}

const ImpactTimeline: React.FC<ImpactTimelineProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount(organisationId);

    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return <TimelineView model={model.timelineModel} />;
});

export { ImpactTimeline };
