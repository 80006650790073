import * as React from 'react';
import { BaseModel } from "../../../../../core/util/BaseModel";
import { TreeEditorModel } from "../../../../../components/widgets/treeEditor/TreeEditor_model";
import { buildTree } from "../../../../../core/util/Helpers";
import { observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { IModalService } from "../../../../../core/modal/IModalService";
import { Services } from "../../../../../constants";
import { IBusinessAreasApi } from '../../../../../services/api/v1/businessAreas/IBusinessAreas.api';
import { IOrganisationsApi } from '../../../../../services/api/v1/organisations/IOrganisations.api';
import I18n from '../../../../../core/localization/I18n';
import { Icon } from '../../../../../components/ui/Icon';
import { IHttpProgressModel } from '../../../../../core/httpProgress/HttpProgress_model';
import { IToasterService } from '../../../../../core/toaster/ToasterService';
import { BulkBusinessAreaUpdateModel } from '../../../stakeholders/bulkBusinessAreaUpdate/BulkBusinessAreaUpdate_model';
import { BulkBusinessAreaUpdate } from '../../../stakeholders/bulkBusinessAreaUpdate/BulkBusinessAreaUpdate_view';
import { IStakeholdersApi } from '../../../../../services/api/v1/stakeholders/IStakeholders.api';
import { IPermissionService } from '../../../../../services/local/permissionService/IPermissionService';
import { Animations } from '../../../../../core/util/Animations';
import { TOASTER_TOAST_TIME } from '../../../../../core/toaster/Toaster_model';

export class BusinessAreaModel extends BaseModel {
    appService: AppService

    modalService: IModalService;
    businessAreaProvider: IBusinessAreasApi;
    stakeholderProvider: IStakeholdersApi;
    organisationProvider: IOrganisationsApi;
    httpProgress: IHttpProgressModel;
    permissionService: IPermissionService;
    toasterService: IToasterService;
    @observable isLoading: boolean = true;
    @observable treeEditorModel: TreeEditorModel;
    @observable.ref organisation: FP.Entities.IOrganisation;
    id: string;
    /**
     *
     */
    constructor(appService: AppService, organisaiton: FP.Entities.IOrganisation) {
        super();
        this.appService = appService;
        this.organisation = organisaiton;
        this.id = "BusinessAreas";
        this.permissionService = this.appService.getService<IPermissionService>(Services.PermissionService);
        this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
        this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
        this.stakeholderProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
        this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
        this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
        this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    }

    onMount = () => {
        this.treeEditorModel = new TreeEditorModel(this.id);
        if (this.permissionService.canContributeOrg()) {
            this.treeEditorModel.onDelete = this.showDeleteConfirmationModal;
            this.treeEditorModel.onAdd = this.createNewBusinessArea;
            this.treeEditorModel.onUpdate = this.updateBusinessArea;
            this.treeEditorModel.onHierarchyChanged = this.updateBusinessArea;
        }

        this.loadBusinessAreas();
    }

    onUnmount = () => {
    }

    loadBusinessAreas = async () => {
        this.isLoading = true;
        const res = await this.organisationProvider.getBusinessAreas(this.organisation.id);

        if (!res || res.isError) {
            this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW)
                .setContent(I18n.t("errors.loadBusinessAreas"));
            return;
        }

        const mappedResults = res.payload.map(e => {
            return {
                ...e,
                title: e.name,
                parent: e.parent === 0 ? "" : e.parent
            }
        })

        const treeData = buildTree(mappedResults);

        this.treeEditorModel.treeData = treeData;
        this.isLoading = false;
    }

    expandAll = () => {
        this.treeEditorModel.setExpand(true)
    }

    collapseAll = () => {
        this.treeEditorModel.setExpand(false)
    }

    performTreeSearch = (ev: React.FormEvent<HTMLInputElement>) => {
        this.treeEditorModel.searchValue = ev.currentTarget.value;
    }

    updateBusinessArea = (node: FP.Entities.IBusinessArea, path: string[] | number[]): Promise<any> => {
        return new Promise(async (resolve) => {
            node.parent = node.parent || 0;
            node.organisationId = this.organisation.id;

            this.httpProgress.showOverlay();
            const res = await this.businessAreaProvider.update(this.organisation.id, node.id, node);
            this.httpProgress.hideOverlay();

            if (!res || res.isError) {
                this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW)
                    .setContent(I18n.t("errors.updateBusinessArea"));
                return;
            }

            this.treeEditorModel.updateNode({
                ...node,
                ...res.payload,
                title: res.payload.name
            }, path);
            resolve(res);
        })
    }

    showDeleteConfirmationModal = (node: FP.Entities.IBusinessArea & {
        children: FP.Entities.IBusinessArea[],
        title: string;
    }, path: string[] | number[]) => {
        if (!node.id) {
            this.treeEditorModel.removeNode(node, path);
            return;
        }

        return new Promise((resolve) => {
            this.modalService.showConfirmDialog(<h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-12">
                            <Icon symbol="alert-circle" className="mr-2" />
                            {I18n.t("warnings.removeBusinessArea")}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {I18n.t("phrases.confirmDelete", { name: node.title })}
                        </div>
                    </div>
                </div>,
                I18n.t("phrases.yes"),
                I18n.t("phrases.no"),
                {
                    wrapWidth: "small",
                    spacing: "small",
                    position: "middle",
                    panelProps: {
                        background: "bg-white",
                    }
                },
                () => {
                    this.businessAreaProvider.remove(this.organisation.id, node.id)
                    this.treeEditorModel.removeNode(node, path);
                    this.modalService.hide();
                    resolve({ node, path });
                },
                () => {
                    this.modalService.hide();
                },
                'danger',
            )
        })
    }

    getBulkUpdateContent = async (node: FP.Entities.IBusinessArea & {
        children: FP.Entities.IBusinessArea[],
        title: string
    }, path: string[] | number[], resolve) => {
        const res = await this.businessAreaProvider.getHierarchyStakeholders(this.organisation.id, node.id)
        if (!res || res.isError) return;
        const stakeholders = res.payload;
        if (stakeholders.length > 0) {
            const bulkBusinessAreaModel =
                new BulkBusinessAreaUpdateModel(this.appService, node, stakeholders);

            this.modalService.show({
                showClose: false,
                title: (<h1>{`${I18n.t("phrases.deleteBusinessArea")} | ${I18n.t("phrases.accountManager")}`}</h1>),
                content: <BulkBusinessAreaUpdate model={bulkBusinessAreaModel} />,
                componentProps: {
                    wrapHeight: "full",
                    wrapWidth: "large",
                    spacing: "medium",
                    position: "right",
                    panelProps: {
                        background: "bg-white"
                    },

                },
                animationOptions: {
                    animateIn: Animations.SLIDE_IN_RIGHT,
                    animateOut: Animations.SLIDE_OUT_RIGHT,
                    speed: 5
                },
                actions: [
                    {
                        id: 'save',
                        label: I18n.t("phrases.save"),
                        onAction: async () => {
                            await bulkBusinessAreaModel.updateStakeholders();
                            if (node.id) {
                                this.modalService.hideAll();
                                this.httpProgress.showOverlay();
                                const res = await this.businessAreaProvider.remove(this.organisation.id, node.id);
                                this.httpProgress.hideOverlay();

                                if (!res || res.isError) {
                                    this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW)
                                        .setContent(I18n.t("errors.deleteBusinessArea"));
                                    return;
                                }
                            }
                            this.treeEditorModel.removeNode(node, path);
                            resolve({ node, path });
                        },
                        rendersIn: "Button",
                        componentProps: {
                            type: "danger",
                            className: "float-right"
                        }
                    },
                    {
                        id: 'cancel',
                        label: I18n.t("phrases.cancel"),
                        onAction: () => this.modalService.hide(),
                        rendersIn: "Button",
                        componentProps: {
                            type: "link",
                            className: "float-right"
                        }
                    }
                ]
            })
        } else {
            if (node.id) {
                this.modalService.hide();
                this.httpProgress.showOverlay();
                const res = await this.businessAreaProvider.remove(this.organisation.id, node.id);
                this.httpProgress.hideOverlay();

                if (!res || res.isError) {
                    this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW)
                        .setContent(I18n.t("errors.deleteBusinessArea"));
                    return;
                }
            }
            this.treeEditorModel.removeNode(node, path);
            resolve({ node, path });
        }
    }

    addParentTreeNode = () => {
        this.collapseAll();
        this.treeEditorModel.addNode();
    }

    createNewBusinessArea = async (node: FP.Entities.IBusinessArea & {
        children: FP.Entities.IBusinessArea[],
        title: string;
    }, path: string[] | number[], treeIndex?: number | string): Promise<any> => {
        return new Promise(async (resolve, rej) => {
            node.organisationId = this.organisation.id;
            node.parentBusinessAreaId = node.parent;
            this.httpProgress.showOverlay();
            const res = await this.businessAreaProvider.create(this.organisation.id, node);
            this.httpProgress.hideOverlay();

            if (!res || res.isError) {
                this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW)
                    .setContent(I18n.t("errors.createBusinessArea"));
                resolve(false);
                return;
            }
            this.treeEditorModel.updateNode({
                ...res.payload,
                title: res.payload.name
            }, path ? [...path, treeIndex as any] : [treeIndex]);
            resolve(true)
        })
    }

    showEditFormModal = (businessArea: FP.Entities.IBusinessArea) => {

    }
}