import React from "react";
import { observer } from "mobx-react";
import { Panel } from "../../ui/Panel";
import moment from "moment";
import AnimateHeight from "react-animate-height";
import I18n from "../../../core/localization/I18n";
import { RevisionHistoryCardModel } from "../../../pages/change/revisionHistory/RevisionHistoryCard_model";
import { TableView } from "../../../core/table/Table_view";
import { Icon } from "../../ui/Icon";
import { RenderXssSafeString } from "../../../core/AntiXss/AntiXssHelper";

export interface RevisionHistoryCardProps {
  model: RevisionHistoryCardModel;
}

const RevisionHistoryCard: React.FunctionComponent<RevisionHistoryCardProps> = observer(({ model }) => {
  const { revision } = model;
  const user = revision.changedBy
    ? `${revision.changedBy.firstName} ${revision.changedBy.lastName}`
    : I18n.t("phrases.system");
  const changePhrase = I18n.t(
    revision.changes.length > 1 ? "phrases.userChangedFieldAndOthers" : "phrases.userChangedField",
    {
      user,
      field: revision.changes[0]?.property.split(/(?=[A-Z])/).join(" "),
      num: revision.changes.length - 1,
      flabel: revision.changes.length > 2 ? "fields" : "field"
    }
  );

  return (
    <Panel className="activity-card__panel p-4" background="bg-white" hasBorderRadius={true}>
      <div className="row" onClick={model.toggleExpanded} style={{ cursor: "pointer" }}>
        <div className="col">
          <div className="d-flex">
            <div>
              <h4 className="mb-1">{moment(revision.changedAt).format("L @ HH:mm")}</h4>
              <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(changePhrase) }}></div>
            </div>
            <Icon className="ml-auto" symbol={model.isExpanded ? "ChevronUp" : "ChevronDown"} />
          </div>
        </div>
      </div>

      <AnimateHeight height={model.isExpanded ? "auto" : 0}>
        <div className="row mt-3">
          <div className="col">
            <TableView model={model.tableModel} />
          </div>
        </div>
      </AnimateHeight>
    </Panel>
  );
});

export { RevisionHistoryCard };
