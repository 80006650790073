import React, { useEffect } from "react";
import { RevisionHistoryModel } from "./RevisionHistory_model";
import { observer } from "mobx-react";
import { RevisionHistoryCard } from "../../../components/widgets/RevisionHistoryCard/RevisionHistoryCard";
import { RevisionHistoryCardModel } from "./RevisionHistoryCard_model";
import I18n from "../../../core/localization/I18n";
import { PositionedSpinner } from "../../../components/ui/PositionedSpinner";
import { Enums } from "../../../enums";
import { RevisionCreate } from "./RevisionCreate_view";
import { RevisionCreateModel } from "./RevisionCreate_model";
import { Animations } from "../../../core/util/Animations";

export interface RevisionHistoryViewProps {
  model: RevisionHistoryModel;
}

const RevisionHistoryView: React.FunctionComponent<RevisionHistoryViewProps> = observer(({ model }) => {
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  if (model.errorMessage) return <div>{model.errorMessage}</div>;

  return (
    <div className={`activity-list pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h2>{I18n.t("phrases.revisionHistory")}</h2>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            {model.revisions.length ? (
              model.revisions.map((revision, i) => {
                switch (revision.changeEventType) {
                  case Enums.ChangeEventType.UPDATE:
                    const revisionModel = new RevisionHistoryCardModel(revision);
                    return (
                      <div key={i} className="mb-2">
                        <RevisionHistoryCard model={revisionModel} />
                      </div>
                    );

                  case Enums.ChangeEventType.CREATE:
                    const model = new RevisionCreateModel(revision);
                    return (
                      <div className="mb-2" key={i}>
                        <RevisionCreate model={model} />
                      </div>
                    );
                  default:
                    return null;
                }
              })
            ) : (
                <div>{I18n.t("phrases.noRevisionHistory")}</div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { RevisionHistoryView };
