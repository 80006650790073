import * as React from "react";
import { HeatmapModel } from "./Heatmap_model";
import * as _ from "lodash";
import { Icon } from "../../ui/Icon";
import { observer } from "mobx-react";
import I18n from "../../../core/localization/I18n";

export interface HeatmapSidebarProps {
  model: HeatmapModel;
}

const HeatmapSidebar: React.FC<HeatmapSidebarProps> = observer(({ model }) => {
  let { sidebarItems } = model;

  return (
    <div className="heatmap__sidebar-content">
      <div className="heatmap__sidebar-row heatmap__sidebar-row--header">
        <div className="heatmap__sidebar-title px-3">
          {model.config.sidebarTitle}
        </div>
        <div className="heatmap__sidebar-total heatmap__sidebar-total--header">
          <h4 className="mb-0 px-3">
            {model.totalColumnHeaderFormatter
              ? model.totalColumnHeaderFormatter(I18n.t("phrases.total"))
              : I18n.t("phrases.total")}
          </h4>
        </div>
      </div>
      {_.map(sidebarItems, (e, i) => {
        const content = e.children && (
          <span className="d-inline-block">
            <Icon symbol={e.shouldShowChildren ? "ChevronUp" : "ChevronDown"} className="mr-2" />
          </span>
        );
        return (
          <div
            className="heatmap__sidebar-row"
            onClick={() => model.onExpandableRowClick(e)}
            key={e.key}
            style={{
              opacity: model.highlightedCell && `${e.key}` !== `${model.highlightedCell.yAxisKey}` ? "0.3" : "1"
            }}
          >
            <div className="heatmap__sidebar-title px-3">
              <p className="mb-0 text-truncate heatmap__sidebar-title-p" style={{ paddingLeft: e.depth * 15 }}>
                {content || <Icon symbol="ChevronRight" className="mr-2 text-white" />}
                {e.label}
              </p>
            </div>
            <div className={`heatmap__sidebar-total ${e.className || ""}`}>
              <p className="mb-0 px-3">{model.totalFormatter ? model.totalFormatter(e.total) : e.total}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export { HeatmapSidebar };
