import { INIT_FORM_FIELD } from "../../formField/FormField_init";
import { ICheckboxSliderModel } from "./ICheckboxSliderModel";
import { FormFieldType } from "../../formField/FormFieldTypes";

export const INIT_CHECKBOX_SLIDER_FIELD: Partial<ICheckboxSliderModel> = {
  ...INIT_FORM_FIELD,
  type: FormFieldType.CheckboxSlider,
  checked: false,
  isDefaultValueUnknown: true,
  validate: () => {
    return true;
  }
};
