
import * as React from 'react';
import { B } from '../../../core/util/BootstrapHelper';

export interface PanelProps extends React.HTMLProps<HTMLDivElement> {
    type?: "border-left-primary" | "border-left-secondary" | "border-lines-y" | "border-lines" |
    "border-top-danger" | "border-top-warning" | "border-top-primary" | "border-top-secondary"
    hasBorderRadius?: boolean;
    hasShadow?: boolean;
    background?: "bg-light" | "bg-primary-light-5" | "bg-white"
}


export const Panel: React.FunctionComponent<PanelProps> = (props) => {
    let cls = B().bl("panel")
        .add(props.background || "")
        .pmod(props.type || "")
        .pmod(props.hasBorderRadius ? "smooth-border" : "")
        .pmod(props.hasShadow ? "shadow" : "")

    let prop = { ...props };
    delete prop.hasShadow;
    delete prop.hasBorderRadius;
    delete prop.background;

    return <div
        {...prop}
        className={`${cls.bem} ${props.className || ""}`}>
        {props.children}
    </div>
};