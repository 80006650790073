import { BaseModel } from "../../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { IModalService } from "../../../../core/modal/IModalService";

export class StakeholderCompactViewModel extends BaseModel {
    appService: AppService;
    projectStakeholder: FP.Entities.IProjectStakeholder;
    projectProvider: IProjectsApi;
    modalService: IModalService;
    @observable isLoadingImpacts: boolean = true;
    @observable.ref stakeholder: FP.Entities.IStakeholder;
    @observable.ref impacts: FP.Entities.IImpact[] = [];
    organisationId: number;

    constructor(appService: AppService, organisationId: number, pStakeholder?: FP.Entities.IProjectStakeholder) {
        super();
        this.appService = appService;
        this.organisationId = organisationId;
        this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
        this.modalService = appService.getService<IModalService>(Services.AsideModalService);
        if (pStakeholder) {
            this.setProjectStakeholder(pStakeholder);
            this.loadStakeholderImpacts(pStakeholder.id);
        }
    }

    loadStakeholderImpacts = async (projectStakeholderId: number) => {
        let res = await this.projectProvider.getProjectStakeholderImpacts(this.organisationId, this.projectStakeholder.project.id, projectStakeholderId);
        if (!res || res.isError) return;

        this.setImpacts(res.payload);
        this.isLoadingImpacts = false;
    }

    @action
    setImpacts = (impacts: FP.Entities.IImpact[]) => {
        this.impacts = impacts;
    }

    @action
    setProjectStakeholder = (pStakeholder: FP.Entities.IProjectStakeholder) => {
        this.projectStakeholder = pStakeholder;
        this.stakeholder = this.projectStakeholder.stakeholder || null;
    }
}