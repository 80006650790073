import * as React from "react";
import { observer } from "mobx-react";
import { IFormFieldModel } from "./IFormField";
import { FormField as FormFieldView } from "../../../components/ui/_forms/FormField";

export interface IFormFieldProps {
  model: IFormFieldModel<any, any>;
  key?: string;
}

export const FormField: React.FC<IFormFieldProps> = observer(({ model }) => {
  return (
    <FormFieldView
      key={model.key}
      sKey={model.key}
      label={model.label}
      isHidden={model.isHidden as any}
      className={model.fieldClassName as any}
      type={model.type}
      isValid={model.isValid as any}
      disabled={model.disabled}
      isLabelHidden={model.isLabelHidden as any}
      errorMessage={model.errorMessage}
      tooltipLabel={model.tooltipLabel}
      hintLabel={model.hintLabel}
      manageLink={model.manageLink}
    >
      {model.renderComponent()}
    </FormFieldView>
  );
});
