import { FormFieldModel } from "../../formField/FormField_model";
import { ICheckboxSliderModel } from "./ICheckboxSliderModel";
import { Slider } from "../../../../components/ui/_forms/Slider";
import React from "react";
import { action, observable } from "mobx";
import { Checkbox } from "../../../../components/ui/Checkbox";
import I18n from "../../../localization/I18n";

export interface CheckboxSlider {}

export class CheckboxSliderModel extends FormFieldModel<number, CheckboxSlider> implements ICheckboxSliderModel {
  checked: boolean;
  testId?: string;
  @observable value: number;
  @observable isUnknown: boolean;
  isDefaultValueUnknown: boolean;
  validate: (...args: any[]) => any;

  /**
   *
   */
  constructor(initOpts?: ICheckboxSliderModel) {
    super(initOpts);
    if (initOpts) {
      this.isUnknown = (initOpts.isDefaultValueUnknown && !initOpts.value && !initOpts.defaultValue) || initOpts.value === -1;
      this.value = initOpts.value || this.value || this.defaultValue;
      this.validate = initOpts.validate || (this.validate as any);
      this.defaultValue = initOpts.defaultValue ?? 0;
    }
  }

  @action
  setValue = (val: number) => {
    this.value = val;

    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  };

  @action
  setValueThroughCheckfield = (e: React.SyntheticEvent<any>) => {
    const val = e.currentTarget as HTMLInputElement;
    this.isUnknown = val.checked;
    this.setValue(this.getSliderValue(val.checked));
  };

  getSliderValue = (isChecked: boolean): number => {
    // If the value is not set, then use the default
    if (this.value === null || this.value === undefined) {
      return this.defaultValue;
    }

    // If the "Unknown" checkbox is not ticked but the value is unknown (-1) the set the default value
    if(isChecked === false && this.value === -1){
      return this.defaultValue;
    }

    // Otherwise use the stored value
    return  this.value;
  };

  setFieldValue = (e: React.SyntheticEvent<any>) => {
    const val = e.currentTarget as HTMLInputElement;
    this.setValue(val.checked ? -1 : this.value);
  };

  extractValue = () => {
    return this.isUnknown ? -1 : this.value;
  };

  reset(): void {
    this.isUnknown = true;
    this.value = null;
  }

  @action
  sliderChange = (ev, value) => {
    this.setValue(value as number);
    this.isUnknown = value > 0 ? false : true;
  };

  renderComponent = () => {
    let props = {
      className: `form-control ${this.className || ""}`,
      id: this.key,
      onChange: this.setValueThroughCheckfield,
      disabled: this.disabled,
      checked: this.isUnknown
    };
    return (
      <>
        <div className="checkbox-slider">
          <div className="d-flex" style={{ width: "100%" }}>
            <div style={{ width: "110px", height: "40px", display: "flex", alignItems: "center" }}>
              <p className="mb-0 d-inline">Unknown</p>
              <Checkbox {...props} checked={this.isUnknown} className="mt-1" />
            </div>
            <Slider
              {...this.componentProps}
              value={this.value}
              style={{ width: "100%" }}
              // @ts-ignore
              onChange={this.sliderChange}
              isUnknownValue={this.isUnknown}
            />
          </div>
          {this.isUnknown && (
            <div className="unknown-note">{I18n.t("forms.checkBoxSliderUnknownNote")}</div>
          )}
        </div>
      </>
    );
  };
}
