import { BaseModel, IModel } from "./BaseModel";


export interface IDisposableModel extends IModel {
    addDisposer: (dis: () => void) => void;
    dispose: () => void;

    destroyModel?: () => void;
}

export abstract class DisposableModel extends BaseModel implements IDisposableModel {
    private disposers: (() => void)[];

    constructor() {
        super();
        this.disposers = [];
    }

    addDisposer = (dis: () => void) => {
        this.disposers.push(dis);
    }

    dispose = () => {
        if (this.disposers && this.disposers.length) {
            var d: () => void = null;
            while ((d = this.disposers.shift())) {
                d();
            }
        }
    }

    destroyModel?: () => void;

}