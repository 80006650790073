import * as React from "react";
import { Button } from "../../../components/ui/Button";
import I18n from "../../../core/localization/I18n";
import { useIntercom } from "react-use-intercom";
import { useFlightPathUser } from "../../../setup";
import { Enums } from "../../../enums";
import { IIntercomLiveChatProps } from "./IntercomLiveChat";

interface IIntercomLiveChatButtonProps {
  intercomLiveChatProps: IIntercomLiveChatProps;
}

const IntercomLiveChatButton: React.FunctionComponent<IIntercomLiveChatProps> = props => {
  const { boot, show } = useIntercom();
  const user = useFlightPathUser();
  const environment = process?.env?.NODE_ENV;

  const bootWithProps = React.useCallback(() => {
    boot({
      userId: user.id.toString(),
      name: user.firstName,
      email: user.email,
      customAttributes: {
        organisation: props.currentOrganisationName,
        organisationId: props.currentOrganisationId,
        environment: environment
      }
    });
    show();
  }, [
    boot,
    show,
    user.firstName,
    user.email,
    user.id,
    props.currentOrganisationId,
    props.currentOrganisationName,
    environment
  ]);

  return (
    <Button id="IntercomChatTrigger" type="link" size={Enums.UiSizes.XS} onClick={bootWithProps}>
      {I18n.t("phrases.chatWithMember")}
    </Button>
  );
};

export default IntercomLiveChatButton;
