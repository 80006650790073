export const IsDev = () => {
  return window.appConfig.appEnvironment === "local" || window.appConfig.appEnvironment === "dev";
};

export const IsStaging = () => {
  return window.appConfig.appEnvironment === "stg";
};

export const IsProduction = () => {
  return window.appConfig.appEnvironment === "demo" || window.appConfig.appEnvironment === "pdn";
};
