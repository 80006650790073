import React from "react";
import { observable, get, set, IObservableObject } from "mobx";
import { observer } from "mobx-react";

type Key = string;

type PortalDictionary = IObservableObject;

const PortalContext = React.createContext<PortalDictionary>(null as any);

export const UiPortalsProvider: React.FC<{}> = ({ children }) => {
  const [portals] = React.useState(() => observable({}, {}, { deep: false }));
  return <PortalContext.Provider value={portals}>{children}</PortalContext.Provider>;
};

export const UiPortal: React.FC<{ name: string }> = observer(({ name, children }: { name: Key; children?: any }) => {
  const portals = React.useContext(PortalContext);
  return get(portals, name) || children || null;
});

export const UiPortalContent: React.FC<{ name: string }> = ({ name, children }) => {
  const portals = React.useContext(PortalContext);
  React.useEffect(() => {
    if (get(portals, name)) {
      throw new Error(`Content for portal with name ${name} is already set`);
    }

    set(portals, name, children);

    return () => {
      set(portals, name, null);
    };
  }, [children, portals, name]);
  return null;
};
