import React from "react";
import { Helmet } from "react-helmet";
import { IPageHeaderApi } from "./IPageHeader";
import I18n from "../../../core/localization/I18n";

export class PageHeaderApi implements IPageHeaderApi {
  setPageTitle = (title: string): React.ReactNode => {
    return (
      <Helmet>
        <title>{I18n.t("phrases.flightPath") + " - " + title}</title>
      </Helmet>
    );
  };
}

export const setPageTitle = (title: string): React.ReactNode => {
  return (
    <Helmet>
      <title>{I18n.t("phrases.flightPath") + " - " + title}</title>
    </Helmet>
  );
};

export const PageTitle: React.FC<{ title: string }> = props => {
  return (
    <Helmet>
      <title data-testid="page-title">{I18n.t("phrases.flightPath") + " - " + props.title}</title>
    </Helmet>
  );
};
