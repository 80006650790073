import * as React from "react";
import { IntercomProvider } from 'react-use-intercom';
import IntercomLiveChatButton from "./IntercomLiveChatButton";

export interface IIntercomLiveChatProps {
  currentOrganisationName: string;
  currentOrganisationId: number;
}

const IntercomLiveChat: React.FunctionComponent<IIntercomLiveChatProps> = props => {
  const INTERCOM_APP_ID = window.appConfig.intercom_App_Id;

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <IntercomLiveChatButton {...props} />
    </IntercomProvider>
  );
};

export default IntercomLiveChat;
