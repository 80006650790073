import * as React from "react";
import { useHistory } from "react-router-dom";
import { B } from "../../../core/util/BootstrapHelper";

export interface TabsProps {
  /**
   * Main bem class name
   */
  bemClassName?: string;

  /**
   * Main element class name
   */
  className?: string;

  /**
   * The class name of the ul list
   */
  ulClassName?: string;

  /**
   * The class name of the list element
   */
  liClassName?: string;

  /**
   * the class name of the tab content
   */
  contentClassName?: string;

  /**
   * Initial tab to be shown
   */
  initialTab?: number;

  /**
   * Function that is being triggered when the initialTab is changed
   */
  onInitialTabChange?: (currentIdx: number) => void;

  /**
   * Function that is being triggered after the index is changed
   */
  afterTabChange?: (currentIdx: number, prevIdx: number) => void;

  /**
   * Function that is being triggered when the tab indexed is being changed
   */
  onTabClicked?: (idx?: number, prevIdx?: number) => void;

  /**
   * If set to true the display of the current tab will be based on css styles instead of render options
   */
  isSeoFriendly?: boolean;
}

export interface TabsState {
  currentTabIdx: number;
}

var cls = B();

export class Tabs extends React.Component<any, TabsState> {
  state = { currentTabIdx: 0 };

  public static defaultProps: Partial<TabsProps> = {
    onTabClicked: () => {},
    onInitialTabChange: () => {},
    afterTabChange: () => {},
    bemClassName: "tabs",
    ulClassName: "",
    className: "",
    contentClassName: "",
    liClassName: "",
    isSeoFriendly: false
  };

  static getDerivedStateFromProps(props: any, state: any) {
    cls.bl(props.bemClassName);
    return null;
  }

  componentDidMount() {
    this.setState({
      currentTabIdx: this.props.initialTab || 0
    });
  }

  componentDidUpdate(prevProps: TabsProps, prevState: TabsState) {
    if (prevProps.initialTab !== this.props.initialTab) {
      this.afterTabChange(prevState.currentTabIdx);
    }
  }

  onInitialTabChange = () => {
    this.props.onInitialTabChange && this.props.onInitialTabChange(this.state.currentTabIdx);
  };

  afterTabChange = (prevIdx: number) => {
    this.props.afterTabChange && this.props.afterTabChange(this.state.currentTabIdx, prevIdx);
  };

  setTab = (idx: number) => {
    this.props.onTabClicked(idx, this.state.currentTabIdx);
    this.setState({
      currentTabIdx: idx
    });
  };

  getTitle = (title: string | React.ReactNode) => {
    if (typeof title === "string") {
      return <span>{title}</span>;
    } else if (React.isValidElement(title)) {
      return title;
    }
  };

  render() {
    let props = this.props,
      state = this.state;
    return (
      <div className={`${cls.bem} ${props.className}`}>
        <ul className={`${cls.el("menu").bem} ${props.ulClassName}`}>
          {React.Children.map(this.props.children, (x, i) => {
            return x !== null && (
              <li
                className={`${cls.el("item").bem} ${props.liClassName} ${
                  state.currentTabIdx === i ? cls.el("item").mod("active").bem : ""
                }`}
                onClick={() => {
                  this.setTab(i);
                }}
              >
                {this.getTitle((x as any).props.title)}
              </li>
            );
          })}
        </ul>
        <div className={`${cls.el("content").bem} ${props.contentClassName}`}>
          {(!props.isSeoFriendly && (this.props.children as any)[state.currentTabIdx]) ||
            React.Children.map(this.props.children as any, (child: React.ReactElement<any>, i) => {
              return React.cloneElement(child, {
                ...child.props,
                style: { display: state.currentTabIdx === i ? "block" : "none" }
              });
            })}
        </div>
      </div>
    );
  }
}

const TabsContext = React.createContext({ activeTabKey: null, setActiveTabKey: (tabKey: string) => {} });

export const TabsProvider: React.FC = ({ children }) => {
  const [activeTabKey, setActiveTabKey] = React.useState<string | null>(null);
  return <TabsContext.Provider value={{ setActiveTabKey, activeTabKey }}>{children}</TabsContext.Provider>;
};

export function useTabContext() {
  return React.useContext(TabsContext);
}

export function useSetActiveTabOnMount(tabKey: string) {
  const { setActiveTabKey } = React.useContext(TabsContext);

  React.useEffect(() => {
    setActiveTabKey(tabKey);
  }, [tabKey, setActiveTabKey]);
}

interface ITabDefinition {
  id: string;
  className?: string;
  title: React.ReactNode;
  href?: string;
  onClick?: () => void;
  content?: React.ReactNode | ((props) => React.ReactNode);
}

export const TabsAlternative: React.FC<{
  className?: string;
  ulClassName?: string;
  liClassName?: string;
  tabs: ITabDefinition[];
}> = props => {
  const cls = B().bl("tabs");
  const { setActiveTabKey, activeTabKey } = useTabContext();
  const history = useHistory();
  return (
    <div className={`${cls.bem} ${props.className || ""}`}>
      <ul className={`${cls.el("menu").bem} ${props.ulClassName || ""}`}>
        {props.tabs.map(tab => (
          <li
            key={tab.id}
            className={`${cls.el("item").bem} ${props.liClassName} ${tab.className} ${
              tab.id === activeTabKey ? cls.el("item").mod("active").bem : ""
            }`}
            onClick={() => {
              if (tab.href) {
                history.push(tab.href);
                return;
              }
              setActiveTabKey(tab.id);
            }}
          >
            {typeof tab.title === "string" ? <span>{tab.title}</span> : tab.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const TabContent: React.FC<{ tabId: string }> = ({ tabId, children }) => {
  useSetActiveTabOnMount(tabId);
  return children as any;
};

export interface TabProps {
  /**
   * Tab panel class name
   */
  className?: string;

  /**
   * The title name of the tab button
   */
  title: string | React.ReactNode;

  style: any;
}

export interface TabState {}

export class Tab extends React.Component<TabProps, TabState> {
  public static defaultProps: Partial<TabProps> = {
    className: ""
  };

  getTitle = () => {
    return this.props.title;
  };

  render() {
    const { props } = this;

    return (
      <div className={`tab-panel ${props.className}`} style={props.style} data-tab-title={this.props.title}>
        {this.props.children}
      </div>
    );
  }
}
