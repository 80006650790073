export enum FormFieldType {
    Text = 0x01,
    Autocomplete = 0x02,
    Checkbox = 0x03,
    Dropdown = 0x04,
    ImageCropper = 0x05,
    CheckboxList = 0x06,
    RadioButtonList = 0x07,
    DatePicker = 0x08,
    Listing = 0x09,
    Slider = 0x10,
    RTEditor = 0x0A,
    Multiselect = 0x0B,
    Multiselector = 0x0C,
    MultiInput = 0x0D,
    CheckboxSlider = 0x0E
}
