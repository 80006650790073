export interface ICsvHelper {
  exportToCsv: (filename: string, data: any[]) => void;
}

export class CsvHelper implements ICsvHelper {
  exportToCsv(filename: string, data: any[]) {
    let dataString;
    var csvContent = "data:text/csv;charset=utf-8,";
    data.forEach(function (infoArray, index) {
      let safeArray = MakeArraySafe(infoArray);

      dataString = safeArray.join(",");
      csvContent += index < data.length ? dataString + "\n" : dataString;
    });
    var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
}

function MakeArraySafe(infoArray: any[]): any[] {
  let result = [];

  if (infoArray !== null && infoArray !== undefined) {
    for (let i = 0; i < infoArray.length; i++) {
      result.push(EscapeUnsafeStrings(infoArray[i]));
    }
  }

  return result;
}

function EscapeUnsafeStrings(info: string): string {
  info += "";
  let result = info;

  if (info !== null && info !== undefined) {
    let specialCharacters = ["=", "+", "-", "@"];

    for (let i = 0; i < specialCharacters.length; i++) {
      if (info.startsWith(specialCharacters[i])) {
        result = `'${info}`;
      }
    }

    if (info.startsWith("|")) {
      result = `\${x}`;
    }
  }

  return result;
}
