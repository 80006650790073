import React, { useCallback, useState } from "react";
import { ProjectViewModel } from "./View_model";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { Panel } from "../../../../components/ui/Panel";
import { observer } from "mobx-react";
import { Timeline } from "../../../../components/widgets/TimelineWidget";
import { LinkButton, Button } from "../../../../components/ui/Button";
import I18n from "../../../../core/localization/I18n";
import { TimelineView } from "../../../../components/widgets/timeline/Timeline_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import {
  ProjectInsightCard,
  ProjectInsight
} from "../../../../components/widgets/projects/ProjectInsightCard/ProjectInsightCard_view";
import { Enums } from "../../../../enums";
import { IF } from "../../../../components/hoc/If";
import { Icon } from "../../../../components/ui/Icon";
import { Pill } from "../../../../components/ui/Pill";
import moment from "moment";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { Link, useParams } from "react-router-dom";
import { ChangeIndicator } from "../../../../components/widgets/ChangeIndicator";
import { percentageChange, convertStakeholderToName } from "../../../../core/util/Helpers";
import _ from "lodash";
import { useAppService } from "../../../../AppService";
import { Async } from "react-async";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { Animations } from "../../../../core/util/Animations";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface IProjectView {
  model?: ProjectViewModel;
}

export const ProjectView: React.FunctionComponent<IProjectView> = observer(({ model: m }) => {
  const appService = useAppService();
  const { projectId, organisationId } = useParams<{ projectId: string; organisationId: string }>();
  const [model, setModel] = useState(() => m || new ProjectViewModel(appService, +projectId, + organisationId));
  const { insights, project } = model;
  const [showAllKeyStakeholders, setShowAllKeyStakeholders] = useState(false);
  const [showAllKeyAudienceStakeholders, setShowAllKeyAudienceStakeholders] = useState(false);
  const [showAllKeySystemUsers, setShowAllKeySystemUsers] = useState(false);

  const keyStakeholders =
    project?.keyStakeholders?.filter(e => e.stakeholderType === Enums.StakeholderType.INDIVIDUAL) || [];
  const keyAudienceStakeholders =
    project?.keyStakeholders?.filter(e => e.stakeholderType === Enums.StakeholderType.AUDIENCE) || [];

  const projectInsights: ProjectInsight[] = [
    {
      key: "totalImpacts",
      label: I18n.t("phrases.totalImpacts"),
      value: insights?.current?.impactsTotal,
      footer: (
        <ChangeIndicator
          changeType={percentageChange(insights?.historic?.impactsTotal, insights?.current?.impactsTotal).type}
          changeValue={_.round(
            percentageChange(insights?.historic?.impactsTotal, insights?.current?.impactsTotal).value,
            2
          )}
          changeSymbol="%"
        />
      )
    },
    {
      key: "impactsMitigated",
      label: I18n.t("phrases.impactsMitigated"),
      value: insights?.current?.impactsMitigated,
      footer: (
        <ChangeIndicator
          changeType={percentageChange(insights?.historic?.impactsMitigated, insights?.current?.impactsMitigated).type}
          changeValue={_.round(
            percentageChange(insights?.historic?.impactsMitigated, insights?.current?.impactsMitigated).value,
            2
          )}
          changeSymbol="%"
        />
      )
    },
    {
      key: "totalActions",
      label: I18n.t("phrases.totalActions"),
      value: insights?.current?.actionsTotal,
      footer: (
        <ChangeIndicator
          changeType={percentageChange(insights?.historic?.actionsTotal, insights?.current?.actionsTotal).type}
          changeValue={_.round(
            percentageChange(insights?.historic?.actionsTotal, insights?.current?.actionsTotal).value,
            2
          )}
          changeSymbol="%"
        />
      )
    },
    {
      key: "closedActions",
      label: I18n.t("phrases.closedActions"),
      value: insights?.current?.actionsClosed,
      footer: (
        <ChangeIndicator
          changeType={percentageChange(insights?.historic?.actionsClosed, insights?.current?.actionsClosed).type}
          changeValue={_.round(
            percentageChange(insights?.historic?.actionsClosed, insights?.current?.actionsClosed).value,
            2
          )}
          changeSymbol="%"
        />
      )
    },
    {
      key: "overdueActions",
      label: I18n.t("phrases.overdueActions"),
      value: insights?.current?.actionsOverdue,
      footer: (
        <ChangeIndicator
          changeType={percentageChange(insights?.current?.actionsOverdue, insights?.historic?.actionsOverdue).type}
          changeValue={_.round(
            percentageChange(insights?.current?.actionsOverdue, insights?.historic?.actionsOverdue).value,
            2
          )}
          changeSymbol="%"
        />
      )
    }
  ];

  const load = useCallback(async () => {
    const m = new ProjectViewModel(appService, +projectId, +organisationId);
    setModel(m);
    await m.load();
  }, [projectId, organisationId, setModel, appService]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`} 
            style={{ minHeight: "100vh" }}>
            <div className="row mb-4">
              <div className="col-12">
                <div className="d-flex align-items-center">
                  <h1 className="mb-0">
                    {project.organisation.name} | <strong>{project.name}</strong>
                  </h1>

                  <Pill type="outline-primary" className="ml-4">
                    {I18n.t("phrases.project")}
                  </Pill>

                  <CanEditProject projectId={project.id}>
                    <LinkButton
                      id="manageProjectButton"
                      className="ml-auto"
                      type="primary"
                      href={`/organisations/${organisationId}/projects/${project.id}/settings`}
                    >
                      {I18n.t("phrases.manageProject")}
                    </LinkButton>
                  </CanEditProject>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-5">
                  <h2>{I18n.t("phrases.overview")}</h2>
                  <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(project.description) }} />
                </div>
                <div className="mb-5">
                  <h2>{I18n.t("phrases.objectives")}</h2>
                  <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(project.objectivesText) }} />
                </div>
                <div className="mb-5">
                  <h2>{I18n.t("phrases.weeklyStatusUpdate")}</h2>
                  <Panel hasBorderRadius={true} background="bg-primary-light-5" className="p-4">
                    {I18n.t("phrases.comingSoon")}
                  </Panel>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row mb-5">
                  <div className="col-12 z-index-1">
                    <h2>{I18n.t("phrases.projectTimeline")}</h2>
                    <Timeline
                      startDate={new Date(project.startDate)}
                      initialEndDate={new Date(project.initialEndDate)}
                      actualEndDate={
                        project.actualEndDate && new Date(project.actualEndDate) > new Date(1970, 1, 1)
                          ? new Date(project.actualEndDate)
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-lg-6">
                    <h2>{I18n.t("phrases.progressStatus")}</h2>
                    <Pill data-testid="progress-status-label" type={`primary-light-${project.progressStatus}` as any}>
                      {Enums.Translator.ProgressStatus(project.progressStatus)}
                    </Pill>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h2>{I18n.t("phrases.projectOwner")}</h2>
                    {project.stakeholderOwner && (
                      <Panel type="border-lines-y" className="py-3">
                        <Link to={`/organisations/${organisationId}/stakeholders/${project.stakeholderOwner.id}`}>
                          <AvatarContent
                            type="primary"
                            avatarProps={{
                              size: Enums.UiSizes.MD,
                              imgSrc:
                                project.stakeholderOwner.profileImageUrl ||
                                USER_IMAGE_PLACEHOLDER(
                                  project.stakeholderOwner.firstName,
                                  project.stakeholderOwner.lastName
                                )
                            }}
                          >
                            <h4 className="mb-0">
                              {project.stakeholderOwner.firstName} {project.stakeholderOwner.lastName}
                            </h4>
                          </AvatarContent>
                        </Link>
                      </Panel>
                    )}
                  </div>
                  <div className="col-lg-6 mb-5">
                    <div className="d-flex flex-column" style={{ height: "100%" }}>
                      <h2>{I18n.t("phrases.locations")}</h2>
                      <Panel
                        type="border-lines-y"
                        className="d-flex flex-column justify-content-center py-3"
                        style={{ flex: 1 }}
                      >
                        {project.locations.length ? (
                          <h4 className="mb-0">{project.locations.map(location => location.name).join(", ")}</h4>
                        ) : (
                            <span>{I18n.t("phrases.noLocations")}</span>
                          )}
                      </Panel>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h2>{I18n.t("phrases.keyStakeholders")}</h2>
                    {keyStakeholders.length ? (
                      keyStakeholders.slice(0, !showAllKeyStakeholders ? 4 : undefined).map(stakeholder => (
                        <Panel type="border-lines-y" key={"Stakeholder_" + stakeholder.id} className="py-3">
                          <Link
                            to={`/organisations/${organisationId}/projects/${model.id}/stakeholders/${stakeholder.id}`}
                          >
                            <AvatarContent
                              type="primary"
                              avatarProps={{
                                size: Enums.UiSizes.MD,
                                imgSrc: stakeholder.profileImageUrl
                              }}
                            >
                              <h4 className="mb-0">{convertStakeholderToName(stakeholder)}</h4>
                            </AvatarContent>
                          </Link>
                        </Panel>
                      ))
                    ) : (
                        <div>{I18n.t("phrases.noKeyStakeholders")}</div>
                      )}
                    <IF condition={keyStakeholders.length > 4}>
                      <Button
                        type="link"
                        className={"my-3 pl-0"}
                        onClick={() => setShowAllKeyStakeholders(!showAllKeyStakeholders)}
                      >
                        {showAllKeyStakeholders
                          ? I18n.t("phrases.showLess")
                          : I18n.t("phrases.showMore", { num: keyStakeholders.length - 4 })}
                      </Button>
                    </IF>
                  </div>
                  <div className="col-lg-6 mb-5">
                    <h2>{I18n.t("phrases.projectTeam")}</h2>
                    {project.keySystemUsers.length ? (
                      project.keySystemUsers.slice(0, !showAllKeySystemUsers ? 4 : undefined).map(user => (
                        <Panel type="border-lines-y" key={"User_" + user.id} className="py-3">
                          <Link to={`/organisations/${organisationId}/users/${user.id}`}>
                            <AvatarContent
                              type="primary"
                              avatarProps={{
                                size: Enums.UiSizes.MD,
                                imgSrc: user.profileImageUrl || USER_IMAGE_PLACEHOLDER(user.firstName, user.lastName)
                              }}
                            >
                              <h4 className="mb-0">{user.firstName + " " + user.lastName}</h4>
                            </AvatarContent>
                          </Link>
                        </Panel>
                      ))
                    ) : (
                        <div>{I18n.t("phrases.noProjectTeam")}</div>
                      )}
                    <IF condition={project.keySystemUsers?.length > 4}>
                      <Button
                        type="link"
                        className={"my-3 pl-0"}
                        onClick={() => setShowAllKeySystemUsers(!showAllKeySystemUsers)}
                      >
                        {showAllKeySystemUsers
                          ? I18n.t("phrases.showLess")
                          : I18n.t("phrases.showMore", { num: project.keySystemUsers.length - 4 })}
                      </Button>
                    </IF>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-5">
                    <h2>{I18n.t("phrases.keyStakeholderAudiences")}</h2>
                    {keyAudienceStakeholders.length ? (
                      keyAudienceStakeholders
                        .slice(0, !showAllKeyAudienceStakeholders ? 4 : undefined)
                        .map(stakeholder => (
                          <Panel type="border-lines-y" key={"Stakeholder_" + stakeholder.id} className="py-3">
                            <Link
                              to={`/organisations/${organisationId}/projects/${project.id}/stakeholders/${stakeholder.id}`}
                            >
                              <AvatarContent
                                type="primary"
                                avatarProps={{
                                  size: Enums.UiSizes.MD,
                                  imgSrc:
                                    stakeholder.profileImageUrl ||
                                    USER_IMAGE_PLACEHOLDER(stakeholder.firstName, stakeholder.lastName)
                                }}
                              >
                                <h4 className="mb-0">{convertStakeholderToName(stakeholder)}</h4>
                              </AvatarContent>
                            </Link>
                          </Panel>
                        ))
                    ) : (
                        <div>{I18n.t("phrases.noKeyAudienceStakeholders")}</div>
                      )}
                    <IF condition={keyAudienceStakeholders.length > 4}>
                      <Button
                        type="link"
                        className={"my-3 pl-0"}
                        onClick={() => setShowAllKeyAudienceStakeholders(!showAllKeyAudienceStakeholders)}
                      >
                        {showAllKeyAudienceStakeholders
                          ? I18n.t("phrases.showLess")
                          : I18n.t("phrases.showMore", { num: keyAudienceStakeholders.length - 4 })}
                      </Button>
                    </IF>
                  </div>
                </div>
              </div>
            </div>
            <div className="row py-6 bg-light">
              <div className="col-12">
                <div className="d-flex align-items-center mb-4">
                  <h1 className="mb-0">
                    {project.name} | <strong>{I18n.t("phrases.metrics")}</strong>
                  </h1>
                  <div className="d-flex align-items-center ml-auto text-dark-blue strong">
                    <Icon symbol="calendar" className="mr-2" />
                    {I18n.t("phrases.metricsChangeFromDate")} {moment().subtract(7, "days").format("L")}
                  </div>
                </div>

                {model.isLoadingInsights ? (
                  <div style={{ height: "100px" }}>
                    <PositionedSpinner />
                  </div>
                ) : model.insights ? (
                  <div className="d-flex flex-wrap mb-6">
                    {projectInsights.map(insight => (
                      <div key={"Insight_" + insight.key} className="mb-2 mr-2">
                        <ProjectInsightCard insight={insight} />
                      </div>
                    ))}
                  </div>
                ) : null}

                <h1 className="mb-4">
                  {project.name} | <strong>{I18n.t("phrases.actionsAndMilestones")}</strong>
                </h1>

                <div className="mb-4">
                  <TimelineView model={model.timelineModel} />
                </div>

                {project.milestones?.length ? (
                  <div className="d-flex flex-wrap mb-4 milestone-list">
                    {project.milestones.map(milestone => {
                      const isSelected = model.selectedMilestone?.id === milestone.id;

                      return (
                        <div className="mr-2 mb-2" key={"milestone_container_" + milestone.id}>
                          <Button
                            key={"milestone_" + milestone.id}
                            type={isSelected ? "primary" : "outline-primary"}
                            onClick={() => model.handleMilestoneClick(milestone)}
                          >
                            {milestone.name}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                {model.selectedMilestone && (
                  <div>
                    <h2 className="mb-0">{model.selectedMilestone.name}</h2>
                    <p>{moment(model.selectedMilestone.deadline).format("L")}</p>
                    <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(model.selectedMilestone.description) }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
