import * as React from 'react';
import { ITextFieldModel } from '../../../../core/forms/controls/textField/ITextFieldModel';
import { INIT_TEXT_FIELD } from '../../../../core/forms/controls/textField/TextField_init';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import { Validations } from '../../../../core/forms/helpers/Validations';
import { ErrorMessage } from '../../../../components/ui/ErrorMessage';
import { INIT_RTEDITOR } from '../../../../core/forms/controls/rteditor/RTEditor_model';
import { IRTEditorModel } from '../../../../core/forms/controls/rteditor/IRTEditorModel';
import { IDatePickerModel } from '../../../../core/forms/controls/datePicker/IDatePickerModel';
import { INIT_DATEPICKER } from '../../../../core/forms/controls/datePicker/DatePicker_model';
import moment from 'moment';
import I18n from '../../../../core/localization/I18n';

export const getFormFields = (milestone?: FP.Entities.IMilestone) => {
    const name: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "name",
        placeholder: I18n.t("placeholders.milestoneName"),
        label: <label htmlFor="name">{I18n.t("forms.milestoneName")} *</label>,
        validate: function () {
            let self: ITextFieldModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.value)) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.milestoneName")}</ErrorMessage>
                res = false;
            }

            return res;
        },
        fieldClassName: "col-lg-6",
        value: milestone?.name
    }

    const description: Partial<IRTEditorModel> = {
        ...INIT_RTEDITOR,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.milestoneDescription")}</label>,
        fieldClassName: "col-12",
        value: milestone?.description
    }

    const deadline: Partial<IDatePickerModel> = {
        ...INIT_DATEPICKER,
        key: "deadline",
        label: <label htmlFor="deadline">{I18n.t("forms.milestoneDate")} *</label>,
        placeholder: I18n.t("placeholders.date"),
        fieldClassName: "col-lg-6",
        validate: function () {
            const self: IDatePickerModel = this;
            let res = true;
            if (Validations.IS_NULL(self.value)) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.milestoneDate", {value: I18n.t("forms.milestoneDate"), entity: "Milestone"})}</ErrorMessage>
                res = false;
            }

            return res;
        },
        componentProps: {
            icon: "calendar",
            datePickerProps: {}
        },
        value: milestone && moment(milestone.deadline)
    };

    const fields = [];

    fields.push(name);
    fields.push(deadline);
    fields.push(description);

    const models = generateFormFieldsFromJson(fields);
    return models;
}