import * as React from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";
import * as Icons from "../../icons";
import * as _ from "lodash";

export type IconSymbol = keyof typeof Icons | string;
export interface IconProps extends Omit<React.HTMLProps<HTMLElement>, "size"> {
  library?: "mdi";
  symbol: IconSymbol;
  size?: UiSizes;
}

export const Icon: React.FunctionComponent<IconProps> = props => {
  const cls = B()
    .bl("icon")
    .add(props.library || "mdi")
    .pmod(props.size || UiSizes.MD).boot;
  const symbol = _.startCase(props.symbol).replace(/\s/g, "") as any;
  const el = Icons[symbol];

  return el ? el({ className: `${cls} ${props.className || ""}` }) : `{${symbol}}`;
};
