import * as React from 'react';
import * as moment from 'moment';
import { observable } from 'mobx';
import { IDatePickerModel } from './IDatePickerModel';
import { FormFieldModel } from '../../formField/FormField_model';
import { SingleDatePickerProps, SingleDatePicker } from "../../../../components/ui/_forms/SingleDatePicker";
import { FormFieldType } from '../../formField/FormFieldTypes';
import { INIT_FORM_FIELD } from '../../formField/FormField_init';
import moment2 from 'moment';


export const INIT_DATEPICKER: Partial<IDatePickerModel> = {
    ...INIT_FORM_FIELD,
    type: FormFieldType.DatePicker,
    componentProps: {
        datePickerProps: null,
        icon: "calendar"
    }
}

const DATE_YEAR_RANGE = 10;

export class DatePickerModel extends FormFieldModel<moment.Moment, SingleDatePickerProps>
    implements IDatePickerModel {

    @observable value: moment.Moment;
    @observable datePickerProps: object;
    placeholder: string;
    exportedDateFormat?: string = null;
    readonly type: FormFieldType = FormFieldType.DatePicker;

    constructor(initOpts?: IDatePickerModel) {
        super(initOpts);

        if (initOpts) {
            this.setValue(initOpts.value)
            this.exportedDateFormat = initOpts.exportedDateFormat || this.exportedDateFormat;
            this.extractValue = initOpts.extractValue || this.extractValue;
            this.validate = initOpts.validate || this.validate;
            this.placeholder = initOpts.placeholder || this.placeholder;
        }

        this.datePickerProps = this.componentProps.datePickerProps || null
    }

    validate: (...args: any[]) => any;

    setValue = (val: moment.Moment): void => {
        this.value = val || this.value;
    }

    setFieldValue(val: any): void {
        throw new Error("Method not implemented.");
    }

    extractValue() {
        if (!this.value) return null;
        if (this.exportedDateFormat) return this.value.format(this.exportedDateFormat);
        return this.value.toISOString();
    }

    reset(): void {
        const val = moment.utc(new Date());
        this.value = this.defaultValue || val;
    }

    setDatepickerProps(newProps): void {
        this.datePickerProps = Object.assign({}, this.datePickerProps, newProps);
    }

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) =>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
                <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                    className="DateRangePicker_monthSelect"
                >
                    {moment.months().map((label, value) => (
                        <option value={value}>{label}</option>
                    ))}
                </select>
            </div>
            <div>
                <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)} className="DateRangePicker_yearSelect">
                    {this.yearOptions(DATE_YEAR_RANGE)}
                </select>
            </div>
        </div>

    yearOptions = (numberOfYears: number) => {
        var rows = [];
        let [start, target] = this.getYears(numberOfYears)
        const startDate = moment2().year() + start;
        const endDate = moment2().year() + target;
        if (!this.value) {
            this.value = moment2();
        }
        if (this.value.year() < startDate || this.value.year() > endDate) {
            rows.push(<option value={this.value.year()}>{this.value.year()}</option>);
        }

        for (var i = start; i < target; i++) {
            rows.push(<option value={moment2().year() + i}>{moment2().year() + i}</option>);
        }

        return rows;
    }

    getYears = (numberOfYears) => {
        var start = (numberOfYears / 2) * -1;
        var target = (numberOfYears / 2) + 1;
        return [start, target];
    }

    renderComponent = (): React.ReactNode => {
        const props = { ...INIT_DATEPICKER.componentProps };
        return <SingleDatePicker
            {...props}
            datePickerProps={{
                id: this.key,
                date: this.value,
                readOnly: true,
                placeholder: this.placeholder,
                onDateChange: this.setValue,
                renderMonthElement: this.renderMonthElement,
                hideKeyboardShortcutsPanel: true,
                ...this.datePickerProps
            }}
        />
    };
}





