import * as React from "react";
import { Checkbox } from "../../Checkbox";
import { observer } from "mobx-react-lite";
import { B } from "../../../../core/util/BootstrapHelper";

interface ICheckboxItem {
  isChecked: boolean;
  label: string;
}

export interface CheckboxListProps {
  header?: React.ReactNode;
  options: ICheckboxItem[];
  onItemClick: (item: ICheckboxItem) => void;
}

const CheckboxList: React.FunctionComponent<CheckboxListProps> = observer(props => {
  let cls = B().bl("checkbox-list");
  return (
    <div className={`${cls.bem}`}>
      {props.header && (
        <div data-testid="checkboxlist-header" className={`${cls.el("header").bem}`}>
          {props.header}
        </div>
      )}

      <ul className={`${cls.el("list").bem}`}>
        {props.options.map(e => {
          return (
            <CheckboxItem
              isChecked={e.isChecked}
              onClick={() => {
                props.onItemClick(e);
              }}
              key={e.label}
            >
              {e.label}
            </CheckboxItem>
          );
        })}
      </ul>
    </div>
  );
});

export interface CheckboxItemProps {
  className?: string;
  isChecked: boolean;
  onClick?: (ev: React.SyntheticEvent<any>) => void;
}

const CheckboxItem: React.FunctionComponent<CheckboxItemProps> = observer(props => {
  return (
    <div
      data-testid="checkbox-list__item"
      className={`checkbox-list__item ${props.className || ""}`}
      onClick={props.onClick && props.onClick}
    >
      <Checkbox onChange={() => {}} checked={props.isChecked} />
      {props.children}
    </div>
  );
});

export { CheckboxList, CheckboxItem };
