import React, { useState } from "react";
import Timeline, {
  TodayMarker,
  TimelineMarkers,
  CustomMarker,
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import { observer } from "mobx-react-lite";
import { TimeLineModel } from "./Timeline_model";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import moment from "moment";
import { ButtonIcon } from "../../ui/Button";
import { Enums } from "../../../enums";
import I18n from "../../../core/localization/I18n";
import { Tooltip } from "react-tippy";

export interface TimelineProps {
  model: TimeLineModel;
}

const TimelineView: React.FunctionComponent<TimelineProps> = observer(({ model }) => {
  const [timelineHeight, setTimelineHeight] = useState(300);
  if (model.isLoading) {
    return <PositionedSpinner style={{ height: `${timelineHeight}px` }} />;
  }

  if (!model.groups?.filter(group => group.title !== null).length && !model.markers?.length) {
    return <div>{model.noItemsText}</div>;
  }

  const timelineProps: any = {
    canMove: model.canEdit ? true : false,
    canResize: model.canEdit ? ("both" as "both") : false,
    ...(model.isEditable && {
      onItemMove: model.handleItemMove,
      onItemResize: model.handleItemResize,
      onItemDoubleClick: model.handleItemDoubleClick,
      sidebarWidth: 0,
      itemHeightRatio: 0.8,
      lineHeight: 56,
      className: "react-calendar-timeline--editable"
    })
  };

  // TODO: this should be wrapped in a useEffect
  if (model.isEditable) {
    const timelineEl: HTMLElement | any = document.querySelector(".timeline");
    if (timelineEl && timelineHeight !== timelineEl.offsetHeight) {
      setTimelineHeight(timelineEl.offsetHeight);
    }
  }

  return (
    <div className="timeline">
      <Timeline
        groups={model.groups}
        items={model.items}
        defaultTimeStart={model.defaultStartDate}
        defaultTimeEnd={model.defaultEndDate}
        visibleTimeStart={model.visibleTimeStart.valueOf()}
        visibleTimeEnd={model.visibleTimeEnd.valueOf()}
        onTimeChange={model.handleTimeChange}
        minZoom={model.minZoom}
        maxZoom={model.maxZoom}
        canChangeGroup={false}
        itemRenderer={model.itemRenderer || undefined}
        groupRenderer={model.groupRenderer || undefined}
        itemTouchSendsClick={false}
        {...timelineProps}
      >
        <TimelineHeaders>
          <SidebarHeader>
            {({ getRootProps }) => {
              return <div {...getRootProps()}></div>;
            }}
          </SidebarHeader>
          <DateHeader
            unit={model.currentZoom > 60 ? "year" : "month"}
            labelFormat={model.currentZoom > 60 ? "YYYY" : "MMM YY"}
            intervalRenderer={({ getIntervalProps, intervalContext }: any) => {
              const { onClick, ...restIntervalProps } = getIntervalProps();
              return (
                <div {...restIntervalProps} className="rct-dateHeader rct-dateHeader-primary">
                  <span className="text-truncate">{intervalContext.intervalText}</span>
                </div>
              );
            }}
          />
          <DateHeader
            unit={model.currentZoom > 60 ? "month" : "day"}
            labelFormat={model.currentZoom > 365 ? "MMM" : model.currentZoom > 60 ? "MMM YY" : "D"}
            intervalRenderer={({ getIntervalProps, intervalContext }: any) => {
              const { onClick, ...restIntervalProps } = getIntervalProps();
              return (
                <div {...restIntervalProps} className="rct-dateHeader">
                  <span className="text-truncate">{intervalContext.intervalText}</span>
                </div>
              );
            }}
          />
        </TimelineHeaders>

        <TimelineMarkers>
          <TodayMarker date={moment().valueOf()}>
            {({ styles, date }) => {
              const customStyles: React.CSSProperties = {
                ...styles,
                pointerEvents: "auto",
                zIndex: 1
              };
              return (
                <div style={customStyles}>
                  <Tooltip
                    theme="light"
                    followCursor
                    html={<small className="d-block">{I18n.t("phrases.today")}</small>}
                  >
                    <div className="timeline-marker timeline-marker--today">
                      <div className="timeline-marker__icon" />
                    </div>
                  </Tooltip>
                </div>
              );
            }}
          </TodayMarker>
          {model.markers?.map(marker => (
            <CustomMarker date={marker.date.valueOf()} key={marker.id}>
              {({ styles, date }) => {
                const customStyles: React.CSSProperties = {
                  ...styles,
                  pointerEvents: "auto",
                  zIndex: 1
                };
                return (
                  <div style={customStyles} onClick={() => model.handleMarkerSelect(marker)}>
                    <Tooltip
                      theme="light"
                      followCursor
                      html={
                        <>
                          {marker.title ? <small className="d-block">{marker.title}</small> : null}
                          <small className="d-block">{moment(date).format("L")}</small>
                        </>
                      }
                    >
                      <div className="timeline-marker" data-selected={model.selectedMarker?.id === marker.id}>
                        <div className="timeline-marker__icon" />
                      </div>
                    </Tooltip>
                  </div>
                );
              }}
            </CustomMarker>
          ))}
        </TimelineMarkers>
      </Timeline>

      <div className="timeline__controls">
        <ButtonIcon
          symbol="ChevronLeft"
          type="link"
          title={I18n.t("phrases.scrollLeft")}
          iconSize={Enums.UiSizes.SM}
          size={Enums.UiSizes.XS}
          onClick={model.onPrevClick}
        />
        <ButtonIcon
          symbol="ChevronRight"
          type="link"
          className="ml-1"
          title={I18n.t("phrases.scrollRight")}
          iconSize={Enums.UiSizes.SM}
          size={Enums.UiSizes.XS}
          onClick={model.onNextClick}
        />
        <ButtonIcon
          symbol="Minus"
          type="link"
          className="ml-1"
          title={I18n.t("phrases.zoomOut")}
          iconSize={Enums.UiSizes.SM}
          size={Enums.UiSizes.XS}
          onClick={model.onZoomOut}
          disabled={!model.canZoomOut}
        />
        <ButtonIcon
          symbol="Plus"
          type="link"
          className="ml-1"
          title={I18n.t("phrases.zoomIn")}
          iconSize={Enums.UiSizes.SM}
          size={Enums.UiSizes.XS}
          onClick={model.onZoomIn}
          disabled={!model.canZoomIn}
        />
        <ButtonIcon
          symbol="Minimize2"
          type="link"
          className="ml-1"
          title={I18n.t("phrases.resetZoom")}
          iconSize={Enums.UiSizes.SM}
          size={Enums.UiSizes.XS}
          onClick={model.resetZoom}
        />
      </div>
    </div>
  );
});

export { TimelineView };
