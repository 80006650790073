import React, { useEffect, useState } from "react";
import { ImpactExtendedViewModel } from "./ImpactExtendedView_model";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { ImpactDisplay } from "../impactDisplay";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ImpactStakeholders } from "../ImpactStakeholders/ImpactStakeholders_view";
import { Enums } from "../../../../enums";
import { ImpactActions } from "../impactActions/ImpactActions_view";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { RevisionHistoryView } from "../../revisionHistory/RevisionHistory_view";
import { useAppService } from "../../../../AppService";
import { useParams } from "react-router-dom";
import { Async } from "react-async";
import { useFlightPathUser } from "../../../../setup";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ImpactExtendedViewProps {
  model?: ImpactExtendedViewModel;
}

const ImpactExtendedView: React.FunctionComponent<ImpactExtendedViewProps> = observer(({ model: m }) => {
  const authUser = useFlightPathUser();
  const appService = useAppService();
  const { projectId, impactId } = useParams<{ projectId: string; impactId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(
    () => m || new ImpactExtendedViewModel({ appService, authUser, projectId: +projectId, impactId: +impactId, organisationId })
  );
  const { impact } = model;

  useEffect(() => {
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
  }

  return (
    <Async promiseFn={model.load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="data-extended-view pt-6">
            <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
              <div className="row">
                <div className="col">
                  <LinkButton
                    size={Enums.UiSizes.MD}
                    type="link-big"
                    className="p-0"
                    href={`/organisations/${organisationId}/projects/${model.projectId}/impacts`}
                  >
                    <Icon className="mr-2" symbol="ChevronLeft" />
                    {I18n.t("phrases.backToImpacts")}
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="data-extended-view__block data-extended-view__block--wrapper">
              <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="d-flex">
                      <h2 className="mb-0" id="ImpactNameLabel">
                        {impact.refNumber} - {impact.name} | <strong>{I18n.t("phrases.details")}</strong>
                      </h2>

                      <CanEditProject projectId={model.projectId}>
                        <LinkButton
                          className="ml-auto"
                          type={"outline-primary"}
                          href={`/organisations/${organisationId}/projects/${model.projectId}/impacts/${model.impactId}/edit`}
                        >
                          <Icon symbol="Pencil" size={Enums.UiSizes.SM} className="mr-2" />
                          {I18n.t("phrases.edit")}
                        </LinkButton>
                      </CanEditProject>
                    </div>
                  </div>
                </div>

                <ImpactDisplay item={impact} />
              </div>
              <Panel
                background="bg-light"
                className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-5`}
              >
                <TabGroup
                  className="data-extended-view__tabs"
                  tabs={[
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.stakeholders")}</h3>,
                      children: <ImpactStakeholders model={model.impactStakeholderModel} />
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.actions")}</h3>,
                      children: <ImpactActions model={model.impactActionsModel} />
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                      children: <CommentListView model={model.commentViewModel} />
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.impactHistory")}</h3>,
                      children: <RevisionHistoryView model={model.revisionHistoryModel} />
                    }
                  ]}
                ></TabGroup>
              </Panel>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});

export { ImpactExtendedView };
