import * as React from "react";
import { observer } from "mobx-react";
import { ImpactFormSectionModel } from "./ImpactFormSection_model";
import { Form } from "../../../../core/forms/baseForm/Form_view";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { B } from "../../../../core/util/BootstrapHelper";

export interface ImpactFormSectionProps {
  model: ImpactFormSectionModel;
}

@observer
export class ImpactFormSectionView extends React.Component<ImpactFormSectionProps> {
  componentDidMount() {
    this.props.model.onMount();
  }

  render() {
    let { model } = this.props;
    let cls = B().bl("impact-form-section");
    return (
      <>
        {model.projectForm.length > 0 && (
          <Form className={`${cls.el("block").amod("form").bem} row mb-4`} model={{ formFields: model.projectForm }} />
        )}
        {model.projectId && (
          <>
            <Form
              className={`${cls.el("block").amod("form").bem} row mb-4`}
              model={{ formFields: model.topForm.formFields }}
            />

            <Form
              className={`${cls.el("block").bem} row bg-light py-5 mb-5`}
              model={{ formFields: model.impactForm.formFields }}
            />

            <Form
              className={`${cls.el("block").amod("form").bem} row mb-4`}
              model={{ formFields: model.bottomForm.formFields }}
            />
          </>
        )}
        {model.config.showActionsInView && model.projectId && (
          <div className={`row ${cls.el("block").bem} justify-content-end mb-4`}>
            <div className="col-lg-10">
              <div className="float-right">
                {model.actions.map(e => (
                  <UiActionComponent key={e.id} action={e} />
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
