import { IConcernsApi } from "./IConcerns.api";
import { IHttp } from "../../IHttp";
import { FilteredProjectApiModel } from "../../filteredApi/FilteredProjectApiModel";

export class ConcernsApi extends FilteredProjectApiModel<FP.Entities.IConcern> implements IConcernsApi {
  controller: string = "concerns";

  constructor(http: IHttp) {
    super(http, "concerns");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }
}
