/**
 * A set of function to help apply value validations
 */
export const Validations = {
  /**
   * Checks if a given value is an email address
   * @param val:string email value
   * @return boolean
   */
  IS_EMAIL: (val: string): boolean => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val);
  },

  /**
   * Checks if a given value is a URL address
   * @param val:string url value
   * @return boolean
   */
  IS_URL: (val: string): boolean => {
    var urlRegex =
      "^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
    var url = new RegExp(urlRegex, "i");
    return val.length < 2083 && url.test(val);
  },
  /**
   * Checks if a given value is more than a specified amount
   * @param val the entered value
   * @param min to check against to
   * @return boolean true if valu is more than min
   */
  IS_MORE_THAN_MIN: (val: number, min: number): boolean => {
    return val >= min;
  },
  /**
   * Checks if a given value is less than a specified amount
   * @param val the entered value
   * @param max to check against to
   * @return boolean
   */
  IS_LESS_THAN_MAX: (val: number, max: number): boolean => {
    return val <= max;
  },
  /**
   * Checks if a given value is empty
   * @param val:string the value to check if is empty
   * @return boolean
   */
  IS_EMPTY: (val: string): boolean => {
    return val == null || typeof val === "undefined" || val.length === 0;
  },
  /**
   * Checks if a given value is null
   * @param val:any the value to check if is empty
   * @return boolean
   */
  IS_NULL: (val: any): boolean => {
    return val === null;
  },
  /**
   * Checks if a given password matches the password rules
   * @param val:any the value to check if is empty
   * @return boolean
   */
  IS_VALID_PASSWORD: (val: string): boolean => {
    /**
     *  Regex to enforce:
     *  At least one uppercase letter
     *  At least one lowercase letter
     *  At least one digit
     *  At least one special character
     *  Minimum 8 length
     **/
    let passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");
    return passwordRegex.test(val);
  },
  IS_NUMERIC: (val: string) => {
    let regex = /^\d+$/;
    return regex.test(val);
  },
  PASSWORDS_DO_NOT_MATCH: (password1: string, password2: string) => {
    return password1 !== password2;
  }
};
