import { ReactCalendarItemRendererProps } from "react-calendar-timeline";
import { ITimelineItem } from "../timeline/Timeline_model";

import * as React from "react";
import moment from "moment";
import { ButtonIcon } from "../../ui/Button";
import { Tooltip } from "../../ui/Tooltip";
import { Icon } from "../../ui/Icon";
import { Panel } from "../../ui/Panel";
import { Enums } from "../../../enums";

interface TimelineItemProps {
  model: any;
  impact: FP.Entities.IImpact;
  timelineProps: ReactCalendarItemRendererProps<ITimelineItem>;
}

const ImpactTimelineItem: React.FC<TimelineItemProps> = props => {
  const { model, impact } = props;
  const { item, getItemProps, itemContext } = props.timelineProps;
  const entity: any = item.data;

  const editingTime: string[] = [];
  if (itemContext.dragging) {
    editingTime[0] = moment(itemContext.dragTime).format("dd, MMMM D YYYY");
    editingTime[1] = moment(item.end_time)
      .add(moment(itemContext.dragTime).diff(item.start_time, "days"), "days")
      .format("dd, MMMM D YYYY");
  } else if (itemContext.resizing) {
    editingTime[0] =
      itemContext.resizeEdge === "left"
        ? moment(itemContext.resizeTime).format("dd, MMMM D YYYY")
        : item.start_time.format("dd, MMMM D YYYY");
    editingTime[1] =
      itemContext.resizeEdge === "right"
        ? moment(itemContext.resizeTime).format("dd, MMMM D YYYY")
        : item.end_time.format("dd, MMMM D YYYY");
  }

  return (
    <div
      {...getItemProps({
        onMouseDown: () => {}
      })}
      title={item.title}
      className={`rct-item rct-item--${(item.id as string).charAt(
        0
      )} d-flex align-items-center justify-content-between ${itemContext.selected && "rct-item--selected"} `}
    >
      <div className="rct-item__label">{item.title}</div>
      {!model.isSingle && entity.actions && entity.actions.length > 0 && (
        <ButtonIcon
          symbol={model.openGroups[item.group] ? "ChevronUp" : "ChevronDown"}
          type="link"
          iconSize={Enums.UiSizes.SM}
          size={Enums.UiSizes.XS}
          onClick={() => {
            model.toggleGroup(entity.id);
            // this.setTimelineItems(this.impacts, model);
          }}
          className="z-index-high"
        />
      )}
      {entity.impacts && entity.impacts.length > 1 && (
        <Tooltip position="right" shownElement={<Icon symbol="AlertTriangle" className="ml-1" />} triggeredOn="click">
          <Panel background="bg-white" className="p-3" hasShadow={true} style={{ width: "320px", zIndex: 100000 }}>
            <p>This actions is related to the following impacts:</p>
            <ul>
              {(entity.impacts as number[])
                .filter(id => true)
                .map(id => {
                  return <li>{impact.refNumber + " " + impact.name}</li>;
                })}
            </ul>
          </Panel>
        </Tooltip>
      )}
      {(itemContext.dragging || itemContext.resizing) && (
        <div className="rct-item__time-label">
          {editingTime[0]} - {editingTime[1]}
        </div>
      )}
    </div>
  );
};

export { ImpactTimelineItem };
