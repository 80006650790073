import React, { useEffect } from "react";
import { ImpactStakeholderListViewModel } from "./ImpactStakeholderListView_model";
import { ButtonIcon } from "../../../../../components/ui/Button";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import I18n from "../../../../../core/localization/I18n";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../../components/ui/_forms/Input";
import { Enums } from "../../../../../enums";
import { StakeholderCard } from "../../../stakeholders/StakeholderCard";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { Animations } from "../../../../../core/util/Animations";

export interface ImpactStakeholderListViewProps {
  model: ImpactStakeholderListViewModel;
}

const ImpactStakeholderListView: React.FunctionComponent<ImpactStakeholderListViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`container-fluid stakeholder-action-list pt-5 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-12">{I18n.t("phrases.impactStakeholderDescription")}</div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div className="d-flex justify-content-end">
            <ButtonIcon
              symbol={model.isSearchMode ? "Close" : "Search"}
              iconSize={Enums.UiSizes.SM}
              type="outline-primary"
              onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
            />
          </div>

          <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
            <Input
              autoFocus={true}
              size={-1}
              onChange={model.updateSearchValue}
              borderStyle="underline"
              placeholder={I18n.t("placeholders.searchImpactStakeholders")}
              icon="search"
              value={model.searchValue}
              onBlur={null}
              className="mt-3"
            />
          </AnimateHeight>
        </div>
      </div>

      <div className="row">
        <div className="col">
          {model.filteredStakeholders?.length ? (
            model.filteredStakeholders.map(stakeholder => {
              return (
                <Link
                  key={stakeholder.id}
                  to={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders/${stakeholder.id}`}
                >
                  <StakeholderCard stakeholder={stakeholder} />
                </Link>
              );
            })
          ) : (
            <div>{I18n.t("phrases.noImpactStakeholders")}</div>
          )}
        </div>
      </div>
    </div>
  );
});

export { ImpactStakeholderListView };
