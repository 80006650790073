import { AxiosRequestConfig } from "axios";
import { IHttp } from "./IHttp";

import Config from "../../config";
import { IFilteredOptions } from "./filteredApi/FilteredApiModel";

export interface IBaseApiModel<T> {
  version: string;
  controller: string;
  http: IHttp;
  url: string;

  getByIdAsync: (id: number, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  getByIdAndOrgaisationIdAsync: (organisationId: number, id: number, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  findAllAsync: (config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T[]>>;

  removeAsync: (id: number, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<void>>;

  createAsync: (data: T, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  updateAsync: (id: number, data: T, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  updateWithOrganisationIdAsync: (organisationId: number, id: number, data: T, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  UrlWithOrgId: (organistionId: number) => string;
}

export interface IFlightPathApiResponse<T> {
  code?: number;
  message?: string;
  payload?: T;
  sentDate?: Date;
  pagination?: IFilteredOptions;
  isError?: boolean;
}

export abstract class BaseApiModel<T> implements IBaseApiModel<T> {
  version: string = Config.API.VERSION.__LATEST__;
  abstract controller: string;
  http: IHttp;
  url: string = "";

  constructor(http: IHttp, controller: string) {
    this.http = http;
    this.url = `${this.http.url}/${this.version}/${controller}/`;
  }

  getByIdAsync = async (id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + id, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<T>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getByIdAndOrgaisationIdAsync = async (organisationId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.UrlWithOrgId(organisationId)}${id}`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<T>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  findAllAsync = async (config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<T[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  removeAsync = async (id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.delete(this.url + id, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  createAsync = async (data: T, config?: AxiosRequestConfig) => {
    let res = await this.http.post(this.url + "create", data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  updateAsync = async (id: number, data: T, config?: AxiosRequestConfig) => {
    let res = await this.http.put(this.url + id, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  updateWithOrganisationIdAsync = async (organisationId: number, id: number, data: T, config?: AxiosRequestConfig) => {
    let res = await this.http.put(`${this.UrlWithOrgId(organisationId)}${id}`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  UrlWithOrgId = (organistionId: number) => {
    return `${this.http.url}/${this.version}/organisations/${organistionId}/${this.controller}/`;
  }
}
