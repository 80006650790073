import * as React from "react";

import { Button, LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { TableView } from "../../../../core/table/Table_view";
import { observer } from "mobx-react";
import { ImpactGroupsViewModel } from "./ImpactGroupsView_model";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { Pagination } from "../../../../components/widgets/pagination/Pagination_view";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { SearchButton } from "../../../../components/ui/SearchButton";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ImpactsViewGroupsProps {
  model: ImpactGroupsViewModel;
}

const ImpactGroupsView: React.FunctionComponent<ImpactsViewGroupsProps> = observer(props => {
  let { model } = props;
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  const searchValue = model.filterModel.getFilter("name").value;
  return (
    <div className="impacts-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>
              {model.project?.name} | <strong>{I18n.t("phrases.impactGroups")}</strong>
            </h1>
            <p className="mb-5">{I18n.t("phrases.impactGroupsViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary mb-5"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        initialTab={1}
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.individualImpact")}</h2>}></Tab>
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.impactGroups")}</h2>}></Tab>
        {model.showVisualisations === true && (
          <Tab title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}></Tab>
        )}
      </Tabs>
      <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-5">
          <div className="col">
            {model.viewType === "groups" && (
              <CanEditProject projectId={model.projectId}>
                <LinkButton href={`/organisations/${organisationId}/projects/${model.projectId}/impactGroups/create`}>
                  <Icon symbol="plus" className="mr-2" />
                  {I18n.t("phrases.createImpactGroup")}
                </LinkButton>
              </CanEditProject>
            )}
            {model.viewType === "impacts" && (
              <CanEditProject projectId={model.projectId}>
                <LinkButton href={`/organisations/${organisationId}/projects/${model.projectId}/impacts/create`}>
                  <Icon symbol="plus" className="mr-2" />
                  {I18n.t("phrases.createImpact")}
                </LinkButton>
              </CanEditProject>
            )}
            <SearchButton
              onChange={model.onSearchChange}
              value={searchValue.length > 0 ? searchValue[0] : ""}
              className={"float-right ml-2"}
              onClose={model.resetNameFilter}
              buttonType="outline-primary"
            />
            <Button
              className={"float-right ml-2"}
              type="outline-primary"
              onClick={model.exportService.showSelectionModal}
            >
              <Icon symbol="download" className="mr-2" />
              {I18n.t("phrases.export")}
            </Button>
            <Button
              className={"float-right ml-2"}
              type="outline-primary"
              onClick={() => model.filterModel.showFilterFormModal()}
            >
              <Icon symbol="filter" className="mr-2" />
              {I18n.t("phrases.applyFilters")}
            </Button>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TableView model={model.tableModel} />
            <Pagination model={model.paginationModel} />
          </div>
        </div>
      </div>
    </div>
  );
});

export { ImpactGroupsView };
