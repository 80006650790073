import * as React from "react";
import { Icon } from "../../Icon";
import { B } from "../../../../core/util/BootstrapHelper";
import { Enums, UiSizes } from "../../../../enums";

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  icon?: string;
  iconSize?: UiSizes;
  borderStyle?: "underline";
}

const Input: React.FC<any> = React.forwardRef(
  ({ borderStyle, className, icon, iconSize, ...props }, ref?: React.Ref<HTMLInputElement>) => {
    let cls = B().bl("ui-input");
    let clsMod = B()
      .bl("input")
      .pmod(borderStyle || "").bem;

    return icon ? (
      <div className="ui-input">
        <input
          ref={ref}
          {...props}
          name={new Date().getTime().toString() + (Math.random() * 1000000).toFixed(0)}
          className={`${clsMod} ${className || ""}`}
        />
        <Icon className={cls.el("icon").bem} symbol={icon} size={iconSize || Enums.UiSizes.MD} />
      </div>
    ) : (
      <input
        ref={ref}
        {...props}
        name={new Date().getTime().toString() + (Math.random() * 1000000).toFixed(0)}
        className={`${clsMod} ${className || ""}`}
      />
    );
  }
);
export { Input };
