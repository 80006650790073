import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { ISliderFieldModel } from "../../../../core/forms/controls/slider/ISliderFieldModel";
import { Enums, Translator } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import { IMPACT_STATUS_OPTIONS, Services } from "../../../../constants";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import moment from "moment";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import AppService from "../../../../AppService";
import { IImpactTypeApi } from "../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { ICheckboxSliderModel } from "../../../../core/forms/controls/checkboxslider/ICheckboxSliderModel";
import { INIT_CHECKBOX_SLIDER_FIELD } from "../../../../core/forms/controls/checkboxslider/CheckboxSlider_init";

export const getProjectFormField = (
  orgProvider: IOrganisationsApi,
  orgId: number,
  onChange: (val) => void,
  projId?: number
) => {
  const projectId = projId
    ? {
      ...INIT_TEXT_FIELD,
      key: "projectId",
      inputType: "hidden",
      value: projId,
      defaultValue: projId
    }
    : {
      ...INIT_AUTOCOMPLETE,
      key: "projectId",
      label: <label htmlFor={"projectId"}>{I18n.t("forms.chooseProject")} *</label>,
      placeholder: I18n.t("placeholders.searchProject"),
      optionElement: (
        <AutocompleteOption
          key={"e"}
          className={"autocomplete__chip"}
          label={e => {
            return e.name;
          }}
        />
      ),
      onFocus: async function (model: IAutocompleteModel, event) {
        if (model.options.length === 0) {
          const res = await orgProvider.getProjects(orgId);

          if (res?.payload) {
            const sortedProjects = _.orderBy(res.payload, [project => project.name.toLowerCase()]);
            model.setOptions(sortedProjects);
          }
        }
      },
      componentProps: {
        className: "form-control",
        icon: "search"
      },
      onItemSelected: function () {
        onChange(this.extractValue());
      },
      searchAttribute: "name",
      validate: function () {
        const self: IAutocompleteModel = this;
        let res = true;
        if (Validations.IS_EMPTY(self.extractValue())) {
          self.errorMessage = <ErrorMessage>{I18n.t("validations.impactProject")}</ErrorMessage>;
          res = false;
        }
        return res;
      },
      fieldClassName: "col-lg-6 mb-0",
      extractValue: function () {
        return this.value?.id;
      }
    };

  const fields = [];
  fields.push(projectId);
  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getTopFormFields = (projId: number,
  isTestUser: boolean,
  impact?: FP.Entities.IImpact) => {
  const projectId = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projId,
    defaultValue: projId
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.impactName"),
    label: <label htmlFor="name">{I18n.t("forms.impactName")} *</label>,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: "col-lg-6",
    value: impact?.name
  };

  const description = isTestUser ? {
    ...INIT_TEXT_FIELD,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.description")} *</label>,
    validate: function () {
      const self: IRTEditorModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactDescription")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: "col-12",
    value: impact?.description
  } : {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.description")} *</label>,
    validate: function () {
      const self: IRTEditorModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactDescription")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: "col-12",
    value: impact?.description
  };



  const fields = [];
  fields.push(name);
  fields.push(projectId);
  fields.push(description);

  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getRadioFields = (organisationId: number, impact?: FP.Entities.IImpact) => {
  const impactTypeProvider = AppService.getService<IImpactTypeApi>(Services.ImpactTypesApi);

  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  }
  
  const impactType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactType",
    label: <label htmlFor={"impactType"}>{I18n.t("forms.impactType")} *</label>,
    placeholder: I18n.t("placeholders.selectImpactType"),
    onFocus: async function (model: IAutocompleteModel) {
      const res = await impactTypeProvider.getFiltered(organisationId, { page: 1, pageSize: 10000, filters: `lifecycleStatus==0,organisationId==${organisationId}` });
      if (!res.payload) return;
      const impactTypes = _.orderBy(res.payload, [item => item.name])
      model.setOptions(impactTypes);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactType")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-lg-6 mb-lg-0",
    extractValue: function () {
      return this.value?.id;
    },

    valueLabelFn: e => e?.name,
    value: impact?.nImpactType,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactType")}</p>
      </FormTooltip>
    )
  };

  const impactLevel: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impactLevel",
    label: <label htmlFor="impactLevel">{I18n.t("forms.impactLevel")}</label>,
    fieldClassName: "col-lg-6 mb-lg-0",
    validate: function () {
      const self: ISliderFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactLevel")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      ...baseCheckboxSlider.componentProps,
    },
    value: impact?.impactLevel,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactLevel")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };

  const mitigationConfidence: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "mitigationConfidence",
    fieldClassName: "col-lg-6 col-space-lg-6 mb-lg-20",
    label: <label htmlFor={"mitigationConfidence"}>{I18n.t("forms.mitigationConfidence")}</label>,
    value: impact?.mitigationConfidence,
    componentProps: {
      ...baseCheckboxSlider.componentProps,
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.mitigationConfidence")}</p>
      </FormTooltip>
    ),
    defaultValue: 5
  };


  const fields = [];

  fields.push(mitigationConfidence);
  fields.push(impactType);
  fields.push(impactLevel);
 

  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getBottomForm = (
  orgProvider: IOrganisationsApi,
  orgId: number,
  projectProvider: IProjectsApi,
  projectId: number,
  projectStakeholderProvider: IProjectStakeholdersApi,
  stakeholderGroupProvider: IStakeholderGroupsApi,
  impactGroupsProvider: IImpactGroupsApi,
  impact?: FP.Entities.IImpact
) => {
  let shouldAddToImpactGroup: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "controlQuestion",
    label: <label htmlFor="controlQuestion">{I18n.t("forms.shouldAddImpactToGroup")}</label>,
    fieldClassName: impact?.impactGroups?.length > 0 ? "col-12 col-lg-6" : "col-12 col-lg-6 col-space-lg-6",
    options: [
      {
        key: "controlQuestionYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "controlQuestion"
        }
      },
      {
        key: "controlQuestionNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "controlQuestion"
        }
      }
    ],
    onValueChange: function (val) {
      if (val === "yes") {
        this.fieldClassName = "col-12 col-lg-6";
        return;
      }
      this.fieldClassName = "col-12 col-lg-6 col-space-lg-6";
    },
    defaultValue: "no",
    value: impact?.impactGroups?.length > 0 ? "yes" : "no"
  };

  const impactGroup: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroups",
    label: <label htmlFor={"impactGroups"}>{I18n.t("forms.impactGroups")}</label>,
    placeholder: I18n.t("placeholders.searchImpactGroup"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpactGroup) => `${e.refNumber} - ${e.name}`}
      />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${orgId}/projects/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await impactGroupsProvider.getFilterList(orgId, projectId);

      if (res?.payload) {
        self.setOptions(res.payload);
      }
    },
    componentProps: {
      icon: "search"
    },
    searchAttribute: "name",
    fieldClassName: "col-sm-12 col-lg-6",
    extractValue: function () {
      return this.isHidden ? null : this.selectedItems.map(e => e.id);
    },
    value: impact?.impactGroups,
    isHidden: impact?.impactGroups?.length === 0
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")} *</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: "col-12 col-lg-6",
    validate: function () {
      const self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.startDate")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      icon: "calendar",
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    value: impact && moment(impact.startDate)
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "actualEndDate",
    label: <label htmlFor="actualEndDate">{I18n.t("forms.endDate")} *</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: "col-12 col-lg-6",
    subscribeTo: ["startDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      const sd = field.value;
      if (sd) {
        self.setDatepickerProps({
          isOutsideRange: day => {
            return day < sd;
          }
        });
      }
    },
    validate: function () {
      const self: IDatePickerModel = this;
      const startDate = this.channels.startDate.value;
      let res = true;

      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDate")}</ErrorMessage>;
        return false;
      }

      if (startDate > this.value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    componentProps: {
      icon: "calendar",
      datePickerProps: impact && {
        isOutsideRange: day => {
          return day < moment(impact.startDate);
        }
      }
    },
    value: impact && moment(impact.actualEndDate)
  };

  const totalPeopleImpacted: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "totalPeopleImpacted",
    placeholder: I18n.t("placeholders.totalPeopleImpacted"),
    label: <label htmlFor="totalPeopleImpacted">{I18n.t("forms.individualsImpacted")} *</label>,
    fieldClassName: "col-lg-6",
    value: impact && impact.totalPeopleImpacted ? impact.totalPeopleImpacted + "" : null,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (!Validations.IS_NUMERIC(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.notNumber")}</ErrorMessage>;
        res = false;
      }
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.individualsImpacted")}</ErrorMessage>;
        res = false;
      }
      if ((self.extractValue() + "").length > 9) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.numberToLarge")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor="progressStatus">{I18n.t("forms.progressStatus")} *</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: IMPACT_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.progressStatus")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-lg-6",
    extractValue: function () {
      return this.value?.key;
    },
    value: IMPACT_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(
      e => e.key === impact?.progressStatus
    )
  };

  const businessAreas: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "businessAreas",
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.nameWithParentName;
        }}
      />
    ),
    manageLink: `/organisations/${orgId}/settings/businessAreas`,
    label: <label htmlFor="businessAreas">{I18n.t("forms.businessAreas")}</label>,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await orgProvider.getBusinessAreas(orgId);

      if (res?.payload) {
        const sortedBusinessAreas = _.orderBy(res.payload, [businessArea => businessArea.name.toLowerCase()]);
        self.setOptions(sortedBusinessAreas);
      }
    },
    componentProps: {
      icon: "search"
    },
    searchAttribute: "name",
    fieldClassName: "col-lg-6",
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.businessAreas,
    valueLabelFn: e => e?.name
  };

  const locations: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "locations",
    placeholder: I18n.t("placeholders.searchLocation"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    label: <label htmlFor="locations">{I18n.t("forms.locations")}</label>,
    manageLink: `/organisations/${orgId}/settings/locations`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await orgProvider.getLocations(orgId);

      if (res?.payload) {
        const sortedLocations = _.orderBy(res.payload, [location => location.name.toLowerCase()]);
        self.setOptions(sortedLocations);
      }
    },
    componentProps: {
      icon: "search"
    },
    searchAttribute: "name",
    fieldClassName: "col-lg-6",
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.locations,
    valueLabelFn: e => e?.name
  };

  const stakeholderGroups: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "stakeholderGroups",
    label: <label htmlFor="stakeholderGroups">{I18n.t("forms.projectStakeholderGroups")}</label>,
    manageLink: `/organisations/${orgId}/settings/stakeholderGroups`,
    placeholder: I18n.t("placeholders.searchStakeholderGroup"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await stakeholderGroupProvider.getFilteredAsync({ filters: `organisationsId==${orgId},lifecycleStatus==0`, page: 0, pageSize: 0 }, null, orgId);

      if (res?.payload) {
        const sortedStakeholderGroups = _.orderBy(res.payload, [
          stakeholderGroup => stakeholderGroup.name.toLowerCase()
        ]);
        self.setOptions(sortedStakeholderGroups);
      }
    },
    componentProps: {
      icon: "user"
    },
    searchAttribute: "name",
    fieldClassName: "col-lg-6 col-space-lg-6",
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.stakeholderGroups,
    valueLabelFn: e => e?.name,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.stakGrpExp")}</p>
      </FormTooltip>
    )
  };

  const stakeholderSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderSearch",
    label: <label htmlFor={"stakeholderSearch"}>{I18n.t("forms.projectStakeholders")}</label>,
    manageLink: `/organisations/${orgId}/settings/stakeholders`,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "user"
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectStakeholderProvider.getStakeholdersOnly(orgId, projectId);
      if (res?.payload) {
        const sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        self.setOptions(sortedStakeholders);
      }
    },
    subscribeTo: ["projectStakeholders"],
    onItemSelected: async function () {
      const self: IAutocompleteModel = this;
      const listingModel: IListingModel = self.channels.projectStakeholders as IListingModel;
      listingModel.uniqueAttr = "projectStakeholderId";
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: "col-lg-6",
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === Enums.StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p>{I18n.t("phrases.stakIndExp")}</p>
        <p className="mb-0">{I18n.t("phrases.stakAudExp")}</p>
      </FormTooltip>
    )
  };

  const stakeholderListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "projectStakeholders",
    placeholder: I18n.t("placeholders.selectStakeholder"),
    label: <label htmlFor={"projectStakeholders"}>{I18n.t("forms.selectedProjectStakeholders")} </label>,
    fieldClassName: "col-lg-6",
    extractValue: function () {
      var projectStakeholderIds = this.value && this.value.map(e => e.projectStakeholders ? e.projectStakeholders[0].id : e.projectStakeholderId);
      return projectStakeholderIds;
    },
    selector: (pst: FP.Entities.IStakeholder) => {
      const e = pst;
      return (
        <p className="mb-0 d-inline-block">
          {convertStakeholderToName(e)}
        </p>
      );
    },
    value: impact?.projectStakeholders?.map(e => e.stakeholder)
  };

  const fields = [];

  // fields.push(stakeholderOwner);
  fields.push(shouldAddToImpactGroup);
  fields.push(impactGroup);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(totalPeopleImpacted);
  fields.push(progressStatus);
  fields.push(businessAreas);
  fields.push(locations);
  fields.push(stakeholderGroups);
  fields.push(stakeholderSearch);
  fields.push(stakeholderListing);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
