import * as React from 'react';

const SvgMusic = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="music_svg__feather music_svg__feather-music"
        {...props}
    >
        <path d="M9 18V5l12-2v13" />
        <circle cx={6} cy={18} r={3} />
        <circle cx={18} cy={16} r={3} />
    </svg>
);

export default SvgMusic;
