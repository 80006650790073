import { AppService } from "strikejs-app-service";
import { HeatmapModel } from "../../../../components/widgets/heatmap/Heatmap_model";
import { BaseModel } from "../../../../core/util/BaseModel";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { Services } from "../../../../constants";
import { ImpactsOverTimeModel } from "./impactsOverTime/ImpactsOverTime_model";
import { ImpactsByProjectsModel } from "./impactsByProjects/ImpactsByProjects_model";

export enum ImpactReportViews {
  IMPACT_BY_PROJECT = "impact-by-project",
  IMPACT_OVER_TIME = "impact-over-time",
  IMPACT_DETAILED_PROJECT = "impact-detailed-project",
  IMPACT_DETAILED_DATE = "impact-detailed-date"
}

export class ImpactReportModel extends BaseModel {
  appService: AppService;
  heatmapModel: HeatmapModel;
  dateHeatMap: HeatmapModel;
  organisation: FP.Entities.IOrganisation;
  orgContextModel: IOrganisationContextModel;
  impactsOverTimeModel: ImpactsOverTimeModel;
  impactsByProjectsModel: ImpactsByProjectsModel;
  currentView: ImpactReportViews;
  orgId: number;
  constructor(appService: AppService, organisationId: number, view: ImpactReportViews) {
    super();
    this.appService = appService;
    this.orgContextModel = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.organisation = this.orgContextModel.getCurrentOrganisation();
    this.currentView = view;
    this.orgId = organisationId;
    this.impactsOverTimeModel = new ImpactsOverTimeModel(appService, organisationId);
    this.impactsByProjectsModel = new ImpactsByProjectsModel(appService, organisationId);
    this.heatmapModel = new HeatmapModel();
    this.dateHeatMap = new HeatmapModel();
  }

  getMonthsArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setMonth(dt.getMonth() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };
}
