import * as React from "react";
import { Panel } from "../../ui/Panel";
import { AvatarContent } from "../../ui/AvatarContent";
import { observer } from "mobx-react-lite";
import { UiSizes } from "../../../enums";
import { Link } from "react-router-dom";
import { useCurrentOrganisation, useUserOrganisations } from "../../../core/auth/organisationContext";
import { OrganisationNavList } from "../../../pages/change/navigation/organisationNavList/OrganisationNavList_view";
import { INavigationModel } from "../navigation/Navigation_model";

export interface OrganisationSwitchProps {
  navigationModel: INavigationModel;
  shouldRedirectToOrganisation?: boolean;
}

const OrganisationSwitch: React.FunctionComponent<OrganisationSwitchProps> = observer(
  ({ shouldRedirectToOrganisation = false, navigationModel }) => {
    const currentOrg = useCurrentOrganisation();
    const organisationList = useUserOrganisations();

    return (
      <>
        <Panel className="organisation-switch p-4">
          <AvatarContent
            avatarProps={{
              imgSrc: currentOrg ? (currentOrg.profileImageUrl as string) : "",
              size: UiSizes.MD,
              imgLink: currentOrg ? `/organisations/${currentOrg.id}` : (null as any)
            }}
          >
            {currentOrg && (
              <Link
                onClick={() => {
                  navigationModel.setCurrentContentKey("content");
                }}
                to={`/organisations/${currentOrg.id}`}
              >
                <p className="organisation-switch__label mb-0 text-truncate">{currentOrg.name}</p>
              </Link>
            )}
            {organisationList.length > 1 && (
              <small className="organisation-switch__action mb-0" onClick={navigationModel.toggleOrganisationList}>
                {navigationModel.isOrganisationListVisible ? "Cancel" : "Change Org"}
              </small>
            )}
          </AvatarContent>
        </Panel>
        {navigationModel.isOrganisationListVisible ? (
          <OrganisationNavList
            shouldRedirectToOrganisation={shouldRedirectToOrganisation}
            onOrganisationSelected={() => navigationModel.setOrganisationListVisible(false)}
          />
        ) : null}
      </>
    );
  }
);

export { OrganisationSwitch };
