import * as React from "react";
import { Switch, Route } from "react-router";
import { ContainerProps } from ".";
import { UserActions } from "../../pages/change/dashboard/userReports/userActions/UserActions_view";
import { UserActionsModel } from "../../pages/change/dashboard/userReports/userActions/UserActions_model";
import { FilterModel, IFilterModel } from "../../core/filter/Filter_model";
import { SetUserActionFilter } from "../../pages/change/dashboard/userReports/UserReports_model";
import { UserReports } from "../../pages/change/dashboard/userReports/UserReports_view";
import { IUserReportsApi } from "../../services/api/v1/userReports/IUserReports.api";
import { Services } from "../../constants";
import { PaginationModel } from "../../components/widgets/pagination/Pagination_model";
import I18n from "../../core/localization/I18n";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import { UserStakeholders } from "../../pages/change/dashboard/userReports/userStakeholders/UserStakeholders_view";
import { UserStakeholdersModel } from "../../pages/change/dashboard/userReports/userStakeholders/UserStakeholders_model";
import { useAppService } from "../../AppService";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationSecondaryBottomContent } from "../../components/widgets/navigation/Navigation_view";
import { DashboardNav } from "../../pages/change/navigation/dashboard/DashboardNav_view";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";

export interface IDashboardContainer extends ContainerProps { }

export const DashboardContainer: React.FunctionComponent<IDashboardContainer> = ({ appService }) => {
  const organisationId = useCurrentOrganisationId();
  return (
    <>
      <NavigationSecondaryBottomContent>
        <DashboardNav />
      </NavigationSecondaryBottomContent>
      <Switch>
        <Route path="/dashboard" exact>
          <>
            <PageTitle title={I18n.t("phrases.myDashboard")} />
            <UserReports />
          </>
        </Route>
        <Route
          path="/dashboard/userStakeholders"
          render={props => {
            let userReportProvider = appService.getService<IUserReportsApi>(Services.UserReportsApi);
            let paginationModel = new PaginationModel();
            let filterModel: IFilterModel<FP.Entities.IProjectStakeholder[]> = new FilterModel(
              appService,
              paginationModel,
              {
                filterCb: async filterOptions => await userReportProvider.getUserStakeholderAsync(filterOptions)
              }
            );

            return (
              <>
                <UserStakeholders model={new UserStakeholdersModel(appService, props, filterModel, paginationModel)} />
              </>
            );
          }}
        />
        <Route path={`/dashboard/overdueActions/:type`}>
          <OverdueActionsContent />
        </Route>
        <Route
          path={`/dashboard/upcomingActions/:type`}
          render={props => {
            let userReportProvider = appService.getService<IUserReportsApi>(Services.UserReportsApi);
            let paginationModel = new PaginationModel();
            let filterModel: IFilterModel<FP.Entities.IAction> = new FilterModel(appService, paginationModel, {
              filterCb: async filterOptions => await userReportProvider.getUpcomingActionsAsync(filterOptions)
            });

            let userActionsModel = new UserActionsModel(appService, filterModel, organisationId);
            userActionsModel.title = (
              <h1 className="d-inline-block mb-0 mt-1">
                {I18n.t("phrases.myActions")} | {I18n.t("phrases.upcomingActions")}
              </h1>
            );
            userActionsModel.paginationModel = paginationModel;
            SetUserActionFilter(organisationId, filterModel, userActionsModel.setActions, "upcomingActions");
            filterModel.setFilterValue("userRelation", props.match.params["type"]);
            paginationModel.setConfig({ onPageClick: userActionsModel.loadPageData });
            return (
              <>
                <PageTitle title={`${I18n.t("phrases.myDashboard")} | ${I18n.t("phrases.upcomingActions")}`} />
                <UserActions model={userActionsModel} />
              </>
            );
          }}
        />
      </Switch>
    </>
  );
};

const OverdueActionsContent: React.FC<{}> = () => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const { type } = useParams() as any;
  let userReportProvider = appService.getService<IUserReportsApi>(Services.UserReportsApi);
  const [paginationModel] = useState(() => new PaginationModel());
  let filterModel: IFilterModel<FP.Entities.IAction> = new FilterModel(appService, paginationModel, {
    filterCb: async filterOptions => await userReportProvider.getOverdueActionsAsync(filterOptions)
  });

  let userActionsModel = new UserActionsModel(appService, filterModel, organisationId);
  userActionsModel.title = (
    <h1 className="d-inline-block mb-0 mt-1">
      {I18n.t("phrases.myActions")} | {I18n.t("phrases.overdueActions")}
    </h1>
  );
  userActionsModel.paginationModel = paginationModel;
  SetUserActionFilter(organisationId, filterModel, userActionsModel.setActions, "overdueActions");
  filterModel.setFilterValue("userRelation", type);
  paginationModel.setConfig({ onPageClick: userActionsModel.loadPageData });
  return (
    <>
      <PageTitle title={`${I18n.t("phrases.myDashboard")} | ${I18n.t("phrases.overdueActions")}`} />
      <UserActions model={userActionsModel} />
    </>
  );
};
