import * as React from "react";
import { AutocompleteProps, Autocomplete } from "../Autocomplete";
import { Pill } from "../../Pill";
import { observer } from "mobx-react-lite";
import { Icon } from "../../Icon";
import { Tooltip, TooltipIcon } from "../../Tooltip";
import { Panel } from "../../Panel";

export interface MultiSelectorProps {
  autocompleteProps: AutocompleteProps;
  selectedItems: any[];
  optionElement: React.ReactNode;
  removeItem: (item: any, index: number) => void;
  valueLabelFn: (obj: any) => string;
  lowerTooltipLabel?: string;
  lowerTooltipContent?: React.ReactNode;
}

const tooltipHtml = (content: React.ReactNode): React.ReactElement => {
  return (
    <Panel background="bg-white" className="p-3" hasBorderRadius={true} type="border-lines">
      <p style={{ width: "200px" }}>
        {content}
      </p>
    </Panel>
  );
};

const tooltipLowerLabel = (text: string): React.ReactElement => {
  return (
    <div>
      <small>{text}</small> <TooltipIcon />
    </div>
  );
};

const MultiSelector: React.FunctionComponent<MultiSelectorProps> = observer(props => (
  <div className="multi-selector">
    <div className="mb-3">
      <Autocomplete {...props.autocompleteProps}>{props.optionElement}</Autocomplete>
    </div>
    {props.lowerTooltipContent !== null && props.lowerTooltipContent !== undefined && (
      <Tooltip
        shownElement={tooltipLowerLabel(props.lowerTooltipLabel)}
        triggeredOn="hover"
        className="float-right"
        position="bottom"
      >
        {tooltipHtml(props.lowerTooltipContent)}
      </Tooltip>
    )}

    <div className="multi-selector_menu">
      {Array.isArray(props.selectedItems) &&
        props.selectedItems.map((e, i) => {
          if (e === null) {
            return null;
          }
          return (
            <Pill className="m-1" key={i}>
              <p className="mb-0 d-inline-block">{props.valueLabelFn(e)}</p>
              <span onClick={ee => props.removeItem(e, i)}>
                <Icon symbol="Close" className="ml-2" />
              </span>
            </Pill>
          );
        })}
    </div>
  </div>
));

export { MultiSelector };
