import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Async } from "react-async";
import { NavLink, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { Icon } from "../../../../components/ui/Icon";
import { MultiLinePlaceholder } from "../../../../components/ui/Placeholder";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { useCurrentOrganisation } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { getAppToken } from "../../../../setup";
import { ImpactReportViews } from "../../organisations/impactReport/ImpactReport_model";
import { ProjectListNav } from "../projectListNav/ProjectListNav_view";

interface INavigationContext {
  back: () => void;
  currentLevel: INavigationLevel | null;
  setCurrentLevel(id: string): void;
}

interface INavigationLevel {
  id: string;
  content: React.ReactNode;
}

export interface INavigationItem {
  resourceId: number;
  type: "project" | "programme";
  parentId: number | null;
  name: string;
}

export type INavigationResult = { projects: INavigationItem[]; programmes: INavigationItem[] };

const NavContext = React.createContext<INavigationContext>({
  back: () => { },
  currentLevel: null,
  setCurrentLevel: () => { }
});

export const NavigationProvider: React.FC = observer(({ children }) => {
  const location = useLocation();
  const initialKey =
    location.pathname.includes("/programmes/") || location.pathname.includes("/projects/")
      ? "programme"
      : "organisations";
  const [currentLevel, setLevel] = useState<INavigationLevel | null>();
  const [currentKey, setCurrentKey] = useState<string | null>(initialKey);
  const [levels, setLevels] = useState<INavigationLevel[]>(() => []);
  const history = useHistory();
  const [{ programmes, projects }, setResult] = useState<INavigationResult | null>({ projects: [], programmes: [] });

  const currentOrganisation = useCurrentOrganisation();
  const organisationId = currentOrganisation.id;

  const load = useCallback(async () => {
    const resp = await fetch(
      `${window.appConfig.flightPathCoreApiUrl}/api/v1/navigation/organisation/${organisationId}`,
      {
        headers: {
          authorization: `Bearer ${await getAppToken()}`
        }
      }
    );

    if (resp.status === 404) {
      throw new Error(`Organisation ${organisationId} does not exist`);
    }

    const { payload } = (await resp.json()) as { payload: INavigationItem[] };
    const result = {
      projects: payload.filter(e => e.type === "project"),
      programmes: payload.filter(e => e.type === "programme")
    } as INavigationResult;

    setResult(result);
    return result;
  }, [organisationId, setResult]);

  const back = useCallback(() => { }, []);

  useEffect(() => {
    if (currentKey) {
      const level = levels.find(e => e.id === currentKey);
      if (level) {
        setLevel(level);
      }
    }
  }, [levels, currentKey, setLevel]);

  useEffect(() => {
    setLevels([
      {
        id: "organisations",
        content: <OrganisationLevelNav organisationId={organisationId} />
      },
      {
        id: "programme",
        content: (
          <Switch>
            <Route path="/organisations/:organisationId/programmes/:programmeId">
              <ProgrammeNavWrapper programmes={programmes} projects={projects} />
            </Route>
            <Route path="/organisations/:organisationId/projects/:projectId">
              <ProgrammeNavWrapper programmes={programmes} projects={projects} />
            </Route>
          </Switch>
        )
      }
    ]);
  }, [organisationId, setLevels, programmes, projects]);

  useEffect(() => {
    return history.listen(loc => {
      const key =
        loc.pathname.includes("/programmes/") || loc.pathname.includes("/projects/") ? "programme" : "organisations";
      setCurrentKey(key);
    });
  }, [history, setCurrentKey]);

  return (
    <NavContext.Provider value={{ back, currentLevel, setCurrentLevel: setCurrentKey }}>
      <Async promiseFn={load}>{children}</Async>
    </NavContext.Provider>
  );
});

export const CurrentNavigation: React.FC = () => {
  const { currentLevel } = useContext(NavContext);

  return currentLevel ? (currentLevel.content as any) : null;
};

export function useNavigationContext() {
  return useContext(NavContext);
}

const OrganisationLevelNav: React.FC<{ organisationId: number }> = ({ organisationId }) => {
  const currentOrganisation = useCurrentOrganisation();
  useOrganisationSwitch(true);
  return (
    <div className="content-nav">
      <div className="content-nav--org-report mb-5">
        <NavLink
          to={`/organisations/${currentOrganisation.id}/impact-report/${ImpactReportViews.IMPACT_BY_PROJECT}`}
          activeClassName="navigation__link--active"
          className={`navigation__link ${Animations.ZOOM_IN} speed-1`}
        >
          {I18n.t("phrases.orgReport")}
          <div className="ml-auto">
            <Icon symbol="ChevronRight" />
          </div>
        </NavLink>
      </div>
      <div className="content-nav__programmes mb-5">
        <h5 className={`navigation__light-title text-uppercase`}>{I18n.t("entities.programmes")}</h5>
        <Async.Loading>
          <MultiLinePlaceholder type="secondary" className="mb-3" />
        </Async.Loading>
        <Async.Resolved<INavigationResult>>
          {({ programmes }) =>
            programmes.length ? (
              programmes.map((e, i) => {
                return (
                  <NavLink
                    key={e.resourceId}
                    activeClassName="navigation__link--active"
                    to={`/organisations/${organisationId}/programmes/${e.resourceId}`}
                    className={`navigation__link ${Animations.ZOOM_IN} speed-1 delay-${i}`}
                  >
                    {e.name}
                    <div className="ml-auto">
                      <Icon symbol="ChevronRight" />
                    </div>
                  </NavLink>
                );
              })
            ) : (
                <div className="content-nav__info">{I18n.t("phrases.noProgrammesFound")}</div>
              )
          }
        </Async.Resolved>
        <Async.Rejected>{err => err.message}</Async.Rejected>
      </div>
      <div className="content-nav__projects mb-5">
        <h5 className={`navigation__light-title text-uppercase`}>{I18n.t("entities.projects")}</h5>
        <Async.Loading>
          <MultiLinePlaceholder type="secondary" className="mb-3" />
        </Async.Loading>
        <Async.Resolved<INavigationResult>>
          {({ projects }) =>
            projects.length ? (
              projects.map((e, i) => {
                return <ProjectNavLink key={e.resourceId} index={i} organisationId={+organisationId} item={e} />;
              })
            ) : (
                <div className="content-nav__info">{I18n.t("phrases.noProjectsFound")}</div>
              )
          }
        </Async.Resolved>
        <Async.Rejected>{err => err.message}</Async.Rejected>
      </div>
    </div>
  );
};

const ProgrammeNavWrapper: React.FC<{ projects: INavigationItem[]; programmes: INavigationItem[] }> = ({
  projects,
  programmes
}) => {
  const { programmeId, projectId } = useParams<{ programmeId?: string; projectId?: string }>();
  const project = projects.find(e => `${e.resourceId}` === projectId);
  const projectsToShow = project
    ? projects.filter(e => project.parentId && `${e.parentId}` === `${project.parentId}`)
    : projects.filter(e => `${e.parentId}` === programmeId);

  if (programmes.length > 0) {
    return <ProjectListNav
      programme={
        project
          ? programmes.find(e => `${e.resourceId}` === `${project.parentId}`)
          : (programmes.find(e => `${e.resourceId}` === programmeId) as INavigationItem)
      }
      project={project}
      projects={projectsToShow}
    />
  } else if (project) {
    return <ProjectListNav
      project={project}
      projects={projectsToShow}
    />
  } else {
    return <div className="content-nav__info">
      {I18n.t("phrases.noProgrammesFound")}
    </div>
  }

};

const ProjectNavLink: React.FC<{
  item: INavigationItem;
  organisationId: number;
  index: number;
}> = ({ item: e, index: i, organisationId }) => {
  return (
    <NavLink
      to={`/organisations/${organisationId}/projects/${e.resourceId}`}
      key={e.resourceId}
      className={`navigation__link ${Animations.ZOOM_IN} speed-1 delay-${i}`}
      activeClassName="navigation__link--active"
    >
      {e.name}
      <div className="ml-auto">
        <Icon symbol="ChevronRight" />
      </div>
    </NavLink>
  );
};
