import * as React from "react";
import { ITableConfig } from "../../../../../core/table/ITableModel";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { CustomPropertiesModel } from "./CustomProperties_model";
import { Checkbox } from "../../../../../components/ui/Checkbox";
import { CheckboxCustomPropertyIsRequired } from "../../../organisations/settingsView/customProperties/CustomProperties_config";


export const GetCustomPropertiesTableConfig = (
  parentModel: CustomPropertiesModel
): ITableConfig<FP.Entities.ICustomProperty> => {
  return {
    colHeaders: [
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "customEntityType",
        content: <h4 className="mb-0">{I18n.t("table.customPropertyEntityTypeName")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.entityType.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "customPropertyType",
        content: <h4 className="mb-0">{I18n.t("table.customPropertyTypeName")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => obj.propertyType.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "isRequired",
        content: <h4 className="mb-0">{I18n.t("table.isRequired")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => <CheckboxCustomPropertyIsRequired
          onChange={async (value) => {
            const result = await parentModel.setCustomPropertyIsRequired(obj.id, value);
            return result.payload;
          }}
          obj={obj} />,
        showSortIcons: false
      },
      {
        key: "canOvverride",
        content: <h4 className="mb-0">{I18n.t("table.canOvverride")}</h4>,
        selector: (obj: FP.Entities.ICustomProperty) => {
          return <Checkbox
            disabled={true}
            checked={obj.isAppliedOnAllProjects}
          />
        },
        showSortIcons: false
      }
    ] as any[],
    actions: parentModel.permissionService.canContributeOrg
      ? [
        {
          id: "action1",
          label: I18n.t("phrases.manage"),
          rendersIn: "HTMLAnchor",
          componentProps: {
            type: "link"
          },
          hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/settings/customProperties/${context.rowObject.id}`
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          rendersIn: "Button",
          componentProps: {
            type: "link"
          },
          onAction: (ev, row: ITableRowModel) => {
            parentModel.showConfirmDeleteModal(row.rowObject);
          }
        }
      ]
      : []
  };
};
