import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action, runInAction } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { Services } from "../../../../constants";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { appHistory } from "../../../../setup";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { getImpactGroupForm } from "../../forms/impactGroups/ImpactGroupFormSection_data";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";

interface IEditImpactGropViewModelOptions {
    appService: AppService;
    impactGroupId: number;
    projectId: number;
    organisationId: number;
    user: FP.Entities.IUser;
}

export class EditImpactGroupViewModel extends BaseModel {
    appService: AppService;
    cookieService: ICookieService;
    projectId: number;
    impactGroupId: number;
    impactProvider: IImpactsApi;
    orgId: number;
    orgProvider: IOrganisationsApi;
    impactGroupProvider: IImpactGroupsApi;
    httpProgress: IHttpProgressModel;
    actionTypeProvider: IActionTypeApi;
    @observable isLoading: boolean = true;
    @observable.ref impactGroup: FP.Entities.IImpactGroup;
    @observable.ref formModel: SingleFormModel;
    user: FP.Entities.IUser;

    constructor({ appService, projectId, impactGroupId, user, organisationId }: IEditImpactGropViewModelOptions) {
        super();
        this.appService = appService;
        this.projectId = projectId;
        this.impactGroupId = impactGroupId;
        this.user = user;

        this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
        this.impactGroupProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
        this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
        this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
        this.actionTypeProvider = this.appService.getService<IActionTypeApi>(Services.ActionTypesApi);
        this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
        this.formModel = new SingleFormModel();
        this.orgId = organisationId;
    }

    onMount = async () => {
        await this.loadImpactGroup();
        this.formModel.formFields = getImpactGroupForm(this.impactProvider, this.projectId, this.orgId, this.impactGroup);

        this.formModel.actions = [
            {
                id: "cancel",
                label: I18n.t("phrases.cancel"),
                rendersIn: "LinkButton",
                componentProps: {
                    type: "link",
                    className: "ml-auto",
                    href: `/organisations/${this.orgId}/projects/${this.projectId}/impactGroups/${this.impactGroupId}`
                }
            },
            {
                id: "save",
                label: I18n.t("phrases.save"),
                rendersIn: "Button",
                componentProps: {
                    type: "primary",
                    className: "ml-2"
                },
                onAction: this.updateImpactGroup
            }
        ];
    };

    onUnmount = () => { };

    @action
    loadImpactGroup = async () => {
        let res = await this.impactGroupProvider.getDetailed(this.orgId, this.projectId, this.impactGroupId);
        if (!res || res.isError) return;

        runInAction(() => {
            this.impactGroup = res.payload;
        });
    };

    @action
    updateImpactGroup = async () => {
        let res = await this.formModel.submit();
        if (!res) return;
        res.refNumber = (this.impactGroup as any).refNumber;
        this.httpProgress.showOverlay();
        let result = await this.impactGroupProvider.update(this.orgId, this.projectId, this.impactGroupId, res as FP.Entities.IImpactGroup);
        this.httpProgress.hideOverlay();

        if (!result || result.isError) return;

        this.impactGroup = result.payload;
        appHistory.push(`/organisations/${this.orgId}/projects/${this.projectId}/impactGroups/${this.impactGroupId}`);
    };
}
