import * as React from "react";
import * as _ from "lodash";
import { IInnerNavContentModel } from "./InnerNavContent_model";
import { observer } from "mobx-react-lite";

export interface InnerNavContentProps {
  model: IInnerNavContentModel;
}

const InnerNavContent: React.FunctionComponent<InnerNavContentProps> = observer(({ model }: any) => {
  return (
    <div className={`inner-nav-content`}>
      {model.config && Object.keys(model.config.items).length > 0 && (
        <div className={`inner-nav-content__block inner-nav-content__block--nav ${model.blockClassName}`}>
          <h5 className="text-uppercase pl-3 mb-3">{model.config.navigationTitle}</h5>
          <ul className="inner-nav-content__list">
            {model.config &&
              model.config.items &&
              _.map(model.config.items, (e, i) => {
                return model.config.displayNavWithLinks && model.config.linkRender ? (
                  <li
                    key={e.key}
                    className={`mb-1 inner-nav-content__item ${e.key === model.currentViewKey ? "inner-nav-content__item--active" : ""
                      }`}
                  >
                    {model.config.linkRender(e)}
                  </li>
                ) : (
                  <li
                    key={e.key}
                    onClick={() => model.setCurrentViewKey(e.key)}
                    className={`mb-1 inner-nav-content__item ${e.key === model.currentViewKey ? "inner-nav-content__item--active" : ""
                      }`}
                  >
                    <h4 className="mb-0">{e.label}</h4>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
      <div className="inner-nav-content__block inner-nav-content__block--content">
        {model.currentViewKey && model.config.items[model.currentViewKey].content}
      </div>
    </div>
  );
});

export { InnerNavContent };
