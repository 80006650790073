import * as React from "react";
import { StakeholderViewModel } from "./StakeholderView_model";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react-lite";
import { StakeholderListView } from "../../stakeholders/stakeholderListView/StakeholderListView_view";

export interface StakeholderViewProps {
  model: StakeholderViewModel;
}

const StakeholderView: React.FunctionComponent<StakeholderViewProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return (
      <h2 className="mb-0">{`${title} ${countText}`}</h2>
    );
  };

  return (
    <div className="stakeholder-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-7">
            <h1>
              {model.project?.name || ""} | <strong>{I18n.t("phrases.stakeholders")}</strong>
            </h1>
            <p className="mb-5">{I18n.t("phrases.stakeholderViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        className="tabs--primary mb-5"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        initialTab={0}
        onTabClicked={model.changeCurrentView}>
        <Tab title={ tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount )}></Tab>
        <Tab title={ tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount) }></Tab>
        {model.showVisualisations === true && (
          <Tab title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}></Tab>
        )}
      </Tabs>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="mt-4">
              <StakeholderListView key="kee" model={model.stakeholderListViewModel} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { StakeholderView };
