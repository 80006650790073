import I18n from "./core/localization/I18n";

export enum CompanySize {
  TINY = 1,
  SMALL = 2,
  MEDIUM = 3,
  LARGE = 4,
  SMALL_ENTERPRISE = 5,
  LARGE_ENTERPRISE = 6
}

export enum ImpactCompletionState {
  Complete = 1,
  Incomplete = 2
}

export enum StakeholderGroupType {
  PROJECT,
  PROGRAMME
}

export enum StakeholderType {
  INDIVIDUAL = 1,
  AUDIENCE = 2
}

export enum RagStatus {
  RED = 1,
  AMBER = 2,
  GREEN = 3,
  COMPLETE = 4
}

export enum RagStatusString {
  RED = "red",
  AMBER = "amber",
  GREEN = "green"
}

export enum ProgressStatus {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3
}

export enum ImpactType {
  PEOPLE = 1,
  PROCESS = 2,
  TECHNOLOGY = 3
}

export enum ImpactLevel {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3
}

export enum AttitudeLevel {
  NEGATIVE = 1,
  NEUTRAL = 2,
  POSITIVE = 3
}

export enum ConcernStatus {
  ACTIVE = 1,
  CLOSED = 2,
  IRRELEVANT = 3
}

export enum LifecycleStatus {
  Active = 0,
  Archived = 1,
  Deleted = 2
}

//Application Events
export enum AppEventType {
  BeforeStart = 0x01,
  AfterStart = 0x02,
  BeforeCacheStart = 0x03,
  AfterCacheStart = 0x04
}

//Application Errors for test
export enum ErrorCode {
  NullPointerException = 0x00001,
  NetworkDown = 0x00002,
  RequestFailed = 0x00003,
  AccessDenied = 0x00004,
  FormValidationFailed = 0x00005,
  FieldValidationFailed = 0x00006,
  RequestTimeout = 0x00007,
  ResourceUnreachable = 0x00008
}

//Application Log Constants
export enum LogLevel {
  Diagnostics = 0x001,
  Info = 0x002,
  Warning = 0x004,
  Error = 0x008,
  All = 0x0f
}

export enum UiSizes {
  XXS = "xxs",
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg"
}

export enum PermissionScope {
  NOACCESS = 0,
  READER = 100,
  CONTRIBUTOR = 200,
  OWNER = 280,
  ADMIN = 300
}

export enum LocalCookies {
  ORGANISATION_ID = ".FlightPath.client.orgId",
  RECENT_PROJECTS = ".FlightPath.client.recentProjects"
}

export enum ChangeType {
  NEUTRAL = 0,
  NEGATIVE = 1,
  POSITIVE = 2
}

export enum ChangeEventType {
  CREATE = 1,
  UPDATE = 2
}

export enum SettingType {
  CUSTOM_PROPERTIES_ENABLED = 1,
  VISUALISATIONS_ENABLED = 2
}

const profileLabelGenerator = (val: AttitudeLevel | number) => {
  const con = I18n.t("phrases.concerned");
  const neu = I18n.t("phrases.neutral");
  const adv = I18n.t("phrases.advocate");
  let s = ["", con, con, con, con, neu, neu, neu, adv, adv, adv, adv];
  return s[val] || "Unknown";
};
const secondarProfileLabelGenerator = (val: AttitudeLevel | number) => {
  const con = I18n.t("phrases.cautious");
  const neu = I18n.t("phrases.neutral");
  const adv = I18n.t("phrases.advocate");
  let s = ["", con, con, con, con, neu, neu, neu, adv, adv, adv, adv];
  return s[val] || "Unknown";
};
export const Translator = {
  SentimentLevel: profileLabelGenerator,
  ReceptivenessLevel: profileLabelGenerator,
  CommitmentLevel: secondarProfileLabelGenerator,
  ImpactType: (val: ImpactType | number) => {
    let s = ["", "People", "Process", "Technology"];
    return s[val];
  },
  ImpactLevel: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
    return s[val] || "Unknown";
  },
  MitigationConfidence: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
    return s[val] || "Unknown";
  },
  ImpactLevelShort: (val: ImpactLevel | number) => {
    let s = ["", "L", "L", "L", "L", "M", "M", "M", "H", "H", "H", "H"];
    return s[val] || "-";
  },
  ImpactLevelMin: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Medium", "High"];
    return s[val];
  },
  InfluenceLevel: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
    return s[val] || "Unknown";
  },
  InfluenceLevelMin: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Medium", "High"];
    return s[val];
  },
  Metric: (val: ImpactLevel | number) => {
    let s = ["", "Low", "Low", "Low", "Low", "Medium", "Medium", "Medium", "High", "High", "High", "High"];
    return s[val] || "Unknown";
  },
  PermissionScope: (val: PermissionScope | number) => {
    switch (val) {
      case PermissionScope.ADMIN:
        return "phrases.admin";
      case PermissionScope.OWNER:
        return "phrases.owner";
      case PermissionScope.CONTRIBUTOR:
        return "phrases.contributor";
      case PermissionScope.NOACCESS:
        return "phrases.noAccess";
      case PermissionScope.READER:
        return "phrases.reader";

      default:
        return "phrases.unknown";
    }
  },
  MetricToEnum: (val: number) => {
    if (val < 5) {
      return ImpactLevel.LOW;
    } else if (val >= 5 && val <= 7) {
      return ImpactLevel.MEDIUM;
    } else {
      return ImpactLevel.HIGH;
    }
  },
  ProgressStatus: (val: ProgressStatus | number) => {
    let s = ["Draft", "Not Started", "In Progress", "Completed"];
    return s[val];
  },
  ImpactProgressStatus: (val: any) => {
    let s = ["Draft", "Not Started", "In Progress", "Mitigated"];
    return s[val];
  },
  ConcernStatus: (val: ConcernStatus | number) => {
    let s = ["", "Active", "Closed", "No longer relevant"];
    return s[val];
  },
  RagStatus: (val: RagStatus | number) => {
    let s = ["", "Red", "Amber", "Green", "Complete"];
    return s[val];
  }
};

export const getStatusAccentFromImpactLevel = (val: number): any => {
  const level = Translator.MetricToEnum(val);

  const s = ["", "indicator-accent-1", "indicator-accent-2", "indicator-accent-3"];
  return s[level];
};

export const Enums = {
  CompanySize,
  StakeholderGroupType,
  StakeholderType,
  RagStatus,
  RagStatusString,
  ProgressStatus,
  ImpactType,
  ImpactLevel,
  AttitudeLevel,
  ConcernStatus,
  LifecycleStatus,
  AppEventType,
  ErrorCode,
  LogLevel,
  UiSizes,
  PermissionScope,
  LocalCookies,
  ChangeType,
  ChangeEventType,
  Translator,
  ImpactCompletionState,
  SettingType
};
