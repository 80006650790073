// TODO - Make Milestones settings reusable across projects and programmes

import React from "react";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../../constants";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { ButtonIcon } from "../../../../../components/ui/Button";
import { MilestoneModal } from "./MilestoneModal_view";
import { MilestoneModalModel } from "./MilestoneModal_model";
import { IMilestonesApi } from "../../../../../services/api/v1/milestones/IMilestones.api";
import I18n from "../../../../../core/localization/I18n";
import moment from "moment";
import { Animations } from "../../../../../core/util/Animations";

export class MilestoneSettingsModel extends BaseModel {
  id: number;
  @observable.ref project?: FP.Entities.IProject;
  appService: AppService;
  projectsProvider: IProjectsApi;
  milestoneProvider: IMilestonesApi;
  tableModel: ITableModel<FP.Entities.IMilestone>;
  modalService: IModalService;
  organisationId: number;

  constructor(appService: AppService, project: FP.Entities.IProject, organisationId: number) {
    super();
    this.appService = appService;
    this.projectsProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.milestoneProvider = this.appService.getService<IMilestonesApi>(Services.MilestonesApi);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.organisationId = organisationId;
    this.id = project.id;
    this.tableModel = new TableModel();
    let tableConfig: ITableConfig<any> = {
      colHeaders: [
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => obj.name
        },
        {
          key: "deadline",
          content: <h4 className="mb-0">{I18n.t("table.milestoneDate")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => moment(obj.deadline).format("L")
        }
      ] as any[],
      actions: [
        {
          id: "edit",
          label: I18n.t("phrases.edit"),
          onAction: (ev, row: ITableRowModel) => {
            this.showMilestoneModal(row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            this.showConfirmDeleteModal(row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        }
      ]
    };

    this.tableModel.set(tableConfig);
    this.setProject(project);
  }

  onMount() { }

  @action
  async loadProject() {
    this.tableModel.isLoading = true;
    const res = await this.projectsProvider.getById(this.organisationId, this.id);
    if (res) {
      this.setProject(res.payload);
    }
  }

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
    this.tableModel.setData(project.milestones);
    this.tableModel.isLoading = false;
  };

  showModal = (row: ITableRowModel) => {
    this.showMilestoneModal(row.rowObject);
  };

  @action
  showMilestoneModal = (milestone?: FP.Entities.IMilestone) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon symbol="close" className="float-right mr-1" onClick={this.modalService.hide} />
        </div>
      ),
      content: (
        <MilestoneModal
          model={new MilestoneModalModel(this.appService, this.organisationId, milestone, () => this.loadProject(), this.project.id)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        overflow: "visible",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
      }
    });
  };

  showConfirmDeleteModal = (milestone: FP.Entities.IMilestone) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: milestone.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          const res = await this.milestoneProvider.deleteProjectMilestone(this.organisationId, this.project.id, milestone.id);
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.modalService.hide();
          this.loadProject();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
