import * as React from 'react';

const SvgMonitor = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="monitor_svg__feather monitor_svg__feather-monitor"
        {...props}
    >
        <rect x={2} y={3} width={20} height={14} rx={2} ry={2} />
        <path d="M8 21h8M12 17v4" />
    </svg>
);

export default SvgMonitor;
