import { IFilteredOptions, IFilteredResponse } from "../filteredApi/FilteredApiModel";
import { IFilteredProjectApiModel, FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import { AxiosRequestConfig } from "axios";

export interface IExportableProjectApi<T> extends IFilteredProjectApiModel<T> {
  exportData: (
    organisationId: number,
    projectId: number,
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => Promise<IFilteredResponse<T>>;
}

export abstract class ExportableProjectApi<T> extends FilteredProjectApiModel<T> implements IExportableProjectApi<T> {
  abstract controller: string;

  exportData = async (
    organisationId: number,
    projectId: number,
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${
      this.controller
    }/export${query}&columns=${columns.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
