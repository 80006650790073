import * as React from "react";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action, reaction } from "mobx";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { ImpactStakeholdersModel } from "../ImpactStakeholders/ImpactStakeholders_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { ImpactActionsModel } from "../impactActions/ImpactActions_model";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";

interface IImpactExtendedViewModelOptions {
  appService: AppService;
  projectId: number;
  impactId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}

export class ImpactExtendedViewModel extends DisposableModel {
  appService: AppService;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  projectId: number;
  impactId: number;
  impactStakeholderModel: ImpactStakeholdersModel;
  impactActionsModel: ImpactActionsModel;
  httpProgress: IHttpProgressModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;

  constructor({ appService, projectId, impactId, authUser, organisationId }: IImpactExtendedViewModelOptions) {
    super();
    this.appService = appService;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.projectId = projectId;
    this.impactId = impactId;
    this.organisationId = organisationId;
    this.impactStakeholderModel = new ImpactStakeholdersModel(this.appService, projectId, impactId, organisationId, false);
    this.impactActionsModel = new ImpactActionsModel(this.appService, organisationId, projectId, impactId, false);
    this.commentViewModel = new CommentListViewModel(appService, projectId, authUser, {
      searchAttribute: "impactId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId
    });

    this.revisionHistoryModel = new RevisionHistoryModel(appService, {
      entityId: this.impactId,
      projectId: this.projectId,
      historyType: "impacts",
      organisationId
    });

    this.installReactions();
  }

  @action.bound
  async load() {
    await this.loadImpact();
    this.impactStakeholderModel.setImpact(this.impact);
    this.commentViewModel.setConfig({
      id: this.impact.id,
      description: <p className="mb-0">{I18n.t("phrases.impactCommentsDescription")}</p>
    });
  }

  @action
  loadImpact = async () => {
    this.isLoading = true;
    const res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, this.impactId);

    if (!res || res.isError) {
      return;
    }

    this.setImpact(res.payload);
    this.isLoading = false;
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  onUnmount = () => {
    this.dispose();
  };

  installReactions = () => {
    var d = reaction(
      () => {
        return this.impact;
      },
      (e, reaction) => {
        this.impactStakeholderModel.setImpact(e);
        // this.impactActionsModel.setImpact(e);
      }
    );

    this.addDisposer(d);
  };
}
