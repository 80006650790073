import * as React from "react";
import { Animations } from "../../../../core/util/Animations";

export interface INotFoundIndex {}

export const NotFoundIndex: React.FunctionComponent<INotFoundIndex> = () => {
  return (
    <div className={`not-found-view pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-5">
          <h3>Page Not Found</h3>
        </div>
      </div>
    </div>
  );
};
