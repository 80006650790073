import * as React from 'react';

const SvgList = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="list_svg__feather list_svg__feather-list"
        {...props}
    >
        <path d="M8 6h13M8 12h13M8 18h13M3 6h0M3 12h0M3 18h0" />
    </svg>
);

export default SvgList;
