import { IStakeholderGroupsApi } from "./IStakeholderGroups.api";
import { IHttp } from "../../IHttp";
import { FilteredOrganisationApiModel } from "../../filteredApi/FilteredOrganisationApiModel";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { AxiosRequestConfig } from "axios";

export class StakeholderGroupsApi
  extends FilteredOrganisationApiModel<FP.Entities.IStakeholderGroup>
  implements IStakeholderGroupsApi {
  controller: string = "stakeholder-groups";

  constructor(http: IHttp) {
    super(http, "stakeholder-groups");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getStakeholders = async (
    organisationId: number,
    stGroupId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    const url =
      `${this.url}/${organisationId}/${this.controller}/${stGroupId}/stakeholders` +
      ((filterOptions && this.getRequestQuery(filterOptions)) || "");
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholder[]>;
    }
  };

  removeStakeholder = async (
    organisationId: number,
    groupId: number,
    stakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/stakeholder-groups/${groupId}/stakeholders/${stakeholderId}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
  };
}
