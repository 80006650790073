import * as React from "react";
import { Route, Switch } from "react-router";
import { CanViewOrgRoute } from "../../core/router/Route";
import { ContainerProps } from ".";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { StakeholderView } from "../../pages/change/stakeholders/view/View_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";

export interface IStakeholdersContainer extends ContainerProps {}

const StakeholdersContainer: React.FunctionComponent<IStakeholdersContainer> = ({ appService }) => {
  return (
    <Switch>
      <Route path="/organisations/:organisationId/stakeholders/:stakeholderId">
        <CanViewOrgRoute>
          <PageTitle title={I18n.t(`phrases.stakeholders`)} />
          <StakeholderView />
        </CanViewOrgRoute>
      </Route>
      <Route path="*">
        <>
          <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
          <NotFoundIndex />
        </>
      </Route>
    </Switch>
  );
};

export { StakeholdersContainer };
