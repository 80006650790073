import React from "react";
import { BaseModel } from "../../../core/util/BaseModel";
import { observable, action, computed } from "mobx";
import moment from "moment";
import { ReactCalendarItemRendererProps, ReactCalendarGroupRendererProps } from "react-calendar-timeline";
import I18n from "../../../core/localization/I18n";

// Zoom Ratio - This will increase and decrease the zoom by 10%
const TIMELINE_ZOOM_RATIO = 0.1;

// Scroll Ratio - This will scroll the viewport by 25%
const TIMELINE_SCROLL_RATIO = 0.25;

// Minimum Zoom for Timeline (in days)
export const TIMELINE_MIN_ZOOM = 30;

// Maximum Zoom for Timeline (in days)
export const TIMELINE_MAX_ZOOM = 2 * 365;

// Minimum number of rows to display in Timeline
export const TIMELINE_MIN_ROWS = 3;

export interface ITimelineGroup {
  id: number | string;
  title: string | React.ReactNode;
  isRoot?: boolean;
  parent?: number | string;
}

export interface ITimelineItem {
  id: number | string;
  group: number | string;
  title: string;
  start_time: moment.Moment;
  end_time: moment.Moment;
  data?: any;
}

export interface ITimelineMarker {
  id: number;
  date: moment.Moment;
  title?: string;
}

export class TimeLineModel extends BaseModel {
  // Converts timeline min/max zoom from days to milliseconds
  minZoom: number = TIMELINE_MIN_ZOOM * 24 * 60 * 60 * 1000;
  maxZoom: number = TIMELINE_MAX_ZOOM * 24 * 60 * 60 * 1000;
  noItemsText: string = I18n.t("phrases.noTimelineItems");
  isEditable: boolean = false;
  canEdit: boolean = false;
  @observable isLoading = true;
  @observable.ref defaultStartDate: any | moment.Moment;
  @observable.ref defaultEndDate: any | moment.Moment;
  @observable.ref visibleTimeStart: any | moment.Moment;
  @observable.ref visibleTimeEnd: any | moment.Moment;
  @observable.ref groups: ITimelineGroup[] = [];
  @observable.ref items: ITimelineItem[] = [];
  @observable.ref markers: ITimelineMarker[] = [];
  @observable.ref selectedMarker: ITimelineMarker | any;
  itemRenderer: any | ((props: ReactCalendarItemRendererProps<ITimelineItem>) => React.ReactNode);
  groupRenderer: any | ((props: ReactCalendarGroupRendererProps<ITimelineGroup>) => React.ReactNode);
  onMarkerSelect: any | ((marker: ITimelineMarker) => void);

  @computed get currentZoom(): number {
    return moment.duration(this.visibleTimeEnd.diff(this.visibleTimeStart)).asDays();
  }

  @computed get canZoomIn(): boolean {
    return this.currentZoom > TIMELINE_MIN_ZOOM;
  }

  @computed get canZoomOut(): boolean {
    return this.currentZoom < TIMELINE_MAX_ZOOM;
  }

  @action
  setItems = (items: ITimelineItem[]) => {
    this.items = items;
  };

  @action
  setGroups = (groups: ITimelineGroup[]) => {
    this.groups = groups;

    if (groups.length < TIMELINE_MIN_ROWS) {
      while (groups.length < TIMELINE_MIN_ROWS) {
        groups.push({ id: Math.random() * 10000, title: null });
      }
    }
  };

  @action
  setMarkers = (markers: ITimelineMarker[]) => {
    this.markers = markers;
  };

  handleMarkerSelect = (marker: ITimelineMarker) => {
    if (this.selectedMarker?.id === marker.id) {
      return;
    }

    this.setSelectedMarker(marker);

    // Needed to trigger a rerender of the markers in the Timeline View
    this.markers = this.markers.slice();

    if (this.onMarkerSelect && typeof this.onMarkerSelect === "function") {
      this.onMarkerSelect(marker);
    }
  };

  handleItemMove = (itemId, time, edge) => { };

  handleItemResize = (itemId, time, edge) => { };

  handleItemDoubleClick = (itemId, time, edge) => { };

  onItemDrag = (eventType, itemId, time, edge, newGroupOrder) => { };

  @action
  setSelectedMarker = (marker: ITimelineMarker) => {
    this.selectedMarker = marker;
  };

  @action
  scrollToDate = (date: moment.Moment) => {
    const startDate = date.clone().subtract(this.currentZoom / 2, "days");
    const endDate = date.clone().add(this.currentZoom / 2, "days");
    this.setVisibleDates(startDate, endDate);
  };

  resetZoom = () => {
    this.setVisibleDates(this.defaultStartDate, this.defaultEndDate);
  };

  onZoomIn = () => {
    const updatedStart = this.visibleTimeStart.clone().add(this.currentZoom * TIMELINE_ZOOM_RATIO, "days");
    const updatedEnd = this.visibleTimeEnd.clone().subtract(this.currentZoom * TIMELINE_ZOOM_RATIO, "days");
    this.setVisibleDates(updatedStart, updatedEnd);
  };

  onZoomOut = () => {
    const updatedStart = this.visibleTimeStart.clone().subtract(this.currentZoom * TIMELINE_ZOOM_RATIO, "days");
    const updatedEnd = this.visibleTimeEnd.clone().add(this.currentZoom * TIMELINE_ZOOM_RATIO, "days");
    this.setVisibleDates(updatedStart, updatedEnd);
  };

  onNextClick = () => {
    const updatedStart = this.visibleTimeStart.clone().add(this.currentZoom * TIMELINE_SCROLL_RATIO, "days");
    const updatedEnd = this.visibleTimeEnd.clone().add(this.currentZoom * TIMELINE_SCROLL_RATIO, "days");
    this.setVisibleDates(updatedStart, updatedEnd);
  };

  onPrevClick = () => {
    const updatedStart = this.visibleTimeStart.clone().subtract(this.currentZoom * TIMELINE_SCROLL_RATIO, "days");
    const updatedEnd = this.visibleTimeEnd.clone().subtract(this.currentZoom * TIMELINE_SCROLL_RATIO, "days");
    this.setVisibleDates(updatedStart, updatedEnd);
  };

  handleTimeChange = (startDate: number, endDate: number) => {
    const updatedStart = moment(startDate);
    const updatedEnd = moment(endDate);
    this.setVisibleDates(updatedStart, updatedEnd);
  };

  @action
  setDefaultDates = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.defaultStartDate = startDate;
    this.defaultEndDate = endDate;
  };

  @action
  setVisibleDates = (startDate: moment.Moment, endDate: moment.Moment) => {
    this.visibleTimeStart = startDate;
    this.visibleTimeEnd = endDate;
  };
}
