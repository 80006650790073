import * as React from 'react';

const SvgDotsVertical = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="dots-vertical_svg__feather dots-vertical_svg__feather-more-vertical"
        {...props}
    >
        <circle cx={12} cy={12} r={1} />
        <circle cx={12} cy={5} r={1} />
        <circle cx={12} cy={19} r={1} />
    </svg>
);

export default SvgDotsVertical;
