import React from "react";
import { FeatureDefinition, FeatureToggleContext } from "./context";

export function FeatureToggleProvider({
  children,
  isFeatureEnabled
}: {
  children: any;
  isFeatureEnabled: (name: string) => boolean;
}) {
  const [features, setFeatures] = React.useState<{
    [idx: string]: FeatureDefinition;
  }>({});

  const registerFeature = React.useCallback(
    (name: string, displayName: string) => {
      setFeatures(features => {
        if (features[name]) {
          if (displayName && !features[name].displayName) {
            return { ...features, [name]: { ...features[name], displayName } };
          }
          return features;
        }
        return {
          ...features,
          [name]: {
            name,
            displayName: displayName || name,
            isEnabled: isFeatureEnabled(name)
          }
        };
      });
    },
    [isFeatureEnabled]
  );

  const isEnabled = React.useCallback(
    name => {
      return (
        isFeatureEnabled(name) ||
        (features[name] && features[name].isEnabled ? true : false)
      );
    },
    [features, isFeatureEnabled]
  );

  const toggleFeature = React.useCallback((name: string, enable?: boolean) => {
    setFeatures(features => ({
      ...features,
      [name]: {
        ...features[name],
        isEnabled:
          typeof enable !== "undefined" ? enable : !features[name].isEnabled
      }
    }));
  }, []);

  return (
    <FeatureToggleContext.Provider
      value={{
        registerFeature,
        toggleFeature,
        features,
        isFeatureEnabled: isEnabled
      }}
    >
      {children}
    </FeatureToggleContext.Provider>
  );
}
