import * as React from "react";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD, ListingModel } from "../../../../core/forms/controls/listing/Listing_model";
import { AutocompleteModel } from "../../../../core/forms/controls/autocomplete/Autocomplete_model";
import _ from "lodash";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";

export const getStakeholderGroupFormFields = (
  organisationId: number,
  organisationProvider: IOrganisationsApi,
  stakeholderGroup?: FP.Entities.IStakeholderGroup
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.stakeholderGroupName"),
    label: <label htmlFor="name">{I18n.t("forms.stakeholderGroupName")} *</label>,
    fieldClassName: "col-lg-6 col-space-lg-6",
    validate: function () {
      const self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="3">{I18n.t("validations.stakeholderGroupName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: stakeholderGroup?.name
  };

  const stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    label: <label htmlFor={"stakeholdersSearch"}>{I18n.t("forms.stakeholders")}</label>,
    manageLink: `/organisations/${organisationId}/settings/stakeholders`,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: "user"
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await organisationProvider.getStakeholders(organisationId, { pageSize: 10000000, page: 1 });

      if (res?.payload) {
        const sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        self.setOptions(sortedStakeholders);
      }
    },
    subscribeTo: ["stakeholders"],
    onItemSelected: async function () {
      const self: AutocompleteModel = this;
      const listingModel: ListingModel = self.channels.stakeholders as ListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    fieldClassName: "col-lg-6",
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === Enums.StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    }
  };

  const listing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "stakeholders",
    placeholder: I18n.t("placeholders.selectStakeholder"),
    label: <label htmlFor={"stakeholders"}>{I18n.t("forms.selectedStakeholders")}</label>,
    fieldClassName: "col-lg-6",
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: (e: FP.Entities.IStakeholder) => (
      <p className="mb-0 d-inline-block">
        {e.stakeholderType === Enums.StakeholderType.AUDIENCE
          ? `${e.firstName} (${I18n.t("phrases.audience")})`
          : `${e.firstName} ${e.lastName} (${e.email})`}
      </p>
    ),
    value: stakeholderGroup && stakeholderGroup.stakeholders
  };

  const fields = [];

  if (stakeholderGroup) {
    const stakeholderGroupId = {
      ...INIT_TEXT_FIELD,
      key: "id",
      inputType: "hidden",
      value: stakeholderGroup && stakeholderGroup.id + "",
      defaultValue: stakeholderGroup && stakeholderGroup.id + ""
    };
    fields.push(stakeholderGroupId);
  }

  fields.push(orgId);
  fields.push(name);
  fields.push(stakeholdersSearch);
  fields.push(listing);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
