import { firebaseConfig } from "./FirebaseConfig";

declare var firebase;

export class IFirebaseService {
  load: () => Promise<any>;
}

export class FirebaseService implements IFirebaseService {
  app: any;
  database: any;
  locale: string;
  def: any;
  data: any;
  isDataLoaded: boolean;

  constructor(locale: string) {
    this.locale = locale;

    this.isDataLoaded = false;
    this.app = firebase.initializeApp(firebaseConfig);
    this.database = firebase.database();
  }

  load = (): Promise<any> => {
    return new Promise((res, rej) => {
      this.database.ref("/").on("value", (e: any) => {
        this.data = e.val() as any;

        this.isDataLoaded = true;
        res(this.data);
      });
    });
  };
}
