import * as React from "react";
import { FormFieldType } from "../../../../core/forms/formField/FormFieldTypes";
import { IF } from "../../../../components/hoc/If";
import { observer } from "mobx-react-lite";
import { Tooltip, TooltipIcon } from "../../Tooltip";
import { Panel } from "../../Panel";
import { LinkButton } from "../../Button";
import I18n from "../../../../core/localization/I18n";

export interface IFormFieldProps {
  key: string;
  sKey: string;
  className: string;
  isHidden: boolean;
  type: FormFieldType;
  isValid: boolean;
  disabled: boolean;
  isLabelHidden: boolean;
  label: string | React.ReactNode;
  errorMessage: React.ReactNode;
  tooltipLabel?: React.ReactNode;
  hintLabel?: React.ReactNode;
  manageLink?: string;
}

export const FormField: React.SFC<IFormFieldProps> = observer((props: any) => {
  if (props.isHidden) {
    return null;
  }

  const hasErrors = props.errorMessage !== null || ((props as any).errorMessage as any[])?.length > 0;

  return (
    <div className={`form-field ${props.className || ""} ${hasErrors ? "form-field--error" : ""}`}>
      <IF condition={props.label && !props.isLabelHidden}>
        <div className="form-field__label d-flex align-items-center flex-wrap">
          {typeof props.label === "string" ? <label htmlFor={props.sKey}>{props.label}</label> : props.label}
          <IF condition={props.tooltipLabel !== null}>
            <Tooltip shownElement={<TooltipIcon className="ml-2" />} triggeredOn="hover" position="top-right">
              <Panel background="bg-white" className="p-3 ml-1 mb-3" hasBorderRadius={true} type="border-lines">
                {props.tooltipLabel}
              </Panel>
            </Tooltip>
          </IF>
          <IF condition={!!props.manageLink}>
            <LinkButton className="form-field__manage-link ml-auto" target="_blank" href={props.manageLink} type="link">
              <small>{I18n.t("phrases.manage")}</small>
            </LinkButton>
          </IF>
        </div>
      </IF>

      {props.children}
      {props.hintLabel}
      {props.errorMessage}
    </div>
  );
});
