import * as React from "react";
import { IModel, BaseModel } from "../util/BaseModel";
import { IFilteredApiModel, IFilteredOptions } from "../../services/api/filteredApi/FilteredApiModel";
import { IFormFieldModel } from "../forms/formField/IFormField";
import { IModalService } from "../modal/IModalService";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services, DEBOUNCE_DELAY } from "../../constants";
import { SingleFormModel } from "../../pages/change/forms/singleFormModel/SingleForm_model";
import I18n from "../localization/I18n";
import { IPaginationModel } from "../../components/widgets/pagination/Pagination_model";
import * as _ from "lodash";
import { Animations } from "../util/Animations";

export interface IFilterAttribute {
  key: string;
  label?: string;
  value?: string[];
  operator?: FilterOperator;

  /**
   * indicates that the filter doesn't has a value and can be used as a flag in the url
   */
  useWithoutValue?: boolean;

  /**
   * Indicated that the value list can have only one value
   */
  isMultiValue?: boolean;
  isHidden?: boolean;

  /**
   * Callback function to populate filter pill content
   */
  valueRenderer?: (value: any, obj?: IFilterAttribute) => React.ReactNode;

  extractFilterValue?: (value: any) => any[];

  /**
   * if true then the filter will be tracked in the url
   */
  shouldUpdateUrlOnChange?: boolean;

  /**
   * if true the it will not reload data on value change
   */
  shouldIgnoreRefetchOnChange?: boolean;
}

export interface ISortAttribute {
  key: string;
  isAsc: boolean;
}

export enum FilterOperator {
  EQUALS = "%3D%3D",
  NOT_EQUALS = "%21%3D",
  CONTAINS = "%40%3D",
  GREATER_THAN = ">", //	Greater than
  LESS_THAN = "<", //Less than
  GREATER_EQUAL_THAN = ">=", //Greater than or equal to
  LESS_EQUAL_THAN = "<=", //Less than or equal to
  STARTS_WITH = "_=", //Starts with
  NOT_CONTAINS = "!@=", //Does not Contains
  NOT_STARTS_WITH = "!_=", //Does not Starts with
  CS_CONTAINS = "@=*", //Case-insensitive string Contains
  CS_STARTS_WITH = "_=*", //Case-insensitive string Starts with
  CS_EQUALS = "==*", //Case-insensitive string Equals
  CS_NOT_EQUALS = "!=*", //Case-insensitive string Not equals
  CS_NOT_CONTAINS = "!@=*", //Case-insensitive string does not Contains
  CS_NOT_STARTS_WITH = "!_=*" //Case-insensitive string does not Starts with
  // EQUALS = "==",
  // NOT_EQUALS = "!=",
  // CONTAINS = "@="
}

export interface IFilterModel<T> extends IModel {
  currentFilters: IFilterAttribute[];
  currentOrder: ISortAttribute;
  addFilter: (filter: IFilterAttribute) => void;
  loadData: () => Promise<any>;
  searchData: any;
  // filter actions
  removeFilter: (key: string) => void;
  setFilter: (filter: IFilterAttribute) => void;
  getFilter: (key: string) => IFilterAttribute | undefined;
  filterExists: (key: string) => boolean;
  toggleFilter: (filter: IFilterAttribute) => void;
  setFilterValue: (key: string, value: string, ignoreReload?: boolean) => void;
  setFilterValueList: (key: string, valueList: string[]) => void;
  removeFilterValue: (key: string, value: string) => void;
  resetFilterValues: (key: string) => void;
  resetAllFilters: () => void;
  getFilterOptions: () => IFilteredOptions;
  setFromQueryString: (queryStr: string) => void;

  // sort actions
  addSort: (sortBy: ISortAttribute) => void;
  removeSort: (sortBy: ISortAttribute) => void;
  toggleSort: () => void;
  setSortByKey: (key: string) => void;
  isSortEqualTo: (sort: string) => boolean;

  httpProvider?: IFilteredApiModel<T>;
  filterCb?: (filterOptions: Partial<IFilteredOptions>) => Promise<any>;
  formFields: (filterModel: IFilterModel<T>) => IFormFieldModel<any, any>[];
  showFilterFormModal: () => void;
  setConfig: (config: Partial<IFilterConfig<T>>) => void;
  data: T[];
  projectId: number;
}

export interface IFilterConfig<T> {
  httpProvider?: IFilteredApiModel<T>;
  filterCb?: (filterOptions: Partial<IFilteredOptions>) => Promise<any>;
  formFields: (filterModel: IFilterModel<T>) => IFormFieldModel<any, any>[];
  currentOrder: ISortAttribute | null;
  onDataLoaded: (data: T[]) => void;
  projectId: number;
  pageSize: number;
  page: number;
}

export class FilterModel<T> extends BaseModel implements IFilterModel<T> {
  appService: AppService;
  projectId: number | any;
  httpProvider?: IFilteredApiModel<T> | any;
  filterCb?: (filterOptions: Partial<IFilteredOptions>) => Promise<any>;
  formFields: any | ((filterModel: IFilterModel<T>) => IFormFieldModel<any, any>[]);
  modalService: IModalService;
  pageSize: number = 10;
  page: number = 1;
  onDataLoaded = (data: T[]) => { };
  paginationModel: IPaginationModel;
  @observable isLoadingData: boolean = false;
  @observable errorMessage: string = "";
  @observable currentOrder: ISortAttribute | any = null;
  @observable currentFilters: IFilterAttribute[] = [];
  @observable.ref data: T[] = [];

  constructor(appService: AppService, paginationModel: IPaginationModel, initOpts?: Partial<IFilterConfig<T>>) {
    super();
    this.appService = appService;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.paginationModel = paginationModel;
    if (initOpts) {
      this.setConfig(initOpts);
      if (initOpts.formFields) {
        initOpts.formFields(this as any);
      }
    }
  }

  @action
  setConfig = (config: Partial<IFilterConfig<T>>) => {
    this.formFields = config.formFields || this.formFields;
    this.httpProvider = config.httpProvider || this.httpProvider;
    this.filterCb = config.filterCb || this.filterCb;
    this.currentOrder = config.currentOrder || this.currentOrder;
    this.onDataLoaded = config.onDataLoaded || this.onDataLoaded;
    this.projectId = config.projectId || this.projectId;
    this.pageSize =
      config.pageSize === null || typeof config.pageSize === "undefined" ? this.pageSize : config.pageSize;
    this.page = config.page || this.page;
    if (!this.filterCb && !this.httpProvider) {
      console.error(
        `FilterModel has no source of retrieving data \n 
                Please specify an httpProvider or a filterCb`
      );
    }
  };

  @action
  addFilter = (filter: IFilterAttribute) => {
    this.currentFilters.push(filter);
  };

  @action
  removeFilter = (key: string) => {
    let idx = this.currentFilters.findIndex(e => e.key === key);
    this.currentFilters.splice(idx, 1);
  };

  @action
  toggleFilter = (filter: IFilterAttribute) => {
    let idx = this.currentFilters.findIndex(e => e.key === filter.key);
    if (idx >= 0) {
      this.currentFilters.splice(idx, 1);
    } else {
      this.currentFilters.push(filter);
    }
  };

  filterExists = (key: string) => {
    return this.currentFilters.findIndex(e => e.key === key) >= 0;
  };

  setFilter = (filter: IFilterAttribute) => {
    let idx = this.currentFilters.findIndex(e => e.key === filter.key);
    if (idx >= 0) {
      this.currentFilters[idx] = filter;
    } else {
      this.addFilter(filter);
    }
    if (filter.shouldIgnoreRefetchOnChange) return;
    this.page = 1;
    this.searchData();
  };

  getFilter = (key: string): IFilterAttribute | undefined => {
    return this.currentFilters.find(e => e.key === key);
  };

  @action
  resetAllFilters = () => {
    for (const filter of this.currentFilters) {
      if (!filter.isHidden) {
        filter.value = [];
      }
    }
    this.updateUrlQueryString();
    this.searchData();
  };

  @action
  resetFilterValues = (key: string) => {
    const filter = this.currentFilters.find(e => e.key === key);

    if (!filter) return;

    if (!filter.isHidden) {
      filter.value = [];
      this.updateUrlQueryString();
      this.searchData();
    }
  };

  @action
  removeFilterValue = (key: string, value: string) => {
    const filter = this.currentFilters.find(e => e.key === key) as any;
    if (!filter) return;
    const removeValue = () => {
      if (!filter.isMultiValue) {
        filter.value = [];
        return;
      }

      const valueList = filter.value.slice();
      const foundValueIndex = valueList.findIndex(e => e === value);

      if (foundValueIndex < 0) return;

      valueList.splice(foundValueIndex, 1);
      filter.value = valueList;
    };
    removeValue();
    if (filter.shouldIgnoreRefetchOnChange) return;
    this.updateUrlQueryString();
    this.searchData();
  };

  @action
  setFilterValue = (key: string, value: string, ignoreReload?: boolean) => {
    const filter = this.getFilter(key);
    if (!filter) return;
    const updateFilter = () => {
      if (!filter.isMultiValue) {
        filter.value = [value];
        this.searchData();
        return;
      }

      if (value === "") return;

      let filterList: any = filter.value;
      let kIdx = filterList.findIndex(e => e === value);
      if (kIdx >= 0) {
        filterList.splice(kIdx, 1);
      } else {
        filterList.push(value);
      }
      filter.value = filterList;
    };
    updateFilter();
    if (!ignoreReload && !filter.shouldIgnoreRefetchOnChange) {
      this.searchData();
    }
    this.updateUrlQueryString();
  };

  @action
  setFilterValueList = (key: string, valueList: string[]) => {
    const filter = this.getFilter(key);
    if (!filter) {
      return;
    }

    filter.value = valueList;
    if (filter.shouldIgnoreRefetchOnChange) return;

    this.updateUrlQueryString();
    this.searchData();
  };

  @action
  addSort = (sortBy: ISortAttribute) => {
    this.currentOrder = sortBy;
  };

  @action
  removeSort = (sortBy: ISortAttribute) => {
    this.currentOrder = null;
  };

  @action
  toggleSort = () => {
    this.currentOrder.isAsc = !this.currentOrder.isAsc;
  };

  setSortByKey = (key: string) => {
    if (this.currentOrder?.key === key) {
      this.toggleSort();
    } else {
      this.addSort({ key, isAsc: true });
    }
    this.loadData();
  };

  isSortEqualTo = (sort: string) => {
    return this.currentOrder && this.currentOrder.key === sort;
  };

  getFilterOptions = (): IFilteredOptions => {
    let k: IFilteredOptions = {
      page: this.page,
      pageSize: this.pageSize,
      filters: this.getFilterString(),
      sorts: this.getSortsString()
    };
    return k;
  };

  getFilterString = (): string => {
    if (this.currentFilters.length === 0) return null as any;
    const list: any[] = [];
    const filtersThatNeedsDataReload = this.currentFilters.filter(
      (e: IFilterAttribute | any) => !e.shouldIgnoreRefetchOnChange
    );
    filtersThatNeedsDataReload.forEach((e: IFilterAttribute) => {
      if (e.useWithoutValue) {
        list.push(e.key);
        return;
      }
      if (e.value.length && e.extractFilterValue) {
        const val = e.extractFilterValue(e.value);
        list.push(e.key + e.operator + val.join("|"));
      } else if (e.value.length) {
        list.push(e.key + e.operator + e.value.join("|"));
      }
    });
    return list.join(",");
  };

  getSortsString = (): string => {
    return this.currentOrder ? (this.currentOrder.isAsc ? this.currentOrder.key : "-" + this.currentOrder.key) : "";
  };

  @action
  loadData = async () => {
    this.isLoadingData = true;
    let s = this.getFilterOptions();

    try {
      let res = this.filterCb ? await this.filterCb(s) : await this.httpProvider.getFilteredAsync(s);
      if (!res || res.isError) return;
      this.data = res.payload;
      this.paginationModel && this.paginationModel.setConfig(res.pagination);
      this.onDataLoaded(this.data);
    } catch {
      this.errorMessage = I18n.t("errors.loadData");
    }

    this.isLoadingData = false;
  };

  searchData = _.debounce(() => {
    this.page = 1;
    this.paginationModel.config.onPageClick(1);
    this.loadData();
  }, DEBOUNCE_DELAY.NORMAL);

  showFilterFormModal = () => {
    let formModel = new SingleFormModel();
    formModel.formFields = this.formFields(this);
    return new Promise(resolve => {
      /** todo: change this code, this is potentially a memory leak, a never ending promise */
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.filterActions")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-white"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  setFromQueryString = (queryStr: string) => {
    if (!queryStr) return;

    const filterArr = queryStr.slice(1).split("&");

    for (const filter of filterArr) {
      const [key, value] = filter.split("=");
      const parsedValue = decodeURIComponent(value);
      const filterObj = this.getFilter(key);
      if (!!filterObj) {
        filterObj.shouldUpdateUrlOnChange = true;
        this.setFilterValue(key, parsedValue);
        continue;
      }

      this.addFilter({
        key,
        operator: FilterOperator.EQUALS,
        value: [parsedValue],
        shouldUpdateUrlOnChange: true
      });
    }
  };

  updateUrlQueryString = () => {
    const baseUrl = window.location.href.split("?")[0];
    const syncFilter = _.filter(this.currentFilters, (e: any) => e.shouldUpdateUrlOnChange && e.value.length > 0);
    if (syncFilter.length === 0) {
      window.history.replaceState("", "", baseUrl);
      return;
    }
    const query = _.map(syncFilter, (filter: IFilterAttribute) => {
      if (!filter.shouldUpdateUrlOnChange) return "";
      let value = filter.value;

      if (filter.extractFilterValue) {
        value = filter.extractFilterValue(value);
      }

      return `${filter.key}=${encodeURIComponent(value.join("|"))}`;
    }).join("&");
    const url = `${baseUrl}?${query}`;
    window.history.replaceState("", "", url);
  };
}
