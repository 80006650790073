import * as React from "react";
import { IFilterAttribute, IFilterModel } from "../../../../core/filter/Filter_model";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IMPACT_STATUS_OPTIONS, IMPACT_LEVEL_OPTIONS, Services } from "../../../../constants";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import _ from "lodash";
import AppService from "../../../../AppService";
import { IImpactTypeApi } from "../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { ICheckboxModel } from "../../../../core/forms/controls/checkbox/ICheckboxModel";
import { INIT_CHECKBOX_FIELD } from "../../../../core/forms/controls/checkbox/Checkbox_init";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";

export const GetImpactListFilters = (
  filterModel: IFilterModel<FP.Entities.IImpact>,
  organisationId: number,
  projectId: number
) => {
  // define filters
  const impactTypeProvider = AppService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
  const impactGroupsProvider = AppService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
  const businessAreaProvider = AppService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);

  // add filter to model so it can be tracked
  const nameFilter = filterModel.getFilter("name");
  const refFilter = filterModel.getFilter("refNumber");
  const type = filterModel.getFilter("impactType");
  const businessAreaFilter = filterModel.getFilter("businessAreaId");
  const progressFilter = filterModel.getFilter("progressStatus");
  const group = filterModel.getFilter("impactGroup");
  const levelFilter = filterModel.getFilter("impactLevelGen");
  const impactGroupAutoPopulateFilter = filterModel.getFilter("impactGroupAutoPopulate");

  // add filter form fields
  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: nameFilter.key,
    label: <label htmlFor={nameFilter.key}>{I18n.t("forms.impactName")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.impactName"),
    onValueChange: value => {
      filterModel.setFilterValue(nameFilter.key, value);
    },
    fieldClassName: "col-12",
    value: nameFilter?.value.length > 0 ? nameFilter?.value[0] : ""
  };

  const ref: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: refFilter.key,
    label: <label htmlFor={refFilter.key}>{I18n.t("table.refNo")}</label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.searchReference"),
    onValueChange: value => {
      filterModel.setFilterValue(refFilter.key, value);
    },
    fieldClassName: "col-12",
    value: refFilter?.value.length > 0 ? refFilter?.value[0] : ""
  };

  const impactType: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactType",
    label: <label htmlFor={"impactType"}>{I18n.t("forms.impactType")}</label>,
    placeholder: I18n.t("placeholders.selectImpactType"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await impactTypeProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `organisationId==${organisationId},lifecycleStatus==0`
      });
      if (!res.payload) return;
      const impactTypes = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(impactTypes);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12",
    onValueChange: value => {
      filterModel.setFilterValueList(
        type.key,
        value.map(e => e)
      );
    },
    value: type.value,
    searchAttribute: "name"
  };

  const getImpactFilterStatusOptions = (): any => {
    let impactFilterStatusOptions = [];

    // Add Draft impact filter option in manually as we don't want to amend the enum
    impactFilterStatusOptions.push({
      key: 0,
      label: "Draft",
      id: 0
    });

    impactFilterStatusOptions = impactFilterStatusOptions.concat(
      IMPACT_STATUS_OPTIONS.map(o => ({
        ...o,
        id: o.key,
        label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
      }))
    );

    return impactFilterStatusOptions;
  };

  let valProgressStatus: any = getImpactFilterStatusOptions().forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });

  const progressStatus: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")}</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(getImpactFilterStatusOptions());
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12",
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(
        progressFilter.key,
        value.map(e => e.key)
      );
    },
    value: valProgressStatus
  };

  const impactGroup: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroup",
    label: <label htmlFor={"impactGroup"}>{I18n.t("forms.impactGroup")}</label>,
    placeholder: I18n.t("placeholders.selectImpactGroup"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await impactGroupsProvider.getFilterList(organisationId, projectId);
      if (!res.payload) return;
      model.setOptions(res.payload);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12",
    onValueChange: value => {
      filterModel.setFilterValueList(
        group.key,
        value.map(e => e)
      );
    },
    value: group.value,
    searchAttribute: "name"
  };

  const isFilterValueTrue = (filter: IFilterAttribute): boolean => {
    return filter?.value.length > 0 ? filter?.value[0] === "true" : false
  }

  const impactGroupAutoPopulate: Partial<ICheckboxModel> = {
    ...INIT_CHECKBOX_FIELD,
    key: "impactGroupAutoPopulate",
    fieldClassName: "col-12 d-flex flex-row-reverse justify-content-end",
    label: I18n.t("forms.prepopulateImpactGroups"),
    value: isFilterValueTrue(impactGroupAutoPopulateFilter),
    onValueChange: (value: boolean) => {
      filterModel.setFilterValue(impactGroupAutoPopulateFilter.key, value.toString());
    }
  };

  let lVal: any = _.filter(IMPACT_LEVEL_OPTIONS, e => levelFilter.value.indexOf(e.key as any) >= 0);
  lVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });
  const impactLevel: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactLevel",
    label: <label htmlFor={"impactLevel"}>{I18n.t("forms.impactLevel")}</label>,
    placeholder: I18n.t("placeholders.impactLevel"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        IMPACT_LEVEL_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12",
    onValueChange: value => {
      filterModel.setFilterValueList(
        levelFilter.key,
        value.map(e => e.key)
      );
    },
    value: lVal
  };

  const businessArea: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "businessArea",
    label: <label htmlFor={"businessArea"}>{I18n.t("forms.businessArea")}</label>,
    placeholder: I18n.t("placeholders.selectBusinessArea"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await businessAreaProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `organisationId==${organisationId},lifecycleStatus==0`
      });
      if (!res.payload) return;
      const businessAreas = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(businessAreas);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12",
    onValueChange: value => {
      filterModel.setFilterValueList(
        businessAreaFilter.key,
        value.map(e => e)
      );
    },
    value: type.value,
    searchAttribute: "name"
  };

  const fields = [];
  fields.push(ref);
  fields.push(name);
  fields.push(businessArea);
  fields.push(impactType);
  fields.push(impactLevel);
  fields.push(progressStatus);
  fields.push(impactGroup);
  fields.push(impactGroupAutoPopulate);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
