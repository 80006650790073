import * as React from "react";
import { useParams } from "react-router-dom";
import I18n from "../localization/I18n";
import {
  useCanEditProgrammeClaim,
  useCanEditProjectClaim,
  useCanViewProgrammeClaim,
  useCanViewProjectClaim,
  useIsOrganisationAdmin,
  useOrganisationScopeClaim,
  useCanEditOrganisationClaim,
  Permission
} from "../auth/authorise";
import { useCurrentOrganisationId, useUserOrganisationIds } from "../auth/organisationContext";
import { useCallback } from "react";
import { Async } from "react-async";
import { PositionedSpinner } from "../../components/ui/PositionedSpinner";
import { getAppToken } from "../../setup";
import { Animations } from "../util/Animations";

const CanEditOrganisationRoute: React.FunctionComponent<{ organisationId?: number | string }> = ({ organisationId, children }) => {
  const { organisationId: organisationIdParam } = useParams<{ organisationId?: string }>();
  const oId = organisationId || +organisationIdParam;
  const canEdit =  useCanEditOrganisationClaim(oId as number);
  return canEdit ? (children as any) : <NoAccessPage />;
};

const CanEditProjectRoute: React.FunctionComponent<{ projectId?: number | string }> = ({ projectId, children }) => {
  const { projectId: projectIdParam } = useParams<{ projectId?: string }>();
  const pId = projectId || +projectIdParam;
  const canEdit = useCanEditProjectClaim(pId as number);
  return canEdit ? (children as any) : <NoAccessPage />;
};

const CanEditProgrammeRoute: React.FunctionComponent<{ programmeId?: number }> = ({ programmeId: pId, children }) => {
  const { programmeId } = useParams<{ programmeId?: string }>();
  const id = pId || programmeId;
  const canEdit = useCanEditProgrammeClaim(+id);
  return canEdit ? (children as any) : <NoAccessPage />;
};

const CanViewProjectRoute: React.FunctionComponent<{ projectId?: number | string }> = ({ projectId, children }) => {
  const { projectId: projectIdParam } = useParams<{ projectId?: string }>();
  const pId = projectId || projectIdParam;
  const canView = useCanViewProjectClaim(pId as number);
  return canView ? (children as any) : <NoAccessPage />;
};

const CanViewProgrammeRoute: React.FunctionComponent<{ programmeId?: number }> = ({ programmeId: pId, children }) => {
  const { programmeId } = useParams<{ programmeId?: string }>();
  const id = pId || programmeId;

  const canView = useCanViewProgrammeClaim(+id);

  return canView ? (children as any) : <NoAccessPage />;
};

export const HasOrganisationAccess: React.FC = props => {
  const organisationIds = useUserOrganisationIds();
  if (organisationIds.length === 0) {
    return <NoAccessPage />;
  }

  return (props.children as any) || null;
};

const CanContributeOrgRoute: React.FunctionComponent<any> = props => {
  const currentOrganisationId = useCurrentOrganisationId();
  const isAdmin = useIsOrganisationAdmin(currentOrganisationId);
  const organisationScope = useOrganisationScopeClaim(currentOrganisationId);
  if (isAdmin || (organisationScope && hasContributorAccess(organisationScope.value as string))) {
    return props.children;
  }

  return <NoAccessPage />;
};

const CanViewOrgRoute: React.FunctionComponent<any> = props => {
  const currentOrganisationId = useCurrentOrganisationId();
  const organisationScope = useOrganisationScopeClaim(currentOrganisationId);

  if (!organisationScope || !hasViewAccess(organisationScope.value as string)) {
    return <NoAccessPage />;
  }

  return props.children;
};

const NoAccessPage: React.FC<any> = () => {
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h3>{I18n.t("phrases.noViewAccess")}</h3>
        </div>
      </div>
    </div>
  );
};

export {
  NoAccessPage,
  CanEditProjectRoute,
  CanEditProgrammeRoute,
  CanViewProgrammeRoute,
  CanViewOrgRoute,
  CanViewProjectRoute,
  CanContributeOrgRoute,
  CanEditOrganisationRoute
};

function hasContributorAccess(scope: string) {
  return scope === Permission.OWNER || scope === Permission.ADMIN || scope === Permission.CONTRIBUTOR;
}

function hasViewAccess(scope: string) {
  return scope === Permission.READER || scope === Permission.OWNER || scope === Permission.ADMIN || scope === Permission.CONTRIBUTOR;
}

export const ResourceLoader: React.FC<{ resourceUrl: string; render?: (data: any) => React.ReactNode }> = ({
  resourceUrl,
  children,
  render
}) => {
  const load = useCallback(async () => {
    const response = await fetch(resourceUrl, {
      method: "get",
      headers: {
        authorization: `Bearer ${await getAppToken()}`
      }
    });
    if (response.status === 401) {
      throw new Error("xYou do not have access to this resource");
    }

    if (response.status === 404) {
      throw new Error("Requested resource does not exist");
    }

    return (await response.json()).payload;
  }, [resourceUrl]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>{render || children}</Async.Resolved>
      <Async.Rejected>{err => err.message}</Async.Rejected>
    </Async>
  );
};

export const RouteResourceLoader: React.FC<{
  resourceUrl: string;
  idParameterName: string;
  render?: (data: any) => React.ReactNode;
}> = ({ resourceUrl, children, idParameterName, render }) => {
  const params = useParams();

  return (
    <ResourceLoader resourceUrl={`${resourceUrl}/${params[idParameterName]}`} render={render}>
      {children}
    </ResourceLoader>
  );
};
