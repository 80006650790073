import * as React from 'react';
import { observable, action } from 'mobx';
import { IRTEditorModel } from './IRTEditorModel';
import { FormFieldModel } from '../../formField/FormField_model';
import { FormFieldType } from '../../formField/FormFieldTypes';
import { INIT_FORM_FIELD } from '../../formField/FormField_init';
import { RichTextEditorProps, RichTextEditor } from '../../../../components/ui/_forms/RichTextEditor';

export const INIT_RTEDITOR: Partial<IRTEditorModel> = {
    ...INIT_FORM_FIELD,
    value: '',
    type: FormFieldType.RTEditor
}

export class RTEditorModel extends FormFieldModel<string, RichTextEditorProps>
    implements IRTEditorModel {

    @observable value: string = '';
    placeholder?: string;
    readonly type: FormFieldType = FormFieldType.RTEditor;

    constructor(initOpts?: IRTEditorModel) {
        super(initOpts);

        if (initOpts) {
            this.value = initOpts.value || this.value;
            this.placeholder = initOpts.placeholder || this.placeholder;
            this.validate = initOpts.validate || this.validate;
            this.extractValue = initOpts.extractValue || this.extractValue;

        }

    }

    validate: (...args: any[]) => any;


    @action
    setValue = (val: string): void => {
        this.value = val;
    }

    setFieldValue(val: any): void {
        throw new Error("Method not implemented.");
    }

    extractValue = () => {
        return this.value;
    }

    reset(): void {
        this.errorMessage = '';
        this.value = this.defaultValue || '';
    }

    renderComponent = (): React.ReactNode => {
        return <RichTextEditor
            id={this.key}
            componentProps={this.componentProps}
            value={this.value}
            onChange={this.setValue}
        />
    };
}





