import * as React from "react";
import { ITableRowModel } from "../../../../core/table/ITableRowModel";
import { ITableConfig, ITableModel } from "../../../../core/table/ITableModel";
import { Enums } from "../../../../enums";
import { StakeholderGroupListViewModel } from "../../stakeholderGroup/stakeholderGroupListView/StakeholderGroupListView_model";
import { Pill } from "../../../../components/ui/Pill";
import { StakeholderListViewModel } from "./StakeholderListView_model";
import { Icon } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../core/table/TableHeader_view";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { TargetLabel } from "../../../../components/widgets/TargetLabel";
import { IStGroupStakeholder } from "../../projects/addStakeholderGroup/AddStakeholderGroup_model";
import { convertStakeholderToName } from "../../../../core/util/Helpers";

export const GetStakeholderTableConfig = (
  parentModel: StakeholderListViewModel | StakeholderGroupListViewModel,
  tableModel: ITableModel<FP.Entities.IProjectStakeholder>,
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>
) => {
  let actions = [];
  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: "HTMLAnchor",
    componentProps: {
      type: "link"
    },
    hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${context.rowObject.project.id}/stakeholders/${context.rowObject.stakeholder.id}`
  });
  if (parentModel.permissionService.canEditProject(parentModel.projectId)) {
    actions.push(
      {
        id: "edit",
        label: I18n.t("phrases.edit"),
        rendersIn: "HTMLAnchor",
        componentProps: {
          type: "link"
        },
        hrefFn: context =>
          `/organisations/${parentModel.organisationId}/projects/${context.rowObject.project.id}/stakeholders/${context.rowObject.stakeholder.id}/edit`
      },
      {
        id: "remove",
        label: I18n.t("phrases.remove"),
        onAction: (ev, row: ITableRowModel) => {
          parentModel.showProjectStakeholderConfirmDeleteModal(
            parentModel.projectId,
            row.rowObject.stakeholder.id,
            row.rowObject.stakeholder
          );
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    );
  }

  return {
    actions,
    colHeaders: [
      {
        key: "stakeholder",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <p className="strong mb-0">
              <Icon symbol="chevron-right" className="mr-2" />
              {`${obj.stakeholder.firstName} ${obj.stakeholder.lastName}`}
            </p>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel, "Stakeholder.Firstname,Stakeholder.Lastname");
        },
        showSortIcons: true
      },
      {
        key: "role.Name",
        content: <h4 className="mb-0">{I18n.t("table.role")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => obj.stakeholder.role?.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "businessArea.Name",
        content: <h4 className="mb-0">{I18n.t("table.businessArea")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => obj.stakeholder.businessArea?.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "isKeyStakeholder",
        content: <h4 className="mb-0">{I18n.t("table.isKeyStakeholder")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => I18n.t(obj.isKeyStakeholder ? "phrases.yes" : "phrases.no"),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "impact",
        content: <h4 className="mb-0">{I18n.t("table.impact")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <Pill
              style={{ width: "90px" }}
              type={`indicator-accent-${Enums.Translator.MetricToEnum(obj.impact)}` as any}
            >
              {Enums.Translator.Metric(obj.impact)}
            </Pill>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "influence",
        content: <h4 className="mb-0">{I18n.t("table.influence")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => Enums.Translator.Metric(obj.influence),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "sentiment",
        content: <h4 className="mb-0">{I18n.t("table.sentiment")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => (
          <TargetLabel
            target={obj.targetSentiment}
            current={obj.sentiment}
            onTarget={obj.sentiment >= obj.targetSentiment}
            text={Enums.Translator.SentimentLevel(obj.sentiment)}
            labelModifier={Enums.Translator.SentimentLevel}
          />
        ),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "commitment",
        content: <h4 className="mb-0">{I18n.t("table.commitment")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => (
          <TargetLabel
            target={obj.targetCommitment}
            current={obj.commitment}
            onTarget={obj.commitment >= obj.targetCommitment}
            text={Enums.Translator.CommitmentLevel(obj.commitment)}
            labelModifier={Enums.Translator.CommitmentLevel}
          />
        ),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "receptiveness",
        content: <h4 className="mb-0">{I18n.t("table.receptiveness")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => (
          <TargetLabel
            target={obj.targetReceptiveness}
            current={obj.receptiveness}
            onTarget={obj.receptiveness >= obj.targetReceptiveness}
            text={Enums.Translator.ReceptivenessLevel(obj.receptiveness)}
            labelModifier={Enums.Translator.ReceptivenessLevel}
          />
        ),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "owner",
        content: <h4 className="mb-0">{I18n.t("table.relationshipOwner")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) =>
          `${obj.stakeholder.owner.firstName} ${obj.stakeholder.owner.lastName}`,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel, "Owner.Firstname,Owner.Lastname");
        },
        showSortIcons: true
      }
    ],
    onRowClick: parentModel.showStakeholderModal,
    tableProps: {
      id: "StakeholderListTable"
    },
  } as ITableConfig<any>;
};

export const GetStakeholderAudienceTableConfig = (
  parentModel: StakeholderListViewModel | StakeholderGroupListViewModel,
  tableModel: ITableModel<FP.Entities.IProjectStakeholder>,
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>
) => {
  let actions = [];
  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: "HTMLAnchor",
    componentProps: {
      type: "link"
    },
    hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${context.rowObject.project.id}/stakeholders/${context.rowObject.stakeholder.id}`
  });
  if (parentModel.permissionService.canEdit()) {
    actions.push(
      {
        id: "edit",
        label: I18n.t("phrases.edit"),
        rendersIn: "HTMLAnchor",
        componentProps: {
          type: "link"
        },
        hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/stakeholders/${context.rowObject.stakeholder.id}/edit`
      },
      {
        id: "remove",
        label: "Remove",
        onAction: (ev, row: ITableRowModel) => {
          parentModel.showProjectStakeholderConfirmDeleteModal(
            parentModel.projectId,
            row.rowObject.stakeholder.id,
            row.rowObject.stakeholder
          );
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    );
  }

  return {
    actions,
    colHeaders: [
      {
        key: "stakeholder",
        content: <h4 className="mb-0">{I18n.t("table.audience")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let st: FP.Entities.IStakeholder = obj.stakeholder;
          return (
            <p className="strong mb-0">
              <Icon symbol="chevron-right" className="mr-2" />
              {st.firstName}
            </p>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel, "Stakeholder.Firstname,Stakeholder.Lastname");
        },
        showSortIcons: true
      },
      {
        key: "audienceCount",
        content: <h4 className="mb-0">{I18n.t("table.audienceCount")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return obj.stakeholder.audienceCount;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "businessArea.Name",
        content: <h4 className="mb-0">{I18n.t("table.businessAreas")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let st: FP.Entities.IStakeholder = obj.stakeholder;
          return (st.businessArea && st.businessArea.name) || "";
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "isKeyStakeholder",
        content: <h4 className="mb-0">{I18n.t("table.isKeyAudience")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => I18n.t(obj.isKeyStakeholder ? "phrases.yes" : "phrases.no"),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "impact",
        content: <h4 className="mb-0">{I18n.t("table.impacts")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <Pill
              style={{ width: "90px" }}
              type={`indicator-accent-${Enums.Translator.MetricToEnum(obj.impact)}` as any}
            >
              {Enums.Translator.Metric(obj.impact)}
            </Pill>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "influence",
        content: <h4 className="mb-0">{I18n.t("table.influence")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return Enums.Translator.Metric(obj.influence);
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "sentiment",
        content: <h4 className="mb-0">{I18n.t("table.sentiment")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <TargetLabel
              target={obj.targetSentiment}
              current={obj.sentiment}
              onTarget={obj.sentiment >= obj.targetSentiment}
              text={Enums.Translator.SentimentLevel(obj.sentiment)}
              labelModifier={Enums.Translator.SentimentLevel}
            />
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "commitment",
        content: <h4 className="mb-0">{I18n.t("table.commitment")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <TargetLabel
              target={obj.targetCommitment}
              current={obj.commitment}
              onTarget={obj.commitment >= obj.targetCommitment}
              text={Enums.Translator.CommitmentLevel(obj.commitment)}
              labelModifier={Enums.Translator.CommitmentLevel}
            />
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "receptiveness",
        content: <h4 className="mb-0">{I18n.t("table.receptiveness")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <TargetLabel
              target={obj.targetReceptiveness}
              current={obj.receptiveness}
              onTarget={obj.receptiveness <= obj.targetReceptiveness}
              text={Enums.Translator.ReceptivenessLevel(obj.receptiveness)}
              labelModifier={Enums.Translator.ReceptivenessLevel}
            />
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel);
        },
        showSortIcons: true
      },
      {
        key: "owner",
        content: <h4 className="mb-0">{I18n.t("table.relationshipOwner")}</h4>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let st: FP.Entities.IStakeholder = obj.stakeholder;
          return st.owner.firstName + " " + st.owner.lastName;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, filterModel, "Owner.Firstname,Owner.Lastname");
        },
        showSortIcons: true
      }
    ],
    onRowClick: parentModel.showStakeholderModal,
    tableProps: {}
  } as ITableConfig<any>;
};

export const GetCompactStakeholderTableConfig = (): ITableConfig<IStGroupStakeholder> => {
  return {
    colHeaders: [
      {
        key: "stakeholder",
        content: <h4 className="mb-0">{I18n.t("table.stakeholder")}</h4>,
        selector: (obj: IStGroupStakeholder) => convertStakeholderToName(obj)
      },
      {
        key: "isInProject",
        content: <h4 className="mb-0">{I18n.t("table.alreadyInProject")}</h4>,
        selector: (obj: IStGroupStakeholder) => {
          return <p className="mb-0">{I18n.t(obj.isInProject ? "phrases.yes" : "phrases.no")}</p>;
        }
      },
      {
        key: "role",
        content: <h4 className="mb-0">{I18n.t("table.role")}</h4>,
        selector: (obj: IStGroupStakeholder) => {
          return obj.role?.name || "";
        }
      },
      {
        key: "businessArea",
        content: <h4 className="mb-0">{I18n.t("table.businessArea")}</h4>,
        selector: (obj: IStGroupStakeholder) => {
          return obj.businessArea?.name || "";
        }
      },
      {
        key: "owner",
        content: <h4 className="mb-0">{I18n.t("table.relationshipOwner")}</h4>,
        selector: (obj: IStGroupStakeholder) => {
          return obj.owner ? `${obj.owner.firstName} ${obj.owner.lastName}` : "";
        }
      }
    ] as any[]
  };
};
