import { INIT_FORM_FIELD } from '../../formField/FormField_init';
import { ICheckboxModel } from './ICheckboxModel';
import { FormFieldType } from '../../formField/FormFieldTypes';

export const INIT_CHECKBOX_FIELD: Partial<ICheckboxModel> = {
    ...INIT_FORM_FIELD,
    type: FormFieldType.Checkbox,
    checked: false,
    validate: () => {
        return true;
    },
};
