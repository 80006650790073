import { ILogger } from "./ILogger";
import { Enums, LogLevel } from "../../enums";

export interface ILoggerDelegate {
  log(level: LogLevel, operation: string, msg: string);
  log(level: LogLevel, error: Error);
  log(level: LogLevel, ...args: any[]);
}

export function createLogger(del: ILoggerDelegate, logLevel: number): ILogger {
  let delegate = del;
  function log(operation: string, msg: string) {
    if (logLevel & Enums.LogLevel.Diagnostics) {
      delegate.log(Enums.LogLevel.Diagnostics, operation, msg);
    }
  }

  function logError(...args: any[]) {
    if (logLevel & Enums.LogLevel.Error) {
      delegate.log(Enums.LogLevel.Error, ...args);
    }
  }

  function logVerbose(operation: string, msg: string) {
    if (logLevel & Enums.LogLevel.Diagnostics) {
      delegate.log(Enums.LogLevel.Diagnostics, operation, msg);
    }
  }

  function logInfo(operation: string, msg: string) {
    if (logLevel & Enums.LogLevel.Info) {
      delegate.log(Enums.LogLevel.Diagnostics, operation, msg);
    }
  }

  function setDelegate(del: ILoggerDelegate) {
    delegate = del;
  }

  return {
    log,
    logError,
    logVerbose,
    logInfo,
    setDelegate
  };
}

export function createConsoleLoggerDelegate(debug: boolean = true): ILoggerDelegate {
  function log(...args: any[]) {
    if (!debug) {
      return;
    }
    if (args.length) {
      let level = +args[0];
      let op =
        args.length > 1
          ? typeof args[1] === "string"
            ? args[1]
            : typeof args[1] === "object" && args[1]
            ? args[1].code
            : null
          : null;
      let msg = args.length > 2 ? args[2] : null;
      const m = `${new Date().toISOString()} - ${op} - ${msg}`;
      if (level === Enums.LogLevel.Error) {
        console.error(m);
      } else if (level === Enums.LogLevel.Info) {
        console.info(m);
      } else if (level === Enums.LogLevel.Warning) {
        console.warn(m);
      } else {
        console.log(m);
      }
    }
  }

  return {
    log
  };
}

export function createDomLoggerDelegate(e?: HTMLElement): ILoggerDelegate {
  let el = e;
  if (!el) {
    el = document.createElement("div");
    el.className = "sys-logger";
    document.querySelector("body").appendChild(el);
  }

  function createLogEl(level: LogLevel, operation: string, msg: string) {
    let el = document.createElement("div");
    el.className = `sys-error level-${level}`;
    el.setAttribute("data-level", level + "");
    el.setAttribute("data-operation", operation);
    el.innerHTML = `${new Date().toISOString()} - ${operation} - ${msg}`;
    return el;
  }

  function log(...args: any[]) {
    if (args.length) {
      let level = +args[0];
      let op =
        args.length > 1
          ? typeof args[1] === "string"
            ? args[1]
            : typeof args[1] === "object" && args[1]
            ? args[1].code
            : null
          : null;
      let msg = args.length > 2 ? args[2] : null;
      let logEl = createLogEl(level, op, msg);
      el.appendChild(logEl);
    }
  }

  return {
    log
  };
}
