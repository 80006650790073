export function GetMainSelectionFromUrl(url: string) {
  let programmeRegex = /\/programmes\/(\d+)/gi;
  let projectRegex = /\/projects\/(\d+)/gi;
  let createPageRegex = /\/(projects|programmes|actions|stakeholders|audiences|stakeholdergroups|impactGroups|impacts)\/create/gi;
  if (createPageRegex.test(url)) {
    return "create";
  } else if (programmeRegex.test(url) || projectRegex.test(url)) {
    return "projectListNav";
  } else if (url.startsWith("/programmes") || url.startsWith("/organisations") || url.startsWith("/projects")) {
    return "content";
  } else if (url === "projects") {
    return "projectListNav";
  } else {
    return "dashboard";
  }
}
