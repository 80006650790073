import { isEmpty } from "lodash";

const permittedHtmlTags: string[] = ["p", "ol", "ul", "li", "b", "i", "strong"];

const xssTriggerPhrases: string[] = [
  "<script",
  "abort",
  "afterprint",
  "animationend",
  "animationiteration",
  "animationstart",
  "beforeprint",
  "beforeunload",
  "blur",
  "canplay",
  "canplaythrough",
  "change",
  "click",
  "contextmenu",
  "copy",
  "cut",
  "dblclick",
  "drag",
  "dragend",
  "dragenter",
  "dragleave",
  "dragover",
  "dragstart",
  "drop",
  "durationchange",
  "ended",
  "error",
  "focus",
  "focusin",
  "focusout",
  "fullscreenchange",
  "fullscreenerror",
  "hashchange",
  "input",
  "invalid",
  "keydown",
  "keypress",
  "keyup",
  "load",
  "loadeddata",
  "loadedmetadata",
  "loadstart",
  "message",
  "mousedown",
  "mouseenter",
  "mouseleave",
  "mousemove",
  "mouseover",
  "mouseout",
  "mouseup",
  "mousewheel",
  "offline",
  "online",
  "open",
  "pagehide",
  "pageshow",
  "paste",
  "pause",
  "play",
  "playing",
  "popstate",
  "progress",
  "ratechange",
  "resize",
  "reset",
  "scroll",
  "search",
  "seeked",
  "seeking",
  "select",
  "show",
  "stalled",
  "storage",
  "submit",
  "suspend",
  "timeupdate",
  "toggle",
  "touchcancel",
  "touchend",
  "touchmove",
  "touchstart",
  "transitionend",
  "unload",
  "volumechange",
  "waiting",
  "wheel"
];

const IsDangerousString = (input: string): boolean => {
  if (isEmpty(input)) {
    return false;
  }

  if (StringContainsHtmlTagMarkers(input)) {
    for (let i = 0; i < xssTriggerPhrases.length; i++) {
      if (StringContainsTriggerPhrase(input, xssTriggerPhrases[i])) {
        return true;
      }
    }
  }

  return false;
};

const RenderXssSafeString = (input: string): string => {
  if (IsDangerousString(input)) {
    return "Potentially harmful content was not shown.";
  }

  return input;
};

const StringContainsHtmlTagMarkers = (input: string): boolean => {
  let stringToTest: string = RemoveExpectedHtmlTags(input);

  if (stringToTest.indexOf("<", 0) < 0) return false;

  if (stringToTest.indexOf(">", 0) < 0) return false;

  return true;
};

const RemoveExpectedHtmlTags = (input: string): string => {
  let result: string = input;

  for (let i = 0; i < permittedHtmlTags.length; i++) {
    result = result.replace(`<${permittedHtmlTags[i]}>`, "").replace(`</${permittedHtmlTags[i]}>`, "");
  }

  return result;
};

const GenerateTriggerPhrasePermutations = (triggerPhrase: string): string[] => [
  `${triggerPhrase}=`,
  `${triggerPhrase} =`,
  `${triggerPhrase}= `,
  `${triggerPhrase} = `
];

const StringContainsTriggerPhrase = (input: string, triggerPhrase: string) => {
  let triggerPhrasePermutations: string[] = GenerateTriggerPhrasePermutations(triggerPhrase);

  for (let i = 0; i < triggerPhrasePermutations.length; i++) {
    let matchPosition = input.indexOf(triggerPhrasePermutations[i], 0);

    if (matchPosition >= 0) {
      return true;
    }
  }

  return false;
};

export { IsDangerousString, RenderXssSafeString };
