import _, { debounce } from "lodash";
import { action, observable } from "mobx";
import React from "react";
import { MultiInput } from "../../../../components/ui/_forms/MultiInput";
import { DEBOUNCE_DELAY } from "../../../../constants";
import { FormFieldType } from "../../formField/FormFieldTypes";
import { FormFieldModel } from "../../formField/FormField_model";
import { INIT_TEXT_FIELD } from "../textField/TextField_init";
import { IMultiInputModel } from "./IMultiInputModel";


export const INIT_MULTIINPUT: Partial<IMultiInputModel> = {
  ...INIT_TEXT_FIELD,
  type: FormFieldType.MultiInput,
  onValueChange: () => { }
}



export class MultiInputModel extends FormFieldModel<string, any> implements IMultiInputModel {
  @observable value: any = null;
  @observable options: any[] = [];
  includeDuplicates: boolean = true;
  placeholder?: string = "";
  clearOnEnter?: boolean = true;
  @observable componentProps: React.HTMLProps<HTMLInputElement> = {};
  onItemSelected?: (val: string) => void = null;
  onChange?: (model: IMultiInputModel, val: string) => void;
  @observable.ref selectedItems: any[] = [];
  valueLabelFn: (obj: any) => string = e => {
    if (e.name) {
      return e.name;
    }
    console.error("The valueLabelFn in Autocomplete model is not set");
  };
  inputType?: "text" | "password" | "file" | "textarea" | "hidden";
  borderStyle?: "underline";
  testId?: string;


  constructor(initOpts?: IMultiInputModel) {
    super(initOpts as any);
    this.options = initOpts.options || this.options;
    this.validate = initOpts.validate || INIT_MULTIINPUT.validate;
    this.onItemSelected = initOpts.onItemSelected || this.onItemSelected;
    this.placeholder = initOpts.placeholder || this.placeholder;
    this.includeDuplicates = initOpts.includeDuplicates || this.includeDuplicates;
    this.clearOnEnter = initOpts.clearOnEnter || this.clearOnEnter
    this.onChange = initOpts.onChange || this.onChange;
    this.onChange = this.onChange ? debounce(this.onChange, DEBOUNCE_DELAY.FAST, { maxWait: 1000 }) : this.onChange;
    this.selectedItems = initOpts.value || this.selectedItems;
    this.valueLabelFn = initOpts.valueLabelFn || this.valueLabelFn;
    this.extractValue = initOpts.extractValue || this.extractValue;
  }
  @observable searchQuery: string = "";

  @action.bound
  setOptions(options: any[]) {
    this.options = options;
  }

  @action.bound
  setValue(val: any): void {
    this.searchQuery = val ? this.valueLabelFn(val) : "";
    this.value = val;
  }

  @action.bound
  setSearchQuery(val) {
    this.searchQuery = val;
    this.onChange && this.onChange(this, val);
  }

  getValue() {
    return this.value;
  }

  validate: (...args: any[]) => any;

  setFieldValue(val: any): void {
    throw new Error("Method not implemented.");
  }

  setSelectedItem = val => {
    this.addToValue(val);
    this.onItemSelected && this.onItemSelected(val);
  };

  extractValue = () => {
    return this.selectedItems;
  };

  @action
  reset(): void {
    this.searchQuery = "";
    this.value = null;
    this.selectedItems = [];
  }

  @action
  setSelectedItems = (items: any[]) => {
    this.selectedItems = items;
    this.value = items;
  };

  removeFromValue = (item, index) => {
    let s = this.selectedItems.slice();
    s.splice(index, 1);
    this.setSelectedItems(s);
  };

  @action
  addToValue = item => {
    let s = this.selectedItems.slice();
    if (!this.includeDuplicates && _.filter(this.selectedItems, e => e.id === item.id).length > 0) {
      return;
    }
    s.push(item);

    this.setSelectedItems(s);
  };

  renderComponent = () => {
    return (
      <MultiInput
        onEnter={(value: string) => {
          this.addToValue({ key: _.camelCase(value), label: value })
        }}
        clearOnEnter={this.clearOnEnter}
        inputProps={null}
        selectedItems={this.selectedItems}
        removeItem={this.removeFromValue}
        valueLabelFn={this.valueLabelFn}
      />
    );
  };
}