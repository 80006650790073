import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { IModalService } from "../../../../core/modal/IModalService";
import { Services } from "../../../../constants";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { getProjectStakeholderImpactsFormFields } from "./StakeholderImpactsView_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import I18n from "../../../../core/localization/I18n";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";
import { Animations } from "../../../../core/util/Animations";

export class StakeholderImpactsViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  isRouteView: boolean;
  stakeholderId: number;
  projectId: number;
  modalService: IModalService;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref impacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredImpacts: FP.Entities.IImpact[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  cookieService: ICookieService;
  organisationId: number;

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, isRouteView: boolean = true) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.isRouteView = isRouteView;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.organisationId = parseInt(this.cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID));
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.stakeholderId = parseInt(this.routeProps.match.params["stakeholderId"]);
    this.projectId = parseInt(this.routeProps.match.params["projectId"]);
  }

  onMount = () => {
    if (this.isRouteView) {
      this.loadProjectStakeholder(this.projectId, this.stakeholderId);
    }
  };

  onUnmount = () => {
    this.resetSearch();
  };

  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    this.isLoading = true;
    let res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload);
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    if (!this.projectId || !this.stakeholderId) {
      this.projectId = projectStakeholder.project.id;
      this.stakeholderId = projectStakeholder.stakeholder.id;
    }
    this.projectStakeholder = projectStakeholder;
    this.loadStakeholderImpacts(this.projectStakeholder.id);
  };

  @action
  loadStakeholderImpacts = async (projectStakeholderId: number) => {
    let res = await this.projectProvider.getProjectStakeholderImpacts(this.organisationId, this.projectId, projectStakeholderId);
    if (!res || res.isError) return;
    this.impacts = res.payload;
    this.filterImpacts();
    this.isLoading = false;
  };

  removeImpact = async (projectStakeholderId, impactId) => {
    this.httpProgress.showOverlay();
    const res = await this.impactProvider.removeStakeholder(this.organisationId, this.projectId, impactId, projectStakeholderId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterImpacts();
    this.hideSearchMode();
  };

  @action
  filterImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredImpacts = this.impacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredImpacts = this.impacts;
    }
  };

  showAddImpactModal = () => {
    let formFields = getProjectStakeholderImpactsFormFields(this.projectProvider, this.projectStakeholderProvider, this.projectStakeholder, this.organisationId, this.projectId);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: "link",
          className: "ml-auto"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          await this.projectProvider.addProjectStakeholderImpacts(this.organisationId, this.projectId, this.projectStakeholder.id, res.impacts);

          this.modalService.hide();
          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: "Button"
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.addImpacts")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: "bg-white"
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showImpactConfirmDeleteModal = (impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeImpact(this.projectStakeholder.id, impact.id);
          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
