import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { FilteredApiModel } from "../../filteredApi/FilteredApiModel";
import { IHttp } from "../../IHttp";
import { IOrganisationSettingsApi } from "./IOrganisationSettings.api";

export class OrganisationSettingsApi extends FilteredApiModel<FP.Entities.IOrganisationSetting> implements IOrganisationSettingsApi {
    controller: string = "organisations";
  
    constructor(http: IHttp) {
      super(http, "organisations");
      this.url = `${this.http.url}/${this.version}/organisations`;
    }

    getOrganisationSetting = async (
        organisationId: number,
        settingId: number,
        config?: AxiosRequestConfig
      ): Promise<IFlightPathApiResponse<FP.Entities.IOrganisationSetting>> => {
        const url = `${this.url}/${organisationId}/setting/${settingId}`;
        let res = await this.http.get(url);
        if (res && res.status === 200) {
            return res.data as IFlightPathApiResponse<FP.Entities.ICustomPropertyType[]>;
        }
    }
}