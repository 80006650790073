import * as React from "react";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import moment from "moment";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import * as _ from "lodash";
import I18n from "../../../../core/localization/I18n";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { Enums } from "../../../../enums";
import { PROGRESS_STATUS_OPTIONS } from "../../../../constants";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";

export const getProgrammeFormFields = (
  orgId: number,
  orgProvider: IOrganisationsApi,
  authUser: FP.Entities.IUser,
  programme?: FP.Entities.IProgramme
): IFormFieldModel<any, any>[] => {
  const organisationId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: orgId && orgId + "",
    defaultValue: orgId && orgId + ""
  };

  const programmeId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "programmeId",
    inputType: "hidden",
    value: programme?.id + "",
    defaultValue: programme?.id + ""
  };

  let actualEndDate = {
    ...INIT_TEXT_FIELD,
    key: "actualEndDate",
    inputType: "hidden",
    value: programme?.actualEndDate
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.enterProgrammeName"),
    label: <label htmlFor="name">{I18n.t("forms.programmeName")} *</label>,
    value: programme && programme.name,
    fieldClassName: "col-lg-6 col-space-lg-6",
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.programmeName")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  const stakeholderOwner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderOwnerId",
    label: <label htmlFor={"stakeholderOwnerId"}>{I18n.t("forms.programmeOwner")} *</label>,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getStakeholders(orgId, { pageSize: 10000000, page: 1 });

      if (res?.payload) {
        const stakeholders = res.payload.filter(e => e.stakeholderType === Enums.StakeholderType.INDIVIDUAL);
        const sortedStakeholders = _.orderBy(stakeholders, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        self.setOptions(sortedStakeholders);
      }
    },
    componentProps: {
      className: "form-control",
      icon: "user"
    },
    charInputNumber: 1,
    validate: function () {
      let self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.programmeOwner")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    fieldClassName: "col-lg-6",
    value: programme && programme.stakeholderOwner,
    extractValue: function () {
      return this.value?.id;
    },
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    }
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")} *</label>,
    placeholder: I18n.t("placeholders.selectStartDate"),
    fieldClassName: "col-lg-6",
    componentProps: {
      icon: "calendar",
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.startDate")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: programme && moment(programme.startDate)
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "initialEndDate",
    label: <label htmlFor="initialEndDate">{I18n.t("forms.endDate")} *</label>,
    placeholder: I18n.t("placeholders.selectEndDate"),
    fieldClassName: "col-lg-6",
    subscribeTo: ["startDate", "progressStatus", "actualEndDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "startDate") {
        const sd = field.value;
        if (sd) {
          self.setDatepickerProps({
            isOutsideRange: day => {
              return day < sd;
            }
          });
        }
      }
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      let startDate = this.channels.startDate.value;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDate")}</ErrorMessage>;
        return false;
      }

      if (startDate > this.value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: "calendar",
      datePickerProps: programme && {
        isOutsideRange: day => {
          return day < moment(programme.startDate);
        }
      }
    },
    extractValue: function () {
      const progress = this.channels.progressStatus.extractValue();
      this.channels.actualEndDate.value = this.value?.format(this.exportedDateFormat);
      if (!programme || progress === Enums.ProgressStatus.NOT_STARTED) {
        this.key = "initialEndDate";
        return this?.value?.format(this.exportedDateFormat);
      } else {
        this.key = "disableInitialDate";
        return this.channels.actualEndDate.value;
      }
    },
    value: programme && moment(programme.actualEndDate)
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
    fieldClassName: "col-12",
    value: programme?.description
  };

  const objectives: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "objectivesText",
    label: <label htmlFor="objectivesText">{I18n.t("forms.objectives")}</label>,
    placeholder: I18n.t("placeholders.programmeObjectives"),
    fieldClassName: "col-12",
    value: programme?.objectivesText || I18n.t("placeholders.defaultObjectives"),
    defaultValue: I18n.t("placeholders.defaultObjectives")
  };

  const projectSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "projectSearch",
    label: <label htmlFor={"projectSearch"}>{I18n.t("forms.projects")}</label>,
    placeholder: I18n.t("placeholders.searchProject"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      const res = await orgProvider.getOrphanProjects(orgId);

      if (res?.payload) {
        const sortedProjects = _.orderBy(res.payload, [project => project.name.toLowerCase()]);
        self.setOptions(sortedProjects);
      }
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: "col-lg-6",
    valueLabelFn: e => e.name,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.projectDesc")}</p>
      </FormTooltip>
    ),
    subscribeTo: ["projects"],
    onItemSelected: async function () {
      const self: IAutocompleteModel = this;
      const listingModel: IListingModel = self.channels.projects as IListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
  };

  let projectListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "projects",
    placeholder: I18n.t("placeholders.selectProject"),
    subscribeTo: ["projectSearch"],
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val) {
        this.addItem(val);
        field.reset();
      }
    },
    label: <label htmlFor={"projects"}>{I18n.t("forms.selectedProjects")} </label>,
    fieldClassName: "col-lg-6",
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: e => <p className="mb-0 d-inline-block">{e.name}</p>,
    value: programme?.projects
  };

  let location: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "locations",
    label: <label htmlFor={"locations"}>{I18n.t("forms.locations")} *</label>,
    manageLink: `/organisations/${orgId}/settings/locations`,
    placeholder: I18n.t("placeholders.searchLocation"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function () {
      let self: IMultiSelectorModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getLocations(orgId);

      if (res?.payload) {
        const sortedLocations = _.orderBy(res.payload, [location => location.name.toLowerCase()]);
        self.setOptions(sortedLocations);
      }
    },
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.programmeLocations")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: "col-12 col-lg-6",
    valueLabelFn: e => e?.name,
    value: programme && programme.locations,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    }
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")} *</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.progressStatus")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col-12 col-lg-6",
    extractValue: function () {
      return this.value?.key;
    },
    value: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(
      e => e.key === programme?.progressStatus
    )
  };

  const contact: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "contactId",
    label: <label htmlFor="contactId">{I18n.t("forms.programmeContact")} * </label>,
    placeholder: I18n.t("placeholders.selectUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: "user"
    },
    charInputNumber: 1,
    fieldClassName: "col-12 col-lg-6",
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.programmeContact")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      if (self.options.length === 0) {
        const res = await orgProvider.getUsers(orgId);

        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          self.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    value: (programme && programme.contact) || authUser,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.programmeContactDesc")}</p>
      </FormTooltip>
    )
  };

  let isPrivate: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isPrivate",
    label: <label htmlFor="isPrivate">{I18n.t("forms.isProgrammePrivate")}</label>,
    fieldClassName: "col-12 col-lg-6",
    options: [
      {
        key: "isPrivateYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "isPrivate"
        }
      },
      {
        key: "isPrivateNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "isPrivate"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes";
    },
    onValueChange: function (val) {
      if (val === "yes") {
        this.fieldClassName = "col-12 col-lg-6";
        return;
      }
      this.fieldClassName = "col-12 col-lg-6 col-space-lg-6";
    },
    value: programme?.isPrivate ? "yes" : "no"
  };

  const fields = [];

  if (orgId) fields.push(organisationId);
  if (programme) fields.push(programmeId);

  fields.push(name);
  fields.push(contact);
  fields.push(stakeholderOwner);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(actualEndDate);
  fields.push(progressStatus);
  fields.push(location);
  fields.push(description);
  fields.push(objectives);
  fields.push(projectSearch);
  fields.push(projectListing);
  fields.push(isPrivate);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
