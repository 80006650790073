import * as React from "react";
import { ISingleFormModel } from "./SingleForm_model";
import { Form } from "../../../../core/forms/baseForm/Form_view";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { observer } from "mobx-react-lite";

export interface SingleFormProps {
  model: ISingleFormModel;
}

const SingleForm: React.FunctionComponent<SingleFormProps> = observer(({ model }) => {
  const { formFields, actions } = model;
  return (
    <>
      <Form className={`row ${model.className || ""}`} model={{ formFields: formFields }} />

      {actions?.length ? (
        <div className="row">
          <div className="col">
            <div className="d-flex">
              {actions.map(action => (
                <UiActionComponent key={action.id} action={action} />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});

export { SingleForm };
