import React from "react";
import { LinkButton } from "../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import { ProjectSettingsModel } from "./ProjectSettings_model";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useParams } from "react-router-dom";
import { Animations } from "../../../../core/util/Animations";

export interface ProjectSettingsProps {
  model: ProjectSettingsModel;
}

const ProjectSettings: React.FunctionComponent<ProjectSettingsProps> = observer(({ model }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  const { project } = model;

  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}
      id="EditProjectPage">
      <div className="row">
        <div className="col">
          <h1 className="d-inline-block">
            {project.name} | <strong>{I18n.t("phrases.manageProject")}</strong>
          </h1>
          <LinkButton
            type="outline-primary"
            href={`/organisations/${organisationId}/projects/${model.project?.id}`}
            className="float-right"
          >
            {I18n.t("phrases.closeSettings")}
          </LinkButton>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("phrases.manageProjectDescription")}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-10">
          <SingleForm model={model.formModel} />
        </div>
      </div>
    </div>
  );
});

export { ProjectSettings };
