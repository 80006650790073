import React from "react";

export interface SideBarLayoutProps extends React.HTMLProps<HTMLDivElement> {}

const SideBarLayout: React.FunctionComponent<SideBarLayoutProps> = props => {
  return (
    <div {...props} className="side-bar-layout">
      {React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            className: `side-bar-layout__block ${child.props.className || ""}`
          });
        }
      })}
    </div>
  );
};

export { SideBarLayout };
