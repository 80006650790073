import * as React from "react";
import { ITableConfig } from "../../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { Icon } from "../../../../../components/ui/Icon";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { ProgrammePermissionModel } from "./ProgrammePermissions_model";

export const GetUserTableConfig: (
  parentModel: ProgrammePermissionModel,
  organisationId: number
) => ITableConfig<any> = (parentModel: ProgrammePermissionModel, organisationId: number) =>
  ({
    actions: [
      {
        id: "action1",
        label: I18n.t("phrases.view"),
        hrefFn: (obj: any) => `/organisations/${organisationId}/users/${obj.rowObject.id}`,
        componentProps: {
          type: "link"
        },
        rendersIn: "HTMLAnchor"
      },
      {
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: (ev, row: ITableRowModel) => {
          parentModel.showPermissionConfirmDeleteModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    ],
    colHeaders: [
      {
        key: "firstName,lastname",
        content: <h4 className="mb-0">{I18n.t("table.user")}</h4>,
        selector: (obj: FP.Entities.IUser) => {
          return (
            <p className="mb-0">
              <Icon symbol={"chevron-right"} className="mr-2" />
              {obj.firstName + " " + obj.lastName}
            </p>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "email",
        content: <h4 className="mb-0">{I18n.t("table.email")}</h4>,
        selector: (obj: FP.Entities.IUser) => {
          return <p className="mb-0">{obj.email}</p>;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "permissionScope",
        content: <h4 className="mb-0">{I18n.t("table.permissionScope")}</h4>,
        selector: (obj: FP.Entities.IOrganisation) => I18n.t(Enums.Translator.PermissionScope(obj.permissionScope)),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ],
    tableProps: {}
  } as any);

export const GetInheritUsersTableConfig = () =>
({
  actions: [],
  colHeaders: [
    {
      key: "firstName,lastname",
      content: <h4 className="mb-0">{I18n.t("table.user")}</h4>,
      selector: (obj: FP.Entities.IUser) => {
        return (
          <p className="mb-0">
            <Icon symbol={"chevron-right"} className="mr-2" />
            {obj.firstName + " " + obj.lastName}
          </p>
        );
      }
    },
    {
      key: "email",
      content: <h4 className="mb-0">{I18n.t("table.email")}</h4>,
      selector: (obj: FP.Entities.IUser) => {
        return <p className="mb-0">{obj.email}</p>;
      }
    },
    {
      key: "inheritsFrom",
      content: <h4 className="mb-0">{I18n.t("table.inheritsFrom")}</h4>,
      selector: (obj: any) => <p className="mb-0">{obj.inheritsFrom}</p>
    },
    {
      key: "permissionScope",
      content: <h4 className="mb-0">{I18n.t("table.permissionScope")}</h4>,
      selector: (obj: FP.Entities.IOrganisation) => I18n.t(Enums.Translator.PermissionScope(obj.permissionScope))
    }
  ],
  tableProps: {}
} as ITableConfig<any>);
