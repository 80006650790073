import * as React from 'react';
import I18n from '../../../../core/localization/I18n';
import { Tooltip, TooltipIcon } from '../../../../components/ui/Tooltip';
import { Panel } from '../../../../components/ui/Panel';
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface ImpactGroupDisplayProps {
    item: FP.Entities.IImpactGroup;
}

const ImpactGroupDisplay: React.FunctionComponent<ImpactGroupDisplayProps> = (props) => {
    const { item } = props;

    return <>
        <div className="row mb-4">
            <div className="col-12">
                <h4 className="mb-2">{I18n.t("table.changeBenefit")}</h4>
                <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.changeBenefitText) || I18n.t("phrases.noChangeBenefit") }}></div>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.impactTypes")}</h4>
                <p>
                    {item.nImpactTypes?.length ?
                        item.nImpactTypes.map(e => e.name).join(", ")
                        :
                        I18n.t("phrases.noImpactTypes")
                    }
                </p>
            </div>
            <div className="col-lg-6">
                <h4 className="mb-2">{I18n.t("table.locations")}</h4>
                <p>
                    {item.locations?.length ?
                        item.locations.map(e => e.name).join(", ")
                        :
                        I18n.t("phrases.noLocations")
                    }
                </p>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-12">
                <h4 className="mb-2 d-inline-block">{I18n.t("table.impactLevels")}</h4>
                <Tooltip
                    shownElement={<TooltipIcon className="ml-2 mb-2" />}
                    triggeredOn="hover"
                    position="top-right"
                    className="impact-group-display__tooltip"
                >
                    <Panel
                        background="bg-white"
                        className="p-3 ml-1 mb-3"
                        hasBorderRadius={true}
                        type="border-lines"
                    >
                        <p className="mb-0"><em>{I18n.t("phrases.impactGroupLevelCalcDescription")}</em></p>
                    </Panel>
                </Tooltip>
                <p>{Math.round(parseFloat(item.impactLevels))}</p>
            </div>
        </div>
    </>
}

export { ImpactGroupDisplay };