import * as React from 'react';
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IFilterModel } from "../../../../../core/filter/Filter_model";
import { ITableConfig, ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { ILogger } from "../../../../../services/local/Logger/ILogger";
import { ImpactReportGroupTypes } from "../impactsByProjects/ImpactsByProjects_model";
import { GetImpactTableConfig } from "./ImpactDetailedReport_config";
import { Icon } from '../../../../../components/ui/Icon';
import I18n from '../../../../../core/localization/I18n';
import { IModalService } from '../../../../../core/modal/IModalService';
import { IImpactsApi } from '../../../../../services/api/v1/impacts/IImpacts.api';
import { ITableRowModel } from '../../../../../core/table/ITableRowModel';
import { ButtonIcon, LinkButton } from '../../../../../components/ui/Button';
import { Enums } from '../../../../../enums';
import { ImpactCompactViewModel } from '../../../impacts/ImpactCompactView/ImpactCompactView_model';
import { ImpactCompactView } from '../../../impacts/ImpactCompactView/ImpactCompactView_view';
import { IBusinessAreasApi } from '../../../../../services/api/v1/businessAreas/IBusinessAreas.api';
import { IStakeholdersApi } from '../../../../../services/api/v1/stakeholders/IStakeholders.api';
import { convertStakeholderToName } from '../../../../../core/util/Helpers';
import { ILocationsApi } from '../../../../../services/api/v1/locations/ILocations.api';
import { Animations } from '../../../../../core/util/Animations';

export class ImpactDetailedReportModel extends BaseModel {
  tableModel: ITableModel<FP.Entities.IImpact>;
  filterModel: IFilterModel<any>;
  @observable.ref data: FP.Entities.IImpact[];
  @observable title: string;
  appService: AppService;
  group: ImpactReportGroupTypes;
  organisationId: number;
  projectId: number;
  resourceId: number;
  organisationProvider: IOrganisationsApi;
  logger: ILogger;
  tableConfig: ITableConfig<FP.Entities.IImpact>;
  modalService: IModalService;
  impactProvider: IImpactsApi;
  httpProgress: any;
  businessProvider: IBusinessAreasApi;
  stakeholderProvider: IStakeholdersApi;
  locationProvider: ILocationsApi;


  /**
   *
   */
  constructor(appService: AppService, group: ImpactReportGroupTypes, organisationId: number, projectId: number, resourceId: number) {
    super();
    this.appService = appService;
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.logger = this.appService.getService<ILogger>(Services.Logger);
    this.businessProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi)
    this.locationProvider = this.appService.getService<ILocationsApi>(Services.LocationsApi);
    this.stakeholderProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.group = group;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.resourceId = resourceId;
    this.tableModel = new TableModel();
    this.setTable();
  }

  setFilterModel = (filterModel: IFilterModel<any>) => {
    this.filterModel = filterModel;
  };

  onMount = async () => {
    this.title = await this.getTitle(this.group);
  }

  onUnmount = () => {

  }

  setTable = () => {
    this.tableConfig = GetImpactTableConfig(this, this.tableModel, this.projectId);
    this.tableModel.set(this.tableConfig);
  };


  loadImpacts = async (organisationId: number, projectId: number, resourceId: number, group: ImpactReportGroupTypes) => {
    const loadFn = this.getLoadFunction(group);

    if (!loadFn) {
      this.logger.LogError("Impact report function could not be detected, make sure the correct group is passed");
    }

    const result = await loadFn(organisationId, projectId, resourceId);

    if (!result || result.isError) {
      return;
    }

    this.setData(result.payload);
  }

  @action
  setData = (data: FP.Entities.IImpact[]) => {
    this.data = data;
    this.tableModel.setData(data);
    this.tableModel.isLoading = false;
  };


  showImpactConfirmDeleteModal = (projectId: number, impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol="alert-circle" className="mr-2" />
              {I18n.t("warnings.removeImpactFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          await this.removeImpact(projectId, impact.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };

  removeImpact = async (projectId: number, impactId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.remove(this.organisationId, projectId, impactId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    this.loadImpacts(this.organisationId, this.projectId, this.resourceId, this.group);
  };

  showImpactModal = (row: ITableRowModel) => {
    const id = row.rowObject.id;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon
            type="outline-primary"
            iconSize={Enums.UiSizes.SM}
            symbol="close"
            className="float-right"
            onClick={this.modalService.hide}
            key={"1"}
          />
          <LinkButton
            className="float-right mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
        </div>
      ),
      content: <ImpactCompactView model={new ImpactCompactViewModel(this.appService, this.projectId, id, this.organisationId)} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: "bg-light",
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  getLoadFunction(group: ImpactReportGroupTypes) {
    switch (group) {
      case ImpactReportGroupTypes.BUSINESS_AREAS:
        return this.organisationProvider.getImpactReportByBusinessAreaDetails
      case ImpactReportGroupTypes.LOCATIONS:
        return this.organisationProvider.getImpactReportByLocationDetails
      case ImpactReportGroupTypes.STAKEHOLDERS:
        return this.organisationProvider.getImpactReportByStakeholderDetails

      default:
        break;
    }
  }

  getTitle = async (group: ImpactReportGroupTypes) => {
    let fn = this.getResourceProviderFn(group);

    let res = await fn(this.organisationId, this.resourceId);
    if (res.isError) return;

    switch (group) {
      case ImpactReportGroupTypes.BUSINESS_AREAS:
      case ImpactReportGroupTypes.LOCATIONS:
        return (res.payload as any).name;
      case ImpactReportGroupTypes.STAKEHOLDERS:
        const st = res.payload as FP.Entities.IStakeholder;
        return convertStakeholderToName(st);
      default:
        break;
    }

  }

  getResourceProviderFn = (group: ImpactReportGroupTypes) => {
    switch (group) {
      case ImpactReportGroupTypes.BUSINESS_AREAS:
        return this.businessProvider.getById;
      case ImpactReportGroupTypes.LOCATIONS:
        return this.locationProvider.getById;
      case ImpactReportGroupTypes.STAKEHOLDERS:
        return this.stakeholderProvider.getById;
      default:
        break;
    }
  }
}
