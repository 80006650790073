import * as React from 'react';

const SvgRotateCcw = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="rotate-ccw_svg__feather rotate-ccw_svg__feather-rotate-ccw"
        {...props}
    >
        <path d="M1 4v6h6" />
        <path d="M3.51 15a9 9 0 102.13-9.36L1 10" />
    </svg>
);

export default SvgRotateCcw;
