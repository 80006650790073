// TODO - Make Milestones settings reusable across projects and programmes

import React from "react";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../../../constants";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { ButtonIcon } from "../../../../../components/ui/Button";
import { MilestoneModal } from "./MilestoneModal_view";
import { MilestoneModalModel } from "./MilestoneModal_model";
import { IMilestonesApi } from "../../../../../services/api/v1/milestones/IMilestones.api";
import { IProgrammesApi } from "../../../../../services/api/v1/programmes/IProgrammes.api";
import I18n from "../../../../../core/localization/I18n";
import moment from "moment";
import { ICookieService } from "../../../../../core/util/CookieService";
import { Enums } from "../../../../../enums";
import { Animations } from "../../../../../core/util/Animations";

export class MilestoneSettingsModel extends BaseModel {
  id: number;
  appService: AppService;
  tableModel: ITableModel<FP.Entities.IMilestone>;
  modalService: IModalService;
  milestoneProvider: IMilestonesApi;
  programmeProvider: IProgrammesApi;
  @observable.ref programme: FP.Entities.IProgramme;
  @observable.ref milestones: FP.Entities.IMilestone[] = [];
  orgId: number;

  constructor(appService: AppService, programme: FP.Entities.IProgramme) {
    super();
    this.appService = appService;
    this.programme = programme;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.milestoneProvider = this.appService.getService<IMilestonesApi>(Services.MilestonesApi);
    this.programmeProvider = this.appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    let cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.orgId = parseInt(cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID), 10);
    this.setTableConfig();
  }

  onMount() { }

  setTableConfig = () => {
    this.tableModel = new TableModel();
    this.tableModel.setData(this.milestones);

    const tableConfig: ITableConfig<FP.Entities.IMilestone> = {
      colHeaders: [
        {
          key: "name",
          content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => obj.name
        },
        {
          key: "deadline",
          content: <h4 className="mb-0">{I18n.t("table.milestoneDate")}</h4>,
          selector: (obj: FP.Entities.IMilestone) => moment(obj.deadline).format("L")
        }
      ] as any[],
      actions: [
        {
          id: "edit",
          label: I18n.t("phrases.edit"),
          onAction: (ev, row: ITableRowModel) => {
            this.showMilestoneModal(row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        },
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          onAction: (ev, row: ITableRowModel) => {
            this.showConfirmDeleteModal(row.rowObject);
          },
          componentProps: {
            type: "link"
          },
          rendersIn: "Button"
        }
      ]
    };

    this.tableModel.set(tableConfig);
  };

  @action
  setLoading = (loadingState: boolean) => { };

  @action
  setMilestones = (milestones: FP.Entities.IMilestone[]) => {
    this.milestones = milestones;
    this.tableModel.setData(milestones);
  };

  @action
  setProgramme = (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
  };

  loadData = async (id: number) => {
    const res = await this.milestoneProvider.getProgrammeMilestones(this.orgId, this.programme.id);
    if (!res || res.isError) return;
    this.setMilestones(res.payload);
  };

  showMilestoneModal = (milestone?: FP.Entities.IMilestone) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon symbol="close" className="float-right mr-1" onClick={this.modalService.hide} />
        </div>
      ),
      content: (
        <MilestoneModal model={new MilestoneModalModel(this.appService, this.orgId, this.programme.id, this.loadData, milestone)} />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        overflow: "visible",
        panelProps: {
          background: "bg-light",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT,
      }
    });
  };

  showConfirmDeleteModal = (milestone: FP.Entities.IMilestone) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: milestone.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: "bg-white"
          }
        },
        async () => {
          const res = await this.milestoneProvider.deleteProgrammeMilestone(this.orgId, this.programme.id, milestone.id);
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.modalService.hide();
          this.loadData(this.id);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        "danger"
      );
    });
  };
}
