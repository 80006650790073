import * as React from "react";
import { Panel } from "../../ui/Panel";
import I18n from "../../../core/localization/I18n";
import { ImpactHistoryModel } from "./ImpactHistory_Model";
import { observer } from "mobx-react";
import { AvatarContent } from "../../ui/AvatarContent";
import { UiSizes } from "../../../enums";
import { Spinner } from "../../ui/Spinner";
import moment from "moment";
import { Tooltip } from "react-tippy";

interface ImpactHistoryProps {
  model: ImpactHistoryModel;
}

const ImpactHistory: React.FunctionComponent<ImpactHistoryProps> = observer(({ model }) => {
  let profileAvatar = () => {
    return (
      <AvatarContent
        type="primary"
        avatarProps={{
          size: UiSizes.SM,
          imgSrc: model.createdByProfileImageUrl
        }}
        onMouseEnter={() => model.loadHistory()}
      ></AvatarContent>
    );
  };

  let historyPanel = () => {
    return (
      <Panel
        background="bg-white"
        className="p-0 target-label impact-history__panel"
        hasBorderRadius={true}
        type="border-lines"
      >
        <>
          {!model.isLoading && (
            <div className="impact-history__panel p-4">
              <h4 className="p-0 m-0">{I18n.t("table.created")}</h4>
              <ul className="impact-history__list p-2 m-0">
                {historyLineItem(I18n.t("table.by"), model.dataHistory.createdByName)}
                {historyLineItem(I18n.t("table.on"), uncDateToUKDate(model.dataHistory.createdOnDate))}
              </ul>

              <h4 className="p-0 m-0">{I18n.t("table.lastUpdated")}</h4>
              <ul className="impact-history__list p-2 m-0">
                {historyLineItem(I18n.t("table.by"), model.dataHistory.lastUpdatedByName)}
                {historyLineItem(I18n.t("table.on"), uncDateToUKDate(model.dataHistory.lastUpdatedOnDate))}
              </ul>
            </div>
          )}

          {model.isLoading && <Spinner></Spinner>}
        </>
      </Panel>
    );
  };

  let historyLineItem = (title, data) => {
    return (
      <li className="m-0 p-0">
        {title}: {data}
      </li>
    );
  };

  let uncDateToUKDate = date => {
    return moment(date).format("L");
  };

  return (
    <Tooltip html={historyPanel()}>
      {profileAvatar()}
    </Tooltip>
  );
});

export { ImpactHistory };
