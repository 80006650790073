import * as React from 'react';



export interface FormTooltipProps {
    className?: string;
}



const FormTooltip: React.FunctionComponent<FormTooltipProps> = (props) => {
    return <div className={`${props.className || ""}`}
        style={{ width: "250px" }}
    >
        {props.children}
    </div>
}

export { FormTooltip };