import { RouteComponentProps } from "react-router-dom";
import { AppService } from "strikejs-app-service";
import { DisposableModel } from "../../../../../core/util/DisposableModel";

export class StakeholderVisualisationsPlaceholderPageModel extends DisposableModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  organisationId: number;
  projectId: number;
  title: string;

  constructor(
    appService: AppService,
    routeProps: RouteComponentProps,
    projectId: number,
    organisationId: number,
    title: string
  ) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.title = title;
  }

  onMount = async () => {};

  onUnmount = () => {};
}
