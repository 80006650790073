import React, { useCallback, useEffect, useState } from "react";
import { ActionExtendedViewModel } from "./ActionExtendedView_model";
import { Enums } from "../../../../enums";
import { LinkButton } from "../../../../components/ui/Button";
import { Icon } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ActionImpacts } from "../actionImpacts/ActionImpacts_view";
import { ActionDisplay } from "../ActionDisplay";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { CanEditProject } from "../../../../components/hoc/CanEdit";
import { RevisionHistoryView } from "../../revisionHistory/RevisionHistory_view";
import { useAppService } from "../../../../AppService";
import { useParams } from "react-router-dom";
import { useCurrentOrganisation, useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Async } from "react-async";
import { useFlightPathUser } from "../../../../setup";
import { Animations } from "../../../../core/util/Animations";

export interface ActionExtendedViewProps {
  model?: ActionExtendedViewModel;
}

const ActionExtendedView: React.FunctionComponent<ActionExtendedViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const authUser = useFlightPathUser();
  const organisation = useCurrentOrganisation();
  const organisationId = useCurrentOrganisationId();
  const { actionId, projectId } = useParams<{ projectId: string; actionId: string }>();
  const [model] = useState(
    () =>
      m ||
      new ActionExtendedViewModel({
        authUser,
        appService,
        organisationId: organisation.id,
        actionId: +actionId,
        projectId: +projectId
      })
  );
  const { action } = model;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const load = useCallback(async () => {
    await model.loadAction();
  }, [model]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="action-extended-view data-extended-view pt-6">
            <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
              <div className="row">
                <div className="col">
                  <LinkButton
                    size={Enums.UiSizes.MD}
                    type="link-big"
                    className="p-0"
                    href={`/organisations/${organisationId}/projects/${model.projectId}/actions`}
                  >
                    <Icon className="mr-2" symbol="ChevronLeft" />
                    {I18n.t("phrases.backToActions")}
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="data-extended-view__block data-extended-view__block--wrapper">
              <div className={`data-extended-view__col container-fluid ${Animations.FP_ZOOM_IN} speed-5`}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="d-flex">
                      <h2 className="mb-0" id="ActionNameLabel">
                        <RagStatus state={action.ragStatus} className="mr-2" />
                        <span className="mr-2 h2-secondary font-weight-bold">{action.refNumber}</span>
                        {action.name} | <strong>{I18n.t("phrases.details")}</strong>
                      </h2>

                      <CanEditProject projectId={model.projectId}>
                        <LinkButton
                          id="EditActionButton"
                          className="ml-auto"
                          type={"outline-primary"}
                          href={`/organisations/${organisationId}/projects/${model.projectId}/actions/${model.actionId}/edit`}
                        >
                          <Icon symbol="Pencil" size={Enums.UiSizes.SM} className="mr-2" />
                          {I18n.t("phrases.edit")}
                        </LinkButton>
                      </CanEditProject>
                    </div>
                  </div>
                </div>
                <ActionDisplay item={action} />
              </div>
              <Panel
                background="bg-light"
                className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FP_ZOOM_IN} speed-5`}
              >
                <TabGroup
                  className="data-extended-view__tabs"
                  tabs={[
                    {
                      id: "ImpactsTab",
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.impacts")}</h3>,
                      children: <ActionImpacts model={model.actionImpactsModel} />
                    },
                    {
                      id: "NotesTab",
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                      children: <CommentListView model={model.commentViewModel} />
                    },
                    {
                      id: "HistoryTab",
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.actionHistory")}</h3>,
                      children: <RevisionHistoryView model={model.revisionHistoryModel} />
                    }
                  ]}
                ></TabGroup>
              </Panel>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});

export { ActionExtendedView };
