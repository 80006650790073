import { IFlightPathApiResponse } from "../../BaseApiModel";
import { IUserReportsApi } from "./IUserReports.api";
import { IHttp } from "../../IHttp";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { IFilteredOptions, FilteredApiModel } from "../../filteredApi/FilteredApiModel";

export class UserReportsApi extends FilteredApiModel<any> implements IUserReportsApi {
  controller: string = "userReports";

  constructor(http: IHttp) {
    super(http, "userReports");
  }

  getOverdueActions = (
    filteredOptions: Partial<IFilteredOptions>,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IAction[]>) => void,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    this.http
      .get(this.url + "overdueActions" + query, config)
      .then((e: AxiosResponse<IFlightPathApiResponse<FP.Entities.IAction[]>>) => {
        if (e.status === 200) {
          cb(e.data);
        }
      });
  };

  getOverdueActionsAsync = async (filteredOptions: Partial<IFilteredOptions>, config?: AxiosRequestConfig) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "overdueActions" + query, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getUpcomingActions = (
    filteredOptions: Partial<IFilteredOptions>,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IAction[]>) => void,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    this.http
      .get(this.url + "upcomingActions" + query, config)
      .then((e: AxiosResponse<IFlightPathApiResponse<FP.Entities.IAction[]>>) => {
        if (e.status === 200) {
          cb(e.data);
        }
      });
  };

  getUpcomingActionsAsync = async (filteredOptions: Partial<IFilteredOptions>, config?: AxiosRequestConfig) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "upcomingActions" + query, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getUserStakeholder = (
    filteredOptions: Partial<IFilteredOptions>,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>) => void,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    this.http
      .get(this.url + "stakeholders" + query, config)
      .then((e: AxiosResponse<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>>) => {
        if (e.status === 200) {
          cb(e.data);
        }
      });
  };

  getUserStakeholderAsync = async (filteredOptions: Partial<IFilteredOptions>, config?: AxiosRequestConfig) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "stakeholders" + query, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getActionsOverTime = (
    filteredOptions: Partial<IFilteredOptions>,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IAction[]>) => void,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    this.http
      .get(this.url + "actionsOverTime" + query, config)
      .then((e: AxiosResponse<IFlightPathApiResponse<FP.Entities.IAction[]>>) => {
        if (e.status === 200) {
          cb(e.data);
        }
      });
  };

  getActionsOverTimeAsync = async (filteredOptions: Partial<IFilteredOptions>, config?: AxiosRequestConfig) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "actionsOverTime" + query, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getActionsByType = (
    filteredOptions: Partial<IFilteredOptions>,
    cb: (obj: IFlightPathApiResponse<FP.Entities.IAction[]>) => void,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    this.http
      .get(this.url + "actionsByType" + query, config)
      .then((e: AxiosResponse<IFlightPathApiResponse<FP.Entities.IAction[]>>) => {
        if (e.status === 200) {
          cb(e.data);
        }
      });
  };

  getActionsByTypeAsync = async (filteredOptions: Partial<IFilteredOptions>, config?: AxiosRequestConfig) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "actionsByType" + query, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IAction[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
}
