import * as React from 'react';

const SvgAlignCenter = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="align-center_svg__feather align-center_svg__feather-align-center"
        {...props}
    >
        <path d="M18 10H6M21 6H3M21 14H3M18 18H6" />
    </svg>
);

export default SvgAlignCenter;
