import { isEmpty } from "lodash";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../constants";
import { BaseModel } from "../../../core/util/BaseModel";
import { IImpactsApi } from "../../../services/api/v1/impacts/IImpacts.api";
import { IQuickImpactsApi } from "../../../services/api/v1/quickImpacts/IQuickImpacts.api";

export class ImpactHistoryModel extends BaseModel {
  appService: AppService;
  createdByProfileImageUrl: string;
  impactId: number;
  organisationId: number;
  projectId: number;
  isQuickImpact: boolean;
  impactProvider: IImpactsApi;
  quickImpactProvider: IQuickImpactsApi;
  @observable dataHistory: FP.Entities.IDataHistory;
  @observable isLoading: boolean = true;

  constructor(
    appService: AppService,
    createdByProfileImageUrl: string,
    impactId: number,
    organisationId: number,
    projectId: number,
    isQuickImpact: boolean
  ) {
    super();
    this.appService = appService;

    this.quickImpactProvider = this.appService.getService<IQuickImpactsApi>(Services.QuickImpactsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactId = impactId;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.isQuickImpact = isQuickImpact;
    this.createdByProfileImageUrl = createdByProfileImageUrl;
  }

  @action
  loadHistory = async () => {
    if (isEmpty(this.dataHistory)) {
      if (this.isQuickImpact) {
        this.loadQuickImpactHistory();
      } else {
        this.loadImpactHistory();
      }
    }
  };

  loadImpactHistory = async () => {
    const res = await this.impactProvider.getDataHistory(this.organisationId, this.projectId, this.impactId);
    if (res.isError) {
      return;
    }

    this.dataHistory = res.payload;
    this.isLoading = false;
  };

  loadQuickImpactHistory = async () => {
    const res = await this.quickImpactProvider.getDataHistory(this.organisationId, this.projectId, this.impactId);
    if (res.isError) {
      return;
    }

    this.dataHistory = res.payload;
    this.isLoading = false;
  };
}
