import * as React from "react";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { IProjectsApi } from "../../../../../services/api/v1/projects/IProject.api";
import { AutocompleteModel } from "../../../../../core/forms/controls/autocomplete/Autocomplete_model";
import { ListingModel, INIT_LISTING_FIELD } from "../../../../../core/forms/controls/listing/Listing_model";
import { IListingModel } from "../../../../../core/forms/controls/listing/IListingModel";
import I18n from "../../../../../core/localization/I18n";
import _ from "lodash";

export const getImpactListViewForm = (
  orgId: number,
  projectProvider: IProjectsApi,
  impactGroup?: Partial<FP.Entities.IImpactGroup>
) => {
  const impactSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactSearch",
    label: <label htmlFor={"impactSearch"}>{I18n.t("forms.impacts")}</label>,
    placeholder: I18n.t("placeholders.searchImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: "search"
    },
    onFocus: async function (model) {
      this.componentProps.disabled = false;
      if (!impactGroup.project) return;
      const res = await projectProvider.getImpacts(orgId, impactGroup.project.id);

      if (res?.payload) {
        const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
        model.setOptions(sortedImpacts);
      }
    },
    subscribeTo: ["impacts"],
    onItemSelected: async function () {
      let self: AutocompleteModel = this;
      let listingModel: ListingModel = self.channels.impacts as ListingModel;
      let val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, item => {
        const lowerName = item.name.toLowerCase();
        const lowerRef = item.refNumber.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: "col-12"
  };

  const impacts: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "impacts",
    placeholder: I18n.t("placeholders.selectImpact"),
    label: <label htmlFor={"impacts"}>{I18n.t("forms.selectedImpacts")}</label>,
    fieldClassName: "col-12",
    selector: (e: FP.Entities.IImpact) => (
      <p className="mb-0 d-inline-block">
        {e.refNumber} - {e.name}
      </p>
    ),
    value: [],
    extractValue: function () {
      return (this.value && this.value.map(e => e.id)) || [];
    }
  };

  const fields = [];

  fields.push(impactSearch);
  fields.push(impacts);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
