import * as React from 'react';
import { PanelProps, Panel } from '../Panel';
import { observer } from 'mobx-react';
import { IUiAction } from '../../../core/uiAction/IUiAction';
import { UiActionComponent } from '../../../core/uiAction/UiAction';


export interface ToasterProps {
    panelProps: PanelProps;
    actions?: IUiAction<any>[];
    animationsClassName?: string;
    onMouseEnter?: (ev: React.SyntheticEvent) => void;
}


const Toaster: React.FunctionComponent<ToasterProps> = observer((props) => {
    let panelProps: PanelProps = {
        type: "border-left-primary",
        background: "bg-white",
        hasShadow: true,
        hasBorderRadius: true
    }

    if (props.panelProps) {
        panelProps = { ...panelProps, ...props.panelProps }
    }

    return <Panel
        {...panelProps}
        className={`toaster mb-3 mr-3 p-3 ${props.animationsClassName || ""}`}
        onMouseEnter={props.onMouseEnter}
    >
        {
            props.actions &&
            <div className="toaster__actions">
                {
                    props.actions.map((e, i) => {
                        return <UiActionComponent action={e} key={i} />
                    })
                }
            </div>
        }
        <div className="toaster__content">
            {props.children}
        </div>
    </Panel>
})


export { Toaster }