import * as React from "react";
import { ImpactCompactViewModel } from "./ImpactCompactView_model";
import { observer } from "mobx-react-lite";
import { ImpactDisplay } from "../impactDisplay";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";

export interface ImpactCompactViewProps {
  model: ImpactCompactViewModel;
}

export const ImpactCompactView: React.FunctionComponent<ImpactCompactViewProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model.impact) {
    return <PositionedSpinner />;
  }

  return (
    <div className="container-fluid impact-compact-view">
      <div className="row my-4">
        <div className="col">
          <h2 className="mb-0">
            <strong className="h2-secondary">{model.impact.refNumber}</strong> - {model.impact.name}
          </h2>
        </div>
      </div>
      <ImpactDisplay item={model.impact} />
    </div>
  );
});
