export interface ICookieService {
  setCookie: (name: string, value: string, expiresInDays: number) => void;
  getCookie: (name: string) => string;
}

export class CookieService implements ICookieService {
  setCookie = (name: string, value: string, expiresInDays: number) => {
    localStorage.setItem(name, value);
  };

  getCookie = (name: string) => {
    return localStorage.getItem(name);
  };
}
