import React from "react";
import { Panel } from "../../../ui/Panel";
import { B } from "../../../../core/util/BootstrapHelper";

export type ProjectInsight = {
    key: string;
    label: string;
    value: number | React.ReactNode;
    footer?: React.ReactNode;
}

type ProjectInsightCardProps = {
    insight: ProjectInsight
}

const ProjectInsightCard = (props: ProjectInsightCardProps) => {
    const { label, value, footer } = props.insight;
    const cls = B().bl("project-insight-card");

    return (
        <Panel background="bg-white" hasBorderRadius={true} className={cls.bem}>
            <h2 className="mb-2">
                {label}
            </h2>
            <div className="display-l strong mb-2">
                {value}
            </div>
            {footer}
        </Panel>
    )
}

export { ProjectInsightCard };