
export const Locale: FlightPath.ILocaleData = {
    phrases: {
    },
    validations: {
    },
    entities: {
    },
    forms: {
    },
    placeholders: {
    },
    tableHeadings: {
    },
    tooltips: {

    },
    words: {
        overview: "Overview",
    },
    admin: {
        overviewSection: {
            title: "Flight Path Overview"
        }
    },
    change: {

    },
    ui: {
        permissions: {
            organisation: {
                scope: {
                    "300": "Admin",
                    "200": "Contributor",
                    "100": "Reader",
                    "0": "Link access"
                }
            }
        },
    },
    loading: [
        "Working, one moment..."
    ]
};