import React, { useEffect } from "react";
import { LinkButton } from "../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import { ProgrammeSettingsModel } from "./ProgrammeSettings_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";

export interface ProgrammeSettingsProps {
  model: ProgrammeSettingsModel;
}

const ProgrammeSettings: React.FunctionComponent<ProgrammeSettingsProps> = observer(({ model }) => {
  const { programme } = model;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div id="EditProgrammePage" className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h1 className="d-inline-block">
            {programme.name} | <strong>{I18n.t("phrases.manageProgramme")}</strong>
          </h1>
          <LinkButton
            id="CloseProgrammeSettingsButton"
            type="outline-primary"
            href={`/organisations/${organisationId}/programmes/${model.programme?.id}`} className="float-right">
            {I18n.t("phrases.closeSettings")}
          </LinkButton>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("phrases.manageProgrammeDescription")}</p>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-10">
          <SingleForm model={model.formModel} />
        </div>
      </div>
    </div>
  );
});

export { ProgrammeSettings };
