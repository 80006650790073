import { observer } from "mobx-react";
import * as React from "react";
import { Async } from "react-async";
import { Button } from "../../../../../components/ui/Button";
import { Icon } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { FilterPills } from "../../../../../components/widgets/filterPills/FilterPills_view";
import { Heatmap } from "../../../../../components/widgets/heatmap/Heatmap_view";
import I18n from "../../../../../core/localization/I18n";
import { ImpactsOverTimeModel } from "./ImpactsOverTime_model";

export interface ImpactsOverTimeProps {
  model: ImpactsOverTimeModel;
}

const ImpactsOverTime: React.FC<ImpactsOverTimeProps> = observer(({ model }) => {
  return (<>
    <Panel background="bg-light" className="pb-2">
      <div className="container-fluid pt-4">
        <div className="row mb-2">
          <div className="col">
            <p className="m-0 mt-2">{I18n.t("phrases.impactReportOverTimeDescription", { group: I18n.t(`entities.${model.impactReportGroup}`) })}</p>
          </div>
          <div className="col">
            <Button className={"float-right"} type="outline-primary" onClick={model.filterModel.showFilterFormModal}>
              <Icon symbol="Filter" className="mr-2" />
              {I18n.t("phrases.applyFilters")}
            </Button>
            <Button className={"float-right mr-2"} type="outline-primary" onClick={model.heatmapModel.exportAsCsv}>
              <Icon symbol="download" className="mr-2" />
              {I18n.t("phrases.export")}
            </Button>
          </div>
        </div>
        <div className="row mb-3">

        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <div className="container-fluid mt-5">
      <div className="row mb-5">
        <div className="col">
          <Async promiseFn={model.loadData}>
            <Async.Loading>
              <PositionedSpinner />
            </Async.Loading>
            <Async.Resolved>{() => <Heatmap model={model.heatmapModel} />}</Async.Resolved>
            <Async.Rejected>{err => err.message}</Async.Rejected>
          </Async>
        </div>
      </div>
    </div>
  </>
  );
});

export { ImpactsOverTime };
