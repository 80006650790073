import * as React from 'react';
import { observer } from "mobx-react-lite";
import { CreateStakeholderViewModel } from './CreateStakeholderView_model';
import I18n from '../../../../core/localization/I18n';
import { SingleForm } from '../../forms/singleFormModel/SingleForm_view';

export interface CreateStakeholderViewProps {
    model: CreateStakeholderViewModel;
}

const CreateStakeholderView: React.FunctionComponent<CreateStakeholderViewProps> = observer((props) => {
    const { model } = props;

    React.useEffect(() => {
        model.onMount();
        return model.onUnmount;
    })

    return <div className="create-Stakeholder-view container-fluid pt-6">
        <div className="row mb-4">
            <div className="col-lg-7">
                <h1>{I18n.t("phrases.createNew")} <strong>{I18n.t("entities.stakeholder")}</strong></h1>
                <p className="mb-0">{I18n.t("phrases.createStakeholderDescription")}</p>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-lg-10">
                <SingleForm model={model.formModel} />
            </div>
        </div>
    </div>;
});

export { CreateStakeholderView };