import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export class UserViewModel extends BaseModel {
  appService: AppService;
  usersProvider: IUsersApi;
  organisationId: number;
  @observable.ref user: FP.Entities.IUser = null;
  constructor(appService: AppService) {
    super();
    this.appService = appService;
    this.usersProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.organisationId = useCurrentOrganisationId();
  }

  @action
  loadUser = async (userId: number) => {
    const res = await this.usersProvider.getByIdAndOrgaisationIdAsync(this.organisationId, userId);

    if (!res || res.isError) throw new Error(res.message);

    this.setUser(res.payload);
  };

  @action
  setUser = (user: FP.Entities.IUser) => {
    this.user = user;
  };
}
