import { createBrowserHistory } from "history";
import { useCallback, useContext, useEffect, useState } from "react";
import { USER_IMAGE_PLACEHOLDER } from "./constants";
import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { Async } from "react-async";
import { Loader } from "./components/ui/PositionedSpinner";
import { AuthService } from "@okta/okta-react";
import { useClaim } from "./core/auth/authorise";

const CLIENT_ID = window.appConfig.oauth_client_id;
export const CALLBACK_PATH = "/login_complete";

const ISSUER = window.appConfig.oauth_authority; // `https://${OKTA_DOMAIN}/oauth2/default`;
const REDIRECT_URI = `${window.appConfig.baseUrl}${CALLBACK_PATH}`;
const SCOPES = "openid profile email groups";


const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scope: SCOPES.split(/\s+/),
  tokenManager: {
    storage: "sessionStorage"
  }
};

export const authService = new AuthService({
  ...config,
  isAuthenticated: async () => {
    const idToken = await authService.getTokenManager().get("idToken");
    const accessToken = await authService.getTokenManager().get("accessToken");
    return !!(idToken && accessToken);
  }
});

export async function getAppToken() {
  const idToken = await authService.getTokenManager().get("idToken");
  const accessToken = await authService.getTokenManager().get("accessToken");
  return idToken ? idToken.value : accessToken ? accessToken.value : null;
}
export const appHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const UserContext = React.createContext<any>({});

export const UserProvider: React.FC = ({ children }) => {
  const { authService } = useOktaAuth();

  const getUser = useCallback(() => {
    return authService.getUser();
  }, [authService]);
  return (
    <Async promiseFn={getUser}>
      <Async.Loading>
        <Loader />
      </Async.Loading>
      <Async.Resolved>{user => <UserContext.Provider value={user}>{children}</UserContext.Provider>}</Async.Resolved>
      <Async.Rejected>{err => err.message}</Async.Rejected>
    </Async>
  );
};

export function useUserIdentity() {
  return useContext(UserContext);
}

function flightPathUserFromUserIdentity(userIdentity: any, profileImageUrl) {
  return {
    firstName: userIdentity.given_name,
    lastName: userIdentity.family_name,
    email: userIdentity.email,
    sub: userIdentity.sub,
    id: userIdentity.flightPathUserId,
    profileImageUrl: userIdentity.picture || profileImageUrl || USER_IMAGE_PLACEHOLDER(userIdentity.given_name, userIdentity.family_name)
  } as FP.Entities.IUser;
}

export function useFlightPathUser() {
  const userIdentity = useUserIdentity();
  const profileImageClaim = useClaim("profileImageUrl");
  const [flightPathUser, setFlightPathUser] = useState(() => flightPathUserFromUserIdentity(userIdentity, profileImageClaim.value));

  useEffect(() => {
    setFlightPathUser(flightPathUserFromUserIdentity(userIdentity, profileImageClaim.value));
  }, [userIdentity, profileImageClaim.value]);

  return flightPathUser;
}
