import * as React from "react";
import { ActionTypeSettingsModel } from "./ActionTypeSettings_model";
import { ITableConfig } from "../../../../../core/table/ITableModel";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";

export const GetActionTypeTableConfig = (
  parentModel: ActionTypeSettingsModel
): ITableConfig<FP.Entities.IActionType> => {
  return {
    colHeaders: [
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: (obj: FP.Entities.IActionType) => obj.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    actions: parentModel.permissionService.canContributeOrg
      ? [
          {
            id: "edit",
            label: I18n.t("phrases.edit"),
            rendersIn: "Button",
            componentProps: {
              type: "link"
            },
            onAction: (ev, row: ITableRowModel) => {
              parentModel.showFormModal(row.rowObject);
            }
          },
          {
            id: "delete",
            label: I18n.t("phrases.delete"),
            rendersIn: "Button",
            componentProps: {
              type: "link"
            },
            onAction: (ev, row: ITableRowModel) => {
              parentModel.showConfirmDeleteModal(row.rowObject);
            }
          }
        ]
      : []
  };
};
