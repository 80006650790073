import _ from "lodash";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../constants";
import { ICookieService } from "../../../core/util/CookieService";
import { Enums } from "../../../enums";
import { IUsersApi } from "../../api/v1/users/IUsers.api";
import { IRecentProjectsService, IRecentProjectType } from "./IRecentProjectsService";

export class RecentProjectsService implements IRecentProjectsService {
  appService: AppService;
  cookieService: ICookieService;
  usersProvider: IUsersApi;
  /**
   *
   */
  constructor(appService: AppService) {
    this.appService = appService;
    this.usersProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.cookieService = this.appService.getService<ICookieService>(Services.CookieService);
  }

  addProject = async (item: IRecentProjectType) => {
    await this.usersProvider.addRecentProject(item.organisationId, item.projectId);
  };

  getRecentProjects = async (organisationId: number) => {
    let res = await this.getRecentProjects(organisationId);
    return res;
  };

  saveProjects = (recentProjects: IRecentProjectType[]) => {
    let items = recentProjects.map(e => `${e.projectId},${e.organisationId},${e.name}`);
    this.cookieService.setCookie(Enums.LocalCookies.RECENT_PROJECTS, items.join("|"), 10);
  };

  projectAlreadIn = (list: IRecentProjectType[], item: IRecentProjectType): boolean => {
    const filtered = _.filter(list, e => e.projectId === item.projectId);
    return filtered.length > 0;
  };
}
