import { BaseModel } from "../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { IUiAction } from "../../../core/uiAction/IUiAction";
import moment from "moment";

export interface ActivityCardConfig {
  actions?: IUiAction<ActivityCardModel>[];
}

export class ActivityCardModel extends BaseModel {
  @observable inputValue: string = "";
  @observable isEditMode: boolean = false;
  @observable config: ActivityCardConfig = {
    actions: []
  };
  @observable.ref comment: FP.Entities.IComment;
  onSave: ((comment: FP.Entities.IComment) => void) | any;

  constructor(comment: FP.Entities.IComment, initOpts?: ActivityCardConfig) {
    super();
    this.comment = comment;
    this.inputValue = comment.content;
    if (initOpts) {
      this.setConfig(initOpts);
    }
  }

  @action
  setConfig = (config: Partial<ActivityCardConfig>) => {
    this.config = { ...this.config, ...config };
  };

  @action
  enterEditMode = () => {
    this.isEditMode = true;
  };

  @action
  exitEditMode = () => {
    this.isEditMode = false;
    this.inputValue = this.comment.content;
  };

  @action
  handleInputChange = (value: string) => {
    this.inputValue = value;
  };

  handleOnSave = (comment: FP.Entities.IComment) => {
    const updatedComment: FP.Entities.IComment = {
      ...comment,
      content: this.inputValue,
      updatedAt: moment().toString()
    };

    if (this.onSave && typeof this.onSave === "function") {
      this.onSave(updatedComment);
    }
  };
}
