import * as React from "react";
import { IProjectInfoCardModel } from "./ProjectInfoCard_model";
import { Panel } from "../../../ui/Panel";
import { Pill } from "../../../ui/Pill";
import { observer } from "mobx-react-lite";
import { B } from "../../../../core/util/BootstrapHelper";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { Tooltip } from "react-tippy";

export interface ProjectInfoCardProps {
  model: IProjectInfoCardModel;
}

type IProjectMetric = {
  key: string;
  label: string;
  value: string;
  tooltip?: React.ReactElement<any>;
};

const ProjectInfoCardView: React.FunctionComponent<ProjectInfoCardProps> = observer(({ model }: any) => {
  const { project } = model;
  const projectInsights: FP.Entities.IProjectInsight | any = project;
  const cls = B().bl("project-info-card");

  const projectMetrics: IProjectMetric[] = [
    {
      key: "stakeholders",
      label: I18n.t("phrases.stakeholders"),
      value:
        projectInsights.stakeholderAudienceCount + projectInsights.stakeholderIndividualCount === 0
          ? "-"
          : `${projectInsights.stakeholderAudienceCount + projectInsights.stakeholderIndividualCount}`,
      tooltip: (
        <div>
          {projectInsights.stakeholderAudienceCount > 0 && (
            <p>
              {projectInsights.stakeholderAudienceCount} - {I18n.t("phrases.audience")}
            </p>
          )}
          {projectInsights.stakeholderIndividualCount > 0 && (
            <p className="mb-0">
              {projectInsights.stakeholderIndividualCount} - {I18n.t("phrases.individual")}
            </p>
          )}
        </div>
      )

      // projectInsights.stakeholderAudienceCount + projectInsights.stakeholderIndividualCount > 0 ? <div>Yooo</div> : null
    },
    {
      key: "impacts",
      label: I18n.t("phrases.completedImpacts"),
      value:
        projectInsights.impactsTotal === 0 ? "-" : `${projectInsights.impactsMitigated}/${projectInsights.impactsTotal}`
    },
    {
      key: "actions",
      label: I18n.t("phrases.completedActions"),
      value:
        projectInsights.actionsTotal === 0 ? "-" : `${projectInsights.actionsClosed}/${projectInsights.actionsTotal}`
    }
  ];

  return (
    <Panel type="border-left-primary" background="bg-white" hasShadow={true} className={`${cls.bem}`}>
      <div className="d-flex">
        <div className="mr-4">
          <h1 className={`${cls.el("title").bem} text-truncate`}>{project.name}</h1>
          <Pill type={`primary-light-${projectInsights.status}` as any} className="text-nowrap">
            {Enums.Translator.ProgressStatus(projectInsights.status)}
          </Pill>
        </div>
        <div className="d-flex flex-wrap ml-auto">
          {projectMetrics.map(metric => {
            return (
              <Tooltip theme="light" html={metric.tooltip} key={metric.key}>
                <div key={metric.key} className={cls.el("metric").bem}>
                  <small className={`${cls.el("metric-heading").bem} d-block text-truncate mb-2`}>{metric.label}</small>
                  <div className="display-s strong">{metric.value}</div>
                </div>
              </Tooltip>
            );
          })}
        </div>
      </div>
    </Panel>
  );
});

export { ProjectInfoCardView };
