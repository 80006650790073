import * as React from 'react';
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { INIT_AUTOCOMPLETE } from '../../../../core/forms/controls/autocomplete/Autocomplete_init';
import { IListingModel } from '../../../../core/forms/controls/listing/IListingModel';
import { INIT_LISTING_FIELD } from '../../../../core/forms/controls/listing/Listing_model';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import { IProjectsApi } from '../../../../services/api/v1/projects/IProject.api';
import I18n from '../../../../core/localization/I18n';
import _ from 'lodash';
import { IProjectStakeholdersApi } from '../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api';


export const getProjectStakeholderImpactsFormFields = (
    projectProvider: IProjectsApi,
    projectStakeholderProvider: IProjectStakeholdersApi,
    projectStakeholder: FP.Entities.IProjectStakeholder,
    orgId: number,
    projectId: number
) => {
    const impactSearch: Partial<IAutocompleteModel> = {
        ...INIT_AUTOCOMPLETE,
        key: "impactSearch",
        label: (<label htmlFor={"impactSearch"}>{I18n.t("entities.impacts")}</label>),
        placeholder: I18n.t("placeholders.searchImpact"),
        optionElement: (<AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
        />),
        componentProps: {
            className: "form-control",
            icon: "search",
        },
        onFocus: async function () {
            let self: IAutocompleteModel = this;
            this.componentProps.disabled = false;
            const res = await projectStakeholderProvider.getUnassignedImpacts(orgId,projectId,projectStakeholder.id);

            if (res?.payload) {
                const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
                self.setOptions(sortedImpacts);
            }
        },
        shouldClearOnBlur: true,
        filterFn: (items: FP.Entities.IImpact[], query) => {
            const lowerQuery = query.toLowerCase();
            return _.filter(items, item => {
                const lowerName = item.name.toLowerCase();
                const lowerRef = item.refNumber ? item.refNumber.toLowerCase() : "";
                return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
            })
        },
        fieldClassName: "col-12",
        valueLabelFn: (e) => e.name
    }

    const listing: Partial<IListingModel> = {
        ...INIT_LISTING_FIELD,
        key: "impacts",
        placeholder: I18n.t("placeholders.selectImpact"),
        label: (<label htmlFor={"impacts"}>{I18n.t("forms.selectedImpacts")}</label>),
        fieldClassName: "col-12",
        subscribeTo: ["impactSearch"],
        onChannelFieldChanged: function (field) {
            let val = field.value;
            if (val) {
                this.addItem(val);
                field.reset();
            }
        },
        extractValue: function () {
            return this.value?.map((e) => e.id)
        },
        selector: (e: FP.Entities.IImpact) => <p className="mb-0 d-inline-block">{e.refNumber} - {e.name}</p>,
    }

    const fields = [];

    fields.push(impactSearch);
    fields.push(listing);
    const models = generateFormFieldsFromJson(fields);
    return models;
}