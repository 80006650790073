import * as React from "react";
import { ListingModel } from "../../../../core/forms/controls/listing/Listing_model";
import { ButtonIcon } from "../../Button";
import { Enums } from "../../../../enums";
import { observer } from "mobx-react-lite";
import { Animations } from "../../../../core/util/Animations";

export interface ListingProps {
  model: ListingModel;
}

export const ListingView: React.FC<ListingProps> = observer(({ model }) => {
  return (
    <ul className={`listing-view ${model.value.length === 0 ? "listing-view--disabled" : ""}`}>
      {model.value.length > 0 ? (
        model.value.map((e, i) => {
          const val =
            typeof model.selector == "function" ? (
              model.selector(e)
            ) : (
                <p className="mb-0 d-inline-block">{e[model.selector]}</p>
              );

          return (
            <li key={i} className={`listing-view__item ${Animations.FP_ZOOM_IN} speed-4`}>
              {val}

              <ButtonIcon
                symbol="Close"
                type="link"
                size={Enums.UiSizes.SM}
                className="ml-auto"
                onClick={() => {
                  model.deleteItemByIdx(i);
                }}
              />
            </li>
          );
        })
      ) : (
          <p className="mb-0">{model.placeholder}</p>
        )}
    </ul>
  );
});
