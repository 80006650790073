import * as React from "react";
import { AppService } from "strikejs-app-service";
import { observable, reaction, action } from "mobx";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { IFilteredOptions, INIT_FILTER_OPTIONS } from "../../../../services/api/filteredApi/FilteredApiModel";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { ActionImpactsModel } from "../actionImpacts/ActionImpacts_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IAuthUserContextModel } from "../../../../services/local/authUserContext/AuthUserContextModel";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";

interface IActionExtendedViewModelOptions {
  appService: AppService;
  projectId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
  actionId: number;
}

export class ActionExtendedViewModel extends DisposableModel {
  appService: AppService;
  projectId: number;
  actionId: number;
  projectProvider: IProjectsApi;
  actionProvider: IActionsApi;
  actionImpactsModel: ActionImpactsModel;
  organisationProvider: IOrganisationsApi;
  orgId: number;
  authUserContext: IAuthUserContextModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  httpProgress: IHttpProgressModel;
  @observable filterOptions: IFilteredOptions = { ...INIT_FILTER_OPTIONS };
  @observable.ref action: FP.Entities.IAction;

  /**
   *
   */
  constructor({ appService, projectId, actionId, organisationId, authUser }: IActionExtendedViewModelOptions) {
    super();
    this.appService = appService;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.orgId = organisationId;
    this.projectId = projectId;
    this.actionId = actionId;
    this.actionImpactsModel = new ActionImpactsModel(this.appService, projectId, this.orgId, false);
    this.commentViewModel = new CommentListViewModel(appService, projectId, authUser, {
      searchAttribute: "actionId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId: this.orgId
    });

    this.revisionHistoryModel = new RevisionHistoryModel(appService, {
      entityId: this.actionId,
      projectId: this.projectId,
      historyType: "actions",
      organisationId: this.orgId
    });

    this.installReactions();
  }

  onMount = () => { };

  onUnmount = () => {
    this.dispose();
  };

  @action
  loadAction = async () => {
    let res = await this.actionProvider.getDetailedByIdAsync(this.orgId, this.projectId, this.actionId);

    if (!res || res.isError) return;

    this.action = res.payload;

    this.commentViewModel.setConfig({
      id: this.action.id,
      description: <p className="mb-0">{I18n.t("phrases.actionCommentsDescription")}</p>
    });
  };

  installReactions = () => {
    var d = reaction(
      () => {
        return this.action;
      },
      (e, reaction) => {
        this.actionImpactsModel.setAction(e);
      }
    );

    this.addDisposer(d);
  };
}
