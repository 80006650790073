import { AppService } from "strikejs-app-service";
import { Services } from "../../../constants";
import { SettingType } from "../../../enums";
import { IFlightPathApiResponse } from "../../api/BaseApiModel";
import { IOrganisationSettingsApi } from "../../api/v1/organisationSettings/IOrganisationSettings.api";
import { IOrganisationSettingsService } from "./IOrganisationSettingsService";

export class OrganisationSettingsService implements IOrganisationSettingsService {
  appService: AppService;
  organisationSettingsApi: IOrganisationSettingsApi;

  constructor(appService: AppService) {
    this.appService = appService;
    this.organisationSettingsApi = this.appService.getService<IOrganisationSettingsApi>(
      Services.OrganisationSettingsApi
    );
  }

  isCustomPropertiesEnabled = async (organisationId: number) => {
    let res = await this.getOrganisationSetting(organisationId, SettingType.CUSTOM_PROPERTIES_ENABLED);

    return this.returnBooleanValueOrDefault(res);
  };

  isVisualisationsEnabled = async (organisationId: number) => {
    let res = await this.getOrganisationSetting(organisationId, SettingType.VISUALISATIONS_ENABLED);

    return this.returnBooleanValueOrDefault(res);
  };

  getOrganisationSetting = async (organisationId: number, settingType: SettingType) => {
    return await this.organisationSettingsApi.getOrganisationSetting(organisationId, settingType);
  };

  returnBooleanValueOrDefault = (response: IFlightPathApiResponse<any>):boolean =>{
    if (response.code === 200) {
      return Boolean(response.payload.value);
    }

    return false;
  }
}
