import * as React from 'react';
import { UiSizes } from '../../../enums';
import { FullScreenWrapper } from '../FullScreenWrapper';
import { Spinner, SpinnerProps } from '../Spinner';

export interface PositionedSpinnerProps {
    position?: "top" | "right" | "bottom" | "left" | "middle";
    background?: "primary" | "secondary" | "tertiary" | "transparent" | "white"
    className?: string;
    spinnerProps?: SpinnerProps;
    style?: any;
}


const PositionedSpinner: React.FunctionComponent<PositionedSpinnerProps> = (props) => {
    return <div className={`positioned-spinner position-${props.position || "middle"} positioned-spinner--${props.background || "transparent"} ${props.className}`} style={props.style}>
        <Spinner {...props.spinnerProps} />
    </div>
};


export function Loader() {
    return (
        <FullScreenWrapper>
            <PositionedSpinner position="middle" spinnerProps={{ size: UiSizes.LG }} />
        </FullScreenWrapper>
    );
}

PositionedSpinner.defaultProps = {
    className: ""
}

export { PositionedSpinner };