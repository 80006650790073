
import * as React from 'react';

export interface FileUploadWrapperProps {
    componentProps: React.InputHTMLAttributes<HTMLInputElement>
}

const FileUploadWrapper: React.FunctionComponent<FileUploadWrapperProps> = (props) => {
    const id = props.componentProps.id || "fileUpload";

    return (
        <label htmlFor={id} className="file-upload-wrapper">
            <div className="file-upload-wrapper__child">
                {props.children}
            </div>
            <input {...props.componentProps}
                type="file"
                name={id}
                id={id}
                className="file-upload-wrapper__input"
            />
        </label>
    )
};

export { FileUploadWrapper };