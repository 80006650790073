import { IModel, BaseModel } from "../../../core/util/BaseModel";
import { observable, action } from "mobx";

export interface IInnerNavContentItem extends FP.Generic.IKeyLabel {
  content: React.ReactNode;
}

export interface IInnerNavContentConfig {
  items?: Dictionary<IInnerNavContentItem>;
  navigationTitle?: React.ReactNode;
  displayNavWithLinks?: boolean;
  linkRender?: (obj: IInnerNavContentItem) => React.ReactNode;
  blockClassName?: string;
}

export interface IInnerNavContentModel extends IModel {
  config: IInnerNavContentConfig | null;
  setCurrentViewKey: (val: string) => void;
  currentViewKey: string;
  setConfig: (config: Partial<IInnerNavContentConfig>) => void;
  blockClassName?: string;
}

export class InnerNavContentModel extends BaseModel implements IInnerNavContentModel {
  @observable.ref config: IInnerNavContentConfig | null = null;
  @observable currentViewKey: string = "";
  blockClassName: string = "";

  constructor(config?: IInnerNavContentConfig) {
    super();
    if (config) {
      this.setConfig(config);
      this.blockClassName = config.blockClassName;
    }
  }

  @action
  setCurrentViewKey = (val: string) => {
    this.currentViewKey = val;
  };

  setConfig = (config: Partial<IInnerNavContentConfig>) => {
    this.config = { ...this.config, ...config };
    const cfg: any = this.config;
    if (this.currentViewKey === "") {
      this.currentViewKey = cfg.items[Object.keys(cfg.items)[0]].key;
    }
  };
}
