import moment from "moment";
import React from "react";
import { Pill } from "../../../../../components/ui/Pill";
import { ImpactActionsTimelineModel } from "../../../../../components/widgets/impactActionsTimeline/ImpactActionsTimeline_model";
import { ImpactActionsTimeline } from "../../../../../components/widgets/impactActionsTimeline/ImpactActionsTimeline_view";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { ITableModel, ITableConfig } from "../../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { Enums } from "../../../../../enums";
import { ImpactDetailedReportModel } from "./ImpactDetailedReport_model";

export const GetImpactTableConfig = (
  parentModel: ImpactDetailedReportModel,
  tableModel: ITableModel<any>,
  projectId: number
) => {
  let actions = [];
  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: "HTMLAnchor",
    componentProps: {
      type: "link"
    },
    hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/impacts/${context.rowObject.id}`
  });
  if (parentModel.permissionService.canEditProject(parentModel.projectId)) {
    actions.push(
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        rendersIn: "HTMLAnchor",
        componentProps: {
          type: "link"
        },
        hrefFn: context => `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/impacts/${context.rowObject.id}/edit`
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: (ev, row: ITableRowModel) => {
          parentModel.showImpactConfirmDeleteModal(parentModel.projectId, row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    );
  }

  let tableConfig: ITableConfig<FP.Entities.IImpact> = {
    actions,
    colHeaders: [
      {
        key: "refNumber",
        content: <h4 className="mb-0">{I18n.t("table.refNo")}</h4>,
        selector: obj => {
          return <p className="mb-0 strong">{obj.refNumber}</p>;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "name",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "impactType",
        content: <h4 className="mb-0">{I18n.t("table.type")}</h4>,
        selector: obj => {
          return Enums.Translator.ImpactType(obj.impactType);
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "startDate",
        content: <h4 className="mb-0">{I18n.t("table.startDate")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true,
        cellClassName: "d-none d-xxl-table-cell",
        cellContentFunction: (row: ITableRowModel) => {
          return moment(row["startDate"]).format("L");
        }
      },
      {
        key: "actualEndDate",
        content: <h4 className="mb-0">{I18n.t("table.endDate")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true,
        cellClassName: "d-none d-xxl-table-cell",
        cellContentFunction: (row: ITableRowModel) => {
          return moment(row["actualEndDate"]).format("L");
        }
      },
      {
        key: "totalPeopleImpacted",
        content: <h4 className="mb-0">{I18n.t("table.numOfIndividualsImpacted")}</h4>,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "impactLevel",
        content: <h4 className="mb-0">{I18n.t("table.level")}</h4>,
        selector: obj => {
          return (
            <Pill
              style={{ width: "90px" }}
              type={`indicator-accent-${Enums.Translator.MetricToEnum(obj.impactLevel)}` as any}
            >
              {Enums.Translator.ImpactLevel(obj.impactLevel)}
            </Pill>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        key: "progressStatus",
        content: <h4 className="mb-0">{I18n.t("table.status")}</h4>,
        selector: obj => Enums.Translator.ImpactProgressStatus(obj.progressStatus),
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, tableModel, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    initData: null,
    renderExpandContent: (row: ITableRowModel) => {
      return (
        <ImpactActionsTimeline
          model={
            new ImpactActionsTimelineModel(
              parentModel.appService,
              row.rowObject.id,
              projectId
            )
          }
        />
      );
    },
    onRowClick: parentModel.showImpactModal,
    tableProps: {}
  };
  return tableConfig;
};
