import * as React from 'react';
import I18n from '../../core/localization/I18n';
import { StakeholderType } from '../../enums';
import { AvatarContent } from './AvatarContent';

const AutocompletePerson: React.FunctionComponent<FP.Entities.IBasePerson> = props => {
    let s: FP.Entities.IStakeholder = props as any;
    return (
        <AvatarContent
            type="primary"
            avatarProps={{
                imgSrc: props.profileImageUrl as string
            }}
        >
            {s.stakeholderType === StakeholderType.AUDIENCE ? (
                <>
                    <h3 className="mb-0">{props.firstName}</h3>
                    <h5 className="mb-0">{I18n.t("phrases.audience")}</h5>
                </>
            ) : (
                    <>
                        <h3 className="mb-0">
                            {props.firstName} {props.lastName}
                        </h3>
                        <h5 className="mb-0">{props.email}</h5>
                    </>
                )}
        </AvatarContent>
    );
};
export { AutocompletePerson }