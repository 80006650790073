import FroalaEditorFunctionality from "./FroalaEditorFunctionality";
import React from "react";

export default class FroalaEditor extends FroalaEditorFunctionality {
  tag: any;
  el: any;
  render() {
    return <this.tag ref={(el: any) => (this.el = el)} id={this.props.id}>{this.props.children}</this.tag>;
  }
}
