import React from "react";
import { Input } from "../../ui/_forms/Input";
import {
  GlobalSearchModel,
  ISearchFacet,
  ISearchFacetOption,
  ISearchResultItem,
  ResultType
} from "./GlobalSearch_model";
import { observer } from "mobx-react";
import { AvatarContent } from "../../ui/AvatarContent";
import { UiSizes } from "../../../enums";
import I18n from "../../../core/localization/I18n";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { Panel } from "../../ui/Panel";
import { Checkbox } from "../../ui/Checkbox";
import { ButtonIcon, Button } from "../../ui/Button";
import { RLink } from "../../ui/Rlink";
import { Async } from "react-async";
import { USER_IMAGE_PLACEHOLDER } from "../../../constants";

const MAX_RESULTS_TO_SHOW = 6;

export interface GlobalSearchViewProps {
  model: GlobalSearchModel;
}

const GlobalSearchView: React.FunctionComponent<GlobalSearchViewProps> = observer(({ model }) => {
  return (
    <div className="global-search">
      <ButtonIcon
        symbol="Close"
        data-testid="global-search-close"
        className="global-search__close"
        type="outline-primary"
        onClick={model.closeModal}
      />
      <div className="global-search__col--search p-5">
        <h1 className="mt-2" data-testid="global-search-title">
          {I18n.t("phrases.search")}
        </h1>
        <div className="mb-5">
          <Input
            autoFocus={true}
            value={model.searchInputValue}
            onChange={model.handleSearchInputChange}
            borderStyle="underline"
            placeholder={I18n.t("placeholders.globalSearch")}
            icon="search"
          />
          {model.searchInputValue ? (
            <Button type="link" className="px-0" onClick={model.resetSearch}>
              {I18n.t("phrases.clearSearch")}
            </Button>
          ) : null}
        </div>
        <Async promiseFn={model.searchInputValue ? model.handleSearch : undefined} key={model.searchCountKey}>
          <Async.Loading>
            <PositionedSpinner position="top" className="h-auto" />
          </Async.Loading>
          <Async.Resolved>
            {() => (
              <>
                {model.organisations?.length ? (
                  <div className="mb-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("entities.organisations")}
                      <small className="ml-1">
                        (
                        {I18n.t("phrases.showingNumOfTotalResults", {
                          num: Math.min(MAX_RESULTS_TO_SHOW, model.organisations.length),
                          total: model.organisations.length
                        })}
                        )
                      </small>
                    </h5>
                    {model.organisations.slice(0, MAX_RESULTS_TO_SHOW).map(org => (
                      <span className="d-block cursor-pointer" onClick={model.closeModal}>
                        <RLink key={org.id} to={`/organisations/${org.id}`}>
                          <h4>{org.name}</h4>
                        </RLink>
                      </span>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.programmes?.length ? (
                  <div className="mb-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("entities.programmes")}
                      <small className="ml-1">
                        (
                        {I18n.t("phrases.showingNumOfTotalResults", {
                          num: Math.min(MAX_RESULTS_TO_SHOW, model.programmes.length),
                          total: model.programmes.length
                        })}
                        )
                      </small>
                    </h5>
                    {model.programmes.slice(0, MAX_RESULTS_TO_SHOW).map(programme => (
                      <span className="d-block cursor-pointer" onClick={model.closeModal}>
                        <RLink
                          key={programme.id}
                          to={`/organisations/${programme.organisationId}/programmes/${programme.id}`}
                        >
                          <h4>{programme.name}</h4>
                        </RLink>
                      </span>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.projects?.length ? (
                  <div className="mb-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("entities.projects")}
                      <small className="ml-1">
                        (
                        {I18n.t("phrases.showingNumOfTotalResults", {
                          num: Math.min(MAX_RESULTS_TO_SHOW, model.projects.length),
                          total: model.projects.length
                        })}
                        )
                      </small>
                    </h5>
                    {model.projects.slice(0, MAX_RESULTS_TO_SHOW).map(project => (
                      <span className="d-block cursor-pointer" onClick={model.closeModal}>
                        <RLink key={project.id} to={`/organisations/${project.organisationId}/projects/${project.id}`}>
                          <h4>{project.name}</h4>
                        </RLink>
                      </span>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.impacts?.length ? (
                  <div className="mb-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("entities.impacts")}
                      <small className="ml-1">
                        (
                        {I18n.t("phrases.showingNumOfTotalResults", {
                          num: Math.min(MAX_RESULTS_TO_SHOW, model.impacts.length),
                          total: model.impacts.length
                        })}
                        )
                      </small>
                    </h5>
                    {model.impacts.slice(0, MAX_RESULTS_TO_SHOW).map(impact => (
                      <span className="d-block cursor-pointer" onClick={model.closeModal}>
                        <RLink
                          key={impact.id}
                          to={`/organisations/${impact.organisationId}/projects/${impact.parentId}/impacts/${impact.id}`}
                        >
                          <h4>{impact.name}</h4>
                        </RLink>
                      </span>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.actions?.length ? (
                  <div className="mb-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("entities.actions")}
                      <small className="ml-1">
                        (
                        {I18n.t("phrases.showingNumOfTotalResults", {
                          num: Math.min(MAX_RESULTS_TO_SHOW, model.actions.length),
                          total: model.actions.length
                        })}
                        )
                      </small>
                    </h5>
                    {model.actions.slice(0, MAX_RESULTS_TO_SHOW).map(action => (
                      <span className="d-block cursor-pointer" onClick={model.closeModal}>
                        <RLink
                          key={action.id}
                          to={`/organisations/${action.organisationId}/projects/${action.parentId}/actions/${action.id}`}
                        >
                          <h4>{action.name}</h4>
                        </RLink>
                      </span>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.stakeholders.length ? (
                  <div className="mb-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("entities.stakeholders")}
                      <small className="ml-1">
                        (
                        {I18n.t("phrases.showingNumOfTotalResults", {
                          num: Math.min(MAX_RESULTS_TO_SHOW, model.stakeholders.length),
                          total: model.stakeholders.length
                        })}
                        )
                      </small>
                    </h5>
                    <div className="row">
                      {model.stakeholders.slice(0, MAX_RESULTS_TO_SHOW).map(stakeholder => (
                        <div className=" col-lg-6 cursor-pointer" onClick={model.closeModal}>
                          <RLink
                            key={stakeholder.id}
                            to={`/organisations/${stakeholder.organisationId}/stakeholders/${stakeholder.id}`}
                          >
                            <span onClick={model.closeModal}>
                              <AvatarContent
                                className="mb-3"
                                avatarProps={{
                                  imgSrc: getSearchResultImage(stakeholder),
                                  size: UiSizes.LG
                                }}
                              >
                                <h4 className="mb-0">{stakeholder.name}</h4>
                              </AvatarContent>
                            </span>
                          </RLink>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </Async.Resolved>
        </Async>
      </div>

      <Panel className="global-search__col global-search__col--filters p-5" background="bg-light">
        <Async promiseFn={model.loadFilters}>
          <Async.Loading>
            <PositionedSpinner />
          </Async.Loading>
          <Async.Resolved<ISearchFacet[]>>
            {result => (
              <>
                {model.organisationsFilter ? (
                  <div className="my-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("phrases.filterByEntity", { entity: I18n.t("entities.organisation") })}
                    </h5>
                    {model.organisationsFilter.options.map((org: ISearchFacetOption) => (
                      <div key={org.id} className="d-flex align-items-center mb-3">
                        <Checkbox
                          id={`organisation-${org.id}`}
                          onChange={ev => {
                            model.toggleFilterValue({
                              id: org.id,
                              label: org.label,
                              resultType: ResultType.organisation
                            });
                          }}
                        />
                        <label htmlFor={`organisation-${org.id}`}>
                          <AvatarContent
                            avatarProps={{
                              imgSrc: org.imageUrl,
                              size: UiSizes.SM
                            }}
                          >
                            <h4 className="mb-0">{org.label}</h4>
                          </AvatarContent>
                        </label>
                      </div>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.programmesFilter ? (
                  <div className="my-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("phrases.filterByEntity", { entity: I18n.t("entities.programme") })}
                    </h5>
                    {model.programmesFilter.options.map((programme: ISearchFacetOption) => (
                      <div key={programme.id}>
                        <Checkbox
                          id={`programme-${programme.id}`}
                          onChange={ev => {
                            model.toggleFilterValue({
                              id: programme.id,
                              label: programme.label,
                              resultType: ResultType.programme
                            });
                          }}
                        />
                        <label htmlFor={`programme-${programme.id}`}>
                          <h4 className="d-inline-block">{programme.label}</h4>
                        </label>
                      </div>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
                {model.projectsFilter ? (
                  <div className="my-5">
                    <h5 className="h5-secondary strong mb-4">
                      {I18n.t("phrases.filterByEntity", { entity: I18n.t("entities.project") })}
                    </h5>
                    {model.projectsFilter.options.map((project: ISearchFacetOption) => (
                      <div key={project.id}>
                        <Checkbox
                          id={`project-${project.id}`}
                          onChange={ev => {
                            model.toggleFilterValue({
                              id: project.id,
                              label: project.label,
                              resultType: ResultType.project
                            });
                          }}
                        />
                        <label htmlFor={`project-${project.id}`}>
                          <h4 className="d-inline-block">{project.label}</h4>
                        </label>
                      </div>
                    ))}
                    <hr className="mt-5" />
                  </div>
                ) : null}
              </>
            )}
          </Async.Resolved>
        </Async>
      </Panel>
    </div>
  );
});

export { GlobalSearchView };

const getSearchResultImage = (img: ISearchResultItem) => {
  if (img.imageUrl) {
    return img.imageUrl;
  }

  const [firstName, lastName] = img.name.split(/\s+/);

  return USER_IMAGE_PLACEHOLDER(firstName, lastName);
};
