import * as React from "react";
import { IF } from "../../hoc/If";
import { Input } from "../_forms/Input";
import { ButtonIcon } from "../Button";
import { UiSizes } from "../../../enums";

export interface SearchButtonProps {
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  id?: string;
  className?: string;
  onClose?: () => void;
  placeholder?: string;
  buttonType?:
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "outline-primary"
  | "outline-secondary"
  | "outline-success"
  | "outline-danger"
  | "outline-warning"
  | "outline-info"
  | "outline-light"
  | "outline-dark"
  | "link"
  | "link-big";
}

const SearchButton: React.FC<SearchButtonProps> = (props: any) => {
  const [isSearchMode, setIsSearchMode] = React.useState(false);

  return (
    <div className={`search-button ${props.className || ""}`}>
      <IF condition={isSearchMode}>
        <Input
          id={`${props.id}Input`}
          borderStyle="underline"
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          autoFocus={true}
          onBlur={() => {
            if (!props.value) {
              setIsSearchMode(false);
              props.onClose();
            }
          }}
        />
        <ButtonIcon
          symbol="Close"
          type="link"
          id={`${props.id}CloseButton`}
          iconSize={UiSizes.SM}
          className="search-button__button--close"
          onClick={() => {
            setIsSearchMode(false);
            props.onClose();
          }}
        />
      </IF>
      <IF condition={!isSearchMode}>
        <ButtonIcon
          id={`${props.id}SearchButton`}
          symbol="search"
          iconSize={UiSizes.SM}
          type={props.buttonType || "outline-primary"}
          onClick={() => {
            setIsSearchMode(true);
          }}
        />
      </IF>
    </div>
  );
};

export { SearchButton };
