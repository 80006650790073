import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Icon } from "../../../../components/ui/Icon";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { observer } from "mobx-react-lite";

export interface ImpactCardProps {
  item: FP.Entities.IImpact;
  actions?: IUiAction<FP.Entities.IImpact>[];
}

const ImpactCard: React.FunctionComponent<ImpactCardProps> = observer(({ item, actions }) => {
  return (
    <Panel className="impact-card p-4 mb-2" background="bg-white" type="border-left-primary">
      <div className="row">
        <div className="col">
          <div className="d-flex">
            <div className="strong mr-3">{item.refNumber}</div>
            <div className="strong mr-3">{item.name}</div>
            {actions && (
              <Tooltip
                position="left"
                className="float-right"
                shownElement={<Icon symbol="DotsHorizontal" />}
                triggeredOn="click"
              >
                <Panel background="bg-white" hasShadow={true}>
                  {actions.map(e => {
                    return <UiActionComponent key={e.id} action={e} contextModel={item} />;
                  })}
                </Panel>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
});

export { ImpactCard };
