import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { Services } from "../../../../constants";
import { GetStakeholderBulkRoleFields } from "../../forms/stakeholder/BulkRoleUpdate_fields";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ICookieService } from "../../../../core/util/CookieService";
import { Enums } from "../../../../enums";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";

export class BulkRoleUpdateModel extends BaseModel {
  appService: AppService;
  role: FP.Entities.IRole;
  stakeholders: FP.Entities.IStakeholder[];
  stakeholderProvider: IStakeholdersApi;
  businessAreaProvider: IBusinessAreasApi;
  organisationProvider: IOrganisationsApi;
  orgId: number;
  formModel: SingleFormModel;

  /**
   *
   */
  constructor(appService: AppService, role: FP.Entities.IRole, stakeholders: FP.Entities.IStakeholder[]) {
    super();
    this.appService = appService;
    this.role = role;
    this.stakeholders = stakeholders;

    this.stakeholderProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    let cookieService = this.appService.getService<ICookieService>(Services.CookieService);
    this.orgId = parseInt(cookieService.getCookie(Enums.LocalCookies.ORGANISATION_ID));

    this.formModel = new SingleFormModel();
    this.formModel.formFields = GetStakeholderBulkRoleFields(
      this.stakeholders,
      this.businessAreaProvider,
      this.organisationProvider,
      this.orgId,
      role
    );
  }

  updateStakeholders = async (orgId: number) : Promise<boolean> => {
    let results: Dictionary<any> = await this.formModel.submit();
    if (!results) return;
    let keys = Object.keys(results);
    let updatedStakeholders = [];

    keys.forEach(e => {
      if (e.split("-")[0] !== "label") {
        const id = e.split("-")[1];
        let stakeholder = updatedStakeholders.find(e => e["stakeholderId"] === id);
        if (stakeholder) {
          stakeholder[e.split("-")[0]] = results[e] || 0;
        } else {
          let newStakeholder = { stakeholderId: +id };
          newStakeholder[e.split("-")[0]] = results[e] || 0;
          updatedStakeholders.push(newStakeholder);
        }
      }
    });

    var updateResult = await this.stakeholderProvider.bulkUpload(orgId, updatedStakeholders);

    if(!updateResult.isError && updateResult.payload){
      return updateResult.payload;
    }
    return false;
  };
}
