import * as React from "react";
import { BaseModel } from "../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../constants";
import I18n from "../../../core/localization/I18n";
import { IToasterService } from "../../../core/toaster/ToasterService";
import { IProjectsApi } from "../../../services/api/v1/projects/IProject.api";
import { TOASTER_TOAST_TIME } from "../../../core/toaster/Toaster_model";

export interface IRevisionHistoryModelConfig {
  projectId: number;
  entityId: number;
  organisationId: number;
  historyType: string;
}

export class RevisionHistoryModel extends BaseModel {
  appService: AppService;
  projectProvider: IProjectsApi;
  toastService: IToasterService;

  @observable config: IRevisionHistoryModelConfig = null;
  @observable isLoading: boolean = true;
  @observable errorMessage: string = "";
  @observable revisions: FP.Entities.IRevisionHistory[] = [];

  constructor(appService: AppService, initOpts: IRevisionHistoryModelConfig) {
    super();

    this.setConfig(initOpts);
    this.appService = appService;

    this.toastService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
  }

  @action.bound
  onMount() {
    this.getRevisions();
  }

  @action
  setConfig = (config: Partial<IRevisionHistoryModelConfig>) => {
    this.config = { ...this.config, ...config };
  };

  @action.bound
  async getRevisions() {
    this.isLoading = true;

    if (!this.config.entityId) {
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.SLOW)
        .setContent(<p>No Stakeholder ID was specified</p>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return;
    }

    try {
      const res = await this.projectProvider.getHistory(
        this.config.organisationId,
        this.config.projectId,
        this.config.entityId,
        this.config.historyType
      );

      if (!res || res.isError) {
        this.errorMessage = I18n.t("errors.loadRevisionHistory");
        return;
      }

      this.setRevisions(res.payload);
    } catch (error) {
      this.errorMessage = I18n.t("errors.loadRevisionHistory");
    } finally {
      this.isLoading = false;
    }
  }

  @action
  setRevisions = (revisions: FP.Entities.IRevisionHistory[]) => {
    this.revisions = revisions;
  };
}
