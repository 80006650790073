import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../../constants";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { ImpactGroupFormSectionModel } from "../../forms/impactGroups/ImpactGroupFormSection_model";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { appHistory } from "../../../../setup";

export class AddImpactGroupModel extends BaseModel {
  appService: AppService;
  impactGroupFormModel: ImpactGroupFormSectionModel;
  projectProvider: IProjectsApi;
  impactGroupProvider: IImpactGroupsApi;
  httpProgress: IHttpProgressModel;
  projectId: number;
  @observable.ref impactGroup: Partial<FP.Entities.IImpactGroup>;
  organisationId: number;
  /**
   *
   */
  constructor(appService: AppService, projectId: number, organisationId: number) {
    super();
    this.appService = appService;
    this.projectId = projectId;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactGroupProvider = this.appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);

    this.impactGroup = {
      project: {
        id: this.projectId,
        name: "",
        slug: ""
      }
    };

    this.impactGroupFormModel = new ImpactGroupFormSectionModel({
      appService,
      projectId,
      impactGroup: this.impactGroup,
      organisationId
    });
  }

  onMount = (orgId: number) => {
    this.organisationId = orgId;
  }

  onUnmount = () => { }

  @action
  createImpactGroup = async () => {
    const form = await this.impactGroupFormModel.formModel.submit();
    if (!form) return;
    form.projectId = this.projectId;

    this.httpProgress.showOverlay();
    const res = await this.impactGroupProvider.create(this.organisationId, this.projectId, form as FP.Entities.IImpactGroup);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups/${res.payload.id}`);
  };
}
