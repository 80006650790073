import * as React from "react";
import ImageCropper from "../../../../components/ui/ImageCropper";
import { IImageCropperModel, IImageCropperResult } from "./IImageCropperModel";
import { observer } from "mobx-react";
import { Avatar } from "../../../../components/ui/Avatar";
import { Enums } from "../../../../enums";

var blobToBase64 = function (blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl: any = reader.result;
    var base64 = dataUrl.split(",")[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
};
export interface ImageCropperViewProps {
  model: IImageCropperModel;
}

@observer
export class ImageCropperView extends React.Component<ImageCropperViewProps, any> {
  imageRef;
  fileUrl;

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: ImageCropper.Crop, percentCrop: ImageCropper.PercentCrop) => {
    this.props.model.setCrop(crop, percentCrop);
  };

  async makeClientCrop(crop) {
    if (this.imageRef) {
      const croppedImageInfo = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.props.model.setCroppedImageResult(croppedImageInfo as IImageCropperResult);
    }
  }

  getCroppedImg(image, crop, fileName) {
    const cropperElHeight = 400;
    const croppHeight = crop.height || cropperElHeight;
    const croppWidth = crop.width || (image.width * cropperElHeight) / image.height;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / ((image.width * cropperElHeight) / image.height);
    const scaleY = image.naturalHeight / cropperElHeight;
    canvas.width = crop.width || (image.width * cropperElHeight) / image.height;
    canvas.height = crop.height || cropperElHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      croppWidth * scaleX,
      croppHeight * scaleY,
      0,
      0,
      croppWidth,
      croppHeight
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob: any) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          blobToBase64(blob, base64 => {
            resolve({ url: this.fileUrl, base64, blob });
          });
        },
        "image/jpeg",
        1
      );
    });
  }

  render() {
    let { model } = this.props;
    return (
      <div className="image-cropper-view">
        {model.value && (
          <ImageCropper
            src={model.orignalImageSource}
            crop={model.crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {model.croppedImageUrl && (
          <div className="mt-3">
            <div>Preview: </div>
            <Avatar imgSrc={model.croppedImageUrl} size={Enums.UiSizes.LG} />
          </div>
        )}
      </div>
    );
  }
}
