import * as React from 'react';

const SvgArrowDownLeft = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="arrow-down-left_svg__feather arrow-down-left_svg__feather-arrow-down-left"
        {...props}
    >
        <path d="M17 7L7 17M17 17H7V7" />
    </svg>
);

export default SvgArrowDownLeft;
