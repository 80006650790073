import React, { useEffect } from "react";
import { CreateAudienceViewModel } from "./CreateAudienceView_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";

interface CreateAudienceViewProps {
    model: CreateAudienceViewModel;
}

const CreateAudienceView: React.FunctionComponent<CreateAudienceViewProps> = observer((props) => {
    const { model } = props;

    useEffect(() => {
        model.onMount();
        return model.onUnmount;
    })

    return <div className="create-audience-view container-fluid pt-6">
        <div className="row mb-4">
            <div className="col-lg-7">
                <h1>{I18n.t("phrases.createNew")} <strong>{I18n.t("entities.audience")}</strong></h1>
                <p className="mb-0">{I18n.t("phrases.createAudienceDescription")}</p>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-lg-10">
                <SingleForm model={model.formModel} />
            </div>
        </div>
    </div>
})

export { CreateAudienceView };