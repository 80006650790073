import * as ReactDOM from "react-dom";
import * as React from "react";
import { action } from "mobx";
import { IModalService, IModalConfig } from "./IModalService";
import { IModalModel } from "./IModalModel";
import { ModalModel } from "./Modal_model";
import { ModalView } from "./Modal_view";
import { IUiAction } from "../uiAction/IUiAction";
import { ModalProps } from "../../components/ui/Modal";
import { Animations } from "../util/Animations";

export class ModalService implements IModalService {
  modalWrapper: HTMLDivElement;
  rootEl: HTMLDivElement;
  currentConfig: IModalConfig;
  model: IModalModel;
  stack: IModalConfig[] = [];

  constructor(el?: HTMLDivElement, shouldUserRenderDom: boolean = true) {
    this.model = new ModalModel(this);
    if (shouldUserRenderDom) {
      this.init(el);
    }
  }

  @action.bound
  show(config: IModalConfig): void {
    this.currentConfig = config;
    this.model.set(config);
    this.showModalElement();
  }

  showAsync<T>(
    cb: (resolve: (val: T) => void, reject: (a: any) => void, model: IModalModel) => IModalConfig
  ): Promise<T> {
    return new Promise<T>((res, rej) => {
      this.showModalElement();
      this.currentConfig = cb(res, rej, this.model);
      this.model.set(this.currentConfig, res, rej);
    });
  }

  showConfirmDialog(
    title: React.ReactNode,
    content: React.ReactNode,
    yes: string = "yes",
    no: string = "no",
    componentProps: ModalProps,
    onPositive?: () => void,
    onNegative?: () => void,
    yesBtnType?:
      | "primary"
      | "secondary"
      | "success"
      | "danger"
      | "warning"
      | "info"
      | "light"
      | "dark"
      | "outline-primary"
      | "outline-secondary"
      | "outline-success"
      | "outline-danger"
      | "outline-warning"
      | "outline-info"
      | "outline-light"
      | "outline-dark"
      | "link"
      | "link-big"
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      this.showWithReturn({
        title,
        content,
        showClose: false,
        componentProps,
        animationOptions: {
          animateIn: Animations.FADE_IN,
          animateOut: Animations.FADE_OUT,
          speed: 5
        },
        actions: [
          {
            id: "yes",
            label: yes,
            onAction: () => {
              onPositive ? onPositive() : this.hide();
              res(true);
            },
            rendersIn: "Button",
            componentProps: {
              type: yesBtnType || "primary",
              className: "float-right ml-2"
            }
          },
          {
            id: "no",
            label: no,
            onAction: () => {
              onNegative ? onNegative() : this.hide();
              res(false);
            },
            rendersIn: "Button",
            componentProps: {
              type: "link",
              className: "float-right ml-2"
            }
          }
        ]
      });
    });
  }

  showWithReturn(config: IModalConfig): void {
    if (this.currentConfig) {
      this.stack.push(this.currentConfig);
    }
    this.show(config);
  }

  setActions(actions: IUiAction<any>[]): void {
    this.model.actions = actions;
  }

  @action.bound
  setTitle = (title: React.ReactNode): void => {
    this.model.title = title;
    this.currentConfig.title = title;
  };

  @action.bound
  setContent(content: React.ReactNode): void {
    this.model.content = content;
    this.currentConfig.content = content;
  }

  isVisible(): boolean {
    return this.model.visible;
  }

  @action.bound
  hide(): void {
    this.currentConfig = this.stack.pop();
    if (this.currentConfig) {
      this.show(this.currentConfig);
    } else {
      this.hideModalElement();
    }
  }

  @action
  hideAll = () => {
    while (this.currentConfig) {
      this.currentConfig = this.stack.pop();
    }
    this.hideModalElement();
  };

  /**
   * Hides the modal wrapper that is injected to
   * 'body' in the init function
   */
  private hideModalElement = () => {
    this.model.animateOutFn();
    setTimeout(() => {
      this.model.visible = false;
      this.model.content = null;

      if (this.modalWrapper) {
        this.modalWrapper.style.display = "none";
      }
    }, 500);
  };

  /**
   * Shows the modal wrapper that is injected to
   * 'body' in the init function
   */
  private showModalElement = () => {
    this.model.animateInFn();
    setTimeout(() => {
      if (this.modalWrapper) {
        this.modalWrapper.style.display = "block";
      }
    });
  };

  init(el?: HTMLDivElement) {
    let child = document.createElement("div");
    child.className = "modal__service-wrapper";
    child.id = "ModalWrapperService";
    this.modalWrapper = child;
    this.hideModalElement();
    if (!el) {
      el = child;
      document.querySelector("body").appendChild(el);
      this.modalWrapper = el;
      ReactDOM.render(<ModalView model={this.model} />, el);
    }
  }

  renderComponent = () => {
    return (
      <div className="modal__service-wrapper--inner" style={{ display: "none" }} ref={e => (this.modalWrapper = e)}>
        <ModalView model={this.model} />
      </div>
    );
  };
}
