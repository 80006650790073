import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Enums } from "../../../../../enums";

export class ImpactStakeholderListViewModel extends BaseModel {
  owner: FP.Entities.IImpactGroup;
  appService: AppService;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref stakeholders: FP.Entities.IStakeholder[] = [];
  @observable.ref filteredStakeholders: FP.Entities.IStakeholder[] = [];
  projectId: number;
  constructor(appService: AppService, projectId: number, owner: FP.Entities.IImpactGroup) {
    super();
    this.appService = appService;
    this.owner = owner;
    this.stakeholders = owner.stakeholders;
    this.filteredStakeholders = owner.stakeholders;
    this.projectId = projectId;
  }

  onMount = () => {};

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterStakeholders();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterStakeholders();
    this.hideSearchMode();
  };

  @action
  filterStakeholders = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredStakeholders = this.stakeholders.filter(stakeholder => {
        const name =
          stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
            ? `${stakeholder.firstName} ${stakeholder.lastName}`
            : stakeholder.firstName;
        const lowerName = name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredStakeholders = this.stakeholders;
    }
  };
}
