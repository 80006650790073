import * as _ from "lodash";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { StakeholderGroupListViewModel } from "../../stakeholderGroup/stakeholderGroupListView/StakeholderGroupListView_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import {
  GetStakeholderTableConfig
} from "../../stakeholders/stakeholderListView/StakeholderTableConfig";
import { StakeholderListViewModel } from "../../stakeholders/stakeholderListView/StakeholderListView_model";
import { Enums } from "../../../../enums";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { IPaginationModel, PaginationModel } from "../../../../components/widgets/pagination/Pagination_model";
import { GetStakeholderListFilters } from "./StakeholderViewFilter_fields";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import I18n from "../../../../core/localization/I18n";
import { IOrganisationSettingsService } from "../../../../services/local/settingsService/IOrganisationSettingsService";

export class StakeholderViewModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  stakeholderListViewModel: StakeholderListViewModel;
  stakeholderAudienceListViewModel: StakeholderListViewModel;
  stakeholderGroupListViewModel: StakeholderGroupListViewModel;
  projectProvider: IProjectsApi;
  projectId: number;
  individualFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  projectStakeholderProvider: IProjectStakeholdersApi;
  individualPagination: IPaginationModel;
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  organisationSettings: IOrganisationSettingsService;
  @observable showVisualisations: boolean;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps, organisationId: number) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.organisationSettings = this.appService.getService<IOrganisationSettingsService>(
      Services.OrganisationSettingsService
    );

    let proId = this.routeProps.match.params["id"];
    if (proId) {
      this.projectId = parseInt(proId);
    }
    this.organisationId = organisationId;

    this.installIndividualPaginations();
    this.installIndividualFilters();
    this.individualFilter.setFromQueryString(this.routeProps.location.search);

    this.stakeholderListViewModel = new StakeholderListViewModel(this.appService, false, {
      filterModel: this.individualFilter,
      stakeholderType: Enums.StakeholderType.INDIVIDUAL,
      paginationModel: this.individualPagination
    }, this.projectId);
    this.stakeholderListViewModel.setTableConfig(
      GetStakeholderTableConfig(
        this.stakeholderListViewModel,
        this.stakeholderListViewModel.tableModel,
        this.individualFilter
      )
    );

    this.loadProject();
    this.loadStakeholderAndAudienceCounts();

    this.showVisualisations = false;
  }

  installIndividualPaginations = () => {
    this.individualPagination = new PaginationModel();
    this.individualPagination.setConfig({ onPageClick: this.loadIndPageData });
  };

  loadIndPageData = (index: number) => {
    this.individualFilter.setConfig({ page: index });
    this.individualFilter.loadData();
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );

    if (!res || res.isError) return;

    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  installIndividualFilters = () => {
    this.individualFilter = new FilterModel(this.appService, this.individualPagination, {
      filterCb: async filterOptions =>
        await this.projectStakeholderProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
      projectId: this.projectId
    });

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };

    const nameFilter: IFilterAttribute = {
      key: "Stakeholder.FirstName|Stakeholder.LastName",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const typeFilter: IFilterAttribute = {
      key: "Stakeholder.StakeholderType",
      value: [Enums.StakeholderType.INDIVIDUAL.toString()],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const ownerFilter: IFilterAttribute = {
      key: "Owner.FirstName|Owner.LastName",
      label: I18n.t("filters.owner"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const roleFilter: IFilterAttribute = {
      key: "Role.Name",
      label: I18n.t("filters.role"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const busAFilter: IFilterAttribute = {
      key: "BusinessArea.Name",
      label: I18n.t("filters.businessArea"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const impactFilter: IFilterAttribute = {
      key: "impact",
      label: I18n.t("filters.impact"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS
    };

    const influenceFilter: IFilterAttribute = {
      key: "influence",
      label: I18n.t("filters.influence"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS
    };

    const impactGenFilter: IFilterAttribute = {
      key: "impactGen",
      label: I18n.t("filters.impactGen"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.ImpactLevelMin(parseInt(value));
      }
    };

    const influenceGenFilter: IFilterAttribute = {
      key: "influenceGen",
      label: I18n.t("filters.influenceGen"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.InfluenceLevelMin(parseInt(value));
      }
    };

    this.individualFilter.addSort({ key: "impact", isAsc: false });
    // this.individualFilter.addFilter(isNew);
    this.individualFilter.addFilter(nameFilter);
    this.individualFilter.addFilter(typeFilter);
    this.individualFilter.addFilter(ownerFilter);
    this.individualFilter.addFilter(projectFilter);
    this.individualFilter.addFilter(lifeCycleFilter);
    this.individualFilter.addFilter(roleFilter);
    this.individualFilter.addFilter(busAFilter);
    this.individualFilter.addFilter(impactFilter);
    this.individualFilter.addFilter(influenceFilter);
    this.individualFilter.addFilter(impactGenFilter);
    this.individualFilter.addFilter(influenceGenFilter);

    this.individualFilter.setConfig({
      formFields: GetStakeholderListFilters,
      onDataLoaded: d => this.stakeholderListViewModel.tableModel.setData(d)
    });
  };

  loadShowVisualisations = async () => await this.organisationSettings.isVisualisationsEnabled(this.organisationId);

  onMount = () => {
    Promise.all([this.loadShowVisualisations()]).then(values => {
      // the project should be set before impacts, timeline requires the
      // project to be present before installing the timelines
      this.showVisualisations = values[0];
    });
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    if (!res || res.isError) return;

    if (res.payload) {
      this.setProject(res.payload);
    }
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  onUnmount = () => {};

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.routeProps.history.push(
        `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholder-visualisations`
      );
      return;
    }

    if (newTabIndex === 0) {
      this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.routeProps.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };
}

export const ProjectStakeholderHelper = {
  getIndividual: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    return _.filter(stakeholders, e => e.stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL);
  },
  getGroups: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    let newList = _.filter(stakeholders, e => typeof e.stakeholderGroup !== "undefined" || e.stakeholderGroup == null);
    let res = _.groupBy(newList, e => {
      return e.stakeholderGroup ? _.camelCase(e.stakeholderGroup.name) : "other";
    });
    return res;
  }
};
