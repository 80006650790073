import React, { useCallback, useEffect, useState } from "react";
import { IOrganisationViewModel, OrganisationViewModel } from "./View_model";
import { SearchPanelView } from "../../../../components/widgets/searchPanel/SearchPanel_view";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { Panel } from "../../../../components/ui/Panel";
import { LinkButton, Button } from "../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Enums } from "../../../../enums";
import { Pill } from "../../../../components/ui/Pill";
import { CanOwnOrg } from "../../../../components/hoc/CanEdit";
import { TimelineView } from "../../../../components/widgets/timeline/Timeline_view";
import { ButtonGroup } from "../../../../components/ui/ButtonGroup";
import { Link, useParams } from "react-router-dom";
import { Async } from "react-async";
import { useAppService } from "../../../../AppService";
import { useFlightPathUser } from "../../../../setup";
import { Animations } from "../../../../core/util/Animations";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";

export interface IOrganisationView {
  model?: IOrganisationViewModel;
}

export const OrganisationView: React.FunctionComponent<IOrganisationView> = observer(({ model: m }) => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const { organisationId } = useParams<{ organisationId: string }>();
  const [model] = useState(() => m || new OrganisationViewModel(appService, user));

  useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  const loadOrganisation = useCallback(async () => {
    await model.loadOrganisation(+organisationId);
  }, [model, organisationId]);

  return (
    <Async promiseFn={loadOrganisation}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <OrganisationViewContent model={model} organisationId={organisationId} />
      </Async.Resolved>
    </Async>
  );
});

const OrganisationViewContent: React.FC<any> = observer(({ model, organisationId }) => {
  const organisation = model.organisation;
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`} data-testid="organisation-detail-view">
      <div className="row mb-4">
        <div className="col-12">
          <div className="d-flex align-items-center">
            <h1 className="mb-0">{organisation.name}</h1>

            <Pill type="outline-primary" className="ml-4">
              {I18n.t("phrases.organisation")}
            </Pill>

            <CanOwnOrg>
              <LinkButton data-testid="manage-organisation-button" className="ml-auto" type="primary" href={`/organisations/${model.orgId}/settings`}>
                {I18n.t("phrases.manageOrganisation")}
              </LinkButton>
            </CanOwnOrg>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <h2>{I18n.t("phrases.overview")}</h2>
          <div
            className="mb-5"
            dangerouslySetInnerHTML={{
              __html: RenderXssSafeString(organisation?.description) || `<p>${I18n.t("phrases.noDescription")}</p>`
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <h2>{I18n.t("phrases.organisationOwner")}</h2>
              <Panel type="border-lines-y" className="py-3">
                <Link to={`/organisations/${organisationId}/users/${organisation.ownerId}`}>
                  <AvatarContent
                    type="primary"
                    avatarProps={{
                      size: Enums.UiSizes.MD,
                      imgSrc: organisation.owner?.profileImageUrl
                    }}
                  >
                    <h4 className="mb-0">
                      {organisation.owner.firstName} {organisation.owner.lastName}
                    </h4>
                  </AvatarContent>
                </Link>
              </Panel>
            </div>
            <div className="col-lg-6 mb-5">
              <div className="d-flex flex-column" style={{ height: "100%" }}>
                <h2>{I18n.t("phrases.location")}</h2>
                <Panel
                  type="border-lines-y"
                  className="d-flex flex-column justify-content-center py-3"
                  style={{ flex: 1 }}
                >
                  {organisation.location ? (
                    <h4 className="mb-0">{organisation.location.name}</h4>
                  ) : (
                      <span>{I18n.t("phrases.noLocation")}</span>
                    )}
                </Panel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-5">
          <SearchPanelView model={model.programmeSearchModel.model} />
        </div>
        <div className="col-lg-6 mb-5">
          <SearchPanelView model={model.projectSearchModel.model} />
        </div>
      </div>
      <div className="row py-6 bg-light">
        <div className="col-12">
          <div className="d-flex align-items-center mb-4">
            <h1 className="mb-0">
              {organisation.name} |{" "}
              <strong>
                {I18n.t(model.timelineViewMode === "programme" ? "phrases.programmes" : "phrases.projects")}
              </strong>
            </h1>
            <ButtonGroup className="ml-auto" isToggle={true}>
              <Button
                type={model.timelineViewMode === "programme" ? "primary" : "outline-primary"}
                onClick={() => {
                  model.setTimelineViewMode("programme");
                }}
              >
                {I18n.t("phrases.programmes")}
              </Button>
              <Button
                type={model.timelineViewMode === "project" ? "primary" : "outline-primary"}
                onClick={() => {
                  model.setTimelineViewMode("project");
                }}
              >
                {I18n.t("phrases.projects")}
              </Button>
            </ButtonGroup>
          </div>
          <div className="mb-4">
            {model.timelineViewMode === "programme" ? (
              <TimelineView model={model.programmeTimelineModel} />
            ) : (
                <TimelineView model={model.projectTimelineModel} />
              )}
          </div>
        </div>
      </div>
    </div>
  );
})