import React from "react";
import { observer } from "mobx-react";
import { IFormFieldModel } from "../formField/IFormField";
import { FormField } from "../formField/FormField_view";

export interface IFormModel {
  formFields: IFormFieldModel<any, any>[];
}

export interface IFormProps {
  model: IFormModel;
  className?: string;
  onSubmit?: (formResult: Dictionary<any>) => void;
}

export interface IFormViewModelOptions {
  fields: IFormFieldModel<any, any>[];
  onSubmit: (dataDictionary: Dictionary<any>) => Promise<void>;
}

export const Form: React.FC<IFormProps> = observer(({ model, className }) => {
  React.useEffect(() => {
    return () => {
      let { formFields } = model;
      if (formFields && formFields.length) {
        formFields.forEach((e: IFormFieldModel<any, any>) => {
          if (e && e.destroyModel) {
            e.destroyModel();
          }
        });
      }
    };
  }, [model]);

  return (
    <div className={`form ${className || ""}`}>
      {model.formFields &&
        model.formFields.map(e => {
          return <FormField model={e} key={e.key} />;
        })}
    </div>
  );
});
