import * as React from "react";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";

export interface UserDetailsProps {
  item: FP.Entities.IUser;
}

const UserDetails: React.FunctionComponent<UserDetailsProps> = ({ item }) => {
  return (
    <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4 row`}>
      <div className="col-lg-6 mb-4">
        <h4 className="mb-2">{I18n.t("table.name")}</h4>
        <p>{`${item.firstName} ${item.lastName}`}</p>
      </div>
      <div className="col-lg-6 mb-4">
        <h4 className="mb-2">{I18n.t("table.email")}</h4>
        <p>
          <a className="hover-underline" href={`mailto:${item.email}`}>
            {item.email}
          </a>
        </p>
      </div>
    </div>
  );
};

export { UserDetails };
