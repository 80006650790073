import { BaseModel } from "../../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IMilestonesApi } from "../../../../../services/api/v1/milestones/IMilestones.api";
import { IFlightPathApiResponse } from "../../../../../services/api/BaseApiModel";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getFormFields } from "../../../forms/milestone/MilestoneFormSection_data";
import I18n from "../../../../../core/localization/I18n";

export class MilestoneModalModel extends BaseModel {
  appService: AppService;
  formModel: SingleFormModel;
  modalService: IModalService;
  milestoneProvider: IMilestonesApi;
  milestone: FP.Entities.IMilestone;
  programmeId: number;
  orgId: number;

  constructor(
    appService: AppService,
    orgId: number,
    programmeId: number,
    loadData: (id: number) => void,
    milestone?: FP.Entities.IMilestone,
    closeFn?: Function
  ) {
    super();
    this.orgId = orgId;
    this.programmeId = programmeId;
    this.appService = appService;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.milestoneProvider = this.appService.getService<IMilestonesApi>(Services.MilestonesApi);
    this.milestone = milestone;    

    this.formModel = new SingleFormModel();
    this.formModel.formFields = getFormFields(this.milestone);
    this.formModel.actions = [
      {
        id: "no",
        label: I18n.t("phrases.cancel"),
        onAction: () => {
          this.modalService.hide();
        },
        rendersIn: "Button",
        componentProps: {
          type: "link",
          className: "ml-auto"
        }
      },
      {
        id: "CreateMilestoneButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let milestoneFormEl = await this.formModel.submit();
          if (milestoneFormEl) {
            let formResult = {
              ...milestoneFormEl
            };

            formResult.programmeId = this.programmeId;

            let res: IFlightPathApiResponse<FP.Entities.IMilestone>;
            if (this.milestone) {
              res = await this.milestoneProvider.updateProgrammeMilestone(
                this.orgId,
                this.programmeId,
                this.milestone.id,
                formResult as FP.Entities.IMilestone
              );
            } else {
              res = await this.milestoneProvider.createProgrammeMilestone(
                this.orgId,
                this.programmeId,
                formResult as FP.Entities.IMilestone
              );
            }

            if (res) {
              closeFn && closeFn();
              this.modalService.hide();
              loadData(this.programmeId);
            }
          }
        },
        rendersIn: "Button",
        componentProps: {
          type: "primary",
          className: "ml-2"
        }
      }
    ];
  }

  onMount = () => { };

  onUnmount = () => { };
}
